/* eslint-disable @typescript-eslint/naming-convention */
export class UserRegister {
  first_name: string;
  last_name: string;
  email: string;
  logonToken: string;
  hashPassword: string;
  billingDocument: string;
  pictureUrl: string;
  pictureName: string;
  hasLoggedIn: boolean;
  requireDocument: boolean;
  hasAccount: boolean;
}
