import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import { Subject, interval } from 'rxjs';
import { takeUntil, takeWhile, tap } from 'rxjs/operators';

import { TSqGalleryFileModel } from '../../models/tsq-gallery-file.model';
import {
  TSqGalleryListItemTemplateRefContext,
  TSqGalleryTopPreviewTemplateRefContext,
} from '../../models/tsq-gallery-template-ref-context.model';
import { TSqGalleryPreviewComponent } from '../tsq-gallery-preview/tsq-gallery-preview.component';

@Component({
  selector: 'comm-gallery',
  templateUrl: './tsq-gallery.component.html',
  styleUrls: ['./tsq-gallery.component.scss'],
})
export class TSqGalleryComponent implements OnDestroy {
  @ViewChild(TSqGalleryPreviewComponent, { static: true }) preview: TSqGalleryPreviewComponent;

  @Input() files: TSqGalleryFileModel[];
  @Input() fileDisplayTemplate: TemplateRef<TSqGalleryListItemTemplateRefContext>;
  @Input() topPreviewTemplate: TemplateRef<TSqGalleryTopPreviewTemplateRefContext>;
  @Input() bottomPreviewTemplate: TemplateRef<unknown>;
  @Input() hasShadow = true;
  @Input() hasPadding = true;

  private listEl: Element;
  private galleryContainerEl: Element;
  private childLoaded$ = new Subject<void>();

  private _shouldShowLeftArrow = false;
  private _shouldShowRightArrow = false;

  private componentDestroyed$ = new Subject<void>();

  constructor(private cdRef: ChangeDetectorRef) {
    this.childLoaded$.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => {
      if (!!this.listEl && !!this.galleryContainerEl) {
        setTimeout(() => this.onScroll(), 1);
      }
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.unsubscribe();
  }

  @ViewChild('list', { static: true }) set list(value: ElementRef) {
    if (!!value) {
      this.listEl = value.nativeElement as Element;
      this.childLoaded$.next();
    }
  }

  @ViewChild('galleryContainer', { static: true }) set galleryContainer(value: ElementRef) {
    if (!!value) {
      this.galleryContainerEl = value.nativeElement as Element;
      this.childLoaded$.next();
    }
  }

  open = (index?: number): void => {
    this.preview.open(index);
  };

  get shouldShowLeftArrow(): boolean {
    return this._shouldShowLeftArrow;
  }

  get shouldShowRightArrow(): boolean {
    return this._shouldShowRightArrow;
  }

  scrollLeft(el: Element): void {
    const animTimeMs = 400;
    const pixelsToMove = 315;
    const stepArray = [0.001, 0.021, 0.136, 0.341, 0.341, 0.136, 0.021, 0.001];
    interval(animTimeMs / 8)
      .pipe(
        takeWhile(value => value < 8),
        tap(value => (el.scrollLeft -= pixelsToMove * stepArray[value])),
      )
      .subscribe();
  }

  scrollRight(el: Element): void {
    const animTimeMs = 400;
    const pixelsToMove = 315;
    const stepArray = [0.001, 0.021, 0.136, 0.341, 0.341, 0.136, 0.021, 0.001];
    interval(animTimeMs / 8)
      .pipe(
        takeWhile(value => value < 8),
        tap(value => (el.scrollLeft += pixelsToMove * stepArray[value])),
      )
      .subscribe();
  }

  getListItemContext(
    file: TSqGalleryFileModel,
    index: number,
  ): TSqGalleryListItemTemplateRefContext {
    return {
      index,
      url: file.displayUrl,
      alt: file.displayAlt,
      fns: {
        open: this.open,
      },
    };
  }

  onScroll(): void {
    this._shouldShowLeftArrow = !this.listEl ? false : this.listEl.scrollLeft > 0;
    this._shouldShowRightArrow = this.checkShouldShowRightArrow();
    this.cdRef.markForCheck();
  }

  private checkShouldShowRightArrow(): boolean {
    if (!this.listEl || !this.galleryContainerEl) {
      return false;
    }

    if (this.listEl.scrollWidth <= this.galleryContainerEl.clientWidth) {
      return false;
    }

    return this.listEl.scrollLeft + this.listEl.clientWidth < this.listEl.scrollWidth;
  }
}
