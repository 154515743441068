import {AisPhone} from './ais-phone.json';

export class AisOwner {
  tsUserId: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phones: AisPhone[];
  aisAccountUuid: string;
  aisPersonUuid: string;
  aisUnitUuid: string;
  aisCommunityUuid: string;
}
