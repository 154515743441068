import { Action, createReducer, on } from '@ngrx/store';

import * as fromUsersDetails from './users.actions';

export interface UsersState {
  userRecovery: {
    sending: boolean;
    error: boolean,
    completed: boolean;
  };
}

export const usersInitialState: UsersState = {
  userRecovery: {
    sending: false,
    error: false,
    completed: false,
  },
};

const reducer = createReducer(
  usersInitialState,
  on(fromUsersDetails.requestUserRecovery, state => ({
    ...state,
    userRecovery: {
      sending: true,
      error: false,
      completed: false,
    },
  })),
  on(fromUsersDetails.requestUserRecoverySuccess, state => ({
    ...state,
    userRecovery: {
      ...state.userRecovery,
      sending: false,
      completed: true,
    },
  })),
  on(fromUsersDetails.requestUserRecoveryFailure, state => ({
    ...state,
    userRecovery: {
      ...state.userRecovery,
      sending: false,
      error: true,
    },
  })),
  on(fromUsersDetails.restoreUserRecoveryInitialState, state => ({
    ...state,
    userRecovery: usersInitialState.userRecovery,
  })),
);

export function usersReducer(state: UsersState | undefined, action: Action): UsersState {
  return reducer(state, action);
}
