import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { UntilDestroy } from '@ngneat/until-destroy';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { ToastrService } from 'ngx-toastr';
import { Observable, switchMap, tap } from 'rxjs';

import { ToastErrorComponent } from '@tsq-web/toast';

import { SsoResetPasswordService } from '../services/sso-reset-password.service';

export interface SsoResetPasswordState {
  loading: boolean;
  emailIsSso: boolean;
  showSentEmailMessage: boolean;
  typedEmail: string;
}
export const resetSsoPasswordInitialState: SsoResetPasswordState = {
  loading: false,
  emailIsSso: false,
  showSentEmailMessage: false,
  typedEmail: undefined,
};

@UntilDestroy()
@Injectable()
export class SsoResetPasswordStore extends ComponentStore<SsoResetPasswordState> {
  readonly resetPasswordLoading$ = this.select(({ loading }) => loading);
  readonly emailIsSso$ = this.select(({ emailIsSso }) => emailIsSso);
  readonly showSentEmailMessage$ = this.select(({ showSentEmailMessage }) => showSentEmailMessage);
  readonly typedEmail$ = this.select(({ typedEmail }) => typedEmail);

  readonly resetPassword = this.effect((data$: Observable<{ email: string }>) =>
    data$.pipe(
      tap(() => this.patchState({ loading: true })),
      switchMap(({ email }) => {
        return this.ssoResetPasswordService.resetPassword(email).pipe(
          tapResponse(
            resetPasswordResponse =>
              this.patchState({
                emailIsSso: resetPasswordResponse.singleSignOn,
                showSentEmailMessage: !resetPasswordResponse.singleSignOn,
                typedEmail: resetPasswordResponse.username,
                loading: false,
              }),
            (error: HttpErrorResponse) => {
              this.resetState();
              this.toastr.error(error.error.message, null, { toastComponent: ToastErrorComponent });
            },
          ),
        );
      }),
    ),
  );

  readonly resetShowSentEmailMessage = this.updater((state: SsoResetPasswordState) => ({
    ...state,
    showSentEmailMessage: false,
  }));

  private readonly resetState = this.updater(() => ({
    ...resetSsoPasswordInitialState,
  }));

  private readonly ssoResetPasswordService = inject(SsoResetPasswordService);
  private readonly toastr = inject(ToastrService);

  constructor() {
    super(resetSsoPasswordInitialState);
  }
}
