import { mockedUserContextState } from '@tsq-web/user-context';
import { mockedRouterState } from '@tsq-web/router';
import { mockedLocalizationState } from '@tsq-web/localization';
import { AppState } from '../models/app-state';

export const mockedAppState: AppState = {
  userContext: mockedUserContextState,
  router: mockedRouterState,
  localization: mockedLocalizationState,
};
