<ng-container *ngIf="!loginSimplificationEnabled; else newLoginLayout">
  <main class="relative grid h-full grid-cols-1 md:grid-cols-3">
    <section class="bg-green md:col-span-1">
      <div class="m-40">
        <img
          src="./assets/brand/white-logo.svg"
          class="h-56"
          alt="{{ 'LOGIN.TOWNSQ_LOGO' | translate }}"
        />
      </div>
    </section>

    <section
      class="bg-petro-n1 col-span-full flex w-full items-center justify-center md:col-span-2"
    >
      <div class="bg-petro-p1 border-petro-n2 border-1 flex flex-col rounded p-40">
        <comm-join-community-card
          [ssoEnabled]="ssoEnabled"
          [showLinkExpiredBanner]="showLinkExpiredBanner"
          *ngIf="(currentStep$ | async) === steps.CommunityCard"
          (goToLogin)="handleStep()"
          (goToSignUp)="goToSignUp()"
          (goToSignUpWithEmail)="goToSignUpWithEmail()"
          (goToLoginWithSso)="goToLoginWithSso()"
          (sendInfoClick)="sendNeedHelpInfoClick()"
          (closeLinkExpiredBanner)="closeLinkExpiredBanner()"
        />

        <comm-login-card
          [ssoEnabled]="ssoEnabled"
          *ngIf="(currentStep$ | async) === steps.LoginCard"
          [isTownSqBrazil]="isTownSqBrazil"
          [isLoginIn]="isLoginIn$ | async"
          [email]="email"
          [showAlreadyRegisteredBanner]="showAlreadyRegisteredBanner"
          (goBackClicked)="handleStep()"
          (login)="doLogin($event)"
          (loginWithSso)="doLoginWithSso($event)"
          (closeBanner)="closeBanner()"
        />
      </div>
    </section>
  </main>
</ng-container>

<ng-template #newLoginLayout>
  <ng-container *ngIf="(currentStep$ | async) === steps.CommunityCard">
    <main class="bg-petro-n1 relative flex h-full w-full flex-col items-center justify-center">
      <img [src]="logoPath" [alt]="'TOWNSQ_LOGO' | translate" class="mb-48 block h-40 w-[176px]" />
      <tsq-loader *ngIf="loading && !routing; else loginTpl" />
      <ng-template #loginTpl>
        <div
          *ngIf="!loading"
          class="bg-petro-p1 border-petro-n2 border-1 flex w-[504px] max-w-md flex-col rounded-2xl p-40"
        >
          <comm-join-community-card
            [loginSimplificationEnabled]="loginSimplificationEnabled"
            [ssoEnabled]="ssoEnabled"
            [showLinkExpiredBanner]="showLinkExpiredBanner"
            (goToLogin)="handleStep()"
            (goToSignUp)="goToSignUp()"
            (goToSignUpWithEmail)="goToSignUpWithEmail()"
            (goToLoginWithSso)="goToLoginWithSso()"
            (sendInfoClick)="sendNeedHelpInfoClick()"
            (closeLinkExpiredBanner)="closeLinkExpiredBanner()"
          />
        </div>
      </ng-template>
    </main>
  </ng-container>

  <comm-auth-card
    *ngIf="(currentStep$ | async) === steps.LoginCard"
    (goBackClicked)="handleStep()"
  />
</ng-template>

<comm-links-footer
  *ngIf="(currentStep$ | async) === steps.CommunityCard || !loginSimplificationEnabled"
  [loginSimplificationEnabled]="loginSimplificationEnabled"
/>
