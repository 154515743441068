import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'comm-customer-taxes',
  templateUrl: './customer-taxes.component.html',
})
export class CustomerTaxesComponent {
  @Input() form: UntypedFormGroup;
}
