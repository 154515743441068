import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';

import { Observable, map } from 'rxjs';

import { injectFeatureFlagService } from '@tsq-web/feature-flag';

export const aisAccountRetrievalGuard: CanActivateFn = (): Observable<boolean | UrlTree> => {
  const router = inject(Router);
  const featureFlagService = injectFeatureFlagService();

  return featureFlagService.getValueAsync('newAccountLinkingFlow', false).pipe(
    map(newAccountLinkingFlowFlag => {
      if (!newAccountLinkingFlowFlag) {
        return true;
      }

      const condoId = localStorage.getItem('condoId');

      return router.createUrlTree(['registration', 'account-linking'], {
        queryParams: { condoId },
      });
    }),
  );
};
