<div class="retrieval-container">
  <h1>{{ 'AIS_SIGN_UP.WELCOME' | translate }}</h1>

  <comm-login-info-banner *ngIf="!userJwt" />

  <p>{{ 'AIS_SIGN_UP.BEFORE_REGISTER' | translate }}</p>

  <form [formGroup]="form">
    <div class="form-group">
      <label for="acc-number"
        >{{ 'AIS_SIGN_UP.ACCOUNT_NUMBER' | translate }}
        <span class="secondary-label">{{ 'MANDATORY_FIELD' | translate }}</span></label
      >
      <i
        class="fa fa-question-circle"
        aria-hidden="true"
        [tooltip]="'AIS_SIGN_UP.ACCOUNT_NUMBER_TOOLTIP' | translate"
      ></i>
      <input id="acc-number" class="form-control" formControlName="account" />
    </div>

    <div class="form-group">
      <label for="zip"
        >{{ 'AIS_SIGN_UP.ZIP' | translate }}
        <span class="secondary-label">{{ 'MANDATORY_FIELD' | translate }}</span></label
      >
      <input id="zip" class="form-control" formControlName="zip" />
    </div>

    <div class="form-group">
      <label for="first-name" class="optional"
        >{{ 'AIS_SIGN_UP.FIRST_NAME' | translate }}
        <span>{{ 'OPTIONAL' | translate | uppercase }}</span></label
      >
      <input id="first-name" class="form-control" formControlName="firstName" />
    </div>

    <div class="form-group">
      <label for="last-name"
        >{{ 'AIS_SIGN_UP.LAST_NAME' | translate }}
        <span class="secondary-label">{{ 'MANDATORY_FIELD' | translate }}</span></label
      >
      <input id="last-name" class="form-control" formControlName="lastName" />
    </div>
  </form>

  <button
    data-cy="ais-account-retrieval--button--continue"
    class="btn btn-primary"
    *ngIf="!sending"
    [disabled]="!isFormValid"
    (click)="onContinue()"
  >
    {{ 'AIS_SIGN_UP.CONTINUE' | translate }}
  </button>

  <div *ngIf="sending" class="sending-loader">
    <tsq-small-loader></tsq-small-loader>
  </div>

  <div class="help-actions">
    <a [routerLink]="['/login']">{{ 'AIS_SIGN_UP.ALREADY_REGISTERED' | translate }}</a>
    <span>|</span>
    <a href="https://learn.townsq.io/faq-signup" target="_blank">{{
      'AIS_SIGN_UP.HAVING_TROUBLE' | translate
    }}</a>
  </div>

  <div class="policy-actions">
    <a href="http://privacy.townsq.io" target="_blank">{{
      'AIS_SIGN_UP.PRIVACY_POLICY' | translate
    }}</a>
    <span>|</span>
    <a href="http://terms.townsq.io" target="_blank">{{
      'AIS_SIGN_UP.TERMS_OF_USE' | translate
    }}</a>
  </div>
</div>
