import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'comm-login-password-form',
  templateUrl: './password-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordFormComponent implements OnInit {
  @Input() isLoginIn = false;
  @Input() password = '';

  @Output() loginRequested = new EventEmitter<string>();

  passwordControl: FormControl<string>;

  ngOnInit(): void {
    this.passwordControl = new FormControl(this.password, [Validators.required]);
  }

  onLogin(): void {
    if (this.passwordControl.invalid) {
      return;
    }

    this.loginRequested.emit(this.passwordControl.value);
  }
}
