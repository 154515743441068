import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { getUserFullName, getUserPicture, getUserProperty, User } from '@tsq-web/users';

@Component({
  selector: 'tsq-user-info',
  templateUrl: './user-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserInfoComponent {

  @Input() user: User;

  userPicture = getUserPicture;
  userName = getUserFullName;
  userProperty = getUserProperty;
}
