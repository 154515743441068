<ng-container *ngIf="!!linkData">
  <div class="tsq-card pointer-cursor" (click)="onLinkDataClick()">
    <div class="site-name-tag" *ngIf="!!linkData.siteName">{{linkData.siteName | uppercase}}</div>
    <div class="site-image" [ngStyle]="{'background-image': 'url(\'' + linkData.imageUrl + '\')'}" *ngIf="!!linkData.imageUrl"></div>
    <div [class.no-img-space]="!linkData.imageUrl">
      <a>{{linkData.title}}</a>
      <p>{{linkData.description}}</p>
    </div>
  </div>
</ng-container>
