import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { Observable, combineLatest, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { AppState } from '@tsq-web/state';
import * as fromUserContextSelectors from '@tsq-web/user-context/selectors';

import { ReloginGuardUtil } from '../utils/relogin-guard.util';
import { LoginService } from './login.service';

@Injectable({ providedIn: 'root' })
export class LoginRequiredGuard extends ReloginGuardUtil implements CanActivate {
  constructor(
    protected loginService: LoginService,
    protected cookieService: CookieService,
    protected router: Router,
    protected store: Store<AppState>,
  ) {
    super(loginService, cookieService, router, store);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return combineLatest([
      this.store.select(fromUserContextSelectors.selectUser),
      this.store.select(fromUserContextSelectors.selectAcceptedTerms),
    ]).pipe(
      mergeMap(([user, acceptedTerms]) => {
        if (!acceptedTerms) {
          return of(
            this.router.createUrlTree(['terms-acceptance'], { queryParamsHandling: 'preserve' }),
          );
        }

        return !!user ? of(true) : this.reloginOverRoute(route, state);
      }),
    );
  }
}
