/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import {
  Component,
  ViewChild,
  Output,
  EventEmitter,
  Input,
  ElementRef,
  HostListener,
} from '@angular/core';

import { ModalDirective } from 'ngx-bootstrap/modal';
import { Store } from '@ngrx/store';

import { AppState } from '@tsq-web/state';

@Component({
  selector: 'tsq-center-modal',
  templateUrl: 'tsq-center-modal.component.html',
  styleUrls: ['tsq-center-modal.component.scss'],
})
export class TSqCenterModalComponent {
  @ViewChild('modal', { static: true }) modal: ModalDirective;
  @ViewChild('modalDialog', { static: true }) modalDialog: ElementRef;
  @ViewChild('headerElement') header: ElementRef;
  @ViewChild('footerElement') footer: ElementRef;

  @Input() ignoreBackdropClick = false;
  @Input() ignoreEscClick = false;

  @Input() hasHeader = true;
  @Input() hasFooter = true;
  @Input() width = 800;
  @Input() maxHeight: number | 'auto' = 700;

  @Input() loading = false;
  @Input() disableBackdrop = false;

  @Output() modalHide = new EventEmitter();
  @Output() modalHidden = new EventEmitter();

  private action: any;

  constructor(private store: Store<AppState>) {}

  @Input() set isOpen(value: boolean) {
    if (value) {
      this.show();
    } else {
      this.close();
    }
  }

  @Input() set backdropAction(value: any) {
    if (!!value) {
      this.action = value;
      this.ignoreBackdropClick = true;
      this.ignoreEscClick = true;
    }
  }

  get modalHeight(): string {
    if (!this.maxHeight) {
      return '500px';
    }
    if (typeof this.maxHeight === 'number') {
      return `${this.maxHeight}px`;
    } else {
      return 'auto';
    }
  }

  get contentHeight(): string {
    if (this.maxHeight === 'auto') {
      return 'auto';
    }
    return (this.maxHeight || 500) - this.headerHeight - 1 - this.footerHeight + 'px';
  }

  innerClose() {
    if (!this.disableBackdrop) {
      if (!!this.action) {
        this.store.dispatch(this.action);
      } else {
        this.close();
      }
    }
  }

  modalHideFired(event: any) {
    this.modalHide.emit(event);
  }

  modalHiddenFired(event: any) {
    this.modalHidden.emit(event);
  }

  @HostListener('mousedown', ['$event'])
  onClick(event: any): void {
    if (event.target.classList.contains('modal') && !this.disableBackdrop) {
      if (!!this.action) {
        this.store.dispatch(this.action);
      }
    }
  }

  show() {
    this.modal.show();
  }

  close() {
    this.modal.hide();
  }

  private get headerHeight(): number {
    return !!this.header ? (this.header.nativeElement as Element).clientHeight : 60;
  }

  private get footerHeight(): number {
    return !!this.footer ? (this.footer.nativeElement as Element).clientHeight : 71;
  }
}
