import { AdministratorFeatures } from './administrator-features.model';
import { AdministratorSettings } from './administrator-settings.model';

export class Administrator {
  id: string;
  name: string;
  url: string;
  pictureURL: string;
  requiresDocument: boolean;
  numberOfCondos: number;
  settings: AdministratorSettings;
  features: AdministratorFeatures;
  externalPaymentLinkUrl: string;
  deleted: boolean;
}
