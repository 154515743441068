/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

import { TSqValidators } from '@tsq-web/forms';
import { TSqFileUploaderComponent } from '@tsq-web/files';
import { GlobalVariables } from '../../globals';
import { TSqBaseComment } from '@tsq-web/generic-components';

@Component({
  selector: 'tsq-comment-box',
  templateUrl: 'tsq-comment-box.component.html',
  styleUrls: ['tsq-comment-box.component.scss'],
})
export class TSqCommentBoxComponent implements OnInit {
  @ViewChild('fileUploader', { static: true }) fileUploader: TSqFileUploaderComponent;

  @Input() hasAttachment = true;
  @Input() maxAttachments = 7;
  @Input() error: boolean;

  @Output() saveComment = new EventEmitter<TSqBaseComment>();

  commentTextControl: UntypedFormControl;

  private _sending: boolean;

  ngOnInit() {
    this.commentTextControl = new UntypedFormControl('', [
      Validators.required,
      TSqValidators.hasCharDifferentThanWhitespace,
    ]);
  }

  @Input() set uploadUrl(value: string) {
    if (!!value) {
      this.fileUploader.setupUploader(value, GlobalVariables.FILE_TYPES_DOCS);
    }
  }

  @Input() set sending(value: boolean) {
    if (this.sending && !value && !this.error) {
      this.commentTextControl.reset();
      this.fileUploader.clearFiles();
    }

    this._sending = value;
  }

  get sending(): boolean {
    return this._sending;
  }

  get attachmentsLabel(): string {
    return this.maxAttachments > 1 ? 'ATTACHMENTS' : 'ATTACHMENT';
  }

  get formValid(): boolean {
    return (
      this.commentTextControl.valid && !!this.fileUploader.uploader && !this.fileUploader.uploading
    );
  }

  onSaveComment() {
    this.saveComment.emit({
      text: this.commentTextControl.value,
      attachments: this.fileUploader.files,
    });
  }
}
