import { CanActivateFn, createUrlTreeFromSnapshot } from '@angular/router';

import { map } from 'rxjs';

import { injectFeatureFlagService } from '../dependency-injection/feature-flag';
import { FeatureFlagName } from '../services/feature-flag.service';

export function featureFlagGuard<TFlagsType extends Record<string, boolean | string | number>>(
  flag: FeatureFlagName<TFlagsType, boolean>,
  unauthorizedRedirectPath = '/',
): CanActivateFn {
  return route => {
    const flags = injectFeatureFlagService<TFlagsType>();

    return flags
      .getValueAsync(flag)
      .pipe(
        map(isEnabled => isEnabled || createUrlTreeFromSnapshot(route, [unauthorizedRedirectPath])),
      );
  };
}
