import { Component, OnInit } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { icons } from '@tsq-web/assets';

import { ModalContext } from '../../models/modal-context';
import { ModalService } from '../../services/modal.service';

@UntilDestroy()
@Component({
  selector: 'tsq-generic-modal-close',
  template: `<tsq-icon [icon]="closeIcon" class="pointer-cursor text-primary" (click)="close()" />`,
})
export class TSqGenericModalCloseComponent implements OnInit {
  private context: ModalContext;
  readonly closeIcon = icons.xmark;

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {
    this.modalService.context$
      .pipe(untilDestroyed(this))
      .subscribe(context => (this.context = context));
  }

  close(): void {
    if (!!this.context && !this.context.disableClose) {
      this.modalService.close();
    }
  }
}
