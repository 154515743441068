import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NgControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { IMaskModule } from 'angular-imask';

import { Phone } from '@tsq-web/users';

import { Country } from './models/country.model';
import { countryCodes } from './utils/country-codes';

@Component({
  standalone: true,
  selector: 'comm-intl-phone-input',
  templateUrl: './intl-phone-input.component.html',
  styleUrls: ['./intl-phone-input.component.scss'],
  imports: [CommonModule, IMaskModule, ReactiveFormsModule, TranslateModule],
})
export class IntlPhoneNumberComponent implements OnInit, ControlValueAccessor {
  @Input() displayOnlyPreferredCountries = false;
  @Input() preferredCountryCodes: string[];
  @Input() lengthValidatorEnabled = true;
  @Output() selectedCountryIso: string;

  phoneControl = new FormControl('');
  selectedCountry: Country;
  preferredCountriesList: Country[];
  isDisabled: boolean;
  isDropdownOpen = false;
  isFocused = false;

  onChanged: (phone: Phone) => void;
  onTouched: () => void;

  readonly countryCodes = countryCodes;

  private phoneNumber = '';
  private countryISO: string;

  constructor(@Optional() @Self() public ngControl: NgControl, private elRef: ElementRef) {
    if (ngControl !== null) {
      ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.phoneControl.valueChanges.subscribe(value => {
      this.phoneNumber = value;
      this.onChanged({ number: this.phoneNumber, countryISO: this.countryISO });

      if (!!this.phoneControl.errors) {
        this.ngControl.control.setErrors({ invalid: true });
      } else {
        this.ngControl.control.setErrors(null);
      }
    });

    this.preferredCountriesList = this.countryCodes.filter(country =>
      this.preferredCountryCodes.includes(country.code),
    );

    this.selectedCountry ??= this.countryCodes.find(
      country => country.code === this.defaultSelectedCountryCode,
    );
    this.countryISO = !this.countryISO ? this.selectedCountry.code : this.countryISO;
  }

  @Input() set defaultSelectedCountryCode(code: string) {
    this.selectedCountry ??= this.countryCodes.find(country => country.code == code.toUpperCase());
  }

  get phoneMask(): IMask.AnyMaskedOptions {
    const masks = this.selectedCountry?.mask.map(mask => ({
      mask: mask,
      lazy: true,
    }));

    return {
      mask: masks,
    };
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (!this.elRef.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
    }
  }

  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  onFocus(): void {
    this.isFocused = true;
  }

  onBlur(): void {
    this.isFocused = false;
  }

  selectCountry(country: Country): void {
    this.selectedCountry = country;
    this.countryISO = country.code;

    this.onChanged({
      number: this.phoneNumber,
      countryISO: this.countryISO,
    });

    this.toggleDropdown();
    if (this.lengthValidatorEnabled) {
      this.updateInputValidators();
    }
    this.phoneControl.markAsTouched();
  }

  writeValue(val: Phone): void {
    if (!!val) {
      this.phoneNumber = val.number;
      this.countryISO = val.countryISO;

      const currentCountry = this.countryCodes.find(country => country.code === this.countryISO);
      this.selectedCountry = currentCountry || this.selectedCountry;

      this.phoneControl.setValue(this.phoneNumber);

      if (this.lengthValidatorEnabled) {
        this.updateInputValidators();
      }
    }
  }

  registerOnChange(fn: (val: Phone) => void): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    if (isDisabled) {
      this.phoneControl.disable();
    } else {
      this.phoneControl.enable();
    }
  }

  private updateInputValidators(): void {
    this.phoneControl.setValidators(null);
    const lengthPattern = (this.phoneMask.mask as { mask: string }[])
      .map(({ mask }) => `[\\s\\S]{${mask.length}}`)
      .join('|');
    this.phoneControl.addValidators(Validators.pattern(`^(${lengthPattern})$`));
  }
}
