import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'c3AccountIdFormat' })
export class C3AccountIdFormatPipe implements PipeTransform {

  transform(value: number): string {
    let stringValue = value.toString();

    if (value < 1000000000) {
      stringValue = stringValue.padStart(9, '0');

      return `${ stringValue.substring(0, 5) }-${ stringValue.substring(5) }`;
    }

    return `${ stringValue.substring(0, 3) }-${ stringValue.substring(3, 7) }-${ stringValue.substring(7) }`;
  }
}
