/* eslint-disable @angular-eslint/component-selector */
import { Component, Input } from '@angular/core';

@Component({
  selector: 'tsq-card-for-switch',
  templateUrl: './tsq-card-for-switch.component.html',
  styleUrls: ['./tsq-card-for-switch.component.scss'],
})
export class TsqCardForSwitchComponent {
  @Input() label: string;
  @Input() description: string;
}
