import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Injectable, inject } from '@angular/core';

import { ComponentStore } from '@ngrx/component-store';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { tap } from 'rxjs';

import {
  ColumnWithMetadata,
  TableComputedConfig,
  tableColumnMetadataKey,
} from '../../models/_table.model';
import { TableColumn } from '../../models/table.model';
import { buildColumnWithMetadata, buildTableHeader } from '../../utils/table';

type ColumnsState<TRowData> = {
  left: ColumnWithMetadata<TRowData>[];
  middle: ColumnWithMetadata<TRowData>[];
  right: ColumnWithMetadata<TRowData>[];
};

@Injectable()
export class TableColumnsStore<TRowData> extends ComponentStore<ColumnsState<TRowData>> {
  readonly allColumnsFlat$ = this.select(state => {
    return [...state.left, ...state.middle, ...state.right];
  });

  private readonly visibleColumns$ = this.select<ColumnsState<TRowData>>(state => {
    return {
      left: state.left.filter(c => c[tableColumnMetadataKey].visible),
      middle: state.middle.filter(c => c[tableColumnMetadataKey].visible),
      right: state.right.filter(c => c[tableColumnMetadataKey].visible),
    };
  });

  readonly headers$ = this.select(this.visibleColumns$, state => {
    return [
      ...state.left.map(c => {
        return buildTableHeader(c);
      }),
      ...state.middle.map((c, index) => {
        return buildTableHeader(c, index === state.middle.length - 1);
      }),
      ...state.right.map(c => {
        return buildTableHeader(c);
      }),
    ];
  });

  readonly columns$ = this.select(this.visibleColumns$, state => {
    return [...state.left, ...state.middle, ...state.right];
  });

  readonly setColumns = this.updater<TableColumn<TRowData>[]>((state, value) => {
    const columns: ColumnsState<TRowData> = {
      left: [],
      middle: [],
      right: [],
    };

    if (!value) {
      return columns;
    }

    cloneDeep(value).forEach(column => {
      switch (column.type) {
        case 'actions':
          columns.right.push(buildColumnWithMetadata(column, false, this.translate));
          break;
        default:
          columns.middle.push(buildColumnWithMetadata(column, true, this.translate));
          break;
      }
    });

    return columns;
  });

  readonly reorder = this.updater<CdkDragDrop<ColumnWithMetadata<TRowData>>>((state, event) => {
    const clone = cloneDeep(state);

    moveItemInArray(clone.middle, event.previousIndex, event.currentIndex);

    return clone;
  });

  readonly updateVisibility = this.updater<{ id: string; value: boolean }>((state, event) => {
    return {
      ...state,
      middle: state.middle.map(column => {
        if (column.id === event.id) {
          return {
            ...column,
            [tableColumnMetadataKey]: {
              ...column[tableColumnMetadataKey],
              visible: event.value,
            },
          };
        }

        return column;
      }),
    };
  });

  private readonly notifyColumnsChanged = this.effect(() =>
    this.allColumnsFlat$.pipe(
      tap<ColumnWithMetadata<TRowData>[]>(columns => {
        this.tableSettingsChangeFn?.({
          current: columns.map(column => {
            return {
              columnId: column.id,
              showing: column[tableColumnMetadataKey].visible,
            };
          }),
        });
      }),
    ),
  );

  private tableSettingsChangeFn: TableComputedConfig['tableSettings']['change'] | undefined;

  private readonly translate = inject(TranslateService);

  constructor() {
    super({
      left: [],
      middle: [],
      right: [],
    });
  }

  setTableSettingsChangeFn(value: TableComputedConfig['tableSettings']['change']): void {
    this.tableSettingsChangeFn = value;
  }
}
