import { Component, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';

import { AppState } from '@tsq-web/state';
import { fromUserContextSelectors } from '@tsq-web/user-context';
import { User } from '@tsq-web/users';

import { SignedUpUser } from '../../../shared/user/signed-up-user.json';
import { AisOwner } from '../../models/ais-owner.json';
import { PublicAisService } from '../../services/public-ais.service';

@UntilDestroy()
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ais-link-logged',
  templateUrl: './ais-link-logged.component.html',
  styleUrls: ['./ais-link-logged.component.scss'],
})
export class AisLinkLoggedComponent implements OnInit {
  readonly successCode = 200;

  user: User;

  emailControl: UntypedFormControl;
  understandWarning: boolean;
  isSending: boolean;

  private isConfirming: boolean;

  constructor(
    private translateService: TranslateService,
    private publicAisService: PublicAisService,
    private toastr: ToastrService,
    private router: Router,
    private store: Store<AppState>,
    private analytics: AngularFireAnalytics,
  ) {}

  ngOnInit(): void {
    this.emailControl = new UntypedFormControl({ value: '', disabled: true });

    this.store
      .pipe(select(fromUserContextSelectors.selectUser), untilDestroyed(this))
      .subscribe(user => {
        this.user = user;
        if (!!user) {
          this.emailControl.setValue(user.email);
        }
      });
  }

  get confirming(): boolean {
    return this.isConfirming;
  }

  get linkWarning(): string {
    if (!!this.user) {
      return this.translateService.instant('AIS_SIGN_UP.LINK_WARNING', {
        unit: this.publicAisService.selectedAccount.address.address1,
        acc: this.publicAisService.aisAccRetrieval.account,
        email: this.user.email,
        oldName: `${this.user.first_name} ${this.user.last_name}`,
        newName: `${this.publicAisService.owner.firstName} ${this.publicAisService.owner.lastName}`,
      });
    }
    return '';
  }

  onContinue(): void {
    this.isConfirming = true;
  }

  onCancel(): void {
    this.isConfirming = false;
  }

  onLinkLogged(): void {
    this.isSending = true;

    const aisOwner = new AisOwner();

    aisOwner.tsUserId = this.user.id;
    aisOwner.firstName = this.user.first_name;
    aisOwner.lastName = this.user.last_name;
    aisOwner.email = this.user.email;
    aisOwner.phones = this.publicAisService.owner.phones;
    aisOwner.aisAccountUuid = this.publicAisService.selectedAccount.account.entityUuid;
    aisOwner.aisPersonUuid = this.publicAisService.owner.entityUuid;
    aisOwner.aisUnitUuid = this.publicAisService.selectedAccount.account.unitUuid;
    aisOwner.aisCommunityUuid = this.publicAisService.selectedAccount.communityUuid;

    this.publicAisService
      .linkAccount(aisOwner, this.publicAisService.selectedAccount.aisName)
      .pipe(finalize(() => (this.isSending = false)))
      .subscribe(
        (res: { status: number; data: SignedUpUser }) => {
          this.analytics.logEvent('invites_signup_linked');
          if (res.status === this.successCode) {
            this.router.navigate(['login']);
          } else {
            this.showError();
          }
        },
        () => this.showError(),
      );
  }

  private showError(): void {
    this.toastr.error(this.translateService.instant('AIS_SIGN_UP.LINK_ERROR_DEFAULT'));
  }
}
