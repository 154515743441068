export enum LoginErrorType {
  NONE = 'NONE',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  WRONG_PASSWORD = 'WRONG_PASSWORD',
  UNSUPPORTED_VERSION = 'UNSUPPORTED_VERSION',
  NO_CONDOS = 'NO_CONDOS',
  NO_CONDOS_FOR_RESIDENT = 'NO_CONDOS_FOR_RESIDENT',
  NO_CONDOS_FOR_MANAGER = 'NO_CONDOS_FOR_MANAGER',
  NO_LOGIN = 'NO_LOGIN',
  INTEGRATION = 'INTEGRATION',
  FIRST_LOGIN = 'FIRST_LOGIN',
  UNKNOWN = 'UNKNOWN',
}
