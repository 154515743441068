import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';

import { LetModule } from '@ngrx/component';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, combineLatest, map } from 'rxjs';

import { TooltipTruncatedDirective } from '../../directives/tooltip-truncated/tooltip-truncated.directive';
import { TableColumnText } from '../../models/table.model';
import { TableCellBaseComponent } from '../table-cell-base/table-cell-base.component';

@Component({
  standalone: true,
  imports: [AsyncPipe, NgClass, NgIf, LetModule, TableCellBaseComponent, TooltipTruncatedDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'tsq-table-cell-text',
  template: `
    <tsq-table-cell-base
      *ngrxLet="content$ as content"
      [align]="content.align"
      [tsqTooltipTruncated]="el"
    >
      <div
        *ngIf="highlight"
        class="size-8 shrink-0 rounded-full"
        style="background-color: #0086ea;"
      ></div>
      <div class="text-coal-secondary truncate" [ngClass]="{ 'font-bold': highlight }" #el>
        {{ text$ | async }}
      </div>
    </tsq-table-cell-base>
  `,
  styles: [
    `
      :host {
        @apply min-w-0;
      }
    `,
  ],
})
export class TableCellTextComponent<TRowData> {
  @Input() highlight: boolean;

  private readonly contentSubject = new BehaviorSubject<
    TableColumnText<TRowData>['content'] | undefined
  >(undefined);
  private readonly rowDataSubject = new BehaviorSubject<TRowData | undefined>(undefined);
  readonly content$ = this.contentSubject.asObservable();

  private readonly translate = inject(TranslateService);

  readonly text$: Observable<string | undefined> = combineLatest([
    this.content$,
    this.rowDataSubject.asObservable(),
  ]).pipe(
    map(([content, rowData]) => {
      if (!content || !rowData) {
        return undefined;
      }

      if (typeof content.value === 'function') {
        return content.value(rowData, { translate: this.translate });
      } else if ('key' in content.value) {
        const cellData = rowData[content.value.key];

        if (typeof content.value.transform === 'function') {
          return content.value.transform(cellData, { translate: this.translate });
        }

        return typeof cellData === 'string' ? cellData : undefined;
      }

      return undefined;
    }),
  );

  @Input()
  set content(value: TableColumnText<TRowData>['content']) {
    this.contentSubject.next(value);
  }

  @Input()
  set rowData(value: TRowData) {
    this.rowDataSubject.next(value);
  }
}
