<ng-container *ngIf="isAndroid && shouldShow">
  <comm-native-app-banner *ngIf="!prompt" (linkClicked)="onOpenClick()">
    <span class="font-bold">{{ 'NATIVE_APP_BANNER.APP_NAME' | translate }}</span>
    <span class="text-sm text-coal-secondary">{{ 'NATIVE_APP_BANNER.OPEN_IN_APP' | translate }}</span>
  </comm-native-app-banner>

  <ng-container *ngIf="!!prompt">
    <comm-native-app-banner [hasClose]="true"
                            [linkText]="'show'"
                            (linkClicked)="onInstallClick()"
                            (closeBanner)="shouldShow = false">
      <span class="font-bold">{{ 'NATIVE_APP_BANNER.APP_NAME' | translate }}</span>
      <span class="text-sm text-coal-secondary">{{ 'NATIVE_APP_BANNER.SOCIALCONDO' | translate }}</span>
      <div class="flex items-center">
        <img *ngFor="let _ of starsArray; let last = last"
             src="assets/icons/star-{{ last ? 'half' : 'full' }}.svg"
             alt="Rating {{ last ? 'half' : 'full' }} star">
      </div>
      <span class="text-sm text-coal-secondary">{{ 'NATIVE_APP_BANNER.GET_PLAY_STORE' | translate }}</span>
    </comm-native-app-banner>

    <comm-native-app-download-button (download)="onInstallClick()"
                                     (closeButton)="shouldShow = false"></comm-native-app-download-button>
  </ng-container>
</ng-container>
