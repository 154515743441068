import { Injectable } from '@angular/core';

import { Multiplier } from '../plans/multiplier.enum';
import { CondoSubscription } from './condo-subscription.json';

@Injectable()
export class SubscriptionChargeUtilService {
  getTotalSubscriptionValue(subscription: CondoSubscription, multiplier: number): number {
    if (subscription.chargeValue) {
      return this.calculateTotalPriceByChargeValue(subscription);
    }

    return subscription.plan ? this.calculateTotalPriceByPlan(subscription, multiplier) : 0;
  }

  private calculateTotalPriceByPlan(subscription: CondoSubscription, multiplierNumber: number): number {
    const multiplier = subscription.plan.multiplier !== Multiplier[Multiplier.NONE] ? multiplierNumber : 1;
    const planPrice = (subscription.plan.price.amount / 100) * multiplier;
    const fixedCharges = this.fixedCharge(subscription);
    const basePrice = planPrice + (subscription.featurePackages.length > 0 ? this.totalPackagesPrice(subscription) : 0) + fixedCharges;

    return this.calculateTotalPrice(subscription, basePrice);
  }

  private calculateTotalPriceByChargeValue(subscription: CondoSubscription): number {
    const basePrice = subscription.chargeValue;

    return this.calculateTotalPrice(subscription, basePrice);
  }

  private calculateTotalPrice(subscription: CondoSubscription, basePrice: number): number {
    const percentageDiscount = (subscription.discounts.length > 0 && this.totalPercentageDiscount(subscription) > 0) ?
      (this.totalPercentageDiscount(subscription) / 100.0) : undefined;
    const rawValueDiscount = (subscription.discounts.length > 0 && this.totalRawValueDiscount(subscription) > 0) ?
      this.totalRawValueDiscount(subscription) : undefined;

    return (basePrice * (percentageDiscount ? (1 - percentageDiscount) : 1)) - (rawValueDiscount ? rawValueDiscount : 0);
  }

  fixedCharge(subscription: CondoSubscription): number {
    const chargeValue = subscription.fixedCharges ? subscription
      .fixedCharges
      .map(charge => charge.price.amount)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0) : 0;

    return chargeValue / 100.00;
  }

  totalPackagesPrice(subscription: CondoSubscription): number {
    return subscription.featurePackages
      .map(featurePackage => featurePackage.price ? featurePackage.price.amount / 100 : 0)
      .reduce((value, p) => p + value, 0);
  }

  totalPercentageDiscount(subscription: CondoSubscription): number {
    return subscription.discounts
      .map(discount => discount.percentage ? +discount.percentage : 0)
      .reduce((value, p) => p + value, 0);
  }

  totalRawValueDiscount(subscription: CondoSubscription): number {
    return subscription.discounts
      .map(discount => discount.rawValue ? discount.rawValue : 0)
      .reduce((value, p) => p + value, 0);
  }

}
