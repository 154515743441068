import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  Renderer2,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { FormControl, NgControl, UntypedFormControl } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';

import { getErrorMessageFromValidationErrors } from '../../utils/validators/tsq-validators.utils';

@UntilDestroy()
@Directive()
export abstract class AbstractInputDirective implements OnDestroy {
  @Input() errorMessage: string;
  @Input() focusOnInit = false;

  protected control: FormControl<string>;
  protected wrapperEl: HTMLElement;
  protected errorEl: HTMLSpanElement;
  protected readonly el = inject(ElementRef<HTMLInputElement | HTMLTextAreaElement>).nativeElement;
  protected readonly ngControl = inject(NgControl, { self: true, optional: true });
  protected readonly viewContainerRef = inject(ViewContainerRef);
  protected readonly renderer = inject(Renderer2);

  private parentEl: HTMLElement;
  private readonly translateService = inject(TranslateService);

  afterViewInit(): void {
    this.wrapElement();
    this.initializeControl();
  }

  ngOnDestroy(): void {
    this.renderer.removeChild(this.parentEl, this.wrapperEl);
  }

  @HostListener('focusout') private onFocusOut(): void {
    this.updateControl();
  }

  focus(): void {
    this.el?.focus();
  }

  updateErrorLabel(): void {
    if (this.control?.touched && this.control?.invalid) {
      this.addErrorLabel();
    } else if (!!this.errorEl) {
      this.renderer.removeChild(this.wrapperEl, this.errorEl);
      this.errorEl = undefined;
    }
  }

  abstract updateControl(): void;

  protected initializeControl(): void {
    if (!!this.ngControl) {
      this.control = this.ngControl.control as UntypedFormControl;

      this.control.registerOnChange(() => setTimeout(() => this.updateControl()));
      this.control.registerOnDisabledChange(() => this.updateControl());
      this.control.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this.updateControl());
    }

    this.updateControl();

    if (this.focusOnInit) {
      this.el?.focus();
    }
  }

  protected wrapElement(): void {
    this.parentEl = this.renderer.parentNode(this.el);

    this.wrapperEl = this.renderer.createElement('div');
    'relative text-input-wrapper'
      .split(' ')
      .forEach(className => this.renderer.addClass(this.wrapperEl, className));

    this.renderer.insertBefore(this.parentEl, this.wrapperEl, this.el);
    this.renderer.removeChild(this.parentEl, this.el);
    this.renderer.appendChild(this.wrapperEl, this.el);
  }

  protected addErrorLabel(): void {
    if (!this.errorEl) {
      this.errorEl = this.renderer.createElement('span') as HTMLSpanElement;
      this.errorEl.setAttribute('data-cy', 'forms--span--error');
      this.renderer.appendChild(this.wrapperEl, this.errorEl);
    }
    const validationError = getErrorMessageFromValidationErrors(this.control?.errors);

    this.errorEl.textContent =
      this.errorMessage ||
      (!!validationError ? this.translateService.instant(...validationError) : '');
  }
}
