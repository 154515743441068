import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  Renderer2,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { NgControl, UntypedFormControl } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { IconComponent, icons } from '@tsq-web/assets';
import { environment } from '@tsq-web/environment';
import { ThemeService } from '@tsq-web/ui';

@UntilDestroy()
@Directive({
  selector: 'input[type=checkbox][tsqCheckbox]',
})
export class CheckboxDirective implements AfterViewInit {
  private checkboxEl: HTMLDivElement;
  private iconEl: HTMLElement;

  private control: UntypedFormControl;

  private readonly theme = inject(ThemeService);
  private primaryColor = this.theme.fullTailwindConfig.theme.colors.primary.DEFAULT;
  private outlineColor = this.primaryColor;

  private readonly ngControl = inject(NgControl, { optional: true, self: true });
  private readonly el = inject<ElementRef<HTMLInputElement>>(ElementRef).nativeElement;
  private readonly renderer = inject(Renderer2);
  private readonly vcRef = inject(ViewContainerRef);

  ngAfterViewInit(): void {
    if (!!this.ngControl) {
      this.control = this.ngControl.control as UntypedFormControl;

      this.buildCheckbox();

      this.control.registerOnDisabledChange(() => this.updateState());
      this.control.registerOnChange(() => this.updateState());
      this.control.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this.updateState());

      this.updateState();
    } else if (!environment.production) {
      console.warn(
        'tsqCheckbox directive needs a Form Control so it can listen to changes and behave as expected.' +
          '\n' +
          "You didn't provide one, so tsqCheckbox won't initialise for this element.",
      );
    }
  }

  @Input() set schema(schema: 'primary' | 'new-primary') {
    if (schema === 'new-primary') {
      const newPrimary = this.theme.fullTailwindConfig.theme.colors['new-primary'];
      this.primaryColor = newPrimary.p2;
      this.outlineColor = newPrimary.DEFAULT;
    }
  }

  private buildCheckbox(): void {
    this.renderer.setAttribute(this.el, 'role', 'checkbox');
    [
      ['width', '18px'],
      ['height', '18px'],
      ['position', 'absolute'],
      ['left', '50%'],
      ['top', '50%'],
      ['transform', 'translate(-50%, -50%)'],
      ['z-index', '10'],
      ['appearance', 'none'],
      ['border-radius', '2px'],
      ['outline-offset', '2px'],
      ['margin', '0'],
      ['outline-color', this.outlineColor],
    ].forEach(([style, value]) => this.renderer.setStyle(this.el, style, value));

    const parent = this.renderer.parentNode(this.el);
    const wrapper = this.renderer.createElement('div');
    [
      ['width', '24px'],
      ['height', '24px'],
      ['display', 'flex'],
      ['align-items', 'center'],
      ['justify-content', 'center'],
      ['position', 'relative'],
    ].forEach(([style, value]) => this.renderer.setStyle(wrapper, style, value));
    this.checkboxEl = this.renderer.createElement('div');
    [
      ['width', '18px'],
      ['height', '18px'],
      ['border-radius', '2px'],
      ['border-width', '2px'],
      ['border-color', this.primaryColor],
      ['background-color', 'white'],
      ['position', 'relative'],
    ].forEach(([style, value]) => this.renderer.setStyle(this.checkboxEl, style, value));
    const iconRef = this.vcRef.createComponent(IconComponent);
    iconRef.setInput('icon', icons.check);
    iconRef.setInput('classes', 'size-[18px]');
    this.iconEl = iconRef.location.nativeElement as HTMLElement;
    [
      ['color', 'white'],
      ['display', 'none'],
      ['cursor', 'pointer'],
      ['position', 'absolute'],
      ['left', '50%'],
      ['top', '50%'],
      ['transform', 'translate(-50%, -50%)'],
    ].forEach(([style, value]) => this.renderer.setStyle(this.iconEl, style, value));

    this.renderer.insertBefore(parent, wrapper, this.el);
    this.renderer.appendChild(wrapper, this.el);
    this.renderer.appendChild(wrapper, this.checkboxEl);
    this.renderer.appendChild(this.checkboxEl, this.iconEl);
  }

  private updateState(): void {
    this.renderer.setAttribute(this.el, 'aria-checked', this.control.value);

    if (this.control.disabled) {
      const petroN2 = this.theme.fullTailwindConfig.theme.colors.petro.n2;
      this.renderer.setStyle(this.el, 'cursor', 'not-allowed');
      [
        ['border-color', petroN2],
        ['background-color', petroN2],
      ].forEach(([style, value]) => this.renderer.setStyle(this.checkboxEl, style, value));

      if (this.control.value) {
        this.renderer.setStyle(this.iconEl, 'display', 'block');
      } else {
        this.renderer.setStyle(this.iconEl, 'display', 'none');
      }
    } else {
      this.renderer.removeStyle(this.el, 'cursor');
      [
        ['border-color', this.primaryColor],
        ['background-color', 'white'],
      ].forEach(([style, value]) => this.renderer.setStyle(this.checkboxEl, style, value));

      if (this.control.value) {
        this.renderer.setStyle(this.checkboxEl, 'background-color', this.primaryColor);
        this.renderer.setStyle(this.iconEl, 'display', 'block');
      } else {
        this.renderer.setStyle(this.iconEl, 'display', 'none');
        this.renderer.setStyle(this.checkboxEl, 'background-color', 'white');
      }
    }
  }
}
