import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  Optional,
  Renderer2,
  Self,
} from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: 'input[tsqInput],textarea[tsqInput]',
})
export class TSqInputDirective implements AfterViewInit, OnDestroy {
  @Input() errorMessage = 'MANDATORY_FIELD_ERROR_LABEL';

  private control: AbstractControl;

  private errorEl: Element;

  private directiveDestroyed$ = new Subject<void>();

  constructor(
    private renderer: Renderer2,
    private elRef: ElementRef,
    @Optional() @Self() private ngControl: NgControl,
    private translateService: TranslateService,
  ) {}

  ngAfterViewInit(): void {
    if (!!this.ngControl) {
      this.control = this.ngControl.control;
      this.wrapFormControl();

      this.control.valueChanges
        .pipe(takeUntil(this.directiveDestroyed$))
        .subscribe(() => this.checkAbstractControl());
    } else {
      console.warn(
        'tsqInput directive needs an Abstract Control so it can listen to changes and behave as expected.' +
          '\n' +
          "You didn't provide one, so tsqInput won't initialise for this element.",
      );
    }
  }

  ngOnDestroy(): void {
    this.directiveDestroyed$.next();
    this.directiveDestroyed$.unsubscribe();
  }

  @HostListener('focusout', ['$event'])
  onFocusOut(event: Event): void {
    this.checkAbstractControl();
    event.stopPropagation();
  }

  private checkAbstractControl(): void {
    if (this.control.touched && this.control.invalid) {
      this.addErrorLabel();
    } else {
      this.removeErrorLabel();
    }
  }

  private wrapFormControl(): void {
    const el = this.elRef.nativeElement;
    const parent = this.renderer.parentNode(el);
    const wrapper = this.renderer.createElement('div');
    this.renderer.insertBefore(parent, wrapper, el);
    this.renderer.removeChild(parent, el);
    this.renderer.appendChild(wrapper, el);
  }

  private addErrorLabel(): void {
    if (!this.errorEl) {
      const el = this.elRef.nativeElement;
      const parent = this.renderer.parentNode(el);
      this.errorEl = this.renderer.createElement('div');
      this.renderer.addClass(this.errorEl, 'input-error-label');
      this.renderer.appendChild(
        this.errorEl,
        this.renderer.createText(this.translateService.instant(this.errorMessage)),
      );
      this.renderer.appendChild(parent, this.errorEl);
    }
  }

  private removeErrorLabel(): void {
    if (!!this.errorEl) {
      const el = this.elRef.nativeElement;
      const parent = this.renderer.parentNode(el);
      this.renderer.removeChild(parent, this.errorEl);
      this.errorEl = null;
    }
  }
}
