<h1 class="leading-tight">{{ 'RECOVERY.INVALID_LINK' | translate }}</h1>

<p class="text-md font-semi-bold mb-16 mt-8 leading-normal">
  {{ 'RECOVERY.INVALID_LINK_DESCRIPTION' | translate }}
</p>
<p class="text-coal-secondary mb-40 mt-16 text-base leading-normal">
  {{ 'RECOVERY.INVALID_LINK_SMALL' | translate }}
</p>

<tsq-button
  [text]="'RECOVERY.BACK_TO_LOGIN' | translate"
  schema="new-primary"
  size="lg"
  route="login"
/>
