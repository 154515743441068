import { ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core';

import { icons } from '@tsq-web/assets';

import { AbstractButtonDirective } from '../../utils/button/abstract-button.directive';

@Component({
  selector: 'tsq-filter-button',
  template: `
    <button
      type="button"
      [attr.schema]="'primary'"
      [attr.kind]="kind"
      [attr.size]="'md'"
      [attr.hasText]="!!text"
      [disabled]="disabled"
      (click)="clicked.emit()"
    >
      <tsq-icon *ngIf="!!icon" [icon]="icon" [classes]="'w-24 h-24'"></tsq-icon>

      <span *ngIf="!!text" [class.ml-8]="!!icon">{{ text | translate }}</span>

      <span
        *ngIf="!!toDisplayCount"
        class="relative ml-8 h-24 w-24 rounded-full"
        [ngClass]="{ 'text-white': kind === 'secondary' }"
      >
        <span class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-xs">
          {{ toDisplayCount }}
        </span>
      </span>
    </button>
  `,
  styleUrls: ['./filter-button.component.scss', '../../utils/button/button.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterButtonComponent extends AbstractButtonDirective {
  @Input() icon = icons.filter;
  @Input() text = 'LIBS.FILTER';
  @Input() count: number;
  @Input() kind: 'primary' | 'secondary' = 'primary';

  constructor(private elRef: ElementRef<Element>) {
    super(elRef.nativeElement);
  }

  get toDisplayCount(): string {
    if (!this.count) {
      return null;
    }

    return this.count < 100 ? this.count.toString(10) : '+99';
  }
}
