import { AfterViewInit, Directive, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: 'label[tsqOptionalLabel]',
})
export class OptionalLabelDirective implements OnChanges, AfterViewInit {
  @Input('tsqOptionalLabel') isActive: boolean;

  private readonly el: HTMLLabelElement;
  private optionalEl: HTMLSpanElement;

  constructor(
    private elRef: ElementRef<HTMLLabelElement>,
    private renderer: Renderer2,
    private translateService: TranslateService,
  ) {
    this.el = elRef.nativeElement;
  }

  ngOnChanges(): void {
    if (!!this.optionalEl) {
      this.renderer.setStyle(
        this.optionalEl,
        'display',
        this.isActive === false ? 'none' : 'block',
      );
    }
  }

  ngAfterViewInit(): void {
    this.wrapLabel();
  }

  private wrapLabel(): void {
    const parent = this.renderer.parentNode(this.el);

    const wrapper = this.renderer.createElement('div');
    this.renderer.addClass(wrapper, 'label-wrapper');

    this.renderer.insertBefore(parent, wrapper, this.el);
    this.renderer.removeChild(parent, this.el);
    this.renderer.appendChild(wrapper, this.el);

    this.optionalEl = this.renderer.createElement('span');
    this.optionalEl.textContent = this.translateService.instant('LIBS.OPTIONAL');
    this.renderer.appendChild(wrapper, this.optionalEl);

    if (this.isActive === false) {
      this.renderer.setStyle(this.optionalEl, 'display', 'none');
    }
  }
}
