/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Store } from '@ngrx/store';

import { AppState } from '@tsq-web/state';

@Component({
  selector: 'comm-confirmation-modal',
  templateUrl: 'tsq-confirmation-modal.component.html',
  styleUrls: ['tsq-confirmation-modal.component.scss'],
})
export class TSqConfirmationModalComponent {
  @Input() isOpen: boolean;
  @Input() loading: boolean;

  @Input() headerImg = 'robot-error-white-border';
  @Input() message: string;

  @Input() confirmationLabel = 'SHARED.TSQ_DEFAULT_CONFIRMATION_CONFIRMATION';
  @Input() cancelLabel = 'SHARED.TSQ_DEFAULT_CANCEL_CONFIRMATION';

  @Input() backdropAction: any;

  @Output() confirm = new EventEmitter();

  constructor(private store: Store<AppState>) {}

  get imgUrl(): string {
    return `./assets/img/icons/${this.headerImg}.png`;
  }

  cancelAction() {
    this.store.dispatch(this.backdropAction);
  }

  confirmAction() {
    this.confirm.emit();
  }
}
