/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { LinkData } from '@tsq-web/files';

@Component({
  selector: 'tsq-link-data-card',
  templateUrl: 'tsq-link-data-card.component.html',
  styleUrls: ['tsq-link-data-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TSqLinkDataCardComponent {
  @Input() linkData: LinkData;

  onLinkDataClick() {
    window.open(this.linkData.url, '_blank');
  }
}
