<div class="font-base flex flex-wrap gap-8" role="listbox">
  <button
    *ngFor="let option of options; index as index"
    class="text-primary border-1 border-petro-n2 hover:bg-primary-n1 aria-selected:bg-primary aria-selected:hover:bg-primary-p1 rounded-full bg-white px-16 py-4 font-bold leading-normal transition focus:outline aria-selected:text-white"
    role="option"
    [attr.aria-selected]="isSelected(index)"
    (click)="onChipSelected(index)"
  >
    {{ option }}
  </button>
</div>
