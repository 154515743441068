/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'tsq-main-info-card',
  templateUrl: 'tsq-main-info-card.component.html',
  styleUrls: ['tsq-main-info-card.component.scss'],
})
export class TsqMainInfoCardComponent {
  @Input() title = '';
  @Input() titleColor: 'battleship-grey' | 'avocado' = 'battleship-grey';
  @Input() subtitle = '';
  @Input() tags: string[] = [];
  @Output() onTitleClicked = new EventEmitter<string>();

  titleClicked() {
    this.onTitleClicked.emit();
  }
}
