<div [ngClass]="columnView ? 'grid grid-cols-3 gap-8' : 'flex flex-col gap-8'">

  <div *ngFor="let file of files; let i = index"
       class="flex gap-8 border-1 border-petro-n2 rounded p-8"
       [id]="'comment-files--div--file--' + i">

    <div class="grow flex gap-8 truncate">
      <div class="bg-petro-n1 p-8 rounded w-40 h-40">
        <tsq-icon [icon]="fileExtension(file.filenameExt)"
                  [classes]="'text-coal-tertiary'"></tsq-icon>
      </div>

      <div class="flex flex-col truncate">
        <span class="font-bold truncate">{{ file.name }}</span>
        <span>{{ getFileSizeInHumanFormat(file.size) }}</span>
      </div>
    </div>

    <button (click)="delete.emit(i)">
      <tsq-icon [icon]="icons.trash" [classes]="'text-red'"></tsq-icon>
    </button>

  </div>

</div>
