import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { documentFileTypes, TSqFileUploaderComponent } from '@tsq-web/files';
import { TSqCommentPresentation } from '../../models/tsq-comment-presentation.model';

@Component({
  selector: 'tsq-edit-comment-form',
  templateUrl: './edit-comment-form.component.html',
  styleUrls: ['./edit-comment-form.component.scss'],
})
export class EditCommentFormComponent implements AfterViewInit {
  @ViewChild('fileUploader', { static: true }) fileUploader: TSqFileUploaderComponent;

  @Input() form: UntypedFormGroup;
  @Input() fileUploadURL: string;
  @Input() editCommentIsLoading: boolean;
  @Input() comment: TSqCommentPresentation;

  @Output() closeModal = new EventEmitter();
  @Output() editComment = new EventEmitter<TSqCommentPresentation>();

  ngAfterViewInit(): void {
    this.fileUploader.setupUploader(this.fileUploadURL, documentFileTypes);

    this.fileUploader.setupFiles(this.form.controls.attachments.value);
  }

  saveForm(): void {
    const newComment = {
      ...this.comment,
      id: this.form.controls.id.value,
      attachments: this.fileUploader.files,
      text: this.form.controls.comment.value,
    };

    this.editComment.emit(newComment);
  }
}
