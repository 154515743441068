<tsq-delete-comment-modal
  [adminDeleteCommentIsSending]="adminDeleteCommentIsSending$ | async"
  (reason)="onAdminDeleteComment(selectedComment, $event)"
></tsq-delete-comment-modal>
<tsq-edit-comment-modal
  [fileUploadURL]="commentAttachmentUploadUrl"
  [commentForm]="selectedComment"
  [editCommentIsLoading]="
    selfHandleComments ? (editCommentIsSending$ | async) : editCommentIsLoading
  "
  (editComment)="onEditComment($event)"
></tsq-edit-comment-modal>
<tsq-delete-modal
  [deleteIsLoading]="
    selfHandleComments ? (deleteCommentIsSending$ | async) : deleteCommentIsLoading
  "
  [confirmActionIcon]="icons.trash"
  [description]="'LIBS.COMMENTS.DELETE_COMMENT_DESCRIPTION'"
  [title]="'LIBS.COMMENTS.DELETE_COMMENT'"
  [confirmActionText]="'LIBS.COMMENTS.DELETE'"
  (confirmDelete)="onDeleteComment(selectedComment)"
></tsq-delete-modal>
<ng-template #modalRef>
  <div class="modal-header">
    <div>
      <img src="./assets/libs/icons/send.svg" class="filter-text-primary" alt="" />
      <span>{{ 'LIBS.SEND_COMMENT' | translate }}</span>
    </div>
  </div>
  <div class="modal-body-content">
    <ng-container *ngTemplateOutlet="approvalTemplate; context: approvalContext"></ng-container>
  </div>
  <div class="modal-footer">
    <tsq-generic-modal-cancel-button></tsq-generic-modal-cancel-button>
    <button class="btn btn-primary" (click)="onSaveComment(approvalContext, true)">
      {{ 'LIBS.SEND' | translate }}
    </button>
  </div>
</ng-template>

<div
  [ngClass]="loading || error ? 'gridless' : 'tsq-feature-wrapper'"
  [ngStyle]="!loading && !error ? { 'grid-template-columns': templateColumns } : {}"
  infiniteScroll
  tsqListInsufficientItems
  [infiniteScrollContainer]="scrollableContainer"
  [scrollWindow]="false"
  [fromRoot]="true"
  [infiniteScrollDistance]="0.5"
  [infiniteScrollUpDistance]="3.0"
  [infiniteScrollThrottle]="20"
  [existingItems]="!!comments ? comments.length : 0"
  [scrollableContainer]="scrollableContainer"
  (scrolled)="onScroll()"
  (loadMore)="onScroll()"
>
  <ng-container *ngIf="!loading && !error">
    <div [style.padding-bottom.px]="stickyFooterWrapperHeight + 16" #detailsWrapper>
      <tsq-feature-details
        [details]="details"
        [detailsTemplate]="detailsTemplate"
        [showCommentList]="showCommentList"
        [clickableUrl]="clickableUrl"
      >
        <tsq-comment-list
          [title]="commentsConfig?.listTitle"
          [zeroDataDescription]="commentsConfig?.zeroData"
          [length]="comments && comments.length"
          [loading]="commentsLoading"
          [loadingMore]="commentsLoadingMore"
          [error]="commentsError"
          (retry)="retryComments.emit()"
        >
          <tsq-comment
            *ngFor="let comment of comments; trackBy: commentTrackBy"
            [comment]="comment"
            [editable]="editable"
            [deletable]="deletable"
            [user]="user"
            [isAdmin]="isAdmin"
            [adminDeleteComments]="adminDeleteComments"
            [clickableUrl]="clickableUrl"
            (editComment)="onOpenEditCommentModal($event)"
            (deleteComment)="onOpenDeleteCommentModal($event)"
          ></tsq-comment>
        </tsq-comment-list>
      </tsq-feature-details>
    </div>

    <div>
      <ng-content></ng-content>
    </div>
  </ng-container>

  <tsq-loader *ngIf="loading"></tsq-loader>
  <tsq-error *ngIf="error && !loading" (retry)="retry.emit()"></tsq-error>
</div>

<div
  class="sticky-footer"
  *ngIf="!loading && !error && showFooter"
  [style.width.px]="detailsWrapperWidth"
  #stickyFooterWrapper
>
  <ng-content select="[footer-extra-info]" />

  <ng-container *ngIf="!shouldShowCreateCommentTextArea; else commentTextArea">
    <ng-container *ngIf="!!createCommentPlaceholder; else defaultCreateCommentPlaceholder">
      <ng-container
        *ngTemplateOutlet="createCommentPlaceholder; context: createCommentPlaceholderContext"
      />
    </ng-container>

    <ng-template #defaultCreateCommentPlaceholder>
      <tsq-create-comment-placeholder (focused)="shouldShowCreateCommentTextArea = true" />
    </ng-template>
  </ng-container>

  <ng-template #commentTextArea>
    <tsq-create-comment-v2
      [sending]="sendingComment"
      [error]="errorSendingComment"
      [fileExtensions]="commentFileExtensions"
      [uploadUrl]="commentAttachmentUploadUrl"
      [hasAttachments]="commentHasAttachments"
      [maxAttachments]="maxAttachments"
      [isSingleLine]="singleLineComment"
      [extraActionsTemplate]="extraActionsTemplate"
      [required]="commentRequired"
      [commentContent]="commentContent"
      (saveComment)="onSaveComment($event, false)"
      (commentSent)="commentSent.emit(); shouldShowCreateCommentTextArea = false"
      (focusOut)="shouldShowCreateCommentTextArea = $event"
    />
  </ng-template>
</div>
