import { ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core';
import { ActivatedRoute, Params, QueryParamsHandling } from '@angular/router';

import { AbstractButtonDirective } from '../../utils/button/abstract-button.directive';

@Component({
  selector: 'tsq-button',
  templateUrl: './button.component.html',
  styleUrls: ['../../utils/button/button.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent extends AbstractButtonDirective {
  @Input() type: 'submit' | 'button' | 'reset' = 'button';

  @Input() schema: 'new-primary' | 'primary' | 'kale' | 'red' = 'primary';
  @Input() kind: 'primary' | 'secondary' | 'tertiary' = 'primary';
  @Input() size: 'sm' | 'md' | 'lg' = 'md';

  @Input() route: string[] | string | null | undefined;
  @Input() relative: boolean;
  @Input() queryParams: Params;
  @Input() queryParamsHandling: QueryParamsHandling;
  @Input() fragment: string;
  @Input() preserveFragment: boolean;

  @Input() href: string;
  @Input() target: '_blank' | '_parent' | '_self' | '_top' = '_blank';

  constructor(public activatedRoute: ActivatedRoute, private elRef: ElementRef<Element>) {
    super(elRef.nativeElement);
  }

  get isLink(): boolean {
    return !!this.route || !!this.queryParams || !!this.fragment || !!this.href;
  }

  get iconClass(): string {
    const isNewPrimaryAndSm = this.schema === 'new-primary' && this.size === 'sm';
    return isNewPrimaryAndSm ? 'size-16' : 'size-24'
  }

  onAnchorClick(): void {
    if (this.disabled) {
      return;
    }
    this.clicked.emit();
  }
}
