<div *ngFor="let group of groups" class="group-item" (click)="onGroupClicked(group)">
  <div
    class="select-field"
    [ngClass]="{
      active: isGroupSelected(group) && !disabled,
      'disabled bg-petro-n2': disabled,
      'border-l-petro-n2 bg-white': !isGroupSelected(group) && disabled
    }"
  >
    <img src="./assets/img/icons/selected-white.svg" alt="" />
  </div>

  <div
    class="group-icon"
    [ngClass]="{ 'bg-petro-n2': disabled }"
    [ngStyle]="{ backgroundColor: !disabled ? group.color : '' }"
  >
    <i class="{{ 'fa ' + group.icon }}" aria-hidden="true"></i>
  </div>

  <div class="info">
    <span>{{ group.name }}</span>
    <span>{{ getGroupCount(group.count) }}</span>
  </div>
</div>
