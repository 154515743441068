<div id="list">
  <form [formGroup]="form" class="form-group">
    <div class="panel panel-transparent">
      <div class="panel-heading">{{ 'SUBSCRIPTION.CHARGE_DATE' | translate }}</div>
      <div class="panel-body">

        <div class="row" >
          <div class="col-xs-4">
            <label for="charge-date">{{ 'SUBSCRIPTION.DATE' | translate }}</label>
            <tsq-datepicker id="charge-date"
                            [date]="nextChargeDate"
                            (dateChange)="handleChargeDate($event)"
                            [minDate]="minDate">
            </tsq-datepicker>
          </div>
        </div>

      </div>
    </div>
  </form>
</div>
