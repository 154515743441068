<div
  tabindex="0"
  class="flex"
  [ngClass]="{
    'border-1 border-petro-n2 mb-8 items-center gap-8 rounded p-8 focus:outline': itemTemplate,
    'cursor-pointer px-16 py-8': !itemTemplate
  }"
  [class.cursor-not-allowed]="disabled"
  [class.mb-8]="!last"
  *ngFor="let option of options; last as last"
  (keyup.Space)="onSelected(option)"
  (keyup.enter)="onSelected(option)"
  (click)="onSelected(option)"
>
  <div
    class="checkbox mr-8 shrink-0"
    [attr.disabled]="disabled ? true : null"
    [attr.selected]="isSelected(option)"
  ></div>
  <ng-container *ngIf="!itemTemplate; else itemTemplateContainer">
    <span [class.text-red-n1]="disabled" [innerHTML]="option.text"></span>
  </ng-container>
  <ng-template #itemTemplateContainer>
    <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: option }" />
  </ng-template>
</div>
