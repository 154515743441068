import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/operators';

import { BaseService } from '@tsq-web/core';
import { UserRecovery } from '../models/user-recovery.model';

@Injectable()
export class UserRecoveryService extends BaseService {

  constructor(protected cookieService: CookieService,
              protected httpClient: HttpClient,
              protected router: Router) {
    super(cookieService, httpClient, router);
  }

  requestRecovery(email: string): Observable<{ status: number, data: string }> {
    const user = { username: email };

    return this.httpClient
      .post<string>(BaseService.getFullUrl(`forgotpassword/mail`), this.prepareBody(user), this.options)
      .pipe(
        map(this.extractDataAndStatus),
      );
  }

  submitRecovery(recovery: UserRecovery): Observable<string> {
    return this.doPost(`forgotpassword/recovery`, this.prepareBody(recovery));
  }
}
