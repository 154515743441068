<div id="date-picker">
  <input bsDatepicker #dp="bsDatepicker" type="text" class="form-control has-icon"
         onkeydown="return false"
         dateInputFormat="MMMM YYYY"
         [placement]="placement"
         [placeholder]="'DATE'| translate"
         [minDate]="minDate"
         [maxDate]="maxDate"
         [bsConfig]="bsConfig"
         [isDisabled]="disabled"
         [disabled]="disabled"
         [isOpen]="isOpen"
         [bsValue]="_value"
         (bsValueChange)="value = $event; ">
  
  <img *ngIf="!_value || disabled"
       [class.filter-coal-disabled]="disabled"
       [class.filter-primary]="!disabled"
       src="./assets/libs/icons/date.svg" alt="">

  <img *ngIf="!!_value && !disabled" src="./assets/img/icons/cancel.svg"
       class="pointer-cursor"
       (click)="onClearInput(); $event.stopPropagation()" alt="">
</div>
