<div class="flex flex-col items-center gap-32" *ngrxLet="showSignUpByEmail$ as showSignUpByEmail">
  <div class="flex flex-col gap-8 text-center">
    <h1>
      {{ 'LOGIN.JOIN_YOUR_COMMUNITY' + (loginSimplificationEnabled ? '_V2' : '') | translate }}
    </h1>
    <span>{{
      (showSignUpByEmail
        ? 'LOGIN.SIGN_UP_WITH_EMAIL_OR_ACCOUNT_NUMBER' + (loginSimplificationEnabled ? '_V2' : '')
        : 'LOGIN.SIGN_UP_WITH_ACCOUNT_NUMBER_AND_ZIP_CODE'
      ) | translate
    }}</span>
  </div>

  <div class="join-community-width flex flex-col gap-32">
    <div class="flex flex-col gap-32">
      <comm-link-expired-banner
        *ngIf="showLinkExpiredBanner"
        [ngClass]="{ '-my-16': showSignUpByEmail }"
        (closeBanner)="closeLinkExpiredBanner.emit()"
      />
      <tsq-button
        *ngIf="showSignUpByEmail"
        data-pendo="join-community-card--button--sign-up-by-email"
        [ngClass]="{ '-mb-16': showSignUpByEmail }"
        [schema]="buttonSchema"
        [size]="'lg'"
        [text]="'LOGIN.SIGN_UP_WITH_EMAIL' + (loginSimplificationEnabled ? '_V2' : '') | translate"
        (clicked)="goToSignUpWithEmail.emit()"
      />

      <tsq-button
        data-pendo="join-community-card--button--sign-up-by-account-number"
        [schema]="buttonSchema"
        [size]="'lg'"
        [text]="
          'LOGIN.SIGN_UP_ACCOUNT_NUMBER' + (loginSimplificationEnabled ? '_V2' : '') | translate
        "
        (clicked)="goToSignUp.emit()"
      />
    </div>

    <div class="flex items-center gap-16 px-32">
      <div class="border-b-1 border-petro-n2 grow"></div>
      <span class="{{ loginSimplificationEnabled ? 'font-base text-md' : 'font-bold' }}">{{
        'LOGIN.ALREADY_ON' | translate : { appName: appName }
      }}</span>
      <div class="border-b-1 border-petro-n2 grow"></div>
    </div>

    <div class="flex flex-col gap-24">
      <tsq-button
        data-pendo="join-community-card--button--login"
        [schema]="buttonSchema"
        [size]="'lg'"
        [text]="'LOGIN.GO_TO_LOGIN' + (loginSimplificationEnabled ? '_V2' : '') | translate"
        [kind]="'secondary'"
        (clicked)="goToLogin.emit()"
      />

      <tsq-button
        *ngIf="ssoEnabled && !loginSimplificationEnabled"
        [size]="'lg'"
        [text]="'LOGIN.GO_TO_LOGIN_WITH_SSO' | translate"
        [kind]="'secondary'"
        (clicked)="goToLoginWithSso.emit()"
      />

      <a
        *ngIf="!loginSimplificationEnabled"
        href="{{ 'LOGIN.MAIL_TO' | translate }}"
        class="self-center"
        (click)="sendInfoClick.emit()"
        data-pendo="join-community-card--a--need-help"
      >
        {{ 'LOGIN.NEED_HELP' | translate }}
      </a>
    </div>
  </div>
</div>
