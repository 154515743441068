<footer
  class="grid h-auto grid-cols-3 items-center p-16"
  [class.bg-petro-p1]="!loginSimplificationEnabled"
  [class.text-green]="!loginSimplificationEnabled"
>
  <div class="justify-self-start">
    <a [href]="loginLinks.backToTownSqLink" [class.new-primary-link]="loginSimplificationEnabled">{{
      'LOGIN.BACK_TO_TOWNSQ' | translate
    }}</a>
  </div>

  <div class="justify-self-center">
    <a
      *ngIf="isTownSqBrazil && !loginSimplificationEnabled"
      target="_blank"
      [href]="loginLinks.helpCenterLink"
    >
      {{ 'LOGIN.ACCESS_HELP_CENTER' | translate }}
    </a>

    <a
      *ngIf="loginSimplificationEnabled"
      class="new-primary-link"
      [href]="loginLinks.supportContactLink"
      [attr.target]="supportContactTarget"
      >{{ 'LOGIN.NEED_HELP_V2' | translate }}</a
    >
  </div>

  <div class="flex gap-24 justify-self-end">
    <a
      *ngIf="showTermsOfUse"
      [class.new-primary-link]="loginSimplificationEnabled"
      [href]="loginLinks.termsOfUseLink"
      target="_blank"
    >
      {{ 'LOGIN.TERMS_OF_USE' | translate }}
    </a>
    <a
      *ngIf="!isTownSqBrazil"
      [href]="loginLinks.privacyPolicyLink"
      [class.new-primary-link]="loginSimplificationEnabled"
      target="_blank"
    >
      {{ 'LOGIN.PRIVACY_POLICE' | translate }}
    </a>
  </div>
</footer>
