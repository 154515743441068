import { PageableV2 } from './pageable-v2.model';
import { Pageable } from './pageable.model';

export interface StorePage<T> {
  page: Pageable<T>;
  loading: boolean;
  error: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const storePageInitialState: StorePage<any> = {
  page: {
    content: [],
    page: 0,
    size: 0,
    number: 0,
    total: 0,
    totalPages: 0,
    totalElements: 0,
  },
  loading: false,
  error: false,
};

export interface StorePageV2<T> {
  page: PageableV2<T>;
  loading: boolean;
  error: boolean;
}

export const storePageV2InitialState: StorePageV2<unknown> = {
  page: {
    content: [],
    pageable: {
      sort: {
        sorted: false,
        unsorted: false,
        empty: false,
      },
      offset: 0,
      pageNumber: 0,
      pageSize: 0,
      paged: false,
      unpaged: false,
    },
    last: false,
    totalElements: 0,
    totalPages: 0,
    size: 0,
    number: 0,
    sort: {
      sorted: false,
      unsorted: false,
      empty: false,
    },
    numberOfElements: 0,
    first: false,
    empty: false,
  },
  loading: false,
  error: false,
};
