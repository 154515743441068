<div class="info-mini-card">
  <div [class.card]="true">
    <div class="simplified-img">
      <img src="{{ getCreatedByPicture() }}" alt="{{ getCreatedByName() }}" class="img-circle" />
    </div>
    <div class="info-data">
      <div class="info-title text-ellipsis">{{ getCreatedByName() }}</div>
      <div class="user-properties info-content">
        <div *ngIf="getPropertyList().length > 1">
          {{ 'PROPERTY.MULTIPLE_PROPERTIES' | translate }}
        </div>
        <div *ngIf="getPropertyList().length === 0">
          {{ 'PROPERTY.NO_PROPERTIES' | translate }}
        </div>
        <div
          *ngFor="let property of getPropertyList()"
          class="info-small"
          [hidden]="getPropertyList().length > 1"
          [ngClass]="{ 'overflow-ellipsis': getPropertyList().length === 1 }"
          [innerHTML]="property.fine_description"
        ></div>
      </div>
    </div>
    <div *ngIf="getPhones().length > 0">
      <div class="divider"></div>
      <div class="detail-header">{{ 'ACCOUNT.PHONE' | translate }}</div>
      <div class="detail-content" *ngFor="let phone of getPhones(auth)">{{ phone.number }}</div>
    </div>
  </div>
</div>
