import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'transformTime'})
export class TransformTimePipe implements PipeTransform {

  transform(value: string, lang: string): string {
    if (lang === 'en') {
      return this.transformToDisplayAMPM(value);
    } else {
      return value;
    }
  }

  private transformToDisplayAMPM(time: string): string {
    const ampm: string = ' ' + this.setAMPMOnConversion(time);
    const timeSplit = time.split(':');
    const hour: number = +timeSplit[0];

    if (hour > 12) {
      const ampmHour: number = hour - 12;
      if (ampmHour < 10) {
        return '0' + ampmHour + ':' + timeSplit[1] + ampm;
      } else {
        return ampmHour + ':' + timeSplit[1] + ampm;
      }
    } else if (hour === 0) {
      return '12:' + timeSplit[1] + ampm;
    } else {
      return time + ampm;
    }
  }

  private setAMPMOnConversion(time: string): string {
    if (+time.split(':')[0] >= 12) {
      return 'PM';
    } else {
      return 'AM';
    }
  }
}
