<div
  class="form-control input-chips-container"
  [class.has-info-label]="hasInfoLabel"
  [class.as-card-container]="displayAsCard"
  (click)="focusInput()"
>
  <tsq-chips-list
    class="display-contents input-chips"
    [options]="tagAsOptions"
    [invalidMultiple]="invalidTagsIndexes"
    [hasRemove]="true"
    [allSelected]="true"
    [hasCheck]="false"
    (optionRemoved)="removeTag($event)"
  >
  </tsq-chips-list>

  <input
    class="clear-input"
    [formControl]="newTagInput"
    [placeholder]="!!emptyPlaceholder && tags.length === 0 ? '' : inputPlaceholder"
    [disabled]="disabled"
    (keyup)="onKeyUp($event)"
    (keydown)="onKeyDown($event)"
    (paste)="onPaste($event)"
    (blur)="onBlur()"
    autocomplete="off"
    #input
  />

  <div *ngIf="emptyPlaceholder && tags.length === 0" class="overlap-placeholder">
    {{ emptyPlaceholder }}
  </div>
</div>
