<ng-container>
  <div class="modal-body-content">
    <form [formGroup]="form" class="p-16">
      <div class="group">
        <label>{{ 'LIBS.COMMENTS.COMMENT' | translate }}</label>
        <textarea class="control h-24" formControlName="comment"></textarea>
      </div>

      <div class="divider"></div>

      <div class="group">
        <label>{{ 'LIBS.COMMENTS.ATTACHMENTS' | translate }}</label>
        <tsq-file-uploader #fileUploader></tsq-file-uploader>
      </div>

      <div class="divider"></div>
    </form>
  </div>

  <div class="modal-footer absolute bottom-0 w-full flex justify-end p-16">
    <tsq-button class="pr-8"
                [text]="'LIBS.COMMENTS.CANCEL' | translate"
                [kind]="'secondary'"
                [schema]="'red'"
                (clicked)="closeModal.emit()"></tsq-button>
    <tsq-button *ngIf="!editCommentIsLoading"
                [disabled]="!form.valid"
                [text]="'LIBS.COMMENTS.SAVE' | translate"
                (clicked)="saveForm()"></tsq-button>
    <tsq-small-loader *ngIf="editCommentIsLoading"></tsq-small-loader>
  </div>
</ng-container>
