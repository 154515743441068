import { Pipe, PipeTransform } from '@angular/core';

import { Temporal } from '@js-temporal/polyfill';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import { fromLocalizationSelectors } from '@tsq-web/localization';
import { selectUntilDestroyed } from '@tsq-web/redux/operators';
import { AppState } from '@tsq-web/state';

import { EpochMilliseconds } from '../models/epoch-milliseconds.model';
import { ISO8601DateTime } from '../models/iso-8601-date-time.model';
import { isEpochMilliseconds } from '../utils/date-time.utils';
import { dateTimeFormat } from '../utils/format-options.utils';

@UntilDestroy()
@Pipe({
  name: 'formatDate',
})
export class FormatDatePipe implements PipeTransform {
  private lang: string;

  constructor(private store: Store<AppState>) {
    this.store
      .pipe(selectUntilDestroyed(fromLocalizationSelectors.selectLanguage, this))
      .subscribe((lang: string) => (this.lang = lang));
  }

  transform(
    value: EpochMilliseconds | ISO8601DateTime | Date,
    format?: Intl.DateTimeFormatOptions,
  ): string {
    if (!value) {
      return '';
    }

    let instant: Temporal.Instant;

    if (typeof value === 'string') {
      if (isEpochMilliseconds(value)) {
        instant = Temporal.Instant.fromEpochMilliseconds(Number(value));
      } else {
        instant = Temporal.Instant.from(value);
      }
    } else {
      const epochMilliseconds = value instanceof Date ? value.valueOf() : value;

      instant = Temporal.Instant.fromEpochMilliseconds(epochMilliseconds);
    }

    return instant.toLocaleString(this.lang || 'en', format || dateTimeFormat);
  }
}
