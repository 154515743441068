import { UserAttributeValue } from 'configcat-js';

import { Condo } from '@tsq-web/condo';
import { Feature } from '@tsq-web/feature';
import { UserGroup } from '@tsq-web/groups';
import {
  Permission,
  Role,
  hasFeaturePermission,
  isFeatureActive,
  isSuperUser,
} from '@tsq-web/permission';
import { User } from '@tsq-web/users';

export function getCustomConfigCatUserPayload(
  user: User,
  condo: Condo,
): Record<string, UserAttributeValue> {
  const customUserPayload: Record<string, UserAttributeValue> = {
    ['full_name']: `${user.first_name} ${user.last_name}`,
    ['is_owner']: containsRoleWithGroup(user, condo.defaultOwnerGroup).toString(),
    ['is_admin']: containsRoleWithGroup(user, condo.defaultAdminGroup).toString(),
    ['is_board_member']: containsRoleWithGroup(user, condo.defaultAdminGroup).toString(),
    ['is_manager']: containsRoleWithGroup(user, condo.defaultManagerGroup).toString(),
    ['is_family_member']: containsRoleWithGroup(user, condo.defaultFamilyMemberGroup).toString(),
    ['is_tenant']: containsRoleWithGroup(user, condo.defaultTenantGroup).toString(),
    ['trustee_discussion_permission']: getFeaturePermissions(
      user,
      condo,
      Feature.TrusteeDiscussion,
    ),
    ['resident_discussion_permission']: getFeaturePermissions(
      user,
      condo,
      Feature.ResidentDiscussion,
    ),
    ['calendars_permission']: getFeaturePermissions(user, condo, Feature.Calendars),
    ['lobby_permission']: getFeaturePermissions(user, condo, Feature.Lobby),
    ['discussion_permission']: getFeaturePermissions(user, condo, Feature.Discussion),
    ['documents_permission']: getFeaturePermissions(user, condo, Feature.Documents),
    ['neighbors_permission']: getFeaturePermissions(user, condo, Feature.Neighbors),
    ['poll_permission']: getFeaturePermissions(user, condo, Feature.Poll),
    ['alerts_permission']: getFeaturePermissions(user, condo, Feature.Alerts),
    ['assignments_permission']: getFeaturePermissions(user, condo, Feature.Assignments),
    ['facility_permission']: getFeaturePermissions(user, condo, Feature.Facility),
    ['properties_permission']: getFeaturePermissions(user, condo, Feature.Properties),
    ['package_delivery_permission']: getFeaturePermissions(user, condo, Feature.PackageDelivery),
    ['settings_permission']: getFeaturePermissions(user, condo, Feature.Settings),
    ['user_groups_permission']: getFeaturePermissions(user, condo, Feature.UserGroups),
    ['occupants_permission']: getFeaturePermissions(user, condo, Feature.Occupants),
    ['website_permission']: getFeaturePermissions(user, condo, Feature.Website),
    ['communication_permission']: getFeaturePermissions(user, condo, Feature.Communication),
    ['shift_logs_permission']: getFeaturePermissions(user, condo, Feature.ShiftLogs),
    ['userAgent']: window.navigator.userAgent,
    ['platform']: 'web',
    ['isSuper']: isSuperUser(user).toString(),
  };

  const customCommunityPayload: Record<string, UserAttributeValue> = {
    ['communityId']: condo.id,
    ['communityName']: condo.name,
    ['city']: condo.address.city,
    ['admName']: !!condo.admName ? condo.admName : '',
    ['admId']: !!condo && !!condo.admId ? condo.admId : '',
    ['is_trustee_discussion_active']: isFeatureActive(Feature.TrusteeDiscussion, condo).toString(),
    ['is_resident_discussion_active']: isFeatureActive(
      Feature.ResidentDiscussion,
      condo,
    ).toString(),
    ['is_calendars_active']: isFeatureActive(Feature.Calendars, condo).toString(),
    ['is_lobby_active']: isFeatureActive(Feature.Lobby, condo).toString(),
    ['is_discussion_active']: isFeatureActive(Feature.Discussion, condo).toString(),
    ['is_documents_active']: isFeatureActive(Feature.Documents, condo).toString(),
    ['is_neighbors_active']: isFeatureActive(Feature.Neighbors, condo).toString(),
    ['is_poll_active']: isFeatureActive(Feature.Poll, condo).toString(),
    ['is_alerts_active']: isFeatureActive(Feature.Alerts, condo).toString(),
    ['is_assignments_active']: isFeatureActive(Feature.Assignments, condo).toString(),
    ['is_package_delivery_active']: isFeatureActive(Feature.PackageDelivery, condo).toString(),
    ['is_occupants_active']: isFeatureActive(Feature.Occupants, condo).toString(),
    ['is_website_active']: isFeatureActive(Feature.Website, condo).toString(),
    ['is_shift_logs_active']: isFeatureActive(Feature.ShiftLogs, condo).toString(),
    ['is_easy_onboarding']: condo.isEasyOnboarding.toString(),
  };

  return { ...customUserPayload, ...customCommunityPayload };
}

function getFeaturePermissions(user: User, condo: Condo, feature: Feature): string[] {
  if (hasFeaturePermission(user, condo, feature, Permission.SUPER)) {
    return [Permission.SUPER, Permission.ADMIN, Permission.CREATE_FOR_OTHERS, Permission.VIEW];
  }
  if (hasFeaturePermission(user, condo, feature, Permission.ADMIN)) {
    return [Permission.ADMIN, Permission.CREATE_FOR_OTHERS, Permission.VIEW];
  }
  if (hasFeaturePermission(user, condo, feature, Permission.CREATE_FOR_OTHERS)) {
    return [Permission.CREATE_FOR_OTHERS, Permission.VIEW];
  }
  if (hasFeaturePermission(user, condo, feature, Permission.VIEW)) {
    return [Permission.VIEW];
  }

  return [Permission.NONE];
}

function containsRoleWithGroup(user: User, group: UserGroup): boolean {
  return user.roles.some((role: Role) => {
    return !!group && role.groupId === group.id;
  });
}
