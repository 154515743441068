import { Pipe, PipeTransform } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ThemeService } from '../services/theme.service';
import { ThemeMode } from '../models/theme-mode.enum';

@Pipe({
  name: 'themeBasedValue',
  pure: false,
})
export class ThemeBasedValuePipe implements PipeTransform {
  constructor(private themeService: ThemeService) {}

  transform(value: unknown, darkModeValue: unknown): Observable<unknown> {
    return this.themeService.mode$.pipe(
      map(theme => (theme === ThemeMode.Dark ? darkModeValue : value))
    );
  }
}
