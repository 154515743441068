<button
  *ngIf="!isLink; else link"
  [type]="type"
  [tabIndex]="tab"
  [attr.schema]="schema"
  [attr.kind]="kind"
  [attr.size]="size"
  [attr.hasText]="!!text"
  [disabled]="disabled"
  (click)="clicked.emit()"
>
  <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
</button>

<ng-template #link>
  <a
    *ngIf="!!href; else innerLink"
    [tabindex]="disabled ? -1 : tab"
    [href]="disabled ? '#' : href"
    [target]="target"
    class="w-fit cursor-pointer"
    [attr.schema]="schema"
    [attr.kind]="kind"
    [attr.size]="size"
    [attr.hasText]="!!text"
    [attr.disabled]="disabled"
    (click)="onAnchorClick()"
  >
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </a>

  <ng-template #innerLink>
    <button
      type="button"
      [tabIndex]="tab"
      [attr.schema]="schema"
      [attr.kind]="kind"
      [attr.size]="size"
      [attr.hasText]="!!text"
      [routerLink]="route"
      [relativeTo]="relative ? activatedRoute : null"
      [queryParams]="queryParams"
      [queryParamsHandling]="queryParamsHandling"
      [fragment]="fragment"
      [preserveFragment]="preserveFragment"
      [disabled]="disabled"
      (click)="clicked.emit()"
    >
      <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
    </button>
  </ng-template>
</ng-template>

<ng-template #buttonContent>
  <tsq-icon *ngIf="!!icon" [icon]="icon" [classes]="iconClass"></tsq-icon>
  <span *ngIf="!!text" [class.ml-8]="!!icon">{{ text }}</span>
</ng-template>
