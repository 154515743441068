<sc-modal
  [center]="true"
  [bigModalSize]="true"
  [showHeader]="false"
  [showFooter]="false"
  [zeroPadding]="true"
  [animation]="'fadeInBottom'"
  [centerVertical]="true"
  [modalCloseable]="modalClosable"
  [escClosable]="escClosable"
  (onHidden)="onModalHidden()"
  #modal
>
  <div modal-content class="tsq-confirmation-dialog">
    <img *ngIf="displayImage" src="{{ imgUrl }}" alt="" />
    <div id="content">
      <p [innerHtml]="message | translate"></p>
      <div id="scroll" class="tsq-scroll-y">
        <ng-content select="[confirmation-content]"></ng-content>
      </div>
    </div>
    <div class="divider"></div>
    <div id="actions">
      <button
        id="dialog-cancel"
        class="btn btn-action btn-white"
        [disabled]="disabled && !disableOnlyConfirmation"
        (click)="cancel()"
      >
        {{ cancelLabel | translate }}
      </button>
      <button
        id="dialog-confirm"
        class="btn btn-action btn-primary"
        [disabled]="disabled"
        (click)="confirm()"
      >
        {{ confirmationLabel | translate }}
      </button>
    </div>
  </div>
</sc-modal>
