/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import moment from 'moment-timezone';

import { User } from '@tsq-web/users';
import { relativeTime } from '@tsq-web/time';
import { FileMeta } from '@tsq-web/files';

@Component({
  selector: 'tsq-comment-card',
  templateUrl: 'tsq-comment-card.component.html',
  styleUrls: ['tsq-comment-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TSqCommentCardComponent {
  @Input() createdBy: User;
  @Input() generated: boolean;
  @Input() text: string;
  @Input() date: moment.Moment;
  @Input() attachments: FileMeta[];

  constructor(private translateService: TranslateService) {}

  get creationDate(): string {
    return relativeTime(this.date, this.translateService.instant('DATE_FORMAT'));
  }

  get propertyDescription(): string {
    const properties = this.createdBy.properties;
    if (!properties || properties.length === 0) {
      return this.translateService.instant('REQUESTS.NO_PROPERTIES');
    }
    if (properties.length > 1) {
      return this.translateService.instant('REQUESTS.MULTIPLE_PROPERTIES');
    }
    return properties[0].fine_description;
  }

  onAttachmentDownload(fileUrl: string) {
    window.open(fileUrl, '_blank');
  }
}
