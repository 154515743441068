import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormControl, Validators } from '@angular/forms';

import { FormSelectionOption } from '@tsq-web/forms';
import { PublicAisService } from '../../services/public-ais.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ais-owner-selection',
  templateUrl: './ais-owner-selection.component.html',
  styleUrls: ['./ais-owner-selection.component.scss'],
})
export class AisOwnerSelectionComponent {
  ownerControl = new UntypedFormControl(undefined, Validators.required);
  ownersOptions: FormSelectionOption[];

  constructor(private publicAisService: PublicAisService, private router: Router) {
    this.ownersOptions = this.publicAisService.selectedAccount.account.owners.map(owner => ({
      text: `${owner.firstName} ${owner.lastName}`,
      value: owner.entityUuid,
    }));
  }

  onContinue(): void {
    this.publicAisService.owner = this.publicAisService.selectedAccount.account.owners.find(
      owner => owner.entityUuid === (this.ownerControl.value as FormSelectionOption).value,
    );
    this.router.navigate(['ais', 'sign-up', 'link-user'], { queryParamsHandling: 'merge' });
  }
}
