import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { LetModule } from '@ngrx/component';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { AssetsModule } from '@tsq-web/assets';
import { TSqFormsModule } from '@tsq-web/forms';
import { TSqGenericComponentsModule } from '@tsq-web/generic-components';
import { TSqLoadersModule } from '@tsq-web/loaders';
import { SingleSignOnService } from '@tsq-web/login';

import { ErrorBannerComponent } from '../shared/component/error-banner/error-banner.component';
import { SCWidgetModule } from '../shared/component/sc.widget.module';
import { LinksFooterComponent } from '../shared/links-footer/links-footer.component';
import { LoginService } from '../shared/user/login.service';
import { RegisterService } from '../shared/user/register.service';
import { AlreadyRegisteredBannerComponent } from './components/already-registered-banner/already-registered-banner.component';
import { EmailFormComponent } from './components/email-form/email-form.component';
import { JoinCommunityCardComponent } from './components/join-community-card/join-community-card.component';
import { LinkExpiredBannerComponent } from './components/link-expired-banner/link-expired-banner.component';
import { LoginCardComponent } from './components/login-card/login-card.component';
import { PasswordFormComponent } from './components/password-form/password-form.component';
import { ReviewAccessComponent } from './components/review-access/review-access.component';
import { SsoRedirectErrorComponent } from './components/sso-redirect-error/sso-redirect-error.component';
import { SsoRedirectComponent } from './components/sso-redirect/sso-redirect.component';
import { AuthCardComponent } from './containers/auth-card/auth-card.component';
import { LoginV2Component } from './containers/login-v2/login-v2.component';
import { LoginComponent } from './containers/login/login.component';
import { SingleSignOnCallbackComponent } from './containers/sso/callback.component';
import { SingleSignOnComponent } from './containers/sso/sso.component';
import { loginRoutes } from './login.routes';
import { AuthStepEffects } from './state/auth-step/auth-step.effects';
import { AuthStepStore } from './state/auth-step/auth-step.store';
import { PasswordUtils } from './utils/password.utils';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(loginRoutes),
    TooltipModule.forRoot(),
    TranslateModule,
    SCWidgetModule,
    TSqFormsModule,
    TSqGenericComponentsModule,
    AssetsModule,
    LinksFooterComponent,
    TSqLoadersModule,
    ReactiveFormsModule,
    LetModule,
    ErrorBannerComponent,
    EffectsModule.forFeature([AuthStepEffects]),
  ],
  declarations: [
    LoginComponent,
    LoginV2Component,
    SingleSignOnComponent,
    SingleSignOnCallbackComponent,
    JoinCommunityCardComponent,
    LoginCardComponent,
    AlreadyRegisteredBannerComponent,
    LinkExpiredBannerComponent,
    AuthCardComponent,
    EmailFormComponent,
    PasswordFormComponent,
    ReviewAccessComponent,
    SsoRedirectComponent,
    SsoRedirectErrorComponent,
  ],
  exports: [LoginComponent],
  providers: [LoginService, PasswordUtils, RegisterService, SingleSignOnService, AuthStepStore],
})
export class LoginModule {}
