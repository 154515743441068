import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { ModalModule } from 'ngx-bootstrap/modal';

import { AssetsModule } from '@tsq-web/assets';
import { TSqChipsModule } from '@tsq-web/chips';
import { TSqLoadersModule } from '@tsq-web/loaders';

import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { TSqDeleteConfirmationModalComponent } from './components/tsq-delete-confirmation-modal/tsq-delete-confirmation-modal.component';
import { TSqFullscreenModalComponent } from './components/tsq-fullscreen-modal/tsq-fullscreen-modal.component';
import { TSqGenericModalCancelButtonComponent } from './components/tsq-generic-modal-cancel-button/tsq-generic-modal-cancel-button.component';
import { TSqGenericModalCloseComponent } from './components/tsq-generic-modal-close/tsq-generic-modal-close.component';
import { TSqGenericModalComponent } from './components/tsq-generic-modal/tsq-generic-modal.component';

@NgModule({
  imports: [
    AssetsModule,
    CommonModule,
    TranslateModule,
    ModalModule.forRoot(),
    PortalModule,
    TSqChipsModule,
    TSqLoadersModule,
    AssetsModule,
  ],
  declarations: [
    TSqGenericModalComponent,
    TSqFullscreenModalComponent,
    TSqGenericModalCloseComponent,
    TSqDeleteConfirmationModalComponent,
    TSqGenericModalCancelButtonComponent,
    ConfirmationDialogComponent,
  ],
  exports: [
    TSqGenericModalComponent,
    TSqFullscreenModalComponent,
    TSqGenericModalCloseComponent,
    TSqDeleteConfirmationModalComponent,
    TSqGenericModalCancelButtonComponent,
    ConfirmationDialogComponent,
  ],
})
export class TSqModalsModule {}
