<div class="zero-data">
  <div class="row">
    <div class="col-md-12 top-text">
      {{ 'SHARED.ERROR_1' | translate }}
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 illustration">
      <img src="./assets/svg/general-error.svg" alt="" />
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 bottom-text">
      {{ 'SHARED.ERROR_2' | translate }}
    </div>
  </div>
  <div class="row">
    <div class="col-md-10 col-md-offset-1 call-to-action">
      <button class="btn btn-primary" (click)="retryClicked()">
        {{ 'SHARED.TRY_AGAIN' | translate }}
      </button>
      <a href="mailto:{{ 'SUPPORT' | translate }}" target="_blank" class="btn white-btn">{{
        'SHARED.CONTACT_SUPPORT' | translate
      }}</a>
    </div>
  </div>
</div>
