/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'tsq-primary-button',
  templateUrl: 'tsq-primary-button.component.html',
  styleUrls: ['tsq-primary-button.component.scss'],
})
export class TsqPrimaryButtonComponent {
  @Input() buttonText = '';
  @Input() icon: string;
  @Input() iconWidth: string;
  @Output() onButtonClick = new EventEmitter<string>();

  onClick() {
    this.onButtonClick.emit();
  }
}
