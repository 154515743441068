import { RouterReducerState } from '@ngrx/router-store';

import { RouterState } from '@tsq-web/router';

export const mockedRouterState: RouterReducerState<RouterState> = {
  state: {
    url: '',
    params: {},
    queryParams: {},
    fragment: '',
  },
  navigationId: 0,
};
