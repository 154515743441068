import {createFeatureSelector, createSelector} from '@ngrx/store';

import {NeighborMassInviteState} from './neighbor-mass-invite.reducer';

export const selectNeighborMassInviteState = createFeatureSelector<NeighborMassInviteState>('neighborMassInvite');

export const selectIsLoading = createSelector(
  selectNeighborMassInviteState,
  (neighborMassInviteState: NeighborMassInviteState) => neighborMassInviteState.loading
);

export const selectIsSuccessful = createSelector(
  selectNeighborMassInviteState,
  (neighborMassInviteState: NeighborMassInviteState) => neighborMassInviteState.success
);
