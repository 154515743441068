<div class="row tsq-card">
  <div class="info">
    <div class="title"
         [ngClass]="{'color-avocado': titleColor === 'avocado', 'color-battleship': titleColor === 'battleship-grey'}">
      <span (click)="titleClicked()">{{title}}</span>
    </div>
    <span *ngIf="subtitle && subtitle !== ''"><br></span>
    <div *ngIf="subtitle && subtitle !== ''" class="subtitle">
      {{ subtitle }}
    </div>
    <span *ngIf="tags.length > 0"><br></span>
    <div *ngIf="tags.length > 0" class="tags-container">
      <div *ngFor="let tag of tags" class="tag inline-block">
        {{ tag }}
      </div>
    </div>
  </div>
  <div class="actions-container">
    <ng-content></ng-content>
  </div>
</div>
