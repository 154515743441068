/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mobile-detection-overlay',
  templateUrl: 'mobile-detection-overlay.component.html',
  styleUrls: ['mobile-detection-overlay.component.scss'],
})
export class MobileDetectionOverlayComponent {
  @Output() keepOnWeb = new EventEmitter();

  static isiOS(): boolean {
    return !!navigator.userAgent.match(/iPhone|iPad|iPod/i);
  }

  static isAndroid(): boolean {
    return !!navigator.userAgent.match(/Android/i);
  }

  constructor(private _translateService: TranslateService) {}

  get infoText(): string {
    return MobileDetectionOverlayComponent.isiOS()
      ? 'SHARED.WE_DETECTED_MOBILE_IOS'
      : 'SHARED.WE_DETECTED_MOBILE_ANDROID';
  }

  get handImg(): string {
    return MobileDetectionOverlayComponent.isiOS()
      ? './assets/img/icons/hand-ios.png'
      : './assets/img/icons/hand-android.png';
  }

  get storeImg(): string {
    return MobileDetectionOverlayComponent.isiOS()
      ? './assets/img/icons/appstore.png'
      : './assets/img/icons/playstore.png';
  }

  keepOnWebClick() {
    this.keepOnWeb.emit();
  }

  openStore() {
    MobileDetectionOverlayComponent.isiOS()
      ? window.open('https://itunes.apple.com/br/app/townsq/id945419466')
      : window.open('https://play.google.com/store/apps/details?id=br.socialcondo.app');
  }
}
