import { AfterViewInit, Directive, Input, SimpleChange } from '@angular/core';

import { IconComponent, icons } from '@tsq-web/assets';

import { PeekableIconSchema } from '../../models/peekable-icon-schema.enum';
import { AbstractInputDirective } from '../abstract-input/abstract-input.directive';

@Directive({
  selector: 'input[type=password][tsqPeekablePassword]',
})
export class PeekablePasswordDirective extends AbstractInputDirective implements AfterViewInit {
  @Input() eyeButtonTabIndex: number;
  @Input() schema: 'primary' | 'new-primary' = 'primary';

  private toggleCheckbox: HTMLInputElement;

  private readonly icons = icons;

  ngAfterViewInit(): void {
    super.wrapElement();
    this.initCheckbox();
    super.initializeControl();
  }

  updateControl(): void {
    super.updateErrorLabel();

    if (this.el.type === 'password') {
      this.addIcon(this.icons.eye, this.iconSchema);
    } else {
      this.addIcon(this.icons.eyeSlash, this.iconSchema);
    }
  }

  private get iconSchema(): PeekableIconSchema {
    if (this.control?.touched && this.control?.invalid) {
      return PeekableIconSchema.Error;
    }

    if (!this.control?.value || this.control?.disabled) {
      return PeekableIconSchema.Disabled;
    }

    return PeekableIconSchema.Primary;
  }

  private initCheckbox(): void {
    this.toggleCheckbox = this.renderer.createElement('div');
    this.renderer.setAttribute(this.el, 'schema', this.schema);
    this.renderer.setAttribute(this.toggleCheckbox, 'role', 'checkbox');
    this.renderer.setAttribute(this.toggleCheckbox, 'aria-checked', 'false');
    this.renderer.setAttribute(
      this.toggleCheckbox,
      'tabindex',
      this.eyeButtonTabIndex?.toString() || this.el.tabIndex.toString(),
    );
    this.renderer.listen(this.toggleCheckbox, 'mousedown', () => this.togglePasswordVisibility());
    this.renderer.listen(this.toggleCheckbox, 'keydown.space', () =>
      this.togglePasswordVisibility(),
    );

    [
      'absolute',
      'top-8',
      'right-16',
      'cursor-pointer',
      'rounded',
      'focus:outline',
      ...(this.schema === 'new-primary' ? ['focus:outline-new-primary'] : []),
    ].forEach(className => this.renderer.addClass(this.toggleCheckbox, className));

    this.renderer.appendChild(this.wrapperEl, this.toggleCheckbox);
  }

  private addIcon(icon: string, schema?: PeekableIconSchema): void {
    this.viewContainerRef.clear();

    const schemaClass = this.handleSchema(schema);

    const iconComponentRef = this.viewContainerRef.createComponent(IconComponent);
    iconComponentRef.instance.icon = icon;
    iconComponentRef.instance.classes = `${schemaClass} top-4`;
    // eslint-disable-next-line @angular-eslint/no-lifecycle-call
    iconComponentRef.instance.ngOnChanges({
      icon: new SimpleChange(undefined, this.icons.eye, false),
    });

    this.renderer.appendChild(
      this.toggleCheckbox,
      iconComponentRef.location.nativeElement as Element,
    );

    this.renderer.setStyle(this.el, 'padding-right', '48px');
  }

  private togglePasswordVisibility(): void {
    if (this.el.type === 'password') {
      this.renderer.setAttribute(this.el, 'type', 'text');
      this.addIcon(this.icons.eyeSlash, this.iconSchema);
    } else {
      this.renderer.setAttribute(this.el, 'type', 'password');
      this.addIcon(this.icons.eye, this.iconSchema);
    }
  }

  private handleSchema(schema: PeekableIconSchema): string {
    switch (schema) {
      case PeekableIconSchema.Primary:
        return this.schema === 'new-primary' ? 'text-new-primary-p2' : 'text-primary';
      case PeekableIconSchema.Disabled:
        return 'text-coal-tertiary';
      case PeekableIconSchema.Error:
        return 'text-red';
    }
  }
}
