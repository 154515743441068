import { Component, Input } from '@angular/core';

import { CondoSubscription } from '../../../shared/subscription/condo-subscription.json';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'subscription-summary-content',
  templateUrl: './subscription-summary-content.component.html',
  styleUrls: ['./subscription-summary-content.component.scss'],
})
export class SubscriptionSummaryContentComponent {
  @Input() subscription: CondoSubscription;
  @Input() multiplierNumber: number;
  @Input() percentageNumber: number;
  @Input() rawValueNumber: number;
  @Input() totalAmount: number;
  @Input() chargeDate: Date;

  get basePrice(): number {
    if (!!this.subscription && !!this.subscription.plan) {
      return (
        (this.subscription.plan.price.amount *
          (this.multiplierNumber ? this.multiplierNumber : 1)) /
        100
      );
    } else {
      return 0;
    }
  }
}
