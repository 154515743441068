<div
  class="uploaded-list mt-8"
  *ngIf="!!files && files.length > 0"
  [attr.disabled]="(!!uploader && uploading) || disabled"
>
  <div
    *ngFor="let file of files; let i = index"
    class="flex items-center gap-8 text-left h-56 w-full p-8 border-1 border-petro-n2 rounded"
  >
    <div class="flex justify-center items-center rounded h-40 w-40 bg-petro-n1">
      <tsq-icon [classes]="'text-coal-tertiary w-24 h-24'" [icon]="documentIcon"></tsq-icon>
    </div>

    <div class="flex-1 truncate leading-none">
      <span class="block font-base text-base text-coal font-bold">
        {{ file.name || defaultFileName }}
      </span>
      <small class="block text-sm text-coal-secondary font-base mt-4" *ngIf="!!file.size">
        {{ file.size | formatBytes : 2 }}
      </small>
    </div>

    <div
      tabindex="0"
      role="button"
      class="flex justify-center items-center h-40 w-40 focus:outline"
      (click)="delete.emit(i)"
    >
      <tsq-icon [icon]="trashIcon" [classes]="'text-red'"></tsq-icon>
    </div>
  </div>
</div>
