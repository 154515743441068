import { Observable } from 'rxjs';

type FilterKeysPer<T, V extends boolean | string | number> = {
  [K in keyof T]: T[K] extends V ? K : never;
}[keyof T];

type AnyString = string & Record<never, never>;

export type FeatureFlagName<
  TFlagsType extends Record<string, boolean | string | number>,
  TFilterType extends boolean | string | number,
> = FilterKeysPer<TFlagsType, TFilterType> | AnyString;

export abstract class FeatureFlagService<
  TFlagsType extends Record<string, boolean | string | number> = Record<
    string,
    boolean | string | number
  >,
> {
  abstract getValue(flag: FeatureFlagName<TFlagsType, boolean>, fallback?: boolean): boolean;
  abstract getValueAsync(
    flag: FeatureFlagName<TFlagsType, boolean>,
    fallback?: boolean,
  ): Observable<boolean>;

  abstract getValueString(flag: FeatureFlagName<TFlagsType, string>, fallback?: string): string;
  abstract getValueStringAsync(
    flag: FeatureFlagName<TFlagsType, string>,
    fallback?: string,
  ): Observable<string>;

  abstract getValueNumber(flag: FeatureFlagName<TFlagsType, number>, fallback?: number): number;
  abstract getValueNumberAsync(
    flag: FeatureFlagName<TFlagsType, number>,
    fallback?: number,
  ): Observable<number>;
}
