import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { RelativeTimePipe } from '@tsq-web/date-time';
import { User, getUserFullName } from '@tsq-web/users';

import { TSqCommentPresentation } from '../../models/tsq-comment-presentation.model';

@Component({
  selector: 'tsq-comment',
  templateUrl: './tsq-comment.component.html',
  styleUrls: ['./tsq-comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TSqCommentComponent {
  @Input() editable: boolean;
  @Input() deletable: boolean;
  @Input() comment: TSqCommentPresentation;
  @Input() user: User;
  @Input() isAdmin: boolean;
  @Input() adminDeleteComments: boolean;
  @Input() clickableUrl: boolean;

  @Output() editComment = new EventEmitter<TSqCommentPresentation>();
  @Output() deleteComment = new EventEmitter<TSqCommentPresentation>();

  constructor(private relativeTimeFormatter: RelativeTimePipe) {}

  get time(): string {
    return this.relativeTimeFormatter.transform(Number(this.comment.creationDate));
  }

  get userPicture(): string {
    if (!!this.comment.createdBy.smallPictureUrl) {
      return this.comment.createdBy.smallPictureUrl;
    }

    return !!this.comment.createdBy.pictureUrl
      ? this.comment.createdBy.pictureUrl
      : './assets/img/icons/user-placeholder.svg';
  }

  get userFullName(): string {
    return this.comment?.createdBy?.name || getUserFullName(this.comment.createdBy);
  }

  get shouldShowCommentDropdown(): boolean {
    const verifyIfCommentWasCreatedByUser =
      !!this.user && this.comment.createdBy.id === this.user.id;
    const isNotGeneratedComment = !(!!this.comment.statusIcon && this.comment.generated);
    const hasDeletableOrEditable = this.deletable && this.editable;

    return verifyIfCommentWasCreatedByUser && isNotGeneratedComment && hasDeletableOrEditable;
  }

  get showCommentDropdownForAdmin(): boolean {
    const canDeleteCommentsFromOtherUsers =
      this.isAdmin &&
      !this.comment.isDeleted &&
      this.adminDeleteComments &&
      !this.comment.generated;

    return canDeleteCommentsFromOtherUsers;
  }

  get shouldShowDeleteOnly(): boolean {
    const commentWasNotCreatedByUser = !!this.user && this.comment.createdBy.id !== this.user.id;

    return commentWasNotCreatedByUser && this.isAdmin;
  }
}
