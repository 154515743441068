import { Params, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import { Observable, Observer } from 'rxjs';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

import { AppState } from '@tsq-web/state';
import * as fromUserContextActions from '@tsq-web/user-context/actions';
import { LoginService } from '../services/login.service';
import { LoginContext } from '../models/login-context.model';

export class ContextGuardUtil {

  constructor(protected loginService: LoginService,
              protected cookieService: CookieService,
              protected router: Router,
              protected store: Store<AppState>,
              protected toastrService: ToastrService,
              protected translateService: TranslateService) {
  }

  getContextOverRoute(state: RouterStateSnapshot, params: Params): Observable<boolean | UrlTree> {
    return new Observable((observer: Observer<boolean | UrlTree>) => {
      if (!!params.contextId) {
        this.loginService.getContext(params.contextId)
          .subscribe(
            (loginContext: LoginContext) => {
              loginContext.user.roles = loginContext.permissions;
              this.store.dispatch(fromUserContextActions.setContext({ loginContext }));
              observer.next(true);
            },
            () => {
              this.store.dispatch(fromUserContextActions.goToContextSelection());
              observer.next(false);
            },
          );
      } else if (this.cookieService.check('jwt')) {
        this.store.dispatch(fromUserContextActions.goToContextSelection());
        observer.next(false);
      } else {
        observer.next(this.router.parseUrl(`login?redirect=${ state.url }`));
      }
    });
  }
}
