<div class="form-control" (click)="focusInput()">
  <tsq-chips-list class="display-contents input-chips"
                  [options]="chips"
                  [hasRemove]="true"
                  [allSelected]="true"
                  [hasCheck]="false"
                  (optionRemoved)="onChipRemoved($event)">
  </tsq-chips-list>
  <input type="text"
         [(ngModel)]="userInput"
         [placeholder]="placeholder"
         (keydown)="onKeyDown($event)"
         (keyup)="onKeyUp($event)"
         #input/>
</div>
