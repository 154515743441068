import { Feature } from '@tsq-web/feature';

export class Favorite {
  id: string;
  creationDate: number;
  updateDate: number;
  features: string[];
  userId: string;
}

export interface FavoriteFeature {
  title: string;
  path: string;
  relatedFeature: Feature;
  showHasPermission?: boolean;
  subFeature?: FavoriteSubFeature[];
}

export type FavoriteSubFeature = Omit<FavoriteFeature, 'subFeature'>;
