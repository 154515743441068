/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { Plan } from '../plan.json';

@Component({
  selector: 'comm-plan-selection',
  templateUrl: 'plan-selection.component.html',
  styleUrls: ['plan-selection.component.scss'],
})
export class PlanSelectionComponent {
  @Input() currentPlan: Plan;

  @Output() planSelected = new EventEmitter<Plan>();
  @Output() errorLoadingPlans = new EventEmitter<any>();

  private _availablePlans: Plan[] = [];
  selectedFeaturePackages: number[] = [];

  constructor(private translateService: TranslateService) {}

  @Input() set availablePlans(plans: Plan[]) {
    if (!!plans) {
      this._availablePlans = plans;
      this.selectedFeaturePackages = new Array(plans.length).fill(0);
    }
  }

  get availablePlans() {
    return this._availablePlans;
  }

  onPlanSelected(plan: Plan): void {
    this.planSelected.emit(plan);
  }

  getPlanPricing(plan: Plan): string {
    const supportedAsString = [30, 60, 90, 180, 365];
    if (plan.price.amount === 0) {
      return this.translateService.instant('SUBSCRIPTION.FREE');
    }
    const price = this.translateService.instant(`SUBSCRIPTION.${plan.price.currency}_PRICE`, {
      amount: (plan.price.amount / 100).toFixed(2),
    });
    if (supportedAsString.indexOf(plan.duration) !== -1) {
      const period = this.translateService.instant(`SUBSCRIPTION.PERIOD.${plan.duration}`);
      return `${price} ${period}`;
    } else {
      const period = this.translateService.instant('SUBSCRIPTION.PERIOD.CUSTOM', {
        period: plan.duration,
      });
      return `${price} ${period}`;
    }
  }

  isCurrentPlan(plan: Plan): boolean {
    return plan.id === (this.currentPlan ? this.currentPlan.id : undefined);
  }

  onPackageSelectedForPlan(planIndex: number, packageIndex: number) {
    this.selectedFeaturePackages[planIndex] = packageIndex;
  }

  isPackageSelectedForPlan(planIndex: number, packageIndex: number): boolean {
    return this.selectedFeaturePackages[planIndex] === packageIndex;
  }
}
