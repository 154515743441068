<div id="tsq-gallery-preview"
     [ngStyle]="{display: isPreviewOpen ? 'flex' : 'none'}" (keyup)="onKeyUp($event)" tabindex="0">

  <div class="preview-top" *ngIf="!!selectedFileToDisplay">
    <ng-container *ngTemplateOutlet="topPreviewTemplate; context: topPreviewContext"></ng-container>
  </div>

  <ng-container *ngIf="!!files && !!selectedFileToDisplay">
    <div class="preview-body">
      <img [src]="selectedFileToDisplay.displayUrl" [alt]="selectedFileToDisplay.displayAlt">
    </div>
  </ng-container>

  <div class="preview-bottom" *ngIf="!!selectedFileToDisplay">
    <ng-container *ngTemplateOutlet="bottomPreviewTemplate; context: bottomPreviewContext"></ng-container>
  </div>
</div>
