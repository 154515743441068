/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { AppState } from '@tsq-web/state';
import { User } from '@tsq-web/users';
import { Condo } from '@tsq-web/condo';
import { fromUserContextSelectors } from '@tsq-web/user-context';
import { getFeatureToggle } from '../feature-toggle';

@Injectable()
export class PipzService {
  user: User;
  condo: Condo;

  featureToggle = getFeatureToggle();

  constructor(private store: Store<AppState>) {
    this.store
      .pipe(select(fromUserContextSelectors.selectUser))
      .subscribe(user => (this.user = user));
    this.store
      .pipe(select(fromUserContextSelectors.selectCondo))
      .subscribe(condo => (this.condo = condo));
  }

  identify() {
    if (this.shouldNotifyPipz(this.user.email)) {
      (window as any).pipz.identify(this.user.id, {
        name: this.user.first_name + ' ' + this.user.last_name,
        email: this.user.email,
        company: {
          remote_id: this.condo.id,
          name: this.condo.name,
        },
      });
    }
  }

  track(event: string, email?: string) {
    const mail = email || this.user.email;
    if (this.shouldNotifyPipz(mail)) {
      (window as any).pipz.track(event, { email: mail });
    }
  }

  private shouldNotifyPipz(email: string): boolean {
    return this.featureToggle.PIPZ_EVENTS && !this.isInternalUser(email);
  }

  private isInternalUser(email: string) {
    const internalEmails = ['@townsq.com.br', '@socialcondo.com.br'];
    return internalEmails.some(domain => email.includes(domain));
  }
}
