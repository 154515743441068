import { animate, style, transition, trigger } from '@angular/animations';

export const slideDown = trigger('slideDown', [
  transition(':enter', [
    style({ transform: 'translateY(-100%)' }),
    animate('300ms ease-in', style({ transform: 'translateY(0%)' })),
  ]),
]);

export const slideUp = trigger('slideUp', [
  transition(':enter', [
    style({ transform: 'translate(-50%, 100%)' }),
    animate('300ms ease-in', style({ transform: 'translateY(-50%, 0%)' })),
  ]),
]);
