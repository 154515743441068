<div
  class="modal-form-confirm-alert border-t-red-p1 border-t-1 flex items-center justify-between gap-8 bg-white p-16"
  [ngClass]="open ? 'modal-form-confirm-alert-open h-auto' : 'modal-form-confirm-alert-close h-0'"
>
  <tsq-icon
    [classes]="'text-red'"
    class="bg-red-n1 flex h-40 w-40 items-center justify-center rounded-full"
    [icon]="libIcons.messageExclamation"
  ></tsq-icon>
  <div data-testid="modal-form-confirm-alert--div--confirm-text" class="flex-1 font-bold">
    {{ description }}
  </div>
  <div class="flex gap-8">
    <tsq-button
      type="button"
      data-testid="modal-form-confirm-alert--button--cancel-button"
      kind="tertiary"
      [text]="cancelBtnText"
      (clicked)="cancelClicked()"
    />
    <tsq-button
      type="button"
      data-testid="modal-form-confirm-alert--button--confirm-button"
      kind="tertiary"
      schema="red"
      [text]="confirmBtnText"
      (clicked)="confirmClicked()"
    />
  </div>
</div>
