<div class="form-group">
  <div class="row">
    <div class="col-md-10">
      <div class="info-mini-card">
        <div class="info-picture">
          <img
            src="./assets/img/fileExts/xls.png"
            alt=""
            class="media-object img-circle"
            width="38"
            height="38"
          />
        </div>
        <div class="info-label">
          <a *ngIf="selfHandleXlsTemplateUrl" href="{{ xlsTemplateUrl }}" target="_blank">{{
            xlsTemplateName
          }}</a>
          <a *ngIf="!selfHandleXlsTemplateUrl" (click)="xlsTemplateUrlClick.emit()">{{
            xlsTemplateName
          }}</a>
        </div>
      </div>
    </div>
  </div>
  <label for="add-files">{{ fileAreaLabel }}</label>
  <div
    [hidden]="uploader.isUploading"
    ng2FileDrop
    id="add-files"
    [uploader]="uploader"
    (fileOver)="onFileOver($event)"
    [ngClass]="{ fileOver: isFileOver }"
  >
    <input ng2FileSelect type="file" id="file-select-input" [uploader]="uploader" />
    <img src="./assets/img/icons/drop-area-icon.svg" alt="" />
    <span class="no-pointer-event"
      >{{ 'ADD_ATTACHMENT_1' | translate }}<br />
      {{ 'ADD_ATTACHMENT_2' | translate }}</span
    >
  </div>
</div>
<div class="row">
  <progressbar *ngIf="uploader.isUploading" [max]="100" [value]="uploader.progress">
    <span style="color: white; white-space: nowrap">{{ uploader.progress }}%</span>
  </progressbar>
</div>
