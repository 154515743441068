/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Permission } from '@tsq-web/permission';
import { UserGroupPermission } from '@tsq-web/groups';
import { UserGroup } from '@tsq-web/groups';

@Component({
  selector: 'tsq-user-group-permissions-selector',
  templateUrl: 'tsq-user-group-permissions-selector.component.html',
  styleUrls: ['tsq-user-group-permissions-selector.component.scss'],
})
export class TSqUserGroupPermissionsSelectorComponent {
  @Input() userGroupPermissions: UserGroupPermission[];
  @Output() userGroupPermissionsChange = new EventEmitter<UserGroupPermission[]>();

  options = [
    { value: Permission.NONE, label: `SHARED.${Permission.NONE}` },
    { value: Permission.VIEW, label: `SHARED.${Permission.VIEW}` },
    { value: Permission.ADMIN, label: `SHARED.${Permission.ADMIN}` },
  ];

  getSelected(permission: Permission): { value: string; label: string } {
    return { value: permission, label: `SHARED.${Permission[permission]}` };
  }

  onSelectChange(group: UserGroup, permission: string) {
    this.userGroupPermissionsChange.emit(
      this.userGroupPermissions.map((groupPermission: UserGroupPermission) => {
        if (groupPermission.userGroup.id === group.id) {
          return new UserGroupPermission(group, permission as Permission);
        }
        return groupPermission;
      }),
    );
  }
}
