/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, ViewChild, Input } from '@angular/core';
import { SCModalComponent } from '../sc-modal/sc-modal.component';

@Component({
  selector: 'sc-category-visibility-modal',
  styleUrls: ['sc-category-visibility-modal.component.scss'],
  templateUrl: 'sc-category-visibility-modal.component.html',
})
export class SCCategoryVisibilityModalComponent {
  @ViewChild('modal', { static: true }) modal: SCModalComponent;
  @Input() modalTitle = 'PVT_CATEGORY.VISIBILITY';

  private _displayedCategory: any;

  showModalForCategory(category: any) {
    this._displayedCategory = category;
    this.modal.show();
  }

  get displayedCategory(): any {
    return this._displayedCategory;
  }
}
