import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { FileUploadModule } from 'ng2-file-upload';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { defineLocale, ptBrLocale } from 'ngx-bootstrap/chronos';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { AssetsModule } from '@tsq-web/assets';
import { TSqFilesModule } from '@tsq-web/files';
import { HighlightDirective, TSqGenericComponentsModule } from '@tsq-web/generic-components';
import { TSqLoadersModule } from '@tsq-web/loaders';
import { TSqPipesModule } from '@tsq-web/pipes';

import { SCTextareaAutosizeDirective } from '../directives/autosize.directive';
import {
  TransitionGroupComponent,
  TransitionGroupItemDirective,
} from '../directives/transition-group.directive';
import { NewLineToBreakLinePipe } from '../pipe/nl2br.pipe';
import { SanitizeHtmlPipe } from '../pipe/sanitize-html.pipe';
import { SanitizeUrlPipe } from '../pipe/sanitize-url.pipe';
import { TransformTimePipe } from '../pipe/transform-time.pipe';
import { CEPValidatorDirective } from '../viacep/cep-validator.directive';
import { AccordionComponent } from './accordion/accordion.component';
import { LinkDataListComponent } from './link-data-list/link-data-list.component';
import { MobileDetectionOverlayComponent } from './mobile-detection-overlay/mobile-detection-overlay.component';
import { ModalFormConfirmAlertComponent } from './modal-form-confirm-alert/modal-form-confirm-alert.component';
import { SCAttachmentViewComponent } from './sc-attachment-view/sc-attachment-view.component';
import { SCButtonGroupComponent } from './sc-button-group/sc-button-group.component';
import { SCRadioButtonComponent } from './sc-buttons/sc-radio-button/sc-radio-button.component';
import { SCCalendarDayComponent } from './sc-calendar/sc-calendar-day/sc-calendar-day.component';
import { SCCalendarComponent } from './sc-calendar/sc-calendar.component';
import { SCCategoryPermissionSelectionComponent } from './sc-category-permission-selection/sc-category-permission-selection.component';
import { SCCategoryVisibilityModalComponent } from './sc-category-visibility-modal/sc-category-visibility-modal.component';
import { SCColorPickerItemComponent } from './sc-color-picker/sc-color-picker-item/sc-color-picker-item.component';
import { SCColorPickerComponent } from './sc-color-picker/sc-color-picker.component';
import { SCCommentBubbleComponent } from './sc-comment/sc-comment-bubble/sc-comment-bubble.component';
import { SCCommentInputComponent } from './sc-comment/sc-comment-input/sc-comment-input.component';
import { SCCommentStatusComponent } from './sc-comment/sc-comment-status/sc-comment-status.component';
import { SCConfigItemComponent } from './sc-config-item/sc-config-item.component';
import { SCConfirmationDialogComponent } from './sc-confirmation-dialog/sc-confirmation-dialog.component';
import { SCDateNavigatorComponent } from './sc-date-navigator/sc-date-navigator.component';
import { SCErrorComponent } from './sc-error/sc-error.component';
import { SCIconPickerItemComponent } from './sc-icon-picker/sc-icon-picker-item/sc-icon-picker-item.component';
import { SCIconPickerComponent } from './sc-icon-picker/sc-icon-picker.component';
import { SCInlineConfirmationComponent } from './sc-inline-confirmation/sc-inline-confirmation.component';
import { SCIntroBannerContainerComponent } from './sc-intro-banner-container/sc-intro-banner-container.component';
import { SCIntroBannerComponent } from './sc-intro-banner-container/sc-intro-banner/sc-intro-banner.component';
import { SCListComponent } from './sc-list/sc-list.component';
import { SCMassUploaderComponent } from './sc-mass-uploader/sc-mass-uploader.component';
import { MenuGroupHeaderComponent } from './sc-menu/menu-group-header/menu-group-header.component';
import { MenuItemChildComponent } from './sc-menu/menu-item-child/menu-item-child.component';
import { MenuItemGroupComponent } from './sc-menu/menu-item-group/menu-item-group.component';
import { MenuItemComponent } from './sc-menu/menu-item/menu-item.component';
import { SCMenuComponent } from './sc-menu/sc-menu.component';
import { SCModalComponent } from './sc-modal/sc-modal.component';
import { SCNumberPickerComponent } from './sc-number-picker/sc-number-picker.component';
import { SCPagerBulletsComponent } from './sc-pager-bullets/sc-pager-bulelts.component';
import { SCPaginatedListComponent } from './sc-paginated-list/sc-paginated-list.component';
import { SCPictureUploaderComponent } from './sc-picture-uploader/sc-picture-uploader.component';
import { SCPrivacySettingsComponent } from './sc-privacy-settings/sc-privacy-settings.component';
import { SCPublicContainerComponent } from './sc-public-container/sc-public-container.component';
import { SCSearchAlertComponent } from './sc-search-alert/sc-search-alert.component';
import { SCSwitchComponent } from './sc-switch/sc-switch.component';
import { SCUserBioComponent } from './sc-user-bio/sc-user-bio.component';
import { SCUserTypeaheadComponent } from './sc-user-typeahead/sc-user-typeahead.component';
import { SCUserComponent } from './sc-user/sc-user.component';
import { SCZeroDataComponent } from './sc-zero-data/sc-zero-data.component';
import { SocialMediaFooterPtBrComponent } from './social-media-footer-pt-br/social-media-footer-pt-br.component';
import { TSqBoxComponent } from './tsq-box/tsq-box.component';
import { TsqCardForSwitchComponent } from './tsq-card-for-switch/tsq-card-for-switch.component';
import { TSqCenterModalComponent } from './tsq-center-modal/tsq-center-modal.component';
import { TSqCollapseCardModule } from './tsq-collapse-card/tsq-collapse-card.module';
import { TSqCommentBoxComponent } from './tsq-comment-box/tsq-comment-box.component';
import { TSqCommentCardComponent } from './tsq-comment-card/tsq-comment-card.component';
import { TsqConfirmationDialogComponent } from './tsq-confimation-dialog/tsq-confirmation-dialog.component';
import { TSqConfirmationModalComponent } from './tsq-confirmation-modal/tsq-confirmation-modal.component';
import { TsqDoubleColumnModalComponent } from './tsq-double-column-modal/tsq-double-column-modal.component';
import { TSqGalleryPreviewComponent } from './tsq-gallery/components/tsq-gallery-preview/tsq-gallery-preview.component';
import { TSqGalleryComponent } from './tsq-gallery/components/tsq-gallery/tsq-gallery.component';
import { TSqGaugeComponent } from './tsq-gauge/tsq-gauge.component';
import { TSqGroupsSelectorComponent } from './tsq-group-selector/tsq-groups-selector.component';
import { TSqInfoCardComponent } from './tsq-info-card/tsq-info-card.component';
import { TSqLinkDataCardComponent } from './tsq-link-data-card/tsq-link-data-card.component';
import { TsqMainInfoCardModule } from './tsq-main-info-card/tsq-main-info-card.module';
import { TSqModalComponent } from './tsq-modal/tsq-modal.component';
import { TSqNavbarComponent } from './tsq-navbar/tsq-navbar.component';
import { TsqPagerBulletComponent } from './tsq-pager-bullet/tsq-pager-bullet.component';
import { TsqPrimaryButtonComponent } from './tsq-primary-button/tsq-primary-button.component';
import { TSqProfileDropdownComponent } from './tsq-profile-dropdown/tsq-profile-dropdown.component';
import { TSqRadioInlineComponent } from './tsq-radio-inline/tsq-radio-inline.component';
import { TSQSmallProgressBarComponent } from './tsq-small-progress-bar/tsq-small-progress-bar.component';
import { TSqTooltipComponent } from './tsq-tooltip/tsq-tooltip.component';
import { TSqTwoHandlesSliderComponent } from './tsq-two-handles-slider/tsq-two-handles-slider.component';
import { TSqUserGroupPermissionsSelectorComponent } from './tsq-user-group-permissions-selector/tsq-user-group-permissions-selector.component';

defineLocale('pt-br', ptBrLocale);

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    FormsModule,
    ModalModule,
    AlertModule,
    ProgressbarModule,
    TypeaheadModule,
    ButtonsModule,
    RatingModule,
    FileUploadModule,
    InfiniteScrollModule,
    TranslateModule,
    BsDropdownModule,
    TSqLoadersModule,
    TSqPipesModule,
    TSqGenericComponentsModule,
    TSqFilesModule,
    TSqCollapseCardModule,
    TsqMainInfoCardModule,
    AssetsModule,
    HighlightDirective,
  ],
  declarations: [
    SCPagerBulletsComponent,
    SCModalComponent,
    TSQSmallProgressBarComponent,
    SCCategoryPermissionSelectionComponent,
    SCPrivacySettingsComponent,
    LinkDataListComponent,
    SCRadioButtonComponent,
    SCSearchAlertComponent,
    SCListComponent,
    SCPaginatedListComponent,
    SCMenuComponent,
    SCSwitchComponent,
    SCUserComponent,
    MenuItemGroupComponent,
    MenuItemChildComponent,
    MenuItemComponent,
    SCConfirmationDialogComponent,
    MenuGroupHeaderComponent,
    SCConfigItemComponent,
    SCMassUploaderComponent,
    SCNumberPickerComponent,
    SCIntroBannerContainerComponent,
    SCIntroBannerComponent,
    SCCalendarComponent,
    SCCalendarDayComponent,
    SCZeroDataComponent,
    SanitizeHtmlPipe,
    SanitizeUrlPipe,
    SCDateNavigatorComponent,
    SCUserTypeaheadComponent,
    SCPictureUploaderComponent,
    SCCommentInputComponent,
    SCCommentStatusComponent,
    SCAttachmentViewComponent,
    SCCommentBubbleComponent,
    SCInlineConfirmationComponent,
    SCTextareaAutosizeDirective,
    NewLineToBreakLinePipe,
    SCErrorComponent,
    SCPublicContainerComponent,
    SCButtonGroupComponent,
    TransformTimePipe,
    SCCategoryVisibilityModalComponent,
    MobileDetectionOverlayComponent,
    SCUserBioComponent,
    TsqConfirmationDialogComponent,
    TsqDoubleColumnModalComponent,
    TSqBoxComponent,
    TSqTwoHandlesSliderComponent,
    TSqGaugeComponent,
    TSqTooltipComponent,
    TSqInfoCardComponent,
    TSqModalComponent,
    TsqPrimaryButtonComponent,
    TsqCardForSwitchComponent,
    TsqPagerBulletComponent,
    TSqCenterModalComponent,
    TransitionGroupComponent,
    TransitionGroupItemDirective,
    SCColorPickerComponent,
    SCColorPickerItemComponent,
    SCIconPickerComponent,
    SCIconPickerItemComponent,
    TSqGroupsSelectorComponent,
    TSqConfirmationModalComponent,
    TSqRadioInlineComponent,
    TSqRadioInlineComponent,
    TSqUserGroupPermissionsSelectorComponent,
    TSqNavbarComponent,
    TSqProfileDropdownComponent,
    CEPValidatorDirective,
    TSqLinkDataCardComponent,
    TSqCommentCardComponent,
    TSqCommentBoxComponent,
    TSqGalleryComponent,
    TSqGalleryPreviewComponent,
    AccordionComponent,
    ModalFormConfirmAlertComponent,
    SocialMediaFooterPtBrComponent,
  ],
  exports: [
    SCPrivacySettingsComponent,
    TSQSmallProgressBarComponent,
    SCButtonGroupComponent,
    TransformTimePipe,
    SCCategoryPermissionSelectionComponent,
    LinkDataListComponent,
    SCPaginatedListComponent,
    MenuItemGroupComponent,
    SCRadioButtonComponent,
    SCSearchAlertComponent,
    TranslateModule,
    InfiniteScrollModule,
    SCModalComponent,
    SCListComponent,
    SCMenuComponent,
    SCUserComponent,
    MenuItemChildComponent,
    SCMassUploaderComponent,
    SCNumberPickerComponent,
    SCSwitchComponent,
    SCIntroBannerContainerComponent,
    SCIntroBannerComponent,
    MenuItemComponent,
    SCConfirmationDialogComponent,
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    MenuGroupHeaderComponent,
    FileUploadModule,
    SCCalendarComponent,
    SCZeroDataComponent,
    SCConfigItemComponent,
    SanitizeHtmlPipe,
    SanitizeUrlPipe,
    SCDateNavigatorComponent,
    SCUserTypeaheadComponent,
    SCPictureUploaderComponent,
    SCCommentInputComponent,
    SCCommentStatusComponent,
    SCAttachmentViewComponent,
    SCCommentBubbleComponent,
    SCInlineConfirmationComponent,
    NewLineToBreakLinePipe,
    RatingModule,
    SCErrorComponent,
    SCPublicContainerComponent,
    SCPagerBulletsComponent,
    SCCategoryVisibilityModalComponent,
    MobileDetectionOverlayComponent,
    SCUserBioComponent,
    TsqConfirmationDialogComponent,
    TsqDoubleColumnModalComponent,
    TSqBoxComponent,
    TSqTwoHandlesSliderComponent,
    TSqGaugeComponent,
    TSqTooltipComponent,
    TSqInfoCardComponent,
    TSqModalComponent,
    TsqPrimaryButtonComponent,
    TsqCardForSwitchComponent,
    TSqCenterModalComponent,
    TsqPagerBulletComponent,
    TransitionGroupComponent,
    TransitionGroupItemDirective,
    SCColorPickerComponent,
    SCColorPickerItemComponent,
    SCIconPickerComponent,
    SCIconPickerItemComponent,
    TSqGroupsSelectorComponent,
    TSqConfirmationModalComponent,
    TSqRadioInlineComponent,
    TSqNavbarComponent,
    TSqProfileDropdownComponent,
    TSqRadioInlineComponent,
    TSqUserGroupPermissionsSelectorComponent,
    CEPValidatorDirective,
    TSqLinkDataCardComponent,
    TSqCommentCardComponent,
    TSqCommentBoxComponent,
    TSqGalleryComponent,
    TSqCollapseCardModule,
    TsqMainInfoCardModule,
    AccordionComponent,
    ModalFormConfirmAlertComponent,
    SocialMediaFooterPtBrComponent,
  ],
})
export class SCWidgetModule {}
