import {TranslateService} from '@ngx-translate/core';

import {User} from '@tsq-web/users';

export function hasPropertiesInCondo(user: User, condoId: string): boolean {
  if (!user || !user.properties || !user.properties.length) {
    return false;
  }
  const inCondoRoles = user.roles.filter(role => role.condo_id === condoId);
  return !!user.properties.filter(property => inCondoRoles.some(role => role.prop_id === property.id)).length;
}

export function getPropertyDescription(user: User, translateService: TranslateService): string {
  if (!user.properties || !user.properties.length) {
    return translateService.instant('LIBS.NO_PROPERTIES');
  }
  return user.properties.length === 1 ? user.properties[0].fine_description : translateService.instant('LIBS.MULTIPLE_PROPERTIES');
}
