import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { icons } from '@tsq-web/assets';

@Component({
  selector: 'comm-already-registered-banner',
  templateUrl: './already-registered-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlreadyRegisteredBannerComponent {
  @Input() showAlreadyRegisteredBannerAdditionalInfo: boolean;

  @Output() closeBanner = new EventEmitter<void>();

  readonly xmarkIcon = icons.xmark;
}
