/* eslint-disable @typescript-eslint/naming-convention */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import IMask from 'imask';

import { User } from '@tsq-web/users';
import { TSqValidators } from '@tsq-web/forms';
import { UserService } from '../../../shared/user/user.service';
import { SubscriptionResponsible } from '../../../shared/subscription/subscription-responsible.json';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'subscription-responsible-selection',
  templateUrl: './subscription-responsible-selection.component.html',
  styleUrls: ['./subscription-responsible-selection.component.scss'],
})
export class SubscriptionResponsibleSelectionComponent implements OnInit {
  @Input() condoId: string;
  @Input() responsibleUser: User;

  @Output() subscriptionResponsibleChanged = new EventEmitter<SubscriptionResponsible>();
  @Output() validityChanged = new EventEmitter<boolean>();

  form: UntypedFormGroup;
  valid = false;

  phoneMask: IMask.AnyMaskedOptions = this.getPhoneMask();

  constructor(private _translateService: TranslateService, private _userService: UserService) {}

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, TSqValidators.validEmail]),
      phone: new UntypedFormControl('', Validators.nullValidator),
    });

    this.form.valueChanges.subscribe((form: Record<string, unknown>) => {
      if (this.valid !== form.valid) {
        this.valid = this.form.valid;
        this.validityChanged.emit(this.valid);
      }
    });

    if (this.responsibleUser) {
      this.form.controls.email.setValue(
        this.responsibleUser.email ? this.responsibleUser.email : '',
      );
      this.form.controls.phone.setValue(
        this.responsibleUser.phone ? this.responsibleUser.phone : '',
      );
    }

    this.form.valueChanges.subscribe((form: Record<string, string>) => {
      this.subscriptionResponsibleChanged.emit(
        SubscriptionResponsible.from(form.email, form.phone),
      );
    });

    this._userService
      .getResidentsForAdmin(this.condoId, true, true)
      .subscribe(users => (this._userService.cachedUsers = users));
  }

  typeaheadUserSelected(user: User): void {
    if (user !== undefined) {
      this.responsibleUser = user;
      const phone = user.phones.length > 0 ? user.phones[0] : '';
      this.form.controls.email.setValue(user.email ? user.email : '');
      this.form.controls.phone.setValue(phone);
    } else {
      this.responsibleUser = undefined;
      this.form.controls.email.setValue('');
      this.form.controls.phone.setValue('');
    }
  }

  private getPhoneMask(): IMask.AnyMaskedOptions {
    if (this._translateService.currentLang === 'pt') {
      return {
        mask: '(00) 00000-0000',
        lazy: false,
      };
    }

    return {
      mask: '(000) 000-0000',
      lazy: false,
    };
  }
}
