import { CommonModule } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild, ViewContainerRef, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, combineLatest, delay } from 'rxjs';

import { TSqValidators } from '@tsq-web/forms';
import {
  ModalContext,
  ModalPositions,
  ModalService,
  ModalSize,
  ModalTypes,
  TSqModalsModule,
  TSqTemplatePortal,
  ofModalType,
} from '@tsq-web/modals';

import { GeneralRatingStepComponent } from '../../components/general-rating-step/general-rating-step.component';
import { RatingDetailsStepComponent } from '../../components/rating-details-step/rating-details-step.component';
import { ThankYouStepComponent } from '../../components/thank-you-step/thank-you-step.component';
import { TownSqArticleComponent } from '../../components/townsq-article/townsq-article.component';
import { InAppFeedbackSteps } from '../../models/in-app-feedback-steps.enum';
import { InAppFeedbackService } from '../../services/in-app-feedback.service';
import { InAppFeedbackStore } from '../../state/in-app-feedback.store';

@UntilDestroy()
@Component({
  selector: 'comm-in-app-feedback-modal',
  templateUrl: './in-app-feedback-modal.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TSqModalsModule,
    GeneralRatingStepComponent,
    RatingDetailsStepComponent,
    ThankYouStepComponent,
    TownSqArticleComponent,
  ],
  providers: [InAppFeedbackStore, InAppFeedbackService],
})
export class InAppFeedbackModalComponent implements OnInit {
  @ViewChild('modal') modalRef: TemplateRef<ModalContext>;

  steps = InAppFeedbackSteps;
  reasonControl = new FormControl('');
  isReasonRequired = false;
  suggestionChips: string[] = [];
  selectedChipsIndexes: number[] = [];

  isFeedbackPositive: boolean;
  activeStep$: Observable<InAppFeedbackSteps>;

  readonly modalSize = ModalSize;

  private positiveChips: string[];
  private negativeChips: string[];

  private readonly componentStore = inject(InAppFeedbackStore);
  private readonly modalService = inject(ModalService);
  private readonly viewContainerRef = inject(ViewContainerRef);
  private readonly modalContext: ModalContext = {
    position: ModalPositions.CENTER,
    disableClose: false,
    size: ModalSize.Small,
  };

  ngOnInit(): void {
    this.modalService.type$
      .pipe(untilDestroyed(this), ofModalType(ModalTypes.InAppFeedbackModal))
      .subscribe(() => {
        this.modalService.setPortal(
          new TSqTemplatePortal(this.modalRef, this.viewContainerRef, this.modalContext),
        );

        if (!this.positiveChips?.length) {
          this.componentStore.getChips(true);
        }
        if (!this.negativeChips?.length) {
          this.componentStore.getChips(false);
        }
      });
    this.modalService.closed$
      .pipe(ofModalType(ModalTypes.InAppFeedbackModal), untilDestroyed(this), delay(400))
      .subscribe(() => {
        this.reasonControl.reset();
        this.componentStore.restoreSteps();
        this.isFeedbackPositive = undefined;
      });

    combineLatest([this.componentStore.positiveChips$, this.componentStore.negativeChips$])
      .pipe(untilDestroyed(this))
      .subscribe(([positiveChips, negativeChips]) => {
        this.positiveChips = positiveChips;
        this.negativeChips = negativeChips;
        this.updateSuggestionChips();
      });

    this.activeStep$ = this.componentStore.activeStep$;
  }

  closeModal(): void {
    this.modalService.close();
  }

  submitFeedback(): void {
    const feedback = {
      positive: this.isFeedbackPositive,
      text: this.reasonControl.value?.trim() || null,
      chips: this.suggestionChips.filter((_, index) => this.selectedChipsIndexes.includes(index)),
    };
    this.componentStore.postFeedback(feedback);
    this.goToNextStep(ModalSize.Small);
  }

  goToNextStep(modalSize: ModalSize): void {
    this.componentStore.goToNextStep();
    this.modalService.updateContext({ size: modalSize });
  }

  onRateButtonClicked(event: { isPositive: boolean }): void {
    if (this.isFeedbackPositive !== event.isPositive) {
      this.isFeedbackPositive = event.isPositive;
      this.modalService.updateContext({ size: ModalSize.Medium });
      this.updateSuggestionChips();
    }
  }

  onSuggestionSelected(chipIndex: number): void {
    const selectedItemIndex = this.selectedChipsIndexes.indexOf(chipIndex);

    if (selectedItemIndex === -1) {
      this.selectedChipsIndexes.push(chipIndex);
    } else {
      this.selectedChipsIndexes.splice(selectedItemIndex, 1);
    }

    this.updateReasonControlValidator();
  }

  private updateSuggestionChips(): void {
    this.selectedChipsIndexes = [];

    if (this.isFeedbackPositive) {
      this.suggestionChips = this.positiveChips;
      return;
    }
    this.suggestionChips = this.negativeChips;

    this.reasonControl.reset();
    this.updateReasonControlValidator();
  }

  private updateReasonControlValidator(): void {
    if (this.selectedChipsIndexes.includes(this.suggestionChips?.length - 1)) {
      this.reasonControl.addValidators([
        Validators.required,
        TSqValidators.emptyOrHasCharDifferentThanWhitespace,
      ]);
    } else {
      this.reasonControl.setValidators([]);
    }

    this.reasonControl.updateValueAndValidity();
  }
}
