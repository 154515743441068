<div class="font-base flex flex-col p-16 pt-0">
  <div class="bg-petro-n2 mt-8 h-4 w-72"></div>

  <div class="mt-16">
    <p class="mb-8">
      {{
        'IN_APP_FEEDBACK.WHAT_DO_YOU_' + (isPositiveFeedback ? '' : 'DONT_') + 'LIKE' | translate
      }}
    </p>

    <comm-chips-group
      [options]="suggestionChips"
      [selectedMultiple]="selectedChipsIndexes"
      (selectedChange)="suggestionSelected.emit($event)"
    />
  </div>

  <div
    data-testid="rating-details-step--div--form-container"
    class="mt-24"
    [class.h-144]="!selectedChipsIndexes.length"
  >
    <ng-container *ngIf="!!selectedChipsIndexes.length">
      <div class="bg-petro-n2 mt-8 h-4 w-72"></div>

      <form class="mb-4 mt-16">
        <div class="group">
          <label
            for="rating-details-step--textarea--reason"
            [tsqOptionalLabel]="!isReasonMandatory"
          >
            {{ 'IN_APP_FEEDBACK.SHARE_DETAILS' | translate }}
          </label>
          <textarea
            id="rating-details-step--textarea--reason"
            class="control font-base"
            rows="3"
            tsqTextInput
            [placeholder]="'IN_APP_FEEDBACK.LEAVE_A_COMMENT' | translate"
            [formControl]="reasonControl"
          ></textarea>
        </div>
      </form>
    </ng-container>
  </div>

  <p class="text-coal-tertiary mb-0 mt-24 text-sm">
    {{ 'IN_APP_FEEDBACK.TERMS_AGREEMENT' | translate }}
  </p>
</div>
<div class="modal-footer font-base">
  <tsq-button
    [text]="'IN_APP_FEEDBACK.SUBMIT' | translate"
    [disabled]="reasonControl.invalid || !selectedChipsIndexes.length"
    (clicked)="feedbackSubmitted.emit()"
  />
</div>
