import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';

import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, switchMap, tap } from 'rxjs';

import { ToastErrorComponent } from '@tsq-web/toast';

import { RecoveryRequestSteps } from '../../models/recovery-request-steps.enum';
import { ResetPasswordResponse } from '../../models/reset-password-response.model';
import { ResetPasswordService } from '../../services/reset-password.service';

interface RequestRecoveryLinkState {
  isSendingRecoveryLink: boolean;
  currentRequestLinkStep: RecoveryRequestSteps;
  isSsoEmail: boolean;
  email?: string;
}

const initialState: RequestRecoveryLinkState = {
  isSendingRecoveryLink: false,
  currentRequestLinkStep: RecoveryRequestSteps.Request,
  isSsoEmail: false,
  email: undefined,
};

@Injectable()
export class RequestRecoveryLinkStore extends ComponentStore<RequestRecoveryLinkState> {
  readonly isSendingRecoveryLink$ = this.select(
    ({ isSendingRecoveryLink }) => isSendingRecoveryLink,
  );
  readonly currentRequestLinkStep$ = this.select(
    ({ currentRequestLinkStep }) => currentRequestLinkStep,
  );
  readonly isSsoEmail$ = this.select(({ isSsoEmail }) => isSsoEmail);
  readonly email$ = this.select(({ email }) => email);

  readonly requestRecoveryLink = this.effect((email$: Observable<string>) =>
    email$.pipe(
      tap(() => this.setIsSendingRecoveryLink(true)),
      switchMap(email =>
        this.requestRecoveryLinkService.resetPassword(email).pipe(
          tapResponse(
            requestLinkResponse => this.linkRequestedSuccess(requestLinkResponse),
            (error: HttpErrorResponse) => {
              if (error.status === HttpStatusCode.NotFound) {
                return this.linkRequestedSuccess({ singleSignOn: false, username: email });
              }

              this.setIsSendingRecoveryLink(false);
              this.toastr.show(
                this.translateService.instant('COMMON_ERROR_TEXT'),
                this.translateService.instant('COMMON_ERROR_TITLE'),
                { toastComponent: ToastErrorComponent },
              );
            },
          ),
        ),
      ),
    ),
  );

  private readonly setIsSendingRecoveryLink = this.updater(
    (state, isSendingRecoveryLink: boolean) => ({
      ...state,
      isSendingRecoveryLink,
    }),
  );
  private readonly linkRequestedSuccess = this.updater(
    (state, response: ResetPasswordResponse) => ({
      ...state,
      isSendingRecoveryLink: false,
      isSsoEmail: response.singleSignOn,
      currentRequestLinkStep: response.singleSignOn
        ? RecoveryRequestSteps.Request
        : RecoveryRequestSteps.Success,
      email: response.username,
    }),
  );

  private readonly requestRecoveryLinkService = inject(ResetPasswordService);
  private readonly toastr = inject(ToastrService);
  private readonly translateService = inject(TranslateService);
  private readonly router = inject(Router);

  constructor() {
    super(initialState);
  }

  goBack(): void {
    switch (this.get().currentRequestLinkStep) {
      case RecoveryRequestSteps.Request:
        this.router.navigate(['login']);
        break;
      default:
        this.patchState({ currentRequestLinkStep: RecoveryRequestSteps.Request });
    }
  }
}
