<div class="col-md-12 config-item">
  <div class="row">
    <div (click)="onClick()" class="config-item-clickable">
      <div class="col-xs-10 config-item-title info-title" [ngClass]="{'active': active}">{{title}}</div>
      <div class="col-xs-2">
        <i *ngIf="!active" class="fa fa-angle-right"></i>
        <i *ngIf="active" class="fa fa-angle-down"></i>
      </div>
    </div>
  </div>
  <div class="row" [hidden]="!active">
    <div class="col-md-12 config-item-content">
      <ng-content></ng-content>
    </div>
  </div>
  <div class="row">
    <div class="divider"></div>
  </div>
</div>
