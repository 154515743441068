import { Feature } from '@tsq-web/feature';

import { CondoSubscription } from './condo-subscription.json';
import { PaymentInfo } from './payment-info.json';

export class SubscriptionDetails {
  subscription: CondoSubscription;
  paymentInfo: PaymentInfo;
  featuresAvailable: Feature[] = [];

  static from(subscription: CondoSubscription, paymentInfo: PaymentInfo): SubscriptionDetails {
    const sp = new SubscriptionDetails();
    sp.subscription = subscription;
    sp.paymentInfo = paymentInfo;
    return sp;
  }
}
