import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { UserFavoritesService } from './service/user-favorites.service';
import { UserRecoveryService } from './service/user-recovery.service';
import { UsersService } from './service/users.service';
import { UsersEffects } from './state/users.effects';
import { usersReducer } from './state/users.reducer';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    StoreModule.forFeature('users', usersReducer),
    EffectsModule.forFeature([UsersEffects]),
  ],
  providers: [UsersService, UserRecoveryService, UserFavoritesService],
})
export class TSqUsersModule {}
