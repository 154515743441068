import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { AssetsModule } from '@tsq-web/assets';
import { TSqFormsModule } from '@tsq-web/forms';
import { TSqGenericComponentsModule } from '@tsq-web/generic-components';
import { TSqLoadersModule } from '@tsq-web/loaders';
import { TSqUsersModule } from '@tsq-web/users';

import { SsoResetYourPasswordCardComponent } from './components/sso-reset-your-password-card/sso-reset-your-password-card.component';
import { TSqLoginComponent } from './containers/tsq-login/tsq-login.component';
import { ChangeContextGuard } from './guards/change-context.guard';
import { ContextLoginBypassGuard } from './guards/context-login-bypass.guard';
import { LoginService } from './services/login.service';
import { OptInService } from './services/opt-in.service';
import { SingleSignOnService } from './services/single-sign-on.service';
import { SsoResetPasswordService } from './services/sso-reset-password.service';
import { SsoResetPasswordStore } from './state/sso-reset-password.store';

@NgModule({
  imports: [
    AssetsModule,
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    TSqFormsModule,
    TSqUsersModule,
    TSqLoadersModule,
    TSqGenericComponentsModule,
  ],
  declarations: [TSqLoginComponent, SsoResetYourPasswordCardComponent],
  exports: [TSqLoginComponent],
  providers: [
    LoginService,
    ContextLoginBypassGuard,
    ChangeContextGuard,
    OptInService,
    SingleSignOnService,
    SsoResetPasswordService,
    SsoResetPasswordStore,
  ],
})
export class TSqLoginModule {}
