import { Pipe, PipeTransform } from '@angular/core';

import { getEnvBasedAssetsPath } from '../utils/assets.utils';

@Pipe({ name: 'envBasedAssetPath' })
export class EnvBasedAssetPathPipe implements PipeTransform {
  transform(path: string): string {
    return getEnvBasedAssetsPath(path);
  }
}
