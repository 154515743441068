import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { TSqLoadersModule } from '@tsq-web/loaders';
import { PlanSelectionComponent } from './plan-selection/plan-selection.component';
import { plansReducer } from './state/plans.reducer';
import { PlansEffects } from './state/plans.effects';
import { PlanService } from './plan.service';

@NgModule({
  declarations: [
    PlanSelectionComponent,
  ],
  exports: [
    PlanSelectionComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    TSqLoadersModule,
    StoreModule.forFeature('plans', plansReducer),
    EffectsModule.forFeature([PlansEffects]),
  ],
  providers: [
    PlanService,
  ],
})
export class CommunityPlansModule {
}
