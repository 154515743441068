import { Component, OnInit, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

import { selectUntilDestroyed } from '@tsq-web/redux/operators';
import { fromUserContextSelectors } from '@tsq-web/user-context';

import { getFeatureToggle } from '../../../shared/feature-toggle';
import { AccountNumberRequest } from '../../models/account-number-request.model';
import { AisAccountMatch } from '../../models/ais-account-match.json';
import { PublicAisService } from '../../services/public-ais.service';

interface AisAddressAccountTypeahead {
  address: string;
  accountMatch: AisAccountMatch;
}

@UntilDestroy()
@Component({
  selector: 'comm-ais-multiple-accounts',
  templateUrl: './ais-multiple-accounts.component.html',
  styleUrls: ['./ais-multiple-accounts.component.scss'],
})
export class AisMultipleAccountsComponent implements OnInit {
  selected: AisAccountMatch;
  hasLoggedInUser: boolean;
  addressControl = new FormControl('');

  private accounts: AisAccountMatch[] = [];
  private accountNumberRequest: AccountNumberRequest;

  private readonly publicAisService = inject(PublicAisService);
  private readonly router = inject(Router);
  private readonly store = inject(Store);

  ngOnInit(): void {
    this.accounts = this.publicAisService.accountsMatch;

    this.addressControl.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      if (!!this.selected && this.selected.address.address1 !== value) {
        this.selected = undefined;
      }
    });

    this.publicAisService.accountNumberRequest$
      .pipe(untilDestroyed(this))
      .subscribe(accountNumberRequest => (this.accountNumberRequest = accountNumberRequest));

    this.store
      .pipe(selectUntilDestroyed(fromUserContextSelectors.selectUser, this))
      .subscribe(user => (this.hasLoggedInUser = !!user));
  }

  get typeaheadAccounts(): AisAddressAccountTypeahead[] {
    return this.accounts.map(acc => ({ address: acc.address.address1, accountMatch: acc }));
  }

  get isAddressValid(): boolean {
    return !!this.selected;
  }

  onSelectAddress(event: TypeaheadMatch): void {
    this.selected = (event.item as AisAddressAccountTypeahead).accountMatch;
  }

  onContinue(): void {
    if (getFeatureToggle().registration.signUp && !this.hasLoggedInUser) {
      this.router.navigate(['registration'], {
        state: { selectedAccount: this.selected, accountNumberRequest: this.accountNumberRequest },
      });

      return;
    }

    this.publicAisService.selectedAccount = this.selected;

    this.router.navigate(['ais', 'sign-up', this.handleOnContinuePath()], {
      queryParamsHandling: 'merge',
    });
  }

  private handleOnContinuePath(): string {
    if (this.selected.account.owners.length > 1) {
      return 'owner-selection';
    }

    this.publicAisService.owner = this.selected.account.owners[0];
    return 'link-user';
  }
}
