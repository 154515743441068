/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sc-number-picker',
  templateUrl: 'sc-number-picker.component.html',
  styleUrls: ['sc-number-picker.component.scss'],
  // directives: [FORM_DIRECTIVES]
})
export class SCNumberPickerComponent {
  @Input() placeholder: string;
  @Input() initialValue = 0;
  @Input() minValue: number = undefined;
  @Input() maxValue: number = undefined;
  @Input() disableTyping = false;
  @Output() initialValueChange = new EventEmitter();
  @Output() incrementCounter = new EventEmitter<number>();
  @Output() decrementCounter = new EventEmitter<number>();

  onIncrementCounter(): void {
    if (this.maxValue !== undefined && this.maxValue <= this.initialValue) {
      return;
    }
    this.initialValue = this.value + 1;
    this.valueChanged();
    this.incrementCounter.emit(this.initialValue);
  }

  onDecrementCounter(): void {
    if (this.minValue !== undefined && this.minValue >= this.initialValue) {
      return;
    }
    this.initialValue = this.value - 1;
    this.valueChanged();
    this.decrementCounter.emit(this.initialValue);
  }

  decrement() {
    this.initialValue = this.value - 1;
  }

  valueChanged() {
    if (this.initialValue > this.maxValue) {
      this.initialValue = this.maxValue;
    }
    if (this.initialValue < this.minValue) {
      this.initialValue = this.minValue;
    }
    this.initialValueChange.emit(this.initialValue);
  }

  isValid(): boolean {
    if (this.minValue !== undefined && this.minValue > this.initialValue) {
      return false;
    }
    if (this.maxValue !== undefined && this.maxValue < this.initialValue) {
      return false;
    }

    return true;
  }

  getInvalidClass(): string {
    return !this.isValid() ? 'sc-invalid' : '';
  }

  get value(): number {
    return this.initialValue || 0;
  }
}
