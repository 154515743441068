export interface Icon {
  code: string;
  highlighted: boolean;
  selected: boolean;
}

export class TSqIconPickerIconList {
  readonly icons: Icon[] = [
    { code: 'fa-building-o', highlighted: true, selected: false },
    { code: 'fa-bullhorn', highlighted: true, selected: false },
    { code: 'fa-ban', highlighted: true, selected: false },
    { code: 'fa-calculator', highlighted: true, selected: false },
    { code: 'fa-calendar-check-o', highlighted: true, selected: false },
    { code: 'fa-dollar', highlighted: true, selected: false },
    { code: 'fa-envelope', highlighted: true, selected: false },
    { code: 'fa-font-awesome', highlighted: true, selected: false },
    { code: 'fa-leaf', highlighted: true, selected: false },
    { code: 'fa-eye', highlighted: true, selected: false },
    { code: 'fa-exclamation-triangle', highlighted: true, selected: false },
    { code: 'fa-fire-extinguisher', highlighted: true, selected: false },
    { code: 'fa-cutlery', highlighted: true, selected: false },
    { code: 'fa-handshake-o', highlighted: true, selected: false },
    { code: 'fa-gavel', highlighted: true, selected: false },
    { code: 'fa-hourglass', highlighted: true, selected: false },
    { code: 'fa-key', highlighted: true, selected: false },
    { code: 'fa-lightbulb-o', highlighted: true, selected: false },
    { code: 'fa-map-marker', highlighted: true, selected: false },
    { code: 'fa-lock', highlighted: true, selected: false },
    { code: 'fa-paperclip', highlighted: true, selected: false },
    { code: 'fa-bell-o', highlighted: true, selected: false },
    { code: 'fa-phone', highlighted: true, selected: false },
    { code: 'fa-pencil', highlighted: true, selected: false },
    { code: 'fa-search', highlighted: true, selected: false },
    { code: 'fa-wrench', highlighted: true, selected: false },
    { code: 'fa-star', highlighted: true, selected: false },
    { code: 'fa-suitcase', highlighted: true, selected: false },
    { code: 'fa-truck', highlighted: true, selected: false },
    { code: 'fa-book', highlighted: true, selected: false },
    { code: 'fa-heart', highlighted: true, selected: false },
    { code: 'fa-car', highlighted: true, selected: false },
    { code: 'fa-recycle', highlighted: true, selected: false },
  ];
}
