import { Component, HostListener, Renderer2, inject } from '@angular/core';
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterEvent } from '@angular/router';

import { Store, select } from '@ngrx/store';
import moment from 'moment-timezone';
import 'moment/min/locales.min';
import { CookieService } from 'ngx-cookie-service';
import { delay } from 'rxjs/operators';

import { setLanguageBasedOnCookie } from '@tsq-web/i18n';
import { fromLocalizationActions } from '@tsq-web/localization';
import { AppState } from '@tsq-web/state';
import { fromUserContextSelectors } from '@tsq-web/user-context';
import { User } from '@tsq-web/users';

import { getFeatureToggle } from './shared/feature-toggle';

@Component({
  selector: 'comm-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  routing: boolean;
  featureToggle = getFeatureToggle();

  private user: User;

  private readonly renderer = inject(Renderer2);

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private store: Store<AppState>,
  ) {
    this.setUserLanguage();
    this.setNavigatorClass();

    this.store
      .pipe(select(fromUserContextSelectors.selectUser))
      .subscribe(user => (this.user = user));

    this.router.events.pipe(delay(100)).subscribe((event: RouterEvent) => {
      if (event instanceof RouteConfigLoadStart) {
        this.routing = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.routing = false;
      }
    });
  }

  @HostListener('window:storage', ['$event']) private onStorageChange(e: StorageEvent): void {
    if (e.key === 'refresh' && e.newValue === 'true') {
      window.location.reload();
    }
    if (e.key === 'changeCondo') {
      this.router.navigate(['w', e.newValue, 'feed']);
      window.location.reload();
    }
    if (e.key === 'currentUser' && !!this.user && this.user.id !== e.newValue) {
      window.location.reload();
    }
  }

  private setUserLanguage(): void {
    setLanguageBasedOnCookie('sc-lang', ['en', 'pt', 'es']);
    const userLang = this.cookieService.get('sc-lang');

    this.store.dispatch(fromLocalizationActions.setLanguage({ language: userLang }));
    this.store.dispatch(fromLocalizationActions.setTimeZone({ timeZone: moment.tz.guess() }));

    switch (userLang) {
      case 'es':
        moment.locale('es');
        break;
      case 'pt':
        moment.locale('pt-BR');
        break;
      default:
        break;
    }
  }

  private setNavigatorClass(): void {
    const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    if (isChrome) {
      this.renderer.addClass(document.body, 'is-chrome');
    }
  }
}
