import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { ModalService } from '../../services/modal.service';
import { ModalContext } from '../../models/modal-context';
import { TSqTemplatePortal } from '../../models/template-portal';
import { ModalPositions } from '../../models/modal-positions.enum';
import { ModalTypes } from '../../models/modal-types.enum';
import { ModalSize } from '../../models/modal-size.enum';

@Component({
  selector: 'tsq-delete-confirmation-modal',
  templateUrl: './tsq-delete-confirmation-modal.component.html',
  styleUrls: ['./tsq-delete-confirmation-modal.component.scss'],
})
export class TSqDeleteConfirmationModalComponent {

  @ViewChild('modalContent', { static: true }) modalRef: TemplateRef<ModalContext>;

  @Input() icon: string;
  @Input() title: string;
  @Input() description: string;

  @Input() cancelCustomText: string;
  @Input() confirmCustomText: string;

  @Output() cancel = new EventEmitter();
  @Output() confirm = new EventEmitter();

  private modalContext: ModalContext = {
    position: ModalPositions.CENTER,
    size: ModalSize.Medium,
    disableClose: true,
  };

  constructor(private translateService: TranslateService,
              private viewContainerRef: ViewContainerRef,
              private modalService: ModalService) {
  }

  @Input() set isOpen(value: boolean) {
    if (value) {
      this.modalService.setPortal(new TSqTemplatePortal(this.modalRef, this.viewContainerRef, this.modalContext));
      this.modalService.open(ModalTypes.TSQ_DELETE_CONFIRMATION);
    } else {
      this.modalService.close();
    }
  }
}
