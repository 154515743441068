import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { AppState } from '@tsq-web/state';
import { UserRecoveryService } from '../service/user-recovery.service';
import * as fromUsersActions from './users.actions';

@Injectable()
export class UsersEffects {

  requestUserRecovery$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(fromUsersActions.requestUserRecovery),
        mergeMap(({ email }: { email: string }) =>
          this.userRecoveryService.requestRecovery(email)),
        catchError((err, caught$) => {
          this.store.dispatch(fromUsersActions.requestUserRecoveryFailure());

          return caught$;
        }),
        map(() => fromUsersActions.requestUserRecoverySuccess()),
      ),
  );

  constructor(private actions$: Actions,
              private store: Store<AppState>,
              private userRecoveryService: UserRecoveryService) {
  }
}
