import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { icons } from '@tsq-web/assets';
import {
  ModalContext,
  ModalPositions,
  ModalService,
  ModalSize,
  ModalTypes,
  TSqTemplatePortal,
  ofModalType,
} from '@tsq-web/modals';

@UntilDestroy()
@Component({
  selector: 'tsq-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteModalComponent implements OnInit {
  @ViewChild('modalContent') modalRef: TemplateRef<ModalContext>;

  @Input() deleteIsLoading = false;
  @Input() confirmActionIcon: string;
  @Input() description: string;
  @Input() title: string;
  @Input() confirmActionText: string;
  @Output() confirmDelete = new EventEmitter();

  readonly trashIcon = icons.trash;

  private readonly modalPortalContext: ModalContext = {
    position: ModalPositions.CENTER,
    disableClose: false,
    size: ModalSize.Small,
  };

  constructor(private modalService: ModalService, private viewContainerRef: ViewContainerRef) {}

  ngOnInit(): void {
    this.modalService.type$
      .pipe(ofModalType(ModalTypes.DeleteModal), untilDestroyed(this))
      .subscribe(() =>
        this.modalService.setPortal(
          new TSqTemplatePortal(this.modalRef, this.viewContainerRef, this.modalPortalContext),
        ),
      );
  }

  closeModal(): void {
    this.modalService.close();
  }
}
