import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { Feature } from '@tsq-web/feature';
import { FeaturePermissionGuard, Permission } from '@tsq-web/permission';

import { residentInvitesGuard } from '../+sc-workspace/features/residents/services/residents-invite.guard';
import { NeighborMassInviteComponent } from './containers/main-neighbor-mass-invite/neighbor-mass-invite.component';

const routes: Routes = [
  {
    path: 'invite-neighbors',
    component: NeighborMassInviteComponent,
    canActivate: [FeaturePermissionGuard, residentInvitesGuard],
    data: { feature: Feature.Neighbors, permission: Permission.ADMIN, anyCondo: true },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NeighborMassInviteRoutingModule {}
