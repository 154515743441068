/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'sc-menu',
  templateUrl: './sc-menu.component.html',
  styleUrls: ['sc-menu.component.scss'],
})
export class SCMenuComponent {
  @ViewChild('menuContainer', { static: true }) menuContainer: ElementRef;

  gotoMenuBottom() {
    this.menuContainer.nativeElement.scrollTop =
      this.menuContainer.nativeElement.scrollHeight - this.menuContainer.nativeElement.clientHeight;
  }

  get canScrollDown(): boolean {
    return this.hasScrollBar() && !this.isAtScrollBottom();
  }

  private hasScrollBar() {
    return (
      this.menuContainer.nativeElement.scrollHeight > this.menuContainer.nativeElement.clientHeight
    );
  }

  private isAtScrollBottom() {
    return (
      this.menuContainer.nativeElement.clientHeight + this.menuContainer.nativeElement.scrollTop ===
      this.menuContainer.nativeElement.scrollHeight
    );
  }
}
