<div class="col-xs-12 inline-confirmation" [class.not-confirming]="!confirming">
  <div class="row confirmation-message">
    {{ message }}
  </div>
  <div class="row">
    <div class="col-xs-6">
      <div class="row-action confirm" (click)="confirmed($event)">
        <img src="./assets/img/icons/done-button.svg" alt="" />
        <p>{{ 'YES' | translate }}</p>
      </div>
    </div>
    <div class="col-xs-6">
      <div class="row-action cancel" (click)="cancelled()">
        <img src="./assets/img/icons/facility-cancel.svg" alt="" />
        <p>{{ 'NO' | translate }}</p>
      </div>
    </div>
  </div>
</div>
