import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

import { icons } from '@tsq-web/assets';
import { slideInOut } from '../../animation/accordion.animations';

@Component({
  selector: 'comm-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  animations: [slideInOut],
})
export class AccordionComponent {
  @Input() title: string;
  @Input() icon: string;
  @Input() open = false;
  @Input() hasArrows = false;
  @Input() arrowDownDisabled: boolean;
  @Input() arrowUpDisabled: boolean;
  @Input() classes: string;
  @Input() rightContent: TemplateRef<unknown>;
  @Input() wrongContent: TemplateRef<unknown>;

  @Output() accordionStatus = new EventEmitter<boolean>();
  @Output() arrowUpClicked = new EventEmitter<void>();
  @Output() arrowDownClicked = new EventEmitter<void>();

  readonly icons = icons;
}
