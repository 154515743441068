import { createAction, props } from '@ngrx/store';

import { Condo, IntegrationSettingsRequest, PaymentSettings, UpsellSettings } from '@tsq-web/condo';
import { LoginBody, LoginContext, LoginData, LoginResponse } from '@tsq-web/login';
import { DefaultLandingPages, Favorite, User } from '@tsq-web/users';

export const clearLogin = createAction('[User Context] Clear Login');

export const login = createAction('[User Context] Login', props<{ user: User }>());

export const loginV2 = createAction('[User Context] Login V2', props<{ user: User }>());

export const loginV3 = createAction('[User Context] Login V3', props<{ user: User }>());

export const relogin = createAction('[User Context] Relogin', (logonToken?: string) => ({
  logonToken,
}));

export const loginFailed = createAction(
  '[User Context] Login Failed',
  props<{ obj: LoginData | any }>(),
);

export const loggedIn = createAction('[User Context] Logged In', props<{ loginData: LoginData }>());

export const contextLogin = createAction(
  '[Login] Context Login',
  props<{ loginBody: LoginBody }>(),
);

export const contextLoginFailed = createAction('[TownSq API] Context Login Failed');

export const contextLoginSuccess = createAction(
  '[TownSq API] Context Login Success',
  props<{ loginResponse: LoginResponse }>(),
);

export const bypassLogin = createAction(
  '[User Context] Bypass Login',
  (args?: { contextId: string }) => ({ contextId: args && args.contextId }),
);

export const setUserState = createAction(
  '[User Context] Set User State',
  props<{ loginData: LoginData }>(),
);

export const updateUser = createAction('[User Context] Update User', props<{ user: User }>());

export const clearUserContext = createAction('[User Context] Clear User Context');

export const logout = createAction('[User Context] Logout');

export const logoutFailed = createAction('[TownSq API] Logout Failed');

export const logoutSuccess = createAction('[TownSq API] Logout Success');

export const contextLogout = createAction('[User Context] Context Logout');

export const contextLogoutSuccess = createAction('[User Context] Context Logout Success');

export const goToContextSelection = createAction('[User Context] Go To Context Selection');

export const loadLoginContexts = createAction('[Change Context] Load Login Contexts');

export const loadLoginContextsSuccess = createAction(
  '[Change Context] Load Login Contexts Success',
  props<{ contexts: LoginContext[] }>(),
);

export const loadLoginContextsFailed = createAction('[Change Context] Load Login Contexts Failed');

export const loadLoginContextsNextPage = createAction(
  '[Change Context] Load Login Contexts Next Page',
);

export const loadLoginContextsNextPageSuccess = createAction(
  '[Change Context] Load Login Contexts Next Page Success',
  props<{ contexts: LoginContext[] }>(),
);

export const setCondoById = createAction(
  '[User Context] Set Condo By Id',
  props<{ condo: string; suppressEvent?: boolean }>(),
);

export const setCondo = createAction(
  '[User Context] Set Condo',
  props<{ condo: Condo; suppressEvent?: boolean }>(),
);

export const setContext = createAction(
  '[Login] Set Context',
  props<{ loginContext: LoginContext }>(),
);

export const clearCondo = createAction('[User Context] Clear Condo');

export const clearIntroBanners = createAction('[User Context] Clear Intro Banners');

export const updateCondoPaymentSettings = createAction(
  '[User Context] Update Condo Payment Settings',
  props<{ paymentSettings: PaymentSettings }>(),
);

export const updateCondoPaymentSettingsSuccess = createAction(
  '[TownSq API] Update Condo Payment Settings Success',
);

export const updateCondoPaymentSettingsFailed = createAction(
  '[TownSq API] Update Condo Payment Settings Failed',
);

export const updateCondoIntegrationSettings = createAction(
  '[User Context] Update Condo Integration Settings',
  props<{ integrationSettings: IntegrationSettingsRequest }>(),
);

export const updateCondoIntegrationSettingsSuccess = createAction(
  '[TownSq API] Update Condo Integration Settings Success',
);

export const updateCondoIntegrationSettingsFailed = createAction(
  '[TownSq API] Update Condo Integration Settings Failed',
);

export const patchUpsellSettingsSuccess = createAction(
  '[User Context] Patch Upsell Settings Success',
);

export const patchUpsellSettingsFailed = createAction(
  '[User Context] Patch Upsell Settings Failed',
);

export const patchUpsellSettings = createAction(
  '[User Context] Toggle Condo Upsell Setting',
  props<{ upsellSettings: UpsellSettings }>(),
);

export const getUserTermsAcceptance = createAction('[User Context] Get User Terms Acceptance');

export const getUserTermsAcceptanceSuccess = createAction(
  '[TownSq API] Get User Terms Acceptance Success',
);

export const getUserTermsAcceptanceFailed = createAction(
  '[TownSq API] Get User Terms Acceptance Failed',
);

export const acceptUserTerms = createAction('[User Context] Accept Terms');

export const acceptTermsSuccess = createAction('[TownSq API] Accept Terms Success');

export const acceptTermsFailed = createAction('[TownSqAPI] Accept Terms Failed');

export const setUser = createAction('[User Context] Set User', props<{ user: User }>());

export const updateUserDefaultLandingPage = createAction(
  '[User Context] Update User Default Landing Page',
  props<{ defaultLandingPage: DefaultLandingPages }>(),
);

export const updateUserDefaultLandingPageSuccess = createAction(
  '[TownSq API] Update User Default Landing Page Success',
  props<{ defaultLandingPage: DefaultLandingPages }>(),
);

export const updateUserDefaultLandingPageFailed = createAction(
  '[TownSq API] Update User Default Landing Page Failed',
);

export const authenticate = createAction(
  '[TownSq API] Authenticate',
  props<{ email: string; uri: string }>(),
);

export const ssoAuthenticate = createAction(
  '[TownSq API] SSO Authenticate',
  props<{ email: string; uri: string }>(),
);

export const authSuccess = createAction('[TownSq API] Authentication Success');
export const authFailed = createAction('[TownSq API] Authentication Failed');

export const authTokenExchangeV0 = createAction(
  '[Login] Authentication Token Exchange V0',
  props<{ code: string }>(),
);
export const authTokenExchange = createAction(
  '[Login] Authentication Token Exchange',
  props<{ code: string }>(),
);

export const loadFavorites = createAction('[User Context] Load Favorites');

export const loadFavoritesSuccess = createAction(
  '[User Context] Load Favorites Success',
  props<{ favorites: Favorite }>(),
);

export const loadFavoritesFailure = createAction('[User Context] Load Favorites Failure');

export const saveFavorites = createAction(
  '[User Context] Save Favorites',
  props<{
    features: string[];
    targetFeature: string;
    isPost: boolean;
    operation: 'add' | 'remove';
  }>(),
);

export const saveFavoritesSuccess = createAction('[User Context] Save Favorites Success');

export const saveFavoritesFailure = createAction(
  '[User Context] Save Favorites Failure',
  props<{ targetFeature: string; operation: 'add' | 'remove' }>(),
);
