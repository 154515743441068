import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpService } from '@tsq-web/core';
import { Feature } from '@tsq-web/feature';

import { TSqCommentPresentation } from '../models/tsq-comment-presentation.model';

@Injectable()
export class CommentsService extends HttpService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  editComment(
    comment: TSqCommentPresentation,
    feature: Feature,
  ): Observable<TSqCommentPresentation> {
    let params = new HttpParams();
    params = params.append('feature', feature);

    return this.httpClient.put<TSqCommentPresentation>(
      this.getFullUrl(`comments/${comment.id}`, 'v1'),
      { text: comment.text, attachments: comment.attachments },
      { params },
    );
  }

  deleteComment(
    comment: TSqCommentPresentation,
    feature: Feature,
  ): Observable<TSqCommentPresentation> {
    let params = new HttpParams();
    params = params.append('feature', feature);

    return this.httpClient.delete<TSqCommentPresentation>(
      this.getFullUrl(`comments/${comment.id}`, 'v1'),
      { params },
    );
  }

  adminDeleteComment(
    comment: TSqCommentPresentation,
    feature: Feature,
    reason: string,
  ): Observable<void> {
    let params = new HttpParams();
    params = params.append('feature', feature);

    return this.httpClient.post<undefined>(
      this.getFullUrl(`comments/${comment.id}/censor`, 'v1'),
      { reason },
      { params },
    );
  }

  adminDeleteCommentAssignments(
    assignmentId: string,
    comment: TSqCommentPresentation,
    reason: string,
  ): Observable<void> {
    return this.httpClient.post<undefined>(
      this.getFullUrl(`assignments/${assignmentId}/comments/${comment.id}/censor`, 'v1'),
      { reason },
    );
  }
}
