import { TranslateService } from '@ngx-translate/core';

import {
  TableColumn,
  TableOptionalStringValue,
  TableRequiredStringValue,
  TableRowDrillDownReturnType,
  TableSettingsChangeEvent,
} from './table.model';

export type TableColumnType = 'text' | 'currency' | 'link' | 'avatar' | 'tag' | 'icon' | 'actions';

export type AlignmentOptions = 'left' | 'center' | 'right';

export type TableAlignment = {
  align: AlignmentOptions;
};

export type SizeOptions =
  | 'auto'
  | { fr: number }
  | { px: number }
  | {
      min: 'min-content' | 'max-content' | { px: number };
      max: 'min-content' | 'max-content' | { fr: number } | { px: number };
    }
  | { fitContent: { px: number } };

export type ColumnSize = {
  size: SizeOptions;
};

export type ColumnSort = {
  sort: boolean;
};

export type ColumnVisibility = {
  startHidden: boolean;
};

export type ColumnHeader = {
  header: Partial<TableAlignment> &
    (
      | {
          text?: TableOptionalStringValue;
          fallbackLabel: TableRequiredStringValue;
        }
      | {
          text: TableRequiredStringValue;
          fallbackLabel?: TableOptionalStringValue;
        }
    );
};

export type ColumnFallbackLabel = {
  fallbackLabel: TableRequiredStringValue;
};

type TransformFn<TValue, TReturnType, THasDeps extends boolean> = {
  transform: THasDeps extends true
    ? (value: TValue, deps: { translate: TranslateService }) => TReturnType
    : (value: TValue) => TReturnType;
};

export type ContentValue<
  TRowData,
  TTransformFnRequired extends boolean,
  TTransformFnReturnType,
  THasDeps extends boolean,
> =
  | {
      [K in keyof TRowData]: {
        key: K;
      } & (TTransformFnRequired extends true
        ? TransformFn<TRowData[K], TTransformFnReturnType, THasDeps>
        : Partial<TransformFn<TRowData[K], TTransformFnReturnType, THasDeps>>);
    }[keyof TRowData]
  | (THasDeps extends true
      ? (item: TRowData, deps: { translate: TranslateService }) => TTransformFnReturnType
      : (item: TRowData) => TTransformFnReturnType);

export type TableComputedConfig = {
  row: {
    drillDown: (item: unknown) => TableRowDrillDownReturnType;
    highlight: {
      columnId: string | symbol;
      fn: (item: unknown) => boolean;
    };
  };
  itemCounter: {
    show: boolean;
    total: number;
  };
  tableSettings: {
    show: boolean;
    change: (event: TableSettingsChangeEvent) => void;
  };
  resizeColumns: {
    enable: boolean;
  };
};

export type ColumnComputedHeader = {
  id: string;
  text: ColumnHeader['header']['text'];
  align: AlignmentOptions;
  resizable: boolean;
  sortable: boolean;
};

export const tableColumnMetadataKey = Symbol('TableColumnMetadata');

export type ColumnWithMetadata<TRowData> = TableColumn<TRowData> & {
  [tableColumnMetadataKey]: {
    label: string;
    visible: boolean;
    canInteract: boolean;
  };
};
