import { NgClass, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { AlignmentOptions } from '../../models/_table.model';

@Component({
  standalone: true,
  imports: [NgClass, NgStyle],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'tsq-table-cell-base',
  template: `
    <div
      role="gridcell"
      class="border-b-1 border-petro-n2 flex h-48 items-center gap-8"
      [ngClass]="{
        'justify-end': align === 'right',
        'justify-center': align === 'center',
        'px-16': size === 'normal',
      }"
      [ngStyle]="size === 'small' ? { 'padding-left.px': 12, 'padding-right.px': 12 } : null"
    >
      <ng-content />
    </div>
  `,
  styles: [
    `
      :host {
        @apply block;
      }
    `,
  ],
})
export class TableCellBaseComponent {
  @Input() align: AlignmentOptions;
  @Input() size: 'normal' | 'small' = 'normal';
}
