<div id="list">
  <form [formGroup]="form" class="form-group">

    <div class="panel panel-transparent">
      <div class="panel-heading">{{ 'SUBSCRIPTION.TRIAL_CONFIGURATION' | translate }}</div>
      <div class="panel-body">

        <div class="row">
          <div class="col-xs-6">
            <tsq-card-for-switch label="{{ 'SUBSCRIPTION.TRIAL' | translate }}">
              <sc-switch [size]="'small'" [checked]="form.controls['trial'].value"
                         (change)="form.controls['trial'].setValue($event)"></sc-switch>
            </tsq-card-for-switch>
          </div>
        </div>

      </div>
    </div>
  </form>
</div>
