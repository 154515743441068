import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable()
export class TaxToPayIssService {

  taxToPayIss$ = new BehaviorSubject<boolean>(false);

}
