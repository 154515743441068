<div class="full-viewport">
  <div class="split-background">
    <div class="centered-content">
      <div>
        <a id="logo" class="navbar-brand" [routerLink]="['/']">
          <img src="../assets/img/logo-inline.png" alt="TownSq" />
        </a>
      </div>

      <div class="title"
           [innerHTML]="'MASS_INVITE_NEIGHBORS.TITLE' | translate | uppercase">
      </div>

      <div class="content-contaienr">
        <ng-content select=".content"></ng-content>
      </div>
    </div>
  </div>
</div>
