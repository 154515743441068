<div class="flex items-center">
  <img class="w-40 h-40 cursor-pointer"
       src="./assets/img/minus-sign.svg"
       alt="Minus sign"
       (click)="onDecrementCounter()">

  <div class="picker-divider"></div>

  <div class="grow">
    <input class="form-control {{ getInvalidClass() }}"
           type="number"
           [(ngModel)]="initialValue"
           [disabled]="disableTyping"
           [min]="minValue"
           [max]="maxValue"
           [placeholder]="placeholder"
           (change)="valueChanged()">
  </div>

  <div class="picker-divider"></div>

  <img class="w-40 h-40 cursor-pointer"
       src="./assets/img/plus-sign.svg"
       alt="Plus sign"
       (click)="onIncrementCounter()">
</div>

