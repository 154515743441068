<ng-template #modalContent>
  <div class="modal-header">
    <div>
      <img [src]="iconPath" [ngClass]="iconFilter" alt="" *ngIf="!!iconPath" />
      <span>{{ title | translate }}</span>
    </div>
    <tsq-generic-modal-close *ngIf="showCloseButton" class="text-end" (click)="cancelAction()" />
  </div>
  <div class="modal-body-content">
    <ng-content></ng-content>
  </div>
  <div class="modal-footer">
    <button
      id="confirmation-dialog--button--cancel"
      class="btn btn-secondary"
      [disabled]="loading"
      *ngIf="!hideCancel"
      (click)="cancelAction()"
    >
      {{ cancelText | translate }}
    </button>

    <button
      id="confirmation-dialog--button--confirm"
      class="btn"
      [ngClass]="confirmStyle"
      [disabled]="!confirmEnable"
      *ngIf="!loading"
      (click)="confirmAction()"
    >
      {{ confirmText | translate }}
    </button>

    <tsq-small-loader *ngIf="loading"></tsq-small-loader>
  </div>
</ng-template>
