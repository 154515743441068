export interface EasyOnboardingStep {
  stepType: EasyOnboardingType;
  stepStatus: EasyOnboardingStatus;
}

export enum EasyOnboardingType {
  ConfigureGroups = 'CONFIGURE_GROUPS',
  AddUnitsAndResidents = 'ADD_UNITS_AND_RESIDENTS',
  TrackInvitations = 'TRACK_INVITATIONS',
}

export enum EasyOnboardingStatus {
  NotStarted = 'NOT_STARTED',
  Started = 'STARTED',
  Finished = 'FINISHED',
}
