<div
  bsModal
  #modalControl="bs-modal"
  id="register"
  class="modal"
  tabindex="-1"
  role="dialog"
  (onHide)="onHideModal($event)"
  (click)="modalClicked()"
  (onShown)="shownEmit()"
  (onHidden)="onHiddenModal($event)"
  [ngClass]="{
    animated: animated,
    fadeInRight: isAnimationType('fadeInRight'),
    fadeInLeft: isAnimationType('fadeInLeft'),
    fadeInTop: isAnimationType('fadeInTop'),
    fadeInBottom: isAnimationType('fadeInBottom')
  }"
  [config]="{
    backdrop: modalCloseable && !ignoreFocusChange ? true : 'static',
    keyboard: escClosable
  }"
  style="overflow: auto"
>
  <div
    class="modal-dialog {{ getComplementaryClasses() }}"
    [class.fixed-banner-size]="fixedBannerSize"
    [class.big-modal-size]="bigModalSize"
    [class.vertically-centered]="centerVertical"
  >
    <div class="modal-content">
      <div class="panel panel-transparent" [style.margin-bottom]="!showFooter ? '0px' : 'auto'">
        <div class="panel-heading" *ngIf="showHeader">
          <span class="title">
            {{ title }}
            <span *ngIf="subtitle !== '' && subtitle !== undefined">/</span>
            <span *ngIf="subtitle !== '' && subtitle !== undefined" class="subtitle">
              {{ subtitle }}
            </span>
          </span>
          <div class="pull-right">
            <button
              [hidden]="!modalCloseable"
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              (click)="modalControl.hide()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div class="panel-body" [class.no-padding]="zeroPadding">
          <ng-content select="[modal-content]"></ng-content>
        </div>
      </div>
      <div *ngIf="showFooter" class="modal-footer flex justify-end">
        <ng-content select="[actions]"></ng-content>
        <a
          id="cancel-button"
          *ngIf="modalCloseable"
          href="javascript:;"
          data-dismiss="modal"
          (click)="modalControl.hide()"
          class="btn btn-link"
        >
          {{ 'CANCEL' | translate }}
        </a>
        <ng-content select="[actions]"></ng-content>
      </div>
    </div>
  </div>
</div>
