import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { icons } from '@tsq-web/assets';
import { TSqValidators } from '@tsq-web/forms';

import { LoginInformation } from '../../models/login-information.model';

@Component({
  selector: 'comm-login-card',
  templateUrl: './login-card.component.html',
})
export class LoginCardComponent {
  @Input() isLoginIn: boolean;
  @Input() isTownSqBrazil: boolean;
  @Input() showAlreadyRegisteredBanner: boolean;
  @Input() ssoEnabled = false;
  @Output() goBackClicked = new EventEmitter<void>();
  @Output() login = new EventEmitter<LoginInformation>();
  @Output() loginWithSso = new EventEmitter<void>();
  @Output() closeBanner = new EventEmitter<void>();

  showAlreadyRegisteredBannerAdditionalInfo: boolean;

  readonly loginForm = new FormGroup({
    username: new FormControl('', [Validators.required, TSqValidators.validLoginUsername]),
    password: new FormControl('', Validators.required),
  });

  readonly arrowLeftIcon = icons.arrowLeft;

  @Input() set email(value: string) {
    if (!!value) {
      this.loginForm.controls.username.setValue(value);
      this.loginForm.controls.password.reset();
      this.showAlreadyRegisteredBannerAdditionalInfo = true;
    }
  }

  doLogin(): void {
    if (this.loginForm.valid) {
      this.login.emit(this.loginForm.value);
    }
  }

  doLoginWithSso(): void {
    this.loginWithSso.emit();
  }

  onCloseBanner(): void {
    this.showAlreadyRegisteredBannerAdditionalInfo = false;
    this.showAlreadyRegisteredBanner = false;
    this.closeBanner.emit();
  }
}
