import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { UntilDestroy } from '@ngneat/until-destroy';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { ToastrService } from 'ngx-toastr';
import { Observable, switchMap, tap } from 'rxjs';

import { ToastErrorComponent } from '@tsq-web/toast';

import { ResetPasswordService } from '../services/reset-password.service';

export interface ResetPasswordState {
  loading: boolean;
  emailIsSso: boolean;
  showSentEmailMessage: boolean;
  typedEmail: string;
}

export const resetPasswordInitialState: ResetPasswordState = {
  loading: false,
  emailIsSso: false,
  showSentEmailMessage: false,
  typedEmail: undefined,
};

/**
 * @deprecated This store is not used anymore in the new password recovery flow.
 * The class was replaced by `RequestRecoveryLinkStore`.
 */
@UntilDestroy()
@Injectable()
export class ResetPasswordStore extends ComponentStore<ResetPasswordState> {
  readonly resetPasswordLoading$ = this.select(({ loading }) => loading);
  readonly emailIsSso$ = this.select(({ emailIsSso }) => emailIsSso);
  readonly showSentEmailMessage$ = this.select(({ showSentEmailMessage }) => showSentEmailMessage);
  readonly typedEmail$ = this.select(({ typedEmail }) => typedEmail);

  readonly resetPassword = this.effect((data$: Observable<{ email: string }>) =>
    data$.pipe(
      tap(() => this.patchState({ loading: true })),
      switchMap(({ email }) => {
        return this.resetPasswordService.resetPassword(email).pipe(
          tapResponse(
            resetPasswordResponse =>
              this.patchState({
                emailIsSso: resetPasswordResponse.singleSignOn,
                showSentEmailMessage: !resetPasswordResponse.singleSignOn,
                typedEmail: resetPasswordResponse.username,
                loading: false,
              }),
            (error: HttpErrorResponse) => {
              this.resetState();
              this.toastr.error(error.error.message, null, { toastComponent: ToastErrorComponent });
            },
          ),
        );
      }),
    ),
  );

  readonly resetShowSentEmailMessage = this.updater((state: ResetPasswordState) => ({
    ...state,
    showSentEmailMessage: false,
  }));

  private readonly resetState = this.updater(() => ({
    ...resetPasswordInitialState,
  }));

  private readonly resetPasswordService = inject(ResetPasswordService);
  private readonly toastr = inject(ToastrService);

  constructor() {
    super(resetPasswordInitialState);
  }
}
