/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, Input, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { LinkData } from '@tsq-web/files';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { SCModalComponent } from '../sc-modal/sc-modal.component';

@Component({
  selector: 'link-data-list',
  templateUrl: './link-data-list.component.html',
  styleUrls: ['./link-data-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkDataListComponent {
  @ViewChild('videoModal', { static: true }) videoModal: SCModalComponent;
  @Input() links: LinkData[];

  modalLink: LinkData;

  constructor(private _sanitizer: DomSanitizer) {}

  getVideoUrl(link: LinkData): SafeUrl {
    if (link) {
      return this._sanitizer.bypassSecurityTrustResourceUrl(link.videoUrl);
    } else {
      return '';
    }
  }

  resetModalLink() {
    this.modalLink = null;
  }

  openModal(modalLink: LinkData) {
    this.modalLink = modalLink;
    this.videoModal.show();
  }

  linkClicked(modalLink: LinkData) {
    if (modalLink.videoUrl) {
      this.openModal(modalLink);
    } else {
      window.open(modalLink.url, '_blank');
    }
  }
}
