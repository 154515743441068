<div class="owners-container">
  <h1>{{ 'AIS_SIGN_UP.OWNER_SELECTION' | translate }}</h1>
  <p>{{ 'AIS_SIGN_UP.OWNER_SELECTION_CAPTION' | translate }}</p>

  <tsq-radio-group [formControl]="ownerControl" [options]="ownersOptions"></tsq-radio-group>

  <button class="btn btn-primary"
          (click)="onContinue()"
          [disabled]="!ownerControl.valid">{{ 'AIS_SIGN_UP.CONTINUE' | translate }}</button>

  <a [routerLink]="['/login']">{{ 'AIS_SIGN_UP.ALREADY_REGISTERED' | translate }}</a>
</div>
