import { CdkDrag } from '@angular/cdk/drag-drop';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
  inject,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription, map, mergeMap, scan, tap } from 'rxjs';

import { AssetsModule } from '@tsq-web/assets';

import { TooltipTruncatedDirective } from '../../directives/tooltip-truncated/tooltip-truncated.directive';
import { AlignmentOptions, ColumnHeader } from '../../models/_table.model';
import { TableSortService } from '../../services/table-sort/table-sort.service';
import { valueFromStringValue } from '../../utils/table';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [AsyncPipe, CdkDrag, NgClass, NgIf, AssetsModule, TooltipTruncatedDirective],
  selector: 'tsq-table-header',
  template: `
    <div
      role="columnheader"
      class="group/header bg-petro-n1 border-petro-n2 border-b-1 relative h-40"
      [ngClass]="{ 'hover:bg-petro-n2': sortable }"
    >
      <div
        [attr.role]="sortable ? 'button' : 'presentation'"
        [tabindex]="sortable ? 0 : -1"
        class="relative flex h-full items-center rounded px-16 focus-visible:z-[1]"
        [ngClass]="{
          'justify-end': align === 'right',
          'justify-center': align === 'center',
        }"
        [tsqTooltipTruncated]="text"
        (click)="onHeaderClick()"
        (keydown)="onHeaderKeyDown($event)"
        (keyup)="onHeaderKeyUp($event)"
      >
        <div class="font-semi-bold truncate" #text>{{ text$ | async }}</div>

        <tsq-icon
          *ngIf="sortable"
          class="group-hover/header:text-primary transition-colors"
          [ngClass]="(isSortSelected$ | async) ? 'text-coal-secondary' : 'text-coal-tertiary'"
          [icon]="sortIcon$ | async"
          classes="size-16"
        />
      </div>

      <div
        *ngIf="resizable"
        class="drag-handle group/drag absolute inset-y-8 right-0 flex w-[6px] cursor-ew-resize justify-center transition-all"
        cdkDrag
        cdkDragLockAxis="x"
      >
        <div
          class="group-hover/drag:!bg-primary group-active/drag:!bg-primary h-full w-[2px] transition-all group-hover/drag:w-[3px] group-active/drag:w-[3px]"
        ></div>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        @apply min-w-0;

        > div {
          > div[role='button']:focus-visible {
            outline: theme('colors.purple.DEFAULT') dotted 2px;
            outline-offset: 0;
          }

          &:hover > .drag-handle,
          .drag-handle:hover,
          .drag-handle:active {
            opacity: 1;
          }

          > .drag-handle {
            opacity: 0;

            > div {
              background-color: theme('colors.coal.tertiary');
            }
          }
        }
      }
    `,
  ],
})
export class TableHeaderComponent {
  @Input() align: AlignmentOptions;
  @Input() resizable: boolean;
  @Input() sortable: boolean;

  @Output() readonly resizeColumn = new EventEmitter<number>();

  private readonly tableSort = inject(TableSortService);

  private readonly colIdSubject = new BehaviorSubject<string | undefined>(undefined);
  protected readonly sortIcon$ = this.colIdSubject.asObservable().pipe(
    mergeMap(id => {
      return this.tableSort.icon$(id);
    }),
  );
  protected readonly isSortSelected$ = this.colIdSubject.asObservable().pipe(
    mergeMap(id => {
      return this.tableSort.isSelected$(id);
    }),
  );

  private readonly textSubject = new BehaviorSubject<string | undefined>(undefined);
  protected readonly text$ = this.textSubject.asObservable();

  private dragSubscriptions: Subscription[] = [];

  private readonly renderer = inject(Renderer2);
  private readonly translate = inject(TranslateService);

  @ViewChild(CdkDrag)
  private set cdkDrag(dragRef: CdkDrag) {
    if (!dragRef) {
      return;
    }

    if (!!this.dragSubscriptions.length) {
      this.dragSubscriptions.forEach(subs => {
        subs.unsubscribe();
      });
      this.dragSubscriptions = [];
    }

    this.dragSubscriptions.push(
      dragRef.started
        .asObservable()
        .pipe(
          tap(() => {
            this.renderer.addClass(document.body, '!cursor-ew-resize');
          }),
          mergeMap(startEvent => {
            return dragRef.moved.pipe(
              scan(
                ({ lastX }, moveEvent) => {
                  if ('clientX' in moveEvent.event) {
                    return {
                      lastX: moveEvent.event.clientX,
                      delta: moveEvent.event.clientX - lastX,
                    };
                  }

                  return {
                    lastX,
                    delta: 0,
                  };
                },
                {
                  lastX: 'clientX' in startEvent.event ? startEvent.event.clientX : 0,
                  delta: 0,
                },
              ),
              map(({ delta }) => delta),
            );
          }),
          untilDestroyed(this),
        )
        .subscribe(value => {
          this.resizeColumn.emit(value);
          dragRef.reset();
        }),
    );

    this.dragSubscriptions.push(
      dragRef.released
        .asObservable()
        .pipe(
          tap(() => {
            this.renderer.removeClass(document.body, '!cursor-ew-resize');
          }),
          untilDestroyed(this),
        )
        .subscribe(),
    );
  }

  @Input()
  set colId(value: string) {
    this.colIdSubject.next(value);
  }

  @Input()
  set text(value: ColumnHeader['header']['text'] | undefined) {
    this.textSubject.next(valueFromStringValue(value, this.translate));
  }

  protected onHeaderClick(): void {
    if (this.sortable) {
      this.tableSort.sort(this.colIdSubject.value);
    }
  }

  protected onHeaderKeyDown(event: KeyboardEvent): void {
    if (event.key === ' ' || event.key === 'Spacebar') {
      event.preventDefault();
    } else if (event.key === 'Enter') {
      event.preventDefault();
      this.onHeaderClick();
    }
  }

  protected onHeaderKeyUp(event: KeyboardEvent): void {
    if (event.key === ' ' || event.key === 'Spacebar') {
      event.preventDefault();
      this.onHeaderClick();
    }
  }
}
