import {Action} from '@ngrx/store';

export enum NeighborMassInviteActionTypes {
  RestoreNeighborMassInviteState = '[Neighbor Mass Invite] Reset State',
  SendInvitesRequest = '[Neighbor Mass Invite] Send Invites Request',
  SendInvitesSuccess = '[TownSq API] Send Invites Success',
  SendInvitesError = '[TownSq API] Send Invites Error',
}

export class RestoreNeighborMassInviteState implements Action {
  readonly type = NeighborMassInviteActionTypes.RestoreNeighborMassInviteState;
}
export class SendInvitesRequest implements Action {
  readonly type = NeighborMassInviteActionTypes.SendInvitesRequest;

  constructor(public payload: {emails: string[]}) { }
}

export class SendInvitesSuccess implements Action {
  readonly type = NeighborMassInviteActionTypes.SendInvitesSuccess;
}

export class SendInvitesError implements Action {
  readonly type = NeighborMassInviteActionTypes.SendInvitesError;
}

export type NeighborMassInviteActions = RestoreNeighborMassInviteState
  | SendInvitesRequest
  | SendInvitesSuccess
  | SendInvitesError;
