import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LetModule } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import { IMaskModule } from 'angular-imask';
import { defineLocale, esUsLocale, ptBrLocale } from 'ngx-bootstrap/chronos';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { AssetsModule } from '@tsq-web/assets';
import { TSqChipsModule } from '@tsq-web/chips';
import { TSqLoadersModule } from '@tsq-web/loaders';

import { ChoiceSelectionComponent } from './components/choice-selection/choice-selection.component';
import { DropdownButtonComponent } from './components/dropdown-button/dropdown-button.component';
import { DropdownCheckboxComponent } from './components/dropdown-checkbox';
import { HourInputComponent } from './components/hour-input/hour-input.component';
import { TSqCheckboxGroupComponent } from './components/tsq-checkbox-group/tsq-checkbox-group.component';
import { TSqChipInputComponent } from './components/tsq-chip-input/tsq-chip-input.component';
import { TSqColorPickerItemComponent } from './components/tsq-color-picker-item/tsq-color-picker-item.component';
import { TSqColorPickerComponent } from './components/tsq-color-picker/tsq-color-picker.component';
import { TSqDatepickerComponent } from './components/tsq-datepicker/tsq-datepicker.component';
import { TSqDropdownComponent } from './components/tsq-dropdown/tsq-dropdown.component';
import { TSqGroupPermissionSelectorComponent } from './components/tsq-group-permission-selector/tsq-group-permission-selector.component';
import { TSqIconPickerItemComponent } from './components/tsq-icon-picker-item/tsq-icon-picker-item.component';
import { TSqIconPickerComponent } from './components/tsq-icon-picker/tsq-icon-picker.component';
import { TSqMonthpickerComponent } from './components/tsq-monthpicker/tsq-monthpicker.component';
import { TSqRadioGroupComponent } from './components/tsq-radio-group/tsq-radio-group.component';
import { TSqSwitchCollapseComponent } from './components/tsq-switch-collapse/tsq-switch-collapse.component';
import { TSqSwitchComponent } from './components/tsq-switch/tsq-switch.component';
import { TSqTypeaheadComponent } from './components/tsq-typeahead/tsq-typeahead.component';
import { TSqUserTypeaheadComponent } from './components/tsq-user-typeahead/tsq-user-typeahead.component';
import { CheckboxDirective } from './directives/checkbox/checkbox.directive';
import { OptionalLabelDirective } from './directives/optional-label/optional-label.directive';
import { PeekablePasswordDirective } from './directives/peekable-password/peekable-password.directive';
import { TextInputDirective } from './directives/text-input/text-input.directive';
import { ToggleDirective } from './directives/toggle/toggle.directive';
import { TSqDropdownDirective } from './directives/tsq-dropdown.directive';
import { TSqInputDirective } from './directives/tsq-input.directive';
import { TSqSearchInputDirective } from './directives/tsq-search-input.directive';
import { ElementManipulationService } from './services/element-manipulation.service';
import { TSqUserTypeaheadService } from './services/tsq-user-typeahead.service';

defineLocale('pt-br', ptBrLocale);
defineLocale('es-us', esUsLocale);

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    LetModule,
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    TypeaheadModule.forRoot(),
    IMaskModule,
    CollapseModule,
    TranslateModule,
    TSqChipsModule,
    TSqLoadersModule,
    AssetsModule,
    ReactiveFormsModule,
    IMaskModule,
    OverlayModule,
  ],
  declarations: [
    TSqDropdownDirective,
    TSqInputDirective,
    TSqSearchInputDirective,
    TSqDropdownComponent,
    DropdownButtonComponent,
    TSqSwitchComponent,
    TSqChipInputComponent,
    TSqUserTypeaheadComponent,
    TSqUserTypeaheadComponent,
    TSqGroupPermissionSelectorComponent,
    TSqColorPickerItemComponent,
    TSqColorPickerComponent,
    TSqIconPickerItemComponent,
    TSqIconPickerComponent,
    TSqDatepickerComponent,
    TSqMonthpickerComponent,
    TSqSwitchCollapseComponent,
    TSqRadioGroupComponent,
    TSqTypeaheadComponent,
    TSqCheckboxGroupComponent,
    HourInputComponent,
    OptionalLabelDirective,
    TextInputDirective,
    ToggleDirective,
    CheckboxDirective,
    ChoiceSelectionComponent,
    PeekablePasswordDirective,
    DropdownCheckboxComponent,
  ],
  exports: [
    TSqDropdownDirective,
    TSqInputDirective,
    TSqSearchInputDirective,
    TSqDropdownComponent,
    DropdownButtonComponent,
    TSqSwitchComponent,
    TSqChipInputComponent,
    TSqUserTypeaheadComponent,
    TSqUserTypeaheadComponent,
    TSqGroupPermissionSelectorComponent,
    TSqColorPickerItemComponent,
    TSqColorPickerComponent,
    TSqIconPickerItemComponent,
    TSqIconPickerComponent,
    TSqDatepickerComponent,
    TSqMonthpickerComponent,
    TSqSwitchCollapseComponent,
    TSqRadioGroupComponent,
    TSqTypeaheadComponent,
    TSqCheckboxGroupComponent,
    HourInputComponent,
    OptionalLabelDirective,
    TextInputDirective,
    ToggleDirective,
    CheckboxDirective,
    ChoiceSelectionComponent,
    PeekablePasswordDirective,
    DropdownCheckboxComponent,
  ],
  providers: [ElementManipulationService, TSqUserTypeaheadService, BsLocaleService],
})
export class TSqFormsModule {}
