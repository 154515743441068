<div class="comment-wrapper">
  <div class="simplified-img">
    <img
      *ngIf="!!(user$ | async)"
      src="{{ (user$ | async).smallPictureUrl }}"
      alt=""
      class="img-circle user-image"
    />
  </div>

  <form accept-charset="UTF-8" role="form" [formGroup]="form" [class.form-uploader]="showUploader">
    <textarea
      class="comment-input form-control input-sm"
      type="text"
      data-cy="comment-input"
      placeholder="{{ 'SHARED.COMMENT_PLACEHOLDER' | translate }}"
      formControlName="text"
      name="text"
      [(ngModel)]="text"
    ></textarea>
  </form>

  <div *ngIf="showUploader" class="uploader-wrapper form-group">
    <div class="form-group" *ngFor="let attachment of attachments; let i = index">
      <label>
        <strong>{{ attachment.name }}</strong>
        <small (click)="removeAttachment(i)">{{ 'SHARED.REMOVE_ATTACHMENT' | translate }}</small>
      </label>
    </div>
    <div
      *ngIf="!uploader.isUploading && attachments.length === 0"
      class="comment-add-files"
      ng2FileDrop
      [uploader]="uploader"
    >
      <input
        ng2FileSelect
        type="file"
        accept="{{ globalVariables.FILE_TYPES_DOCS.toString() }}"
        class="file-select-input"
        [uploader]="uploader"
      />
      <div class="uploader-icon">
        <img class="upload-img" src="./assets/img/icons/drop-area-icon.svg" alt="" />
      </div>
      <div class="uploader-text">
        <span class="no-pointer-event"
          >{{ 'ADD_ATTACHMENT_1' | translate }} {{ 'ADD_ATTACHMENT_2' | translate }}</span
        >
      </div>
    </div>
    <div *ngIf="uploader.isUploading" class="form-group">
      <progressbar max="100" [value]="uploader.progress">
        <span style="color: white; white-space: nowrap">{{ uploader.progress }}%</span>
      </progressbar>
    </div>
  </div>

  <div class="buttons-wrapper" [class.buttons-wrapper-uploader]="showUploader">
    <button
      class="btn btn-default btn-sm"
      data-cy="send-comment"
      [ngClass]="{ disabled: !form.valid }"
      [disabled]="!form.valid"
      (click)="send()"
    >
      {{ 'SEND' | translate }}
    </button>
  </div>
</div>
