/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

import { ModalDirective } from 'ngx-bootstrap/modal';
import { Store } from '@ngrx/store';

import { AppState } from '@tsq-web/state';
import { Tab } from '@tsq-web/generic-components';

@Component({
  selector: 'tsq-modal',
  templateUrl: 'tsq-modal.component.html',
  styleUrls: ['tsq-modal.component.scss'],
})
export class TSqModalComponent {
  @ViewChild('modal', { static: true }) modal: ModalDirective;
  @ViewChild('modalDialog', { static: true }) modalDialog: ElementRef;

  @Input() ignoreBackdropClick = false;
  @Input() ignoreEscClick = false;

  @Input() title: string;
  @Input() hasFooter = true;
  @Input() hasTabs = false;
  @Input() tabs: Tab[] = [];
  @Input() selected: number;

  @Input() disableBackdrop = false;

  @Output() selectedChange = new EventEmitter<number>();
  @Output() modalShow = new EventEmitter();
  @Output() modalHide = new EventEmitter();
  @Output() modalHidden = new EventEmitter();

  private action: any;
  private _sending: boolean;

  constructor(private store: Store<AppState>) {}

  @Input() set isOpen(value: boolean) {
    if (value) {
      this.show();
    } else {
      this.close();
    }
  }

  @Input() set backdropAction(value: any) {
    if (!!value) {
      this.action = value;
      this.ignoreBackdropClick = true;
      this.ignoreEscClick = true;
    }
  }

  @Input() set sending(value: boolean) {
    this._sending = value;
    if (!this.action) {
      this.ignoreBackdropClick = value;
      this.ignoreEscClick = value;
    }
  }

  get sending(): boolean {
    return this._sending;
  }

  show() {
    this.modal.show();
  }

  close() {
    this.modal.hide();
  }

  innerClose() {
    if (!this.sending) {
      if (!!this.action && !this.disableBackdrop) {
        this.store.dispatch(this.action);
      } else {
        this.close();
      }
    }
  }

  modalHideFired(event: any) {
    this.modalHide.emit(event);
  }

  modalHiddenFired(event: any) {
    this.modalHidden.emit(event);
  }

  modalShowFired(event: any) {
    this.modalShow.emit(event);
  }

  @HostListener('mousedown', ['$event'])
  onClick(event: any): void {
    if (
      event.target.classList.contains('modal') &&
      !this.sending &&
      !!this.action &&
      !this.disableBackdrop
    ) {
      this.store.dispatch(this.action);
    }
  }
}
