import { Component, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';

import { TSqValidators } from '@tsq-web/forms';
import { TagInput } from '../../models/tag-input.model';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'neighbor-mass-invite-form',
  templateUrl: './neighbor-mass-invite-form.component.html',
  styleUrls: ['./neighbor-mass-invite-form.component.scss'],
})
export class NeighborMassInviteFormComponent {
  @Input() isLoading: boolean;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() submit = new EventEmitter<string[]>();

  searchControl = new UntypedFormControl('');
  emailValidator = TSqValidators.stringEmailValidator;

  emails: TagInput[] = [];

  constructor(private translateService: TranslateService) {}

  submitEmails(): void {
    this.submit.emit(this.emails.map(emailTag => emailTag.value));
  }

  get validEmailsMessage(): string {
    return this.translateService.instant(
      `MASS_INVITE_NEIGHBORS.EMAIL_LABEL${this.emails.length === 1 ? '' : '_P'}`,
      { emails: this.emails.length },
    );
  }

  get invalidEmailsMessage(): string {
    return this.translateService.instant(
      `MASS_INVITE_NEIGHBORS.EMAIL_ISSUE_LABEL${this.emails.length === 1 ? '' : '_P'}${
        this.invalidEmails.length === 1 ? '' : '_P'
      }`,
      { emails: this.emails.length, invalidEmails: this.invalidEmails.length },
    );
  }

  get invalidEmails(): TagInput[] {
    return this.emails.filter(email => !email.isValid);
  }
}
