/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

import { icons } from '@tsq-web/assets';
import { selectUntilDestroyed } from '@tsq-web/redux/operators';
import { fromRouterSelectors } from '@tsq-web/router';
import { AppState } from '@tsq-web/state';

import { getFeatureToggle } from '../../feature-toggle';
import { SCListNotifier } from '../sc-list/sc-list.notifier';

@UntilDestroy()
@Component({
  selector: 'comm-sc-paginated-list',
  templateUrl: './sc-paginated-list.component.html',
  styleUrls: ['./sc-paginated-list.component.scss'],
})
export class SCPaginatedListComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() caption: string;
  @Input() parentRoute: string;
  @Input() configIcon: string;
  @Input() reportIcon: string;
  @Input() listWidth: number;
  @Input() showConfig = false;
  @Input() showReport = false;
  @Input() showToolbar = true;
  @Input() showSearch = true;
  @Input() searchPlaceholder: string;
  @Input() loading = true;
  @Input() loadingMore = false;
  @Input() error = false;
  @Input() showDivider = false;
  @Input() showHeaderSpace = false;
  @Input() showNavigation = false;
  @Input() showNewHeader = false;
  @Input() hasZeroDataOffset = true;
  @Input() newHeaderSearchPlaceholder: string;
  @Input() newHeaderCustomSearchWidth: string;
  @Input() panel = true;
  @Input() panelIsRow: boolean;
  @Input() debounceTime = 1000;
  @Input() filterMinLength = 1;
  @Input() simplified = false;
  @Input() getElementsDone: EventEmitter<any>;
  @Input() getElementsError: EventEmitter<any>;
  @Input()
  set search(value: string) {
    this._search = value;
    this.filterControl.setValue(this._search);
  }

  get search(): string {
    return this._search;
  }

  @Output() elementsGetter = new EventEmitter(true);
  @Output() scrollevent = new EventEmitter();
  @Output() searchevent = new EventEmitter();
  @Output() retryevent = new EventEmitter();
  @Output() configClicked = new EventEmitter();
  @Output() reportClicked = new EventEmitter();

  userIdFilter = '';
  filterControl = new UntypedFormControl();
  listSize = 0;
  searchFor = '';
  waitToLoadMore = false;
  searchParam = 'search';
  currentPage = 1;

  readonly featureToggle = getFeatureToggle();
  readonly icons = icons;

  private latestScrollPosition: any = 0;
  private _search: string;

  private readonly notifier = inject(SCListNotifier);
  private readonly store = inject(Store<AppState>);

  ngOnInit(): void {
    this.getElementsDone
      .pipe(untilDestroyed(this))
      .subscribe((elements: any) => this.onElementsLoaded(elements));

    this.getElementsError
      .pipe(untilDestroyed(this))
      .subscribe((error: any) => this.errorGettingElements(error));

    this.notifier.loadingObservable
      .pipe(untilDestroyed(this))
      .subscribe((nextValue: boolean) => (this.loading = nextValue));
    this.notifier.listSizeObservable.pipe(untilDestroyed(this)).subscribe((nextValue: number) => {
      this.listSize = nextValue;
    });
    this.notifier.loadingMoreObservable
      .pipe(untilDestroyed(this))
      .subscribe((nextValue: boolean) => (this.loadingMore = nextValue));
    this.notifier.errorObservable
      .pipe(untilDestroyed(this))
      .subscribe((nextValue: boolean) => (this.error = nextValue));
    this.filterControl.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(this.debounceTime),
        distinctUntilChanged(),
        filter((value: string) => !value || value.length >= this.filterMinLength),
      )
      .subscribe((f: string) => {
        if (f.length >= 2 || f.length < this.searchFor.length) {
          this.onSearch(f);
        }
      });

    this.store
      .pipe(
        selectUntilDestroyed(fromRouterSelectors.selectRouterQueryParams, this),
        filter(() => this.featureToggle.localSearch.residents),
        filter(queryParam => Object.keys(queryParam).length > 0),
      )
      .subscribe(() => {
        this.currentPage = 1;
        this.latestScrollPosition = 0;
      });

    this.loadElements('', 1);
  }

  onRetry(): void {
    this.loadElements(this.searchFor, 1);
  }

  onScroll(event: any): void {
    if (!this.waitToLoadMore) {
      if (event.currentScrollPosition > this.latestScrollPosition) {
        this.currentPage = this.currentPage + 1;
        this.latestScrollPosition = event.currentScrollPosition;
        this.loadElements(this.searchFor, this.currentPage);
      }
    }
  }

  onSearch(f: string): void {
    this.latestScrollPosition = 0;
    this.currentPage = 1;
    this.searchFor = f;
    this.loadElements(f, 1);
  }

  configIconClicked(): void {
    this.configClicked.emit({});
  }

  reportIconClicked(): void {
    this.reportClicked.emit({});
  }

  private errorGettingElements(error: any): void {
    this.notifier.notifyError(error);
  }

  private onElementsLoaded(elements: any[]): void {
    this.notifier.notifyLoadPageFinished(elements.length);
    this.waitToLoadMore = false;
  }

  private loadElements(searchFor: string, page: number): void {
    this.waitToLoadMore = true;
    this.elementsGetter.emit({ searchFor: searchFor, perPage: 20, pageNumber: page });
  }
}
