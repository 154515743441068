<div
  bsModal
  #modal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  [config]="{ ignoreBackdropClick: ignoreBackdropClick, keyboard: !ignoreEscClick }"
  (onHide)="modalHideFired($event)"
  (onHidden)="modalHiddenFired($event)"
>
  <div class="modal-dialog" [ngStyle]="{ width: modalWidth + 'px' }" #modalDialog>
    <div class="modal-content" [ngStyle]="{ height: modalHeight + 'px' }">
      <div class="modal-body">
        <div id="loader-container" *ngIf="loading">
          <tsq-loader></tsq-loader>
        </div>

        <div id="content-container" *ngIf="!loading">
          <div id="content-header">
            <div>
              <ng-content select=".tsq-modal-header"></ng-content>
            </div>
            <img
              src="./assets/img/icons/close-big-avocado.svg"
              class="pointer-cursor"
              (click)="innerClose()"
              alt=""
            />
          </div>

          <div class="divider"></div>

          <div id="content-content">
            <div>
              <ng-content select=".tsq-modal-left"></ng-content>
            </div>

            <div class="vertical-divider"></div>

            <div>
              <ng-content select=".tsq-modal-right"></ng-content>
            </div>
          </div>

          <div *ngIf="hasFooter">
            <div class="divider"></div>

            <div id="content-footer">
              <ng-content select=".tsq-modal-footer"></ng-content>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
