import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { delay } from 'rxjs/operators';

import { icons } from '@tsq-web/assets';
import { FileMeta, TSqFileUploaderComponent, documentFileTypes } from '@tsq-web/files';
import { TSqValidators } from '@tsq-web/forms';

import { TSqBaseComment } from '../../models/tsq-base-comment.model';
import { isDescendant } from '../../utils/dom.utils';

@UntilDestroy()
@Component({
  selector: 'tsq-create-comment-v2',
  templateUrl: './create-comment.component.html',
  styleUrls: ['./create-comment.component.scss'],
})
export class CreateCommentComponent implements OnInit {
  @ViewChild('fileUploader', { static: true }) fileUploader: TSqFileUploaderComponent;
  @ViewChild('textarea') textarea: ElementRef<HTMLTextAreaElement>;

  @Input() hasAttachments = true;
  @Input() maxAttachments = 3;
  @Input() error: boolean;
  @Input() isSingleLine: boolean;
  @Input() extraActionsTemplate: TemplateRef<unknown>;
  @Input() fileExtensions: string[];
  @Input() maxLength: number;

  @Output() saveComment = new EventEmitter<TSqBaseComment>();
  @Output() filesChanged = new EventEmitter<FileMeta[]>();
  @Output() commentSent = new EventEmitter();
  @Output() focusOut = new EventEmitter<boolean>();

  commentTextControl = new UntypedFormControl();
  sendingComment: boolean;
  private _required = true;

  readonly icons = icons;

  constructor(private elRef: ElementRef) {}

  ngOnInit(): void {
    this.fileUploader?.files$.pipe(untilDestroyed(this), delay(50)).subscribe(files => {
      this.textarea.nativeElement.focus();

      this.filesChanged.emit(files);
    });
  }

  @Input() set uploadUrl(value: string) {
    if (!!value) {
      this.fileUploader?.setupUploader(
        value,
        documentFileTypes,
        false,
        undefined,
        this.fileExtensions,
      );
    }
  }

  @Input() set sending(value: boolean) {
    if (this.sendingComment && !value && !this.error) {
      this.commentTextControl.reset();
      this.fileUploader?.clearFiles();
      this.commentSent.emit();
    }

    this.sendingComment = value;
  }

  @Input() set commentContent(value: string) {
    if (!!value) {
      this.commentTextControl.setValue(value);

      setTimeout(() => this.onTextAreaChange(), 0);
    }
  }

  @Input() set required(value: boolean) {
    if (!value) {
      this.commentTextControl.clearValidators();
    } else {
      this.commentTextControl.setValidators([
        Validators.required,
        TSqValidators.hasCharDifferentThanWhitespace,
      ]);
    }

    if (undefined !== this.required && this.required !== value) {
      this.commentTextControl.updateValueAndValidity();
    }

    this._required = value;
  }

  get required(): boolean {
    return this._required;
  }

  get formValid(): boolean {
    return this.commentTextControl.valid && (!this.hasAttachments || !this.fileUploader?.uploading);
  }

  onTextAreaChange(): void {
    const scrollHeight = this.textarea.nativeElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;

    if (scrollHeight < clientHeight) {
      this.textarea.nativeElement.style.height = 'auto';
      this.textarea.nativeElement.style.height = `${this.textarea.nativeElement.scrollHeight}px`;
    }
  }

  onSaveComment(): void {
    this.saveComment.emit({
      text: this.commentTextControl.value,
      attachments: this.fileUploader?.files,
    });
  }

  onBlur(): void {
    setTimeout(() => {
      const currentEl = document.activeElement as HTMLElement;

      if (!isDescendant(this.elRef.nativeElement, currentEl)) {
        const hasValue = !!this.commentTextControl.value || !!this.fileUploader?.files?.length;

        if (!this.required || !hasValue) {
          this.focusOut.emit(hasValue);
        }
      }
    }, 0);
  }
}
