import { mockedCondo } from '@tsq-web/condo';
import { LoginProvider } from '@tsq-web/login';
import { storeListWithPageInitialState } from '@tsq-web/redux/utils';
import { UserContextState } from '@tsq-web/user-context';
import { mockedUser } from '@tsq-web/users';

export const mockedUserContextState: UserContextState = {
  userState: {
    user: mockedUser,
    condos: [],
    isFirstLogin: false,
    loginProvider: LoginProvider.Standard,
    acceptedTerms: {
      entity: false,
      loading: false,
      error: false,
    },
  },
  contexts: storeListWithPageInitialState,
  condo: mockedCondo,
  introBanners: [],
  login: {
    loggingIn: false,
    loggingOut: false,
    error: undefined,
  },
  patchUpsellSettings: {
    error: false,
    success: false,
  },
  userFavorites: undefined,
  ssoLogin: {
    loading: false,
    error: false,
  },
};
