<div class="hour-container">
  <input class="form-control"
         [imask]="hourMask"
         [formControl]="hourControl">

  <div *ngIf="lang !== 'pt'">
    <tsq-dropdown [formControl]="periodControl"
                  [items]="periodOptions"
                  [placeholder]="'LIBS.AM_PM'"></tsq-dropdown>
  </div>
</div>

<label class="input-error-label"
       *ngIf="abstractControl.errors?.invalidHourError && !!abstractControl.value">
  {{ 'LIBS.VALIDATORS.INVALID_HOUR_ERROR' | translate }}
</label>

<label class="input-error-label"
       *ngIf="abstractControl.errors?.minHourError && !!abstractControl.value">
  {{ 'LIBS.VALIDATORS.MIN_HOUR_ERROR' | translate }}
</label>
