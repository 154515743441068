/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sc-error',
  templateUrl: 'sc-error.component.html',
  styleUrls: ['sc-error.component.scss'],
})
export class SCErrorComponent {
  @Input() topText: string;
  @Input() illustrationSrc: string;
  @Input() bottomText: string;
  @Output() retry = new EventEmitter<boolean>();

  retryClicked() {
    this.retry.emit(true);
  }
}
