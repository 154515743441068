<div class="divider row" *ngIf="!noDivider"></div>

<div class="row text-center">
  <div class="date-navigator-pagination">
    <div class="prev-date">
      <a href="javascript:;" (click)="previousDate()">
        <img src="./assets/img/icons/left-arrow.svg" alt="" />
      </a>
    </div>

    <div [ngClass]="{ 'mt-4 flex items-center justify-center gap-4': newDesign }">
      <ng-container *ngIf="newDesign; else oldDesign">
        <tsq-icon [icon]="icons.calendar" class="text-primary" />
      </ng-container>
      <ng-template #oldDesign>
        <div *ngIf="!newDesign" class="date-day">{{ getDateDay() }}</div>
      </ng-template>

      <div class="date-week-day">{{ getDateWeekDay() }}</div>
      <div class="date-month-year">{{ getDateMonthYear() }}</div>
      <div *ngIf="!newDesign" class="relative-date relative-bubble">{{ relativeDate }}</div>
    </div>

    <div class="next-date">
      <a href="javascript:;" (click)="nextDate()">
        <img src="./assets/img/icons/right-arrow.svg" alt="" />
      </a>
    </div>
  </div>
</div>

<div class="divider row" *ngIf="!noDivider"></div>
