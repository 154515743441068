<div class="picture-uploader-actions">
  <div class="btn white-btn btn-primary-o upload-button">
    <span>{{ getUploadButtonText() | translate }}</span>
    <input ng2FileSelect
           type="file" accept="{{ globalVariables.FILE_TYPES_IMAGES.toString() }}"
           class="picture-input-file pointer-cursor"
           [uploader]="uploader"
           (change)="fileSelected($event)"
           [disabled]="uploader.isUploading">
  </div>
</div>
