import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { TSqCommentPresentation } from '../../models/tsq-comment-presentation.model';
import {
  ModalContext,
  ModalPositions,
  ModalService,
  ModalTypes,
  ofModalType,
  TSqTemplatePortal,
} from '@tsq-web/modals';
import { TSqValidators } from '@tsq-web/forms';

@UntilDestroy()
@Component({
  selector: 'tsq-edit-comment-modal',
  templateUrl: './edit-comment-modal.component.html',
  styleUrls: ['./edit-comment-modal.component.scss'],
})
export class EditCommentModalComponent implements OnInit {
  @ViewChild('modalContent') modalRef: TemplateRef<ModalContext>;

  @Input() fileUploadURL: string;
  @Input() editCommentIsLoading: boolean;
  @Output() editComment = new EventEmitter<TSqCommentPresentation>();

  form: UntypedFormGroup;

  private readonly modalPortalContext: ModalContext = {
    position: ModalPositions.RIGHT,
    disableClose: false,
  };

  constructor(private modalService: ModalService, private viewContainerRef: ViewContainerRef) {}

  ngOnInit(): void {
    this.modalService.type$
      .pipe(ofModalType(ModalTypes.EditCommentModal), untilDestroyed(this))
      .subscribe(() =>
        this.modalService.setPortal(
          new TSqTemplatePortal(this.modalRef, this.viewContainerRef, this.modalPortalContext),
        ),
      );
  }

  @Input() set commentForm(comment: TSqCommentPresentation) {
    if (!!comment) {
      this.form = new UntypedFormGroup({
        id: new UntypedFormControl(comment.id),
        comment: new UntypedFormControl(comment.text, TSqValidators.hasCharDifferentThanWhitespace),
        attachments: new UntypedFormControl(comment.attachments),
      });
    }
  }

  modalClose(): void {
    this.modalService.close();
  }
}
