import { HttpClient, HttpParams } from '@angular/common/http';

import { TranslateLoader } from '@ngx-translate/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { multiTranslateResources, TranslateResource } from './translate-resources';

declare const CACHE_BUSTING: number;

export class MultiTranslateHttpLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    private resources: TranslateResource[] = multiTranslateResources,
    private cacheBusting = false,
  ) {}

  public getTranslation(lang: string): Observable<any> {
    const params = this.cacheBusting
      ? new HttpParams({
          fromObject: {
            cb: CACHE_BUSTING,
          },
        })
      : {};

    return forkJoin(
      this.resources.map(config => {
        return this.http.get(`${config.prefix}${lang}${config.suffix}`, { params });
      }),
    ).pipe(map(response => response.reduce((a, b) => Object.assign(a, b))));
  }
}
