/* eslint-disable @typescript-eslint/naming-convention */
import moment from 'moment-timezone';

import { Administrator } from '@tsq-web/administrator';
import { Condo, condoDisablesReports } from '@tsq-web/condo';
import { ExternalFeature } from '@tsq-web/external-modules';
import { Feature } from '@tsq-web/feature';
import { UserGroup } from '@tsq-web/groups';
import { LoginProvider } from '@tsq-web/login';
import {
  Permission,
  Role,
  hasFeaturePermission,
  isFeatureActive,
  isSuperUser,
} from '@tsq-web/permission';
import { User } from '@tsq-web/users';

import { getEnvironment } from '../environment';
import { FeaturePackage } from '../plans/feature-package.json';
import { SubscriptionDetails } from '../subscription/subscription-details.json';

export function getPendoPayload(
  user: User,
  condo: Condo,
  loginProvider: LoginProvider,
  externalFeatures: ExternalFeature[],
  subscriptionDetails: SubscriptionDetails,
  administrator: Administrator,
  trial: boolean,
): Record<string, unknown> {
  const offersActive = externalFeatures.some(
    externalFeature => externalFeature.feature === Feature.OfferService,
  );

  return {
    visitor: {
      id: `${user.email}_${getEnvironment().env}`,
      email: user.email,
      full_name: `${user.first_name} ${user.last_name}`,
      environment: getEnvironment().env,
      app: 'townsq',
      is_owner: containsRoleWithGroup(user, condo.defaultOwnerGroup),
      is_admin: containsRoleWithGroup(user, condo.defaultAdminGroup),
      is_board_member: containsRoleWithGroup(user, condo.defaultAdminGroup),
      is_manager: containsRoleWithGroup(user, condo.defaultManagerGroup),
      is_family_member: containsRoleWithGroup(user, condo.defaultFamilyMemberGroup),
      is_tenant: containsRoleWithGroup(user, condo.defaultTenantGroup),
      trustee_discussion_permission: getFeaturePermissions(user, condo, Feature.TrusteeDiscussion),
      resident_discussion_permission: getFeaturePermissions(
        user,
        condo,
        Feature.ResidentDiscussion,
      ),
      calendars_permission: getFeaturePermissions(user, condo, Feature.Calendars),
      lobby_permission: getFeaturePermissions(user, condo, Feature.Lobby),
      discussion_permission: getFeaturePermissions(user, condo, Feature.Discussion),
      documents_permission: getFeaturePermissions(user, condo, Feature.Documents),
      neighbors_permission: getFeaturePermissions(user, condo, Feature.Neighbors),
      poll_permission: getFeaturePermissions(user, condo, Feature.Poll),
      alerts_permission: getFeaturePermissions(user, condo, Feature.Alerts),
      assignments_permission: getFeaturePermissions(user, condo, Feature.Assignments),
      facility_permission: getFeaturePermissions(user, condo, Feature.Facility),
      properties_permission: getFeaturePermissions(user, condo, Feature.Properties),
      package_delivery_permission: getFeaturePermissions(user, condo, Feature.PackageDelivery),
      settings_permission: getFeaturePermissions(user, condo, Feature.Settings),
      user_groups_permission: getFeaturePermissions(user, condo, Feature.UserGroups),
      occupants_permission: getFeaturePermissions(user, condo, Feature.Occupants),
      website_permission: getFeaturePermissions(user, condo, Feature.Website),
      communication_permission: getFeaturePermissions(user, condo, Feature.Communication),
      shift_logs_permission: getFeaturePermissions(user, condo, Feature.ShiftLogs),
      hasAccountsInMenu: hasAccountsInMenu(user, condo),
      hasVehicle: hasVehicle(user),
      hasAccount: user.hasAccount,
      hasC3Account: user.linkedToAssociaAccount,
      accountCreationTime: accountCreationTime(user),
      userAgent: window.navigator.userAgent,
      isSuper: isSuperUser(user),
      loginProvider,
    },
    account: {
      id: `${condo.name}_${getEnvironment().env}`,
      environment: getEnvironment().env,
      communityId: condo.id,
      is_trial: trial,
      street: condo.address.street,
      number: condo.address.number,
      zip_code: condo.address.zipCode,
      neighborhood: condo.address.neighborhood,
      country:
        !!condo.address && !!condo.address.country ? formatCountryName(condo.address.country) : '',
      city: condo.address.city,
      state: condo.address.state,
      time_zone: condo.timeZone,
      admName: !!condo.admName ? condo.admName : '',
      hasPaymentInfo: !!subscriptionDetails && !!subscriptionDetails.paymentInfo,
      organizationSize: !!administrator ? administrator.numberOfCondos : 0,
      organizationName: !!administrator ? administrator.name : '',
      reports_enabled: condoDisablesReports(condo),
      is_trustee_discussion_active: isFeatureActive(Feature.TrusteeDiscussion, condo),
      is_resident_discussion_active: isFeatureActive(Feature.ResidentDiscussion, condo),
      is_calendars_active: isFeatureActive(Feature.Calendars, condo),
      is_lobby_active: isFeatureActive(Feature.Lobby, condo),
      is_discussion_active: isFeatureActive(Feature.Discussion, condo),
      is_documents_active: isFeatureActive(Feature.Documents, condo),
      is_neighbors_active: isFeatureActive(Feature.Neighbors, condo),
      is_poll_active: isFeatureActive(Feature.Poll, condo),
      is_alerts_active: isFeatureActive(Feature.Alerts, condo),
      is_assignments_active: isFeatureActive(Feature.Assignments, condo),
      is_package_delivery_active: isFeatureActive(Feature.PackageDelivery, condo),
      is_occupants_active: isFeatureActive(Feature.Occupants, condo),
      is_website_active: isFeatureActive(Feature.Website, condo),
      is_shift_logs_active: isFeatureActive(Feature.ShiftLogs, condo),
      is_offers_active: offersActive,
      is_easy_onboarding: condo.isEasyOnboarding,
    },
  };
}

export function getPendoPayloadWithSubscription(
  user: User,
  condo: Condo,
  loginProvider: LoginProvider,
  externalFeatures: ExternalFeature[],
  subscriptionDetails: SubscriptionDetails,
  administrator: Administrator,
  trial: boolean,
  features: Feature[],
  featuresCommercialName: Record<string, string>,
): Record<string, unknown> {
  const offersActive = externalFeatures.some(
    externalFeature => externalFeature.feature === Feature.OfferService,
  );

  return {
    visitor: {
      id: `${user.email}_${getEnvironment().env}`,
      email: user.email,
      full_name: `${user.first_name} ${user.last_name}`,
      environment: getEnvironment().env,
      app: 'townsq',
      is_owner: containsRoleWithGroup(user, condo.defaultOwnerGroup),
      is_admin: containsRoleWithGroup(user, condo.defaultAdminGroup),
      is_board_member: containsRoleWithGroup(user, condo.defaultAdminGroup),
      is_manager: containsRoleWithGroup(user, condo.defaultManagerGroup),
      is_family_member: containsRoleWithGroup(user, condo.defaultFamilyMemberGroup),
      is_tenant: containsRoleWithGroup(user, condo.defaultTenantGroup),
      trustee_discussion_permission: getFeaturePermissions(user, condo, Feature.TrusteeDiscussion),
      resident_discussion_permission: getFeaturePermissions(
        user,
        condo,
        Feature.ResidentDiscussion,
      ),
      calendars_permission: getFeaturePermissions(user, condo, Feature.Calendars),
      lobby_permission: getFeaturePermissions(user, condo, Feature.Lobby),
      discussion_permission: getFeaturePermissions(user, condo, Feature.Discussion),
      documents_permission: getFeaturePermissions(user, condo, Feature.Documents),
      neighbors_permission: getFeaturePermissions(user, condo, Feature.Neighbors),
      poll_permission: getFeaturePermissions(user, condo, Feature.Poll),
      alerts_permission: getFeaturePermissions(user, condo, Feature.Alerts),
      assignments_permission: getFeaturePermissions(user, condo, Feature.Assignments),
      facility_permission: getFeaturePermissions(user, condo, Feature.Facility),
      properties_permission: getFeaturePermissions(user, condo, Feature.Properties),
      package_delivery_permission: getFeaturePermissions(user, condo, Feature.PackageDelivery),
      settings_permission: getFeaturePermissions(user, condo, Feature.Settings),
      user_groups_permission: getFeaturePermissions(user, condo, Feature.UserGroups),
      occupants_permission: getFeaturePermissions(user, condo, Feature.Occupants),
      website_permission: getFeaturePermissions(user, condo, Feature.Website),
      communication_permission: getFeaturePermissions(user, condo, Feature.Communication),
      shift_logs_permission: getFeaturePermissions(user, condo, Feature.ShiftLogs),
      hasAccountsInMenu: hasAccountsInMenu(user, condo),
      hasVehicle: hasVehicle(user),
      hasAccount: user.hasAccount,
      hasC3Account: user.linkedToAssociaAccount,
      accountCreationTime: accountCreationTime(user),
      userAgent: window.navigator.userAgent,
      isSuper: isSuperUser(user),
      loginProvider,
    },
    account: {
      id: `${condo.name}_${getEnvironment().env}`,
      environment: getEnvironment().env,
      communityId: condo.id,
      is_trial: trial,
      street: condo.address.street,
      number: condo.address.number,
      zip_code: condo.address.zipCode,
      neighborhood: condo.address.neighborhood,
      country:
        !!condo.address && !!condo.address.country ? formatCountryName(condo.address.country) : '',
      city: condo.address.city,
      state: condo.address.state,
      time_zone: condo.timeZone,
      admName: !!condo.admName ? condo.admName : '',
      hasPaymentInfo: !!subscriptionDetails && !!subscriptionDetails.paymentInfo,
      organizationSize: !!administrator ? administrator.numberOfCondos : 0,
      organizationName: !!administrator ? administrator.name : '',
      reports_enabled: condoDisablesReports(condo),
      is_trustee_discussion_active: isFeatureActive(Feature.TrusteeDiscussion, condo),
      is_resident_discussion_active: isFeatureActive(Feature.ResidentDiscussion, condo),
      is_calendars_active: isFeatureActive(Feature.Calendars, condo),
      is_lobby_active: isFeatureActive(Feature.Lobby, condo),
      is_discussion_active: isFeatureActive(Feature.Discussion, condo),
      is_documents_active: isFeatureActive(Feature.Documents, condo),
      is_neighbors_active: isFeatureActive(Feature.Neighbors, condo),
      is_poll_active: isFeatureActive(Feature.Poll, condo),
      is_alerts_active: isFeatureActive(Feature.Alerts, condo),
      is_assignments_active: isFeatureActive(Feature.Assignments, condo),
      is_package_delivery_active: isFeatureActive(Feature.PackageDelivery, condo),
      is_occupants_active: isFeatureActive(Feature.Occupants, condo),
      is_website_active: isFeatureActive(Feature.Website, condo),
      is_shift_logs_active: isFeatureActive(Feature.ShiftLogs, condo),
      is_offers_active: offersActive,
      is_easy_onboarding: condo.isEasyOnboarding,
      subscription_plan:
        subscriptionDetails?.subscription?.plan?.name || 'No subscription selected',
      subscription_feature_packages: getSubscriptionFeaturePackages(
        subscriptionDetails?.subscription?.featurePackages || [],
      ),
      subscription_available_features: convertFeatureListToString(features, featuresCommercialName),
      active_features: activeFeatures(features, condo, featuresCommercialName),
    },
  };
}

function accountCreationTime(user: User): number {
  const creationDate = moment(user.creationDate);
  const currentDate = moment();

  return currentDate.diff(creationDate, 'month');
}

function getFeaturePermissions(user: User, condo: Condo, feature: Feature): string[] {
  if (hasFeaturePermission(user, condo, feature, Permission.SUPER)) {
    return [Permission.SUPER, Permission.ADMIN, Permission.CREATE_FOR_OTHERS, Permission.VIEW];
  }
  if (hasFeaturePermission(user, condo, feature, Permission.ADMIN)) {
    return [Permission.ADMIN, Permission.CREATE_FOR_OTHERS, Permission.VIEW];
  }
  if (hasFeaturePermission(user, condo, feature, Permission.CREATE_FOR_OTHERS)) {
    return [Permission.CREATE_FOR_OTHERS, Permission.VIEW];
  }
  if (hasFeaturePermission(user, condo, feature, Permission.VIEW)) {
    return [Permission.VIEW];
  }

  return [Permission.NONE];
}

function containsRoleWithGroup(user: User, group: UserGroup): boolean {
  return user.roles.some((role: Role) => {
    return !!group && role.groupId === group.id;
  });
}

function formatCountryName(country: string): string {
  return country.replace('_', ' ');
}

function hasVehicle(user: User): boolean {
  return !!user.vehicles && !!user.vehicles.length;
}

function hasAccountsInMenu(user: User, condo: Condo): boolean {
  if (!user || !condo) {
    return false;
  }

  const userHasAccounts = user.linkedToAssociaAccount || user.hasAccount;
  const administratorHasLedgerAccountsFlow =
    condo.administrator?.settings?.accountingFlow === 'LEDGER';
  const hasFinancial = condo.administrator?.features?.financial;

  return userHasAccounts && administratorHasLedgerAccountsFlow && !hasFinancial;
}

function getSubscriptionFeaturePackages(featurePackages: FeaturePackage[]): string {
  return !featurePackages?.length
    ? 'No feature packages available'
    : featurePackages.map(featurePackage => featurePackage.name.toUpperCase()).join('; ');
}

export function activeFeatures(
  features: Feature[],
  condo: Condo,
  featuresCommercialName: Record<string, string>,
): string {
  const featuresFiltered = features.filter(ft => isFeatureActive(ft, condo));

  return convertFeatureListToString(featuresFiltered, featuresCommercialName);
}

export function convertFeatureListToString(
  features: Feature[],
  featuresCommercialName: Record<string, string>,
): string {
  return features
    .map(feature => {
      return feature
        .replace(/([A-Z])/g, '_$1')
        .replace(/^_|_$/g, '')
        .toUpperCase();
    })
    .map(feature => {
      return featuresCommercialName[feature] ?? feature;
    })
    .map(feature => {
      return feature.replace(/\s+/g, '_').toLowerCase();
    })
    .join('; ');
}

export function initializePendoForAnonymousVisitor(): void {
  const pendo = (window as unknown as { pendo: { initialize: (_: object) => void } }).pendo;
  if (!!pendo) {
    pendo.initialize({
      visitor: {
        app: 'townsq',
        environment: getEnvironment().env,
        userAgent: window.navigator.userAgent,
      },
    });
  }
}
