import { HttpErrorResponse } from '@angular/common/http';

import { defaultFindFn, ErrorMessageFindFn } from '../models/errors.model';

const clientErrorStatusStart = 400;
const clientErrorStatusEnd = 499;

function isStatusInClientErrorRange(status: number): boolean {
  return status >= clientErrorStatusStart && status <= clientErrorStatusEnd;
}

export function getHttpErrorMessage(error: HttpErrorResponse,
                                    findFn: ErrorMessageFindFn = defaultFindFn): string {
  const errorMessage = findFn(error);
  if (isStatusInClientErrorRange(error.status) && !!errorMessage) {
    return errorMessage;
  }

  return undefined;
}
