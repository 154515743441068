<subscription-summary-modal
  [subscription]="subscriptionDraft"
  [multiplierNumber]="multiplierInput"
  [percentageNumber]="!!subscriptionDraft ? percentageForCart : undefined"
  [rawValueNumber]="!!subscriptionDraft ? rawValueForCart : undefined"
  [totalAmount]="!!subscriptionDraft ? totalSubscriptionValue : undefined"
  (confirmChangeSubscription)="onConfirmChangeSubscription()"
  #subscriptionSummaryModal
></subscription-summary-modal>

<comm-confirmation-dialog
  [message]="'SUBSCRIPTION.MULTIPLIER_INPUT'"
  [headerImg]="'robot-glasses-border'"
  [confirmationLabel]="'SUBSCRIPTION.CONFIRM_LABEL_ASK_MULTIPLIER'"
  [cancelLabel]="'SUBSCRIPTION.CANCEL_LABEL_ASK_MULTIPLIER'"
  [modalClosable]="false"
  [escClosable]="false"
  [disabled]="!multiplierInputValid"
  [disableOnlyConfirmation]="true"
  (confirmAction)="onMultiplierInputConfirm()"
  (cancelAction)="onMultiplierInputCancel()"
  #askCondoInputModal
>
  <div confirmation-content>
    <form [formGroup]="form">
      <label for="multiplier-input" *ngIf="subscriptionDraft && subscriptionDraft.plan">
        {{
          (subscriptionDraft.plan.multiplier === _multiplier[_multiplier.USER]
            ? 'SUBSCRIPTION.MULTIPLIER_INPUT_USERS'
            : 'SUBSCRIPTION.MULTIPLIER_INPUT_UNITS'
          ) | translate
        }}</label
      >
      <input
        id="multiplier-input"
        type="number"
        class="form-control"
        formControlName="multiplierInput"
      />
    </form>
  </div>
</comm-confirmation-dialog>

<ng-template *ngIf="loading; then loader; else content"></ng-template>

<ng-template #loader>
  <div id="loader-container">
    <tsq-loader></tsq-loader>
  </div>
</ng-template>

<ng-template #content>
  <ng-template *ngIf="error; then errorContainer; else subsContainer"></ng-template>

  <ng-template #errorContainer>
    <div id="error-container">
      <img src="./assets/img/icons/robot-error.png" alt="" />
      <h1>
        <i>{{ 'SUBSCRIPTION.SUBSCRIPTION_ERROR_TITLE' | translate }}</i>
      </h1>
      <p [innerHtml]="'SUBSCRIPTION.SUBSCRIPTION_ERROR_DESCRIPTION' | translate"></p>
      <button class="btn btn-primary" (click)="getCondoAndSubs(condoId)">
        {{ 'SUBSCRIPTION.TRY_AGAIN' | translate }}
      </button>
    </div>
  </ng-template>

  <ng-template #subsContainer>
    <div class="col-xs-12">
      <tsq-main-info-card
        [title]="condo.name"
        [subtitle]="'SUBSCRIPTION.CHANGE_PLAN' | translate"
        [titleColor]="'battleship-grey'"
      ></tsq-main-info-card>
      <div class="row">
        <div class="col-xs-8 no-padding-right">
          <div class="collapse-card">
            <div class="pointer-cursor flex items-center" (click)="openTab = _tabs.PLANS">
              <img src="./assets/img/icons/select-plan.svg" alt="" />
              <span class="grow">{{ 'SUBSCRIPTION.SELECT_A_PLAN' | translate }}</span>
              <i
                class="fa pull-right"
                [ngClass]="{
                  'fa-angle-up': openTab === _tabs.PLANS,
                  'fa-angle-down': openTab !== _tabs.PLANS
                }"
              ></i>
            </div>
            <div [ngClass]="openTab !== _tabs.PLANS ? 'hidden' : 'block'">
              <div class="divider"></div>
              <comm-plan-selection
                [currentPlan]="subscriptionDraft.plan"
                [availablePlans]="availablePlans"
                (planSelected)="onPlanSelected($event)"
              ></comm-plan-selection>
            </div>
          </div>

          <div class="collapse-card">
            <div
              class="pointer-cursor flex items-center"
              (click)="openTab = _tabs.FEATURE_PACKAGES"
            >
              <img src="./assets/img/icons/add-features.svg" alt="" />
              <span class="grow">{{ 'SUBSCRIPTION.ADD_FEATURES' | translate }}</span>
              <i
                class="fa pull-right"
                [ngClass]="{
                  'fa-angle-up': openTab === _tabs.FEATURE_PACKAGES,
                  'fa-angle-down': openTab !== _tabs.FEATURE_PACKAGES
                }"
              ></i>
            </div>
            <div [ngClass]="openTab !== _tabs.FEATURE_PACKAGES ? 'hidden' : 'block'">
              <div class="divider"></div>
              <feature-packages-selection
                [selectedPlan]="subscriptionDraft.plan"
                [selectedFeaturePackages]="subscriptionDraft.featurePackages"
                (toggleFeaturePackage)="onFeaturePackageToggle($event)"
                (errorLoadingFeaturePackages)="error = true"
              ></feature-packages-selection>
            </div>
          </div>

          <div class="collapse-card">
            <div class="pointer-cursor flex items-center" (click)="openTab = _tabs.DISCOUNTS">
              <img src="./assets/img/icons/discounts.svg" alt="" />
              <span class="grow">{{ 'SUBSCRIPTION.OFFER_DETAILS' | translate }}</span>
              <i
                class="fa pull-right"
                [ngClass]="{
                  'fa-angle-up': openTab === _tabs.DISCOUNTS,
                  'fa-angle-down': openTab !== _tabs.DISCOUNTS
                }"
              ></i>
            </div>
            <div [ngClass]="openTab !== _tabs.DISCOUNTS ? 'hidden' : 'block'">
              <div class="divider"></div>
              <comm-charge-value
                *ngIf="environmentConfig !== communitiesEnvironments.US_PROD"
                [chargeValue]="subscriptionDraft.chargeValue"
                (chargeValueChanged)="onChargeValueChanged($event)"
              >
              </comm-charge-value>
              <discounts-selection
                (discountsSelectionChanged)="onDiscountsSelectionChanged($event)"
                #discountsSelection
              ></discounts-selection>
              <div id="trialContainer">
                <trial-selection
                  (trialSelectionChanged)="onTrialSelectionChanged($event)"
                  #trialSelection
                ></trial-selection>
              </div>
              <div id="chargeDateContainer">
                <charge-date
                  [date]="
                    subscriptionDraft.nextChargeDate ? subscriptionDraft.nextChargeDate : now()
                  "
                  (nextChargeDateChanged)="onNextChargeDateChanged($event)"
                ></charge-date>
              </div>
            </div>
          </div>

          <div class="collapse-card">
            <div class="pointer-cursor flex items-center" (click)="openTab = _tabs.BILLING_DETAILS">
              <img src="./assets/img/icons/billing.svg" alt="" />
              <span class="grow">{{ 'SUBSCRIPTION.BILLING_DETAILS' | translate }}</span>

              <div id="build-toggle" class="pull-right inline-block">
                <comm-payment-info-type-selection
                  *ngIf="
                    environmentConfig !== 'PROD' &&
                    featureToggle.SUBSCRIPTION_PAYMENT_TYPE_SELECTION
                  "
                  [environment]="environmentConfig"
                  [(type)]="paymentInfoDraft.type"
                >
                </comm-payment-info-type-selection>
              </div>

              <i
                class="fa pull-right"
                [ngClass]="{
                  'fa-angle-up': openTab === _tabs.BILLING_DETAILS,
                  'fa-angle-down': openTab !== _tabs.BILLING_DETAILS
                }"
              ></i>
            </div>
            <div
              [formGroup]="billingDetails"
              [ngClass]="openTab !== _tabs.BILLING_DETAILS ? 'hidden' : 'block'"
            >
              <div class="divider"></div>
              <comm-billing-info
                (brBillingChanged)="brBillingChanged($event)"
                (usBillingChanged)="usBillingChanged($event)"
                [paymentInfo]="paymentInfoDraft"
              >
              </comm-billing-info>
              <comm-community-document-number
                formControlName="document"
                *ngIf="paymentInfoDraft.type === paymentTypes.BankSlip"
              >
              </comm-community-document-number>

              <comm-taxes-selection
                [form]="taxesToPayForm"
                *ngIf="paymentInfoDraft.type === paymentTypes.BankSlip"
              >
              </comm-taxes-selection>

              <comm-customer-taxes
                [form]="customerTaxes"
                *ngIf="paymentInfoDraft.type === paymentTypes.BankSlip"
              >
              </comm-customer-taxes>

              <div id="responsibleContainer">
                <subscription-responsible-selection
                  [condoId]="condo.id"
                  [responsibleUser]="subscriptionDraft.responsible"
                  (validityChanged)="responsibleValidChanged($event)"
                  (subscriptionResponsibleChanged)="onSubscriptionResponsibleChanged($event)"
                  #UsSubscriptionResponsibleSelection
                ></subscription-responsible-selection>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xs-4">
          <subscription-cart
            [(openTab)]="openTab"
            [fixedCharges]="subscriptionDraft.fixedCharges"
            [selectedPlan]="subscriptionDraft.plan"
            [selectedPackages]="subscriptionDraft.featurePackages"
            [percentage]="percentageForCart"
            [rawValue]="rawValueForCart"
            [paymentInfo]="paymentInfoDraft"
            [responsibleForPayment]="subscriptionDraft.responsible"
            [amount]="totalSubscriptionValue.toFixed(2)"
            [cartValid]="cartValid()"
            (changeSubscriptionClicked)="subscriptionSummaryModal.openSummary()"
            (cancelSubscriptionClicked)="onCancelChangeSubscription()"
            #subsCart
          ></subscription-cart>
        </div>
      </div>
    </div>
  </ng-template>
</ng-template>
