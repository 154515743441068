export class SubscriptionResponsible {
  email: string;
  phone: string;

  static from(email: string, phone: string): SubscriptionResponsible {
    const sr = new SubscriptionResponsible();
    sr.email = email;
    sr.phone = phone;
    return sr;
  }
}
