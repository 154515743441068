<div class="zero-data">
  <div class="row">
    <div class="col-md-12 top-text">
      {{ topText }}
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 illustration">
      <img src="{{ illustrationSrc }}" alt="" />
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 bottom-text" [innerHTML]="bottomText | sanitizeHtml"></div>
  </div>
  <div class="row">
    <div class="col-md-10 col-md-offset-1 call-to-action">
      <ng-content select="[actions]"></ng-content>
    </div>
  </div>
</div>
