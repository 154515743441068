<div id="list">
  <form [formGroup]="form" class="form-group">
    <div class="panel panel-transparent">
      <div class="panel-heading">{{ 'SUBSCRIPTION.DISCOUNT' | translate }}</div>
      <div class="panel-body">
        <div formArrayName="discounts">
          <div
            class="row"
            *ngFor="let discounts of discountsArrayControl; let i = index"
            [formGroupName]="i"
          >
            <div class="col-xs-4">
              <label for="percentage">{{ 'SUBSCRIPTION.PERCENTAGE' | translate }}</label>
              <input id="percentage" class="form-control" formControlName="percentage" />
              <span>{{ 'SUBSCRIPTION.OR' | translate }}</span>
            </div>
            <div class="col-xs-4">
              <label for="gross">{{ 'SUBSCRIPTION.GROSS' | translate }}</label>
              <input id="gross" class="form-control" formControlName="gross" />
            </div>
            <div class="col-xs-4 one-time">
              <tsq-card-for-switch label="{{ 'SUBSCRIPTION.ONE_TIME' | translate }}">
                <sc-switch
                  [size]="'small'"
                  [checked]="form.controls['discounts'].at(i).controls['oneTime'].value"
                  (change)="form.controls['discounts'].at(i).controls['oneTime'].setValue($event)"
                ></sc-switch>
              </tsq-card-for-switch>
              <button class="btn white-btn inline-block" *ngIf="i > 0" (click)="removeDiscount(i)">
                <img src="./assets/img/icons/icon-remove-orange.svg" alt="" />
              </button>
            </div>
          </div>
        </div>

        <div class="row" id="add-more">
          <u (click)="addDiscount()">{{ 'SUBSCRIPTION.ADD_DISCOUNT' | translate }}</u>
        </div>
      </div>
    </div>
  </form>
</div>
