import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlatformModule } from '@angular/cdk/platform';

import { TranslateModule } from '@ngx-translate/core';

import { AssetsModule } from '@tsq-web/assets';
import {
  NativeAppBannerWrapperComponent,
} from './components/native-app-banner-wrapper/native-app-banner-wrapper.component';
import { NativeAppBannerComponent } from './components/native-app-banner/native-app-banner.component';
import {
  NativeAppDownloadButtonComponent,
} from './components/native-app-download-button/native-app-download-button.component';

@NgModule({
  imports: [
    CommonModule,
    PlatformModule,
    TranslateModule,
    AssetsModule,
  ],
  declarations: [
    NativeAppBannerWrapperComponent,
    NativeAppBannerComponent,
    NativeAppDownloadButtonComponent,
  ],
  exports: [
    NativeAppBannerWrapperComponent,
  ],
})
export class NativeAppBannerModule {
}
