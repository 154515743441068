/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'sc-modal',
  templateUrl: 'sc-modal.component.html',
  styleUrls: ['sc-modal.component.scss'],
})
export class SCModalComponent {
  @ViewChild('modalControl', { static: true }) modalControl: ModalDirective;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() modalCloseable = true;
  @Input() escClosable = true;
  @Input() ignoreFocusChange = false;
  @Input() center = false;
  @Input() animated = true;
  @Input() animation = 'fadeInRight';
  @Output() onHide = new EventEmitter();
  @Output() onShown = new EventEmitter();
  @Output() onHidden = new EventEmitter();
  @Output() onClick = new EventEmitter();
  @Input() showHeader = true;
  @Input() showFooter = true;
  @Input() zeroPadding = false;
  @Input() fixedBannerSize = false;
  @Input() bigModalSize = false;
  @Input() centerVertical = false;

  onAfterHideModal(): void {}

  getComplementaryClasses() {
    const classes: string[] = [];
    if (!this.center) {
      classes.push('register-modal');
    }
    return classes.join(' ');
  }

  show() {
    this.modalControl.show();
  }

  modalClicked() {
    this.onClick.emit();
  }

  shownEmit() {
    this.onShown.emit();
  }

  hide() {
    this.modalControl.hide();
  }

  isShown() {
    return this.modalControl === undefined ? false : this.modalControl.isShown;
  }

  isAnimationType(type: string) {
    if (!this.animated) return false;

    return this.animation === type;
  }

  onHideModal(event: any) {
    this.onHide.emit(event);
  }

  onHiddenModal(event: any) {
    this.onHidden.emit(event);
    this.onAfterHideModal();
  }
}
