/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl } from '@angular/forms';

import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';

@Component({
  selector: 'tsq-dropdown-button',
  templateUrl: './dropdown-button.component.html',
  styleUrls: ['./dropdown-button.component.scss'],
  providers: [{ provide: BsDropdownConfig, useValue: { isAnimated: true, autoClose: true } }],
})
export class DropdownButtonComponent implements OnInit, ControlValueAccessor {
  @Input() placeholder: string;
  @Input() icon: string;

  control: AbstractControl;
  itemsText: string[];
  selectedItemText: string;

  private selectedItemRaw: any;
  private _optionField: string | ((item: any) => string);
  private _items: any[];
  private _disabled: boolean;

  constructor(@Optional() @Self() public ngControl: NgControl) {
    ngControl.valueAccessor = this;
  }

  onChanged: (item: any) => void;
  onTouched: () => void;

  ngOnInit(): void {
    this.control = this.ngControl.control;
  }

  @Input() set optionField(value: string | ((item: any) => string)) {
    this._optionField = value;

    this.setItemsText();

    if (!!this.selectedItemRaw) {
      this.writeValue(this.selectedItemRaw);
    }
  }

  @Input() set items(value: any[]) {
    this._items = value;
    this.setItemsText();
  }

  get disabled(): boolean {
    return this._disabled;
  }

  set selectItem(index: number) {
    this.onChanged(this._items[index]);
    this.writeValue(this._items[index]);
  }

  registerOnChange(fn: (item: any) => void): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  writeValue(item: any): void {
    this.selectedItemRaw = item;
    this.selectedItemText = this.getItemText(item);
  }

  setItemsText(): void {
    this.itemsText = [];
    if (!!this._items) {
      this._items.forEach(v => this.itemsText.push(this.getItemText(v)));
    }
  }

  getItemText(item: any): string {
    if (typeof item === 'string' || !item) {
      return item;
    }

    if (typeof this._optionField === 'string') {
      return item[this._optionField];
    }

    return !!this._optionField ? this._optionField(item) : '';
  }
}
