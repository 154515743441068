import { createAction, props } from '@ngrx/store';

import { Plan } from '../plan.json';

export const load = createAction(
  '[Organization Subscription] Load Plans',
);

export const loadSuccess = createAction(
  '[TownSq API] Load Plans Success',
  props<{ entities: Plan[] }>(),
);

export const loadFailure = createAction(
  '[TownSq API] Load Plans Failure',
);
