/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sc-pager-bullets',
  templateUrl: 'sc-pager-bullets.component.html',
  styleUrls: ['sc-pager-bullets.component.scss'],
})
export class SCPagerBulletsComponent {
  @Input() showBorders = false;
  @Input() showTitle = true;
  @Input() backgroundColor = 'white';
  @Input() pagerShadow = false;
  @Input() pagerPadding = 'small';
  @Input() hasMaxBullets = true;
  @Input() bulletBottom = false;
  @Input() bulletAlignLeft = false;
  @Input() bulletAlignCenter = false;
  @Input() set bulletsSize(size: number) {
    this._bulletSize = size;
    this.currentSelected = 0;
  }
  @Output() indexSelectedEvent = new EventEmitter();

  readonly MAX_BULLETS = 5;

  get bulletsSize(): number {
    return this._bulletSize;
  }
  _bulletSize: number;
  currentSelected = 0;
  bulletsArray = Array;

  get numberOfBulletsToDisplay(): number {
    if (this.hasMaxBullets) {
      return this.bulletsSize > 5 ? this.MAX_BULLETS : this.bulletsSize;
    }
    return this.bulletsSize;
  }

  selectItem(index: number) {
    this.currentSelected = index;
    this.indexSelectedEvent.emit(index);
  }
}
