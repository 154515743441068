import {
  AsyncPipe,
  NgClass,
  NgFor,
  NgIf,
  NgStyle,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  QueryList,
  TemplateRef,
  ViewChildren,
  inject,
} from '@angular/core';
import { RouterLink } from '@angular/router';

import { LetModule } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import {
  BehaviorSubject,
  Subject,
  map,
  pairwise,
  scan,
  startWith,
  switchScan,
  tap,
  withLatestFrom,
} from 'rxjs';

import { TableColumn, TableConfig } from '../../models/table.model';
import { TableSortService } from '../../services/table-sort/table-sort.service';
import { TableColumnsStore } from '../../state/table-columns/table-columns.store';
import {
  buildTableConfig,
  getGridSizeConfigForColumn,
  getGridSizeConfigForColumns,
  recalculateColumnsWidth,
} from '../../utils/table';
import { TableCellActionsComponent } from '../table-cell-actions/table-cell-actions.component';
import { TableCellAvatarComponent } from '../table-cell-avatar/table-cell-avatar.component';
import { TableCellCurrencyComponent } from '../table-cell-currency/table-cell-currency.component';
import { TableCellDummyComponent } from '../table-cell-dummy/table-cell-dummy.component';
import { TableCellIconComponent } from '../table-cell-icon/table-cell-icon.component';
import { TableCellLinkComponent } from '../table-cell-link/table-cell-link.component';
import { TableCellTagComponent } from '../table-cell-tag/table-cell-tag.component';
import { TableCellTextComponent } from '../table-cell-text/table-cell-text.component';
import { TableHeaderComponent } from '../table-header/table-header.component';
import { TableSettingsComponent } from '../table-settings/table-settings.component';

@Component({
  standalone: true,
  imports: [
    AsyncPipe,
    NgClass,
    NgFor,
    NgIf,
    NgStyle,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    RouterLink,
    LetModule,
    TranslateModule,
    TableHeaderComponent,
    TableCellTextComponent,
    TableCellCurrencyComponent,
    TableCellLinkComponent,
    TableCellAvatarComponent,
    TableCellTagComponent,
    TableCellIconComponent,
    TableCellActionsComponent,
    TableCellDummyComponent,
    TableSettingsComponent,
  ],
  providers: [TableColumnsStore, TableSortService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'tsq-table',
  templateUrl: './table.component.html',
  styles: [
    `
      a {
        &:focus-visible {
          outline: theme('colors.purple.DEFAULT') dotted 2px;
          outline-offset: 0;
        }

        &:focus:not(:focus-visible) {
          outline: none;
        }
      }
    `,
  ],
})
export class TableComponent<TRowData> {
  @Input() data: TRowData[];
  @Input() actionsTemplate: TemplateRef<{ $implicit: TRowData }>;

  protected readonly resizeSubject = new Subject<{ id: string; value: number } | undefined>();

  private readonly configSubject = new BehaviorSubject<TableConfig<TRowData> | undefined>(
    undefined,
  );
  protected readonly config$ = this.configSubject.asObservable().pipe(
    tap(value => {
      this.tableSort.listen(value?.sort);
    }),
    map(value => {
      return buildTableConfig(value);
    }),
    tap(config => {
      this.columnsStore.setTableSettingsChangeFn(config.tableSettings.change);
    }),
  );

  private readonly columnsStore = inject<TableColumnsStore<TRowData>>(TableColumnsStore);
  protected readonly headers$ = this.columnsStore.headers$;
  protected readonly columns$ = this.columnsStore.columns$;

  protected readonly gridTemplateColumns$ = this.columns$.pipe(
    startWith(undefined),
    pairwise(),
    switchScan((acc, [oldColumns, columns]) => {
      let initialGridSizeConfig: { id: string | symbol; value: string }[] = [];

      if (acc.length === 0 || oldColumns.length !== columns.length) {
        initialGridSizeConfig = getGridSizeConfigForColumns(columns);
      } else {
        initialGridSizeConfig = columns.map(column => {
          const foundConfig = acc.find(value => value.id === column.id);

          if (!foundConfig) {
            return getGridSizeConfigForColumn(column);
          }

          return foundConfig;
        });
      }

      return this.resizeSubject.asObservable().pipe(
        startWith(undefined),
        withLatestFrom(this.tableHeaderElsSubject.asObservable()),
        scan((state, [event, headerEls]) => {
          if (!event?.id || headerEls?.length === 0) {
            return initialGridSizeConfig;
          }

          return recalculateColumnsWidth(state, event.id, event.value, headerEls);
        }, initialGridSizeConfig),
      );
    }, []),
    map(gridSizeConfig => {
      return gridSizeConfig.map(config => config.value).join(' ');
    }),
  );

  private readonly tableHeaderElsSubject = new BehaviorSubject<HTMLElement[]>([]);

  private readonly tableSort = inject(TableSortService);

  @Input()
  set config(value: TableConfig<TRowData>) {
    this.configSubject.next(value);
  }

  @Input()
  set columns(value: TableColumn<TRowData>[]) {
    this.columnsStore.setColumns(value);
  }

  protected trackByRowData(index: number, rowData: TRowData): unknown {
    return typeof rowData === 'object' && 'id' in rowData ? rowData.id : index;
  }

  @ViewChildren(TableHeaderComponent, { read: ElementRef })
  private set tableHeaderEls(value: QueryList<ElementRef<HTMLElement>>) {
    this.tableHeaderElsSubject.next(value.map(item => item.nativeElement));
  }

  @HostListener('window:resize')
  private onWindowResize(): void {
    this.resizeSubject.next(undefined);
  }
}
