export class FeaturesStatuses {
  alertsActive = true;
  assignmentsActive = true;
  calendarActive = true;
  lobbyActive = true;
  cftvActive = false;
  trusteeDiscActive = true;
  discussionActive = true;
  documentsActive = true;
  neighborsActive = true;
  pollsActive = true;
  residentDiscussionActive = true;
  serviceActive = true;
  occupantsActive = true;
  websiteActive = false;
  packageDeliveryActive = false;
  shiftLogsActive = false;
  architecturalReviewActive = false;
  digitalVotingActive = false;
  viewUsersFullNamesActive = false;
  userCustomFieldsActive = false;
  bonvoyNumberActive = false;
  petRegistryActive = false;
  valetParkingActive = false;
  emergencyBroadcastActive = false;
  conciergeHubActive = false;
  serviceLogsActive = false;
  keyManagementActive = false;
  trusteeDiscussionPerformanceReviewActive = true;
  replySuggestionActive = true;
  requestInterceptionActive = true;
}
