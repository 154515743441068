/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'sc-radio-button',
  templateUrl: 'sc-radio-button.component.html',
  styleUrls: ['sc-radio-button.component.scss'],
})
export class SCRadioButtonComponent implements OnInit {
  @Input() id: string;
  @Input() value: any;
  @Input() inputName: string;
  @Output() onClick = new EventEmitter();

  ngOnInit() {}
}
