import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { SCWidgetModule } from './component/sc.widget.module';

@NgModule({
  imports: [
    HttpClientModule,
    TypeaheadModule,
    ProgressbarModule.forRoot(),
    SCWidgetModule,
    TranslateModule,
  ],
  exports: [SCWidgetModule, TranslateModule],
})
export class LibsModule {}
