/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

import { BaseService } from '@tsq-web/core';
import { PrivacySettings } from './privacySettings.json';

@Injectable()
export class PrivacySettingsService extends BaseService {
  constructor(
    protected _cookieService: CookieService,
    public _http: HttpClient,
    protected _router: Router,
  ) {
    super(_cookieService, _http, _router);
  }

  getPrivacySettings(userId: string): Observable<PrivacySettings> {
    return this.doGet(`user/${userId}/settings/privacy`);
  }
}
