<div class="row">
  <div class="col-md-12">
    <div class="divider" *ngIf="showBorders"></div>
    <div
      class="pager-bg"
      [ngClass]="{
        'pager-shadow': pagerShadow,
        'bg-white': backgroundColor === 'white',
        'bg-gray': backgroundColor === 'gray',
        'big-padding': pagerPadding === 'big',
        'small-padding': pagerPadding === 'small'
      }"
    >
      <div class="row">
        <div class="col-md-12" *ngIf="showTitle || (bulletsSize > 1 && !bulletBottom)">
          <div class="row">
            <div class="inline-block">
              <ng-content select="[title]"></ng-content>
            </div>

            <div class="inline-block float-right" *ngIf="bulletsSize > 1">
              <div
                *ngFor="let item of bulletsArray(numberOfBulletsToDisplay).fill(1); let i = index"
                class="inline-block"
              >
                <div
                  [class.bullet-active]="currentSelected === i"
                  class="bullet"
                  (click)="selectItem(i)"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="row">
            <ng-content select="[body]"></ng-content>
          </div>
        </div>

        <div class="col-md-12" *ngIf="bulletsSize > 1 && bulletBottom">
          <div
            [class.float-right]="!bulletAlignLeft && !bulletAlignCenter"
            [class.center]="bulletAlignCenter"
            class="inline-block"
          >
            <div
              *ngFor="let item of bulletsArray(numberOfBulletsToDisplay).fill(1); let i = index"
              class="inline-block"
            >
              <div
                [class.bullet-active]="currentSelected === i"
                class="bullet"
                (click)="selectItem(i)"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="divider" *ngIf="showBorders"></div>
  </div>
</div>
