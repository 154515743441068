import { CanMatchFn } from '@angular/router';

import { getFeatureToggle } from '../../shared/feature-toggle';

export function newPasswordRecoveryFlowGuard(): CanMatchFn {
  return () => {
    const isNewFlowActivated = getFeatureToggle().passwordRecovery;
    return isNewFlowActivated;
  };
}
