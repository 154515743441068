import { ModalContext } from './modal-context';
import { ModalTypes } from './modal-types.enum';
import { TSqTemplatePortal } from './template-portal';

export interface ModalState {
  open: boolean;
  type: ModalTypes;
  context: ModalContext;
  portal: TSqTemplatePortal;
}

export const modalInitialState: ModalState = {
  open: false,
  type: undefined,
  context: undefined,
  portal: undefined,
};
