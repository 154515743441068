import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

import { icons } from '@tsq-web/assets';

@Component({
  selector: 'comm-link-expired-banner',
  templateUrl: './link-expired-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkExpiredBannerComponent {
  @Output() closeBanner = new EventEmitter<void>();

  readonly xmarkIcon = icons.xmark;
}
