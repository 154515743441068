/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'tsq-list',
  templateUrl: './tsq-list.component.html',
  styleUrls: ['./tsq-list.component.scss'],
})
export class TSqListComponent {
  @ViewChild('listContainer', { static: true }) listContainer: ElementRef;

  @Input() scrollUp = false;
  @Input() loading = true;
  @Input() selfHandleLoader = true;
  @Input() error = false;
  @Input() selfHandleError = true;
  @Input() errorTitle: string;
  @Input() errorText: string;
  @Input() loadingMore = false;
  @Input() scrollableContainer = '.root-scrollable-content';
  @Input() hasPaginator = false;
  @Input() paginatorTheme: 'avocado' | 'blue';
  @Input() paginatorEntity: string;
  @Input() page: number;
  @Input() totalRecords: number;
  @Input() perPage: number;

  @Output() scrollEvent = new EventEmitter();
  @Output() scrollUpEvent = new EventEmitter();

  @Output() retry = new EventEmitter<any>();
  @Output() pageChanged = new EventEmitter<number>();

  showZeroData = false;

  private _listSize: number;

  @Input() set listSize(size: number) {
    this.showZeroData = size === 0;

    this._listSize = size;
  }

  get listSize(): number {
    return this._listSize;
  }

  onScrollUp(event: any): void {
    if (!this.loading) {
      this.scrollUpEvent.emit(event);
    }
  }

  onScroll(event: any): void {
    if (!this.loading && !this.loadingMore) {
      this.scrollEvent.emit(event);
    }
  }

  retryOnError(): void {
    this.retry.emit();
  }
}
