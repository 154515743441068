import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ThemeService } from './services/theme.service';
import { ThemeBasedValuePipe } from './pipes/theme-based-value.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [ThemeBasedValuePipe],
  exports: [ThemeBasedValuePipe],
})
export class UiModule {
  static forRoot(): ModuleWithProviders<UiModule> {
    return {
      ngModule: UiModule,
      providers: [
        ThemeService,
        {
          provide: APP_INITIALIZER,
          useFactory: () => (): void => undefined,
          deps: [ThemeService],
          multi: true,
        },
      ],
    };
  }
}
