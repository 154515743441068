import { AppType } from '@tsq-web/core';

import { CommunitiesEnvironment } from '../app/shared/environment/models/communities-environment';

/* eslint-disable @typescript-eslint/naming-convention */
export const environment: CommunitiesEnvironment = {
  production: false,
  env: 'US_DEV',
  baseApi: 'https://api-qa.townsq.io',
  appType: AppType.COMMUNITIES,
  communitiesUrl: 'https://qa.townsq.io',
  businessUrl: 'https://business-qa.townsq.io',
  financialUrl: 'https://adm-qa.townsq.com.br',
  firebaseConfig: {
    apiKey: 'AIzaSyAWUhJeksLzvTDU9SLUhO_WI-K_mqkuPPo',
    authDomain: 'townsq-dev.firebaseapp.com',
    databaseURL: 'https://townsq-dev.firebaseio.com',
    projectId: 'townsq-dev',
    storageBucket: 'townsq-dev.appspot.com',
    messagingSenderId: '877022775181',
    appId: '1:877022775181:web:e4e923a7272b4de7459fc4',
    measurementId: 'G-31S4QTTDK8',
  },
  ASSOCIA_ACCOUNT_API: 'https://onboarding-service-dev.townsq.io',
  FINANCIAL_API: 'https://adm-qa.townsq.com.br/api/financial/',
  FINANCIAL_URL: 'https://adm-qa.townsq.com.br',
  BUSINESS_URL: 'https://business-qa.townsq.io',
  FOUNTAIN_API: 'https://dev-fountain.townsq.io',
  fountainSiteProtocol: 'https://',
  FOUNTAIN_LIVE_DOMAIN: 'dev-sites.townsq.io',
  FOUNTAIN_PREVIEW_DOMAIN: 'dev-previews.townsq.io',
  FROALA_LICENSE: 'BWC6D-16A1E3F3A2F1A6vC-21czf1G-10aH1F-7A-32hC7D6C5E4D2F3H3C6B7B6==',
  STRIPE_KEY_ATG: 'pk_test_DIchScr6ft8sBgNnyYgZBSgN',
  STRIPE_KEY_TSQ: 'pk_test_Woxhxu6AAKYV0MgDdVq4bQ99',
  stripeKeyArchReview:
    'pk_test_51HmNhHLxveSlGj1Qwi1gRyHbwVqHZuA6iOJny8SyFP67O007K8lrbk5glYL9ieawMZZisgPTOOj68sJzst4rY1vh00ojc6rEe3',
  APPLICATION_SERVER_PUBLIC_KEY:
    'BBlDhfILr0J8sE0xsBJLyhJCKdrTbLi2cSOnrjiUG1UbRrOO5WM6CnPfEFsm220qIKnp8z6SU-EZeN-C8eoHxeE',
  ENABLE_ERROR_CAPTURE: false,
  INVISION_URL: 'https://community-dev.townsq.io/',
  countryStateCityKey: 'TXNDU1NQNEQyQmVySUtjeWlVa1lnOUhXRDRGRkJ6M0hDRGsxT0lraw==',
  zipCodeApiKey: 'js-9hoLH7wpjAcmybtowi7AwTn2PY5ftxOu9Nv0q3nBYUOETExZtQ2VkW19DfXcgdkb',
  configCatSdkKey: 'configcat-sdk-1/dJvcCPLzbUuJMKYbgWQXyw/u2GnPzcOa0mtTZ5A7C5UaA',
  vsMicroAppsCdnBaseUrl: 'https://cdn.vendorsmarttest.com'
};
