import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { icons } from '@tsq-web/assets';

@Component({
  selector: 'tsq-chip',
  templateUrl: './tsq-chip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TSqChipComponent {
  @Input() selected: boolean;
  @Input() text: string;
  @Input() hasCheck = true;
  @Input() hasRemove = false;
  @Input() lock: boolean;
  @Input() disabled: boolean;
  @Input() invalid: boolean;
  @Input() isPrimary: boolean;
  @Input() isNewDesign = false;

  @Output() removeTag = new EventEmitter();

  protected colorSchema: 'new-primary' | 'primary' = 'primary';
  protected isNewPrimary = false;

  protected readonly icons = icons;

  @Input() set schema(value: 'new-primary' | 'primary') {
    this.colorSchema = value;
    this.isNewPrimary = value === 'new-primary';
  }
}
