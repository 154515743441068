import { TemplateRef, ViewContainerRef } from '@angular/core';
import { TemplatePortal } from '@angular/cdk/portal';

import { ModalContext } from './modal-context';

export class TSqTemplatePortal extends TemplatePortal<ModalContext> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(
    template: TemplateRef<ModalContext>,
    viewContainerRef: ViewContainerRef,
    context: ModalContext,
  ) {
    super(template, viewContainerRef, context);
  }
}
