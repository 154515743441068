import { IntegrationType } from './integration-type.enum';
import { InviteFlow } from './invite-flow.enum';
import { SupportedReports } from './supported-reports.enum';

export class AdministratorSettings {
  onboardingJourneyEngine = 'TOWNSQ';
  accountingFlow: 'LEDGER' | 'BOLETO' = 'LEDGER';
  disabledReports: SupportedReports[];
  inviteFlow: InviteFlow;
  integrationType: IntegrationType;
  allowsSendingBoletoEmail = false;
  allowsRequestingNewBoleto = false;
  stripeConnectedAccount: string;
}
