import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'tsq-error',
  templateUrl: './tsq-error.component.html',
  styleUrls: ['./tsq-error.component.scss'],
})
export class TSqErrorComponent {
  @Input() title: string;
  @Input() text: string;
  @Input() hasRetry = true;
  @Output() retry = new EventEmitter();
}
