<div class="tsq-card p-8">

  <div class="flex items-center gap-16">
    <div class="group w-full">
      <input type="text"
             class="control w-full border-1 border-petro-n2 rounded resize-y"
             [placeholder]="customPlaceHolder ?? 'ADD_COMMENT' | translate"
             (focus)="focused.emit()">
    </div>

    <tsq-icon [icon]="icons.send" [classes]="'text-primary'"></tsq-icon>
  </div>

</div>
