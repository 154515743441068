import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpService } from '@tsq-web/core';
import { getTSqEnvironment } from '@tsq-web/environment';

import { LoginData } from '../models/login-data.model';
import { LoginResponse } from '../models/login-response.model';
import { getBrowserNameAndVersion } from '../utils/browser-metadata.util';

@Injectable()
export class SingleSignOnService extends HttpService {
  private readonly httpClient = inject(HttpClient);

  redirect(params: { email: string; uri: string }): string {
    const { email, uri } = params;
    return this.getFullUrl(`login/sso?email=${email}&redirect=${uri}`, 'v1');
  }

  sso(code: string): Observable<LoginResponse> {
    const url = this.getFullUrl('login/sso/callback', 'v1');

    const headers = new HttpHeaders({
      ['X-Townsq-Application']: this.application(),
    });

    return this.httpClient.post<LoginResponse>(url, { code }, { headers });
  }

  ssoV0(code: string): Observable<LoginData> {
    const url = this.getFullUrl('login/sso/callback');

    return this.httpClient.post<LoginData>(url, { code });
  }

  checkSsoConnection(email: string): Observable<{ ssoConection: boolean }> {
    let params = new HttpParams();
    params = params.append('email', email);

    return this.httpClient.get<{ ssoConection: boolean }>(
      this.getFullUrl('login/sso/conection', 'v1'),
      { params },
    );
  }

  private application(): string {
    const appType = getTSqEnvironment().appType?.toLocaleLowerCase();
    return `${appType}/1.0.0 webapp/1 ${getBrowserNameAndVersion()}`;
  }
}

const messages = {
  ['unexpected']: 'LOGIN.SSO_ERROR.UNEXPECTED',
  ['sso_disabled']: 'LOGIN.SSO_ERROR.SSO_DISABLED',
  ['redirect_not_allowed']: 'LOGIN.SSO_ERROR.REDIRECT_NOT_ALLOWED',
  ['unsupported_provider']: 'LOGIN.SSO_ERROR.UNSUPPORTED_PROVIDER',
  ['unsupported_domain']: 'LOGIN.SSO_ERROR.UNSUPPORTED_DOMAIN',
  ['access_denied']: 'LOGIN.SSO_ERROR.ACCESS_DENIED',
  ['ambiguous_connection_selector']: 'LOGIN.SSO_ERROR.AMBIGUOUS_CONNECTION_SELECTOR',
  ['connection_invalid']: 'LOGIN.SSO_ERROR.CONNECTION_INVALID',
  ['connection_domain_invalid']: 'LOGIN.SSO_ERROR.CONNECTION_DOMAIN_INVALID',
  ['connection_strategy_invalid']: 'LOGIN.SSO_ERROR.CONNECTION_STRATEGY_INVALID',
  ['connection_unlinked']: 'LOGIN.SSO_ERROR.CONNECTION_UNLINKED',
  ['invalid_connection_selector']: 'LOGIN.SSO_ERROR.INVALID_CONNECTION_SELECTOR',
  ['organization_invalid']: 'LOGIN.SSO_ERROR.ORGANIZATION_INVALID',
  ['oauth_failed']: 'LOGIN.SSO_ERROR.OAUTH_FAILED',
  ['profile_not_allowed_outside_organization']: 'LOGIN.SSO_ERROR.PROFILE_NOT_ALLOWED',
  ['server_error']: 'LOGIN.SSO_ERROR.SERVER_ERROR',
};

type SSOError = keyof typeof messages;

export function singleSignOnErrorKey(code: string): string {
  return messages[code?.toLowerCase() as SSOError] || 'LOGIN.SSO_ERROR.UNEXPECTED';
}
