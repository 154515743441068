export class CommentTag {
  icon?: string;
  text: string;
  class: CommentTagClass;
}

export enum CommentTagClass {
  Blueberry = 'blueberry',
  Error = 'error',
  Grey = 'grey',
  Primary = 'primary',
  TextPrimary = 'text-primary',
}
