<div class="toast-container-div">
  <div class="bg-red-n1">
    <tsq-icon [icon]="libIcons.xmarkCircleFill" [classes]="'text-red w-40 h-40'"></tsq-icon>
  </div>
  <div class="info-container">
    <span class="title" id="toast--span--title">{{ title }}</span>
    <span *ngIf="!!message && !enableHtml" id="toast--span--message">{{ message }}</span>
    <span
      *ngIf="!!message && enableHtml"
      id="toast--span--message-html-enabled"
      [innerHtml]="message"
    ></span>
  </div>
  <div>
    <button (click)="onRemove()">
      <tsq-icon
        [icon]="libIcons.xmark"
        [classes]="'text-red w-16 h-16 top-16 right-16 absolute'"
      ></tsq-icon>
    </button>
  </div>
</div>
