import { Component, Input, Optional, Self, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

import { FormSelectionOption } from '../../models/form-selection-option.model';

@Component({
  selector: 'tsq-radio-group',
  templateUrl: './tsq-radio-group.component.html',
})
export class TSqRadioGroupComponent implements ControlValueAccessor {
  @Input() options: FormSelectionOption[];
  @Input() itemTemplate: TemplateRef<{ item: FormSelectionOption }>;

  @Input() showLongText: boolean;
  @Input() inline: boolean;
  @Input() inlineWrap = false;
  @Input() schema: 'new-primary' | 'primary' = 'primary';

  selected: FormSelectionOption;
  disabled: boolean;

  constructor(@Optional() @Self() public ngControl: NgControl) {
    ngControl.valueAccessor = this;
  }

  onChange: (value: FormSelectionOption) => void;
  onTouched: () => void;

  registerOnChange(fn: (value: FormSelectionOption) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: FormSelectionOption): void {
    this.selected = value;
  }

  onRadioSelected(option: FormSelectionOption): void {
    if (!option.isDisabled && !this.disabled) {
      this.onTouched();
      this.onChange(option);
      this.writeValue(option);
    }
  }
}
