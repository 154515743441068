import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { FileUploader } from 'ng2-file-upload';

import { icons } from '@tsq-web/assets';

import { FileMeta } from '../../models/file-meta.model';

@Component({
  selector: 'tsq-uploaded-list',
  templateUrl: './tsq-uploaded-list.component.html',
  styleUrls: ['./tsq-uploaded-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TSqUploadedListComponent {
  @Input() uploader: FileUploader;
  @Input() uploading: boolean;
  @Input() disabled: boolean;
  @Input() files: FileMeta[];
  @Input() defaultFileName: string;

  @Output() delete = new EventEmitter<number>();

  readonly trashIcon = icons.trash;
  readonly documentIcon = icons.document;
}
