<ng-container *ngrxLet="{ config: config$, headers: headers$, columns: columns$ } as vm">
  <div
    *ngIf="vm.config.itemCounter.show || vm.config.tableSettings.show"
    class="relative z-[1] flex h-48 items-center"
  >
    <span class="small-text-bold text-coal-secondary" *ngIf="vm.config.itemCounter.show">
      {{
        vm.config.itemCounter.total > -1
          ? ('LIBS.TABLE.SHOWING_ITEMS_OF'
            | translate : { inPage: data?.length ?? '-', total: vm.config.itemCounter.total })
          : ('LIBS.TABLE.SHOWING_ITEMS' | translate : { num: data?.length ?? '-' })
      }}
    </span>

    <tsq-table-settings *ngIf="vm.config.tableSettings.show" />
  </div>

  <div
    role="grid"
    class="grid"
    [ngStyle]="{ 'grid-template-columns': gridTemplateColumns$ | async }"
  >
    <ng-container *ngFor="let header of vm.headers">
      <tsq-table-header
        [colId]="header.id"
        [text]="header.text"
        [align]="header.align"
        [resizable]="vm.config.resizeColumns.enable && header.resizable"
        [sortable]="header.sortable"
        (resizeColumn)="resizeSubject.next({ id: header.id, value: $event })"
      />
    </ng-container>

    <ng-container *ngFor="let rowData of data; trackBy: trackByRowData">
      <a
        role="row"
        *ngrxLet="vm.config.row.drillDown(rowData) as drillDown"
        class="base-text bg-petro-p1 group col-start-1 col-end-[-1] grid grid-cols-subgrid rounded text-inherit hover:text-inherit hover:no-underline focus:no-underline focus-visible:z-[1]"
        [ngClass]="{
          'hover:bg-petro-n1 cursor-pointer': !!drillDown?.route,
          'cursor-auto': !drillDown?.route
        }"
        [tabindex]="!!drillDown?.route ? 0 : -1"
        [routerLink]="drillDown?.route"
        [relativeTo]="drillDown?.relative ? activatedRoute : null"
        [queryParams]="drillDown?.queryParams"
        [queryParamsHandling]="drillDown?.queryParamsHandling"
        [fragment]="drillDown?.fragment"
        [preserveFragment]="drillDown?.preserveFragment"
      >
        <ng-container *ngFor="let col of vm.columns">
          <ng-container [ngSwitch]="col.type">
            <tsq-table-cell-text
              *ngSwitchCase="'text'"
              [content]="col.content"
              [rowData]="rowData"
              [highlight]="
                vm.config.row.highlight.columnId === col.id && vm.config.row.highlight.fn(rowData)
              "
            />

            <tsq-table-cell-currency
              *ngSwitchCase="'currency'"
              [content]="col.content"
              [rowData]="rowData"
            />

            <tsq-table-cell-link
              *ngSwitchCase="'link'"
              [content]="col.content"
              [rowData]="rowData"
            />

            <tsq-table-cell-avatar
              *ngSwitchCase="'avatar'"
              [content]="col.content"
              [rowData]="rowData"
            />

            <tsq-table-cell-tag *ngSwitchCase="'tag'" [content]="col.content" [rowData]="rowData" />

            <tsq-table-cell-icon
              *ngSwitchCase="'icon'"
              [content]="col.content"
              [rowData]="rowData"
            />

            <tsq-table-cell-actions
              *ngSwitchCase="'actions'"
              [content]="col.content"
              [rowData]="rowData"
              [actionsTemplate]="actionsTemplate"
            />

            <tsq-table-cell-dummy *ngSwitchDefault />
          </ng-container>
        </ng-container>
      </a>
    </ng-container>
  </div>
</ng-container>
