import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpService } from '@tsq-web/core';

import { SsoResetPasswordResponse } from '../models/sso-reset-password-response.model';

@Injectable()
export class SsoResetPasswordService extends HttpService {
  private readonly httpClient = inject(HttpClient);

  resetPassword(email: string): Observable<SsoResetPasswordResponse> {
    return this.httpClient.post<SsoResetPasswordResponse>(
      this.getFullUrl('password-reset/mail', 'v2'),
      { userName: email },
    );
  }
}
