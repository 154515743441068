import { inject } from '@angular/core';
import { ActivatedRoute, Params, QueryParamsHandling, Router } from '@angular/router';

export function queryParamToArray<T>(value: string, separator: string = ','): T[] {
  return !!value ? (value.split(separator) as unknown as T[]) : ([] as T[]);
}

export function toggleQueryParamContent<T>(arr: T[], value: T, separator: string = ','): string {
  let arrCopy = [...arr];
  if (arr.some(a => a === value)) {
    arrCopy = arrCopy.filter(a => a !== value);
  } else {
    arrCopy.push(value);
  }

  return !arrCopy.length ? undefined : arrCopy.join(separator);
}

export function updateQueryParams(
  router: Router,
  activatedRoute: ActivatedRoute,
  params: { [key: string]: any },
  queryParamsHandling: 'merge' | 'preserve' | '' = 'merge',
  preserveFragment: boolean = true,
) {
  router.navigate([], {
    relativeTo: activatedRoute,
    queryParamsHandling,
    queryParams: { ...params },
    preserveFragment,
  });
}

export function updateQueryParamsV2(): (
  queryParams: Params,
  queryParamsHandling?: QueryParamsHandling,
  preserveFragment?: boolean,
) => void {
  const router = inject(Router);
  const activatedRoute = inject(ActivatedRoute);

  return (
    queryParams: Params,
    queryParamsHandling: QueryParamsHandling = 'merge',
    preserveFragment = true,
  ) =>
    router.navigate([], {
      relativeTo: activatedRoute,
      queryParamsHandling,
      queryParams,
      preserveFragment,
    });
}

export function clearQueryParams(router: Router, activatedRoute: ActivatedRoute) {
  router.navigate([], { relativeTo: activatedRoute });
}
