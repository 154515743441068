<div id="main-subscription-type-selection--div--container" *ngIf="environment !== 'PROD'">
  <div class="inline-block" *ngIf="environment !== 'US_PROD'">
    <button class="btn" id="main-subscription--button--bank-slip"
            [ngClass]="{ 'btn-primary': type === 'BANK_SLIP', 'white-btn': type !== paymentTypes.BankSlip }"
            (click)="paymentTypeSelected(paymentTypes.BankSlip)">
      {{ 'SUBSCRIPTION.BANK_SLIP' | translate }}
    </button>
  </div>
  <button class="btn" id="main-subscription--button--credit-card"
          [ngClass]="{ 'btn-primary': type === paymentTypes.Stripe, 'white-btn': type !== paymentTypes.Stripe }"
          (click)="paymentTypeSelected(paymentTypes.Stripe)">
    {{ 'SUBSCRIPTION.CREDIT_CARD' | translate }}
  </button>

  <button class="btn" id="main-subscription--button--invoice"
          [ngClass]="{ 'btn-primary':type === paymentTypes.Invoice, 'white-btn': type !== paymentTypes.Invoice }"
          (click)="paymentTypeSelected(paymentTypes.Invoice)">
    {{ 'SUBSCRIPTION.INVOICE' | translate }}
  </button>
</div>
