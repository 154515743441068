import IMask from 'imask';

export function getPercentMask(digits: number, min = 0): IMask.AnyMaskedOptions {
  return {
    mask: 'num%',
    lazy: false,
    blocks: {
      num: {
        mask: Number,
        scale: digits,
        min,
        padFractionalZeros: true,
        radix: '.',
        mapToRadix: [','],
      },
    },
  };
}
