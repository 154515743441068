<div class="file-uploader-wrapper">
  <div
    *ngIf="(!!uploader && !uploading) || selfHandleVisualization"
    class="tsq-card uploader-area h-40"
    [attr.disabled]="files.length >= maxFiles || disabled || uploading"
  >
    <img
      src="/assets/libs/icons/attachment.svg"
      [ngClass]="
        files.length >= maxFiles || disabled || uploading
          ? 'filter-coal-disabled'
          : 'filter-primary'
      "
      alt="{{ 'LIBS.FILE_UPLOAD_ATTACHMENT' | translate }}"
    />

    <input
      ng2FileSelect
      type="file"
      *ngIf="!!uploader && !uploading"
      accept="{{ acceptedFileTypes.toString() }}"
      [multiple]="multiple"
      [uploader]="uploader"
      [disabled]="disabled || files.length >= maxFiles"
      data-cy="inputImage"
    />
  </div>

  <progressbar
    *ngIf="!selfHandleSending && !!uploader && uploading"
    [max]="100"
    [value]="uploader.progress"
  >
    <span>{{ uploader.progress }}%</span>
  </progressbar>

  <ng-container *ngIf="!selfHandleVisualization">
    <tsq-uploaded-list
      [uploader]="uploader"
      [uploading]="uploading"
      [disabled]="disabled"
      [files]="files"
      (delete)="removeAt($event)"
    ></tsq-uploaded-list>
  </ng-container>
</div>
