/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  NgControl,
} from '@angular/forms';

import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { distinctUntilChanged } from 'rxjs/operators';
import { Temporal } from '@js-temporal/polyfill';
import IMask from 'imask';

import { TemporalComparison, to12Hour, to24Hour, TimePeriod } from '@tsq-web/date-time';
import { MaskRangedBlocks } from '../../models/mask-blocks.model';

@UntilDestroy()
@Component({
  selector: 'tsq-hour-input',
  templateUrl: './hour-input.component.html',
  styleUrls: ['./hour-input.component.scss'],
})
export class HourInputComponent implements OnInit, ControlValueAccessor {
  disabled: boolean;
  abstractControl: AbstractControl;

  hourControl: UntypedFormControl = new UntypedFormControl('');
  periodControl: UntypedFormControl = new UntypedFormControl('');
  timePeriod = TimePeriod;
  periodOptions = Object.keys(TimePeriod);

  hourMask: IMask.AnyMaskedOptions;

  private _lang: string;
  private minimumHour: string;
  private onChanged: (value: string) => void;
  private onTouched: () => void;

  constructor(@Optional() @Self() private ngControl: NgControl) {
    ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    this.abstractControl = this.ngControl.control;

    this.hourControl.valueChanges
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe(() => this.handleDateValue());

    this.periodControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => this.handleDateValue());
  }

  @Input() set minHour(value: string) {
    this.minimumHour = value;
    this.checkFormValidity(this.getMilitary());
  }

  @Input() set lang(value: string) {
    this._lang = value;

    this.hourMask = this.getHourMask();
  }

  get lang(): string {
    return this._lang;
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;

    if (this.disabled) {
      this.hourControl.disable();
      this.periodControl.disable();
    } else {
      this.hourControl.enable();
      this.periodControl.enable();
    }
  }

  writeValue(value: string): void {
    if (!!value) {
      if (this.lang === 'pt') {
        this.hourControl.setValue(value);
      } else {
        const hour = +value.split(':')[0];
        const minute = +value.split(':')[1];
        const { time, period } = to12Hour(hour, minute);

        this.hourControl.setValue(time);
        this.periodControl.setValue(period);
      }
    } else {
      this.periodControl.setValue(undefined);
      this.hourControl.setValue('');
    }
  }

  private getHourMask(): IMask.AnyMaskedOptions {
    return {
      mask: 'HH:MM',
      lazy: false,
      blocks: this.getMaskBlocks(),
    };
  }

  private getMaskBlocks(): MaskRangedBlocks {
    const minHours = this.lang === 'pt' ? 0 : 1;
    const maxHours = this.lang === 'pt' ? 23 : 12;

    return {
      HH: {
        mask: IMask.MaskedRange,
        from: minHours,
        to: maxHours,
      },
      MM: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 59,
      },
    };
  }

  private handleDateValue(): void {
    const period = this.periodControl.value;

    if (!!period || this.lang === 'pt') {
      const military = this.getMilitary();

      this.onChanged(military);
      this.onTouched();
      this.checkFormValidity(military);
    }
  }

  private checkFormValidity(time: string): void {
    const hourControlValue = this.hourControl.value.replace(/_/g, '');

    if (!!this.abstractControl) {
      const temporalTime = !!time ? Temporal.PlainTime.from(time) : null;

      if (
        hourControlValue.split(':')[0].length !== 2 ||
        hourControlValue.split(':')[1].length !== 2
      ) {
        this.abstractControl.setErrors({ invalidHourError: true });
      } else if (
        !!temporalTime &&
        !!this.minimumHour &&
        Temporal.PlainTime.compare(temporalTime, Temporal.PlainTime.from(this.minimumHour)) ===
          TemporalComparison.Time1BeforeTime2
      ) {
        this.abstractControl.setErrors({ minHourError: true });
      } else {
        this.abstractControl.setErrors(undefined);
      }
    }
  }

  private getMilitary(): string {
    if (!this.hourControl.value) {
      return null;
    }

    const time = this.hourControl.value || '00:00';
    const hour = parseInt(time.split(':')[0], 10);
    const minute = parseInt(time.split(':')[1], 10);
    const period = this.periodControl.value;

    return to24Hour(hour, minute, period);
  }
}
