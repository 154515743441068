<div class="p-16 pb-0">
  <form [formGroup]="form">
    <div class="bg-white p-16 rounded shadow">
      <div class="grid grid-cols-3">
        <div>
          <label for="charge-value">{{ 'SUBSCRIPTION.CHARGE_VALUE.NAME' | translate }}</label>
          <input id="charge-value" class="form-control" formControlName="chargeValue" />
        </div>
      </div>

      <div class="charge-value-warning mt-16 mb-0 mx-auto p-8 rounded flex">
        <img class="pr-8" src="./assets/icons/warning-triangle.svg" alt="" />
        <span [innerHTML]="'SUBSCRIPTION.CHARGE_VALUE.WARNING_MESSAGE' | translate"></span>
      </div>
    </div>
  </form>
</div>
