<div
  class="tsq-chip"
  [class.tsq-chip-primary]="isNewDesign ? selected : isPrimary"
  [attr.schema]="colorSchema"
  [attr.error]="invalid"
  [attr.selected]="selected && !isNewDesign"
  [attr.disabled]="disabled"
>
  <tsq-icon
    *ngIf="hasCheck && selected"
    [class.mr-8]="!isNewPrimary"
    [classes]="!isNewPrimary && 'size-16'"
    [icon]="icons.check"
  />

  <tsq-icon
    *ngIf="invalid && isNewPrimary"
    classes="size-16"
    [class]="isNewPrimary ? 'ml-8' : 'mr-8'"
    [icon]="icons.triangleExclamation"
  />

  <span>{{ text | translate }}</span>

  <tsq-icon *ngIf="lock" classes="size-16" [class.ml-8]="!isNewPrimary" [icon]="icons.lock" />

  <tsq-icon
    *ngIf="hasRemove"
    classes="size-16"
    [class.ml-8]="!isNewPrimary || lock"
    [icon]="icons.xmarkCircleFill"
    (click)="!disabled && removeTag.emit(); $event.stopPropagation()"
  />
</div>
