<div id="tsq-gallery" #galleryContainer [class.shadow]="hasShadow" [class.p-16]="hasPadding">
  <img
    src="./assets/img/icons/gallery-arrow.svg"
    alt=""
    class="arrow arrow-left pointer-cursor"
    (click)="scrollLeft(list)"
    *ngIf="shouldShowLeftArrow"
  />
  <img
    src="./assets/img/icons/gallery-arrow.svg"
    alt=""
    class="arrow arrow-right pointer-cursor"
    (click)="scrollRight(list)"
    *ngIf="shouldShowRightArrow"
  />

  <div #list (scroll)="onScroll()">
    <ng-container *ngFor="let file of files; let i = index">
      <ng-container
        *ngTemplateOutlet="fileDisplayTemplate; context: getListItemContext(file, i)"
      ></ng-container>
    </ng-container>
  </div>
</div>

<tsq-gallery-preview
  [files]="files"
  [topPreviewTemplate]="topPreviewTemplate"
  [bottomPreviewTemplate]="bottomPreviewTemplate"
  #preview
></tsq-gallery-preview>
