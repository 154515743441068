import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { uniqBy } from 'lodash';

import { TagInput } from '../../models/tag-input.model';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'neighbor-mass-invite-chips-input',
  templateUrl: './neighbor-mass-invite-chips-input.component.html',
  styleUrls: ['./neighbor-mass-invite-chips-input.component.scss'],
})
export class NeighborMassInviteChipsInputComponent {
  @ViewChild('input', { static: true }) input: ElementRef;

  @Input() tags: TagInput[];
  @Input() validator: (input: string) => boolean;
  @Input() keySeparators: string[] = [',', ' ', ';', '\n'];
  @Input() emptyPlaceholder: string;
  @Input() inputPlaceholder: string;
  @Input() disabled = false;
  @Input() onlyAllowUnique = true;
  @Input() hasInfoLabel = false;
  @Input() displayAsCard = false;

  @Output() tagsChange = new EventEmitter<TagInput[]>();

  newTagInput: UntypedFormControl = new UntypedFormControl('');

  get tagAsOptions(): string[] {
    return this.tags.map(tag => tag.value);
  }

  get invalidTagsIndexes(): number[] {
    return this.tags
      .map((tag, index) => ({ isValid: tag.isValid, index }))
      .filter(opt => !opt.isValid)
      .map(opt => opt.index);
  }

  focusInput(): void {
    this.input.nativeElement.focus();
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Backspace' && this.newTagInput.value.length < 1 && this.tags.length > 0) {
      this.removeTag(this.tags.length - 1);
    }
  }

  onKeyUp(event: KeyboardEvent): void {
    if ((event.key === 'Enter' || event.key === 'Tab') && this.newTagInput.value.length > 0) {
      this.addTag(this.newTagInput.value);
      this.newTagInput.setValue('');
    } else if (
      this.keySeparators.some(char => char === event.key) &&
      this.newTagInput.value.length > 1
    ) {
      this.addTag(this.newTagInput.value.substring(0, this.newTagInput.value.length - 1));
      this.newTagInput.setValue('');
    }
  }

  onPaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData || event.clipboardData;
    const pastedText = clipboardData.getData('text');

    event.stopPropagation();
    event.preventDefault();

    if (!!pastedText) {
      const newTags: TagInput[] = pastedText
        .split(new RegExp(this.keySeparators.join('|').toString()))
        .map((tag: string) => this.newTag(tag));

      this.newTagInput.setValue('');

      if (this.onlyAllowUnique) {
        const uniqueTags: TagInput[] = uniqBy(newTags, 'value').filter(
          newTag => !this.tags.some(tag => tag.value === newTag.value),
        );

        this.tagsChange.emit([...this.tags, ...uniqueTags]);
      } else {
        this.tagsChange.emit([...this.tags, ...newTags]);
      }
    }
  }

  onBlur(): void {
    if (!!this.newTagInput.value) {
      this.addTag(this.newTagInput.value);
      this.newTagInput.setValue('');
    }
  }

  removeTag(tagIndex: number): void {
    this.tagsChange.emit(this.tags.filter((tag: TagInput, index: number) => index !== tagIndex));
  }

  private newTag(value: string): TagInput {
    return {
      value,
      isValid: !!this.validator ? this.validator(value) : true,
    };
  }

  private addTag(value: string): void {
    const newTag = this.newTag(value);

    if (!this.onlyAllowUnique || !this.tags.some(tag => tag.value === value)) {
      this.tagsChange.emit([...this.tags, newTag]);
    }
  }
}
