import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment-timezone';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { icons } from '@tsq-web/assets';
import { AppState } from '@tsq-web/state';
import { fromUserContextSelectors } from '@tsq-web/user-context';

@UntilDestroy()
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sc-date-navigator',
  templateUrl: './sc-date-navigator.component.html',
  styleUrls: ['./sc-date-navigator.component.scss'],
})
export class SCDateNavigatorComponent implements OnInit {
  @Input() buttonLabel = '';
  @Input() noDivider = false;
  @Input() newDesign = false;
  @Output() initialDateChange = new EventEmitter();
  @Output() dateChangedImmediate = new EventEmitter();

  readonly icons = icons;

  moment: moment.Moment;
  relativeDate = '';
  private timeZone = '';
  private date$ = new Subject<moment.Moment>();

  private readonly initialDate$ = new BehaviorSubject('');

  constructor(private translateService: TranslateService, private store: Store<AppState>) {}

  ngOnInit(): void {
    combineLatest([
      this.initialDate$,
      this.store.select(fromUserContextSelectors.selectCondoTimeZone),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([initialDate, timezone]) => {
        this.setTimeZone(timezone, initialDate);
      });

    this.date$
      .pipe(debounceTime(400), untilDestroyed(this))
      .subscribe((date: moment.Moment) => this.initialDateChange.emit(date?.format('YYYY-MM-DD')));
  }

  @Input() set initialDate(value: string) {
    this.initialDate$.next(value);
  }

  nextDate(): void {
    this.moment.date(this.moment.date() + 1);
    this.relativeDate = this.relativeDateUpdated(this.moment);
    this.dateChangedImmediate.emit();
    this.date$.next(this.moment);
  }

  previousDate(): void {
    this.moment.date(this.moment.date() - 1);
    this.relativeDate = this.relativeDateUpdated(this.moment);
    this.dateChangedImmediate.emit();
    this.date$.next(this.moment);
  }

  relativeDateUpdated(currentMoment: moment.Moment): string {
    if (currentMoment.isSame(moment(), 'day')) {
      return this.translateService.instant('TODAY');
    } else {
      return !!this.timeZone
        ? currentMoment.clone().endOf('day').tz(this.timeZone, true).fromNow()
        : currentMoment.clone().endOf('day').fromNow();
    }
  }

  getDateDay(): string {
    return this.moment?.format('D');
  }

  getDateWeekDay(): string {
    return this.moment?.format('dddd').toUpperCase();
  }

  getDateMonthYear(): string {
    return ' | ' + this.moment?.format(`MMMM${this.newDesign ? ' D' : ''}, YYYY`);
  }

  private setTimeZone(timeZone: string, initialDate?: string): void {
    this.timeZone = timeZone;
    this.moment = !!initialDate ? moment.utc(initialDate, 'YYYY-MM-DD') : moment.utc();
    this.relativeDate = this.relativeDateUpdated(this.moment);
  }
}
