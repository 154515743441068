import { Routes } from '@angular/router';

import { UserRegisterComponent } from './user-register.component';

export const userRegisterRoutes: Routes = [
  {
    path: 'user-register/:userId/:token',
    component: UserRegisterComponent,
  },
];
