/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/naming-convention */
const persistableItemsMap = new Map<string, Map<string | symbol, unknown>>();

function initializePersistableProperties(key: string, targetObject: object): void {
  if (persistableItemsMap.has(key)) {
    const itemsMap = persistableItemsMap.get(key);
    for (const [name, value] of itemsMap.entries()) {
      targetObject[name] = value;
      itemsMap.set(name, undefined);
    }
  }
}

function storePersistableProperties(key: string, targetObject: object): void {
  if (persistableItemsMap.has(key)) {
    const itemsMap = persistableItemsMap.get(key);
    for (const [name] of itemsMap.entries()) {
      itemsMap.set(name, targetObject[name]);
    }
  }
}

export function PersistableClass(): ClassDecorator {
  return target => {
    const onInitBackup: Function = target.prototype.ngOnInit;
    target.prototype.ngOnInit = function (): void {
      initializePersistableProperties(target.name, this);
      if (!!onInitBackup) {
        onInitBackup.call(this);
      }
    };

    const onDestroyBackup: Function = target.prototype.ngOnDestroy;
    target.prototype.ngOnDestroy = function (): void {
      if (!!onDestroyBackup) {
        onDestroyBackup.call(this);
      }
      storePersistableProperties(target.name, this);
    };
  };
}

export function Persistable(): PropertyDecorator {
  return (target, propertyName) => {
    let propertyMap: Map<string | symbol, unknown>;
    const constructorName = target.constructor.name;
    if (!persistableItemsMap.has(constructorName)) {
      persistableItemsMap.set(constructorName, new Map());
    }

    // eslint-disable-next-line prefer-const
    propertyMap = persistableItemsMap.get(constructorName);
    propertyMap.set(propertyName, undefined);
  };
}
