import { Params } from '@angular/router';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouterReducerState } from '@ngrx/router-store';

import { RouterState } from './router.reducer';

const selectRouterReducerState = createFeatureSelector<RouterReducerState<RouterState>>('router');

export const selectRouterState = createSelector(
  selectRouterReducerState,
  (routerReducerState: RouterReducerState<RouterState>) => (routerReducerState && routerReducerState.state) || {},
);

export const selectRouterUrl = createSelector(
  selectRouterState,
  (routerState: RouterState) => routerState.url,
);

export const selectRouterParams = createSelector(
  selectRouterState,
  (routerState: RouterState) => (routerState && routerState.params) || {},
);

export const selectRouterQueryParams = createSelector(
  selectRouterState,
  (routerState: RouterState) => (routerState && routerState.queryParams) || {},
);

export const selectRouterFragment = createSelector(
  selectRouterState,
  (routerState: RouterState) => routerState.fragment,
);

export const selectRouterCondoIdParam = createSelector(
  selectRouterParams,
  (params: Params) => params['condoId'],
);

export const selectRouterQueryParamSearch = createSelector(
  selectRouterQueryParams,
  (queryParams: Params) => queryParams['search'],
);
