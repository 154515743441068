import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subject, map, mergeMap, tap } from 'rxjs';

import { icons } from '@tsq-web/assets';
import { updateQueryParamsV2 } from '@tsq-web/router';

import { TableConfig } from '../../models/table.model';

@UntilDestroy()
@Injectable()
export class TableSortService {
  private readonly defaultSortParam = 's';
  private readonly defaultDirectionParam = 'd';

  private sortParam = this.defaultSortParam;
  private directionParam = this.defaultDirectionParam;

  private readonly sortSubject = new Subject<string | undefined>();
  private readonly updateQueryParams = updateQueryParamsV2();

  private readonly queryParams$ = inject(ActivatedRoute).queryParams;

  constructor() {
    this.queryParams$
      .pipe(
        mergeMap(queryParams => {
          return this.sortSubject.asObservable().pipe(
            tap(id => {
              const { sort: curSort, direction: curDirection } = this.getParams(queryParams);

              if (!id) {
                this.updateQueryParams({
                  [this.sortParam]: undefined,
                  [this.directionParam]: undefined,
                });
              } else {
                this.updateQueryParams({
                  [this.sortParam]: id,
                  [this.directionParam]: curSort !== id || curDirection !== 'asc' ? 'asc' : 'desc',
                });
              }
            }),
          );
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  listen(config: TableConfig<unknown>['sort'] | undefined): void {
    this.sortParam = config?.overrideSortParam || this.defaultSortParam;
    this.directionParam = config?.overrideDirectionParam || this.defaultDirectionParam;
  }

  icon$(id: string): Observable<string | undefined> {
    return this.queryParams$.pipe(
      map(params => {
        const { sort, direction } = this.getParams(params);

        if (sort === id) {
          switch (direction) {
            case 'asc':
              return icons.arrowUpV2;
            case 'desc':
              return icons.arrowDownV2;
          }
        }

        return icons.doubleChevron;
      }),
    );
  }

  isSelected$(id: string): Observable<boolean> {
    return this.queryParams$.pipe(
      map(params => {
        const { sort } = this.getParams(params);

        return sort === id;
      }),
    );
  }

  sort(id: string): void {
    this.sortSubject.next(id);
  }

  private getParams(params: Params): { sort: string; direction: string } {
    return {
      sort: params?.[this.sortParam],
      direction: params?.[this.directionParam],
    };
  }
}
