import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { PageDirection } from '../../models/page-direction.enum';

@Component({
  selector: 'tsq-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginatorComponent implements OnInit {
  @Input() inPage = 0;
  @Input() perPage = 0;
  @Input() pageNeighbours = 1;
  @Input() entity: string;
  @Input() theme: 'avocado' | 'blue';
  @Input() hasItemsCounter = true;
  @Output() pageChange = new EventEmitter<number>();

  pages: (number | string)[] = [];
  currentPage = 1;

  pageDirections = PageDirection;

  private _total = 0;
  private _page = 0;

  ngOnInit(): void {
    this.pages = this.fetchPageNumbers();
  }

  @Input() set page(page: number) {
    this._page = page;
    this.goToPage(page + 1);
  }

  @Input() set total(value: number) {
    this._total = value;
    this.pages = this.fetchPageNumbers();
    this.goToPage(this._page + 1);
  }

  get total(): number {
    return this._total;
  }

  get totalPages(): number {
    return Math.ceil(this.total / this.perPage);
  }

  onPageClick(page: number): void {
    if (page <= 0 || page > this.totalPages) {
      return;
    }

    this.pageChange.emit(page - 1);
  }

  handlePageDirection(direction: PageDirection): void {
    switch (direction) {
      case PageDirection.Left:
        this.pageChange.emit(this.currentPage - this.pageNeighbours * 2 - 1);
        break;
      case PageDirection.Right:
        this.pageChange.emit(this.currentPage + this.pageNeighbours * 2 + 1);
        break;
    }
  }

  goToPage(page: number): void {
    this.currentPage = Math.max(1, Math.min(page, this.totalPages));
    this.pages = this.fetchPageNumbers();
  }

  private fetchPageNumbers(): (number | string)[] {
    const totalNumbers = this.pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (this.totalPages > totalBlocks) {
      const startPage = Math.max(2, this.currentPage - this.pageNeighbours);
      const endPage = Math.min(this.totalPages - 1, this.currentPage + this.pageNeighbours);
      let pages: (number | string)[] = this.range(startPage, endPage);

      const hasLeftSpill = startPage > 2;
      const hasRightSpill = this.totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = this.range(startPage - spillOffset, startPage - 1);
          pages = [PageDirection.Left, ...extraPages, ...pages];
          break;
        }
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = this.range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, PageDirection.Right];
          break;
        }
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [PageDirection.Left, ...pages, PageDirection.Right];
          break;
        }
      }

      return [1, ...pages, this.totalPages];
    }

    return this.range(1, this.totalPages);
  }

  private range(from: number, to: number, step = 1): number[] {
    const range = [];
    for (let i = from; i <= to; i += step) {
      range.push(i);
    }

    return range;
  }
}
