import { inject } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';

export function setLanguageBasedOnCookie(cookieName: string, validLangs: string[]): void {
  const cookieService = inject(CookieService);
  const translateService = inject(TranslateService);

  let userLang = cookieService.get(cookieName);

  if (!isValidLang(userLang, validLangs)) {
    const navLang = navigator.language.split('-')[0];
    userLang = new RegExp(validLangs.join('|')).test(navLang) ? navLang : 'en';

    cookieService.set(cookieName, userLang, undefined, '/');
  }

  translateService.setDefaultLang('en');
  translateService.use(userLang);
}

function isValidLang(lang: string, validLangs: string[]): boolean {
  return !!lang && validLangs.indexOf(lang) !== -1;
}
