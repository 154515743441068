/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';

import { User } from '@tsq-web/users';
import { Condo } from '@tsq-web/condo';
import { dropdownFadeOut, dumbParent } from './tsq-profile-dropdown.animations';

@Component({
  selector: 'tsq-profile-dropdown',
  templateUrl: 'tsq-profile-dropdown.component.html',
  animations: [dumbParent(), dropdownFadeOut()],
})
export class TSqProfileDropdownComponent {
  @ViewChild('dialogContainer') dialogContainer: ElementRef;
  @ViewChild('dialog') dialog: ElementRef;

  @Input() user: User;
  @Input() condo: Condo;
  @Input() subtitle = '';

  private _open: boolean;

  get open(): boolean {
    return this._open;
  }

  openProfileDropdown() {
    this._open = true;
  }

  closeProfileDropdown() {
    this._open = false;
  }

  @HostListener('click', ['$event'])
  onClick(event: any) {
    if (
      !!this.dialog &&
      !!this.dialogContainer &&
      (this.dialogContainer.nativeElement as Element).contains(event.target) &&
      !(this.dialog.nativeElement as Element).contains(event.target)
    ) {
      this._open = false;
    }
  }
}
