import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'comm-taxes-selection',
  templateUrl: './taxes-selection.component.html',
  styleUrls: ['./taxes-selection.component.scss'],
})
export class TaxesSelectionComponent {

  @Input() form: UntypedFormGroup;

}
