import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, combineLatest, take } from 'rxjs';

import { injectFeatureFlagService } from '@tsq-web/feature-flag';
import { UserRecoveryService } from '@tsq-web/users';

import { initializePendoForAnonymousVisitor } from '../../shared/pendo/pendo.utils';
import { ResetPasswordStore } from '../state/reset-password.store';

@UntilDestroy()
@Component({
  selector: 'comm-user-recovery-form',
  templateUrl: './user-recovery-form.component.html',
  styleUrls: ['./user-recovery-form.component.scss'],
  providers: [UserRecoveryService],
})
export class UserRecoveryFormComponent implements OnInit {
  email1 = '';
  email2 = '';
  isSent = false;
  ssoPasswordReset = false;
  isSsoEmail = false;
  typedEmail: string;

  resetPasswordLoading$: Observable<boolean>;
  showSentEmailMessage$: Observable<boolean>;

  emailRegex =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

  private readonly recoveryService = inject(UserRecoveryService);
  private readonly translateService = inject(TranslateService);
  private readonly toastr = inject(ToastrService);
  private readonly featureFlagService = injectFeatureFlagService();
  private readonly resetPasswordStore = inject(ResetPasswordStore);
  private readonly router = inject(Router);

  ngOnInit(): void {
    initializePendoForAnonymousVisitor();

    this.featureFlagService
      .getValueAsync('blockSsoPasswordReset', false)
      .pipe(take(1))
      .subscribe(value => {
        this.ssoPasswordReset = value;
      });

    this.listenToStore();
  }

  get greetingBackground(): string {
    return this.isPortuguese ? 'green' : 'green-alternate';
  }

  get isPortuguese(): boolean {
    return this.translateService.currentLang === 'pt';
  }

  get enableButtonAgain(): boolean {
    return this.isSent;
  }

  get isDisabled(): boolean {
    const shouldDisableButton =
      this.isSent ||
      this.email1.length === 0 ||
      this.email1 !== this.email2 ||
      !this.emailRegex.test(this.email1);

    return (
      shouldDisableButton ||
      (this.isSsoEmail && this.typedEmail === this.email1 && this.typedEmail === this.email2)
    );
  }

  get showResetYourPasswordCard(): boolean {
    return this.isSsoEmail && this.typedEmail === this.email1 && this.typedEmail === this.email2;
  }

  onSubmit(): void {
    if (this.ssoPasswordReset) {
      this.resetPasswordStore.resetPassword({ email: this.email1 });
    } else {
      this.recoveryService.requestRecovery(this.email1).subscribe(
        () => (this.isSent = true),
        error => this.toastr.error(error.error.message),
      );
    }
  }

  backToLogin(): void {
    this.router.navigate(['login']);

    if (this.ssoPasswordReset) {
      this.resetPasswordStore.resetShowSentEmailMessage();
    }
  }

  private listenToStore(): void {
    this.resetPasswordLoading$ = this.resetPasswordStore.resetPasswordLoading$;
    this.showSentEmailMessage$ = this.resetPasswordStore.showSentEmailMessage$;

    combineLatest([this.resetPasswordStore.emailIsSso$, this.resetPasswordStore.typedEmail$])
      .pipe(untilDestroyed(this))
      .subscribe(([emailIsSso, typedEmail]) => {
        this.isSsoEmail = emailIsSso;
        this.typedEmail = typedEmail;
      });
  }
}
