/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

import { Property } from '@tsq-web/property';
import { BaseService } from '@tsq-web/core';
import { PropertyWithUsers } from './property.users.json';
import { XLSOptions } from './xls-options.model';
import { VoteFraction } from '../../+sc-workspace/root-properties/models/vote-fraction.model';

@Injectable()
export class PropertyService extends BaseService {
  private _cachedProperties: Property[];

  constructor(
    protected _cookieService: CookieService,
    protected _http: HttpClient,
    protected _router: Router,
  ) {
    super(_cookieService, _http, _router);
  }

  toggleInDebit(property: Property): Observable<Property> {
    return this.doPatch(`property/${property.id}`, this.prepareBody(property));
  }

  getProperties(condoId: string): Observable<Property[]> {
    return this.doGet(`property/condo/${condoId}`);
  }

  getUserPropertiesByCondo(condoId: string): Observable<Property[]> {
    return this.doGet(`property/user/properties/${condoId}`);
  }

  getTypeaheadPublic(condoId: string, code: string): Observable<Property[]> {
    return this.doGet(`property/open/typeahead/${condoId}/${code}`);
  }

  getPropertiesWithUsers(
    condoId: string,
    search: string,
    page: number,
    size: number,
  ): Observable<PropertyWithUsers[]> {
    const params = new HttpParams()
      .append('search', `${search}`)
      .append('page', `${page > 0 ? page - 1 : 0}`)
      .append('size', `${size}`);

    return this.doGet(`property/condo/${condoId}/users`, params);
  }

  saveProperty(property: Property): Observable<Property> {
    return this.doPost(`property`, this.prepareBody(property));
  }

  updateProperty(property: Property): Observable<Property> {
    return this.doPut(`property/${property.id}`, this.prepareBody(property));
  }

  deleteProperty(property: Property): Observable<Property> {
    return this.doDelete(`property/${property.id}`);
  }

  getPropertiesPublic(condoId: string): Observable<Property[]> {
    return this.doGet(`public/property/no-user/${condoId}`);
  }

  savePropertyAsForSale(condoId: string, propertiesIds: string[]) {
    let params: HttpParams = new HttpParams();
    params = params.append('condoId', condoId);
    params = params.append('propertiesIds', propertiesIds.toString());
    return this.doPost(`properties/for-sale`, {}, params, 'v1');
  }

  savePropertyAsForRent(condoId: string, propertiesIds: string[]) {
    let params: HttpParams = new HttpParams();
    params = params.append('condoId', condoId);
    params = params.append('propertiesIds', propertiesIds.toString());
    return this.doPost(`properties/for-rent`, {}, params, 'v1');
  }

  get cachedProperties(): Property[] {
    return this._cachedProperties;
  }

  set cachedProperties(value: Property[]) {
    this._cachedProperties = value;
  }

  getMassImportXls({ includeVoteFraction, includeParkingSpots }: XLSOptions): Observable<Blob> {
    let params = new HttpParams();
    params = params.append('includeVoteFraction', includeVoteFraction.toString());
    params = params.append('includeParkingSpots', includeParkingSpots.toString());

    const blobOptions = this.blobOptions(
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    );
    const options = { ...blobOptions, params };

    return this._http.get(BaseService.getFullUrl('properties/files/import', 'v1'), options);
  }

  getMassFractionsImportXls(): Observable<Blob> {
    const blobOptions = this.blobOptions(
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    );

    return this._http.get(
      BaseService.getFullUrl('properties/files/vote-fraction', 'v1'),
      blobOptions,
    );
  }

  patchPropertyQuotes(properties: VoteFraction[]): Observable<VoteFraction[]> {
    return this.doPatch(`properties/vote-fraction`, { voteFractions: properties }, 'v1');
  }
}
