import { Injectable, inject } from '@angular/core';
import { Params } from '@angular/router';

import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { fromRouterSelectors } from '@tsq-web/router';

import { FeedbackContexts } from '../../shared/in-app-feedback/models/feedback-contexts.enum';
import { FeedbackTriggerService } from '../../shared/in-app-feedback/services/feedback-trigger.service';
import { NeighborMassInviteRequestsService } from '../services/neighbor-mass-invite-requests.service';
import * as fromNeighborMassInviteActions from './neighbor-mass-invite.actions';
import { NeighborMassInviteState } from './neighbor-mass-invite.reducer';

@Injectable()
export class NeighborMassInviteEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store<NeighborMassInviteState>);
  private readonly translateService = inject(TranslateService);
  private readonly toastr = inject(ToastrService);
  private readonly neighborMassInviteRequestsService = inject(NeighborMassInviteRequestsService);
  private readonly feedbackTriggerService = inject(FeedbackTriggerService);

  allResidentsRequested = createEffect(() =>
    this.actions$.pipe(
      ofType<fromNeighborMassInviteActions.SendInvitesRequest>(
        fromNeighborMassInviteActions.NeighborMassInviteActionTypes.SendInvitesRequest,
      ),
      concatLatestFrom(() => this.store.select(fromRouterSelectors.selectRouterQueryParams)),
      mergeMap(([action, params]: [fromNeighborMassInviteActions.SendInvitesRequest, Params]) =>
        this.neighborMassInviteRequestsService
          .sendInvites(action.payload.emails, params.condoId)
          .pipe(
            map(() => {
              this.feedbackTriggerService.triggerFeedback(FeedbackContexts.NeighborInvitation);

              return new fromNeighborMassInviteActions.SendInvitesSuccess();
            }),
            catchError(() => {
              this.toastr.error(this.translateService.instant('MASS_INVITE_NEIGHBORS.ERROR'));

              return of(new fromNeighborMassInviteActions.SendInvitesError());
            }),
          ),
      ),
    ),
  );
}
