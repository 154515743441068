import { AfterViewInit, Directive, EventEmitter, Input, Output } from '@angular/core';

@Directive()
export abstract class AbstractButtonDirective implements AfterViewInit {
  @Input() tab = 0;
  @Input() icon: string;
  @Input() text: string;
  @Input() disabled: boolean;
  @Input() focusOnInit = false;

  @Output() clicked = new EventEmitter();

  protected constructor(protected el: Element) {}

  ngAfterViewInit(): void {
    if (this.focusOnInit) {
      this.focus();
    }
  }

  focus(): void {
    (this.el?.firstElementChild as HTMLButtonElement | HTMLAnchorElement)?.focus();
  }
}
