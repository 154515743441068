import { FileUploader } from 'ng2-file-upload';
import { CookieService } from 'ngx-cookie-service';

import { getEnvironment } from '../shared/environment';

export function provideRegisterPicUploader(cookieService: CookieService): FileUploader {
  const headers = [{ name: 'Authorization', value: 'Bearer ' + cookieService.get('jwt') }];

  const logonToken = cookieService.get('logonToken');
  if (!!logonToken) {
    headers.push({
      name: 'xx-sc-r',
      value: JSON.parse(logonToken).logonToken,
    });
  }

  return new FileUploader({
    url: `${getEnvironment().baseApi}/api/v1/registration/uploadPicture`,
    queueLimit: 1,
    autoUpload: true,
    headers: headers,
  });
}
