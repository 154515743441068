import { CdkPortalOutlet, PortalOutlet } from '@angular/cdk/portal';
import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { ModalContext } from '../../models/modal-context';
import { ModalPositions } from '../../models/modal-positions.enum';
import { ModalSize } from '../../models/modal-size.enum';
import { ModalService } from '../../services/modal.service';

@UntilDestroy()
@Component({
  selector: 'tsq-generic-modal',
  templateUrl: './tsq-generic-modal.component.html',
})
export class TSqGenericModalComponent implements OnInit, AfterViewInit {
  @ViewChild('modal', { static: true }) modal: ModalDirective;
  @ViewChild(CdkPortalOutlet, { static: true }) portalOutlet: PortalOutlet;

  position: ModalPositions;
  disableClose: boolean;
  size: ModalSize;
  hideCloseImg: boolean;

  modalPositionEnum = ModalPositions;

  private _context: ModalContext;

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {
    this.modalService.context$.pipe(untilDestroyed(this)).subscribe(context => {
      this._context = context;
      this.position = context?.position || ModalPositions.RIGHT;
      this.disableClose = context?.disableClose;
      this.hideCloseImg = context?.hideCloseImg;
      this.size = context?.size || ModalSize.Responsive;
    });
  }

  ngAfterViewInit(): void {
    this.modalService.open$.pipe(untilDestroyed(this)).subscribe(value => {
      if (value) {
        this.modal.show();
      } else {
        this.modal.hide();
      }
    });

    this.modalService.portal$.pipe(untilDestroyed(this)).subscribe(portal => {
      if (!!portal && !!portal.templateRef) {
        this.portalOutlet.detach();
        this.portalOutlet.attach(portal);
      }
    });
  }

  close(): void {
    if (!this.disableClose) {
      this.modalService.close();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @HostListener('mousedown', ['$event']) onClick(event: any): void {
    if (event.target.classList.contains('modal') && !this.disableClose) {
      this.modalService.close();
    }
  }
}
