import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginService } from '@tsq-web/login';
import { AuthGuard } from './services/auth.guard';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    AuthGuard,
    LoginService,
  ],
})
export class TSqAuthModule {}
