/* eslint-disable @typescript-eslint/naming-convention */
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';

import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[tsq-toast-component]',
  template: `
    <div class="notyf-wrapper">
      <div class="notyf-icon">
        <i class="notyf-confirm-icon"></i>
      </div>
      <div *ngIf="message && !options.enableHtml" class="notyf-message">{{ message }}</div>
      <div *ngIf="message && options.enableHtml" [innerHTML]="message" class="notyf-message"></div>
    </div>
  `,
  animations: [
    trigger('flyInOut', [
      state('inactive', style({ opacity: 0 })),
      transition(
        'inactive => active',
        animate(
          '500ms cubic-bezier(0.23, 1, 0.32, 1)',
          keyframes([
            style({ opacity: 0, transform: 'translateX(200px)', offset: 0 }),
            style({ opacity: 1, transform: 'translateX(15px)', offset: 0.8 }),
            style({ opacity: 1, transform: 'translateX(0)', offset: 1.0 }),
          ]),
        ),
      ),
      state(
        'active',
        style({
          'max-height': '500px',
          'max-width': '400px',
        }),
      ),
      transition(
        'active => removed',
        animate(
          '300ms ease-out',
          keyframes([
            style({
              opacity: 1,
            }),
            style({
              opacity: 0,
            }),
          ]),
        ),
      ),
    ]),
  ],
})
export class TSqToastComponent extends Toast {
  constructor(protected toastrService: ToastrService, public toastPackage: ToastPackage) {
    super(toastrService, toastPackage);
  }
}
