/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { AfterViewInit, Directive, Input } from '@angular/core';
import { UntypedFormControl, NgControl } from '@angular/forms';

import { Endereco } from '@brunoc/ngx-viacep';

import { ViacepService } from './viacep.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[cepValidator]',
})
export class CEPValidatorDirective implements AfterViewInit {
  @Input() streetControl: UntypedFormControl;
  @Input() neighborhoodControl: UntypedFormControl;
  @Input() cityControl: UntypedFormControl;
  @Input() stateControl: UntypedFormControl;

  constructor(private ngControl: NgControl, private viacep: ViacepService) {}

  ngAfterViewInit() {
    const control = this.ngControl.control;
    control.valueChanges.subscribe((value: string) => {
      if (!value.includes('_')) {
        control.setErrors({ invalidCEP: true });
        this.viacep.searchAddress(control.value).subscribe(
          (endereco: Endereco) => {
            control.setErrors(null);
            this.streetControl.setValue(endereco.logradouro);
            this.neighborhoodControl.setValue(endereco.bairro);
            this.cityControl.setValue(endereco.localidade);
            this.stateControl.setValue(endereco.uf);
          },
          error => {
            control.setErrors({ invalidCEP: true });
            this.streetControl.setValue('');
            this.neighborhoodControl.setValue('');
            this.cityControl.setValue('');
            this.stateControl.setValue('');
          },
        );
      } else {
        control.setErrors({ invalidCEP: true });
      }
    });
  }
}
