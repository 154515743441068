import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LinkyModule } from 'ngx-linky';

import { AssetsModule } from '@tsq-web/assets';
import { TSqFilesModule } from '@tsq-web/files';
import { TSqFormsModule } from '@tsq-web/forms';
import { TSqLoadersModule } from '@tsq-web/loaders';
import { TSqModalsModule } from '@tsq-web/modals';
import { TSqPipesModule } from '@tsq-web/pipes';

import { AttachmentGalleryComponent } from './components/attachment-gallery/attachment-gallery.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { ButtonComponent } from './components/button/button.component';
import { CreateCommentPlaceholderComponent } from './components/create-comment-placeholder/create-comment-placeholder.component';
import { CreateCommentComponent } from './components/create-comment/create-comment.component';
import { StepsStatusComponent } from './components/creation-status/steps-status.component';
import { DeleteCommentModalComponent } from './components/delete-comment-modal/delete-comment-modal.component';
import { DeleteModalComponent } from './components/delete-modal/delete-modal.component';
import { ManageCommentDropdownComponent } from './components/edit-comment-dropdown/manage-comment-dropdown.component';
import { EditCommentFormComponent } from './components/edit-comment-form/edit-comment-form.component';
import { EditCommentModalComponent } from './components/edit-comment-modal/edit-comment-modal.component';
import { FeatureHeaderComponent } from './components/feature-header/feature-header.component';
import { FileListComponent } from './components/file-list/file-list.component';
import { FilterButtonComponent } from './components/filter-button/filter-button.component';
import { FilterGroupComponent } from './components/filter-group/components/filter-group/filter-group.component';
import { FilterGroupItemDirective } from './components/filter-group/directives/filter-group-item/filter-group-item.directive';
import { InputChipComponent } from './components/input-chip/input-chip.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { SubHeaderTabsComponent } from './components/sub-header-tabs/sub-header-tabs.component';
import { TableComponent } from './components/table/components/table/table.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { TagComponent } from './components/tag/tag.component';
import { TSqAccordionComponent } from './components/tsq-accordion/tsq-accordion.component';
import { TSqAttachmentCardComponent } from './components/tsq-attachment-card/tsq-attachment-card.component';
import { TSqCommentListComponent } from './components/tsq-comment-list/tsq-comment-list.component';
import { TSqCommentComponent } from './components/tsq-comment/tsq-comment.component';
import { TSqCreateCommentComponent } from './components/tsq-create-comment/tsq-create-comment.component';
import { TSqErrorComponent } from './components/tsq-error/tsq-error.component';
import { TSqFeatureDetailsComponent } from './components/tsq-feature-details/tsq-feature-details.component';
import { TSqFeatureWrapperComponent } from './components/tsq-feature-wrapper/tsq-feature-wrapper.component';
import { TSqFileCardComponent } from './components/tsq-file-card/tsq-file-card.component';
import { TSqListComponent } from './components/tsq-list/tsq-list.component';
import { UserInfoComponent } from './components/user-info/user-info.component';
import { ZeroDataComponent } from './components/zero-data/zero-data.component';
import { HighlightDirective } from './directives/highlight/highlight.directive';
import { ListInsufficientItemsDirective } from './directives/list-insufficient-items.directive';
import { CommentsService } from './services/comments.service';
import { ScrollBottomService } from './services/scroll-bottom.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    InfiniteScrollModule,
    ModalModule,
    TSqLoadersModule,
    TSqFormsModule,
    TSqFilesModule,
    TSqModalsModule,
    AssetsModule,
    TSqPipesModule,
    LinkyModule,
    OverlayModule,
    HighlightDirective,
    TableComponent,
  ],
  declarations: [
    SubHeaderTabsComponent,
    TSqErrorComponent,
    FeatureHeaderComponent,
    ZeroDataComponent,
    TSqListComponent,
    TSqAccordionComponent,
    TSqFeatureDetailsComponent,
    TSqFeatureWrapperComponent,
    TSqAttachmentCardComponent,
    TSqCommentComponent,
    TSqCreateCommentComponent,
    TSqCommentListComponent,
    TSqFileCardComponent,
    AttachmentGalleryComponent,
    ListInsufficientItemsDirective,
    UserInfoComponent,
    BreadcrumbsComponent,
    NavbarComponent,
    TabsComponent,
    PaginatorComponent,
    ButtonComponent,
    FilterButtonComponent,
    TagComponent,
    ManageCommentDropdownComponent,
    EditCommentModalComponent,
    EditCommentFormComponent,
    DeleteModalComponent,
    CreateCommentComponent,
    FileListComponent,
    CreateCommentPlaceholderComponent,
    StepsStatusComponent,
    InputChipComponent,
    DeleteCommentModalComponent,
    FilterGroupComponent,
    FilterGroupItemDirective,
  ],
  exports: [
    SubHeaderTabsComponent,
    TSqErrorComponent,
    FeatureHeaderComponent,
    ZeroDataComponent,
    TSqListComponent,
    TSqAccordionComponent,
    TSqFeatureDetailsComponent,
    TSqFeatureWrapperComponent,
    TSqAttachmentCardComponent,
    TSqCommentComponent,
    TSqCreateCommentComponent,
    CreateCommentComponent,
    TSqCommentListComponent,
    TSqFileCardComponent,
    AttachmentGalleryComponent,
    ListInsufficientItemsDirective,
    UserInfoComponent,
    BreadcrumbsComponent,
    NavbarComponent,
    TabsComponent,
    PaginatorComponent,
    ButtonComponent,
    FilterButtonComponent,
    TagComponent,
    DeleteModalComponent,
    FileListComponent,
    StepsStatusComponent,
    InputChipComponent,
    ManageCommentDropdownComponent,
    CreateCommentPlaceholderComponent,
    DeleteCommentModalComponent,
    TableComponent,
    FilterGroupComponent,
    FilterGroupItemDirective,
  ],
  providers: [ScrollBottomService, CommentsService],
})
export class TSqGenericComponentsModule {}
