/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

@Injectable()
export class ScrollBottomService {
  private scrollBottomSubject$ = new Subject<void>();

  get scrollBottom$(): Observable<any> {
    return this.scrollBottomSubject$.asObservable();
  }

  scrollBottom(): void {
    this.scrollBottomSubject$.next();
  }
}
