import { Injectable } from '@angular/core';

import { tapResponse } from '@ngrx/component-store';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { AppType } from '@tsq-web/core';
import { getTSqEnvironment } from '@tsq-web/environment';
import { Feature } from '@tsq-web/feature';
import { ModalService } from '@tsq-web/modals';
import { ToastErrorComponent, ToastSuccessComponent } from '@tsq-web/toast';

import { EditAndDeleteComments } from '../models/edit-and-delete-comments';
import { CommentsService } from '../services/comments.service';

export interface CommentsState {
  edit: {
    sending: boolean;
    success: boolean;
  };
  delete: {
    sending: boolean;
    success: boolean;
  };
  adminDelete: {
    sending: boolean;
    success: boolean;
  };
}

export const initialState: CommentsState = {
  edit: {
    sending: false,
    success: false,
  },
  delete: {
    sending: false,
    success: false,
  },
  adminDelete: {
    sending: false,
    success: false,
  },
};

@Injectable()
export class CommentsStore extends ComponentStore<CommentsState> {
  readonly editCommentIsSending$ = this.select(({ edit }) => edit.sending);
  readonly editCommentSuccess$ = this.select(({ edit }) => edit.success);

  readonly deleteCommentIsSending$ = this.select(({ delete: { sending } }) => sending);
  readonly deleteCommentSuccess$ = this.select(({ delete: { success } }) => success);

  readonly adminDeleteCommentIsSending$ = this.select(({ adminDelete: { sending } }) => sending);
  readonly adminDeleteCommentSuccess$ = this.select(({ adminDelete: { success } }) => success);

  readonly editComment = this.effect((data$: Observable<EditAndDeleteComments>) =>
    data$.pipe(
      tap(() => this.setSendingEdit()),
      switchMap(({ comment, feature }) =>
        this.commentsService.editComment(comment, feature).pipe(
          tapResponse(
            () => {
              this.setSuccessEdit();
              if (getTSqEnvironment().appType === AppType.COMMUNITIES) {
                this.toastr.show(
                  this.translateService.instant('LIBS.COMMENTS.EDIT_COMMENT_SUCCESS'),
                  this.translateService.instant('LIBS.COMMENTS.EDIT_COMMENT_SUCCESS_TITLE'),
                  { toastComponent: ToastSuccessComponent },
                );
              } else {
                this.toastr.success(
                  this.translateService.instant('LIBS.COMMENTS.EDIT_COMMENT_SUCCESS'),
                );
              }
              this.modalService.close();
            },
            () => {
              if (getTSqEnvironment().appType === AppType.COMMUNITIES) {
                this.toastr.show(
                  this.translateService.instant('LIBS.COMMENTS.EDIT_OR_DELETE_COMMENT_ERROR'),
                  this.translateService.instant('LIBS.COMMENTS.EDIT_OR_DELETE_COMMENT_ERROR_TITLE'),
                  { toastComponent: ToastErrorComponent },
                );
              } else {
                this.toastr.error(
                  this.translateService.instant('LIBS.COMMENTS.EDIT_OR_DELETE_COMMENT_ERROR'),
                );
              }
            },
          ),
        ),
      ),
    ),
  );

  readonly deleteComment = this.effect((data$: Observable<EditAndDeleteComments>) =>
    data$.pipe(
      tap(() => this.setSendingDelete()),
      switchMap(({ comment, feature }) =>
        this.commentsService.deleteComment(comment, feature).pipe(
          tapResponse(
            () => {
              this.setSuccessDelete();
              if (getTSqEnvironment().appType === AppType.COMMUNITIES) {
                this.toastr.show(
                  this.translateService.instant('LIBS.COMMENTS.DELETE_COMMENT_SUCCESS'),
                  this.translateService.instant('LIBS.COMMENTS.DELETE_COMMENT_SUCCESS_TITLE'),
                  { toastComponent: ToastSuccessComponent },
                );
              } else {
                this.toastr.success(
                  this.translateService.instant('LIBS.COMMENTS.DELETE_COMMENT_SUCCESS'),
                );
              }
              this.modalService.close();
            },
            () => {
              if (getTSqEnvironment().appType === AppType.COMMUNITIES) {
                this.toastr.show(
                  this.translateService.instant('LIBS.COMMENTS.EDIT_OR_DELETE_COMMENT_ERROR'),
                  this.translateService.instant('LIBS.COMMENTS.EDIT_OR_DELETE_COMMENT_ERROR_TITLE'),
                  { toastComponent: ToastErrorComponent },
                );
              } else {
                this.toastr.error(
                  this.translateService.instant('LIBS.COMMENTS.EDIT_OR_DELETE_COMMENT_ERROR'),
                );
              }
            },
          ),
        ),
      ),
    ),
  );

  readonly adminDeleteComment = this.effect((data$: Observable<EditAndDeleteComments>) =>
    data$.pipe(
      tap(() => this.setSendingAdminDelete()),
      switchMap(({ assignmentId, comment, feature, reason }) => {
        return feature !== Feature.Assignments
          ? this.commentsService.adminDeleteComment(comment, feature, reason)
          : this.commentsService.adminDeleteCommentAssignments(assignmentId, comment, reason);
      }),
      tapResponse(
        () => {
          this.setSuccessAdminDelete();
          if (getTSqEnvironment().appType === AppType.COMMUNITIES) {
            this.toastr.show(
              this.translateService.instant('LIBS.DELETE_COMMENTS.DELETE_COMMENT_SUCCESS'),
              this.translateService.instant('LIBS.COMMENTS.DELETE_COMMENT_SUCCESS_TITLE'),
              { toastComponent: ToastSuccessComponent },
            );
          } else {
            this.toastr.success(
              this.translateService.instant('LIBS.DELETE_COMMENTS.DELETE_COMMENT_SUCCESS'),
            );
          }
          this.modalService.close();
        },
        () => {
          if (getTSqEnvironment().appType === AppType.COMMUNITIES) {
            this.toastr.show(
              this.translateService.instant('LIBS.COMMENTS.EDIT_OR_DELETE_COMMENT_ERROR'),
              this.translateService.instant('LIBS.COMMENTS.EDIT_OR_DELETE_COMMENT_ERROR_TITLE'),
              { toastComponent: ToastErrorComponent },
            );
          } else {
            this.toastr.error(
              this.translateService.instant('LIBS.COMMENTS.EDIT_OR_DELETE_COMMENT_ERROR'),
            );
          }
        },
      ),
    ),
  );

  private setSendingEdit = this.updater(state => ({
    ...state,
    edit: {
      ...state.edit,
      sending: true,
      success: false,
    },
  }));

  private setSuccessEdit = this.updater(state => ({
    ...state,
    edit: {
      ...state.edit,
      sending: false,
      success: true,
    },
  }));

  private setSendingDelete = this.updater(state => ({
    ...state,
    delete: {
      ...state.delete,
      sending: true,
      success: false,
    },
  }));

  private setSuccessDelete = this.updater(state => ({
    ...state,
    delete: {
      ...state.delete,
      sending: false,
      success: true,
    },
  }));

  private setSendingAdminDelete = this.updater(state => ({
    ...state,
    adminDelete: {
      ...state.adminDelete,
      sending: true,
      success: false,
    },
  }));

  private setSuccessAdminDelete = this.updater(state => ({
    ...state,
    adminDelete: {
      ...state.adminDelete,
      sending: false,
      success: true,
    },
  }));

  constructor(
    private commentsService: CommentsService,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private modalService: ModalService,
    private store: Store,
  ) {
    super(initialState);
  }
}
