import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { SCModalComponent } from '../sc-modal/sc-modal.component';

@Component({
  selector: 'comm-confirmation-dialog',
  templateUrl: './tsq-confirmation-dialog.component.html',
  styleUrls: ['./tsq-confirmation-dialog.component.scss'],
})
export class TsqConfirmationDialogComponent {
  @ViewChild('modal', { static: true }) modal: SCModalComponent;

  @Input() headerImg = 'robot-error-white-border';
  @Input() message = '';
  @Input() disabled = false;
  @Input() disableOnlyConfirmation = false;
  @Input() confirmationLabel = 'SHARED.TSQ_DEFAULT_CONFIRMATION_CONFIRMATION';
  @Input() cancelLabel = 'SHARED.TSQ_DEFAULT_CANCEL_CONFIRMATION';
  @Input() modalClosable = true;
  @Input() escClosable = true;
  @Input() displayImage = true;

  @Output() confirmAction = new EventEmitter();
  @Output() cancelAction = new EventEmitter();
  @Output() modalHidden = new EventEmitter();

  isActionConfirmed = false;

  private entity;

  onModalHidden(): void {
    this.isActionConfirmed
      ? this.confirmAction.emit(this.entity)
      : this.cancelAction.emit(this.entity);
    this.isActionConfirmed = false;
    this.modalHidden.emit();
  }

  askConfirmation(entity?): void {
    this.entity = entity ? entity : undefined;
    this.modal.show();
  }

  get imgUrl(): string {
    return `./assets/img/icons/${this.headerImg}.png`;
  }

  confirm(): void {
    this.isActionConfirmed = true;
    this.modal.hide();
  }

  cancel(): void {
    this.isActionConfirmed = false;
    this.modal.hide();
  }
}
