import { ValidationErrors } from '@angular/forms';

import { cloneDeep } from 'lodash';

export function getErrorMessageFromValidationErrors(validationErrors: ValidationErrors)
: [string?, Record<string, unknown>?] {
  if (!!validationErrors) {
    const errorKey = Object.keys(validationErrors)[0];
    const args = cloneDeep(validationErrors);
    delete args[errorKey];

    return [`LIBS.VALIDATORS.${ camelToSnakeCase(errorKey).toLocaleUpperCase() }`, { ...args }];
  }

  return undefined;
}

function camelToSnakeCase(value: string): string {
  return value.replace(/[A-Z]/g, letter => `_${ letter.toLocaleLowerCase() }`);
}
