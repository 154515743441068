<div>
  <boleto-billing-selection-edit *ngIf="paymentInfo.type === paymentTypes.BankSlip"
                                 [bankSlip]="paymentInfo.bankSlip"
                                 (brBillingChanged)="boletoChanged($event)"
                                 #brBillingSelection>
  </boleto-billing-selection-edit>

  <credit-card-billing-selection-edit *ngIf="paymentInfo.type === paymentTypes.Stripe"
                                      (usBillingChanged)="creditCardChanged($event)"
                                      #usBillingSelection>
  </credit-card-billing-selection-edit>
</div>
