import { Injectable, inject } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import { fromUserContextActions } from '@tsq-web/user-context';

import { AuthStep } from '../../models/auth-step.enum';
import { AuthStepStore } from './auth-step.store';

@Injectable()
export class AuthStepEffects {
  private actions$ = inject(Actions);
  private authStepStore = inject(AuthStepStore);

  loginFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromUserContextActions.loginFailed),
        tap(() => {
          this.authStepStore.patchState({
            step: AuthStep.Review,
          });
        }),
      ),
    { dispatch: false },
  );
}
