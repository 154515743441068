import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'tsq-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagComponent {
  @Input() text: string;
  @Input() kind: 'active' | 'disabled' | 'alternate' | 'error' = 'active';
  @Input() icon: string;
  @Input() highlight: string;
}
