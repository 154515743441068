<div class="tsq-card">
  <div class="collapse-header pointer-cursor" (click)="toggleCollapse()" *ngIf="!icon">
    <span>{{ label | translate }}</span>
    <i class="fa fa-angle-down" [attr.isCollapsed]="isCollapsed" aria-hidden="true"></i>
  </div>
  <div class="collapse-header pointer-cursor" (click)="toggleCollapse()" *ngIf="!!icon">
    <div class="with-icon flex">
      <img [src]="icon" alt="" />
      <span>{{ label | translate }}</span>
    </div>
    <i class="fa fa-angle-down" [attr.isCollapsed]="isCollapsed" aria-hidden="true"></i>
  </div>
  <div class="divider" *ngIf="!isCollapsed"></div>
  <div [ngClass]="isCollapsed ? 'hidden' : 'block'">
    <ng-content></ng-content>
  </div>
</div>
