import {createFeatureSelector, createSelector} from '@ngrx/store';

import {LocalizationState} from './localization.reducer';

export const selectLocalizationState = createFeatureSelector<LocalizationState>('localization');

export const selectLanguage = createSelector(
  selectLocalizationState,
  (localizationState: LocalizationState) => localizationState.language,
);

export const selectTimeZone = createSelector(
  selectLocalizationState,
  (localizationState: LocalizationState) => localizationState.timeZone,
);
