<div id="br-billing">
  <div class="tsq-card">
    <h1>{{ 'SUBSCRIPTION.BILLING_INFORMATION' | translate }}</h1>

    <form [formGroup]="form" [ngClass]="{ 'form-row': isInlineForm, 'form-column': !isInlineForm }">

      <div class="mt-16">
        <div class="text-coal mb-8">
          <label for="boleto-billing-selection-edit--input--condo-name">
            {{ 'SUBSCRIPTION.PAYER_NAME' | translate }}
          </label>
          <input id="boleto-billing-selection-edit--input--condo-name" class="form-control" formControlName="condoName">
        </div>
      </div>

      <div class="grid grid-cols-2 gap-8">
        <div class="text-coal">
          <label for="boleto-billing-selection-edit--input--billing-email">
            {{ 'SUBSCRIPTION.CHARGE_EMAIL' | translate }}
          </label>
          <input id="boleto-billing-selection-edit--input--billing-email" class="form-control"
                 formControlName="chargeEmail">
        </div>

        <div class="text-coal">
          <label for="boleto-billing-selection-edit--input--cpfCnpj">
            {{ 'SUBSCRIPTION.PAYER_CPF_CNPJ' | translate }}
          </label>
          <input id="boleto-billing-selection-edit--input--cpfCnpj" class="form-control" formControlName="cpfCnpj"
                 [imask]="cpfCnpjMask">
        </div>

        <div class="text-coal">
          <label for="boleto-billing-selection-edit--input--phone">{{ 'SUBSCRIPTION.PHONE_DDD' | translate }}</label>
          <input id="boleto-billing-selection-edit--input--phone" class="form-control" formControlName="phone"
                 [imask]="phoneMask">
        </div>

        <div class="text-coal">
          <label for="boleto-billing-selection-edit--input--cep">{{ 'SUBSCRIPTION.CEP' | translate }}</label>
          <input id="boleto-billing-selection-edit--input--cep" class="form-control" formControlName="cep" cepValidator
                 [imask]="cepMask"
                 [unmask]="true"
                 [streetControl]="form.controls['street']"
                 [neighborhoodControl]="form.controls['neighborhood']"
                 [cityControl]="form.controls['city']"
                 [stateControl]="form.controls['state']">
        </div>
      </div>

      <div class="text-coal my-8">
        <label for="boleto-billing-selection-edit--input--street">{{ 'SUBSCRIPTION.STREET' | translate }}</label>
        <input id="boleto-billing-selection-edit--input--street" class="form-control" formControlName="street">
      </div>

      <div class="grid grid-cols-2 gap-8">
        <div class="text-coal" [ngClass]="{ 'no-margin': isInlineForm }">
          <label for="boleto-billing-selection-edit--input--number">{{ 'SUBSCRIPTION.NUMBER' | translate }}</label>
          <input id="boleto-billing-selection-edit--input--number" class="form-control" formControlName="number"
                 type="number">
        </div>

        <div class="text-coal">
          <label for="boleto-billing-selection-edit--input--neighborhood">
            {{ 'SUBSCRIPTION.NEIGHBORHOOD' | translate }}
          </label>
          <input id="boleto-billing-selection-edit--input--neighborhood" class="form-control"
                 formControlName="neighborhood">
        </div>

        <div class="text-coal">
          <label for="boleto-billing-selection-edit--input--city">{{ 'SUBSCRIPTION.CITY' | translate }}</label>
          <input id="boleto-billing-selection-edit--input--city" class="form-control" formControlName="city">
        </div>

        <div class="text-coal">
          <label for="boleto-billing-selection-edit--input--state">{{ 'SUBSCRIPTION.STATE' | translate }}</label>
          <input id="boleto-billing-selection-edit--input--state" class="form-control" formControlName="state">
        </div>

        <div class="text-coal">
          <label for="boleto-billing-selection-edit--input--complement">
            {{ 'SUBSCRIPTION.COMPLEMENT' | translate }}
            <span class="text-sm text-coal-tertiary">{{ 'OPTIONAL' | translate | uppercase }}</span>
          </label>
          <input id="boleto-billing-selection-edit--input--complement" class="form-control"
                 formControlName="complement">
        </div>

        <div class="text-coal">
          <label for="boleto-billing-selection-edit--input--municipal-inscription">
            {{ 'SUBSCRIPTION.MUNICIPAL_INSCRIPTION' | translate }}
          </label>
          <input id="boleto-billing-selection-edit--input--municipal-inscription" class="form-control"
                 formControlName="municipalInscription">
        </div>
      </div>
    </form>
  </div>
</div>
