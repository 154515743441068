import { Observable } from 'rxjs';

import { Feature } from '@tsq-web/feature';
import { Permission } from '@tsq-web/permission';

export abstract class AuthService {
  abstract hasFeatureEnabled(feature: Feature): Observable<boolean>;
  abstract hasPermissionOn(
    feature: Feature,
    permission: Permission,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fn?: (...params: any) => boolean,
  ): Observable<boolean>;
  abstract shouldRefresh(): Observable<boolean>;
}
