<ng-template #modalRef>
  <div class="modal-header">
    <div class="flex gap-8">
      <tsq-icon [icon]="icons.trash"></tsq-icon>
      <span>{{ 'LIBS.DELETE_COMMENT' | translate }}</span>
    </div>
    <tsq-generic-modal-close></tsq-generic-modal-close>
  </div>

  <div class="modal-body-content px-16 pb-24">
    <tsq-icon [icon]="icons.triangleExclamation" [classes]="'w-40 h-40 mt-56'"></tsq-icon>
    <span class="text-coal text-lg font-extra-bold pt-16">{{
      'LIBS.THIS_ACTION_CANNOT_BE_UNDONE' | translate
    }}</span>
    <p class="pt-4 pb-16 m-0 text-coal-secondary">
      {{ 'LIBS.DELETE_COMMENTS.ONCE_GONE' | translate }}
    </p>
    <div class="border-b-4 border-petro-n2 w-72"></div>
    <form [formGroup]="form">
      <p class="pt-16 pb-8 text-coal">
        {{ 'LIBS.DELETE_COMMENTS.WHATS_THE_REASON' | translate }}
      </p>
      <tsq-radio-group [options]="deleteCommentsOptions" formControlName="reason"></tsq-radio-group>
      <div *ngIf="shouldShowOtherReasonField" class="group flex-col">
        <label class="pt-16">{{ 'LIBS.DELETE_COMMENTS.WHATS_YOUR_REASONING' | translate }} </label>
        <textarea
          tsqTextInput
          class="control"
          formControlName="description"
          [placeholder]="'LIBS.DELETE_COMMENTS.ENTER_YOUR_ANSWER' | translate"
        ></textarea>
      </div>
    </form>
  </div>

  <div class="modal-footer flex flex-row gap-8">
    <tsq-generic-modal-cancel-button></tsq-generic-modal-cancel-button>
    <tsq-button
      *ngIf="!adminDeleteCommentIsSending"
      data-cy="delete-comment-modal--button--delete-comment"
      [text]="'LIBS.DELETE_COMMENT' | translate"
      [kind]="'primary'"
      [schema]="'red'"
      [disabled]="form.invalid"
      (clicked)="onDeleteComment()"
    ></tsq-button>
    <tsq-small-loader *ngIf="adminDeleteCommentIsSending" />
  </div>
</ng-template>
