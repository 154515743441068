import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';

import {CookieService} from 'ngx-cookie-service';
import {Observable} from 'rxjs';

import {BaseService} from '@tsq-web/core';
import {User} from '@tsq-web/users';
import {Condo} from '@tsq-web/condo';

@Injectable()
export class CondoService extends BaseService {

  constructor(protected cookieService: CookieService,
              protected httpClient: HttpClient,
              protected router: Router) {
    super(cookieService, httpClient, router);
  }

  goToCondo(id: string): Observable<User> {
    return this.doPut(`login/gotoCondo/${id}`, '{}');
  }

  getCondo(id: string): Observable<Condo> {
    return this.doGet(`condo/${id}`);
  }
}
