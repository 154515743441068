<div class="bg-petro-n1 font-base flex h-full justify-center">
  <div class="flex h-full flex-col items-center gap-48">
    <img [src]="logoPath" [alt]="'TOWNSQ_LOGO' | translate" class="mt-120 block w-[176px]" />

    <div class="border-petro-n2 border-1 w-[504px] rounded-2xl bg-white p-40 pt-32">
      <button
        *ngIf="!(ssoLoginError$ | async)"
        class="text-new-primary-p2 hover:text-new-primary-p1 outline-new-primary mb-16 rounded outline-offset-2 focus:outline"
        (click)="onPreviousStep()"
      >
        <tsq-icon [icon]="leftArrow" />
      </button>

      <ng-container [ngSwitch]="step$ | async">
        <comm-login-email-form
          *ngSwitchCase="authStep.Email"
          [isLoading]="isLoading$ | async"
          [email]="email$ | async"
          (emailSubmitted)="onNext($event)"
        />
        <comm-login-password-form
          *ngSwitchCase="authStep.Password"
          [isLoginIn]="isLoginIn$ | async"
          [password]="loginInformation?.password"
          (loginRequested)="passwordSent$.next($event)"
        />
        <comm-login-review-access
          *ngSwitchCase="authStep.Review"
          [messageError]="errorMessage$ | async"
          [loginInformation]="loginInformation"
          [isLoginIn]="isLoginIn$ | async"
          (restartFlowRequested)="onRestartFlowRequested()"
          (loginRequested)="onLoginRequested($event)"
        />
        <comm-sso-redirect
          *ngSwitchCase="authStep.Sso"
          [ssoError]="ssoLoginError$ | async"
          [ssoAutenthicateLoading]="ssoAuthenticateLoading$ | async"
          (redirectSso)="redirectSso()"
          (backToLogin)="backToLogin()"
        />
      </ng-container>
    </div>
  </div>
</div>
