<div class="tsq-tooltip" (mouseenter)="onMouseOver()" (mouseleave)="onMouseLeave()" [ngStyle]="styleDivLabel">
    <span #labelTooltip [ngStyle]="styleLabel">
        <ng-content></ng-content>
    </span>
</div>

<div #divTooltip style="position: absolute" [style.left.px]="leftPosition">
    <div class="tsq-tooltiptext" *ngIf="showHint" [style.width.px]="size">
        {{hint}}
        <ng-content select="[tsq-tooltip-type=hint]"></ng-content>
    </div>
</div>