/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import * as crypto from 'crypto-js';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { TSqValidators } from '@tsq-web/forms';
import { UserRecovery } from '@tsq-web/users';
import { UserRecoveryService } from '@tsq-web/users';

import { initializePendoForAnonymousVisitor } from '../shared/pendo/pendo.utils';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sc-user-recovery',
  templateUrl: './user-recovery.component.html',
  styleUrls: ['./user-recovery.component.scss'],
  providers: [UserRecoveryService],
})
export class UserRecoveryComponent implements OnInit {
  recovery: UserRecovery = new UserRecovery();
  isSent = false;

  private userId = '';
  private token = '';
  private paramSubscription: Subscription = null;

  readonly form = new FormGroup(
    {
      password: new FormControl('', [Validators.required, TSqValidators.minLengthNChars(8)]),
      passwordConfirmation: new FormControl('', [
        Validators.required,
        TSqValidators.minLengthNChars(8),
      ]),
    },
    TSqValidators.confirmationValidator('password', 'passwordConfirmation'),
  );

  constructor(
    private route: ActivatedRoute,
    private _router: Router,
    private _recoveryService: UserRecoveryService,
    private _translateService: TranslateService,
    private toastr: ToastrService,
  ) {
    this.paramSubscription = this.route.params.subscribe(params => {
      this.userId = params.userId || this.userId;
      this.token = params.token || this.token;
    });
  }

  ngOnInit(): void {
    initializePendoForAnonymousVisitor();
  }

  get userRecoveryImage(): string {
    return this._translateService.currentLang === 'pt'
      ? '../../assets/img/login-image-pt.png'
      : '../../assets/img/login-image-en.png';
  }

  onSubmit(): void {
    this.recovery.id = this.userId;
    this.recovery.token = this.token;
    this.recovery.hashPassword = crypto.SHA1(this.form.controls.password.value).toString();

    this._recoveryService.submitRecovery(this.recovery).subscribe(
      () => this.onReceiveRecoveryInfo(),
      error => this.onReceiveRecoveryError(error),
    );
  }

  get isDisabled(): boolean {
    return this.form.invalid;
  }

  get greetingBackground(): string {
    return this.isPortuguese ? 'green' : 'green-alternate';
  }

  get isPortuguese(): boolean {
    return this._translateService.currentLang === 'pt';
  }

  get loginImage(): string {
    return this.isPortuguese
      ? '../../assets/img/login-image-pt.png'
      : '../../assets/img/login-image-en.png';
  }

  private onReceiveRecoveryInfo(): void {
    this.isSent = true;
  }

  private onReceiveRecoveryError(error: { message: string }): void {
    this.toastr.error(error.message);
  }
}
