<div class="flex justify-end" *ngIf="deletable || editable">
  <button
    #trigger="cdkOverlayOrigin"
    cdkOverlayOrigin
    class="border-1 border-petro-n2 bg-petro-n1 hover:bg-petro-n2 flex h-32 w-32 items-center justify-center rounded"
    (click)="toggleDropDown()"
  >
    <tsq-icon [classes]="'text-green align-middle justify-center'" [icon]="icons.ellipsis" />
  </button>

  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="showDropdownOptions"
    [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
    [cdkConnectedOverlayPositions]="positions"
    (overlayOutsideClick)="showDropdownOptions = false"
    (detach)="showDropdownOptions = false"
  >
    <div class="dropdown-options flex flex-col rounded bg-white p-4 shadow">
      <button
        class="custom-grid hover:bg-green-n1 text-green grid items-center gap-8 rounded p-8 text-left"
        (click)="onEditMessageClick()"
        *ngIf="editable && !shouldShowDeleteOnly"
      >
        <tsq-icon [classes]="'text-green'" [icon]="icons.pencil" />
        <span>{{ 'LIBS.COMMENTS.EDIT_COMMENT' | translate }}</span>
      </button>
      <button
        class="custom-grid hover:bg-red-n1 text-red grid items-center gap-8 rounded p-8 text-left"
        (click)="onDeleteMessageClick()"
        *ngIf="deletable"
      >
        <tsq-icon [classes]="'text-red'" [icon]="icons.trash" />
        <span>{{ 'LIBS.COMMENTS.DELETE_COMMENT' | translate }}</span>
      </button>
    </div>
  </ng-template>
</div>
