import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Observable, Subscription } from 'rxjs';

import { PlanService } from '../../../shared/plans/plan.service';
import { FeaturePackage } from '../../../shared/plans/feature-package.json';
import { Plan } from '../../../shared/plans/plan.json';
import { FeaturePackagesList } from '../../../shared/plans/feature-packages-list.json';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'feature-packages-selection',
  templateUrl: './feature-packages-selection.component.html',
  styleUrls: ['./feature-packages-selection.component.scss'],
})
export class FeaturePackagesSelectionComponent {
  @Input() selectedFeaturePackages: FeaturePackage[];
  @Output() toggleFeaturePackage = new EventEmitter<FeaturePackage>();
  @Output() errorLoadingFeaturePackages = new EventEmitter();

  private _selectedPlan: Plan;

  featurePackages: Observable<FeaturePackagesList>;
  filteredPackages: FeaturePackage[];
  includedFeaturesSubscription: Subscription;

  constructor(private planService: PlanService) {
    this.featurePackages = this.planService.getFeaturePackages();
  }

  @Input()
  set selectedPlan(value: Plan) {
    this._selectedPlan = value;
    if (value) {
      const includedFeatures = value.packages.map(featurePackage => featurePackage.features);

      if (this.includedFeaturesSubscription) {
        this.includedFeaturesSubscription.unsubscribe();
      }

      this.includedFeaturesSubscription = this.featurePackages.subscribe(
        (featurePackages: FeaturePackagesList) => {
          this.filteredPackages = featurePackages.featurePackages.filter(featurePackage => {
            return !featurePackage.features.every(feature =>
              includedFeatures.some(features => features.indexOf(feature) !== -1),
            );
          });
        },
        () => this.errorLoadingFeaturePackages.emit(),
      );
    }
  }

  get selectedPlan(): Plan {
    return this._selectedPlan;
  }

  isPackageSelected(featurePackage: FeaturePackage): boolean {
    return this.selectedFeaturePackages.some(selected => selected.id === featurePackage.id);
  }

  featurePackageClicked(featurePackage: FeaturePackage): void {
    this.toggleFeaturePackage.emit(featurePackage);
  }
}
