<h1>{{ 'LOGIN.PASSWORD' | translate }}</h1>
<p class="text-md mb-0 mt-8">{{ 'LOGIN.ENTER_PASSWORD_TO_ACCESS_COMMUNITY' | translate }}</p>

<form class="group mt-16" (ngSubmit)="onLogin()">
  <label for="password-form--input--email">{{ 'LOGIN.PASSWORD' | translate }}</label>
  <input
    id="password-form--input--email"
    tsqPeekablePassword
    schema="new-primary"
    type="password"
    class="control font-base"
    [formControl]="passwordControl"
  />

  <div class="mt-16 flex justify-center">
    <a
      data-testid="password-form--a--forgot-password"
      routerLink="/user-recovery"
      class="new-primary-link"
      >{{ 'LOGIN.FORGOT_YOUR_PASSWORD_V2' | translate }}</a
    >
  </div>

  <div class="mt-24">
    <tsq-button
      *ngIf="!isLoginIn; else loader"
      schema="new-primary"
      size="lg"
      type="submit"
      [text]="'LOGIN.LOG_IN_V2' | translate"
      [disabled]="passwordControl.invalid"
    />

    <ng-template #loader>
      <tsq-small-loader />
    </ng-template>
  </div>
</form>
