import { Component, EventEmitter, Output } from '@angular/core';

import { icons } from '@tsq-web/assets';
import { slideUp } from '../../utils/banner.animations';

@Component({
  selector: 'comm-native-app-download-button',
  templateUrl: './native-app-download-button.component.html',
  styleUrls: ['./native-app-download-button.component.scss'],
  animations: [slideUp],
})
export class NativeAppDownloadButtonComponent {
  @Output() download = new EventEmitter();
  @Output() closeButton = new EventEmitter();

  readonly icons = icons;
}
