import { Injectable, inject } from '@angular/core';

import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { switchMap, tap } from 'rxjs';

import { SingleSignOnService } from '@tsq-web/login';
import { ToastErrorComponent } from '@tsq-web/toast';

import { getFeatureToggle } from '../../../shared/feature-toggle';
import { AuthStep } from '../../models/auth-step.enum';

interface AuthStepState {
  email: string;
  password: string;
  step: AuthStep;
  isLoading: boolean;
}

const initialState: AuthStepState = {
  email: '',
  password: '',
  step: AuthStep.Email,
  isLoading: false,
};
@Injectable()
export class AuthStepStore extends ComponentStore<AuthStepState> {
  readonly email$ = this.select(({ email }) => email);
  readonly password$ = this.select(({ password }) => password);
  readonly step$ = this.select(({ step }) => step);
  readonly isLoading$ = this.select(({ isLoading }) => isLoading);

  readonly checkSsoConnection = this.effect<string>(email$ =>
    email$.pipe(
      tap(() => this.patchState({ isLoading: true })),
      switchMap(email =>
        this.ssoService.checkSsoConnection(email).pipe(
          tapResponse(
            ({ ssoConection }) => {
              const shouldShowSSOFlow = getFeatureToggle().ssoForNewLogin && ssoConection;

              this.patchState({
                email,
                step: shouldShowSSOFlow ? AuthStep.Sso : AuthStep.Password,
                isLoading: false,
              });
            },
            () => {
              this.patchState({ isLoading: false });
              this.toastr.show(
                this.translateService.instant('LOGIN.SSO.TOAST.ERROR.SSO_CONNECTION'),
                undefined,
                { toastComponent: ToastErrorComponent },
              );
            },
          ),
        ),
      ),
    ),
  );

  readonly goToStep = this.updater((state, step: AuthStep) => ({
    ...state,
    step,
  }));

  readonly goBack = this.updater(state => {
    const previousStepMap: Record<AuthStep, AuthStep> = {
      [AuthStep.Email]: AuthStep.Email,
      [AuthStep.Password]: AuthStep.Email,
      [AuthStep.Review]: AuthStep.Password,
      [AuthStep.Sso]: AuthStep.Email,
    };

    return { ...state, step: previousStepMap[state.step] };
  });

  readonly setEmail = this.updater((state, email: string) => ({
    ...state,
    email,
  }));

  readonly setPassword = this.updater((state, password: string) => ({
    ...state,
    password,
  }));

  readonly resetState = this.updater(() => initialState);

  private readonly ssoService = inject(SingleSignOnService);
  private readonly toastr = inject(ToastrService);
  private readonly translateService = inject(TranslateService);

  constructor() {
    super(initialState);
  }
}
