<section class="w-screen flex items-center py-8 px-16" @slideDown>
  <tsq-icon *ngIf="hasClose"
            [icon]="icons.xmark"
            [classes]="'w-16 mr-8 text-coal-secondary cursor-pointer'"
            (click)="closeBanner.emit()"></tsq-icon>

  <img src="assets/brand/app-icon.png" alt="TownSq App Icon"
       class="w-{{ linkText === 'open' ? 32 : 48 }}">

  <div class="mr-8 ml-16 flex flex-col grow">
    <ng-content></ng-content>
  </div>

  <a class="text-lg" (click)="linkClicked.emit()">
    {{ linkTextTranslationKey | translate }}
  </a>
</section>
