import { CloseScrollStrategy, ConnectedPosition, Overlay } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
} from '@angular/core';

import { icons } from '@tsq-web/assets';

@Component({
  selector: 'tsq-edit-comment-dropdown',
  templateUrl: './manage-comment-dropdown.component.html',
  styleUrls: ['./manage-comment-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageCommentDropdownComponent {
  @Input() editable: boolean;
  @Input() deletable: boolean;
  @Input() shouldShowDeleteOnly: boolean;
  @Output() editMessage = new EventEmitter();
  @Output() deleteMessage = new EventEmitter();

  showDropdownOptions = false;
  scrollStrategy: CloseScrollStrategy;

  readonly icons = icons;
  readonly positions: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
      offsetY: 4,
    },
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
      offsetY: 4,
    },
    {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom',
      offsetY: -4,
    },
    {
      originX: 'end',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'bottom',
      offsetY: -4,
    },
  ];

  private readonly overlay = inject(Overlay);

  constructor() {
    this.scrollStrategy = this.overlay.scrollStrategies.close();
  }

  toggleDropDown(): void {
    this.showDropdownOptions = !this.showDropdownOptions;
  }

  onEditMessageClick(): void {
    this.editMessage.emit();
    this.toggleDropDown();
  }

  onDeleteMessageClick(): void {
    this.deleteMessage.emit();
    this.toggleDropDown();
  }
}
