import { FeaturePackage } from './feature-package.json';
import { Price } from './price.json';

export class Plan {
  id: string;
  name: string;
  description: string;
  packages: FeaturePackage[];
  price: Price;
  duration: number;
  multiplier: string;
  commerciallyAvailable: boolean;
  iconUrl: string;
}
