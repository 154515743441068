import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

import { User } from '@tsq-web/users';
import { AppState } from '@tsq-web/state';
import { fromUserContextSelectors } from '@tsq-web/user-context';
import { PublicAisService } from '../../services/public-ais.service';
import { AisPerson } from '../../models/ais-person.json';
import { AisAccountMatch } from '../../models/ais-account-match.json';

@UntilDestroy()
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ais-link-user',
  templateUrl: './ais-link-user.component.html',
  styleUrls: ['./ais-link-user.component.scss'],
})
export class AisLinkUserComponent implements OnInit {
  owner: AisPerson;
  account: AisAccountMatch;
  accountNumber: string;
  zip: string;

  constructor(
    private publicAisService: PublicAisService,
    private router: Router,
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.owner = this.publicAisService.owner;
    this.account = this.publicAisService.selectedAccount;
    this.accountNumber = this.publicAisService.aisAccRetrieval.account;
    this.zip = this.publicAisService.aisAccRetrieval.zip;

    this.store
      .pipe(select(fromUserContextSelectors.selectUser), untilDestroyed(this))
      .subscribe((user: User) => {
        if (!!user) {
          this.router.navigate(['ais', 'sign-up', 'link-user', 'logged'], {
            queryParamsHandling: 'merge',
          });
        }
      });
  }

  get ownerName(): string {
    return `${this.owner.firstName} ${this.owner.lastName}`;
  }

  goToLogin(): void {
    this.router.navigate(['login'], { queryParams: { redirect: 'ais/sign-up/link-user' } });
  }
}
