import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { initializePendoForAnonymousVisitor } from '../shared/pendo/pendo.utils';
import { ChangeEmail } from '../shared/user/changeEmail.json';
import { UserService } from '../shared/user/user.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sc-user-email-change',
  templateUrl: './user-email-change.component.html',
  styleUrls: ['./user-email-change.component.scss'],
  providers: [UserService],
})
export class UserEmailChangeComponent implements OnInit {
  success = false;
  errorMsg = '';
  private userId = '';
  private token = '';
  private paramSubscription: Subscription = null;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private translateService: TranslateService,
  ) {
    this.paramSubscription = this.route.params.subscribe(params => {
      this.userId = params.userId || this.userId;
      this.token = params.token || this.token;

      if (this.userId.length > 0 && this.token.length > 0) {
        this.confirmEmailChange(this.userId, this.token);
      }
    });
  }

  ngOnInit(): void {
    initializePendoForAnonymousVisitor();
  }

  get greetingBackground(): string {
    return this.isPortuguese ? 'green' : 'green-alternate';
  }

  get isPortuguese(): boolean {
    return this.translateService.currentLang === 'pt';
  }

  get loginImage(): string {
    return this.isPortuguese
      ? '../../assets/img/login-image-pt.png'
      : '../../assets/img/login-image-en.png';
  }

  private confirmEmailChange(userId: string, token: string): void {
    const changeEmail: ChangeEmail = new ChangeEmail();
    changeEmail.id = userId;
    changeEmail.token = token;

    this.userService.confirmEmailChange(changeEmail).subscribe(
      () => (this.success = true),
      error => (this.errorMsg = error.error.message),
    );
  }
}
