/* eslint-disable @angular-eslint/component-selector */
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { UserGroup } from '@tsq-web/groups';

import { getFeatureToggle } from '../../feature-toggle';

@Component({
  selector: 'tsq-groups-selector',
  templateUrl: './tsq-groups-selector.component.html',
  styleUrls: ['./tsq-groups-selector.component.scss'],
})
export class TSqGroupsSelectorComponent {
  @Input() groups: UserGroup[];
  @Input() selectedGroups: UserGroup[];
  @Input() disabled = false;
  @Output() groupClicked = new EventEmitter<UserGroup>();

  readonly featureToggle = getFeatureToggle();

  constructor(private translateService: TranslateService) {}

  getGroupCount(count: number): string {
    return `${count} ${this.translateService.instant(count === 1 ? 'MEMBER' : 'MEMBERS')}`;
  }

  isGroupSelected(group: UserGroup): boolean {
    return this.selectedGroups?.some(selected => selected.id === group.id);
  }

  onGroupClicked(group: UserGroup): void {
    if (!this.disabled) {
      this.groupClicked.emit(group);
    }
  }
}
