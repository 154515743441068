<div
  bsModal
  #modal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  [config]="{ ignoreBackdropClick: ignoreBackdropClick, keyboard: !ignoreEscClick }"
  (onHide)="modalHideFired($event)"
  (onHidden)="modalHiddenFired($event)"
>
  <img
    src="./assets/img/icons/light-close-avocado.svg"
    class="modal-close pointer-cursor"
    (click)="innerClose()"
    alt=""
  />

  <div class="modal-dialog" [ngStyle]="{ width: width + 'px' }" #modalDialog>
    <div class="modal-content" [ngStyle]="{ 'max-height': modalHeight }">
      <div class="modal-body">
        <div id="loader-container" *ngIf="loading">
          <tsq-loader></tsq-loader>
        </div>

        <div id="content-container" *ngIf="!loading">
          <div id="content-header" *ngIf="hasHeader" #headerElement>
            <div>
              <ng-content select="[modal-header]"></ng-content>
            </div>
          </div>

          <div class="divider" *ngIf="hasHeader"></div>

          <div id="content-content" class="tsq-scroll-y">
            <div [ngStyle]="{ 'max-height': contentHeight }">
              <ng-content select="[modal-content]"></ng-content>
            </div>
          </div>

          <div *ngIf="hasFooter" #footerElement>
            <div class="divider"></div>

            <div id="content-footer">
              <ng-content select="[modal-footer]"></ng-content>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
