import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'richText' })
export class RichTextPipe implements PipeTransform {
  transform(value: string): string {
    // eslint-disable-next-line no-control-regex
    let text = value.replace(new RegExp('\n', 'g'), '<br>');

    text = this.replaceCharacterByHtmlTag(text, `\\*`, 'b');
    text = this.replaceCharacterByHtmlTag(text, '_', 'i');

    return text;
  }

  private getMatchingIndexesByChar(text: string, regex: RegExp): number[] {
    const indexes: number[] = [];
    const spreadedText = [...text];

    spreadedText.forEach((char, index) => {
      if (char.match(regex) && !this.findIgnoredIndexes(text).includes(index)) {
        indexes.push(index);
      }
    });

    return indexes;
  }

  private replaceCharacterByHtmlTag(text: string, character: string, tagName: string): string {
    const regex = new RegExp(`(?!<a[^>]*?>)(${character})(?![^<]*?</a>)`, 'g');
    const indexes = this.getMatchingIndexesByChar(text, regex);
    if (!!indexes.length) {
      const arrText = [...text];
      indexes.forEach((charIndex, index) => {
        if (index !== indexes.length - 1 || indexes.length % 2 === 0) {
          arrText[charIndex] = index % 2 === 0 ? `<${tagName}>` : `</${tagName}>`;
        }
      });
      text = arrText.join('');
    }

    return text;
  }

  private findIgnoredIndexes(text: string): number[] {
    const urlPatternRegex = /(?:https?|ftp):\/\/[\w/\-?=%.]+\.[\w/\-?=%.]+/g;
    const emailPatternRegex = /[\w-.]+@([\w-]+\.)+[\w-]{2,4}/g;
    const htmAttributePatternRegex = /\w+="[^"]*"/g;

    const urlIndexes = this.getMatchingIndexesByString(text, urlPatternRegex);
    const emailIndexes = this.getMatchingIndexesByString(text, emailPatternRegex);
    const htmlAttributeIndexes = this.getMatchingIndexesByString(text, htmAttributePatternRegex);

    return [...urlIndexes, ...emailIndexes, ...htmlAttributeIndexes];
  }

  private getMatchingIndexesByString(text: string, regex: RegExp): number[] {
    return [...text.matchAll(regex)].flatMap(match =>
      [...match[0]].map((_, index) => match.index + (index > 0 ? index : 0)),
    );
  }
}
