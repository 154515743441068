<ng-template #modal>
  <div class="relative overflow-y-auto" [ngSwitch]="activeStep$ | async">
    <tsq-generic-modal-close
      *ngIf="isFeedbackPositive !== undefined"
      class="absolute right-16 top-16"
    />

    <ng-container *ngSwitchCase="steps.Rating">
      <comm-general-rating-step
        [isFeedbackPositive]="isFeedbackPositive"
        (rateButtonClicked)="onRateButtonClicked($event)"
        (closeModal)="closeModal()"
      />
      <comm-rating-details-step
        *ngIf="isFeedbackPositive !== undefined"
        [reasonControl]="reasonControl"
        [suggestionChips]="suggestionChips"
        [isPositiveFeedback]="isFeedbackPositive"
        [selectedChipsIndexes]="selectedChipsIndexes"
        (suggestionSelected)="onSuggestionSelected($event)"
        (feedbackSubmitted)="submitFeedback()"
      />
    </ng-container>

    <comm-thank-you-step
      *ngSwitchCase="steps.Sent"
      [isFeedbackPositive]="isFeedbackPositive"
      (openArticle)="goToNextStep(modalSize.Medium)"
      (closeModal)="closeModal()"
    />

    <comm-townsq-article *ngSwitchCase="steps.Article" class="overflow-auto" />
  </div>
</ng-template>
