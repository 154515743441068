import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { getEnvironment } from '../environment';
import { CommunitiesEnvironments } from '../environment/models/communities-environments.enum';
import { LoginLinks } from './models/login-links.model';

@Component({
  selector: 'comm-links-footer',
  templateUrl: './links-footer.component.html',
  standalone: true,
  imports: [TranslateModule, CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinksFooterComponent {
  @Input() showTermsOfUse = true;
  @Input() loginSimplificationEnabled = false;

  loginLinks: LoginLinks;

  readonly isTownSqBrazil = getEnvironment().env === CommunitiesEnvironments.BR_PROD;

  private readonly brazilLinks = {
    backToTownSqLink: 'https://townsq.com.br/',
    helpCenterLink: 'https://townsq.octadesk.com/kb',
    termsOfUseLink: 'https://www.townsq.com.br/termos-de-uso-e-servicos/',
    supportContactLink: 'https://townsq.octadesk.com/kb',
  };
  private readonly unitedStatesLinks = {
    backToTownSqLink: 'https://www.townsq.io/',
    termsOfUseLink: 'http://terms.townsq.io',
    privacyPolicyLink: 'http://privacy.townsq.io',
    supportContactLink: 'mailto:support@townsq.io',
  };

  constructor() {
    this.loginLinks = this.isTownSqBrazil ? this.brazilLinks : this.unitedStatesLinks;
  }

  get supportContactTarget(): string | null {
    return this.loginLinks.supportContactLink.startsWith('mailto:') ? null : '_blank';
  }
}
