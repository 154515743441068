<div>
  <div class="tags-label">
    {{'MASS_INVITE_NEIGHBORS.SEND_INVITES_TO' | translate}}
  </div>

  <div class="input-container">
    <div class="input-info"
         *ngIf="!!emails.length && !invalidEmails.length">
      {{validEmailsMessage}}
    </div>

    <div class="error-input-info"
         *ngIf="!!emails.length && !!invalidEmails.length">
      {{invalidEmailsMessage}}
    </div>

    <neighbor-mass-invite-chips-input id="mass-invite-neighbors--input--emails"
                                      [hasInfoLabel]="!!emails.length"
                                      [displayAsCard]="true"
                                      [inputPlaceholder]="'MASS_INVITE_NEIGHBORS.EMAIL_PLACEHOLDER' | translate"
                                      [emptyPlaceholder]="'MASS_INVITE_NEIGHBORS.EMAIL_PLACEHOLDER_EMPTY' | translate"
                                      [validator]="emailValidator"
                                      [(tags)]="emails"></neighbor-mass-invite-chips-input>

  </div>

  <button id="mass-invite-neighbors--button--submit"
          class="btn btn-primary send-emails-button"
          [class.loading]="isLoading"
          [disabled]="!emails.length || !!invalidEmails.length"
          (click)="submitEmails()">
    <div *ngIf="isLoading; else label">
      <tsq-small-loader backgroundColor="white"></tsq-small-loader>
    </div>
    <ng-template #label>
      {{'MASS_INVITE_NEIGHBORS.SEND' | translate}}
    </ng-template>
  </button>
</div>
