import { Component, ElementRef, Input, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl } from '@angular/forms';

import { ChipInputKeyCodes } from '../../models/chip-input-key-codes.enum';

@Component({
  selector: 'tsq-chip-input',
  templateUrl: './tsq-chip-input.component.html',
  styleUrls: ['./tsq-chip-input.component.scss'],
})
export class TSqChipInputComponent implements OnInit, ControlValueAccessor {
  @ViewChild('input', { static: true }) input: ElementRef;

  @Input() placeholder = '';

  abstractControl: AbstractControl;
  userInput = '';
  disabled: boolean;

  onChanged: (val: string[]) => void;
  onTouched: () => void;

  constructor(@Optional() @Self() public ngControl: NgControl) {
    ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    this.abstractControl = this.ngControl.control;
  }

  get chips(): string[] {
    return !!this.abstractControl.value ? this.abstractControl.value : [];
  }

  registerOnChange(fn: (val: string[]) => void): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  writeValue(value: string[]): void {
    this.userInput = '';
  }

  focusInput(): void {
    this.input.nativeElement.focus();
  }

  onChipRemoved(index: number): void {
    this.onTouched();
    this.onChanged(this.chips.filter((chip, i) => i !== index));
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.code === ChipInputKeyCodes.Backspace) {
      this.onBackspacePressed();
    }
  }

  onKeyUp(event: KeyboardEvent): void {
    if (event.code === ChipInputKeyCodes.Enter || event.code === ChipInputKeyCodes.Space) {
      this.onCreateChip();
    }
  }

  private onCreateChip(): void {
    const trimmedChipText = this.userInput.replace(/\s/g, '');

    if (!!trimmedChipText) {
      this.onTouched();
      this.onChanged([...this.chips, trimmedChipText]);
    }
    this.userInput = '';
  }

  private onBackspacePressed(): void {
    if (!this.userInput) {
      this.onTouched();
      this.onChanged(this.chips.filter((value, index, arr) => arr.length - 1 !== index));
    }
  }
}
