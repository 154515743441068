import { Condo } from '@tsq-web/condo';
import { User } from '@tsq-web/users';

import { IntroBanner } from './intro-banner.model';
import { LoginErrorType } from './login-error-type.enum';
import { LoginProvider } from './login-providers.enum';

export class LoginData {
  user: User;
  condos: Condo[];

  // eslint-disable-next-line @typescript-eslint/naming-convention
  logon_token: string;
  jwt: string;

  isFirstLogin: boolean;
  loginProvider: LoginProvider;

  success: boolean;
  errorType: LoginErrorType;
  errorMessage: string;

  introBanners: IntroBanner[];
}
