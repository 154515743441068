<main class="relative grid h-full grid-cols-1 md:grid-cols-3">
  <section class="bg-green md:col-span-1">
    <div class="m-40">
      <img
        src="./assets/brand/white-logo.svg"
        class="h-56"
        alt="{{ 'LOGIN.TOWNSQ_LOGO' | translate }}"
      />
    </div>
  </section>

  <section class="bg-petro-n1 col-span-full flex w-full items-center justify-center md:col-span-2">
    <div class="bg-petro-p1 border-petro-n2 border-1 flex flex-col rounded p-40">
      <button (click)="getBackToLogin()">
        <tsq-icon [icon]="arrowLeftIcon" [classes]="'text-green'" />
      </button>

      <div class="mt-32 flex flex-col gap-16">
        <h1>{{ 'LOGIN.WELCOME_BACK' | translate }}</h1>
        <span>{{ 'LOGIN.SSO_INFORMATION' | translate }}</span>
      </div>

      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="mt-16">
          <label>{{ 'LOGIN.EMAIL' | translate }}</label>
          <input
            tsqTextInput
            formControlName="username"
            class="control"
            placeholder="{{ 'LOGIN.EMAIL_PLACEHOLDER' | translate }}"
          />
        </div>

        <div class="mt-24">
          <tsq-button
            *ngIf="(loggingIn$ | async) === false; else loginIn"
            [text]="'LOGIN.LOG_IN' | translate"
            [type]="'submit'"
            [size]="'lg'"
            [disabled]="loginForm.invalid"
          />

          <ng-template #loginIn>
            <div class="flex items-center justify-center">
              <tsq-small-loader />
            </div>
          </ng-template>
        </div>
      </form>

    </div>
  </section>

</main>