export enum FeedbackContexts {
  NeighborInvitation = 'neighbor_invitation',
  DocumentCreation = 'document_creation',
  ForumCreation = 'forum_creation',
  ForumComment = 'forum_comment',
  AnnouncementCreation = 'announcement_creation',
  SurveyCreation = 'survey_creation',
  SurveyVote = 'survey_vote',
  AssignmentCreation = 'assignment_creation',
  AssignmentComment = 'assignment_comment',
  MessageCreation = 'message_creation',
  MessageComment = 'message_comment',
  RequestCreation = 'request_creation',
  RequestComment = 'request_comment',
  LobbyEntranceApproved = 'lobby_entrance_approved',
  GroupCreation = 'group_creation',
}
