<div
  class="tsq-card p-8"
  id="create-comment--div--comment-container"
  tabindex="0"
  (blur)="onBlur()"
>
  <div class="group">
    <textarea
      class="control border-1 border-petro-n2 w-full resize-y rounded"
      #textarea
      tsqTextInput
      [focusOnInit]="true"
      [formControl]="commentTextControl"
      [placeholder]="'ADD_COMMENT' | translate"
      [maxlength]="maxLength"
      (input)="onTextAreaChange()"
      (blur)="onBlur()"
    ></textarea>
  </div>

  <div *ngIf="!!fileUploader?.files?.length" class="mt-8">
    <tsq-file-list
      [files]="fileUploader.files"
      (delete)="fileUploader.removeAt($event)"
    ></tsq-file-list>
  </div>

  <div class="mt-8 flex justify-between gap-8">
    <div>
      <ng-container *ngTemplateOutlet="extraActionsTemplate"></ng-container>
    </div>

    <div class="flex items-center gap-8">
      <tsq-file-uploader
        [hidden]="!hasAttachments || fileUploader?.uploading"
        [disabled]="fileUploader.files?.length >= maxAttachments"
        [maxFiles]="maxAttachments"
        [selfHandleVisualization]="true"
        [selfHandleSending]="true"
        #fileUploader
      ></tsq-file-uploader>

      <tsq-small-loader
        *ngIf="(hasAttachments && fileUploader?.uploading) || sendingComment"
      ></tsq-small-loader>

      <tsq-button
        *ngIf="!sendingComment"
        [text]="'SEND' | translate"
        [disabled]="!formValid || !commentTextControl.value"
        [icon]="icons.send"
        (clicked)="onSaveComment()"
      ></tsq-button>
    </div>
  </div>
</div>
