import { User } from '@tsq-web/users';
import { Role } from '@tsq-web/permission';
import { Organization } from '@tsq-web/organization';
import { Condo } from '@tsq-web/condo';
import { ContextType } from './context-type.enum';

export class LoginContext {
  type: ContextType;
  id: string;
  user: User;
  permissions: Role[];
  context: Organization | Condo;
}
