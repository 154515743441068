export class BankSlipPaymentInfo {
  invoiceId: string;
  invoiceUrl: string;
  digitableLine: string;
  barcodeData: string;

  dueDate: string;
  name: string;
  cpfCnpj: string;
  email: string;
  phone: string;
  zipCode: string;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
  municipalInscription: string;
  simplesNacional: boolean;
  taxpayer: boolean;
  applyPisCofinsCsll: boolean;
  applyIR: boolean;
  applyISS: boolean;
}
