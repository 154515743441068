<ng-template #modalContent>
  <div class="p-16 modal-header flex justify-between text-left">
    <h1>{{ 'LIBS.COMMENTS.EDIT_COMMENT' | translate }}</h1>
    <tsq-generic-modal-close></tsq-generic-modal-close>
  </div>

  <tsq-edit-comment-form [form]="form"
                         [fileUploadURL]="fileUploadURL"
                         [editCommentIsLoading]="editCommentIsLoading"
                         (closeModal)="modalClose()"
                         (editComment)="editComment.emit($event)"></tsq-edit-comment-form>
</ng-template>
