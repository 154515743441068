export class FileMeta {
  id: string;
  key: string;
  name: string;
  filenameExt: string;
  extension?: string;
  size: number;
  fileUrl: string;
  bucket?: string;
}
