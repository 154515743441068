import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'nl2br' })
export class NewLineToBreakLinePipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  constructor(private _sanitizer: DomSanitizer) {}

  transform(value: string): SafeHtml {
    if (value === undefined || value === null || value.trim() === '') {
      return '';
    }

    return this._sanitizer.bypassSecurityTrustHtml(value.replace(/\n/g, '<br>'));
  }
}
