import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { ToastrModule } from 'ngx-toastr';

import { neighborInviteRegisterRoute } from '../+neighbor-invite-register/neighbor-invite-register.route';
import { ExpiredSubscriptionTrialComponent } from './expired-subscription-trial.component';
import { SubscriptionService } from '../shared/subscription/subscription.service';
import { SCWidgetModule } from '../shared/component/sc.widget.module';
import { SubscriptionModule } from '../+dashboard/main-subscription/subscription.module';
import { TSqLoadersModule } from '@tsq-web/loaders';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    RouterModule.forChild(neighborInviteRegisterRoute),
    SCWidgetModule,
    SubscriptionModule,
    ToastrModule,
    TSqLoadersModule,
  ],
  exports: [ExpiredSubscriptionTrialComponent],
  declarations: [ExpiredSubscriptionTrialComponent],
  providers: [SubscriptionService],
})
export class ExpiredSubscriptionTrialModule {}
