<div class="gauge" #gauge>
  <div class="gauge-pointer"></div>
  <div class="gauge-pointer" *ngFor="let pointer of pointers" [ngStyle]="pointer"></div>
  <div class="gauge-over"></div>
  <div class="gauge-label">
    <div class="gauge-percentage" *ngIf="money <= -1 || money >= 1">{{percentage | number:'1.0-0'}}%</div>
    <div class="gauge-percentage" *ngIf="money > -1 && money < 1">-</div>
    <div *ngIf="money <= -0.01 || money >= 0.01">{{"CURRENCY" | translate}} {{money | number:'1.2-2'}}</div>
    <div *ngIf="money > -0.01 && money < 0.01">-</div>
  </div>
</div>