<hr />
<div class="text-center md:grid-cols-12">
  <div id="contact-social-media" class="flex justify-center">
    <a href="https://www.facebook.com/TownSqAppBr" target="_blank" rel="noopener">
      <img class="m-4 size-24" src="./assets/icons/facebook.svg" alt="" />
    </a>
    <a href="https://www.instagram.com/townsqbr/" target="_blank" rel="noopener">
      <img class="m-4 size-24" src="./assets/icons/instagram.svg" alt="" />
    </a>
    <a href="https://www.youtube.com/SocialCondo" target="_blank" rel="noopener">
      <img class="m-4 size-24" src="./assets/icons/youtube.svg" alt="" />
    </a>
    <a href="https://www.linkedin.com/company/townsq/" target="_blank" rel="noopener">
      <img class="m-4 size-24" src="./assets/icons/linkedin.svg" alt="" />
    </a>
  </div>
</div>
