import { getEnvironment } from '../environment';
import { CommunitiesEnvironments } from '../environment/models/communities-environments.enum';

export function isBrazilianEnvironment(): boolean {
  return getEnvironment().env === CommunitiesEnvironments.BR_PROD;
}

export function getCurrentLogoPath(): string {
  return isBrazilianEnvironment() ? 'assets/brand/logo.svg' : 'assets/brand/logo-community.svg';
}
