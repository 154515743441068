<div
  class="relative flex h-40 cursor-pointer gap-8 rounded-full bg-white shadow"
  [attr.disabled]="disabled ? 'disabled' : null"
>
  <div
    class="bg-primary absolute rounded-full duration-300"
    [ngStyle]="pillStyle"
    [class.hidden]="(selectedIndex ?? -1) < 0"
  ></div>

  <button
    class="text-primary flex w-full items-center justify-center p-16 font-bold"
    *ngFor="let opt of options; let i = index"
    [attr.selected]="i === selectedIndex ? true : null"
    [disabled]="disabled"
    (click)="onRadioSelect(opt.value); $event.preventDefault()"
  >
    {{ opt.text | translate }}
  </button>
</div>
