<div class="tabs-container" *ngIf="!!tabs?.length">
  <div
    *ngFor="let tab of tabs; let i = index"
    [ngStyle]="{ 'flex-basis': 100 / tabs.length + '%' }"
    [attr.selected]="i === selected"
    class="tab-item pointer-cursor"
    [class.uppercase-tab]="shouldUppercase"
    [id]="tab.id || ''"
    (click)="selectTab(i)"
  >
    <img
      *ngIf="!!tab.icon && !useIconPool"
      [class]="tab.iconFilter || ''"
      [src]="tab.icon"
      alt=""
    />

    <tsq-icon
      *ngIf="!!tab.icon && !!useIconPool"
      [icon]="tab.icon"
      [class]="tab.iconFilter || ''"
    />

    <span class="font-bold">{{ tab.text | translate }}</span>

    <span
      *ngIf="!!tab.counterBadge || tab.counterBadge === 0"
      [attr.data-cy]="'tsq-tabs--span--badge-counter-' + i"
      class="font-semi-bold mx-8 rounded px-8 leading-normal text-white"
      [ngClass]="badgeClasses(tab, i === selected)"
    >
      {{ tab.counterBadge }}
    </span>
  </div>

  <div
    class="tab-indicator"
    [ngStyle]="{ width: 100 / tabs.length + '%', left: selected * (100 / tabs.length) + '%' }"
  ></div>
</div>

<div class="divider"></div>
