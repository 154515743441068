import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';

import { getTSqEnvironment } from '@tsq-web/environment';
import { Organization } from '@tsq-web/organization';
import { AppState } from '@tsq-web/state';

import { AppType } from '../models/app-type.enum';

@Injectable()
export class HttpHeadersInterceptor implements HttpInterceptor {
  private readonly cookieService = inject(CookieService);
  private readonly store = inject(Store<AppState>);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.isTSqEnvironment(request.url)) {
      return next.handle(request);
    }

    let { headers } = request.clone();

    const lang = this.cookieService.get('tsq-lang') || this.cookieService.get('sc-lang');
    headers = headers
      .set('Accept-Language', lang)
      .set('xx-sc-l', lang)
      .set('X-Townsq-Application', HttpHeadersInterceptor.xTownSqApplicationHeader);

    if (!headers.has('Accept')) {
      headers = headers.set('Accept', 'application/json');
    }
    if (!headers.has('Content-Type')) {
      headers = headers.set('Content-Type', 'application/json');
    }
    if (this.cookieService.check('logonToken')) {
      const token = this.cookieService.get('logonToken');
      headers = headers.set('xx-sc-r', (JSON.parse(token) as { logonToken: string }).logonToken);
    }
    if (this.cookieService.check('jwt') && !headers.has('Authorization')) {
      const jwt = this.cookieService.get('jwt');
      headers = headers.set('Authorization', `Bearer ${jwt}`);
    }

    return this.store.pipe(
      take(1),
      mergeMap(state => {
        if (!!state) {
          const appType = getTSqEnvironment().appType;

          if (appType === AppType.COMMUNITIES) {
            const condoId = (state as AppState)?.userContext?.condo?.id;

            if (!!condoId) {
              if (!headers.has('X-Scope-Type')) {
                headers = headers.set('X-Scope-Type', 'COMMUNITY');
              }
              if (!headers.has('X-Scope-Id')) {
                headers = headers.set('X-Scope-Id', condoId);
              }
            }
          } else if (
            appType === AppType.BUSINESS &&
            typeof state === 'object' &&
            'organization' in state &&
            typeof state.organization === 'object' &&
            'entity' in state.organization
          ) {
            const orgId = (state.organization.entity as Organization)?.id;

            if (!!orgId) {
              if (!headers.has('X-Scope-Type')) {
                headers = headers.set('X-Scope-Type', 'ORGANIZATION');
              }
              if (!headers.has('X-Scope-Id')) {
                headers = headers.set('X-Scope-Id', orgId);
              }
            }
          }
        }

        return next.handle(request.clone({ headers }));
      }),
    );
  }

  private static get xTownSqApplicationHeader(): string {
    const agent = window.navigator.userAgent.toLocaleLowerCase();
    let browser = 'other';

    if (agent.includes('edge')) {
      browser = 'edge';
    } else if (agent.includes('opr') && !!(window as unknown as { opr }).opr) {
      browser = 'opera';
    } else if (agent.includes('chrome') && !!(window as unknown as { chrome }).chrome) {
      browser = 'chrome';
    } else if (agent.includes('firefox')) {
      browser = 'firefox';
    } else if (agent.includes('safari')) {
      browser = 'safari';
    }

    return `${getTSqEnvironment().appType?.toLocaleLowerCase()}/1.0.0 webapp/1 ${browser}`;
  }

  private isTSqEnvironment(url: string): boolean {
    return url.includes(getTSqEnvironment().baseApi);
  }
}
