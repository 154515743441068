/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'comm-comment-bubble',
  templateUrl: './sc-comment-bubble.component.html',
})
export class SCCommentBubbleComponent {
  @Input() isAdmin: boolean;
  @Input() right: boolean;
  @Input() time: string;
  @Input() name: string;
  @Input() desc: string;
  @Input() text: string;
  @Input() avatarUrl: string;
  @Input() id: string;
  @Input() inReplyTo: any;
  @Input() enableActions = true;
  @Input() showAttachment = false;
  @Input() attachments: any[] = [];
  @Input() urlView: string;
  @Input() urlDownload: string;
  @Input() isHidden: boolean;
  @Output() replySent = new EventEmitter<any>();
  @Output() deleteClick = new EventEmitter<string>();
  showReplyForm = false;
  showReplyComment = false;

  reply(): void {
    this.showReplyForm = true;
  }

  sendResponse(text: string): void {
    if (!this.enableActions) {
      return;
    }

    const params = {
      response: text,
      id: this.id,
    };
    this.replySent.emit(params);
  }

  parseDiscussionText(text: string): string {
    if (text === undefined) {
      return '';
    }

    return text.replace(/(?:\r\n|\r|\n)/g, '<br />');
  }

  showInReplyTo(): void {
    this.showReplyComment = !this.showReplyComment;
  }

  getInReplyToLinkText(): string {
    return this.showReplyComment ? 'SHARED.HIDE_COMMENT' : 'SHARED.SHOW_COMMENT';
  }

  getBubbleSideClasses(): string {
    return this.right ? 'bubble-right bubble-info' : 'bubble-left';
  }

  getDiscussionTimeClass(): string {
    return this.right ? 'text-info-dark' : 'text-warning';
  }

  getReplyIconUrl(): string {
    return (
      './assets/img/icons/' + (this.right ? 'answer-comment-white' : 'answer-comment') + '.svg'
    );
  }

  getRemoveIconUrl(): string {
    return (
      './assets/img/icons/' + (this.right ? 'remove-comment-white' : 'remove-comment') + '.svg'
    );
  }

  delete(): void {
    if (!this.enableActions) {
      return;
    }

    this.deleteClick.emit(this.id);
  }
}
