import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  Renderer2,
  SecurityContext,
  inject,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { escapeRegExp } from 'lodash';

@Directive({
  selector: '[tsqHighlight]',
  standalone: true,
})
export class HighlightDirective implements AfterViewInit {
  private highlightTerm: string;

  private readonly el = inject(ElementRef);
  private readonly renderer = inject(Renderer2);
  private readonly sanitizer = inject(DomSanitizer);

  ngAfterViewInit(): void {
    this.renderNewText();
  }

  @Input()
  set tsqHighlight(term: string) {
    this.highlightTerm = term;
    this.renderNewText();
  }

  private renderNewText(): void {
    if (!this.el?.nativeElement?.textContent) {
      return;
    }

    if (!this.highlightTerm?.trim()) {
      return this.renderTextContentAsInnerHtml();
    }

    this.renderInnerHtmlWithMarkers();
  }

  private renderTextContentAsInnerHtml(): void {
    this.renderer?.setProperty(
      this.el?.nativeElement,
      'innerHTML',
      this.el?.nativeElement?.textContent,
    );
  }

  private renderInnerHtmlWithMarkers(): void {
    this.renderer?.setProperty(this.el?.nativeElement, 'innerHTML', this.getFormattedText());
  }

  private getFormattedText(): string {
    const highlightTerm = this.highlightTerm?.trim();
    const textContent = this.el?.nativeElement?.textContent ?? '';

    if (!highlightTerm) {
      return textContent;
    }

    const sanitizedTerm = this.prepareHighlightPattern(this.highlightTerm);
    const normalizedTerm = this.normalizeText(sanitizedTerm);
    const normalizedContent = this.normalizeText(textContent);

    const highlightRegex = this.buildHighlightRegex(normalizedTerm);

    const matches = this.extractHighlightMatches(highlightRegex, normalizedContent, textContent);
    const highlightedHtml = this.applyHighlights(textContent, matches);

    return this.sanitizer.sanitize(SecurityContext.HTML, highlightedHtml);
  }

  private prepareHighlightPattern(term: string): string {
    return escapeRegExp(term)
      .split(' ')
      .sort((a, b) => b.length - a.length)
      .join(' ');
  }

  private normalizeText(text: string): string {
    const normalizationForm = 'NFD';
    const diacriticsRegex = /[\u0300-\u036f]/g;

    return text.normalize(normalizationForm).replace(diacriticsRegex, '').toLowerCase();
  }

  private buildHighlightRegex(pattern: string): RegExp {
    const words = pattern.split(' ').filter(Boolean).join('|');
    return new RegExp(`(${words})`, 'gi');
  }

  private extractHighlightMatches(
    regex: RegExp,
    normalizedText: string,
    originalText: string,
  ): { start: number; end: number; original: string }[] {
    const matches = [];
    let regexMatch: RegExpExecArray | null;

    while ((regexMatch = regex.exec(normalizedText)) !== null) {
      const start = regexMatch.index;
      const end = start + regexMatch[0].length;
      const original = originalText.substring(start, end);

      matches.push({ start, end, original });
    }

    return matches;
  }

  private applyHighlights(
    text: string,
    matches: { start: number; end: number; original: string }[],
  ): string {
    const highlightClass = 'p-0 text-coal highlight';
    let result = '';
    let lastIndex = 0;

    for (const match of matches) {
      result += text.substring(lastIndex, match.start);
      result += `<mark class="${highlightClass}">${match.original}</mark>`;
      lastIndex = match.end;
    }

    result += text.substring(lastIndex);
    return result;
  }
}
