import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalyticsModule, ScreenTrackingService } from '@angular/fire/compat/analytics';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import {
  AngularFireRemoteConfigModule,
  DEFAULTS,
  SETTINGS,
} from '@angular/fire/compat/remote-config';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { EffectsModule } from '@ngrx/effects';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { CookieService } from 'ngx-cookie-service';

import { AuthService } from '@tsq-web/auth';
import { HttpHeadersInterceptor } from '@tsq-web/core';
import { provideFeatureFlagService } from '@tsq-web/feature-flag';
import { TSqGenericComponentsModule } from '@tsq-web/generic-components';
import { MultiTranslateHttpLoader } from '@tsq-web/i18n';
import { TSqLoadersModule } from '@tsq-web/loaders';
import { OptInService, SingleSignOnService } from '@tsq-web/login';
import { TSqModalsModule } from '@tsq-web/modals';
import { TSqPipesModule } from '@tsq-web/pipes';
import { StateModule } from '@tsq-web/state';
import { TSqToastModule } from '@tsq-web/toast';

import { OrganizationsService } from './+dashboard/features/organizations/services/organizations.service';
import { ExpiredSubscriptionTrialModule } from './+expired-subscription-trial/expired-subscription-trial.module';
import { LoginModule } from './+login/login.module';
import { NeighborInviteRegisterModule } from './+neighbor-invite-register/neighbor-invite-register.module';
import { NeighborMassInviteModule } from './+neighbor-mass-invite/neighbor-mass-invite.module';
import { PublicAisModule } from './+public-ais/public-ais.module';
import { UserEmailChangeModule } from './+user-email-change/user-email-change.module';
import { UserRecoveryModule } from './+user-recovery/user-recovery.module';
import { UserRegisterModule } from './+user-register/user-register.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CondoSelectionComponent } from './condo/+condo-selection/condo-selection.component';
import { CondoSettingsService } from './condo/condo.settings.service';
import { CommunitiesAuthService } from './shared/auth/services/communities-auth.service';
import { CondoService } from './shared/condo/condo.service';
import { ConfigCatService } from './shared/config-cat/config-cat.service';
import { AuthorizationModule } from './shared/core/security/authorization.module';
import { TSqAnalyticsService } from './shared/core/service/tsq-analytics.service';
import { TSqErrorHandler } from './shared/core/service/tsq-error-handler';
import { getEnvironment } from './shared/environment';
import { isLowerEnvironment } from './shared/environment/utils/environment-checker';
import { defaultFirebaseRemoteConfig } from './shared/firebase-remote-config/firebase-remote-config';
import { LibsModule } from './shared/libs.module';
import { NativeAppBannerModule } from './shared/native-app-banner/native-app-banner.module';
import { PipzService } from './shared/pipz/pipz.service';
import { MessagingService } from './shared/push/services/messaging.service';
import { WebPushService } from './shared/push/services/web-push.service';
import { UpsellSettingsService } from './shared/upsell-settings/upsell.settings.service';
import { AppEffects } from './state/app.effects';

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    AuthorizationModule.forRoot(),
    LoginModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => new MultiTranslateHttpLoader(http),
        deps: [HttpClient],
      },
    }),
    LibsModule,
    UserRegisterModule,
    UserRecoveryModule,
    UserEmailChangeModule,
    TranslateModule,
    NeighborInviteRegisterModule,
    ExpiredSubscriptionTrialModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TSqToastModule,
    StateModule,
    EffectsModule.forRoot([AppEffects]),
    PublicAisModule,
    NeighborMassInviteModule,
    TypeaheadModule.forRoot(),
    TSqModalsModule,
    TSqLoadersModule,
    TSqPipesModule,
    AngularFireModule.initializeApp(getEnvironment().firebaseConfig),
    AngularFireRemoteConfigModule,
    AngularFireAnalyticsModule,
    AngularFireMessagingModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    NativeAppBannerModule,
    TSqGenericComponentsModule,
  ],
  declarations: [AppComponent, CondoSelectionComponent],
  providers: [
    CondoService,
    CondoSettingsService,
    PipzService,
    WebPushService,
    MessagingService,
    TranslateService,
    SingleSignOnService,
    CookieService,
    UpsellSettingsService,
    OptInService,
    OrganizationsService,
    { provide: DEFAULTS, useValue: defaultFirebaseRemoteConfig },
    {
      provide: SETTINGS,
      useValue: {
        minimumFetchIntervalMillis: isLowerEnvironment() ? 10_000 : 3_600_000,
      },
    },
    {
      provide: ErrorHandler,
      useClass: TSqErrorHandler,
    },
    TSqAnalyticsService,
    ScreenTrackingService,
    {
      provide: AuthService,
      useClass: CommunitiesAuthService,
    },
    provideFeatureFlagService(ConfigCatService),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpHeadersInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
