/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'tsq-collapse-card',
  templateUrl: 'tsq-collapse-card.component.html',
  styleUrls: ['tsq-collapse-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TSqCollapseCardComponent {
  @Input() label: string;
  @Input() icon: string;
  @Input() disabled: boolean;
  @Output() collapseToggled = new EventEmitter<boolean>();

  private _isCollapsed = true;

  constructor(private cdRef: ChangeDetectorRef) {}

  @Input() set isCollapsed(value: boolean) {
    this._isCollapsed = value;
    this.cdRef.markForCheck();
  }

  get isCollapsed(): boolean {
    return this._isCollapsed;
  }

  toggleCollapse() {
    if (!this.disabled) {
      this.isCollapsed = !this.isCollapsed;
    }
  }
}
