/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, EventEmitter, Output, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { FileUploader } from 'ng2-file-upload';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { TSqValidators } from '@tsq-web/forms';
import { User } from '@tsq-web/users';
import { fromUserContextSelectors } from '@tsq-web/user-context';
import { AppState } from '@tsq-web/state';
import { GlobalVariables } from '../../../globals';

@Component({
  selector: 'sc-comment-input',
  templateUrl: 'sc-comment-input.component.html',
  styleUrls: ['sc-comment-input.component.scss'],
})
export class SCCommentInputComponent {
  @Input('simplified') simplified = false;
  @Input() uploader: FileUploader;
  @Input() showUploader = false;
  @Input() attachments: any[];
  @Output() onCommentSent = new EventEmitter<string>();

  text: string;
  form: UntypedFormGroup;
  globalVariables = GlobalVariables;

  user$: Observable<User>;

  constructor(private _builder: UntypedFormBuilder, private store: Store<AppState>) {
    this.form = this._builder.group({
      text: this._builder.control('', [
        TSqValidators.hasCharDifferentThanWhitespace,
        Validators.required,
      ]),
    });

    this.user$ = this.store.pipe(select(fromUserContextSelectors.selectUser));
  }

  removeAttachment(index: number) {
    this.attachments.splice(index, 1);
  }

  send() {
    this.onCommentSent.emit(this.text);
    this.attachments = [];
    this.form.reset();
    this.text = '';
  }
}
