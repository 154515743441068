<div class="flex zero-data-container">
  <div class="flex img-bg">
    <img src="/assets/img/icons/robot-border.png" alt="">
  </div>

  <div class="empty-content flex">
    <span>{{ title | translate }}</span>

    <span class="description">{{ description | translate }}</span>

    <ng-content select="[after-content]"></ng-content>
  </div>
</div>
