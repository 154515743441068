import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { UserContextEffects } from '@tsq-web/user-context';
import { CustomRouterSerializer } from '@tsq-web/router';
import { getTSqEnvironment } from '@tsq-web/environment';
import { reducers } from './state/app.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot(reducers),
    EffectsModule.forFeature([UserContextEffects]),
    StoreRouterConnectingModule.forRoot({ serializer: CustomRouterSerializer }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: getTSqEnvironment().production,
      name: 'TownSq',
    }),
  ],
})
export class StateModule {
}
