import { Temporal } from '@js-temporal/polyfill';

import { DurationUnit, Meridium, Time } from '../models/date-time.types';
import { EpochMilliseconds } from '../models/epoch-milliseconds.model';
import { ISO8601DateTime } from '../models/iso-8601-date-time.model';
import { TemporalDateLike } from '../models/temporal-date-like.model';
import { TimePeriod, TimeWithPeriod } from '../models/time-with-period.model';

export function isEpochMilliseconds(value: string): boolean {
  const epochMillisecondsRegex = /^-?\d+$/;

  return epochMillisecondsRegex.test(value);
}

export function toDate(value: EpochMilliseconds | ISO8601DateTime | TemporalDateLike): Date {
  try {
    if (!value) {
      return null;
    }

    let epochMilliseconds: number;

    if (typeof value === 'string') {
      if (isEpochMilliseconds(value)) {
        epochMilliseconds = Number(value);
      } else {
        epochMilliseconds = Temporal.Instant.from(value).epochMilliseconds;
      }
    } else if (value instanceof Temporal.ZonedDateTime || value instanceof Temporal.Instant) {
      epochMilliseconds = value.epochMilliseconds;
    } else {
      epochMilliseconds = value;
    }

    return new Date(epochMilliseconds);
  } catch {
    throw new Error(
      `Invalid ISO 8601 string or EpochMilliseconds string|number or Temporal like object: ${value}`,
    );
  }
}

export function toTemporalZonedDateTime(
  value: EpochMilliseconds | ISO8601DateTime | Date,
  timeZone?: Temporal.TimeZoneLike,
): Temporal.ZonedDateTime {
  try {
    if (!value) {
      return null;
    }

    let instant: Temporal.Instant;

    if (typeof value === 'string') {
      if (isEpochMilliseconds(value)) {
        instant = Temporal.Instant.fromEpochMilliseconds(Number(value));
      } else {
        instant = Temporal.Instant.from(value);
      }
    } else {
      const epochMilliseconds = value instanceof Date ? value.valueOf() : value;

      instant = Temporal.Instant.fromEpochMilliseconds(epochMilliseconds);
    }

    return instant.toZonedDateTimeISO(timeZone || Temporal.Now.timeZone());
  } catch {
    throw new Error(
      `Invalid ISO 8601 string or EpochMilliseconds string|number or Date object: ${value}`,
    );
  }
}

export function to24Hour(hour: number, minute: number, period: TimePeriod): string {
  const minuteFormatted = minute < 10 ? `0${minute}` : minute;

  if (period === TimePeriod.AM) {
    return hour === 12
      ? `00:${minuteFormatted}`
      : `${hour < 10 ? '0' + hour : hour}:${minuteFormatted}`;
  } else if (period === TimePeriod.PM) {
    return hour === 12 ? `${hour}:${minuteFormatted}` : `${hour + 12}:${minuteFormatted}`;
  }

  return `${hour < 10 ? `0${hour}` : hour}:${minuteFormatted}`;
}

export function to12Hour(hour: number, minute: number): TimeWithPeriod {
  const minuteFormatted = minute < 10 ? `0${minute}` : minute;

  if (hour === 0) {
    return { time: `${hour + 12}:${minuteFormatted}`, period: TimePeriod.AM };
  } else if (hour >= 1 && hour <= 11) {
    return { time: `${hour < 10 ? '0' + hour : hour}:${minuteFormatted}`, period: TimePeriod.AM };
  } else if (hour === 12) {
    return { time: `${hour}:${minuteFormatted}`, period: TimePeriod.PM };
  }

  return {
    time: `${hour - 12 < 10 ? '0' + (hour - 12) : hour - 12}:${minuteFormatted}`,
    period: TimePeriod.PM,
  };
}

export function transform12hoursDateToISOFormat(
  date: Date,
  hour: Time,
  meridium: Meridium,
): string {
  if (!date || !hour || hour === 'Invalid date') {
    return null;
  }

  const formattedDate = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
  const dateTime = new Date(`${formattedDate} ${hour} ${meridium}`);
  const isoString = new Date(
    `${formattedDate} ${dateTime.getHours()}:${dateTime.getMinutes()} UTC`,
  ).toISOString();
  return isoString;
}

export function getTimeDifference(
  startTime: Time,
  startTimeMd: Meridium,
  endTime: Time,
  endTimeMd: Meridium,
  date: Date,
): Temporal.Duration {
  const start = toTemporalInstant(startTime, startTimeMd, date);
  const end = toTemporalInstant(endTime, endTimeMd, date);

  if (!start || !end) {
    return null;
  }

  return getDurationByHour(start, end);
}

export function toTemporalInstant(time: Time, meridium: Meridium, date: Date): Temporal.Instant {
  const isoString = transform12hoursDateToISOFormat(date, time, meridium);

  if (!isoString) {
    return null;
  }

  return Temporal.Instant.from(isoString);
}

export function areEqualDates(date1: Date, date2: Date, ignoreTime = true): boolean {
  if (ignoreTime) {
    date1?.setHours(0, 0, 0, 0);
    date2?.setHours(0, 0, 0, 0);
  }

  return date1?.getTime() === date2?.getTime();
}

export function formatIsoDateStringToLocalString(isoDate: string, culture: string): string {
  const date = new Date(isoDate).toLocaleString(culture);
  return date;
}

export function getDuration(
  start: Temporal.Instant,
  end: Temporal.Instant,
  unit: DurationUnit,
): Temporal.Duration {
  return start.until(end, { largestUnit: unit });
}

export function getDurationByHour(
  start: Temporal.Instant,
  end: Temporal.Instant,
): Temporal.Duration {
  return getDuration(start, end, 'hour');
}
