/* eslint-disable @typescript-eslint/adjacent-overload-signatures */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable @angular-eslint/no-empty-lifecycle-method */

/* eslint-disable @typescript-eslint/no-empty-function */

/* eslint-disable @angular-eslint/component-selector */

/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/explicit-function-return-type */

/* eslint-disable @typescript-eslint/naming-convention */

/* eslint-disable @angular-eslint/no-output-on-prefix */

/* eslint-disable @angular-eslint/relative-url-prefix */
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

import { Store } from '@ngrx/store';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { AppState } from '@tsq-web/state';

@Component({
  selector: 'tsq-double-column-modal',
  templateUrl: 'tsq-double-column-modal.component.html',
  styleUrls: ['tsq-double-column-modal.component.scss'],
})
export class TsqDoubleColumnModalComponent {
  @ViewChild('modal', { static: true }) modal: ModalDirective;
  @ViewChild('modalDialog', { static: true }) modalDialog: ElementRef;

  @Input() ignoreBackdropClick = false;
  @Input() ignoreEscClick = false;
  @Input() disableBackdrop = false;

  @Input() modalWidth = 700;
  @Input() modalHeight = 500;
  @Input() hasFooter = true;

  @Input() loading = false;

  @Output() modalHide = new EventEmitter();
  @Output() modalHidden = new EventEmitter();

  private action: any;

  constructor(private store: Store<AppState>) {}

  @Input() set isOpen(value: boolean) {
    if (value) {
      this.showModal();
    } else {
      this.closeModal();
    }
  }

  @Input() set backdropAction(value: any) {
    if (!!value) {
      this.action = value;
      this.ignoreBackdropClick = true;
      this.ignoreEscClick = true;
    }
  }

  innerClose() {
    if (!!this.action) {
      if (!this.disableBackdrop) {
        this.store.dispatch(this.action);
      }
    } else {
      this.closeModal();
    }
  }

  showModal() {
    this.modal.show();
  }

  closeModal() {
    this.modal.hide();
  }

  modalHideFired(event: any) {
    this.modalHide.emit(event);
  }

  modalHiddenFired(event: any) {
    this.modalHidden.emit(event);
  }

  @HostListener('click', ['$event'])
  onClick(event: any): void {
    if (
      !this.modalDialog.nativeElement.contains(event.target) &&
      !!this.action &&
      !this.disableBackdrop
    ) {
      this.store.dispatch(this.action);
    }
  }
}
