import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UserRegisterComponent } from './user-register.component';
import { RegisterService } from '../shared/user/register.service';
import { userRegisterRoutes } from './user-register.routes';
import { SCWidgetModule } from '../shared/component/sc.widget.module';
import { TranslateModule } from '@ngx-translate/core';
import { provideRegisterPicUploader } from './register.uploader.provider';
import { FileUploader } from 'ng2-file-upload';
import { CookieService } from 'ngx-cookie-service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SCWidgetModule,
    RouterModule.forChild(userRegisterRoutes),
    TranslateModule,
  ],
  declarations: [UserRegisterComponent],
  exports: [UserRegisterComponent],
  providers: [
    RegisterService,
    { provide: FileUploader, useFactory: provideRegisterPicUploader, deps: [CookieService] },
  ],
})
export class UserRegisterModule {}
