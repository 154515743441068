import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { Store, select } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { Observable, combineLatest, mergeMap, of } from 'rxjs';

import { InviteFlow } from '@tsq-web/administrator';
import { Condo } from '@tsq-web/condo';
import { Feature } from '@tsq-web/feature';
import { LoginService, ReloginGuardUtil } from '@tsq-web/login';
import { Permission, hasFeatureAccess } from '@tsq-web/permission';
import { AppState } from '@tsq-web/state';
import { fromUserContextSelectors } from '@tsq-web/user-context';
import { User } from '@tsq-web/users';

export const residentInvitesGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Observable<boolean | UrlTree> => {
  const store = inject(Store<AppState>);
  const router = inject(Router);
  const loginService = inject(LoginService);
  const cookieService = inject(CookieService);
  const reloginGuardUtil = new ReloginGuardUtil(loginService, cookieService, router, store);

  return combineLatest([
    store.pipe(select(fromUserContextSelectors.selectUser)),
    store.pipe(select(fromUserContextSelectors.selectCondo)),
  ]).pipe(
    mergeMap(([user, condo]: [User, Condo]) => {
      if (!user) {
        return reloginGuardUtil.reloginOverRoute(route, state);
      }

      const isAdmin = hasFeatureAccess(user, condo, Feature.Neighbors, Permission.ADMIN);
      const hasInvitesListEnabled =
        isAdmin &&
        (!condo.administrator || condo.administrator.settings.inviteFlow === InviteFlow.STANDALONE);

      if (!hasInvitesListEnabled) {
        return of(router.createUrlTree(!!condo?.id ? ['w', condo.id, 'residents', 'list'] : ['']));
      }

      return of(true);
    }),
  );
};
