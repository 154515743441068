import { FileMeta } from '@tsq-web/files';
import { User } from '@tsq-web/users';

import { CommentTag } from './comment-tag.model';

export class TSqCommentPresentation {
  id?: string;
  createdBy: User;
  text: string;
  attachments: FileMeta[];
  generated: boolean;
  creationDate: string;
  edited?: boolean;
  isDeleted?: boolean;
  statusIcon?: string;
  reason?: string;
  deletedBy?: string;
  tags?: CommentTag[];
}
