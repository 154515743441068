<div class="toast-container-div">
  <div class="bg-yellow-n1">
    <tsq-icon [icon]="icons.triangleExclamation" [classes]="'text-yellow w-40 h-40'" />
  </div>
  <div class="info-container">
    <span class="title">{{ title }}</span>
    <span *ngIf="!!message && !enableHtml">{{ message }}</span>
    <span *ngIf="!!message && enableHtml" [innerHtml]="message"></span>
  </div>
  <div>
    <button (click)="onRemove()">
      <tsq-icon [icon]="icons.xmark" [classes]="'text-yellow w-16 h-16 top-16 right-16 absolute'" />
    </button>
  </div>
</div>
