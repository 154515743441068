import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { getTSqEnvironment } from '@tsq-web/environment';
import { AppType } from '@tsq-web/core';

@Component({
  selector: 'tsq-comment-list',
  templateUrl: './tsq-comment-list.component.html',
  styleUrls: ['./tsq-comment-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TSqCommentListComponent implements DoCheck {
  @Input() title: string;
  @Input() zeroDataDescription: string;
  @Input() length: number;
  @Input() loading: boolean;
  @Input() loadingMore: boolean;
  @Input() error: boolean;

  @Output() retry = new EventEmitter();

  hasScroll: boolean;

  constructor(private cd: ChangeDetectorRef) {}

  ngDoCheck(): void {
    const el = this.scrollableEl;
    const draft = !!el && el.scrollHeight > el.clientHeight;
    if (draft !== this.hasScroll) {
      this.hasScroll = draft;
      this.cd.markForCheck();
    }
  }

  onScrollBottom(): void {
    const el = this.scrollableEl;
    if (!!el) {
      el.scrollTop = el.scrollHeight;
    }
  }

  private get scrollableEl(): Element {
    const containerClass =
      getTSqEnvironment().appType === AppType.COMMUNITIES
        ? '.root-scrollable-content'
        : '.content-container';

    return document.querySelector(containerClass);
  }
}
