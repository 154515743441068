<div class="toast-container-div">
  <div>
    <tsq-icon [icon]="libIcons.checkCircleFill" [classes]="'w-40 h-40'" />
  </div>
  <div class="info-container">
    <span class="title" id="toast--span--title" data-cy="toast-success--span--title">
      {{ title }}
    </span>
    <span *ngIf="!!message" id="toast--span--message">{{ message }}</span>
  </div>
  <div>
    <button (click)="onRemove()">
      <tsq-icon [icon]="libIcons.xmark" [classes]="'w-16 h-16 top-16 right-16 absolute'" />
    </button>
  </div>
</div>
