<div
  data-testid="general-rating-step--div--general-container"
  class="font-base flex flex-col justify-center p-16 pt-56 text-center"
  [ngClass]="isFeedbackPositive === undefined ? 'items-center' : 'items-start'"
>
  <tsq-icon [icon]="starIcon" [classes]="'w-40 h-40 mb-8'" />
  <h2 class="text-xl">{{ 'IN_APP_FEEDBACK.ENJOYING_TOWNSQ' | translate }}</h2>
  <p class="text-coal-secondary mt-4">
    {{ 'IN_APP_FEEDBACK.YOUR_FEEDBACK_IS_IMPORTANT' | translate }}
  </p>

  <div class="mt-16 flex justify-center gap-16">
    <button
      class="border-1 border-petro-n2 rounded-full bg-white p-16 transition focus:outline"
      data-testid="general-rating-step--button--positive"
      [class.bg-primary]="isFeedbackPositive"
      (click)="rateButtonClicked.emit({ isPositive: true })"
    >
      <tsq-icon
        [icon]="libsIcons.thumbsUp"
        [classes]="isFeedbackPositive ? 'text-white' : 'text-primary'"
      />
    </button>
    <button
      class="border-1 border-petro-n2 rounded-full bg-white p-16 transition focus:outline"
      data-testid="general-rating-step--button--negative"
      [class.bg-primary]="isFeedbackPositive === false"
      (click)="rateButtonClicked.emit({ isPositive: false })"
    >
      <tsq-icon
        [icon]="libsIcons.thumbsDown"
        [classes]="isFeedbackPositive === false ? 'text-white' : 'text-primary'"
      />
    </button>
  </div>

  <button
    *ngIf="isFeedbackPositive === undefined"
    class="text-primary mt-40 block font-bold"
    (click)="closeModal.emit()"
  >
    {{ 'IN_APP_FEEDBACK.ANSWER_LATER' | translate }}
  </button>
</div>
