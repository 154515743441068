/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tsq-gauge',
  templateUrl: 'tsq-gauge.component.html',
  styleUrls: ['tsq-gauge.component.scss'],
})
export class TSqGaugeComponent implements OnInit {
  @Input() percentage = 0;
  @Input() money = 0;

  position = 0;
  animationTimer: any;
  steps = 100;
  deg: number = 360 / this.steps;

  // #B2EF4C - 178, 239, 76
  // #7EBC0A - 126, 188, 10

  pointerRed: number = (126 - 178) / this.steps;
  pointerGreen: number = (188 - 239) / this.steps;
  pointerBlue: number = (10 - 76) / this.steps;

  pointers: any[] = [];

  ngOnInit() {
    const maxSteps: number = this.steps * (this.percentage / 100);
    this.pointers = [];

    while (this.position <= maxSteps) {
      this.animationGauge();
    }
  }

  getColorPointer() {
    return (
      'rgb(' +
      (this.pointerRed * this.position + 178) +
      ', ' +
      (this.pointerGreen * this.position + 239) +
      ', ' +
      (this.pointerBlue * this.position + 76) +
      ')'
    );
  }

  animationGauge() {
    const secund: number = 0.5 + this.position * 0.02;
    const pointer: any = {
      'z-index': this.position,
      transform: 'rotate(' + this.position * this.deg + 'deg)',
      background:
        'radial-gradient(circle at 50% 2.2%, ' + this.getColorPointer() + ' 2.5%, transparent 0%)',

      animation: 'signup-response ' + secund + 's 1',
      '-webkit-animation': 'signup-response ' + secund + 's 1',
      'animation-fill-mode': 'forwards',
      'animation-delay': secund + 's',
      '-webkit-animation-delay': secund + 's',
      '-webkit-animation-fill-mode': 'forwards',
    };

    this.pointers.push(pointer);

    this.position++;
  }
}
