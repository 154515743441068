import { Component, Input, Output, EventEmitter } from '@angular/core';

import { Icon } from '../../utils/icons.utils';

@Component({
  selector: 'tsq-icon-picker-item',
  templateUrl: './tsq-icon-picker-item.component.html',
  styleUrls: ['./tsq-icon-picker-item.component.scss'],
})
export class TSqIconPickerItemComponent {
  @Input() color: string;
  @Input() icon: Icon;
  @Output() iconChange = new EventEmitter<Icon>();

  selectIcon(): void {
    this.iconChange.emit(this.icon.selected ? undefined : this.icon);
  }
}
