import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { LetModule } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';

import { AssetsModule } from '@tsq-web/assets';
import { TSqFormsModule } from '@tsq-web/forms';
import { TSqGenericComponentsModule } from '@tsq-web/generic-components';
import { TSqLoadersModule } from '@tsq-web/loaders';
import { UserRecoveryService } from '@tsq-web/users';

import { SCWidgetModule } from '../shared/component/sc.widget.module';
import { InvalidTokenComponent } from './components/invalid-token/invalid-token.component';
import { LinkSentCardComponent } from './components/link-sent-card/link-sent-card.component';
import { NewPasswordFormComponent } from './components/new-password-form/new-password-form.component';
import { RequestLinkCardComponent } from './components/request-link-card/request-link-card.component';
import { ChangePasswordComponent } from './containers/change-password/change-password.component';
import { RequestPasswordRecoveryComponent } from './containers/request-password-recovery/request-password-recovery.component';
import { ResetYourPasswordCardComponent } from './reset-your-password-card/reset-your-password-card.component';
import { ResetPasswordService } from './services/reset-password.service';
import { ResetPasswordStore } from './state/reset-password.store';
import { UserRecoveryFormComponent } from './user-recovery-form/user-recovery-form.component';
import { UserRecoveryComponent } from './user-recovery.component';
import { userRecoveryRoutes } from './user-recovery.routes';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SCWidgetModule,
    RouterModule.forChild(userRecoveryRoutes),
    TranslateModule,
    TSqFormsModule,
    TSqGenericComponentsModule,
    TSqLoadersModule,
    LetModule,
    AssetsModule,
  ],
  declarations: [
    UserRecoveryComponent,
    UserRecoveryFormComponent,
    ResetYourPasswordCardComponent,
    RequestPasswordRecoveryComponent,
    RequestLinkCardComponent,
    LinkSentCardComponent,
    ChangePasswordComponent,
    NewPasswordFormComponent,
    InvalidTokenComponent,
  ],
  exports: [UserRecoveryComponent],
  providers: [UserRecoveryService, ResetPasswordStore, ResetPasswordService],
})
export class UserRecoveryModule {}
