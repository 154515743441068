<ng-template #modalContent>
  <div class="modal-header">
    <div>
      <img [src]="icon || './assets/libs/icons/delete.svg'"
           class="filter-delete-error" alt="">
      <span>{{ title | translate }}</span>
    </div>
  </div>

  <div class="modal-body-content">
    <div class="text-bold-title" *ngIf="!!description">{{ description | translate }}</div>
    <div class="tsq-card">
      <ng-content></ng-content>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-danger-alternative"
            (click)="cancel.emit()">
      {{ (cancelCustomText || 'SHARED.TSQ_DEFAULT_CANCEL_CONFIRMATION') | translate }}
    </button>
    <button class="btn btn-danger"
            (click)="confirm.emit()">
      {{ (confirmCustomText || 'SHARED.TSQ_DEFAULT_CONFIRMATION_CONFIRMATION') | translate }}
    </button>
  </div>
</ng-template>
