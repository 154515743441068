/* eslint-disable @typescript-eslint/naming-convention */
import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { Designator } from '../models/designator.enum';

enum InflectionType {
  Singular = 0,
  Plural = 1,
}

@Pipe({
  name: 'formatDuration',
})
export class FormatDurationPipe implements PipeTransform {
  private readonly keys = {
    [Designator.Period]: [
      {
        D: 'DAY',
        M: 'MONTH',
        W: 'WEEK',
        Y: 'YEAR',
      },
      {
        D: 'DAYS',
        M: 'MONTHS',
        W: 'WEEKS',
        Y: 'YEARS',
      },
    ],
    [Designator.Time]: [
      {
        H: 'HOUR',
        M: 'MINUTE',
        S: 'SECOND',
      },
      {
        H: 'HOURS',
        M: 'MINUTES',
        S: 'SECONDS',
      },
    ],
  };

  constructor(private translateService: TranslateService) {}

  transform(value: string): string {
    if (!/[+-]?P(\d+(\.\d*)?[YMWD])*(T(\d+(\.\d*)?[HMS])*)?/.test(value)) {
      throw new Error('Not a ISO 8601 duration.');
    }

    const periodRepresentations = value.match(/P(\d+(\.\d*)?[YMWD])+/);
    const periodLangSensitiveRepresentations = !!periodRepresentations
      ? this.getLangSensitiveRepresentations(
          periodRepresentations[0],
          /\d+(\.\d*)?[YMWD]/g,
          Designator.Period,
        )
      : [];

    const timeRepresentations = value.match(/T(\d+(\.\d*)?[HMS])+/);
    const timeLangSensitiveRepresentations = !!timeRepresentations
      ? this.getLangSensitiveRepresentations(
          timeRepresentations[0],
          /\d+(\.\d*)?[HMS]/g,
          Designator.Time,
        )
      : [];

    return [...periodLangSensitiveRepresentations, ...timeLangSensitiveRepresentations].join(' ');
  }

  private getLangSensitiveRepresentations(
    representation: string,
    durationsRegExp: RegExp,
    type: Designator.Period | Designator.Time,
  ): string[] {
    return representation
      .substring(1)
      .match(durationsRegExp)
      .map(duration => {
        const interval = this.getInterval(duration);
        const designator = this.getDesignator(duration);
        const inflection = this.getInflection(interval);

        const key = this.keys[type][inflection][designator];

        if (!!key) {
          const langSensitiveRepresentation = this.translateService.instant(
            `LIBS.DATE_TIME.${key}`,
          );

          return `${interval} ${langSensitiveRepresentation}`;
        }

        return `${interval} ${key}`;
      });
  }

  private getInterval(duration: string): number {
    return parseInt(duration.substring(0, duration.length - 1), 10);
  }

  private getDesignator(duration: string): string {
    return duration.substring(duration.length - 1);
  }

  private getInflection(invertal: number): InflectionType {
    return invertal === 1 ? InflectionType.Singular : InflectionType.Plural;
  }
}
