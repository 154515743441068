/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Injectable } from '@angular/core';

@Injectable()
export class TSqAnalyticsService {
  sendAnalyticsEvent(event: AnalyticsEvent) {
    const gaEvent: Record<string, string | number> = { event_category: event.eventCategory };
    if (!!event.eventLabel) {
      gaEvent.event_label = event.eventLabel;
    }
    if (event.eventValue !== undefined && event.eventValue !== null) {
      gaEvent.value = event.eventValue;
    }
    (window as any).gtag('event', event.eventAction, gaEvent);
  }
}

export interface AnalyticsEvent {
  eventAction: string;
  eventCategory: string;
  eventLabel?: string;
  eventValue?: number;
}
