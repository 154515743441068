import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { Observable, first } from 'rxjs';

import { HttpService } from '@tsq-web/core';
import { ModalService, ModalTypes } from '@tsq-web/modals';

import { getFeatureToggle } from '../../feature-toggle';

const localStorageItem = 'feedbackTriggerActionsCount';
const minimumTriggerActions = 3;

@Injectable()
export class FeedbackTriggerService extends HttpService {
  private hasAlreadyResponded = false;
  private triggerActionsCount: number;
  private context: string;

  private readonly httpClient = inject(HttpClient);
  private readonly modalService = inject(ModalService);
  private readonly inAppFeedbackFeatureToggle = getFeatureToggle().inAppFeedback;

  constructor() {
    super();
    this.triggerActionsCount = parseInt(localStorage.getItem(localStorageItem)) || 0;
  }

  get isAbleToRespond(): boolean {
    return !this.hasAlreadyResponded && this.triggerActionsCount >= minimumTriggerActions;
  }

  get currentContext(): string {
    return this.context;
  }

  triggerFeedback(context: string): void {
    this.triggerActionsCount++;
    localStorage.setItem(localStorageItem, this.triggerActionsCount.toString());

    if (!this.isAbleToRespond || !this.inAppFeedbackFeatureToggle) {
      return;
    }

    this.getIsEligibleForFeedback()
      .pipe(first())
      .subscribe(response => {
        if (response.eligibleForFeedback) {
          this.context = context;
          this.modalService.open(ModalTypes.InAppFeedbackModal);
        }
        this.hasAlreadyResponded = true;
      });
  }

  getIsEligibleForFeedback(): Observable<{ eligibleForFeedback: boolean }> {
    return this.httpClient.get<{ eligibleForFeedback: boolean }>(
      this.getFullUrl('feedback/is-eligible', 'v1'),
    );
  }
}
