import { Component } from '@angular/core';

import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

import { icons } from '@tsq-web/assets';

@Component({
  selector: 'tsq-toast-warning',
  templateUrl: './toast-warning.component.html',
  styleUrls: ['./toast-warning.component.scss'],
})
export class ToastWarningComponent extends Toast {
  readonly icons = icons;

  private _enableHtml: boolean;

  constructor(protected toastrService: ToastrService, public toastPackage: ToastPackage) {
    super(toastrService, toastPackage);
    this.enableHtml = toastPackage.config.enableHtml;
  }

  onRemove(): void {
    this.toastPackage.triggerAction({
      action: 'close',
      title: this.title,
    });
    this.remove();
  }

  set enableHtml(value: boolean) {
    this._enableHtml = value;
  }

  get enableHtml(): boolean {
    return this._enableHtml;
  }
}
