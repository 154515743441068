<div class="bg-petro-n1 font-base flex h-full justify-center">
  <div class="flex h-full flex-col items-center gap-48">
    <img [src]="logoPath" [alt]="'TOWNSQ_LOGO' | translate" class="mt-120 block w-[176px]" />

    <div class="border-petro-n2 border-1 max-w-[504px] rounded-lg bg-white p-40 pt-32">
      <button
        *ngIf="(currentStep$ | async) === steps.Request"
        class="text-new-primary-p2 hover:text-new-primary-p1 outline-new-primary mb-16 rounded outline-offset-2 focus:outline"
        (click)="goBack()"
      >
        <tsq-icon [icon]="leftArrow" />
      </button>

      <ng-container [ngSwitch]="currentStep$ | async">
        <comm-user-recovery-request-link-card
          *ngSwitchCase="steps.Request"
          [isLoading]="isLoading$ | async"
          [isSso]="isSso$ | async"
          (linkRequested)="requestRecoveryLink($event)"
        />
        <comm-user-recovery-link-sent
          *ngSwitchCase="steps.Success"
          class="mt-40 block"
          [email]="email$ | async"
        />
      </ng-container>
    </div>
  </div>
</div>
