import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { take, timer } from 'rxjs';

@Component({
  selector: 'comm-sso-redirect',
  templateUrl: './sso-redirect.component.html',
})
export class SsoRedirectComponent implements OnInit {
  @Input() ssoError: boolean;
  @Input() ssoAuthenticateLoading: boolean;

  @Output() redirectSso = new EventEmitter<void>();
  @Output() backToLogin = new EventEmitter<void>();

  countdown = 30;
  isRedirecting = false;

  ngOnInit(): void {
    this.startCountdown();
  }

  startCountdown(): void {
    const startInteval = 0;
    const endInteval = 1000;
    const maxTime = 30;

    timer(startInteval, endInteval)
      .pipe(take(this.countdown + 1))
      .subscribe({
        next: value => (this.countdown = maxTime - value),
        complete: () => this.onRedirectSso(),
      });
  }

  onRedirectSso(): void {
    this.isRedirecting = true;
    this.redirectSso.emit();
  }
}
