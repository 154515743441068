import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import resolveConfig from 'tailwindcss/resolveConfig';

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import rootTailwindConfig from '../../../../tailwind-preset/tailwind.config.js';
import { ThemeMode } from '../models/theme-mode.enum';
import { Theme } from '../models/theme.enum';

@Injectable()
export class ThemeService implements OnDestroy {
  readonly fullTailwindConfig = resolveConfig(rootTailwindConfig);

  private observer: MutationObserver;

  private themeSubject$: BehaviorSubject<Theme | null>;
  private modeSubject$: BehaviorSubject<ThemeMode | null>;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.initSubjects();

    this.observer = new MutationObserver((mutations: MutationRecord[]) => {
      mutations.forEach(mutation => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          this.updateSubjects();
        }
      });
    });
    this.observer.observe(document.body, { attributes: true, attributeFilter: ['class'] });
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }

  get theme$(): Observable<Theme> {
    return this.themeSubject$.asObservable().pipe(distinctUntilChanged());
  }

  get mode$(): Observable<ThemeMode> {
    return this.modeSubject$.asObservable().pipe(distinctUntilChanged());
  }

  private get themeClass(): string | undefined {
    const themeList = Object.keys(Theme).map(theme => theme.toLowerCase());

    return Array.from(this.document.body.classList).find(className =>
      new RegExp(`^theme-(${themeList.join('|')})`).test(className),
    );
  }

  private initSubjects(): void {
    this.themeSubject$ = new BehaviorSubject<Theme | null>(this.classToTheme(this.themeClass));
    this.modeSubject$ = new BehaviorSubject<ThemeMode | null>(this.classToMode(this.themeClass));
  }

  private updateSubjects(): void {
    this.themeSubject$.next(this.classToTheme(this.themeClass));
    this.modeSubject$.next(this.classToMode(this.themeClass));
  }

  private classToTheme(className: string): Theme | null {
    let theme: Theme;

    Object.keys(Theme).forEach(key => {
      if (className?.toLowerCase().includes(key.toLowerCase())) {
        theme = key as Theme;
      }
    });

    return theme;
  }

  private classToMode(className: string): ThemeMode | null {
    if (!this.classToTheme(className)) {
      return null;
    }

    return className.toLowerCase().includes('dark') ? ThemeMode.Dark : ThemeMode.Light;
  }
}
