/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'menu-item-group',
  templateUrl: 'menu-item-group.component.html',
})
export class MenuItemGroupComponent {
  @Input() icon: string;
  @Input() label: string;
  @Input() isCollapsed: boolean;
  @Output() toggleClick = new EventEmitter();

  toggleCollapse() {
    this.toggleClick.emit();
  }

  displayChildren() {
    if (this.isCollapsed) return 'block';
    return 'none';
  }
}
