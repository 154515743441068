export class AisAccountRetrieval {
  account: string;
  zip: string;
  firstName?: string;
  lastName: string;
  company?: string;

  static from(
    account: string,
    zip: string,
    firstName: string,
    lastName: string,
    company: string,
  ): AisAccountRetrieval {
    const newAisAccTrie = new AisAccountRetrieval();
    newAisAccTrie.account = account;
    newAisAccTrie.zip = zip;
    newAisAccTrie.firstName = firstName;
    newAisAccTrie.lastName = lastName;
    newAisAccTrie.company = company;
    return newAisAccTrie;
  }
}
