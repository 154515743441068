import moment from 'moment-timezone';

import { TimePeriod } from '../models/time-period.enum';

export interface TimeAndPeriod {
  time: string;
  period: TimePeriod;
}

/**
 * @deprecated Use `to24Hour` from date-time lib instead
 * @param hours 1-12
 * @param minutes 0-59
 * @param period AM or PM
 * @returns 00:00-23:59
 */
export function convertToMilitary(hours: number, minutes: number, period: TimePeriod): string {
  const minutesFormatted = minutes < 10 ? `0${minutes}` : minutes;

  if (period === TimePeriod.AM) {
    return hours === 12
      ? `00:${minutesFormatted}`
      : `${hours < 10 ? '0' + hours : hours}:${minutesFormatted}`;
  } else if (period === TimePeriod.PM) {
    return hours === 12 ? `${hours}:${minutesFormatted}` : `${hours + 12}:${minutesFormatted}`;
  } else {
    return `${hours < 10 ? `0${hours}` : hours}:${minutesFormatted}`;
  }
}

/**
 * @deprecated Use `to12Hour` from date-time lib instead
 * @param hours 0-23
 * @param minutes 0-59
 * @returns 1-12:00-59 AM or PM
 */
export function convertToAMPM(hours: number, minutes: string): TimeAndPeriod {
  if (hours === 0) {
    return { time: `${hours + 12}:${minutes}`, period: TimePeriod.AM };
  } else {
    if (hours >= 1 && hours <= 11) {
      return { time: `${hours < 10 ? '0' + hours : hours}:${minutes}`, period: TimePeriod.AM };
    } else {
      if (hours === 12) {
        return { time: `${hours}:${minutes}`, period: TimePeriod.PM };
      } else {
        return {
          time: `${hours - 12 < 10 ? '0' + (hours - 12) : hours - 12}:${minutes}`,
          period: TimePeriod.PM,
        };
      }
    }
  }
}

/**
 * @deprecated This function will be removed in the next major release
 * @param time 00:00-23:59
 * @returns true if time is in the correct format
 */
export function testTimeFormat(time: string): boolean {
  return new RegExp('\\d\\d:\\d\\d').test(time);
}

/**
 * @deprecated Use `relativeTime` from date-time lib instead
 * @param inputDate Date to format
 * @param format Format to use if date is older than 3 days
 * @returns Formatted date
 */
export function relativeTime(inputDate: moment.Moment, format: string): string {
  if (!inputDate) {
    return '';
  }

  const threeDaysAgo = moment().subtract(3, 'day');

  if (inputDate.isAfter(threeDaysAgo)) {
    return inputDate.fromNow();
  }
  return inputDate.format(format);
}
