<a *ngFor="let link of links" class="col-md-6 col-sm-12 no-padding">
  <div (click)="linkClicked(link)" class="link-data">
    <div class="col-md-12">
      <img class="site-icon" *ngIf="link.iconUrl" [src]="link.iconUrl" alt="" />
      <p class="site-name" *ngIf="link.siteName">{{ link.siteName }}</p>
    </div>
    <div class="col-md-12 no-padding">
      <img class="col-sm-4 site-image" *ngIf="link.imageUrl" [src]="link.imageUrl" alt="" />
      <div class="col-md-8 no-padding">
        <h4 class="col-sm-12 no-padding">
          <a>{{ link.title }}</a>
        </h4>
        <p class="site-url col-sm-12 no-padding">{{ link.url }}</p>
      </div>
    </div>
    <p class="site-description col-sm-12">
      {{ link.description.length > 95 ? link.description.substr(0, 95) + '...' : link.description }}
    </p>
  </div>
</a>
<sc-modal
  title=""
  [modalCloseable]="true"
  [center]="true"
  [animated]="false"
  (onHide)="resetModalLink()"
  [showFooter]="false"
  #videoModal
>
  <div modal-content>
    <iframe
      *ngIf="modalLink && modalLink.videoUrl"
      width="600"
      height="400"
      class="col-md-12 site-video"
      [src]="getVideoUrl(modalLink)"
      frameborder="0"
      webkitallowfullscreen
      mozallowfullscreen
      allowfullscreen
      #iframe
    >
    </iframe>
  </div>
</sc-modal>
