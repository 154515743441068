<div id="expired-container">
  <img src="./assets/img/icons/logo-white.svg" alt="" />

  <div id="left-content">
    <div>
      <div>
        <img src="./assets/img/icons/robot-o.png" alt="" />
      </div>
      <div>
        <span [innerHtml]="'SUBSCRIPTION.EXPIRED_TRIAL_TITLE_1' | translate"></span>
        <span [innerHtml]="'SUBSCRIPTION.EXPIRED_TRIAL_TITLE_2' | translate"></span>
      </div>
      <p>{{ 'SUBSCRIPTION.EXPIRED_TRIAL_SUBTITLE' | translate }}</p>
    </div>
  </div>

  <div id="right-content">
    <ng-template *ngIf="loading; then loader; else content"></ng-template>

    <ng-template #loader>
      <tsq-loader *ngIf="loading"></tsq-loader>
    </ng-template>

    <ng-template #content>
      <ng-template *ngIf="error; then errorContainer; else info"></ng-template>

      <ng-template #info>
        <div class="tsq-scroll-y">
          <subscription-summary-content
            [subscription]="subscription"
            [multiplierNumber]="multiplierNumber"
            [percentageNumber]="!!subscription ? percentageForCart : undefined"
            [rawValueNumber]="!!subscription ? rawValueForCart : undefined"
            [totalAmount]="!!subscription ? totalSubscriptionValue : undefined"
          ></subscription-summary-content>

          <!--TODO check buildContry-->
          <div class="billing-card">
            <div>
              <img src="./assets/img/icons/discounts.svg" alt="" />
              <span>{{ 'SUBSCRIPTION.OFFER_DETAILS' | translate }}</span>
            </div>
            <div>
              <div class="divider"></div>
              <credit-card-billing-selection-edit
                (usBillingChanged)="onUsBillingChanged($event)"
              ></credit-card-billing-selection-edit>
            </div>
          </div>

          <button
            class="btn btn-primary"
            *ngIf="!sending"
            (click)="onReactivateClicked()"
            [disabled]="!paymentInfoDraft || !paymentInfoDraft.stripe"
          >
            {{ 'SUBSCRIPTION.REACTIVATE_SUBSCRIPTION' | translate }}
          </button>
          <tsq-small-loader *ngIf="sending"></tsq-small-loader>
        </div>
      </ng-template>

      <ng-template #errorContainer>
        <div>Error</div>
      </ng-template>
    </ng-template>
  </div>
</div>
