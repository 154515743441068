<div
  class="dropdown"
  #dropdown="bs-dropdown"
  dropdown
  [container]="container"
  [dropup]="dropUp"
  [tsqDropdown]="control"
  [markInnerAsTouched]="markInnerAsTouched"
  [isDisabled]="disabled"
  [isNewDesign]="isNewDesign"
  [errorMessage]="errorMessage"
  (keydown)="onKeyDown(dropdown, dropdownEl, $event)"
  (keydown.arrowDown)="onKeyArrow(dropdown, dropdownEl, $event)"
  (keydown.arrowUp)="onKeyArrow(dropdown, dropdownEl, $event)"
  #dropdownEl
>
  <div
    dropdownToggle
    #dropdownToggle
    [class]="isNewDesign ? 'new-design' : 'old-design'"
    [tabindex]="tabIndex"
    (keydown.Space)="$event.preventDefault()"
    (keyup.Space)="dropdown.toggle(true)"
    (keyup.Enter)="dropdown.toggle(true)"
    (keyup.Escape)="dropdown.isOpen ? dropdown.toggle(true) : undefined"
    (keydown.Tab)="this.tabKeyEvent.emit()"
  >
    <ng-container *ngIf="!selectedTemplate; else selectedTemplateContainer">
      <span [attr.placeholder]="!selectedItemText">
        {{ !!selectedItemText ? selectedItemText : (placeholder | translate) }}
      </span>
    </ng-container>
    <ng-template #selectedTemplateContainer>
      <span
        [attr.placeholder]="!selectedItemRaw"
        *ngIf="!selectedItemRaw; else selectedItemTemplateContainer"
      >
        {{ placeholder | translate }}
      </span>
      <ng-template #selectedItemTemplateContainer>
        <span>
          <ng-container
            *ngTemplateOutlet="selectedTemplate; context: { $implicit: selectedItemRaw }"
          />
        </span>
      </ng-template>
    </ng-template>

    <div class="flex items-center">
      <tsq-icon
        *ngIf="isCleanable && !!selectedItemText"
        class="text-primary mr-4"
        [icon]="xMarkIcon"
        (click)="clearSelection(); $event?.stopPropagation()"
      />
      <tsq-icon [icon]="caretIcon" [classes]="iconClasses" />
    </div>
  </div>
  <ul
    *dropdownMenu
    class="dropdown-menu"
    [ngClass]="{ 'limit-height overflow-scroll': limitItemListHeight, 'translate-y-8': dropUp }"
    [style.width.px]="menuWidthPx ?? undefined"
    [attr.has-container]="!!container || null"
  >
    <li *ngFor="let item of items; let index = index">
      <a
        tabindex="0"
        (keydown.Space)="$event.preventDefault()"
        (keyup.Space)="onSelectItem(dropdown, index)"
        (keyup.Enter)="onSelectItem(dropdown, index)"
        (keyup.Escape)="dropdown.toggle(true); dropdownToggle.focus()"
        (click)="selectItem = index"
      >
        <ng-container *ngIf="!itemTemplate; else itemTemplateContainer">{{
          getItemText(item)
        }}</ng-container>
        <ng-template #itemTemplateContainer>
          <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: item }" />
        </ng-template>
      </a>
    </li>
  </ul>
</div>
