import { NgIf } from '@angular/common';
import { Component, Input, inject } from '@angular/core';

import { LetModule } from '@ngrx/component';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, combineLatest, map } from 'rxjs';

import { TooltipTruncatedDirective } from '../../directives/tooltip-truncated/tooltip-truncated.directive';
import { TableColumnTag, TableColumnTagReturnType } from '../../models/table.model';
import { TableCellBaseComponent } from '../table-cell-base/table-cell-base.component';

@Component({
  standalone: true,
  imports: [NgIf, LetModule, TableCellBaseComponent, TooltipTruncatedDirective],
  selector: 'tsq-table-cell-tag',
  template: `
    <tsq-table-cell-base
      *ngrxLet="{ content: content$, tag: tag$ } as vm"
      [align]="vm.content.align"
      [tsqTooltipTruncated]="el"
    >
      <div
        *ngIf="!!vm.tag?.text"
        class="table-tag small-text truncate rounded py-4"
        [attr.tag-type]="vm.tag?.type"
        #el
      >
        {{ vm.tag?.text }}
      </div>
    </tsq-table-cell-base>
  `,
  styles: [
    `
      :host {
        @apply min-w-0;

        .table-tag {
          line-height: 1.32;
          padding-left: 12px;
          padding-right: 12px;

          &[tag-type='neutral'] {
            @apply bg-petro-n1 border-petro-n2 border-1 text-coal-secondary;
          }

          &[tag-type='info'] {
            @apply bg-kale-n1 text-kale;
          }

          &[tag-type='warning'] {
            @apply bg-yellow-n1 text-yellow;
          }

          &[tag-type='success'] {
            @apply bg-green-n1 text-green;
          }

          &[tag-type='critical'] {
            @apply bg-red-n1 text-red;
          }

          &[tag-type='disabled'] {
            @apply border-1;
            color: #bfbfbf;
            background-color: #f0f0f0;
            border-color: #e5e5e5;
          }
        }
      }
    `,
  ],
})
export class TableCellTagComponent<TRowData> {
  private readonly contentSubject = new BehaviorSubject<
    TableColumnTag<TRowData>['content'] | undefined
  >(undefined);
  private readonly rowDataSubject = new BehaviorSubject<TRowData | undefined>(undefined);
  readonly content$ = this.contentSubject.asObservable();

  private readonly translate = inject(TranslateService);

  readonly tag$: Observable<TableColumnTagReturnType> = combineLatest([
    this.content$,
    this.rowDataSubject.asObservable(),
  ]).pipe(
    map(([content, rowData]) => {
      if (!content || !rowData) {
        return undefined;
      }

      if (typeof content.value === 'function') {
        return content.value(rowData, { translate: this.translate });
      } else if ('key' in content.value && 'transform' in content.value) {
        return content.value.transform(rowData[content.value.key], { translate: this.translate });
      }

      return undefined;
    }),
  );

  @Input()
  set content(value: TableColumnTag<TRowData>['content']) {
    this.contentSubject.next(value);
  }

  @Input()
  set rowData(value: TRowData) {
    this.rowDataSubject.next(value);
  }
}
