/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, OnInit, EventEmitter, Output, Input, OnDestroy } from '@angular/core';

import { FileUploader } from 'ng2-file-upload';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

import { GlobalVariables } from '../../globals';

@UntilDestroy()
@Component({
  selector: 'sc-mass-uploader',
  templateUrl: 'sc-mass-uploader.component.html',
  styleUrls: ['sc-mass-uploader.component.scss'],
})
export class SCMassUploaderComponent implements OnInit, OnDestroy {
  @Input() uploader: FileUploader;
  @Input() fileAreaLabel: string;
  @Input() xlsTemplateName: string;
  @Input() xlsTemplateUrl: string;
  @Input() selfHandleXlsTemplateUrl = true;

  @Output() fileUploaded = new EventEmitter<any>();
  @Output() fileUploadError = new EventEmitter<any>();
  @Output() xlsTemplateUrlClick = new EventEmitter();

  isFileOver = false;
  globalVariables = GlobalVariables;

  private fileOver$ = new Subject<boolean>();

  ngOnInit() {
    this.fileOver$
      .pipe(debounceTime(400), untilDestroyed(this))
      .subscribe(isFileOver => (this.isFileOver = isFileOver));

    this.uploader.onSuccessItem = (item: any, response: any, headers: any) => {
      this.fileUploaded.emit(response);
    };

    this.uploader.onErrorItem = (item: any, response: any, status: any, headers: any) => {
      this.fileUploadError.emit(status);
    };
  }

  ngOnDestroy() {}

  onFileOver(isFileOver: boolean) {
    if (isFileOver && !this.isFileOver) {
      this.isFileOver = isFileOver;
    } else {
      this.fileOver$.next(isFileOver);
    }
  }

  uploadUsersFile() {
    this.uploader.uploadAll();
  }
}
