import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { AssetsModule } from '@tsq-web/assets';

import { TSqChipComponent } from './components/tsq-chip/tsq-chip.component';
import { TSqChipsListComponent } from './components/tsq-chips-list/tsq-chips-list.component';
import { TSqChipsRadioComponent } from './components/tsq-chips-radio/tsq-chips-radio.component';

@NgModule({
  imports: [CommonModule, TranslateModule, AssetsModule],
  declarations: [TSqChipComponent, TSqChipsListComponent, TSqChipsRadioComponent],
  exports: [TSqChipComponent, TSqChipsListComponent, TSqChipsRadioComponent],
})
export class TSqChipsModule {}
