/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'tsq-tooltip',
  templateUrl: 'tsq-tooltip.component.html',
  styleUrls: ['tsq-tooltip.component.scss'],
})
export class TSqTooltipComponent {
  @ViewChild('labelTooltip', { static: true }) labelElement: ElementRef;
  @ViewChild('divTooltip', { static: true }) divElement: ElementRef;

  @Input() hint = '';
  @Input() size = 220;

  showHint = false;
  leftPosition = 0;
  styleLabel: any;
  styleDivLabel: any;

  onMouseOver() {
    this.leftPosition =
      this.labelElement.nativeElement.getBoundingClientRect().left -
      this.divElement.nativeElement.getBoundingClientRect().left +
      this.labelElement.nativeElement.getBoundingClientRect().width / 2 +
      (220 - this.size) / 2;

    this.styleLabel = {
      'background-color': 'white',
      'border-radius': '5px',
      'box-shadow': '8px 11px 12px -4px rgba(0, 0, 0, 0.15)',
      'padding-top': '8px',
      'padding-right': '5px',
      'padding-bottom': '2px',
      'padding-left': '5px',
      'margin-top': '-5px',
      'margin-left': '-5px',
      'z-index': 10,
    };
    this.styleDivLabel = {
      'z-index': 2,
    };

    this.showHint = true;
  }

  onMouseLeave() {
    this.styleLabel = {};
    this.styleDivLabel = {};
    this.leftPosition = 0;
    this.showHint = false;
  }
}
