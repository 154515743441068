export class Pageable<T> {
  content: T[];
  page: number;
  number: number;
  size: number;
  total: number;
  numberOfElements?: number;
  totalPages?: number;
  totalElements?: number;
  cursor?: Cursor;
  empty?: boolean;
  last?: boolean;
}

export class Cursor {
  next: string;
}
