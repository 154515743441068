import { Component, EventEmitter, Input, Output } from '@angular/core';

import { icons } from '@tsq-web/assets';

@Component({
  selector: 'tsq-input-chip',
  templateUrl: './input-chip.component.html',
  styleUrls: ['./input-chip.component.scss'],
})
export class InputChipComponent {
  @Input() text: string;
  @Input() kind: 'active' | 'error' = 'active';

  @Output() clicked = new EventEmitter();

  readonly icons = icons;
}
