<div class="form-group right-margin">
  <tsq-card-for-switch id="private-category-switch">
    <sc-switch id="private-switch" class="right-margin" [(checked)]="privateCategory"></sc-switch>
    <label class="switch-label">{{ 'PRIVATE_CAT' | translate }}</label>
  </tsq-card-for-switch>
</div>

<div *ngIf="privateCategory" class="form-group">
  <label>{{ 'GROUPS.CONFIGURATIONS' | translate }}</label>
</div>

<div *ngIf="privateCategory">
  <div class="form-group group" *ngFor="let group of groups;let i=index">
    <label class="text-info-dark right-margin"><strong>{{ group.name }}</strong></label>
    <sc-button-group [options]="options" [selectedOption]="getGroupOption(group)"
                     (onOptionClick)="onPermissionSelected(group, $event)" #categoryOptions></sc-button-group>
  </div>
</div>
