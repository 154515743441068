/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';

import { of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

import { getEnvironment } from '../../environment';
import { WebPushService } from './web-push.service';
import { PushMessagePayload } from '../models/push-data.model';

@UntilDestroy()
@Injectable()
export class MessagingService implements OnDestroy {
  constructor(private fireMessaging: AngularFireMessaging, private webPushService: WebPushService) {
    fireMessaging.onMessage(payload => this.showNotification(payload as PushMessagePayload));
  }

  ngOnDestroy() {}

  register(email: string) {
    this.fireMessaging.requestToken
      .pipe(
        mergeMap(token => this.webPushService.saveToken(email, token)),
        catchError(e => {
          this.logError(e);

          return of(true);
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  unregister() {
    this.fireMessaging.getToken
      .pipe(
        mergeMap(token => this.fireMessaging.deleteToken(token)),
        catchError(e => {
          this.logError(e);

          return of(true);
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private showNotification(payload: PushMessagePayload) {
    const title = payload.data.title;
    const options = {
      body: payload.data.body,
      icon: '/assets/images/logo-192x192.png',
      data: payload.data,
    };

    navigator.serviceWorker
      .getRegistration('/firebase-cloud-messaging-push-scope')
      .then(registration => registration.showNotification(title, options));
  }

  private logError(error: unknown) {
    if (!getEnvironment().production) {
      console.error(error);
    }
  }
}
