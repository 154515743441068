import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'tsq-chips-radio',
  template: `
      <tsq-chips-list class="display-row"
                      [hasCheck]="false"
                      [options]="options"
                      [selected]="selected"
                      (selectedChange)="selectedChange.emit($event)"></tsq-chips-list>
  `,
})
export class TSqChipsRadioComponent {

  @Input() options: string[];
  @Input() selected: number;
  @Output() selectedChange = new EventEmitter<number>();
}
