<div class="slider-container">
  <div class="slider-years">
    <div class="slider-year" *ngFor="let year of years" [style.width.%]="year.size">
      {{year.name}}
    </div>
  </div>
  <div class="slider-months">
    <div class="slider-month" *ngFor="let month of months" [style.width.%]="month.size">
      {{ "SHORT_MONTH_" + month.name | translate }}
    </div>
  </div>
  <div class="slider-slider">
    <input type="range" multiple min="0" [value]="startValueIndex + ',' + endValueIndex" (change)="onChangeSelection()">
    <div class="slider-fiscal-years" *ngIf="legendsLocal.length > 1">
      <div class="slider-fiscal-year" *ngFor="let legend of legendsLocal" [style.width.%]="legend.size" (click)="onSelectRange(legend)">
        <span *ngIf="legend.date !== '0/0'" class="slider-fiscal-year-line">|</span>
        <span class="slider-fiscal-year-label">{{legend.label}}</span>
      </div>
    </div>
  </div>
</div>