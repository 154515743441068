import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import IMask from 'imask';

import { TSqValidators } from '@tsq-web/forms';
import { BankSlipPaymentInfo } from '../../../shared/subscription/bank-slip-payment-info.json';
import { BoletoBillingChanged } from '../../../shared/subscription/boletoBillingChanged.model';
import { TaxToPayIssService } from '../services/tax-to-pay-iss.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'boleto-billing-selection-edit',
  templateUrl: './boleto-billing-selection-edit.component.html',
  styleUrls: ['./boleto-billing-selection-edit.component.scss'],
})
export class BoletoBillingSelectionEditComponent implements OnInit {
  @Input() isInlineForm = true;
  @Input() bankSlip: BankSlipPaymentInfo;
  @Output() brBillingChanged = new EventEmitter<BoletoBillingChanged>();

  form: UntypedFormGroup;
  boletoChanged: BoletoBillingChanged;

  cpfCnpjMask: IMask.AnyMaskedOptions = {
    mask: [
      {
        mask: '000.000.000-00',
        lazy: false,
      },
      {
        mask: '00.000.000/0000-00',
        lazy: false,
      },
    ],
  };

  phoneMask: IMask.AnyMaskedOptions = {
    mask: [
      {
        mask: '(00) 0000-0000',
        lazy: false,
      },
      {
        mask: '(00) 00000-0000',
        lazy: false,
      },
    ],
  };

  cepMask: IMask.AnyMaskedOptions = {
    mask: '00000-000',
    lazy: false,
  };

  readonly cepServiceError = 'ERRO_SERVIDOR';

  constructor(private taxToPayIssService: TaxToPayIssService) {}

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      condoName: new UntypedFormControl('', [
        Validators.required,
        TSqValidators.hasCharDifferentThanWhitespace,
      ]),
      cpfCnpj: new UntypedFormControl('', [Validators.required, TSqValidators.validCpfCnpj]),
      cep: new UntypedFormControl('', Validators.required),
      number: new UntypedFormControl('', Validators.required),
      complement: new UntypedFormControl('', Validators.nullValidator),
      chargeEmail: new UntypedFormControl('', [
        Validators.required,
        TSqValidators.validMultipleEmails,
      ]),
      phone: new UntypedFormControl('', Validators.required),
      street: new UntypedFormControl('', [
        Validators.required,
        TSqValidators.hasCharDifferentThanWhitespace,
      ]),
      neighborhood: new UntypedFormControl('', [
        Validators.required,
        TSqValidators.hasCharDifferentThanWhitespace,
      ]),
      city: new UntypedFormControl('', [
        Validators.required,
        TSqValidators.hasCharDifferentThanWhitespace,
      ]),
      state: new UntypedFormControl('', [
        Validators.required,
        TSqValidators.hasCharDifferentThanWhitespace,
      ]),
      municipalInscription: new UntypedFormControl(''),
    });

    this.form.valueChanges.subscribe(value => {
      this.boletoChanged = new BoletoBillingChanged();
      this.boletoChanged.bankSlip = this.buildBankSlipObj(value);
      this.boletoChanged.valid = this.isFormValid();
      this.brBillingChanged.emit(this.boletoChanged);
    });

    if (!!this.bankSlip) {
      this.form.controls.condoName.setValue(this.bankSlip.name);
      this.form.controls.cpfCnpj.setValue(this.bankSlip.cpfCnpj);
      this.form.controls.cep.setValue(this.bankSlip.zipCode);
      this.form.controls.number.setValue(this.bankSlip.number);
      this.form.controls.complement.setValue(this.bankSlip.complement);
      this.form.controls.chargeEmail.setValue(this.bankSlip.email);
      this.form.controls.phone.setValue(this.bankSlip.phone);
      this.form.controls.street.setValue(this.bankSlip.street);
      this.form.controls.neighborhood.setValue(this.bankSlip.neighborhood);
      this.form.controls.city.setValue(this.bankSlip.city);
      this.form.controls.state.setValue(this.bankSlip.state);
      this.form.controls.municipalInscription.setValue(this.bankSlip.municipalInscription);
    }

    this.taxToPayIssService.taxToPayIss$.subscribe((taxToPayIss: boolean) => {
      this.municipalInscriptionValidators(taxToPayIss);
    });
  }

  isFormValid(): boolean {
    return this.form.valid && this.isPhoneValid;
  }

  private municipalInscriptionValidators(taxToPayIss: boolean): void {
    if (taxToPayIss) {
      this.form.controls.municipalInscription.setValidators([
        Validators.required,
        TSqValidators.hasCharDifferentThanWhitespace,
      ]);
    } else {
      this.form.controls.municipalInscription.clearValidators();
    }
    this.form.controls.municipalInscription.updateValueAndValidity();
  }

  private get isPhoneValid(): boolean {
    return !(this.form.controls.phone.value as string).includes('_');
  }

  private sanitizeString(raw: string): string {
    return !!raw ? raw.replace(/[.\s\-_()/]+/g, '') : '';
  }

  private buildBankSlipObj(formValue: Record<string, string>): BankSlipPaymentInfo {
    const bankSplip = new BankSlipPaymentInfo();

    bankSplip.name = formValue.condoName;
    bankSplip.cpfCnpj = this.sanitizeString(formValue.cpfCnpj);
    bankSplip.email = formValue.chargeEmail;
    bankSplip.phone = this.sanitizeString(formValue.phone);
    bankSplip.zipCode = this.sanitizeString(formValue.cep);
    bankSplip.street = formValue.street;
    bankSplip.number = formValue.number;
    bankSplip.complement = formValue.complement;
    bankSplip.neighborhood = formValue.neighborhood;
    bankSplip.city = formValue.city;
    bankSplip.state = formValue.state;
    bankSplip.municipalInscription = formValue.municipalInscription;

    return bankSplip;
  }
}
