import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

import { BaseService } from '@tsq-web/core';

import { ChargeDocumentResponse } from '../../+sc-workspace/main-billing/charges-list/models/charge-document-response.model';
import { ChargeDocument } from '../../+sc-workspace/main-billing/charges-list/models/document.model';
import { Boleto } from './boleto.model';
import { ChargeBoletoUpdate } from './charge-boleto-update.model';
import { ChargeStatusUpdate } from './charge-status-update.model';
import { SubscriptionCharge } from './subscription-charge.json';

@Injectable()
export class ChargeService extends BaseService {
  constructor(
    protected cookieService: CookieService,
    protected http: HttpClient,
    protected router: Router,
  ) {
    super(cookieService, http, router);
  }

  createCharge(subscriptionCharge: SubscriptionCharge): Observable<SubscriptionCharge> {
    return this.doPost('charges', subscriptionCharge, 'v1');
  }

  generateFatura(chargeId: string, boleto: Boleto): Observable<SubscriptionCharge> {
    return this.doPost(`charges/${chargeId}/fatura`, boleto, 'v1');
  }

  setInvoiceStatus(
    chargeId: string,
    chargeStatus: ChargeStatusUpdate,
  ): Observable<SubscriptionCharge> {
    return this.doPut(`charges/${chargeId}/status`, chargeStatus, 'v2');
  }

  editBoleto(chargeId: string, chargeBoleto: ChargeBoletoUpdate): Observable<SubscriptionCharge> {
    return this.doPut(`charges/${chargeId}/boleto`, chargeBoleto, 'v1');
  }

  removeCharge(chargeId: string): Observable<SubscriptionCharge> {
    return this.doDelete(`charges/${chargeId}`, 'v1');
  }

  getTaxes(chargeAmount: number): Observable<Map<string, number>> {
    let params: HttpParams = new HttpParams();
    params = params.append('amount', String(chargeAmount));
    return this.doGet(`charges/fatura/tax-rate`, params, 'v1');
  }

  getChargeDocument(
    chargeId: string,
    chargeDocument: ChargeDocument,
  ): Observable<ChargeDocumentResponse> {
    let params: HttpParams = new HttpParams();
    params = params.append('document', chargeDocument);
    return this.doGet(`charges/${chargeId}/document`, params, 'v2');
  }
}
