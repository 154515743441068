import {Permission} from '@tsq-web/permission';
import {UserGroup} from './user-group.model';

export class UserGroupPermission {
  userGroup: UserGroup;
  permission: Permission;

  constructor(group: UserGroup, permission: Permission) {
    this.userGroup = group;
    this.permission = permission;
  }
}
