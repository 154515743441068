import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  inject,
} from '@angular/core';

import { ModalDirective } from 'ngx-bootstrap/modal';

import { icons } from '@tsq-web/assets';

import { FullScreenModalService } from './tsq-fullscreen-modal.service';

@Component({
  selector: 'tsq-fullscreen-modal',
  templateUrl: './tsq-fullscreen-modal.component.html',
  styleUrls: ['./tsq-fullscreen-modal.component.scss'],
  providers: [FullScreenModalService],
})
export class TSqFullscreenModalComponent {
  @ViewChild('scrollContainer', { static: true }) scrollContainer: ElementRef;
  @ViewChild('modal', { static: true }) modal: ModalDirective;

  @Input() scrollTopOnShow = false;
  @Input() icon: string;
  @Input() iconClass: string;
  @Input() title: string;
  @Input() description: string;
  @Input() primaryBtnText = 'SAVE';
  @Input() secondaryBtnText = 'CANCEL';
  @Input() infoWrapperClass = 'default';
  @Input() enablePrimaryBtn = true;
  @Input() enableSecondaryBtn = true;
  @Input() hasBodyPadding = true;
  @Input() enableBack = false;
  @Input() showBack = false;
  @Input() sending = false;
  @Input() loading = false;

  @Output() back = new EventEmitter();
  @Output() closeModal = new EventEmitter();
  @Output() save = new EventEmitter();
  @Output() modalHidden = new EventEmitter();

  readonly libIcons = icons;

  private readonly fullScreenModalService = inject(FullScreenModalService);

  @Input() set isOpen(value: boolean) {
    if (value) {
      this.modal.show();
    } else {
      this.modal.hide();
    }
  }

  onBack(): void {
    if (this.enableBack) {
      this.back.emit();
    }
  }

  onClose(): void {
    this.closeModal.emit();
    this.resetScrollTop();
  }

  onSave(): void {
    this.save.emit();
  }

  modalHiddenFired(): void {
    this.modalHidden.emit();
    this.fullScreenModalService.hidden();
  }

  resetScrollTop(): void {
    if (this.scrollTopOnShow) {
      this.scrollContainer.nativeElement.scrollTop = 0;
    }
  }
}
