import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';

import { Observable, of } from 'rxjs';

import { PublicAisService } from './public-ais.service';

export const aisOwnerSelectionGuard: CanActivateFn = (): Observable<boolean | UrlTree> => {
  const router = inject(Router);
  const publicAisService = inject(PublicAisService);

  if (!!publicAisService.aisAccRetrieval && !!publicAisService.selectedAccount) {
    return of(true);
  }

  return of(router.createUrlTree(['ais', 'sign-up'], { queryParamsHandling: 'merge' }));
};
