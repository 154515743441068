/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import moment from 'moment-timezone';

import { SubscriptionService } from '../../subscription/subscription.service';
import { CondoAndSubscription } from '../../subscription/condo-and-subscription.json';

@Injectable()
export class TrialCondoAuthorizer implements CanActivate {
  constructor(private _router: Router, private _subscriptionService: SubscriptionService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | boolean {
    const condoId = route.params.condoId;
    return this._subscriptionService.getCondoAndSubscription(condoId).pipe(
      map((condoAndSubsDetails: CondoAndSubscription) => {
        const subsDetails = condoAndSubsDetails.subscriptionDetails;
        if (
          !subsDetails?.subscription ||
          (!!subsDetails.subscription && !subsDetails.subscription.trial)
        ) {
          return true;
        }
        const today = moment();
        const expire = moment(subsDetails.subscription.expirationDate);
        const isBefore = expire.isBefore(today, 'day');
        if (subsDetails.subscription.trial && isBefore) {
          this._router.navigate(['expired-trial', condoId]);
          return false;
        }
        return true;
      }),
    );
  }
}
