<div class="input-container" [class.display-none]="!!item">
  <input
    #inputEl
    class="form-control font-base"
    autocomplete="off"
    [class.has-icon]="hasSearchIcon"
    [(ngModel)]="input"
    [placeholder]="placeholder | translate"
    [disabled]="disabled"
    [typeahead]="dataSource$"
    [typeaheadAsync]="true"
    [typeaheadWaitMs]="1000"
    [typeaheadMinLength]="minLength"
    [typeaheadOptionsInScrollableView]="numberOfOptions"
    [typeaheadScrollable]="true"
    [typeaheadLatinize]="true"
    [typeaheadOptionField]="'text'"
    [typeaheadItemTemplate]="itemTemplate"
    [container]="container"
    (typeaheadLoading)="loading = $event; adjustDropdownWidth()"
    (typeaheadOnSelect)="onSelect($event)"
    (typeaheadOnBlur)="onTypeaheadBlur()"
    (typeaheadOnPreview)="adjustDropdownWidth()"
    (blur)="onInputBlur()"
  />

  <img
    *ngIf="hasSearchIcon"
    src="./assets/libs/icons/search.svg"
    class="filter-primary"
    [ngClass]="{ 'filter-delete-error': error, 'filter-coal-disabled': disabled }"
    alt=""
  />

  <div class="loader-container dropdown-menu" *ngIf="loading">
    <tsq-small-loader></tsq-small-loader>
  </div>
</div>

<div class="tsq-card" [class.card-error]="error" *ngIf="!!item">
  <ng-container *ngTemplateOutlet="selectedTemplate; context: selectedContext"></ng-container>
  <img
    src="./assets/libs/icons/delete.svg"
    class="filter-delete-error"
    [class.filter-line]="disabled"
    alt=""
    (click)="onSelect(null)"
  />
</div>

<div class="text-error text-label" *ngIf="error">{{ finalErrorMessage }}</div>
