import { Routes } from '@angular/router';

import { ExpiredSubscriptionTrialComponent } from './expired-subscription-trial.component';

export const expiredSubscriptionTrialRoute: Routes = [
  {
    path: 'expired-trial/:condoId',
    component: ExpiredSubscriptionTrialComponent,
  },
];
