import { ChangeDetectionStrategy, Component } from '@angular/core';

import { TableCellBaseComponent } from '../table-cell-base/table-cell-base.component';

@Component({
  standalone: true,
  imports: [TableCellBaseComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'tsq-table-cell-dummy',
  template: `<tsq-table-cell-base />`,
  styles: [
    `
      :host {
        @apply min-w-0;
      }
    `,
  ],
})
export class TableCellDummyComponent {}
