import { Component, OnInit, Optional, Self } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl } from '@angular/forms';

/**
 * @deprecated tsq-switch component has been deprecated. Use tsqToggle directive instead.
 */
@Component({
  selector: 'tsq-switch',
  templateUrl: './tsq-switch.component.html',
})
export class TSqSwitchComponent implements OnInit, ControlValueAccessor {
  disabled: boolean;
  abstractControl: AbstractControl;

  onChanged: (value: boolean) => void;
  onTouched: () => void;

  constructor(@Optional() @Self() public ngControl: NgControl) {
    ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    this.abstractControl = this.ngControl.control;
  }

  registerOnChange(fn: (value: boolean) => void): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  writeValue(value: boolean): void {}

  toggleSelected(): void {
    if (!this.disabled) {
      this.onChanged(!this.abstractControl.value);
      this.onTouched();
    }
  }
}
