/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, Input } from '@angular/core';
import { User } from '@tsq-web/users';
import { Phone } from '@tsq-web/users';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sc-user-bio',
  templateUrl: 'sc-user-bio.component.html',
  styleUrls: ['sc-user-bio.component.scss'],
})
export class SCUserBioComponent {
  @Input() user: User;

  constructor(private _translateService: TranslateService) {}

  getPhones() {
    return this.user !== undefined ? this.user.phones : new Array<Phone>();
  }

  getCreatedByPicture() {
    return this.user !== undefined ? this.user.pictureUrl : '';
  }

  getCreatedByName() {
    return this.user !== undefined ? `${this.user.first_name} ${this.user.last_name}` : '';
  }

  getCreatedByDesc() {
    return this.getPropertyDescription(this.user);
  }

  getPropertyList() {
    if (this.user !== undefined) return this.user.properties;

    return [];
  }

  getPropertyDescription(user: User): string {
    if (user !== undefined && user.properties.length > 0) {
      return user.properties[0].fine_description;
    }
    return this._translateService.instant('PROPERTY.NO_PROPERTIES');
  }
}
