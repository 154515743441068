<div bsModal
     #modal="bs-modal"
     class="modal"
     tabindex="-1" (keyup.arrowLeft)="previous()" (keyup.arrowRight)="next()">
  <div class="content-wrapper">

    <div class="header">
      <div>{{ currentImage?.name }}</div>

      <div class="image-counter">{{ currentIndex + 1 }} / {{ images?.length }}</div>

      <div>
        <img src="./assets/libs/icons/download.svg"
             class="filter-white"
             [alt]="'LIBS.DOWNLOAD' | translate"
             (click)="download()">
        <img src="./assets/libs/icons/close.svg"
             class="filter-white"
             [alt]="'LIBS.CLOSE' | translate"
             (click)="close()">
      </div>
    </div>

    <div class="content">
      <div class="image-viewer">
        <img [src]="currentImage?.fileUrl"
             [alt]="currentImage?.name"
             (load)="currentLoading = false">

        <tsq-small-loader *ngIf="currentLoading"></tsq-small-loader>
      </div>

      <div class="navigator previous">
        <img *ngIf="currentIndex !== 0"
             src="./assets/libs/icons/arrow.svg"
             class="filter-white"
             [alt]="'LIBS.PREVIOUS' | translate"
             (click)="previous()">
      </div>
      <div class="navigator next">
        <img *ngIf="currentIndex < (images?.length - 1)"
             src="./assets/libs/icons/arrow.svg"
             class="filter-white"
             [alt]="'LIBS.NEXT' | translate"
             (click)="next()">
      </div>
    </div>

  </div>
</div>

<div class="preview-container tsq-scroll-x" *ngIf="!!images?.length">
  <div *ngFor="let image of images; let index = index">
    <tsq-small-loader *ngIf="!hasLoaded(index)"></tsq-small-loader>
    <img [src]="image.fileUrl"
         [alt]="image.name"
         (load)="loadedImage(index)"
         (click)="selectImage(index)">
  </div>
</div>

<tsq-file-card *ngFor="let file of files"
               [file]="file"></tsq-file-card>
