import { Directive, Input, TemplateRef, ViewContainerRef, inject } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, of, switchMap } from 'rxjs';

import { injectFeatureFlagService } from '../dependency-injection/feature-flag';

@UntilDestroy()
@Directive({
  standalone: true,
  selector: '[tsqFeatureFlagEnabled]',
  exportAs: 'tsqFeatureFlagEnabled',
})
export class FeatureFlagEnabledDirective {
  private readonly _flag = new BehaviorSubject<string | undefined>(undefined);
  private readonly _elseTemplate = new BehaviorSubject<TemplateRef<unknown> | null>(null);
  private readonly _fallback = new BehaviorSubject<boolean>(false);

  private readonly vcRef = inject(ViewContainerRef);
  private readonly templateRef = inject(TemplateRef);
  private readonly flags = injectFeatureFlagService();

  constructor() {
    combineLatest([
      this._flag.asObservable(),
      this._elseTemplate.asObservable(),
      this._fallback.asObservable(),
    ])
      .pipe(
        switchMap(([flag, , fallback]) => {
          if (!flag) {
            return of(fallback);
          }

          return this.flags.getValueAsync(flag, fallback);
        }),
        untilDestroyed(this),
      )
      .subscribe(render => {
        this.vcRef.clear();

        if (render) {
          this.vcRef.createEmbeddedView(this.templateRef);
        } else if (!!this._elseTemplate.value) {
          this.vcRef.createEmbeddedView(this._elseTemplate.value);
        }
      });
  }

  @Input()
  set tsqFeatureFlagEnabled(value: string) {
    this._flag.next(value);
  }

  @Input()
  set tsqFeatureFlagEnabledElse(value: TemplateRef<unknown> | null) {
    if (value === null || 'createEmbeddedView' in value) {
      this._elseTemplate.next(value);
    } else {
      console.warn(`tsqFeatureFlagEnabled: 'else' must be a TemplateRef.`);
    }
  }

  @Input()
  set tsqFeatureFlagEnabledFallback(value: boolean) {
    this._fallback.next(value);
  }
}
