/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, Input } from '@angular/core';

import { UserGroup } from '@tsq-web/groups';
import { Permission } from '@tsq-web/permission';
import { UserGroupPermission } from '@tsq-web/groups';
import { SCPermission } from './sc-permissions';

@Component({
  selector: 'sc-category-permission-selection',
  templateUrl: 'sc-category-permission-selection.component.html',
  styleUrls: ['sc-category-permission-selection.component.scss'],
})
export class SCCategoryPermissionSelectionComponent {
  @Input() groups: UserGroup[] = [];
  @Input() options: { value: SCPermission; label: string }[];
  privateCategory = false;
  userGroupPermissions: UserGroupPermission[] = [];

  setupForEdition(privateCategory: boolean, userGroupPermissions: UserGroupPermission[]) {
    this.privateCategory = privateCategory;
    this.userGroupPermissions = userGroupPermissions;
  }

  setupForCreation() {
    this.privateCategory = false;
    this.userGroupPermissions = [];
  }

  onPermissionSelected(group: UserGroup, option: string) {
    const index = this.userGroupPermissions.findIndex(
      (permission: UserGroupPermission) => permission.userGroup.id === group.id,
    );
    if (index !== -1 && option === Permission[Permission.NONE]) {
      this.userGroupPermissions.splice(index, 1);
    } else if (index !== -1) {
      this.userGroupPermissions[index].permission = option as Permission;
    } else if (option !== Permission[Permission.NONE]) {
      const userGroupPermission = new UserGroupPermission(
        new UserGroup(group.id),
        option as Permission,
      );
      this.userGroupPermissions.push(userGroupPermission);
    }
  }

  getGroupOption(group: UserGroup): string {
    const permission = this.userGroupPermissions.find(perm => perm.userGroup.id === group.id);
    if (permission) {
      return permission.permission;
    } else {
      return Permission[Permission.NONE];
    }
  }
}
