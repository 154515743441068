import { createAction, props } from '@ngrx/store';

export const requestUserRecovery = createAction(
  '[Login] Request User Recovery',
  props<{ email: string }>(),
);

export const requestUserRecoverySuccess = createAction(
  '[TownSq API] Request User Recovery Success',
);

export const requestUserRecoveryFailure = createAction(
  '[TownSq API] Request User Recovery Failure',
);

export const restoreUserRecoveryInitialState = createAction(
  '[Login] Restore User Recovery Initial State',
);
