<div class="tsq-typeahead-wrapper" *ngIf="!single || !user">
  <input
    id="user-in-name-of"
    [(ngModel)]="userInput"
    [disabled]="disabled"
    [typeahead]="dataSource$"
    [typeaheadAsync]="true"
    [typeaheadWaitMs]="1000"
    [typeaheadMinLength]="minLength"
    [typeaheadItemTemplate]="optionTemplate"
    [typeaheadScrollable]="true"
    [typeaheadOptionsInScrollableView]="numberOfOptions"
    [typeaheadLatinize]="true"
    (typeaheadOnSelect)="onSelect($event)"
    (typeaheadOnBlur)="onTypeaheadBlur()"
    (typeaheadLoading)="loading = $event"
    (blur)="onInputBlur()"
    (keyup)="onKeyUp($event)"
    class="form-control"
    [placeholder]="'SELECT_USER' | translate"
    autocomplete="off"
    #inputEl
  />

  <div class="loader-container dropdown-menu" *ngIf="loading">
    <tsq-small-loader></tsq-small-loader>
  </div>
</div>

<div class="input-error-label" *ngIf="error">{{ 'MANDATORY_FIELD_ERROR_LABEL' | translate }}</div>

<ng-template #optionTemplate let-user="item">
  <div>{{ userFullName(user) }}</div>
  <div *ngIf="userHasProperty(user)" class="typeahead-item-desc">{{ userPropertyText(user) }}</div>
</ng-template>

<ng-container *ngIf="!!abstractControl && !!abstractControl.value && !selfHandleSelect">
  <div class="tsq-card" [ngClass]="{ 'has-margin-top': !!user && !single }">
    <img [src]="user.pictureUrl" class="img-circle" alt="" />
    <div class="user-info">
      <span>{{ userFullName(user) }}</span>
      <span *ngIf="hasProperties">{{ property | translate }}</span>
    </div>
    <img *ngIf="single" src="./assets/img/icons/delete-error.svg" (click)="onRemove()" alt="" />
  </div>
</ng-container>
