<nav aria-label="page navigation">
  <ul class="page" *ngIf="total > perPage">
    <li class="page-btn" [attr.theme]="theme" [class.disabled]="currentPage - 1 === 0">
      <button (click)="onPageClick(currentPage - 1)">
        <img src="./assets/libs/icons/rounded-angle.svg" alt="" />
      </button>
    </li>

    <ng-container *ngFor="let page of pages">
      <li
        *ngIf="page === pageDirections.Left || page === pageDirections.Right"
        class="page-numbers"
        [attr.theme]="theme"
      >
        <button (click)="handlePageDirection(page)">...</button>
      </li>

      <li
        *ngIf="!(page === pageDirections.Left || page === pageDirections.Right)"
        class="page-numbers"
        [attr.theme]="theme"
        [ngClass]="{ active: currentPage === page }"
      >
        <button (click)="onPageClick(page)">{{ page }}</button>
      </li>
    </ng-container>

    <li class="page-btn" [attr.theme]="theme" [class.disabled]="currentPage === totalPages">
      <button (click)="onPageClick(currentPage + 1)">
        <img class="right-arrow" src="./assets/libs/icons/rounded-angle.svg" alt="" />
      </button>
    </li>
  </ul>

  <div class="showing-out-of" *ngIf="hasItemsCounter">
    {{
      'LIBS.PAGINATOR.SHOWING_OUT_OF' | translate : { inPage: inPage, total: total, entity: entity }
    }}
  </div>
</nav>
