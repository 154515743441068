import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { AssetsModule, icons } from '@tsq-web/assets';

import { communitiesIcons } from '../../../../../assets/dist/communities-icons';

@Component({
  selector: 'comm-general-rating-step',
  templateUrl: './general-rating-step.component.html',
  standalone: true,
  imports: [CommonModule, AssetsModule, TranslateModule],
})
export class GeneralRatingStepComponent {
  @Input() isFeedbackPositive: boolean;

  @Output() rateButtonClicked = new EventEmitter<{ isPositive: boolean }>();
  @Output() closeModal = new EventEmitter<void>();

  readonly libsIcons = icons;
  readonly starIcon = communitiesIcons.star;
}
