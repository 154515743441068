/* eslint-disable @typescript-eslint/naming-convention */
import { Component, ElementRef, Input, OnInit, QueryList, ViewChildren } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AppState } from '@tsq-web/state';
import { fromRouterSelectors } from '@tsq-web/router';
import { SubHeaderTab } from '../../models/sub-header-tab.model';

@UntilDestroy()
@Component({
  selector: 'tsq-sub-header-tabs',
  templateUrl: './sub-header-tabs.component.html',
  styleUrls: ['./sub-header-tabs.component.scss'],
})
export class SubHeaderTabsComponent implements OnInit {
  @Input() tabs: SubHeaderTab[];

  selectedIndex: number;

  private _selectedMarkerStyle: [number, number] = [0, 0];

  private tabsRefList$ = new BehaviorSubject<ElementRef[]>([]);

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    combineLatest([
      this.store.pipe(select(fromRouterSelectors.selectRouterUrl)),
      this.tabsRefList$.asObservable(),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([url, tabsRefList]) => this.updateSelectedMarker(url, tabsRefList));
  }

  @ViewChildren('tabRef') set tabsRefList(value: QueryList<ElementRef>) {
    if (!!value) {
      this.tabsRefList$.next(value.toArray());
    }
  }

  get selectedMarkerStyle(): { 'width.px': number; 'left.px': number } {
    return { 'width.px': this._selectedMarkerStyle[0], 'left.px': this._selectedMarkerStyle[1] };
  }

  private updateSelectedMarker(url: string, tabsRefList: ElementRef[]): void {
    this.tabs.forEach((tab, index) => {
      if (url?.includes(tab.path.replace(/[./]/g, ''))) {
        this.selectedIndex = index;
        this._selectedMarkerStyle = this.getStyleFromTab(tabsRefList[index]);
      }
    });
  }

  private getStyleFromTab(el: ElementRef): [number, number] {
    if (!!el) {
      const tabEl = el.nativeElement as HTMLElement;

      return [tabEl.clientWidth, tabEl.offsetLeft];
    }

    return [0, 0];
  }
}
