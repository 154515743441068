<comm-confirmation-dialog id="summary-modal"
                          [message]="'SUBSCRIPTION.SUMMARY_DESCRIPTION'"
                          [headerImg]="'robot-glasses-border'"
                          [confirmationLabel]="'SUBSCRIPTION.YES_CHANGE_MY_PLAN'"
                          [cancelLabel]="'SUBSCRIPTION.NO_CANCEL'"
                          (confirmAction)="onConfirmClicked()"
                          #summaryModal>

  <div confirmation-content *ngIf="!!subscription">

    <subscription-summary-content [subscription]="subscription"
                                  [multiplierNumber]="multiplierNumber"
                                  [percentageNumber]="percentageNumber"
                                  [rawValueNumber]="rawValueNumber"
                                  [totalAmount]="totalAmount"></subscription-summary-content>

  </div>

</comm-confirmation-dialog>
