import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { AssetsModule, icons } from '@tsq-web/assets';
import { TSqGenericComponentsModule } from '@tsq-web/generic-components';

@Component({
  selector: 'comm-thank-you-step',
  templateUrl: './thank-you-step.component.html',
  standalone: true,
  imports: [CommonModule, TranslateModule, TSqGenericComponentsModule, AssetsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThankYouStepComponent {
  @Input() isFeedbackPositive: boolean;

  @Output() openArticle = new EventEmitter<void>();
  @Output() closeModal = new EventEmitter<void>();

  readonly checkDoubleIcon = icons.checkDouble;
}
