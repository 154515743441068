import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  inject,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject, combineLatest, merge, mergeMap, startWith } from 'rxjs';

import { FilterGroupItemDirective } from '../../directives/filter-group-item/filter-group-item.directive';

@UntilDestroy()
@Component({
  selector: 'tsq-filter-group',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="flex items-end gap-16" #gridContainer>
      <ng-content select="div[tsqFilterGroupItem]" />

      <tsq-filter-button
        class="ml-auto whitespace-nowrap"
        [text]="'LIBS.FILTER_GROUP.ALL_FILTERS' | translate"
        [count]="activeFiltersCount"
        (clicked)="allFiltersClick.emit()"
        #allFilters
      />
    </div>
  `,
})
export class FilterGroupComponent implements AfterContentInit, AfterViewInit, OnDestroy {
  @ViewChild('gridContainer', { static: true }) gridContainer: ElementRef<HTMLDivElement>;
  @ViewChild('allFilters', { static: true, read: ElementRef }) allFilters: ElementRef<HTMLElement>;

  @ContentChildren(FilterGroupItemDirective) itemsQueryList: QueryList<FilterGroupItemDirective>;

  @Input() activeFiltersCount: number;
  @Input() allFiltersAlwaysVisible = false;

  @Output() readonly allFiltersClick = new EventEmitter<void>();

  private readonly contentInitSubject = new Subject<void>();
  private readonly viewInitSubject = new Subject<void>();
  private readonly gridContainerResizeSubject = new Subject<void>();

  private readonly gridContainerObserver = new ResizeObserver(() => {
    this.gridContainerResizeSubject.next();
  });

  private readonly renderer = inject(Renderer2);

  constructor() {
    combineLatest([this.contentInitSubject.asObservable(), this.viewInitSubject.asObservable()])
      .pipe(
        mergeMap(() => {
          return merge(
            this.itemsQueryList.changes,
            this.gridContainerResizeSubject.asObservable(),
          ).pipe(startWith(undefined));
        }),
        untilDestroyed(this),
      )
      .subscribe(() => {
        if (!this.gridContainer || !this.allFilters || !this.itemsQueryList) {
          return;
        }

        const containerWidth = Math.floor(
          this.gridContainer.nativeElement.getBoundingClientRect().width,
        );
        const totalItems = this.itemsQueryList.length;
        const maxItemsPerRow = totalItems > 4 ? 4 : totalItems;
        const minItemWidth = 200;
        const maxItemWidth = 400;
        const gap = 16;

        const allFiltersButtonWidth = this.getAllFiltersButtonWidth(
          gap,
          this.allFilters.nativeElement,
        );

        const maxAvailableWidth =
          this.calculateAvailableWidth(containerWidth, gap, maxItemsPerRow) -
          (this.allFiltersAlwaysVisible ? allFiltersButtonWidth : 0);

        const maxItemsInMaxAvailableWidth = this.calculateMaxItemsInWidth(
          this.itemsQueryList.toArray(),
          maxAvailableWidth,
          minItemWidth,
          maxItemsPerRow,
        );

        const isShowingAllItems = maxItemsInMaxAvailableWidth === totalItems;

        const itemsPerRow =
          isShowingAllItems || this.allFiltersAlwaysVisible
            ? maxItemsInMaxAvailableWidth
            : this.calculateMaxItemsInWidth(
                this.itemsQueryList.toArray(),
                maxAvailableWidth - allFiltersButtonWidth,
                minItemWidth,
                maxItemsPerRow,
              );

        const finalAvailableWidth =
          this.calculateAvailableWidth(containerWidth, gap, itemsPerRow) -
          (isShowingAllItems || this.allFiltersAlwaysVisible ? 0 : allFiltersButtonWidth);

        const itemAutoWidth = this.calculateItemMaxAutoWidth(
          this.itemsQueryList.toArray(),
          finalAvailableWidth,
          itemsPerRow,
          maxItemWidth,
        );

        this.itemsQueryList.forEach((item, index) => {
          const el = item.el;

          this.renderer.setStyle(el, 'min-height', '40px');

          if (index < itemsPerRow) {
            const width = item.width === 'auto' ? itemAutoWidth : item.width;

            this.renderer.setStyle(el, 'flex-basis', `${width}px`);
            this.renderer.removeStyle(el, 'display');
          } else {
            this.renderer.setStyle(el, 'display', 'none');
          }
        });

        this.renderer.setStyle(
          this.allFilters.nativeElement,
          'display',
          this.allFiltersAlwaysVisible || totalItems > itemsPerRow ? 'block' : 'none',
        );
      });
  }

  ngAfterContentInit(): void {
    this.contentInitSubject.next();
  }

  ngAfterViewInit(): void {
    this.gridContainerObserver.observe(this.gridContainer.nativeElement);
    this.viewInitSubject.next();
  }

  ngOnDestroy(): void {
    this.gridContainerObserver.disconnect();
  }

  private calculateAvailableWidth(
    containerWidth: number,
    gap: number,
    itemsPerRow: number,
  ): number {
    return containerWidth - gap * (itemsPerRow - 1);
  }

  private calculateMaxItemsInWidth(
    items: FilterGroupItemDirective[],
    containerWidth: number,
    minItemWidth: number,
    maxItems: number,
  ): number {
    let accItems = 0;
    let accWidth = 0;

    items.every(item => {
      const itemWidth = item.width === 'auto' ? minItemWidth : item.width;

      if (accWidth + itemWidth > containerWidth) {
        return false;
      }

      accItems += 1;
      accWidth += itemWidth;

      return true;
    });

    return accItems > maxItems ? maxItems : accItems;
  }

  private calculateItemMaxAutoWidth(
    allItems: FilterGroupItemDirective[],
    containerWidth: number,
    itemsPerRow: number,
    maxItemWidth: number,
  ): number {
    const items = allItems.slice(0, itemsPerRow);
    const numberOfAutoWidthItems = items.filter(item => {
      return item.width === 'auto';
    }).length;
    const accWidthOfFixedWidthItems = items
      .map(item => {
        return item.width;
      })
      .filter((width): width is number => {
        return typeof width === 'number';
      })
      .reduce((acc, curr) => {
        return acc + curr;
      }, 0);

    const itemMaxAutoWidth = Math.floor(
      (containerWidth - accWidthOfFixedWidthItems) / numberOfAutoWidthItems,
    );

    return itemMaxAutoWidth > maxItemWidth ? maxItemWidth : itemMaxAutoWidth;
  }

  private getAllFiltersButtonWidth(gap: number, allFiltersEl: HTMLElement): number {
    const curAllFiltersElDisplay = allFiltersEl.style.display;

    this.renderer.setStyle(allFiltersEl, 'display', 'block');

    const allFiltersWidth = Math.ceil(allFiltersEl.getBoundingClientRect().width);

    this.renderer.setStyle(allFiltersEl, 'display', curAllFiltersElDisplay);

    return gap + allFiltersWidth;
  }
}
