import {
  AfterViewInit,
  Directive,
  ElementRef,
  OnDestroy,
  Optional,
  Renderer2,
  Self,
} from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';

import { startWith } from 'rxjs/operators';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

import { ElementManipulationService } from '../services/element-manipulation.service';

@UntilDestroy()
@Directive({
  selector: 'input[tsqSearchInput]',
})
export class TSqSearchInputDirective implements AfterViewInit, OnDestroy {
  private control: AbstractControl;

  private imgElRef: Element;
  private listenFunc: () => void;

  constructor(
    private renderer: Renderer2,
    private elRef: ElementRef,
    @Optional() @Self() private ngControl: NgControl,
    private elManipulationService: ElementManipulationService,
  ) {}

  private listener = (): void => {
    if (!!this.control.value) {
      this.control.setValue('');
    }
  };

  ngAfterViewInit(): void {
    if (!!this.ngControl) {
      this.control = this.ngControl.control;

      this.renderer.addClass(this.elRef.nativeElement, 'has-icon');

      this.wrapInput();
      this.addIcon();

      this.control.valueChanges
        .pipe(startWith(this.control.value as string), untilDestroyed(this))
        .subscribe(value => this.setIcon(!!value ? 'cancel' : 'search'));
    } else {
      console.warn(
        'tsqSearchInput directive needs an Abstract Control so it can listen to changes and behave as expected.' +
          '\n' +
          "You didn't provide one, so tsqSearchInput won't initialise for this element.",
      );
    }
  }

  ngOnDestroy(): void {
    if (!!this.listenFunc) {
      this.listenFunc();
    }
  }

  private wrapInput(): void {
    const wrapper = this.renderer.createElement('div');
    this.renderer.setStyle(wrapper, 'position', 'relative');
    this.elManipulationService.wrapElement(this.elRef.nativeElement, wrapper);
  }

  private addIcon(): void {
    const parent = this.renderer.parentNode(this.elRef.nativeElement);
    this.imgElRef = this.renderer.createElement('img');
    this.renderer.addClass(this.imgElRef, 'filter-primary');
    this.renderer.appendChild(parent, this.imgElRef);
    this.listenFunc = this.renderer.listen(this.imgElRef, 'click', this.listener);
  }

  private setIcon(imgPath: string): void {
    this.renderer.setProperty(this.imgElRef, 'src', `./assets/libs/icons/${imgPath}.svg`);
    if (imgPath === 'search') {
      this.renderer.removeClass(this.imgElRef, 'pointer-cursor');
    } else {
      this.renderer.addClass(this.imgElRef, 'pointer-cursor');
    }
  }
}
