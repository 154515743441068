<div bsModal #modalControl="bs-modal" id="confirmation-dialog" class="modal" tabindex="-1" role="dialog" #registerModal>
  <div class="modal-dialog register-modal">
    <div class="modal-content">
      <div class="panel panel-transparent">
        <div class="panel-heading">
					<span class="title">
						{{ title }}
					</span>
          <div class="pull-right">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalControl.hide()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div class="panel-body">
          {{ message }}
        </div>
      </div>
      <div class="modal-footer flex justify-end">
        <a data-dismiss="modal" (click)="onLeftButtonClick($event)" class="btn btn-link">{{ leftButtonLabel | translate }}</a>
        <button class="btn btn-primary" (click)="onButtonClick($event)">{{ buttonLabel }}</button>
      </div>
    </div>
  </div>
</div>
