import { createAction, props } from '@ngrx/store';

import { Condo, IntegrationSettingsResponse, PaymentSettings, Settings } from '@tsq-web/condo';

export const goToCondo = createAction(
  '[Communities] Go To Condo',
  props<{condo: string | Condo}>(),
);

export const updateCondoSettings = createAction(
  '[Communities] Update Condo Settings',
  props<{settings: Settings}>(),
);

export const setCondoPaymentSettings = createAction(
  '[Communities] Set Condo Payment Settings',
  props<{ paymentSettings: PaymentSettings }>(),
);

export const setCondoIntegrationSettings = createAction(
  '[Communities] Set Condo Integration Settings',
  props<{ settings: IntegrationSettingsResponse }>(),
);
