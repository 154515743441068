import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  Optional,
  Renderer2,
  Self,
} from '@angular/core';
import { NgControl, UntypedFormControl } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { environment } from '@tsq-web/environment';

@UntilDestroy()
@Directive({
  selector: 'input[type=checkbox][tsqToggle]',
})
export class ToggleDirective implements AfterViewInit {
  private _schema: 'primary' | 'new-primary' = 'primary';

  private control: UntypedFormControl;

  private path: HTMLDivElement;
  private marker: HTMLDivElement;

  private readonly el: HTMLInputElement;

  constructor(
    @Optional() @Self() private ngControl: NgControl,
    private renderer: Renderer2,
    private elRef: ElementRef<HTMLInputElement>,
  ) {
    this.el = elRef.nativeElement;
  }

  ngAfterViewInit(): void {
    if (!!this.ngControl) {
      this.control = this.ngControl.control as UntypedFormControl;

      this.buildToggle();

      this.control.registerOnDisabledChange(() => this.updateState());
      this.control.registerOnChange(() => this.updateState());
      this.control.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this.updateState());
    } else if (!environment.production) {
      console.warn(
        'tsqToggle directive needs a Form Control so it can listen to changes and behave as expected.' +
          '\n' +
          "You didn't provide one, so tsqToggle won't initialise for this element.",
      );
    }
  }

  @Input() set schema(value: 'primary' | 'new-primary') {
    this._schema = value;

    if (!!this.control) {
      this.updateState();
    }
  }

  private buildToggle(): void {
    [
      ['width', '36px'],
      ['height', '28px'],
    ].forEach(style => this.renderer.setStyle(this.el, style[0], style[1]));
    ['appearance-none', 'focus:!outline', '!m-0', 'rounded'].forEach(className =>
      this.renderer.addClass(this.el, className),
    );

    const wrapper = this.wrapElement();
    const transitionClasses = ['transition-all', 'duration-200', 'ease-in-out'];

    this.path = this.renderer.createElement('div');
    [
      ['width', '36px'],
      ['height', '12px'],
    ].forEach(style => this.renderer.setStyle(this.path, style[0], style[1]));
    [
      'bg-petro-n2',
      'rounded-full',
      'absolute',
      'top-1/2',
      'left-1/2',
      'transform',
      '-translate-x-1/2',
      '-translate-y-1/2',
      ...transitionClasses,
    ].forEach(className => this.renderer.addClass(this.path, className));

    this.marker = this.renderer.createElement('div');
    [
      ['width', '20px'],
      ['height', '20px'],
      ['border', '1px solid rgba(0, 0, 0, 0.04)'],
      ['box-shadow', '0px 3px 8px rgba(0, 0, 0, 0.15), 0px 1px 1px rgba(0, 0, 0, 0.06)'],
      ['transform', 'translateY(calc(-50% - 0.5px))'],
    ].forEach(style => this.renderer.setStyle(this.marker, style[0], style[1]));
    ['bg-white', 'rounded-full', 'absolute', 'top-1/2', 'left-0', ...transitionClasses].forEach(
      className => this.renderer.addClass(this.marker, className),
    );

    this.renderer.appendChild(wrapper, this.path);
    this.renderer.appendChild(wrapper, this.marker);

    this.updateState();
  }

  private wrapElement(): HTMLDivElement {
    const parent = this.renderer.parentNode(this.el);

    const wrapper = this.renderer.createElement('div');
    [
      ['width', '36px'],
      ['height', '28px'],
    ].forEach(style => this.renderer.setStyle(wrapper, style[0], style[1]));
    this.renderer.addClass(wrapper, 'relative');

    this.renderer.insertBefore(parent, wrapper, this.el);
    this.renderer.removeChild(parent, this.el);
    this.renderer.appendChild(wrapper, this.el);

    ['absolute', 'z-10'].forEach(className => this.renderer.addClass(this.el, className));

    return wrapper;
  }

  private updateState(): void {
    const bgClass = this._schema === 'primary' ? 'bg-primary' : 'bg-new-primary-p2';

    if (this.control.disabled) {
      this.replaceClass(this.path, bgClass, 'bg-petro-n2');
      this.replaceClass(this.marker, 'bg-white', 'bg-petro-n2');
      this.replaceClass(this.el, 'cursor-pointer', 'cursor-not-allowed');
    } else {
      this.replaceClass(this.marker, 'bg-petro-n2', 'bg-white');
      this.replaceClass(this.el, 'cursor-not-allowed', 'cursor-pointer');

      if (this.control.value) {
        this.replaceClass(this.path, 'bg-petro-n2', bgClass);
      } else {
        this.replaceClass(this.path, bgClass, 'bg-petro-n2');
      }
    }

    this.updateMarkerPosition();
  }

  private updateMarkerPosition(): void {
    if (this.control.value) {
      this.replaceClass(this.marker, 'left-0', 'left-16');
    } else {
      this.replaceClass(this.marker, 'left-16', 'left-0');
    }
  }

  private replaceClass(el: Element, toRemove: string, toAdd: string): void {
    this.renderer.removeClass(el, toRemove);
    this.renderer.addClass(el, toAdd);
  }
}
