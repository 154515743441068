import { Administrator, IntegrationType } from '../../administrator';
import { Condo } from '../models/condo.model';

export function condoDisablesReports(condo: Condo): boolean {
  if (!condo) {
    return false;
  }
  return !condo.adminFeatures || !condo.adminFeatures.disablesReports;
}

export function isIntegratedCondo(condoAdministrator: Administrator): boolean {
  if (!condoAdministrator) {
    return false;
  }

  const condoSettings = condoAdministrator.settings;

  return (
    condoSettings?.integrationType === IntegrationType.C3 ||
    condoSettings?.integrationType === IntegrationType.Middleware
  );
}
