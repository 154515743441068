import { TranslateService } from '@ngx-translate/core';

import {
  ColumnComputedHeader,
  ColumnWithMetadata,
  TableComputedConfig,
  tableColumnMetadataKey,
} from '../models/_table.model';
import {
  TableColumn,
  TableConfig,
  TableOptionalStringValue,
  TableRequiredStringValue,
  TableRowDrillDownReturnType,
} from '../models/table.model';

export function buildTableConfig<T>(value: TableConfig<T> | undefined): TableComputedConfig {
  return {
    row: {
      highlight: {
        columnId: value?.row?.highlight?.columnId || Symbol('NOOP'),
        fn:
          value?.row?.highlight?.fn ??
          ((): boolean => {
            return false;
          }),
      },
      drillDown:
        value?.row?.drillDown ??
        ((): TableRowDrillDownReturnType => {
          return undefined;
        }),
    },
    itemCounter: {
      show: value?.itemCounter?.hide !== true,
      total:
        typeof value?.itemCounter?.total === 'number' && value?.itemCounter?.total >= 0
          ? value?.itemCounter.total
          : -1,
    },
    tableSettings: {
      show: value?.tableSettings?.show,
      change:
        value?.tableSettings?.change ??
        ((): void => {
          return;
        }),
    },
    resizeColumns: {
      enable: value?.resizeColumns?.disable !== true,
    },
  };
}

export function buildTableHeader<T>(column: TableColumn<T>, last = false): ColumnComputedHeader {
  const text = 'header' in column ? column.header?.text : undefined;
  const align = 'header' in column ? column.header?.align : 'left';

  return {
    id: column.id,
    text,
    align,
    resizable: 'size' in column && !last,
    sortable: 'sort' in column && !!column.sort,
  };
}

export function buildColumnWithMetadata<T>(
  column: TableColumn<T>,
  canInteract: boolean,
  translate: TranslateService,
): ColumnWithMetadata<T> {
  return {
    ...column,
    [tableColumnMetadataKey]: {
      label: getColumnLabel(column, translate),
      visible: !(column as unknown as { startHidden?: boolean })?.startHidden,
      canInteract,
    },
  };
}

function getColumnLabel<T>(column: TableColumn<T>, translate: TranslateService): string {
  if ('header' in column && !!column.header) {
    const headerText = valueFromStringValue(column.header.text, translate);

    if (!!headerText) {
      return headerText;
    }

    const fallbackLabel = valueFromStringValue(column.header.fallbackLabel, translate);

    if (!!fallbackLabel) {
      return fallbackLabel;
    }
  }
  if ('fallbackLabel' in column && !!column.fallbackLabel) {
    const fallbackLabel = valueFromStringValue(column.fallbackLabel, translate);

    if (!!fallbackLabel) {
      return fallbackLabel;
    }
  }

  return '-';
}

export function valueFromStringValue(
  value: TableRequiredStringValue | TableOptionalStringValue,
  translate: TranslateService,
): string | undefined {
  if (typeof value === 'function') {
    return value({ translate });
  } else if (typeof value === 'string') {
    return value;
  }

  return undefined;
}

export function getGridSizeConfigForColumns<T>(columns: TableColumn<T>[]): {
  id: string | symbol;
  value: string;
}[] {
  return columns.map(column => {
    return getGridSizeConfigForColumn(column);
  });
}

export function getGridSizeConfigForColumn<T>(col: TableColumn<T>): {
  id: string | symbol;
  value: string;
} {
  switch (col.type) {
    case 'icon':
    case 'actions':
      return {
        id: col.id,
        value: '48px',
      };
    case 'text':
    case 'currency':
    case 'link':
    case 'avatar':
    case 'tag': {
      if (col.size === 'auto') {
        return {
          id: col.id,
          value: 'auto',
        };
      } else if ('fr' in col.size) {
        return {
          id: col.id,
          value: `${col.size.fr}fr`,
        };
      } else if ('px' in col.size) {
        return {
          id: col.id,
          value: `${col.size.px}px`,
        };
      } else if ('min' in col.size && 'max' in col.size) {
        const min = typeof col.size.min === 'string' ? col.size.min : `${col.size.min.px}px`;

        let max: string;
        if (typeof col.size.max === 'string') {
          max = col.size.max;
        } else if ('fr' in col.size.max) {
          max = `${col.size.max.fr}fr`;
        } else {
          max = `${col.size.max.px}px`;
        }

        return {
          id: col.id,
          value: `minmax(${min}, ${max})`,
        };
      } else if ('fitContent' in col.size) {
        return {
          id: col.id,
          value: `fit-content(${col.size.fitContent.px})`,
        };
      }

      return {
        id: col.id,
        value: '1fr',
      };
    }
    default:
      return {
        id: Symbol(),
        value: '1fr',
      };
  }
}

export function recalculateColumnsWidth(
  state: {
    id: string | symbol;
    value: string;
  }[],
  resizedId: string,
  resizedDelta: number,
  headerEls: HTMLElement[],
): {
  id: string | symbol;
  value: string;
}[] {
  const resizedIndex = state.findIndex(({ id }) => id === resizedId);

  let willOverflow = false;

  return state.map((config, index) => {
    const elCurrentWidth = headerEls[index]?.getBoundingClientRect().width || 0;

    if (resizedIndex === index) {
      const newWidth = Math.max(elCurrentWidth + resizedDelta, 70);

      if (resizedDelta > 0) {
        const nextElCurrentWidth = headerEls[index + 1]?.getBoundingClientRect().width || 0;
        const nextElNewWidth = Math.max(nextElCurrentWidth + resizedDelta * -1, 70);

        if (nextElNewWidth < 71) {
          willOverflow = true;

          return {
            ...config,
            value: `${elCurrentWidth}px`,
          };
        }
      } else if (resizedDelta < 0 && newWidth < 71) {
        willOverflow = true;

        return {
          ...config,
          value: `${elCurrentWidth}px`,
        };
      }

      return {
        ...config,
        value: `${newWidth}px`,
      };
    } else if (resizedIndex + 1 === index && resizedIndex !== -1 && !willOverflow) {
      return {
        ...config,
        value: `${Math.max(elCurrentWidth + resizedDelta * -1, 70)}px`,
      };
    }

    return {
      ...config,
      value: `${elCurrentWidth}px`,
    };
  });
}
