export class RegistrationRequest {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  billingDocument: string;
  hashPassword: string;
  registrationToken: string;
  pictureName: string;
}
