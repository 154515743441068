import { Pipe, PipeTransform } from '@angular/core';

import { Temporal } from '@js-temporal/polyfill';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { fromLocalizationSelectors } from '@tsq-web/localization';
import { selectUntilDestroyed } from '@tsq-web/redux/operators';
import { AppState } from '@tsq-web/state';

import { EpochMilliseconds } from '../models/epoch-milliseconds.model';
import { ISO8601DateTime } from '../models/iso-8601-date-time.model';
import { TemporalComparison } from '../models/temporal-comparison.enum';
import { TemporalDateLike } from '../models/temporal-date-like.model';
import { isEpochMilliseconds, toTemporalZonedDateTime } from '../utils/date-time.utils';
import { dateTimeFormat } from '../utils/format-options.utils';

@UntilDestroy()
@Pipe({
  name: 'relativeTime',
})
export class RelativeTimePipe implements PipeTransform {
  private lang: string;

  constructor(private store: Store<AppState>, private translateService: TranslateService) {
    this.store
      .pipe(selectUntilDestroyed(fromLocalizationSelectors.selectLanguage, this))
      .subscribe((lang: string) => (this.lang = lang));
  }

  transform(
    value: EpochMilliseconds | ISO8601DateTime | TemporalDateLike | Date,
    format?: Intl.DateTimeFormatOptions,
    timeZone?: Temporal.TimeZoneLike,
  ): string {
    if (!value) {
      return '';
    }

    const now = Temporal.Now.zonedDateTimeISO();
    const threeDaysAhead = now.add({ days: 3 });
    const threeDaysAgo = now.subtract({ days: 3 });
    const date = this.getZonedDateTime(value, timeZone);

    if (
      Temporal.ZonedDateTime.compare(date, threeDaysAgo) === TemporalComparison.Date1AfterDate2 &&
      Temporal.ZonedDateTime.compare(date, threeDaysAhead) === TemporalComparison.Date1BeforeDate2
    ) {
      const duration = date.until(now);
      const pastDate = duration.sign === 1;
      const seconds = duration.abs().round('second').total('seconds');
      const minutes = duration.abs().round('second').round('minute').total('minutes');
      const hours = duration.abs().round('hour').total('hours');
      const days = duration.abs().round('day').total('days');

      let key: string;
      let interpolateParams: Record<string, string | number>;

      if (seconds <= 44) {
        key = 'A_FEW_SECONDS';
      } else if (seconds <= 89) {
        key = 'A_MINUTE';
      } else if (minutes <= 44) {
        key = `N_MINUTES`;
        interpolateParams = { minutes };
      } else if (minutes <= 89) {
        key = `AN_HOUR`;
      } else if (hours <= 21) {
        key = `N_HOURS`;
        interpolateParams = { hours };
      } else if (hours <= 36) {
        key = `A_DAY`;
      } else {
        key = `N_DAYS`;
        interpolateParams = { days };
      }

      key = pastDate ? `${key}_AGO` : `IN_${key}`;

      return this.translateService.instant(`LIBS.DATE_TIME.${key}`, interpolateParams);
    }

    return date.toLocaleString(this.lang || 'en', format || dateTimeFormat);
  }

  getZonedDateTime(
    value: EpochMilliseconds | TemporalDateLike | Date,
    tzLike?: Temporal.TimeZoneLike,
  ): Temporal.ZonedDateTime {
    const tz = tzLike || Temporal.Now.timeZone();

    if (typeof value === 'string') {
      if (isEpochMilliseconds(value)) {
        return Temporal.Instant.fromEpochMilliseconds(Number(value)).toZonedDateTimeISO(tz);
      }

      return Temporal.Instant.from(value).toZonedDateTimeISO(tz);
    } else if (typeof value === 'number') {
      return Temporal.Instant.fromEpochMilliseconds(value).toZonedDateTimeISO(tz);
    } else if (value instanceof Temporal.Instant) {
      return value.toZonedDateTimeISO(tz);
    } else if (value instanceof Date) {
      return toTemporalZonedDateTime(value);
    }

    return value;
  }
}
