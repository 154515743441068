import { Component, Output, EventEmitter, Input } from '@angular/core';

export interface Color {
  colorId: string;
  colorHex: string;
  highlighted: boolean;
  selected: boolean;
}

@Component({
  selector: 'tsq-color-picker',
  templateUrl: './tsq-color-picker.component.html',
  styleUrls: ['./tsq-color-picker.component.scss'],
})
export class TSqColorPickerComponent {
  @Output() colorChange = new EventEmitter<string>();

  colors: Color[] = [
    { colorId: 'red', colorHex: '#FD6860', highlighted: true, selected: false },
    { colorId: 'orange', colorHex: '#FCAD41', highlighted: true, selected: false },
    { colorId: 'green', colorHex: '#7AAA12', highlighted: true, selected: false },
    { colorId: 'oceanGreen', colorHex: '#42AF71', highlighted: true, selected: false },
    { colorId: 'turquoise', colorHex: '#47D9DE', highlighted: true, selected: false },
    { colorId: 'blue', colorHex: '#5CA0E2', highlighted: true, selected: false },
    { colorId: 'purple', colorHex: '#A162AE', highlighted: true, selected: false },
    { colorId: 'brown', colorHex: '#92551F', highlighted: true, selected: false },
    { colorId: 'black', colorHex: '#333333', highlighted: true, selected: false },
    { colorId: 'grey', colorHex: '#C0C3D6', highlighted: true, selected: false },
  ];

  @Input() set color(hex: string) {
    if (!hex) {
      this.resetColors();
    } else {
      const index = this.colors.findIndex(color => color.colorHex === hex);
      if (index >= 0) {
        this.colors[index].highlighted = true;
        this.colors[index].selected = true;
        this.updateColors(this.colors[index]);
      }
    }
  }

  onColorSelected(color: Color): void {
    this.colorChange.emit(color ? color.colorHex : undefined);
  }

  private resetColors(): void {
    for (const color of this.colors) {
      color.highlighted = true;
      color.selected = false;
    }
  }

  private updateColors(selectedColor: Color): void {
    for (const color of this.colors) {
      color.highlighted = color.colorId === selectedColor.colorId;
      color.selected = color.colorId === selectedColor.colorId;
    }
  }
}
