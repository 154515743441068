<sc-comment-status
  *ngIf="showReplyComment"
  [name]="inReplyTo.createdBy.first_name + ' ' + inReplyTo.createdBy.last_name"
  [avatarUrl]="inReplyTo.createdBy.pictureUrl"
  [text]="inReplyTo.text"
></sc-comment-status>
<div class="conversation row" [class.conversation-right]="right">
  <div class="mr-8 inline-block align-top" *ngIf="!right">
    <img
      src="{{ avatarUrl }}"
      alt="{{ name }}"
      title="{{ name }}"
      class="img-circle h-56 w-56 object-cover"
    />
  </div>

  <div class="conversation-bubble">
    <div class="{{ 'bubble ' + getBubbleSideClasses() }}">
      <div>
        <small class="{{ getDiscussionTimeClass() }}">
          <strong>{{ time }}</strong>
        </small>
        <br />
        <small class="text-info-dark" *ngIf="!right">
          <strong>{{ name }}</strong>
          <span *ngIf="inReplyTo">
            {{ 'SHARED.IN_ANSWER_TO' | translate }} {{ inReplyTo.createdBy.first_name }} (<a
              href="javascript:void(0);"
              (click)="showInReplyTo()"
              class="text-kale"
              >{{ getInReplyToLinkText() | translate }}</a
            >)
          </span>
          <br />
          <strong>{{ desc }}</strong>
        </small>
        <div class="break-words mt-4" data-cy="discussion-comment">
          <p *ngIf="text" [innerHTML]="parseDiscussionText(text) | sanitizeHtml"></p>
        </div>
      </div>
      <div>
        <div *ngIf="enableActions" class="text-right">
          <div *ngIf="!showReplyForm" class="text-right mr-16">
            <div *ngIf="enableActions" (click)="reply()" class="inline-block ml-16 cursor-pointer">
              <img
                class="btn-cfg"
                src="{{ getReplyIconUrl() }}"
                alt="{{ 'SHARED.ANSWER' | translate }}"
              />
              <span class="text-coal-tertiary ml-4">{{ 'SHARED.ANSWER' | translate }}</span>
            </div>
            <div
              *ngIf="enableActions && isAdmin && !isHidden"
              (click)="delete()"
              class="inline-block ml-16 cursor-pointer"
            >
              <img
                class="btn-cfg"
                src="{{ getRemoveIconUrl() }}"
                alt="{{ 'SHARED.DELETE' | translate }}"
              />
              <span class="text-coal-tertiary ml-4">{{ 'SHARED.DELETE' | translate }}</span>
            </div>
          </div>
        </div>

        <div *ngIf="attachments.length > 0" class="row">
          <div class="col-xs-12">
            <div *ngFor="let document of attachments; let idx = index">
              <sc-attachment-view
                [fileName]="document.name"
                [fileSize]="document.size"
                [fileExtension]="document.filenameExt"
                [fileViewUrl]="urlView"
                [fileDownloadUrl]="urlDownload"
              ></sc-attachment-view>
            </div>
          </div>
        </div>
        <div *ngIf="showReplyForm" class="col-md-12">
          <sc-comment-input
            *ngIf="showReplyForm"
            (onCommentSent)="sendResponse($event)"
          ></sc-comment-input>
        </div>
      </div>
    </div>
  </div>

  <div class="mr-8 inline-block align-top" *ngIf="right">
    <img
      src="{{ avatarUrl }}"
      alt="{{ name }}"
      title="{{ name }}"
      class="img-circle h-56 w-56 object-cover"
    />
  </div>
</div>
