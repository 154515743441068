import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { TSqChipsModule } from '@tsq-web/chips';
import { TSqLoadersModule } from '@tsq-web/loaders';

import { InAppFeedbackModalComponent } from '../shared/in-app-feedback/containers/in-app-feedback-modal/in-app-feedback-modal.component';
import { FeedbackTriggerService } from '../shared/in-app-feedback/services/feedback-trigger.service';
import { NeighborMassInviteChipsInputComponent } from './components/neighbor-mass-invite-chips-input/neighbor-mass-invite-chips-input.component';
import { NeighborMassInviteFormComponent } from './components/neighbor-mass-invite-form/neighbor-mass-invite-form.component';
import { NeighborMassInviteLayoutComponent } from './components/neighbor-mass-invite-layout/neighbor-mass-invite-layout.component';
import { NeighborMassInviteComponent } from './containers/main-neighbor-mass-invite/neighbor-mass-invite.component';
import { NeighborMassInviteRoutingModule } from './neighbor-mass-invite.routes';
import { NeighborMassInviteRequestsService } from './services/neighbor-mass-invite-requests.service';
import { NeighborMassInviteEffects } from './state/neighbor-mass-invite.effects';
import { neighborMassInviteReducer } from './state/neighbor-mass-invite.reducer';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    BrowserAnimationsModule,
    HttpClientModule,
    StoreModule.forFeature('neighborMassInvite', neighborMassInviteReducer),
    EffectsModule.forFeature([NeighborMassInviteEffects]),
    TSqChipsModule,
    NeighborMassInviteRoutingModule,
    TSqLoadersModule,
    InAppFeedbackModalComponent,
  ],
  declarations: [
    NeighborMassInviteComponent,
    NeighborMassInviteFormComponent,
    NeighborMassInviteLayoutComponent,
    NeighborMassInviteChipsInputComponent,
  ],
  exports: [NeighborMassInviteComponent],
  providers: [NeighborMassInviteRequestsService, FeedbackTriggerService],
})
export class NeighborMassInviteModule {}
