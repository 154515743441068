import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

import { BaseService } from '@tsq-web/core';

@Injectable()
export class WebPushService extends BaseService {
  constructor(
    protected cookieService: CookieService,
    protected httpClient: HttpClient,
    protected router: Router,
  ) {
    super(cookieService, httpClient, router);
  }

  saveToken(email: string, token: string): Observable<void> {
    return this.doPost(`mobile/token`, { userEmail: email, deviceToken: token, client: 'web' });
  }
}
