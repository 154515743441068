import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { Params } from '@angular/router';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { fromRouterSelectors } from '@tsq-web/router';
import { AppState } from '@tsq-web/state';

import { getFeatureToggle } from '../../../shared/feature-toggle';
import * as fromAppActions from '../../../state/app.actions';
import * as neighborMassInviteActions from '../../state/neighbor-mass-invite.actions';
import * as fromNeighborMassInviteState from '../../state/neighbor-mass-invite.selectors';

@Component({
  selector: 'comm-neighbor-mass-invite',
  templateUrl: './neighbor-mass-invite.component.html',
  styleUrls: ['./neighbor-mass-invite.component.scss'],
})
export class NeighborMassInviteComponent implements OnInit, AfterViewInit, OnDestroy {
  isLoading$: Observable<boolean>;
  isSuccessful$: Observable<boolean>;
  private condoId: string;

  readonly inAppFeedback = getFeatureToggle().inAppFeedback;

  private componentDestroyed$ = new Subject<void>();

  constructor(private store: Store<AppState>, private analytics: AngularFireAnalytics) {}

  ngOnInit(): void {
    this.analytics.logEvent('invites_bulk_form');

    this.isLoading$ = this.store.pipe(select(fromNeighborMassInviteState.selectIsLoading));
    this.isSuccessful$ = this.store.pipe(select(fromNeighborMassInviteState.selectIsSuccessful));
  }

  ngAfterViewInit(): void {
    this.store
      .pipe(
        select(fromRouterSelectors.selectRouterQueryParams),
        takeUntil(this.componentDestroyed$),
      )
      .subscribe((params: Params) => (this.condoId = params.condoId));
  }

  ngOnDestroy(): void {
    this.store.dispatch(new neighborMassInviteActions.RestoreNeighborMassInviteState());

    this.componentDestroyed$.next();
    this.componentDestroyed$.unsubscribe();
  }

  sendEmails(emails: string[]): void {
    this.analytics.logEvent('invites_bulk_submit');
    this.store.dispatch(new neighborMassInviteActions.SendInvitesRequest({ emails }));
  }

  redirectToCondo(): void {
    this.store.dispatch(fromAppActions.goToCondo({ condo: this.condoId }));
  }
}
