<button
  data-cy="login-card--button--go-back"
  *ngIf="!isTownSqBrazil; else linkToBrazil"
  (click)="goBackClicked.emit()"
>
  <tsq-icon [icon]="arrowLeftIcon" [classes]="'text-green size-32'" />
</button>

<ng-template #linkToBrazil>
  <a data-cy="login-card--a--to-townsq-brazil" href="https://townsq.com.br/">
    <tsq-icon [icon]="arrowLeftIcon" [classes]="'text-green'" />
  </a>
</ng-template>

<div class="mt-32 flex flex-col gap-16">
  <h1>{{ 'LOGIN.WELCOME_BACK' | translate }}</h1>

  <span>{{ 'LOGIN.LOGIN_INFORMATION' | translate }}</span>

  <div class="w-[438px]">
    <comm-already-registered-banner
      *ngIf="showAlreadyRegisteredBanner"
      [showAlreadyRegisteredBannerAdditionalInfo]="showAlreadyRegisteredBannerAdditionalInfo"
      (closeBanner)="onCloseBanner()"
    />
  </div>
</div>

<form [formGroup]="loginForm" (ngSubmit)="doLogin()">
  <div class="mt-16">
    <label>{{ 'LOGIN.EMAIL' | translate }}</label>
    <input
      tsqTextInput
      formControlName="username"
      class="control"
      placeholder="{{ 'LOGIN.EMAIL_PLACEHOLDER' | translate }}"
      data-cy="login-card--input--email"
    />
  </div>

  <div class="mt-8">
    <label>{{ 'LOGIN.PASSWORD' | translate }}</label>
    <input
      tsqPeekablePassword
      formControlName="password"
      type="password"
      class="control"
      data-cy="login-card--input--password"
    />
  </div>

  <div class="text-green mt-24 flex justify-center">
    <a data-cy="login-card--a--forgot-password" routerLink="/user-recovery">{{
      'LOGIN.FORGOT_YOUR_PASSWORD' | translate
    }}</a>
  </div>

  <div class="text-green mt-24 flex justify-center">
    <a *ngIf="ssoEnabled" class="mb-16" (click)="doLoginWithSso()">{{
      'LOGIN.LOG_IN_WITH_SSO' | translate
    }}</a>
  </div>

  <div class="mt-24">
    <tsq-button
      data-cy="login-card--button--log-in"
      *ngIf="!isLoginIn; else loginIn"
      [text]="'LOGIN.LOG_IN' | translate"
      [type]="'submit'"
      [size]="'lg'"
      [disabled]="loginForm.invalid"
    />

    <ng-template #loginIn>
      <div class="flex items-center justify-center">
        <tsq-small-loader />
      </div>
    </ng-template>
  </div>
</form>
