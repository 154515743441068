<div>
  <div class="tsq-card">
    <div class="panel-heading">
      <h1>{{ 'SUBSCRIPTION.TAXES_TO_PAY' | translate }}</h1>
    </div>

    <div class="panel-body">
      <form [formGroup]="form">
        <div class="tsq-card">
          <div>
            <div class="form-group inline">
              <label>{{ 'SUBSCRIPTION.TAXES.PIS_COFINS_CSLL' | translate }}</label>
              <tsq-switch formControlName="pisCofinsCsll"></tsq-switch>
            </div>
          </div>

          <div>
            <div class="form-group inline">
              <label>{{ 'SUBSCRIPTION.TAXES.IR' | translate }}</label>
              <tsq-switch formControlName="ir"></tsq-switch>
            </div>
          </div>

          <div>
            <div class="form-group inline">
              <label>{{ 'SUBSCRIPTION.TAXES.ISS' | translate }}</label>
              <tsq-switch formControlName="iss"></tsq-switch>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
