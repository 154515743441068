/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'sc-button-group',
  templateUrl: 'sc-button-group.component.html',
  styleUrls: ['sc-button-group.component.scss'],
})
export class SCButtonGroupComponent implements OnInit {
  @Input() options: { value: any; label: string }[];
  @Input() defaultOptionIndex: number;
  @Output() onOptionClick = new EventEmitter();
  @Input() selectedOption: any;

  ngOnInit(): void {
    if (this.defaultOptionIndex) {
      this.selectedOption = this.options[this.defaultOptionIndex].value;
    }
  }

  selectOption(event: any) {
    this.selectedOption = event.value;
    this.onOptionClick.emit(this.selectedOption);
  }

  isOptionActive(currentOption: string, newOption: string) {
    return currentOption === newOption;
  }

  reset() {
    this.selectedOption = this.options[this.defaultOptionIndex].value;
  }

  setSelectedOption(value: string) {
    this.selectedOption = value;
  }
}
