<div
  class="col-md-12 col-sm-12 day"
  [class.disabled]="!available"
  [class.past]="isPast()"
  [class.today]="isToday()"
  [class.future]="isFuture()"
  [class.not-navigation-month]="!isInNavigationMonth"
>
  <div class="row">
    <div class="day-number" [class.busy-day]="isBusyDay" [class.full-busy-day]="isFullBusyDay">
      {{ getDay() }}
    </div>
  </div>
  <div class="row event-dots">
    <i *ngIf="hasWaitingQueue" class="fa fa-circle waiting-queue" aria-hidden="true"></i>
    <i *ngIf="hasWaitingApproval" class="fa fa-circle waiting-approval" aria-hidden="true"></i>
    <i *ngIf="hasCurrentUserEvent" class="fa fa-circle current-user-event" aria-hidden="true"></i>
    <i
      *ngIf="isPendingConfirmation"
      class="fa fa-circle pending-confirmation"
      aria-hidden="true"
    ></i>
  </div>
</div>
