/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'tsq-attachment-card',
  templateUrl: './tsq-attachment-card.component.html',
  styleUrls: ['./tsq-attachment-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TSqAttachmentCardComponent {
  @Input() attachment: any;
  @Input() iconImage = 'icon-delete-avocado';
  @Input() titleLink: string;

  @Output() actionClick = new EventEmitter();

  get extensionImage(): string {
    const extension = this.extension;

    switch (extension) {
      case 'png':
      case 'jpg':
      case 'gif':
        return 'attachment-type-image';
      case 'xlsx':
      case 'xls':
        return 'attachment-type-spreadsheet';
      case 'doc':
      case 'docx':
      case 'txt':
      case 'pdf':
        return 'attachment-type-doc';
      case 'ppt':
      case 'pptx':
        return 'attachment-type-presentation';
      default:
        return 'attachment-type-others';
    }
  }

  get extension(): string {
    return this.attachment.extension ? this.attachment.extension : this.attachment.filenameExt;
  }

  actionClicked(): void {
    this.actionClick.emit();
  }

  onTitleClick(): void {
    if (!!this.titleLink) {
      window.open(this.titleLink, '_blank');
    }
  }
}
