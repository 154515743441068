/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'sc-color-picker-item',
  templateUrl: 'sc-color-picker-item.component.html',
  styleUrls: ['sc-color-picker-item.component.scss'],
})
export class SCColorPickerItemComponent {
  @Input() color: any;
  @Output() onSelected = new EventEmitter<any>();

  setSelected() {
    this.color.selected = !this.color.selected;
  }

  selectColor(event: any) {
    this.setSelected();
    if (!this.color.selected) {
      this.onSelected.emit(null);
    } else {
      this.onSelected.emit(this.color);
    }
  }

  setClasses() {
    const classes = {
      opaque: !this.color.highlighted,
    };
    return classes;
  }
}
