import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { FormatDurationPipe } from './pipes/format-duration.pipe';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { RelativeTimePipe } from './pipes/relative-time.pipe';

@NgModule({
  declarations: [FormatDurationPipe, FormatDatePipe, RelativeTimePipe],
  imports: [CommonModule, TranslateModule],
  providers: [FormatDurationPipe, FormatDatePipe, RelativeTimePipe],
  exports: [FormatDurationPipe, FormatDatePipe, RelativeTimePipe],
})
export class DateTimeModule {}
