<!-- eslint-disable @angular-eslint/template/conditional-complexity -->
<div
  [ngClass]="{ 'list-container': scrollUp }"
  infiniteScroll
  [infiniteScrollDistance]="0.5"
  [infiniteScrollUpDistance]="3.0"
  [infiniteScrollThrottle]="20"
  [infiniteScrollContainer]="'.root-scrollable-content'"
  [scrollWindow]="false"
  [fromRoot]="true"
  (scrolled)="onScroll($event)"
  (scrolledUp)="onScrollUp($event)"
  #listContainer
>
  <div class="col-sm-{{ listWidth }} col-md-{{ listWidth }}">
    <div
      class="panel-transparent"
      [class.panel]="panel || loading || listSize === 0"
      data-widget='{"draggable": "false"}'
    >
      <ng-content select="[pre-header]"></ng-content>
      <div class="panel-heading">
        <span class="title">
          <span
            *ngIf="parentRoute !== undefined"
            class="parent-link"
            [routerLink]="['..', parentRoute]"
            >{{ title }}</span
          >
          <span *ngIf="parentRoute === undefined">{{ title }}</span>
          <span *ngIf="subtitle !== '' && subtitle !== undefined">/</span>
          <span *ngIf="subtitle !== '' && subtitle !== undefined" class="subtitle">
            {{ subtitle }}
          </span>

          <a
            id="config-button"
            *ngIf="showConfig"
            class="pull-right"
            (click)="configIconClicked()"
            data-cy="config-button"
          >
            <img
              *ngIf="configIcon === undefined"
              class="btn-cfg"
              src="./assets/img/icons/config.svg"
              alt="config"
            />
            <img
              *ngIf="configIcon !== undefined"
              class="btn-cfg"
              src="{{ configIcon }}"
              alt="config"
            />
          </a>

          <a id="report-button" *ngIf="showReport" class="pull-right" (click)="reportIconClicked()">
            <img
              *ngIf="reportIcon === undefined"
              class="btn-cfg"
              src="./assets/img/icons/report.svg"
              alt="report"
            />
            <img
              *ngIf="reportIcon !== undefined"
              class="btn-cfg"
              src="{{ reportIcon }}"
              alt="report"
            />
          </a>
        </span>

        <div *ngIf="showDivider" class="divider"></div>

        <div *ngIf="showToolbar" class="toolbar">
          <div class="toolbar-text">
            <p>{{ caption }}</p>
          </div>
          <div *ngIf="showSearch && !loading && !error" class="search col-md-4 pull-left">
            <div class="inner-addon-sm left-addon">
              <i class="fa fa-search"></i>
              <input
                id="list-search"
                [formControl]="filterControl"
                value="{{ searchFor }}"
                type="text"
                placeholder="{{ searchPlaceholder }}"
                class="form-control input-sm"
              />
            </div>
          </div>
          <div [hidden]="loading || error" class="buttons pull-right">
            <ng-content select="[actions]"></ng-content>
          </div>
        </div>
      </div>
      <br />
      <div *ngIf="showHeaderSpace">
        <div class="header-space">
          <div class="top-draw"></div>
        </div>
      </div>
      <div *ngIf="showNavigation">
        <ng-content select="[navigation]"></ng-content>
      </div>
      <sc-search-alert
        [filterName]="entityFilter"
        [userId]="userIdFilter"
        (onClose)="removeUserFilter()"
        #searchAlert
      ></sc-search-alert>
      <div *ngIf="loadingMore && !error && scrollUp">
        <div class="loader-container animated fadeIn">
          <tsq-small-loader></tsq-small-loader>
        </div>
      </div>
      <div [hidden]="loading || (showZeroData && listSize === 0) || error">
        <ng-content select="[list]"></ng-content>
      </div>
      <div *ngIf="loading && !error" class="row">
        <div>
          <div class="loader-container animated fadeIn">
            <tsq-loader></tsq-loader>
          </div>
        </div>
      </div>
      <div *ngIf="loadingMore && !error && !scrollUp">
        <div class="loader-container animated fadeIn">
          <tsq-small-loader></tsq-small-loader>
        </div>
      </div>

      <div
        *ngIf="!loading && listSize === 0 && searchFor?.length === 0 && !error && showZeroData"
        class="row"
      >
        <div
          class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12"
        >
          <ng-content select="[zero-data]"></ng-content>
        </div>
      </div>
      <div
        *ngIf="!loading && listSize === 0 && searchFor?.length > 0 && !error && showZeroData"
        class="row"
      >
        <div
          class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12"
        >
          <sc-zero-data
            topText="{{ 'SEARCH_EMPTY_TOP' | translate : { value: searchFor } }}"
            bottomText="{{ 'SEARCH_EMPTY_BOTTOM' | translate }}"
            illustrationSrc="./assets/svg/empty-search.svg"
          >
          </sc-zero-data>
        </div>
      </div>
      <div *ngIf="!loading && error" class="row">
        <div
          class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12"
        >
          <sc-error (retry)="onRetry($event)"></sc-error>
        </div>
      </div>
    </div>
  </div>
</div>
