import { uniqBy } from 'lodash';

import { getTSqEnvironment } from '@tsq-web/environment';

import { User } from '../models/user.model';

export function getUserBaseName(user: User): string {
  return user?.name || '';
}

export function getUserFullName(user: User): string {
  if (!!user) {
    if (!!user.firstName || !!user.lastName) {
      return `${user.firstName} ${user.lastName}`;
    }

    return `${user.first_name} ${user.last_name}`;
  }

  return '';
}

export function getUserPicture(user: User): string {
  if (!!user) {
    if (!!user.smallPictureUrl) {
      return user.smallPictureUrl;
    }

    return !!user.pictureUrl ? user.pictureUrl : './assets/libs/images/user-placeholder.svg';
  }

  return '';
}

export function getUserProperty(user: User): string {
  const properties = uniqBy(user?.properties, 'id');
  if (!!properties?.length) {
    if (properties.length > 1) {
      return 'PROPERTY.MULTIPLE_PROPERTIES';
    }

    return properties[0].fine_description || properties[0].description;
  }

  return 'PROPERTY.NO_PROPERTIES';
}

export function getFullUrlFavorite(): string {
  const { communitiesUrl } = getTSqEnvironment();

  return [communitiesUrl, 'api', 'user-personalization', 'v1', 'favorite', 'user']
    .filter(part => !!part)
    .join('/');
}
