import { Component } from '@angular/core';

import { AssetsModule, icons } from '@tsq-web/assets';

@Component({
  selector: 'comm-error-banner',
  template: `
    <section class="bg-red-n1 border-1 border-red flex w-full gap-16 rounded p-16 shadow">
      <div class="flex items-center">
        <tsq-icon [icon]="xMarkCircleIcon" [classes]="'text-red w-40 h-40'" />
      </div>

      <ng-content />
    </section>
  `,
  standalone: true,
  imports: [AssetsModule],
})
export class ErrorBannerComponent {
  readonly xMarkCircleIcon = icons.xmarkCircleFill;
}
