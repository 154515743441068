import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { FileMeta } from '@tsq-web/files';

@Component({
  selector: 'tsq-file-card',
  templateUrl: './tsq-file-card.component.html',
  styleUrls: ['./tsq-file-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TSqFileCardComponent {
  @Input() file: FileMeta;

  openImage(): void {
    window.open(this.file.fileUrl, '_blank');
  }
}
