import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ais-link-sign-up-login',
  template: `
    <div class="page-container">
      <h1>{{ 'AIS_SIGN_UP.SIGN_UP' | translate }} / {{ 'AIS_SIGN_UP.LOGIN' | translate }}</h1>
      <p>{{ 'AIS_SIGN_UP.DONT_HAVE_TSQ' | translate }}</p>
      <button class="btn btn-primary" [routerLink]="['register']">
        {{ 'AIS_SIGN_UP.SIGN_UP' | translate }}
      </button>
      <p>{{ 'LOGIN.OR' | translate }}</p>
      <p>{{ 'AIS_SIGN_UP.DO_HAVE_TSQ' | translate }}</p>
      <button class="btn btn-primary" (click)="goToLogin()">
        {{ 'AIS_SIGN_UP.LOGIN' | translate }}
      </button>
    </div>
  `,
  styleUrls: ['./ais-link-sign-up-login.component.scss'],
})
export class AisLinkSignUpLoginComponent {
  constructor(private router: Router) {}

  goToLogin(): void {
    this.router.navigate(['login'], { queryParams: { redirect: 'ais/sign-up/link-user/logged' } });
  }
}
