export class SubscriptionDiscount {
  rawValue: number;
  percentage: number;
  oneTime: boolean;

  static from(rawValue: number, percentage: number, oneTime: boolean): SubscriptionDiscount {
    const sd = new SubscriptionDiscount();
    sd.rawValue = rawValue;
    sd.percentage = percentage;
    sd.oneTime = oneTime;
    return sd;
  }
}
