<div id="summary-modal" *ngIf="!!subscription">
  <h1>{{ 'SUBSCRIPTION.PLAN' | translate }}:</h1>
  <div class="summary-card" *ngIf="!!subscription.plan">
    <div class="card-content inline-block">
      <h1>{{ subscription.plan.name }}</h1>
      <p>{{ subscription.plan.description }}</p>
    </div>
    <div class="card-cost inline-block" *ngIf="basePrice">
      <img src="./assets/img/icons/icon-price.svg" alt="" />
      <span>{{ '$ ' + basePrice.toFixed(2) }}</span>
    </div>
  </div>

  <h1
    class="mt-15"
    *ngIf="!!subscription.featurePackages && subscription.featurePackages.length > 0"
  >
    {{ 'SUBSCRIPTION.FEATURES' | translate }}:
  </h1>
  <div *ngIf="subscription.featurePackages && subscription.featurePackages.length > 0">
    <div class="summary-card" *ngFor="let fPackage of subscription.featurePackages">
      <div class="card-content inline-block">
        <h1>{{ fPackage.name }}</h1>
      </div>
      <div class="card-cost inline-block">
        <img src="./assets/img/icons/icon-price.svg" alt="" />
        <span>{{ '$ ' + (fPackage.price.amount / 100).toFixed(2) }}</span>
      </div>
    </div>
  </div>

  <h1 class="mt-15" *ngIf="!!percentageNumber || !!rawValueNumber">
    {{ 'SUBSCRIPTION.DISCOUNT' | translate }}:
  </h1>
  <div class="summary-card" *ngIf="!!percentageNumber">
    <div class="card-content inline-block">
      <h1>{{ 'SUBSCRIPTION.PERCENTAGE' | translate }}</h1>
    </div>
    <div class="card-cost inline-block">
      <img src="./assets/img/icons/icon-price.svg" alt="" />
      <span>- {{ percentageNumber }}%</span>
    </div>
  </div>
  <div class="summary-card" *ngIf="!!rawValueNumber">
    <div class="card-content inline-block">
      <h1>{{ 'SUBSCRIPTION.GROSS' | translate }}</h1>
    </div>
    <div class="card-cost inline-block">
      <img src="./assets/img/icons/icon-price.svg" alt="" />
      <span>- $ {{ rawValueNumber }}</span>
    </div>
  </div>

  <h1 class="mt-15" *ngIf="!!subscription.fixedCharges && subscription.fixedCharges.length > 0">
    {{ 'SUBSCRIPTION.FIXED_CHARGES' | translate }}:
  </h1>
  <div *ngIf="subscription.fixedCharges && subscription.fixedCharges.length > 0">
    <div class="summary-card" *ngFor="let fixedCharge of subscription.fixedCharges">
      <div class="card-content inline-block">
        <h1>{{ fixedCharge.description }}</h1>
      </div>
      <div class="card-cost inline-block">
        <img src="./assets/img/icons/icon-price.svg" alt="" />
        <span>{{ '$ ' + (fixedCharge.price.amount / 100).toFixed(2) }}</span>
      </div>
    </div>
  </div>
  <div class="total-card" *ngIf="!!totalAmount">
    Total <span class="pull-right">$ {{ totalAmount.toFixed(2) }}</span>
  </div>
</div>
