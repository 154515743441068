import {animate, animateChild, AnimationTriggerMetadata, group, query, style, transition, trigger} from '@angular/animations';

export function dumbParent(): AnimationTriggerMetadata {
  return trigger('dumbParent', [
    transition('* => void', [
      query('@*', [animateChild()], {optional: true})
    ]),
  ]);
}

export function dropdownFadeOut(): AnimationTriggerMetadata {
  return trigger('dropdownFadeOut', [
    transition(':leave', [
      group([
        query('i',
          animate('300ms ease-out', style({transform: 'rotateZ(0deg)'}))
        ),
        animate('200ms 200ms', style({opacity: 0}))
      ])
    ]),
  ]);
}
