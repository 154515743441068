/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  Optional,
  Renderer2,
  Self,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Observable, Subscriber } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { TypeaheadDataSource, TypeaheadItem } from '../../models/typeahead-data-source.model';
import { getErrorMessageFromValidationErrors } from '../../utils/validators/tsq-validators.utils';

@UntilDestroy()
@Component({
  selector: 'tsq-typeahead',
  templateUrl: './tsq-typeahead.component.html',
  styleUrls: ['./tsq-typeahead.component.scss'],
})
export class TSqTypeaheadComponent implements OnInit, ControlValueAccessor {
  @ViewChild('inputEl') inputEl: ElementRef;

  @Input() dataSource: TypeaheadDataSource<any>;
  @Input() props: any;
  @Input() placeholder = '';
  @Input() minLength = 3;
  @Input() numberOfOptions = 7;
  @Input() hasSearchIcon = false;
  @Input() itemTemplate: TemplateRef<{ item: TypeaheadItem<any>; index: number }>;
  @Input() selectedTemplate: TemplateRef<{ item: TypeaheadItem<any> }>;
  @Input() errorMessage: string;
  @Input() container: string;

  dataSource$: Observable<TypeaheadItem<any>[]>;
  input: string;
  item: TypeaheadItem<any>;
  selectedContext: { item: TypeaheadItem<any> };

  loading: boolean;
  error: boolean;
  finalErrorMessage: string;

  disabled: boolean;
  private onChange: (item: TypeaheadItem<any>) => void;
  private onTouched: () => void;

  private control: UntypedFormControl;
  private readonly cd = inject(ChangeDetectorRef);

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private renderer: Renderer2,
    private translateService: TranslateService,
  ) {
    ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    this.control = this.ngControl.control as UntypedFormControl;

    this.control.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this.updateError());

    this.setDataSource();
  }

  adjustDropdownWidth(): void {
    if (!this.container) {
      return;
    }

    const dropdown = document.querySelector('.dropdown-menu') as HTMLElement;
    if (!dropdown || !this.inputEl?.nativeElement) {
      return;
    }

    const inputRect = this.inputEl.nativeElement.getBoundingClientRect();
    this.renderer.setStyle(dropdown, 'width', `${inputRect.width}px`);
    this.cd.detectChanges();
  }

  setDataSource(): void {
    this.dataSource$ = new Observable<string>((subscriber: Subscriber<string>) =>
      subscriber.next(this.input),
    ).pipe(switchMap((value: string) => this.dataSource.getEntities(value, this.props)));
  }

  registerOnChange(fn: (item: TypeaheadItem<any>) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(item: TypeaheadItem<any>): void {
    this.item = item;
    this.selectedContext = { item };
    this.input = item?.text;
  }

  onSelect(match: TypeaheadMatch): void {
    if (!this.disabled) {
      this.writeValue(match?.item);
      this.markAsTouched();
      this.onChange(match?.item);
      this.updateError();
    }
  }

  onTypeaheadBlur(): void {
    this.onChange(null);
    this.onInputBlur();
  }

  onInputBlur(): void {
    this.markAsTouched();
    this.updateError();
  }

  private markAsTouched(): void {
    this.onTouched();

    this.renderer.removeClass(this.inputEl.nativeElement, 'ng-untouched');
    this.renderer.addClass(this.inputEl.nativeElement, 'ng-touched');
  }

  private updateError(): void {
    this.error = this.control.touched && this.control.status === 'INVALID';
    const validationError = getErrorMessageFromValidationErrors(this.control?.errors);

    this.finalErrorMessage =
      this.errorMessage ||
      (!!validationError ? this.translateService.instant(...validationError) : '');

    if (this.error) {
      this.renderer.removeClass(this.inputEl.nativeElement, 'ng-valid');
      this.renderer.addClass(this.inputEl.nativeElement, 'ng-invalid');
    } else {
      this.renderer.removeClass(this.inputEl.nativeElement, 'ng-invalid');
      this.renderer.addClass(this.inputEl.nativeElement, 'ng-valid');
    }
  }
}
