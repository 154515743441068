<div class="tsq-card">
  <div
    class="flex cursor-pointer justify-between border-b-1 border-petro-n2 p-16"
    [ngClass]="classes ?? ''"
    tabindex="0"
    (click)="open = !open; accordionStatus.emit(open)"
    (keyup.enter)="open = !open; accordionStatus.emit(open)"
    (keyup.space)="open = !open; accordionStatus.emit(open)"
  >
    <div class="flex items-center gap-8">
      <tsq-icon [icon]="icon" [classes]="'text-primary'"></tsq-icon>
      <h4 class="text-primary">{{ title }}</h4>
    </div>

    <div class="flex items-center gap-8">
      <ng-container *ngIf="hasArrows">
        <button
          data-cy="accordion--button--up-option"
          [disabled]="arrowUpDisabled"
          (click)="arrowUpClicked.emit(); $event.stopPropagation()"
        >
          <tsq-icon [icon]="icons.arrowUp"></tsq-icon>
        </button>

        <button
          data-cy="accordion--button--down-option"
          [disabled]="arrowDownDisabled"
          (click)="arrowDownClicked.emit(); $event.stopPropagation()"
        >
          <tsq-icon [icon]="icons.arrowDown"></tsq-icon>
        </button>
      </ng-container>

      <ng-container *ngTemplateOutlet="rightContent || wrongContent"></ng-container>

      <tsq-icon
        data-cy="accordion--icon--expand"
        [icon]="icons.chevron"
        [classes]="
          'text-primary transition duration-300 transform ' + (open ? '-rotate-90' : 'rotate-90')
        "
      >
      </tsq-icon>
    </div>
  </div>

  <div *ngIf="open" @slideInOut>
    <ng-content></ng-content>
  </div>
</div>
