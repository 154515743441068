<h1>{{ 'LOGIN.SSO.TITLE' | translate }}</h1>
<p class="text-md mb-0 mt-8">
  {{ 'LOGIN.SSO.DESCRIPTION' | translate }}
</p>

<div class="mt-40 flex flex-col gap-y-24">
  <tsq-button
    *ngIf="!loading; else loader"
    schema="new-primary"
    size="lg"
    [text]="'LOGIN.SSO.RELOAD_BUTTON' | translate"
    (clicked)="redirectToSso.emit()"
  />
  <ng-template #loader>
    <tsq-small-loader />
  </ng-template>

  <div class="flex justify-center">
    <a class="new-primary-link" (click)="backToLogin.emit()">{{
      'LOGIN.SSO.BACK_TO_LOGIN' | translate
    }}</a>
  </div>
</div>
