import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
  Self,
  ViewChild,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl } from '@angular/forms';

import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Observable, Observer, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Condo } from '@tsq-web/condo';
import { AppState } from '@tsq-web/state';
import * as fromUserContextSelectors from '@tsq-web/user-context/selectors';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { User } from '@tsq-web/users';

import { UserTypeaheadRequest } from '../../models/user-typeahead-request.model';
import { TSqUserTypeaheadService } from '../../services/tsq-user-typeahead.service';

@Component({
  selector: 'tsq-user-typeahead',
  templateUrl: './tsq-user-typeahead.component.html',
  styleUrls: ['./tsq-user-typeahead.component.scss'],
})
export class TSqUserTypeaheadComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @ViewChild('inputEl') inputEl: ElementRef;

  @Input() single = false;
  @Input() request: UserTypeaheadRequest;
  @Input() numberOfOptions = 7;
  @Input() minLength = 3;
  @Input() includeWrappers: boolean;
  @Input() selfHandleSelect = false;

  dataSource$: Observable<User[]>;

  abstractControl: AbstractControl;
  userInput: string;
  disabled: boolean;
  loading: boolean;
  error: boolean;

  onChanged: (user: User) => void;
  onTouched: () => void;

  private condo: Condo;

  private componentDestroyed$ = new Subject<void>();

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private renderer: Renderer2,
    private store: Store<AppState>,
    private translateService: TranslateService,
    private tsqUserTypeaheadService: TSqUserTypeaheadService,
  ) {
    ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    this.abstractControl = this.ngControl.control;

    this.dataSource$ = new Observable<User[]>((observer: Observer<User[]>) => {
      const source$ = this.tsqUserTypeaheadService.getUsers(
        this.userTypeaheadRequest,
        this.userInput,
      );
      source$.subscribe((users: User[]) => observer.next(users));
    });

    this.store
      .pipe(takeUntil(this.componentDestroyed$), select(fromUserContextSelectors.selectCondo))
      .subscribe(condo => (this.condo = condo));
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.unsubscribe();
  }

  get userTypeaheadRequest(): UserTypeaheadRequest {
    if (!!this.request) {
      return this.request;
    }

    return {
      url: `user/condo/${this.condo.id}`,
      searchable: true,
      includeWrappers: this.includeWrappers,
    };
  }

  get user(): User {
    return this.abstractControl.value as User;
  }

  get hasProperties(): boolean {
    return !!this.user.properties && !!this.user.properties.length;
  }

  get property(): string {
    return !!this.user.properties && this.user.properties.length > 1
      ? 'PROPERTY.MULTIPLE_PROPERTIES'
      : this.user.properties[0].fine_description;
  }

  registerOnChange(fn: (user: User) => void): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: User): void {
    this.userInput = !!value ? this.userFullName(value) : '';
  }

  onSelect(match: TypeaheadMatch): void {
    const user = match.item as User;
    this.userInput = this.userFullName(user);
    this.onTouched();
    this.onChanged(user);
    this.updateError();
  }

  onRemove(): void {
    this.userInput = '';
    this.onChanged(null);
  }

  onTypeaheadBlur(): void {
    this.onChanged(null);
    this.onInputBlur();
  }

  onInputBlur(): void {
    this.onTouched();
    this.updateError();
  }

  onKeyUp(event: KeyboardEvent): void {
    const eventContentArray = [event.code, event.key];
    if (eventContentArray.some(e => e === 'Backspace')) {
      this.onTypeaheadBlur();
    }
  }

  userFullName(user: User): string {
    return !!user.first_name
      ? `${user.first_name} ${user.last_name}`
      : `${user.firstName} ${user.lastName}`;
  }

  userHasProperty(user: User): boolean {
    return !!user && !!user.properties && user.properties.length > 0;
  }

  userPropertyText(user: User): string {
    if (!user?.properties) {
      return '';
    }
    if (user.properties.length > 1) {
      return this.translateService.instant('PROPERTY.MULTIPLE_PROPERTIES');
    }

    return user.properties.map(p => p.fine_description)[0];
  }

  private updateError(): void {
    this.error = this.abstractControl.touched && this.abstractControl.status === 'INVALID';

    if (this.error) {
      this.renderer.removeClass(this.inputEl.nativeElement, 'ng-valid');
      this.renderer.addClass(this.inputEl.nativeElement, 'ng-invalid');
    } else {
      this.renderer.removeClass(this.inputEl.nativeElement, 'ng-invalid');
      this.renderer.addClass(this.inputEl.nativeElement, 'ng-valid');
    }
  }
}
