/* eslint-disable @typescript-eslint/naming-convention */
import { BaseEnvironment, environment } from '@tsq-web/environment';

const { FROALA_LICENSE } = environment as BaseEnvironment;

export const richTextConfig = {
  toolbarButtons: {
    moreText: {
      buttons: [
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'fontFamily',
        'fontSize',
        'textColor',
      ],
    },
    moreParagraph: {
      buttons: [
        'alignLeft',
        'alignCenter',
        'formatOLSimple',
        'alignRight',
        'alignJustify',
        'formatOL',
        'formatUL',
        'paragraphFormat',
        'paragraphStyle',
        'outdent',
        'indent',
        'quote',
      ],
    },
    moreRich: {
      'buttons': [
        'insertLink',
        'insertImage',
        'insertTable',
        'specialCharacters',
        'insertFile',
        'insertHR',
      ],
    },
    moreMisc: {
      'buttons': [
        'undo',
        'redo',
        'selectAll',
        'html',
        'help',
      ],
      align: 'right',
      buttonsVisible: 2,
    },
  },
  quickInsertButtons: ['image', 'table', 'ul', 'ol', 'hr'],
  height: 400,
  placeholderText: '',
  key: FROALA_LICENSE,
  charCounterCount: false,
};
