import { StorePageV2, storePageV2InitialState } from './store-page.utils';

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface StoreList<T> {
  entities: T[];
  loading: boolean;
  loadingMore: boolean;
  error: boolean;
  allLoaded: boolean;
}

export interface StoreListWithPage<T> extends StoreList<T> {
  page: number;
}

export interface StoreListWithCursor<T> extends StoreList<T> {
  nextPage: string;
  previousPage: string;
  hasNextPage: boolean;
}

export interface StoreListWithPageV2<T> extends StorePageV2<T> {
  loadingMore: boolean;
  allLoaded: boolean;
}

export const storeListInitialState: StoreList<any> = {
  entities: [],
  loading: false,
  loadingMore: false,
  error: false,
  allLoaded: false,
};

export const storeListWithPageInitialState: StoreListWithPage<any> = {
  ...storeListInitialState,
  page: 0,
};

export const storeListWithCursorInitialState: StoreListWithCursor<any> = {
  ...storeListInitialState,
  nextPage: undefined,
  previousPage: undefined,
  hasNextPage: false,
};

export const storeListWithPageV2InitialState: StoreListWithPageV2<unknown> = {
  ...storePageV2InitialState,
  loadingMore: false,
  allLoaded: false,
};
