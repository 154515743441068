import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  Renderer2,
  ViewChild,
  forwardRef,
  inject,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';

import { provideComponentStore } from '@ngrx/component-store';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, combineLatest, map, startWith } from 'rxjs';

import { icons } from '@tsq-web/assets';

import { FormMultipleSelectionOption } from '../../../models/form-selection-option.model';
import { getErrorMessageFromValidationErrors } from '../../../utils/validators/tsq-validators.utils';
import { DropdownCheckboxFilterFn } from '../models/dropdown-checkbox-filter-fn.model';
import { ListStore } from '../state/list.store';

@Component({
  selector: 'tsq-dropdown-checkbox',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownCheckboxComponent),
      multi: true,
    },
    provideComponentStore(ListStore),
  ],
  template: `
    <ng-container
      *ngrxLet="{
        open: open$,
        list: list$,
        selectedDisplay: selectedDisplay$,
        optionsLoading: optionsLoading$
      } as vm"
    >
      <div class="text-input-wrapper relative">
        <input
          [id]="id || null"
          role="combobox"
          [attr.aria-expanded]="vm.open"
          type="text"
          class="control cursor-pointer !pr-48"
          style="outline-offset: 2px;"
          [placeholder]="
            vm.open || !!vm.selectedDisplay
              ? ''
              : placeholder || ('LIBS.FORMS.DROPDOWN_CHECKBOX.PLACEHOLDER' | translate)
          "
          [autocomplete]="false"
          [formControl]="filterControl"
          cdkOverlayOrigin
          (focus)="onInputFocus()"
          (click)="onInputClick()"
          (keydown.tab)="onInputTab()"
          (keydown.shift.tab)="onInputTab()"
          (keydown.esc)="onInputEsc()"
          (keydown.arrowDown)="onInputArrowDown()"
          #overlayOrigin="cdkOverlayOrigin"
          #input
        />

        <tsq-icon
          class="text-primary"
          [icon]="caretIcon"
          [classes]="
            'absolute right-16 top-8 pointer-events-none transition-transform ' +
            (vm.open && '-rotate-180')
          "
        />

        <div
          *ngIf="!vm.open && !!vm.selectedDisplay"
          class="pointer-events-none absolute inset-y-[9px] left-[17px] right-[49px] flex items-center"
        >
          <span class="grow truncate">{{ vm.selectedDisplay.title }}</span>

          <span
            class="small-text-bold text-coal-secondary shrink-0"
            *ngIf="vm.selectedDisplay.others > 0"
            [ngPlural]="vm.selectedDisplay.others"
          >
            <ng-template ngPluralCase="=1">
              {{ 'LIBS.FORMS.DROPDOWN_CHECKBOX.PLUS_OTHER' | translate }}
            </ng-template>
            <ng-template ngPluralCase="other">
              {{
                'LIBS.FORMS.DROPDOWN_CHECKBOX.PLUS_OTHERS'
                  | translate : { num: vm.selectedDisplay.others }
              }}
            </ng-template>
          </span>
        </div>

        <span *ngIf="error$ | async as error">{{ error }}</span>
      </div>

      <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="overlayOrigin"
        [cdkConnectedOverlayOpen]="vm.open"
        [cdkConnectedOverlayFlexibleDimensions]="true"
        [cdkConnectedOverlayViewportMargin]="16"
        [cdkConnectedOverlayOffsetY]="4"
        (overlayOutsideClick)="onOverlayOutsideClick($event)"
      >
        <ul
          role="listbox"
          class="bg-petro-p1 max-h-[328px] overflow-y-auto rounded p-4 shadow"
          [style.width.px]="input.getBoundingClientRect().width"
          (click)="onUlClick()"
          #ul
        >
          <li *ngIf="vm.optionsLoading; else content" class="py-8">
            <tsq-small-loader />
          </li>

          <ng-template #content>
            <li *ngIf="vm.list.length === 0; else list">
              <span class="text-coal-tertiary block w-full p-8 text-center">
                {{
                  'LIBS.FORMS.DROPDOWN_CHECKBOX.' +
                    (!!filterControl.value ? 'NO_OPTION_MATCHED_FILTER' : 'NO_OPTIONS') | translate
                }}
              </span>
            </li>
          </ng-template>

          <ng-template #list>
            <li
              class="flex items-center gap-8 p-8"
              (click)="onLiClick($event, li)"
              *ngFor="let item of vm.list; let index = index; let first = first; let last = last"
              #li
            >
              <input
                [id]="'dropdown-checkbox--input--option-' + index"
                type="checkbox"
                class="shrink-0"
                tsqCheckbox
                [disabled]="item.option.isDisabled"
                [ngModel]="item.selected"
                (ngModelChange)="onCheckboxChange($event, item.option)"
                (keydown.esc)="onCheckboxEsc()"
                (keydown.tab)="onCheckboxTab()"
                (keydown.shift.tab)="onCheckboxTab()"
                (keydown.arrowDown)="onCheckboxArrowVertical($event, li, first, last)"
                (keydown.arrowUp)="onCheckboxArrowVertical($event, li, first, last)"
                (keydown.arrowLeft)="onCheckboxArrowHorizontal()"
                (keydown.arrowRight)="onCheckboxArrowHorizontal()"
              />

              <label
                [for]="'dropdown-checkbox--input--option-' + index"
                class="font-regular mb-0 line-clamp-2 grow break-words"
                [class.text-coal-secondary]="item.option.isDisabled"
              >
                {{ item.option.text }}
              </label>
            </li>
          </ng-template>
        </ul>
      </ng-template>
    </ng-container>
  `,
  styles: [
    `
      :host {
        &.ng-touched.ng-invalid tsq-icon {
          @apply text-red;
        }

        input[disabled] ~ tsq-icon {
          @apply text-coal-tertiary;
        }
      }
    `,
  ],
})
export class DropdownCheckboxComponent implements AfterViewInit, OnDestroy, ControlValueAccessor {
  @ViewChild('input') protected inputElRef: ElementRef<HTMLInputElement>;
  @ViewChild('ul') protected ulElRel: ElementRef<HTMLUListElement>;

  @Input() id: string;
  @Input() placeholder: string;
  @Input() focusOnInit = false;
  @Input() filterFn: DropdownCheckboxFilterFn;

  private readonly openSubject = new BehaviorSubject<boolean>(false);
  readonly open$ = this.openSubject.asObservable();

  private readonly listStore = inject(ListStore);
  protected readonly selectedDisplay$ = this.listStore.selectedList$.pipe(
    map(list => {
      if (!list || list.length === 0) {
        return null;
      }

      return {
        title: list[0].option.text,
        others: list.length - 1,
      };
    }),
  );
  protected readonly optionsLoading$ = this.listStore.optionsLoading$;

  protected readonly filterControl = new FormControl<string>('', { nonNullable: true });
  protected readonly list$ = combineLatest([
    this.listStore.list$,
    this.filterControl.valueChanges.pipe(startWith('')),
  ]).pipe(
    map(([list, filter]) => {
      if (!filter) {
        return list;
      }

      return list.filter(item => {
        if (!!this.filterFn) {
          return this.filterFn(item.option, filter);
        }

        return item.option.text.toLocaleLowerCase().startsWith(filter.toLocaleLowerCase());
      });
    }),
  );

  private readonly errorSubject = new BehaviorSubject<string | null>(null);
  protected readonly error$ = this.errorSubject.asObservable();

  protected readonly caretIcon = icons.caret;

  private onChange: (options: FormMultipleSelectionOption[]) => void;
  private onTouched: () => void;

  private readonly injector = inject(Injector);
  private readonly translate = inject(TranslateService);
  private readonly hostClassMutationObserver = new MutationObserver(
    (mutations: MutationRecord[]) => {
      mutations.forEach(mutation => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          Array.from(this.inputElRef.nativeElement.classList)
            .filter(c => c.startsWith('ng-'))
            .forEach(c => {
              this.renderer.removeClass(this.inputElRef.nativeElement, c);
            });

          Array.from(this.rootEl.classList)
            .filter(c => c.startsWith('ng-'))
            .forEach(c => {
              this.renderer.addClass(this.inputElRef.nativeElement, c);
            });

          if (
            this.rootEl.classList.contains('ng-invalid') &&
            this.rootEl.classList.contains('ng-touched')
          ) {
            const control = this.injector.get(NgControl)?.control;

            if (!!control) {
              const validationError = getErrorMessageFromValidationErrors(control?.errors);
              this.errorSubject.next(this.translate.instant(...validationError));

              return;
            }
          }

          this.errorSubject.next(null);
        }
      });
    },
  );

  private readonly rootEl = inject<ElementRef<HTMLElement>>(ElementRef).nativeElement;
  private readonly renderer = inject(Renderer2);

  ngAfterViewInit(): void {
    if (this.focusOnInit) {
      this.focus();
    }

    this.hostClassMutationObserver.observe(this.rootEl, {
      attributes: true,
      attributeFilter: ['class'],
    });
  }

  ngOnDestroy(): void {
    this.hostClassMutationObserver.disconnect();
  }

  writeValue(value: FormMultipleSelectionOption[]): void {
    this.listStore.setOutsideSelectedOptions(value);
  }

  registerOnChange(fn: typeof this.onChange): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: typeof this.onTouched): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.filterControl.disable();

      this.close();
    } else {
      this.filterControl.enable();
    }
  }

  @Input()
  set options(value: FormMultipleSelectionOption[] | Observable<FormMultipleSelectionOption[]>) {
    this.listStore.setOptions(value);
  }

  get isOpen(): boolean {
    return this.openSubject.value;
  }

  open(): void {
    if (this.filterControl.enabled) {
      this.openSubject.next(true);
    }
  }

  close(): void {
    this.openSubject.next(false);
  }

  focus(): void {
    this.inputElRef?.nativeElement?.focus();
  }

  protected closeAndMarkAsTouched(): void {
    this.close();
    this.onTouched();
    this.filterControl.setValue('');
  }

  protected onInputFocus(): void {
    setTimeout(() => this.open());
  }

  protected onInputClick(): void {
    if (!this.openSubject.value && document.activeElement === this.inputElRef.nativeElement) {
      this.open();
    }
  }

  protected onInputTab(): void {
    this.closeAndMarkAsTouched();
  }

  protected onInputEsc(): void {
    if (!this.openSubject.value) {
      this.filterControl.setValue('');
    }

    this.close();
  }

  protected onOverlayOutsideClick(event: PointerEvent): void {
    if (!this.rootEl.contains(event.target as Node)) {
      this.closeAndMarkAsTouched();
    }
  }

  protected onInputArrowDown(): void {
    if (!this.openSubject.value) {
      this.open();
    }

    setTimeout(() => {
      Array.from(this.ulElRel.nativeElement.children).some(liEl => {
        const checkbox = liEl.children[0]?.children[0] as HTMLInputElement;

        if (!!checkbox && !checkbox.disabled) {
          checkbox.focus();

          return true;
        }

        return false;
      });
    });
  }

  protected onUlClick(): void {
    this.focus();
  }

  protected onLiClick(event: PointerEvent, liEl: HTMLLIElement): void {
    const input = liEl.children[0]?.children[0] as HTMLInputElement;

    if (!!input && !input.disabled) {
      input.focus();
      event.stopPropagation();

      return;
    }

    this.focus();
  }

  protected onCheckboxChange(value: boolean, option: FormMultipleSelectionOption): void {
    this.listStore.toggleListItem({ value, option });

    this.onChange(this.listStore.selectedList);
  }

  protected onCheckboxEsc(): void {
    this.closeAndMarkAsTouched();
  }

  protected onCheckboxTab(): void {
    requestAnimationFrame(() => {
      if (!this.ulElRel.nativeElement.contains(document.activeElement)) {
        this.focus();
      }
    });
  }

  protected onCheckboxArrowVertical(
    event: KeyboardEvent,
    li: HTMLLIElement,
    first: boolean,
    last: boolean,
  ): void {
    let dir = 0;
    if (event.code === 'ArrowDown') {
      dir = 1;
    } else if (event.code === 'ArrowUp') {
      dir = -1;
    }

    if (dir === 0) {
      return;
    }
    if ((dir === -1 && first) || (dir === 1 && last)) {
      this.focus();

      return;
    }

    let sibling = dir === 1 ? li.nextElementSibling : li.previousElementSibling;
    let siblingCheckbox: HTMLInputElement;
    while (sibling !== null) {
      if (sibling.tagName === 'LI') {
        const checkbox = sibling.children?.[0]?.children?.[0] as HTMLInputElement;

        if (!!checkbox && !checkbox.disabled) {
          siblingCheckbox = checkbox;
          break;
        }
      }

      sibling = dir === 1 ? sibling.nextElementSibling : sibling.previousElementSibling;
    }

    if (!siblingCheckbox) {
      this.focus();

      return;
    }

    siblingCheckbox.focus();
  }

  protected onCheckboxArrowHorizontal(): void {
    this.focus();
  }

  @HostListener('window:resize')
  private onWindowResize(): void {
    this.close();
  }
}
