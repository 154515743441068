<div class="tsq-card">
  <div class="info-header">
    <img *ngIf="icon" [src]="icon" alt="" />

    <ng-content select=".before-info"></ng-content>

    <div
      class="info"
      [class.check-info]="infoClick.observers.length !== 0"
      (click)="infoClick.emit()"
    >
      <div class="flex items-center">
        <ng-content select=".before-title"></ng-content>
        <div
          *ngIf="hasNewHighlight; else legacyHighlight"
          class="title"
          [ngClass]="{
            'title-avocado': titleColor === 'avocado',
            'title-battleship-grey': titleColor === 'battleship-grey'
          }"
          [tsqHighlight]="highlight"
        >
          {{ title | translate }}
        </div>
        <ng-template #legacyHighlight>
          <div
            class="title"
            [ngClass]="{
              'title-avocado': titleColor === 'avocado',
              'title-battleship-grey': titleColor === 'battleship-grey'
            }"
            [innerHtml]="title | translate | tsqSearchHighlight : highlight"
          ></div>
        </ng-template>
      </div>
      <div *ngIf="!!subtitle" class="subtitle">{{ subtitle | translate }}</div>
    </div>

    <div class="after-info-container">
      <ng-content select=".after-info"></ng-content>
    </div>

    <div class="actions-container">
      <ng-content select=".actions"></ng-content>
    </div>
  </div>

  <div *ngIf="hasInfo" class="divider"></div>

  <div *ngIf="hasInfo">
    <ng-content select=".info"></ng-content>
  </div>
</div>
