import { environment } from '@tsq-web/environment';

export interface TranslateResource {
  prefix: string;
  suffix: string;
}

export const multiTranslateResources: TranslateResource[] = [
  { prefix: './assets/i18n/', suffix: '.json' },
  { prefix: './assets/i18n/libs-', suffix: '.json' },
];

export const environmentBasedMultiTranslateResources: TranslateResource[] = [
  { prefix: `${environment['i18n']}/assets/i18n/`, suffix: '.json' },
  { prefix: `${environment['i18n']}/assets/i18n/libs-`, suffix: '.json' },
];
