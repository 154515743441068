import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { FeatureGuard } from '@tsq-web/feature';
import { featureFlagGuard } from '@tsq-web/feature-flag';
import { LoginRequiredGuard } from '@tsq-web/login';

import { FeatureFlags } from '../feature-flag/feature-flags.model';
import { expiredSubscriptionTrialRoute } from './+expired-subscription-trial/expired-subscription-trial.route';
import { neighborInviteRegisterRoute } from './+neighbor-invite-register/neighbor-invite-register.route';
import { userEmailChangeRoutes } from './+user-email-change/user-email-change.routes';
import { userRecoveryRoutes } from './+user-recovery/user-recovery.routes';
import { userRegisterRoutes } from './+user-register/user-register.routes';
import { CondoSelectionComponent } from './condo/+condo-selection/condo-selection.component';
import { getFeatureToggle } from './shared/feature-toggle';

const routes: Routes = [
  {
    path: 'logout',
    loadChildren: () => import('./+logout/logout.module').then(m => m.LogoutModule),
  },
  ...userRegisterRoutes,
  ...userRecoveryRoutes,
  ...userEmailChangeRoutes,
  ...neighborInviteRegisterRoute,
  ...expiredSubscriptionTrialRoute,
  {
    path: 'condo-selection',
    component: CondoSelectionComponent,
    canActivate: [LoginRequiredGuard],
  },
  {
    path: 'w',
    loadChildren: () =>
      import('../app/+sc-workspace/workspace.module').then(m => m.WorkspaceModule),
  },
  {
    path: 'd',
    loadChildren: () => import('./+dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'audit-logs',
    loadChildren: () => import('../app/+audit-logs/audit-logs.module').then(m => m.AuditLogsModule),
  },
  {
    path: 'unsubscribe',
    loadChildren: () =>
      import('../app/+public-email-unsubscribe/public-email-unsubscribe.module').then(
        m => m.PublicEmailUnsubscribeModule,
      ),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./+global-profile/global-profile.module').then(m => m.GlobalProfileModule),
    canActivate: [LoginRequiredGuard],
  },
  {
    path: 'inspector',
    loadChildren: () => import('./pages/inspector/inspector.module').then(m => m.InspectorModule),
  },
  {
    path: 'self-service',
    loadChildren: () =>
      import('./pages/self-service/self-service.module').then(m => m.SelfServiceModule),
  },
  {
    path: 'easy-onboarding',
    loadChildren: () =>
      import('./pages/easy-onboarding/easy-onboarding.module').then(m => m.EasyOnboardingModule),
  },
  {
    path: 'registration',
    loadChildren: () =>
      import('./pages/registration/registration.module').then(m => m.RegistrationModule),
    canActivate: [FeatureGuard],
    data: { featureToggle: getFeatureToggle().registration.signUp },
  },
  {
    path: 'terms-acceptance',
    loadChildren: () =>
      import('./pages/terms-acceptance/terms-acceptance.module').then(m => m.TermsAcceptanceModule),
    canActivate: [FeatureGuard],
    data: { featureToggle: getFeatureToggle().termsOfUse.login },
  },
  {
    path: 'invoice-details',
    loadChildren: () =>
      import('./pages/vendor-smart-invoice-details/vendor-smart-invoice-details.module').then(
        m => m.VendorSmartInvoiceDetailsModule,
      ),
  },
  {
    path: 'select-community',
    loadChildren: () =>
      import('./pages/condo-selection-v2/condo-selection-v2.module').then(
        m => m.CondoSelectionV2Module,
      ),
    canActivate: [
      LoginRequiredGuard,
      featureFlagGuard<FeatureFlags>('condoSelectionV2', '/condo-selection'),
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
