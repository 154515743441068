<div class="bg-yellow-n1 border-1 border-yellow-p1 flex w-full justify-between rounded p-16 shadow">
  <div class="flex flex-col">
    <span class="text-base font-bold">
      {{ 'LOGIN.LINK_EXPIRED' | translate }}
    </span>

    <p class="small-text mb-0">
      {{ 'LOGIN.LINK_EXPIRED_DESCRIPTION' | translate }}
    </p>
  </div>

  <div>
    <button class="focus:outline" (click)="closeBanner.emit()">
      <tsq-icon [icon]="xmarkIcon" [classes]="'text-yellow-p1 w-16 h-16'" />
    </button>
  </div>
</div>
