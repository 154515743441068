import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { C3AccountIdFormatPipe } from './pipes/c3-account-id-format.pipe';
import { RichTextPipe } from './pipes/rich-text.pipe';
import { TSqSearchHighlightPipe } from './pipes/tsq-search-highlight.pipe';
import { SentenceCasePipe } from './pipes/sentence-case.pipe';
import { FormatBytesPipe } from './pipes/format-bytes.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    TSqSearchHighlightPipe,
    SentenceCasePipe,
    RichTextPipe,
    C3AccountIdFormatPipe,
    FormatBytesPipe,
  ],
  exports: [
    TSqSearchHighlightPipe,
    SentenceCasePipe,
    RichTextPipe,
    C3AccountIdFormatPipe,
    FormatBytesPipe,
  ],
})
export class TSqPipesModule {}
