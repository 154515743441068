import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { delay } from 'rxjs/operators';

@UntilDestroy()
@Directive({
  selector: '[tsqListInsufficientItems]',
})
export class ListInsufficientItemsDirective implements OnInit {
  @Input() scrollableContainer: string;

  @Output() loadMore = new EventEmitter();

  private existingItemsSubject$ = new BehaviorSubject<number>(0);

  ngOnInit(): void {
    this.existingItemsSubject$
      .pipe(delay(100), untilDestroyed(this))
      .subscribe((existingItems: number) => {
        const htmlElement = document.querySelector(this.scrollableContainer);
        if (
          !!existingItems &&
          !!htmlElement?.clientHeight &&
          htmlElement.clientHeight === htmlElement.scrollHeight
        ) {
          this.loadMore.emit();
        }
      });
  }

  @Input() set existingItems(val: number) {
    this.existingItemsSubject$.next(val);
  }
}
