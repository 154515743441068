import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { IMaskModule } from 'angular-imask';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { TSqLoadersModule } from '@tsq-web/loaders';

import { ResidentsInviteService } from '../+sc-workspace/features/residents/services/residents-invite.service';
import { SCWidgetModule } from '../shared/component/sc.widget.module';
import { IntlPhoneNumberComponent } from '../shared/intl-phone-input/intl-phone-input.component';
import { PropertyService } from '../shared/property/property.service';
import { NeighborInviteRegisterComponent } from './neighbor-invite-register.component';
import { neighborInviteRegisterRoute } from './neighbor-invite-register.route';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SCWidgetModule,
    RouterModule.forChild(neighborInviteRegisterRoute),
    TranslateModule,
    IMaskModule,
    TypeaheadModule,
    TSqLoadersModule,
    IntlPhoneNumberComponent,
  ],
  exports: [NeighborInviteRegisterComponent],
  declarations: [NeighborInviteRegisterComponent],
  providers: [ResidentsInviteService, PropertyService],
})
export class NeighborInviteRegisterModule {}
