import { Injectable } from '@angular/core';

import { getEnvBasedAssetsPath } from '../utils/assets.utils';

@Injectable()
export class AssetsService {
  getEnvBasedAssetsPath(path: string): string {
    return getEnvBasedAssetsPath(path);
  }
}
