/* eslint-disable @typescript-eslint/naming-convention */
import { Condo } from '@tsq-web/condo';
import { Role } from '@tsq-web/permission';
import { Property } from '@tsq-web/property';

import { CategoryNotificationSetting } from './category-notification-setting.model';
import { DefaultLandingPages } from './default-landing-pages.enum';
import { MobileNotificationSettings } from './mobile-notification-settings.model';
import { Phone } from './phone.model';
import { SMSNotificationSettings } from './sms-notification-settings.model';
import { Vehicle } from './vehicle.model';

export class User {
  currentCondoId: string;
  condo: Condo;
  username: string;
  password: string;
  auth_token: string;
  fb_user: string = null;
  login_type: string;
  first_name: string;
  firstName: string;
  name: string;
  last_name = '';
  lastName: string;
  preferredName: string;
  id: string;
  pictureUrl: string;
  smallPictureUrl: string;
  phone: string;
  phone2: string;
  documentNumber: string;
  billingDocument: string;
  email: string;
  deleted: boolean;
  logonToken: string;
  platform: string;
  inDebit: boolean;
  hasLoggedIn: boolean;
  invalidEmail: boolean;
  lastLoginTime: Date;
  creationDate: number;
  linkedToAssociaAccount = false;
  hasAccount: boolean;
  properties: Property[] = [];
  userProperties: Property[] = [];
  roles: Role[] = [];
  vehicles: Vehicle[] = [];
  phones: Phone[] = [];
  canEditPassword = true;
  organizationId: string;
  mobileNotificationSettings?: MobileNotificationSettings;
  smsNotificationSettings?: SMSNotificationSettings;
  categoryNotificationSetting?: CategoryNotificationSetting;
  defaultLandingPage?: DefaultLandingPages;
  displayName?: string;

  static updateDelinquency(user: User, property: Property): void {
    let isUserInDebit = false;
    user.properties.forEach(userProperty => {
      if (userProperty.id === property.id) {
        userProperty.inDebit = property.inDebit;
      }
      isUserInDebit = isUserInDebit || userProperty.inDebit;
    });
    user.inDebit = isUserInDebit;
  }
}
