import { AfterViewInit, Directive, Input, OnDestroy, SimpleChange } from '@angular/core';

import { IconComponent, icons } from '@tsq-web/assets';

import { AbstractInputDirective } from '../abstract-input/abstract-input.directive';

@Directive({
  selector: 'input[tsqTextInput],textarea[tsqTextInput]',
  exportAs: 'tsqTextInput',
})
export class TextInputDirective extends AbstractInputDirective implements AfterViewInit, OnDestroy {
  private _icon: string;

  ngAfterViewInit(): void {
    super.afterViewInit();
  }

  @Input() set icon(value: string) {
    this._icon = value;

    if (!!this.wrapperEl) {
      this.updateControl();
    }
  }

  private get isTextArea(): boolean {
    return this.el?.tagName.toLowerCase() === 'textarea';
  }

  updateControl(): void {
    super.updateErrorLabel();

    if (this.control?.touched && this.control?.invalid) {
      this.addIcon(this._icon || icons.triangleExclamation, 'red');
    } else if (!!this.errorEl) {
      this.addIcon(undefined);
    }

    if (!this.errorEl) {
      this.addIcon(this._icon, this.control?.disabled ? 'disabled' : 'primary');
    }
  }

  private addIcon(icon: string, schema?: 'primary' | 'red' | 'disabled'): void {
    this.viewContainerRef.clear();

    if (!!icon) {
      let schemaClass: string;
      switch (schema) {
        case 'red':
          schemaClass = 'text-red';
          break;
        case 'disabled':
          schemaClass = 'text-coal-secondary';
          break;
        default:
          schemaClass = 'text-primary';
          break;
      }

      const compRef = this.viewContainerRef.createComponent(IconComponent);

      const iconEl = compRef.location.nativeElement as Element;
      this.renderer.appendChild(this.wrapperEl, iconEl);

      const rightClass = this.isTextArea ? 'right-8' : 'right-16';
      const cursorClass = schema === 'disabled' ? 'cursor-not-allowed' : 'cursor';
      compRef.instance.icon = icon;
      compRef.instance.classes = `${schemaClass} absolute top-8 ${rightClass} ${cursorClass}`;
      // eslint-disable-next-line @angular-eslint/no-lifecycle-call
      compRef.instance.ngOnChanges({ icon: new SimpleChange(undefined, icon, false) });

      this.renderer.setStyle(this.el, 'padding-right', this.isTextArea ? '32px' : '48px');
    } else {
      this.renderer.removeStyle(this.el, 'padding-right');
    }
  }
}
