import { Component, Input } from '@angular/core';

@Component({
  selector: 'tsq-zero-data',
  templateUrl: './zero-data.component.html',
  styleUrls: ['./zero-data.component.scss'],
})
export class ZeroDataComponent {
  @Input() title = 'LIBS.ZERO_DATA_TITLE';
  @Input() description = '';
}
