<div class="bg-petro-n1 font-base flex h-full items-center justify-center">
  <div class="flex flex-col items-center gap-48 px-16 md:h-full">
    <img [src]="logoPath" [alt]="'TOWNSQ_LOGO' | translate" class="md:mt-120 block w-[176px]" />

    <div
      [ngSwitch]="currentStep$ | async"
      class="border-petro-n2 border-1 rounded-lg bg-white px-40 py-48 md:w-[504px]"
    >
      <comm-user-recovery-invalid-token *ngSwitchCase="steps.InvalidToken" />

      <comm-user-recovery-new-password-form
        *ngSwitchCase="steps.Form"
        [isLoading]="isChangingPassword$ | async"
        (changePassword)="changePassword($event)"
        (backToLogin)="backToLogin()"
      />

      <ng-container *ngSwitchCase="steps.Success">
        <h1>{{ 'RECOVERY.PASSWORD_CHANGED_SUCCESSFULLY' | translate }}</h1>
        <p class="text-md mb-0 mt-8">
          {{ 'RECOVERY.PASSWORD_CHANGED_SUCCESSFULLY_MESSAGE' | translate }}
        </p>

        <tsq-button
          schema="new-primary"
          size="lg"
          class="mt-40"
          [text]="'RECOVERY.BACK_TO_LOGIN' | translate"
          (clicked)="backToLogin()"
        />
      </ng-container>

      <div *ngSwitchDefault class="flex justify-center">
        <tsq-loader />
      </div>
    </div>
  </div>
</div>
