import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'tsq-chips-list',
  templateUrl: './tsq-chips-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TSqChipsListComponent {
  @Input() options: string[];
  @Input() invalidMultiple: number[];
  @Input() selected: number;
  @Input() selectedMultiple: number[];
  @Input() allSelected: boolean;
  @Input() hasCheck = true;
  @Input() hasRemove = false;
  @Input() disabled = false;
  @Input() lockMultiple: number[];
  @Input() isNewDesign = false;
  @Input() schema: 'new-primary' | 'primary' = 'primary';

  @Output() selectedChange = new EventEmitter<number>();
  @Output() optionRemoved = new EventEmitter<number>();

  isSelected(index: number): boolean {
    return (
      this.allSelected ||
      ((!!this.selected || this.selected === 0) && this.selected === index) ||
      (!!this.selectedMultiple && this.selectedMultiple.some(i => i === index))
    );
  }

  isInvalid(index: number): boolean {
    return (
      !!this.invalidMultiple &&
      this.invalidMultiple.some((invalidIndex: number) => invalidIndex === index)
    );
  }

  hasLock(index: number): boolean {
    return !!this.lockMultiple && this.lockMultiple.some(i => i === index);
  }

  onChipSelected(index: number): void {
    if (!this.disabled) {
      this.selectedChange.emit(index);
    }
  }
}
