export enum Feature {
  TrusteeDiscussion = 'TrusteeDiscussion',
  ResidentDiscussion = 'ResidentDiscussion',
  Calendars = 'Calendars',
  Lobby = 'Lobby',
  Discussion = 'Discussion',
  Documents = 'Documents',
  Neighbors = 'Neighbors',
  Poll = 'Poll',
  Alerts = 'Alerts',
  Assignments = 'Assignments',
  Facility = 'Facility',
  Properties = 'Properties',
  PackageDelivery = 'PackageDelivery',
  Settings = 'Settings',
  Dashboard = 'Dashboard',
  UserGroups = 'UserGroups',
  Occupants = 'Occupants',
  Website = 'Website',
  Communication = 'Communication',
  TrialsManagement = 'TrialsManagement',
  ShiftLogs = 'ShiftLogs',
  Plans = 'Plans',
  Workspace = 'Workspace',
  AuditLogs = 'AuditLogs',
  Portfolio = 'Portfolio',
  ArchitecturalReview = 'ArchitecturalReview',
  PerformanceReview = 'Insights',
  BusinessManagement = 'BusinessManagement',
  Workflow = 'Workflow',
  DigitalVoting = 'DigitalVoting',
  InspectorAppAccess = 'InspectorAppAccess',
  Violations = 'Violations',
  OfferService = 'OfferService',
  Billing = 'Billing',
  Reports = 'Reports',
  Accounts = 'Accounts',
  ChangeContext = 'ChangeContext',
  MyFinances = 'MyFinances',
  ViewUsersFullNames = 'ViewUsersFullNames',
  DigitalVotingCrossSell = 'DigitalVotingCrossSell',
  TrusteeComment = 'TrusteeComment',
  AssignmentComment = 'AssignmentComment',
  AccountPaymentReminder = 'AccountPaymentReminder',
  MailOrders = 'MailOrders',
  DashboardUserManagement = 'DashboardUserManagement',
  DashboardCommunityManagement = 'DashboardCommunityManagement',
  CommunitySubscriptionManagement = 'CommunitySubscriptionManagement',
  DashboardPlansManagement = 'DashboardPlansManagement',
  Administrators = 'Administrators',
  CondoAccess = 'CondoAccess',
  Subscription = 'Subscription',
  PaymentInfo = 'PaymentInfo',
  AnalyticsInsights = 'AnalyticsInsights',
  ArchitecturalReviewComment = 'ArchitecturalReviewComment',
  ArchitecturalReviewCategories = 'ArchitecturalReviewCategories',
  DashboardArchitecturalReviewManagement = 'DashboardArchitecturalReviewManagement',
  ActionItem = 'ActionItem',
  UserCustomFields = 'UserCustomFields',
  BonvoyNumber = 'BonvoyNumber',
  PetRegistry = 'PetRegistry',
  ValetParking = 'ValetParking',
  InkOrdersBackOffice = 'InkOrdersBackOffice',
  ServiceLogs = 'ServiceLogs',
  EmergencyBroadcast = 'EmergencyBroadcast',
  ConciergeHub = 'ConciergeHub',
  KeyManagement = 'KeyManagement',
  Automations = 'Automations',
  InkOrdersApprover = 'InkOrdersApprover',
  CarRequests = 'CarRequests',
  ReplySuggestion = 'ReplySuggestion',
  RequestInterception = 'RequestInterception',
}
