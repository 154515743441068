import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { TSqValidators } from '@tsq-web/forms';

@Component({
  selector: 'comm-user-recovery-request-link-card',
  templateUrl: './request-link-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestLinkCardComponent {
  @Output() linkRequested = new EventEmitter<string>();

  @Input() isLoading = false;
  @Input() isSso = false;

  readonly email = new FormControl('', [Validators.required, TSqValidators.validEmail]);

  requestLink(): void {
    if (this.email.invalid) {
      return;
    }

    this.linkRequested.emit(this.email.value);
  }
}
