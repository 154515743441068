<div class="relative grid h-full grid-cols-1 md:grid-cols-3">
  <section class="bg-green md:col-span-1">
    <div class="m-40">
      <img
        src="./assets/brand/white-logo.svg"
        class="h-56"
        alt="{{ 'LOGIN.TOWNSQ_LOGO' | translate }}"
      />
    </div>
  </section>
  <section class="col-span-2 flex flex-col">
    <div class="mx-auto flex h-full items-center justify-center">
      <div class="w-[380px]">
        <div *ngIf="isSent" class="public-col-message">
          <h1>{{ 'RECOVERY.PASSWORD_CHANGED_TITLE' | translate }}</h1>
          <p>{{ 'RECOVERY.PASSWORD_CHANGED_MESSAGE' | translate }}</p>
        </div>

        <form accept-charset="UTF-8" role="form" *ngIf="!isSent" [formGroup]="form">
          <fieldset>
            <div class="form-group">
              <label for="password">{{ 'RECOVERY.PASSWORD' | translate }}</label>
              <input
                tsqPeekablePassword
                formControlName="password"
                type="password"
                class="control"
                id="user-recovery--input--password"
                placeholder="{{ 'RECOVERY.PASSWORD' | translate }}"
                required
                name="password"
              />
            </div>

            <div class="form-group">
              <label for="password2">{{ 'RECOVERY.CONFIRM_PASSWORD' | translate }}</label>
              <input
                tsqPeekablePassword
                formControlName="passwordConfirmation"
                type="password"
                class="control"
                id="user-recovery--input--password2"
                placeholder="{{ 'RECOVERY.CONFIRM_PASSWORD' | translate }}"
                required
                name="password2"
              />
              <span class="label-text text-red mt-4" *ngIf="!!form?.errors?.confirmationInvalid">
                {{ 'ACCOUNT.VALIDATORS.PASSWORDS_DONT_MATCH' | translate | uppercase }}
              </span>
            </div>

            <div class="mb-24 flex w-full justify-end">
              <tsq-button
                [text]="'RECOVERY.CONFIRM_RESET' | translate"
                [disabled]="isDisabled"
                (clicked)="onSubmit()"
              ></tsq-button>
            </div>
          </fieldset>
        </form>

        <div class="text-blue text-center">
          <a routerLink="/login">{{ 'RECOVERY.BACK_TO_LOGIN' | translate }}</a>
        </div>
      </div>
    </div>
    <div *ngIf="isPortuguese" class="mb-[20px] w-full justify-end">
      <comm-social-media-footer-pt-br />
    </div>
  </section>
</div>
