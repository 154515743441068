import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Icon, TSqIconPickerIconList } from '../../utils/icons.utils';

@Component({
  selector: 'tsq-icon-picker',
  templateUrl: './tsq-icon-picker.component.html',
  styleUrls: ['./tsq-icon-picker.component.scss'],
})
export class TSqIconPickerComponent {
  @Input() color: string;
  @Output() iconChange = new EventEmitter<string>();

  iconList: TSqIconPickerIconList = new TSqIconPickerIconList();

  @Input() set icon(code: string) {
    if (!code) {
      this.resetIcons();
    } else {
      const index = this.iconList.icons.findIndex(icon => icon.code === code);
      if (index >= 0) {
        this.iconList.icons[index].highlighted = true;
        this.iconList.icons[index].selected = true;
        this.updateIcons(this.iconList.icons[index].code);
      }
    }
  }

  onIconSelected(icon: Icon): void {
    this.iconChange.emit(icon ? icon.code : undefined);
  }

  private resetIcons(): void {
    this.iconList = new TSqIconPickerIconList();
  }

  private updateIcons(code: string): void {
    for (const icon of this.iconList.icons) {
      icon.highlighted = icon.code === code;
      icon.selected = icon.code === code;
    }
  }
}
