/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/prefer-enum-initializers */
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';

import { Subject } from 'rxjs';
import { debounceTime, finalize } from 'rxjs/operators';

import { StripeService } from '../../../shared/stripe/stripe.service';
import { StripePaymentInfo } from '../../../shared/subscription/stripe-payment-info.json';
import { getEnvironment } from '../../../shared/environment';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'credit-card-billing-selection-edit',
  templateUrl: './credit-card-billing-selection-edit.component.html',
  styleUrls: ['./credit-card-billing-selection-edit.component.scss'],
  providers: [StripeService],
})
export class CreditCardBillingSelectionEditComponent implements AfterViewInit {
  @Output() usBillingChanged = new EventEmitter<StripePaymentInfo>();
  @Output() cardValid = false;

  stripe: any;
  elements: any;
  card: any;
  changeHandler = this.onChange.bind(this);
  error: string;
  serviceError: boolean;
  waitingToken = false;
  stripePaymentInfo: StripePaymentInfo;
  cardValidObserver$: Subject<boolean> = new Subject<boolean>();

  constructor(private cdRef: ChangeDetectorRef, private stripeService: StripeService) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    this.stripe = (window as unknown as { Stripe: (key: string) => unknown }).Stripe(
      getEnvironment().STRIPE_KEY_TSQ,
    );
    this.elements = this.stripe.elements();

    this.cardValidObserver$.pipe(debounceTime(1000)).subscribe(() => {
      this.getCardToken();
    });
  }

  ngAfterViewInit(): void {
    this.card = this.elements.create('card');
    this.card.mount('#card');
    this.card.addEventListener('change', this.changeHandler);
  }

  onChange(change: { error: { message: string }; complete: boolean }): void {
    if (change.error) {
      this.error = change.error.message;
      this.cardValid = false;
      this.emitCardUndefined();
    } else {
      this.error = undefined;
      this.cardValid = change.complete;
      if (this.cardValid) {
        this.cardValidObserver$.next(true);
      } else {
        this.emitCardUndefined();
      }
    }
    this.cdRef.detectChanges();
  }

  initUsBillingSelection(stripeInfo: StripePaymentInfo): void {
    this.stripePaymentInfo = stripeInfo;
    this.cdRef.detectChanges();
  }

  clearStripeInput(): void {
    this.card.clear();
    this.stripePaymentInfo = undefined;
  }

  emitCardUndefined(): void {
    this.usBillingChanged.emit(undefined);
    this.stripePaymentInfo = undefined;
  }

  get cardBrandImgName(): string {
    if (!!this.stripePaymentInfo && !!this.stripePaymentInfo.brand) {
      switch (this.stripePaymentInfo.brand.toLocaleUpperCase()) {
        case CardBrand[CardBrand.MASTER_CARD]:
          return 'icon-card-MasterCard';
        case CardBrand[CardBrand.VISA]:
          return 'icon-card-Visa';
        case CardBrand[CardBrand.AMERICAN_EXPRESS]:
          return 'icon-card-AmericanExpress';
        default:
          return 'icon-card-generic';
      }
    } else {
      return 'icon-card-generic';
    }
  }

  private getCardToken(): void {
    this.waitingToken = true;
    this.stripeService
      .tokenizeCard(this.stripe, this.card)
      .pipe(finalize(() => (this.waitingToken = false)))
      .subscribe(
        result => {
          if (result.error) {
            this.onGetTokenError();
          } else if (result.token) {
            this.onGetTokenSuccess(result.token);
          }
        },
        () => this.onGetTokenError(),
      );
  }

  private onGetTokenSuccess(token: { id: string; card: { last4: string; brand: string } }): void {
    this.stripePaymentInfo = StripePaymentInfo.from(token.card.last4, token.card.brand, token.id);
    this.usBillingChanged.emit(this.stripePaymentInfo);
  }

  private onGetTokenError(): void {
    this.clearStripeInput();
    this.serviceError = true;
    setTimeout(() => (this.serviceError = false), 6000);
  }
}

enum CardBrand {
  MASTER_CARD,
  VISA,
  AMERICAN_EXPRESS,
  OTHER,
}
