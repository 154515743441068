<div>
  <div class="tsq-card">
    <div class="panel-heading">
      <h1>{{ 'SUBSCRIPTION.COMMUNITY_CPF_CNPJ' | translate }}</h1>
    </div>

    <div class="panel-body">
      <form class="mt-10">
        <div class="col-xs-6 pr-5 no-padding-left form-group">
          <label for="document">{{ 'SUBSCRIPTION.CPF_CNPJ' | translate }}</label>
          <input id="document" class="form-control" name="documentNumber"
                 [formControl]="documentNumber"
                 [imask]="cpfCnpjMask">
        </div>
      </form>
    </div>
  </div>
</div>
