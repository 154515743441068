import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpService } from '@tsq-web/core';

import { ResetPasswordResponse } from '../models/reset-password-response.model';

@Injectable()
export class ResetPasswordService extends HttpService {
  private readonly httpClient = inject(HttpClient);

  resetPassword(email: string): Observable<ResetPasswordResponse> {
    return this.httpClient.post<ResetPasswordResponse>(
      this.getFullUrl('password-reset/mail', 'v2'),
      { userName: email },
    );
  }

  validateToken(userId: string): Observable<void> {
    return this.httpClient.get<undefined>(
      this.getFullUrl(`password-reset/${userId}/validate/token`, 'v1'),
    );
  }
}
