/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sc-inline-confirmation',
  templateUrl: 'sc-inline-confirmation.component.html',
  styleUrls: ['sc-inline-confirmation.component.scss'],
})
export class SCInlineConfirmationComponent {
  @Input() message = '';
  @Output() confirmedActionOnEntity = new EventEmitter();
  @Output() confirmedAction = new EventEmitter();
  entity: any;
  confirming = false;

  confirmActionOnEntity(entity: any) {
    this.entity = entity;
    this.confirmAction();
  }

  confirmAction() {
    this.confirming = true;
  }

  cancelled() {
    this.confirming = false;
  }

  confirmed(event: any) {
    if (this.entity !== undefined && this.entity !== null) {
      this.confirmedActionOnEntity.emit(this.entity);
      this.entity = undefined;
    } else {
      this.confirmedAction.emit(event);
    }
    this.confirming = false;
  }
}
