import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'tsq-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent implements OnInit {
  @Input() title: string;
  @Input() icon: string;
  @Input() img: string;
  @Input() prevUrl: string;
  @Input() hasSearch = true;
  @Input() loading = false;
  @Output() searchChange = new EventEmitter<string>();

  searchControl = new UntypedFormControl();
  constructor(private router: Router) {}

  ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(untilDestroyed(this), debounceTime(400), distinctUntilChanged())
      .subscribe(search => {
        this.searchChange.emit(search);
      });
  }

  @Input() set search(value: string) {
    this.searchControl.setValue(value);
  }

  onBack(): void {
    this.router.navigateByUrl(this.prevUrl);
  }
}
