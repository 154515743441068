import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { TSqValidators } from '@tsq-web/forms';

import { AuthStep } from '../../models/auth-step.enum';

@Component({
  selector: 'comm-login-email-form',
  templateUrl: './email-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailFormComponent implements OnInit {
  @Input() email = '';
  @Input() isLoading = false;

  @Output() emailSubmitted = new EventEmitter<string>();
  @Output() nextStep = new EventEmitter<AuthStep>();

  emailControl: FormControl<string>;

  ngOnInit(): void {
    this.emailControl = new FormControl(this.email, [
      Validators.required,
      TSqValidators.validLoginUsername,
    ]);
  }

  onNext(): void {
    if (this.emailControl.invalid) {
      return;
    }

    this.emailSubmitted.emit(this.emailControl.value);
  }
}
