import { getTSqEnvironment } from '@tsq-web/environment';
import { HttpBlobOptions, HttpJsonOptions, OptionsPayload } from '../models/http-options.model';

export abstract class HttpService {
  protected getFullUrl(url: string, version?: string, service?: string): string {
    const { baseApi } = getTSqEnvironment();
    const api = !version && !service ? 'rest' : 'api';

    return [baseApi, api, service, version, url].filter(part => !!part).join('/');
  }

  protected getJsonOptions(args?: OptionsPayload): HttpJsonOptions {
    const { headers, params } = args || {};

    return {
      headers,
      observe: 'body',
      params,
      responseType: 'json',
      withCredentials: true,
    };
  }

  protected getBlobOptions(args?: OptionsPayload): HttpBlobOptions {
    const { headers, params } = args || {};

    return {
      headers,
      observe: 'body',
      params,
      responseType: 'blob',
      withCredentials: true,
    };
  }
}
