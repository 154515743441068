import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { AppState } from '@tsq-web/state';
import { PlanService } from '../plan.service';
import * as fromPlansActions from './plans.actions';
import { PlansList } from '../plans-list.json';

@Injectable()
export class PlansEffects {
  loadOrganizationSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromPlansActions.load),
      mergeMap(() => {
        return this.plansService.getPlans().pipe(
          map((plan: PlansList) => fromPlansActions.loadSuccess({ entities: plan.plans })),
          catchError(() => {
            this.toastrService.error(this.translateService.instant('PLANS.PLANS_ERROR'));

            return of(fromPlansActions.loadFailure());
          }),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private plansService: PlanService,
    private toastrService: ToastrService,
    private translateService: TranslateService,
  ) {}
}
