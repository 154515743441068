import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TooltipTruncatedDirective } from '../../directives/tooltip-truncated/tooltip-truncated.directive';
import { AlignmentOptions } from '../../models/_table.model';
import { TableCellBaseComponent } from '../table-cell-base/table-cell-base.component';

@Component({
  standalone: true,
  imports: [TableCellBaseComponent, TooltipTruncatedDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'tsq-table-cell-link-external',
  template: `
    <tsq-table-cell-base [align]="align" [tsqTooltipTruncated]="el">
      <a
        class="truncate rounded focus-visible:z-[1]"
        [href]="href"
        [target]="target || '_blank'"
        (click)="onAnchorClick($event)"
        #el
      >
        {{ text }}
      </a>
    </tsq-table-cell-base>
  `,
  styles: [
    `
      a {
        &:focus-visible {
          outline: theme('colors.purple.DEFAULT') dotted 2px;
          outline-offset: 0;
        }

        &:focus:not(:focus-visible) {
          outline: none;
        }
      }
    `,
  ],
})
export class TableCellLinkExternalComponent {
  @Input() align: AlignmentOptions;
  @Input() text: string;
  @Input() href: string;
  @Input() target: '_blank' | '_parent' | '_self' | '_top';

  onAnchorClick(event: PointerEvent): void {
    event.stopPropagation();
  }
}
