import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { icons } from '@tsq-web/assets';
import { TSqValidators } from '@tsq-web/forms';

import { LoginInformation } from '../../models/login-information.model';

@Component({
  selector: 'comm-login-review-access',
  templateUrl: './review-access.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewAccessComponent implements OnInit {
  @Input() loginInformation: LoginInformation;
  @Input() isLoginIn = false;

  @Output() loginRequested = new EventEmitter<LoginInformation>();
  @Output() restartFlowRequested = new EventEmitter<void>();

  pencilIcon = icons.pencil;
  translatedErrorKey: string;
  currentErrorMessage: string;

  form: FormGroup<{
    username: FormControl<string>;
    password: FormControl<string>;
  }>;

  ngOnInit(): void {
    this.form = new FormGroup({
      username: new FormControl({ value: this.loginInformation?.username, disabled: true }, [
        Validators.required,
        TSqValidators.validEmail,
      ]),
      password: new FormControl(this.loginInformation?.password, [Validators.required]),
    });
  }

  @Input() set messageError(message: string) {
    if (!!message) {
      this.currentErrorMessage = message;
    }
  }

  get titleKey(): string {
    return `${this.currentErrorMessage}.TITLE`;
  }

  get descriptionKey(): string {
    return `${this.currentErrorMessage}.DESCRIPTION`;
  }

  get isLoginDisabled(): boolean {
    return !this.form?.value.password?.trim();
  }

  onLogin(): void {
    if (this.form.invalid) {
      return;
    }

    this.loginRequested.emit(this.form.getRawValue() as LoginInformation);
  }

  onRestartFlow(): void {
    this.restartFlowRequested.emit();
  }
}
