import { createFeatureSelector, createSelector } from '@ngrx/store';

import { UsersState } from './users.reducer';

export const selectUsersState = createFeatureSelector<UsersState>('users');

export const selectUserRecoverySending = createSelector(
  selectUsersState,
  (usersState: UsersState) => usersState.userRecovery.sending,
);

export const selectUserRecoveryError = createSelector(
  selectUsersState,
  (usersState: UsersState) => usersState.userRecovery.error,
);

export const selectUserRecoveryCompleted = createSelector(
  selectUsersState,
  (usersState: UsersState) => usersState.userRecovery.completed,
);
