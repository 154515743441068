import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AssociaSignUpGuard } from '../shared/core/security/associa-sign-up.guard';
import { AisAccountRetrievalComponent } from './components/ais-account-retrieval/ais-account-retrieval.component';
import { AisLinkSignUpLoginComponent } from './components/ais-link-sign-up-login/ais-link-sign-up-login.component';
import { AisOwnerSelectionComponent } from './components/ais-owner-selection/ais-owner-selection.component';
import { AisLinkLoggedComponent } from './containers/ais-link-logged/ais-link-logged.component';
import { AisLinkRegisterComponent } from './containers/ais-link-register/ais-link-register.component';
import { AisLinkUserComponent } from './containers/ais-link-user/ais-link-user.component';
import { AisMultipleAccountsComponent } from './containers/ais-multiple-accounts/ais-multiple-accounts.component';
import { PublicAisComponent } from './containers/public-ais/public-ais.component';
import { aisAccountRetrievalGuard } from './services/ais-account-retrieval.guard';
import { aisLinkUserGuard } from './services/ais-link-user.guard';
import { aisMultipleAccountsGuard } from './services/ais-multiple-accounts.guard';
import { aisOwnerSelectionGuard } from './services/ais-owner-selection.guard';

const routes: Routes = [
  {
    path: 'associa',
    component: PublicAisComponent,
    canActivate: [AssociaSignUpGuard],
    children: [{ path: '**', redirectTo: '/associa', pathMatch: 'full' }],
  },
  {
    path: 'partners',
    component: PublicAisComponent,
    canActivate: [AssociaSignUpGuard],
    children: [{ path: '**', redirectTo: '/partners', pathMatch: 'full' }],
  },
  { path: 'ais', redirectTo: 'ais/sign-up', pathMatch: 'full' },
  {
    path: 'ais/sign-up',
    component: PublicAisComponent,

    children: [
      {
        path: '',
        component: AisAccountRetrievalComponent,
        canActivate: [aisAccountRetrievalGuard],
      },
      {
        path: 'accounts',
        component: AisMultipleAccountsComponent,
        canActivate: [aisMultipleAccountsGuard],
      },
      {
        path: 'owner-selection',
        component: AisOwnerSelectionComponent,
        canActivate: [aisOwnerSelectionGuard],
      },
      {
        path: 'link-user',
        component: AisLinkUserComponent,
        canActivate: [aisLinkUserGuard],
        children: [
          { path: '', component: AisLinkSignUpLoginComponent },
          { path: 'register', component: AisLinkRegisterComponent },
          { path: 'logged', component: AisLinkLoggedComponent },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PublicAisRoutingModule {}
