function filterCoalColor(theme) {
  return Object.fromEntries(Object.entries(theme('colors')).filter(([key]) => key !== 'coal'));
}

/** @type {import('tailwindcss').Config} */
module.exports = {
  theme: {
    animation: {
      none: 'none',
      spin: 'spin 1s linear infinite',
      ping: 'ping 1s cubic-bezier(0, 0, 0.2, 1) infinite',
      pulse: 'pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite',
      bounce: 'bounce 1s infinite',
    },
    aria: {
      busy: 'busy="true"',
      checked: 'checked="true"',
      ['current-page']: 'current="page"',
      disabled: 'disabled="true"',
      expanded: 'expanded="true"',
      haspopup: 'haspopup="true"',
      hidden: 'hidden="true"',
      pressed: 'pressed="true"',
      readonly: 'readonly="true"',
      required: 'required="true"',
      selected: 'selected="true"',
    },
    backgroundColor: ({ theme }) => filterCoalColor(theme),
    backgroundImage: {
      none: 'none',
    },
    backgroundPosition: {
      bottom: 'bottom',
      center: 'center',
      left: 'left',
      ['left-bottom']: 'left bottom',
      ['left-top']: 'left top',
      right: 'right',
      ['right-bottom']: 'right bottom',
      ['right-top']: 'right top',
      top: 'top',
    },
    backgroundSize: {
      auto: 'auto',
      cover: 'cover',
      contain: 'contain',
    },
    borderColor: ({ theme }) => ({
      ['DEFAULT']: 'transparent',
      ...filterCoalColor(theme),
    }),
    borderRadius: {
      none: '0',
      ['DEFAULT']: '4px',
      lg: '8px',
      xl: '12px',
      '2xl': '16px',
      full: '9999px',
    },
    borderWidth: {
      ['0']: '0',
      ['1']: '1px',
      ['2']: '2px',
      ['4']: '4px',
    },
    boxShadow: {
      none: 'none',
      ['DEFAULT']: 'var(--box-shadow-element)',
      hover: 'var(--box-shadow-hover)',
    },
    caretColor: ({ theme }) => theme('colors'),
    colors: {
      inherit: 'inherit',
      current: 'currentColor',
      transparent: 'transparent',
      black: '#000',
      white: '#fff',
      primary: {
        ['DEFAULT']: 'var(--primary)',
        n1: 'var(--primary-n1)',
        p1: 'var(--primary-p1)',
      },
      ['new-primary']: {
        ['DEFAULT']: 'var(--new-primary)',
        n1: 'var(--new-primary-n1)',
        n2: 'var(--new-primary-n2)',
        n3: 'var(--new-primary-n3)',
        p1: 'var(--new-primary-p1)',
        p2: 'var(--new-primary-p2)',
        p3: 'var(--new-primary-p3)',
      },
      green: {
        ['DEFAULT']: 'var(--green)',
        n1: 'var(--green-n1)',
        p1: 'var(--green-p1)',
      },
      ['new-green']: {
        ['DEFAULT']: 'var(--new-green)',
        n1: 'var(--new-green-n1)',
        n2: 'var(--new-green-n2)',
        n3: 'var(--new-green-n3)',
        p1: 'var(--new-green-p1)',
        p2: 'var(--new-green-p2)',
        p3: 'var(--new-green-p3)',
      },
      kale: {
        ['DEFAULT']: 'var(--kale)',
        n1: 'var(--kale-n1)',
        p1: 'var(--kale-p1)',
      },
      purple: {
        DEFAULT: 'var(--purple)',
        n1: 'var(--purple-n1)',
        p1: 'var(--purple-p1)',
      },
      red: {
        ['DEFAULT']: 'var(--red)',
        n1: 'var(--red-n1)',
        p1: 'var(--red-p1)',
      },
      yellow: {
        ['DEFAULT']: 'var(--yellow)',
        n1: 'var(--yellow-n1)',
        p1: 'var(--yellow-p1)',
      },
      petro: {
        ['DEFAULT']: 'var(--petro)',
        n1: 'var(--petro-n1)',
        n2: 'var(--petro-n2)',
        p1: 'var(--petro-p1)',
      },
      coal: {
        ['DEFAULT']: 'var(--coal)',
        secondary: 'var(--coal-secondary)',
        tertiary: 'var(--coal-tertiary)',
      },
    },
    columns: {
      auto: 'auto',
      ['1']: '1',
      ['2']: '2',
      ['3']: '3',
      ['4']: '4',
      ['5']: '5',
      ['6']: '6',
      ['7']: '7',
      ['8']: '8',
      ['9']: '9',
      ['10']: '10',
      ['11']: '11',
      ['12']: '12',
      ['13']: '13',
      ['14']: '14',
    },
    content: {
      none: 'none',
      empty: '',
    },
    cursor: {
      auto: 'auto',
      default: 'default',
      pointer: 'pointer',
      wait: 'wait',
      text: 'text',
      move: 'move',
      help: 'help',
      ['not-allowed']: 'not-allowed',
      none: 'none',
      ['context-menu']: 'context-menu',
      progress: 'progress',
      cell: 'cell',
      crosshair: 'crosshair',
      ['vertical-text']: 'vertical-text',
      alias: 'alias',
      copy: 'copy',
      ['no-drop']: 'no-drop',
      grab: 'grab',
      grabbing: 'grabbing',
      ['all-scroll']: 'all-scroll',
      ['col-resize']: 'col-resize',
      ['row-resize']: 'row-resize',
      ['n-resize']: 'n-resize',
      ['e-resize']: 'e-resize',
      ['s-resize']: 's-resize',
      ['w-resize']: 'w-resize',
      ['ne-resize']: 'ne-resize',
      ['nw-resize']: 'nw-resize',
      ['se-resize']: 'se-resize',
      ['sw-resize']: 'sw-resize',
      ['ew-resize']: 'ew-resize',
      ['ns-resize']: 'ns-resize',
      ['nesw-resize']: 'nesw-resize',
      ['nwse-resize']: 'nwse-resize',
      ['zoom-in']: 'zoom-in',
      ['zoom-out']: 'zoom-out',
    },
    divideColor: ({ theme }) => theme('borderColor'),
    divideWidth: ({ theme }) => theme('borderWidth'),
    fill: ({ theme }) => ({
      none: 'none',
      ...filterCoalColor(theme),
    }),
    flex: {
      ['1']: '1 1 0%',
      auto: '1 1 auto',
      initial: '0 1 auto',
      none: 'none',
    },
    flexBasis: ({ theme }) => ({
      auto: 'auto',
      ...theme('spacing'),
      full: '100%',
    }),
    flexGrow: {
      ['0']: '0',
      ['DEFAULT']: '1',
    },
    flexShrink: {
      ['0']: '0',
      ['DEFAULT']: '1',
    },
    fontFamily: {
      base: 'Nunito, sans-serif',
    },
    fontSize: {
      xs: '10px',
      sm: '12px',
      base: '14px',
      md: '16px',
      lg: '18px',
      xl: '24px',
    },
    fontWeight: {
      regular: '400',
      ['semi-bold']: '600',
      bold: '700',
      ['extra-bold']: '800',
    },
    gap: ({ theme }) => theme('spacing'),
    gridAutoColumns: {
      auto: 'auto',
      min: 'min-content',
      max: 'max-content',
      fr: 'minmax(0, 1fr)',
    },
    gridAutoRows: {
      auto: 'auto',
      min: 'min-content',
      max: 'max-content',
      fr: 'minmax(0, 1fr)',
    },
    gridColumn: {
      auto: 'auto',
      ['span-1']: 'span 1 / span 1',
      ['span-2']: 'span 2 / span 2',
      ['span-3']: 'span 3 / span 3',
      ['span-4']: 'span 4 / span 4',
      ['span-5']: 'span 5 / span 5',
      ['span-6']: 'span 6 / span 6',
      ['span-7']: 'span 7 / span 7',
      ['span-8']: 'span 8 / span 8',
      ['span-9']: 'span 9 / span 9',
      ['span-10']: 'span 10 / span 10',
      ['span-11']: 'span 11 / span 11',
      ['span-12']: 'span 12 / span 12',
      ['span-13']: 'span 13 / span 13',
      ['span-14']: 'span 14 / span 14',
      ['span-full']: '1 / -1',
    },
    gridColumnEnd: {
      auto: 'auto',
      ['1']: '1',
      ['2']: '2',
      ['3']: '3',
      ['4']: '4',
      ['5']: '5',
      ['6']: '6',
      ['7']: '7',
      ['8']: '8',
      ['9']: '9',
      ['10']: '10',
      ['11']: '11',
      ['12']: '12',
      ['13']: '13',
      ['14']: '14',
      ['15']: '15',
    },
    gridColumnStart: {
      auto: 'auto',
      ['1']: '1',
      ['2']: '2',
      ['3']: '3',
      ['4']: '4',
      ['5']: '5',
      ['6']: '6',
      ['7']: '7',
      ['8']: '8',
      ['9']: '9',
      ['10']: '10',
      ['11']: '11',
      ['12']: '12',
      ['13']: '13',
      ['14']: '14',
      ['15']: '15',
    },
    gridRow: {
      auto: 'auto',
      ['span-1']: 'span 1 / span 1',
      ['span-2']: 'span 2 / span 2',
      ['span-3']: 'span 3 / span 3',
      ['span-4']: 'span 4 / span 4',
      ['span-5']: 'span 5 / span 5',
      ['span-6']: 'span 6 / span 6',
      ['span-full']: '1 / -1',
    },
    gridRowEnd: {
      auto: 'auto',
      ['1']: '1',
      ['2']: '2',
      ['3']: '3',
      ['4']: '4',
      ['5']: '5',
      ['6']: '6',
      ['7']: '7',
    },
    gridRowStart: {
      auto: 'auto',
      ['1']: '1',
      ['2']: '2',
      ['3']: '3',
      ['4']: '4',
      ['5']: '5',
      ['6']: '6',
      ['7']: '7',
    },
    gridTemplateColumns: {
      none: 'none',
      subgrid: 'subgrid',
      ['1']: 'repeat(1, minmax(0, 1fr))',
      ['2']: 'repeat(2, minmax(0, 1fr))',
      ['3']: 'repeat(3, minmax(0, 1fr))',
      ['4']: 'repeat(4, minmax(0, 1fr))',
      ['5']: 'repeat(5, minmax(0, 1fr))',
      ['6']: 'repeat(6, minmax(0, 1fr))',
      ['7']: 'repeat(7, minmax(0, 1fr))',
      ['8']: 'repeat(8, minmax(0, 1fr))',
      ['9']: 'repeat(9, minmax(0, 1fr))',
      ['10']: 'repeat(10, minmax(0, 1fr))',
      ['11']: 'repeat(11, minmax(0, 1fr))',
      ['12']: 'repeat(12, minmax(0, 1fr))',
      ['13']: 'repeat(13, minmax(0, 1fr))',
      ['14']: 'repeat(14, minmax(0, 1fr))',
    },
    gridTemplateRows: {
      none: 'none',
      subgrid: 'subgrid',
      ['1']: 'repeat(1, minmax(0, 1fr))',
      ['2']: 'repeat(2, minmax(0, 1fr))',
      ['3']: 'repeat(3, minmax(0, 1fr))',
      ['4']: 'repeat(4, minmax(0, 1fr))',
      ['5']: 'repeat(5, minmax(0, 1fr))',
      ['6']: 'repeat(6, minmax(0, 1fr))',
    },
    height: ({ theme }) => ({
      auto: 'auto',
      ...theme('spacing'),
      full: '100%',
      screen: '100vh',
      min: 'min-content',
      max: 'max-content',
      fit: 'fit-content',
    }),
    inset: ({ theme }) => ({
      auto: 'auto',
      ['2']: '2px',
      ...theme('spacing'),
      ['1/2']: '50%',
      full: '100%',
    }),
    keyframes: {
      spin: {
        to: {
          transform: 'rotate(360deg)',
        },
      },
      ping: {
        ['75%, 100%']: {
          transform: 'scale(2)',
          opacity: '0',
        },
      },
      pulse: {
        ['50%']: {
          opacity: '.5',
        },
      },
      bounce: {
        ['0%, 100%']: {
          transform: 'translateY(-25%)',
          animationTimingFunction: 'cubic-bezier(0.8,0,1,1)',
        },
        ['50%']: {
          transform: 'none',
          animationTimingFunction: 'cubic-bezier(0,0,0.2,1)',
        },
      },
    },
    letterSpacing: {
      normal: '0',
      wide: '0.025rem',
    },
    lineClamp: {
      ['1']: '1',
      ['2']: '2',
      ['3']: '3',
      ['4']: '4',
      ['5']: '5',
      ['6']: '6',
    },
    lineHeight: {
      none: '1',
      tight: '1.20833',
      normal: '1.5',
      relaxed: '1.58333',
    },
    listStyleType: {
      none: 'none',
      disc: 'disc',
      decimal: 'decimal',
    },
    margin: ({ theme }) => ({
      auto: 'auto',
      ...theme('spacing'),
    }),
    maxHeight: {
      none: 'none',
      full: '100%',
      screen: '100vh',
      min: 'min-content',
      max: 'max-content',
      fit: 'fit-content',
    },
    maxWidth: ({ theme, breakpoints }) => ({
      none: 'none',
      full: '100%',
      min: 'min-content',
      max: 'max-content',
      fit: 'fit-content',
      ...breakpoints(theme('screens')),
    }),
    minHeight: {
      ['0']: '0px',
      full: '100%',
      screen: '100vh',
      min: 'min-content',
      max: 'max-content',
      fit: 'fit-content',
    },
    minWidth: {
      ['0']: '0px',
      full: '100%',
      min: 'min-content',
      max: 'max-content',
      fit: 'fit-content',
    },
    objectPosition: {
      bottom: 'bottom',
      center: 'center',
      left: 'left',
      ['left-bottom']: 'left bottom',
      ['left-top']: 'left top',
      right: 'right',
      ['right-bottom']: 'right bottom',
      ['right-top']: 'right top',
      top: 'top',
    },
    order: {
      first: '-9999',
      last: '9999',
      none: '0',
      ['1']: '1',
      ['2']: '2',
      ['3']: '3',
      ['4']: '4',
      ['5']: '5',
      ['6']: '6',
      ['7']: '7',
      ['8']: '8',
      ['9']: '9',
      ['10']: '10',
      ['11']: '11',
      ['12']: '12',
      ['13']: '13',
      ['14']: '14',
    },
    outlineColor: {
      ['DEFAULT']: 'var(--primary)',
      ['new-primary']: 'var(--new-primary)',
    },
    outlineOffset: {
      ['DEFAULT']: '4px',
      ['2']: '2px',
    },
    outlineWidth: {
      ['DEFAULT']: '2px',
    },
    padding: ({ theme }) => theme('spacing'),
    rotate: {
      ['0']: '0deg',
      ['1']: '1deg',
      ['2']: '2deg',
      ['3']: '3deg',
      ['6']: '6deg',
      ['12']: '12deg',
      ['45']: '45deg',
      ['90']: '90deg',
      ['180']: '180deg',
    },
    scale: {
      ['0']: '0',
      ['50']: '.5',
      ['75']: '.75',
      ['90']: '.9',
      ['95']: '.95',
      ['100']: '1',
      ['105']: '1.05',
      ['110']: '1.1',
      ['125']: '1.25',
      ['150']: '1.5',
    },
    screens: {
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      ['2xl']: '1536px',
    },
    scrollMargin: ({ theme }) => theme('spacing'),
    scrollPadding: ({ theme }) => theme('spacing'),
    skew: {
      ['0']: '0deg',
      ['1']: '1deg',
      ['2']: '2deg',
      ['3']: '3deg',
      ['6']: '6deg',
      ['12']: '12deg',
    },
    space: ({ theme }) => theme('spacing'),
    spacing: {
      ['0']: '0',
      ['1']: '1px',
      ['4']: '4px',
      ['8']: '8px',
      ['12']: '12px',
      ['16']: '16px',
      ['24']: '24px',
      ['32']: '32px',
      ['40']: '40px',
      ['48']: '48px',
      ['56']: '56px',
      ['64']: '64px',
      ['72']: '72px',
      ['80']: '80px',
      ['88']: '88px',
      ['96']: '96px',
      ['104']: '104px',
      ['112']: '112px',
      ['120']: '120px',
      ['128']: '128px',
      ['136']: '136px',
      ['144']: '144px',
      ['152']: '152px',
      ['160']: '160px',
      ['200']: '200px',
    },
    stroke: ({ theme }) => ({
      none: 'none',
      ...filterCoalColor(theme),
    }),
    strokeWidth: {
      ['0']: '0',
      ['1']: '1',
      ['2']: '2',
    },
    textColor: ({ theme }) => theme('colors'),
    textDecorationColor: ({ theme }) => theme('colors'),
    textDecorationThickness: {
      auto: 'auto',
      ['from-font']: 'from-font',
      ['0']: '0px',
      ['1']: '1px',
      ['2']: '2px',
      ['4']: '4px',
    },
    textIndent: ({ theme }) => theme('spacing'),
    textUnderlineOffset: {
      auto: 'auto',
      ['0']: '0px',
      ['1']: '1px',
      ['2']: '2px',
      ['4']: '4px',
    },
    transformOrigin: {
      center: 'center',
      top: 'top',
      ['top-right']: 'top right',
      right: 'right',
      ['bottom-right']: 'bottom right',
      bottom: 'bottom',
      ['bottom-left']: 'bottom left',
      left: 'left',
      ['top-left']: 'top left',
    },
    transitionDelay: {
      ['0']: '0s',
      ['75']: '75ms',
      ['100']: '100ms',
      ['150']: '150ms',
      ['200']: '200ms',
      ['300']: '300ms',
      ['500']: '500ms',
      ['700']: '700ms',
      ['1000']: '1000ms',
    },
    transitionDuration: {
      ['DEFAULT']: '150ms',
      ['0']: '0s',
      ['75']: '75ms',
      ['100']: '100ms',
      ['150']: '150ms',
      ['200']: '200ms',
      ['300']: '300ms',
      ['500']: '500ms',
      ['700']: '700ms',
      ['1000']: '1000ms',
    },
    transitionProperty: {
      none: 'none',
      all: 'all',
      ['DEFAULT']:
        'color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter',
      colors: 'color, background-color, border-color, text-decoration-color, fill, stroke',
      shadow: 'box-shadow',
      transform: 'transform',
    },
    transitionTimingFunction: {
      ['DEFAULT']: 'cubic-bezier(0.4, 0, 0.2, 1)',
      linear: 'linear',
      in: 'cubic-bezier(0.4, 0, 1, 1)',
      out: 'cubic-bezier(0, 0, 0.2, 1)',
      ['in-out']: 'cubic-bezier(0.4, 0, 0.2, 1)',
    },
    translate: ({ theme }) => ({
      ...theme('spacing'),
      ['1/2']: '50%',
      ['1/3']: '33.333333%',
      ['2/3']: '66.666667%',
      ['1/4']: '25%',
      ['2/4']: '50%',
      ['3/4']: '75%',
      full: '100%',
    }),
    width: ({ theme }) => ({
      auto: 'auto',
      ...theme('spacing'),
      full: '100%',
      screen: '100vw',
      min: 'min-content',
      max: 'max-content',
      fit: 'fit-content',
    }),
    willChange: {
      auto: 'auto',
      scroll: 'scroll-position',
      contents: 'contents',
      transform: 'transform',
    },
    zIndex: {
      auto: 'auto',
      ['0']: '0',
      ['10']: '10',
      ['20']: '20',
      ['30']: '30',
      ['40']: '40',
      ['50']: '50',
      ['100']: '100',
    },
  },
  corePlugins: {
    accentColor: false,
    // accessibility: true,
    // alignContent: true,
    // alignItems: true,
    // alignSelf: true,
    // animation: true,
    // appearance: true,
    aspectRatio: false,
    backdropBlur: false,
    backdropBrightness: false,
    backdropContrast: false,
    backdropFilter: false,
    backdropGrayscale: false,
    backdropHueRotate: false,
    backdropInvert: false,
    backdropOpacity: false,
    backdropSaturate: false,
    backdropSepia: false,
    // backgroundAttachment: true,
    backgroundBlendMode: false,
    // backgroundColor: true,
    // backgroundImage: true,
    backgroundOpacity: false,
    // backgroundOrigin: true,
    // backgroundPosition: true,
    // backgroundRepeat: true,
    // backgroundSize: true,
    blur: false,
    borderCollapse: false,
    // borderColor: true,
    borderOpacity: false,
    // borderRadius: true,
    borderSpacing: false,
    // borderStyle: true,
    // borderWidth: true,
    // boxDecorationBreak: true,
    // boxShadow: true,
    boxShadowColor: false,
    // boxSizing: true,
    // breakAfter: true,
    // breakBefore: true,
    // breakInside: true,
    brightness: false,
    captionSide: false,
    // caretColor: true,
    // clear: true,
    // colors: true,
    // columns: true,
    // container: true,
    // content: true,
    contrast: false,
    // cursor: true,
    // display: true,
    // divideColor: true,
    divideOpacity: false,
    // divideStyle: true,
    // divideWidth: true,
    dropShadow: false,
    // fill: true,
    filter: false,
    // flex: true,
    // flexBasis: true,
    // flexDirection: true
    // flexGrow: true,
    // flexShrink: true,
    // flexWrap: true,
    // float: true,
    // fontFamily: true,
    // fontSize: true,
    fontSmoothing: false,
    // fontStyle: true,
    // fontVariantNumeric: true,
    // fontWeight: true,
    // gap: true,
    gradientColorStops: false,
    grayscale: false,
    // gridAutoColumns: true,
    // gridAutoFlow: true,
    // gridAutoRows: true,
    // gridColumn: true,
    // gridColumnEnd: true,
    // gridColumnStart: true,
    // gridRow: true,
    // gridRowEnd: true,
    // gridRowStart: true,
    // gridTemplateColumns: true,
    // gridTemplateRows: true,
    // height: true,
    hueRotate: false,
    // hyphens: true,
    // inset: true,
    invert: false,
    // isolation: true,
    // justifyContent: true,
    // justifyItems: true,
    // justifySelf: true,
    // letterSpacing: true,
    // lineClamp: true,
    // lineHeight: true,
    listStyleImage: false,
    // listStylePosition: true,
    // listStyleType: true,
    // margin: true,
    // maxHeight: true,
    // maxWidth: true,
    // minHeight: true,
    // minWidth: true,
    mixBlendMode: false,
    // objectFit: true,
    // objectPosition: true,
    opacity: false,
    // order: true,
    // outlineColor: true,
    // outlineOffset: true,
    // outlineWidth: true,
    // overflow: true,
    // overscrollBehavior: true,
    // padding: true,
    // placeContent: true,
    // placeItems: true,
    // placeSelf: true,
    placeholderColor: false,
    placeholderOpacity: false,
    // pointerEvents: true,
    // position: true,
    // resize: true,
    ringColor: false,
    ringOffsetColor: false,
    ringOffsetWidth: false,
    ringOpacity: false,
    ringWidth: false,
    // rotate: true,
    saturate: false,
    // scale: true,
    // screens: true,
    // scrollBehavior: true,
    // scrollMargin: true,
    // scrollPadding: true,
    // scrollSnapAlign: true,
    // scrollSnapStop: true,
    // scrollSnapType: true,
    sepia: false,
    // skew: true,
    // space: true,
    // spacing: true,
    // stroke: true,
    // strokeWidth: true,
    tableLayout: false,
    // textAlign: true,
    // textColor: true,
    // textDecoration: true,
    // textDecorationColor: true,
    // textDecorationStyle: true,
    // textDecorationThickness: true,
    // textIndent: true,
    textOpacity: false,
    // textOverflow: true,
    // textTransform: true,
    // textUnderlineOffset: true,
    touchAction: false,
    // transform: true,
    // transformOrigin: true,
    // transitionDelay: true,
    // transitionDuration: true,
    // transitionProperty: true,
    // transitionTimingFunction: true,
    // translate: true,
    // userSelect: true,
    // verticalAlign: true,
    // visibility: true,
    // whitespace: true,
    // width: true,
    // willChange: true,
    // wordBreak: true,
    // zIndex: true,
  },
  plugins: [],
};
