import { CdkDrag, CdkDragDrop, CdkDropList, DragDropModule } from '@angular/cdk/drag-drop';
import { ConnectedPosition, Overlay, OverlayModule } from '@angular/cdk/overlay';
import { AsyncPipe, NgClass, NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { LetModule } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';

import { AssetsModule, icons } from '@tsq-web/assets';
import { TSqFormsModule } from '@tsq-web/forms';

import { ColumnWithMetadata, tableColumnMetadataKey } from '../../models/_table.model';
import { TableColumnsStore } from '../../state/table-columns/table-columns.store';

@Component({
  standalone: true,
  imports: [
    AsyncPipe,
    DragDropModule,
    FormsModule,
    NgClass,
    NgFor,
    OverlayModule,
    LetModule,
    TranslateModule,
    AssetsModule,
    TSqFormsModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'tsq-table-settings',
  template: `
    <button
      class="hover:bg-primary-n1 text-coal-secondary flex size-48 items-center justify-center rounded transition-colors"
      cdkOverlayOrigin
      #overlayOrigin="cdkOverlayOrigin"
      (click)="onTriggerClick()"
    >
      <tsq-icon [icon]="icons.gear" [classes]="'size-24'" />
    </button>

    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="overlayOrigin"
      [cdkConnectedOverlayOpen]="open$ | async"
      [cdkConnectedOverlayFlexibleDimensions]="true"
      [cdkConnectedOverlayPositions]="overlayPositions"
      [cdkConnectedOverlayViewportMargin]="16"
      [cdkConnectedOverlayScrollStrategy]="overlayScrollStrategy"
      (overlayOutsideClick)="onOverlayOutsideClick()"
      (detach)="onOverlayDetach()"
    >
      <div
        class="table-settings-popup bg-petro-p1 grid h-full w-[284px] grid-rows-[min-content,minmax(0,_1fr)] gap-8 rounded py-16"
        *ngrxLet="columns$ as columns"
      >
        <div class="flex flex-col gap-8 px-16">
          <div class="flex items-center justify-between">
            <h5>{{ 'LIBS.TABLE.TABLE_SETTINGS.TITLE' | translate }}</h5>

            <button
              class="hover:bg-petro-n1 size-24 rounded transition-colors"
              (click)="onCloseOverlayClick()"
            >
              <tsq-icon [icon]="icons.xmark" />
            </button>
          </div>

          <p class="text-coal-tertiary small-text">
            {{ 'LIBS.TABLE.TABLE_SETTINGS.DESCRIPTION' | translate }}
          </p>
        </div>

        <ul
          class="overflow-y-auto px-8"
          cdkDropList
          [cdkDropListData]="columns"
          [cdkDropListSortPredicate]="sortColumnsPredicate"
          (cdkDropListDropped)="onDropped($event)"
          #list
        >
          <ng-container *ngFor="let col of columns; trackBy: trackByColumnId">
            <li
              *ngrxLet="col[tableColumnMetadataKey] as metadata"
              class="group flex items-center gap-8 rounded p-8 transition-colors"
              [ngClass]="
                metadata.canInteract
                  ? 'bg-petro-p1 hover:bg-primary-n1'
                  : 'bg-petro-n1 cursor-not-allowed'
              "
              cdkDrag
              [cdkDragBoundary]="list"
              [cdkDragDisabled]="!metadata.canInteract"
              cdkDragLockAxis="y"
            >
              <tsq-icon
                class="text-coal-tertiary rounded transition-colors"
                [ngClass]="{ 'group-hover:text-primary cursor-grab': metadata.canInteract }"
                [icon]="icons.sixDots"
                cdkDragHandle
              />

              <label
                for="table-settings--input--toggle-col-{{ col.id }}"
                class="font-regular m-0 grow"
                [ngClass]="{ 'text-coal-secondary cursor-not-allowed': !metadata.canInteract }"
              >
                {{ metadata.label }}
              </label>

              <input
                id="table-settings--input--toggle-col-{{ col.id }}"
                type="checkbox"
                tsqToggle
                [disabled]="!metadata.canInteract"
                [ngModel]="metadata.visible"
                (ngModelChange)="onVisibilityChange(col.id, $event)"
              />
            </li>
          </ng-container>
        </ul>
      </div>
    </ng-template>
  `,
  styles: [
    `
      :host {
        @apply ml-auto block;

        > button {
          &:focus-visible {
            outline: theme('colors.purple.DEFAULT') dotted 2px;
            outline-offset: 0;
          }

          &:focus:not(:focus-visible) {
            outline: none;
          }
        }
      }

      .table-settings-popup,
      .cdk-drag-preview {
        box-shadow: 0px 0px 16px 0px #1c2e5229;
      }

      .cdk-drag-preview {
        @apply z-[11000] #{!important};

        > tsq-icon {
          @apply text-primary-p1;
        }
      }

      .cdk-drag-placeholder {
        @apply bg-primary-n1;

        > * {
          visibility: hidden;
        }
      }

      .cdk-drop-list-dragging {
        @apply cursor-grabbing;
      }
    `,
  ],
})
export class TableSettingsComponent {
  protected readonly icons = icons;
  protected readonly tableColumnMetadataKey = tableColumnMetadataKey;

  private readonly openSubject = new Subject<boolean>();
  protected readonly open$ = this.openSubject.asObservable();

  protected readonly overlayPositions: ConnectedPosition[] = [
    { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top', offsetX: -16 },
    { originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'bottom', offsetX: -16 },
  ];
  protected readonly overlayScrollStrategy = inject(Overlay).scrollStrategies.close();

  private readonly columnsStore = inject(TableColumnsStore);
  protected readonly columns$ = this.columnsStore.allColumnsFlat$;

  protected onTriggerClick(): void {
    this.openSubject.next(true);
  }

  protected onOverlayOutsideClick(): void {
    this.openSubject.next(false);
  }

  protected onOverlayDetach(): void {
    this.openSubject.next(false);
  }

  protected onCloseOverlayClick(): void {
    this.openSubject.next(false);
  }

  protected sortColumnsPredicate(
    index: number,
    _: CdkDrag,
    drop: CdkDropList<ColumnWithMetadata<unknown>[]>,
  ): boolean {
    return drop.data[index][tableColumnMetadataKey].canInteract;
  }

  protected onDropped(event: CdkDragDrop<ColumnWithMetadata<unknown>>): void {
    if (event.currentIndex !== event.previousIndex) {
      this.columnsStore.reorder(event);
    }
  }

  protected onVisibilityChange(id: string, value: boolean): void {
    this.columnsStore.updateVisibility({ id, value });
  }

  protected trackByColumnId(_: number, column: ColumnWithMetadata<unknown>): string {
    return column.id;
  }
}
