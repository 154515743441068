import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { FileItem, FileLikeObject, FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { ErrorsService } from '@tsq-web/errors';

import { FileMetaWithPayload } from '../../models/file-meta-payload.model';
import { FileMeta } from '../../models/file-meta.model';
import { FilesService } from '../../services/files.service';

@Component({
  selector: 'tsq-file-uploader',
  templateUrl: './tsq-file-uploader.component.html',
  styleUrls: ['./tsq-file-uploader.component.scss'],
})
export class TSqFileUploaderComponent {
  @Input() maxFiles = 7;
  @Input() maxFileSize = 50;
  @Input() disabled: boolean;
  @Input() multiple = false;
  @Input() selfHandleVisualization = false;
  @Input() selfHandleSending = false;
  @Input() serviceErrorMessage = false;
  @Input() jwt: string;

  @Output() fileItemAdded = new EventEmitter<FileItem>();

  isPresigned: boolean;

  acceptedFileTypes: string[] = [];
  fileExtensions: string[] = [];

  files$ = new BehaviorSubject<FileMeta[]>([]);
  filesWithPayload$ = new BehaviorSubject<FileMetaWithPayload[]>([]);

  private _uploader: FileUploader;
  private _files: FileMeta[] = [];
  private _filesWithPayload: FileMetaWithPayload[] = [];

  private url: string;
  private service: string;
  private loadingUrl: boolean;
  private presignedKeys = new Map<string, string>();
  private presignedBuckets = new Map<string, string>();

  constructor(
    private filesService: FilesService,
    private cookieService: CookieService,
    private translateService: TranslateService,
    private errorService: ErrorsService,
    private toastr: ToastrService,
    private cdRef: ChangeDetectorRef,
  ) {}

  get uploader(): FileUploader {
    return this._uploader;
  }

  get files(): FileMeta[] {
    return this._files;
  }

  get filesAsObservable(): Observable<FileMeta[]> {
    return this.files$.asObservable();
  }

  get uploading(): boolean {
    return !!this.uploader ? this.uploader.isUploading || this.loadingUrl : false;
  }

  setupUploader(
    url: string,
    types: string[],
    presigned = false,
    service?: string,
    fileExtensions?: string[],
  ): void {
    this.acceptedFileTypes = !!fileExtensions ? [...types, ...fileExtensions] : types;
    this.isPresigned = presigned;
    this.url = url;
    this.service = service;
    this.fileExtensions = fileExtensions;

    const headers = [
      { name: 'Authorization', value: `Bearer ${this.jwt || this.cookieService.get('jwt')}` },
    ];

    const logonToken = this.cookieService.get('logonToken');
    if (!!logonToken) {
      headers.push({ name: 'xx-sc-r', value: JSON.parse(logonToken).logonToken });
    }

    this._uploader = new FileUploader({
      url,
      allowedMimeType: types,
      autoUpload: !this.isPresigned,
      queueLimit: this.multiple ? this.maxFiles : 1,
      disableMultipart: this.isPresigned,
      headers: this.isPresigned ? undefined : headers,
      maxFileSize: this.isPresigned ? this.maxFileSize * 1000 * 1000 : undefined,
    });

    this.cdRef.detectChanges();

    this.setUploaderListeners();
  }

  setupFiles(files: FileMeta[]): void {
    this._files = files || [];
    this.files$.next(this._files);

    this.cdRef.detectChanges();
  }

  clearFiles(): void {
    this._files = [];
    this.files$.next(this._files);

    this._filesWithPayload = [];
    this.filesWithPayload$.next(this._filesWithPayload);

    if (!!this.uploader) {
      this.uploader.clearQueue();
    }
  }

  removeAt(index: number): void {
    if (!this.uploader.isUploading) {
      this._files = this._files.filter((file, i) => i !== index);
      this.files$.next(this._files);

      this._filesWithPayload = this._filesWithPayload.filter((file, i) => i !== index);
      this.filesWithPayload$.next(this._filesWithPayload);
    }
  }

  private setUploaderListeners(): void {
    this.uploader.onSuccessItem = (item: FileItem, response: string): void => {
      let presignedItem: FileMeta, fileFromResponse: FileMeta;

      if (this.isPresigned) {
        presignedItem = {
          key: this.presignedKeys.get(item.url),
          name: item._file.name,
          filenameExt: item._file.name.split('.').pop(),
          size: item._file.size,
          fileUrl: window.URL.createObjectURL(item._file),
          bucket: this.presignedBuckets.get(item.url),
        } as FileMeta;

        this.presignedKeys.delete(item.url);
        this.presignedBuckets.delete(item.url);
      }

      let data: FileMeta | FileMetaWithPayload | string;
      if (!!response) {
        data = this.isValidJSON(response)
          ? (JSON.parse(response) as FileMeta | FileMetaWithPayload)
          : response;
      } else {
        data = new FileMeta();
      }

      if (typeof data === 'object') {
        if ('file' in data) {
          fileFromResponse = data.file;
          this._filesWithPayload = [...this._filesWithPayload, data];
          this.filesWithPayload$.next(this._filesWithPayload);
        } else {
          fileFromResponse = data as FileMeta;
        }

        const file = this.isPresigned ? presignedItem : fileFromResponse;

        this._files = [...this._files, file];
        this.files$.next(this._files);
      } else {
        this.fileItemAdded.emit({ ...item, url: response } as FileItem);
      }

      this.handleFileUploadCompletion();
    };

    this.uploader.onErrorItem = (item, response): void => {
      this.onUploadFileError(JSON.parse(response) as HttpErrorResponse);
      this.uploader.clearQueue();
    };

    if (this.isPresigned) {
      this.uploader.onAfterAddingFile = (item: FileItem): void => {
        this.loadingUrl = true;

        this.filesService
          .getUploadUrl(this.url, this.service)
          .pipe(finalize(() => (this.loadingUrl = false)))
          .subscribe({
            next: ({ key, uploadUrl, bucket }) => {
              this.presignedKeys.set(uploadUrl, key);
              this.presignedBuckets.set(uploadUrl, bucket);

              item.url = uploadUrl;
              item.method = 'PUT';
              item.headers = [{ name: 'Content-Type', value: item.file.type }];
              item.withCredentials = false;
              item.upload();
            },

            error: () => this.onUploadFileError(),
          });
      };
    }

    this.uploader.onWhenAddingFileFailed = (
      item: FileLikeObject,
      filter: { name: string },
    ): void => {
      if (!this.isPresigned) {
        this.handleFileExtensionFailure(item, filter);
      } else {
        this.handleAddingFileFailed(filter);
      }
    };
  }

  private onUploadFileError(response?: HttpErrorResponse): void {
    if (!!response && this.serviceErrorMessage) {
      this.errorService.show(response, (error: HttpErrorResponse) => error?.message);
    } else {
      this.toastr.error(this.translateService.instant('LIBS.UPLOAD_FILE_ERROR'));
    }
  }

  private handleFileUploadCompletion(): void {
    if (!this.hasPendingFiles()) {
      this.uploader.clearQueue();
    }
  }

  private handleAddingFileFailed(filter: { name: string }): void {
    switch (filter.name) {
      case 'fileSize':
        this.toastr.error(
          this.translateService.instant('LIBS.FILE_TOO_LARGE_ERROR', {
            maxFileSize: `${this.maxFileSize}MB`,
          }),
        );
        break;
      default:
        this.onUploadFileError();
        break;
    }
  }

  private handleFileExtensionFailure(item: FileLikeObject, filter: { name: string }): void {
    if (filter.name !== 'mimeType') {
      return;
    }

    const chunks = item.name.split('.');
    const itemExtension = chunks[chunks.length - 1].toLowerCase();
    const isAcceptedType = this.fileExtensions
      ?.map(e => e.replace('.', ''))
      .includes(itemExtension);
    const shouldForceUpload = !item.type && isAcceptedType;

    if (shouldForceUpload) {
      const fixedFile = new File(
        [item.rawFile as BlobPart],
        (item.rawFile as unknown as File).name,
      );
      this.uploader.addToQueue([fixedFile], {} as FileUploaderOptions, []);
      this.uploader.uploadAll();
    } else {
      this.toastr.error(this.translateService.instant('LIBS.FILE_TYPE_NOT_ALLOWED'));
    }
  }

  private hasPendingFiles(): boolean {
    return this.presignedKeys.size > 0;
  }

  private isValidJSON(str: string): boolean {
    try {
      return JSON.parse(str) && !!str;
    } catch (_) {
      return false;
    }
  }
}
