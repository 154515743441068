/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Input, Component, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';

import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { User } from '@tsq-web/users';
import { UserService } from '../../user/user.service';
import { latinise } from '@tsq-web/text';
import { SCUserBioComponent } from '../sc-user-bio/sc-user-bio.component';

@Component({
  selector: 'sc-user-typeahead',
  templateUrl: 'sc-user-typeahead.component.html',
  styleUrls: ['sc-user-typeahead.component.scss'],
})
export class SCUserTypeaheadComponent implements OnInit {
  @ViewChild('userBio') userBio: SCUserBioComponent;
  @Input() fieldLabel = this._translateService.instant('SHARED.IN_NAME_OF');
  @Input() required = true;
  @Input() inputStatusParam = '';
  @Output() userSelectedEvent = new EventEmitter();

  users: User[];
  dataSource: Observable<User[]>;
  searchedUser: string;
  searchedUserEntity: User;

  private inNameOfId: string;

  constructor(private _userService: UserService, private _translateService: TranslateService) {}

  ngOnInit(): any {
    this.users = [];
    this.dataSource = new Observable((observer: any) => {
      observer.next(
        this._userService.cachedUsers.filter(
          (user: User) =>
            this.users.indexOf(user) === -1 && this.passesFilter(user, this.searchedUser),
        ),
      );
    });
  }

  passesFilter(user: User, filter: string) {
    if (filter === '') {
      return true;
    }
    const filters = filter.split(' ');
    const tokens: string[] = [];
    const regexs: RegExp[] = [];
    tokens.push(user.first_name);
    tokens.push(user.last_name);
    tokens.push(user.email);
    user.properties.forEach(property => {
      regexs.push(
        new RegExp(
          `^[${property.group_type}]*[ ]*${property.group_number}[ -]*[${property.property_type}]*[ ]*${property.property_number}$`,
          'i',
        ),
      );
      regexs.push(new RegExp(`^[${property.group_type}]*[ ]*${property.group_number}[ -]*$`, 'i'));
      regexs.push(
        new RegExp(`^[ -]*[${property.property_type}]*[ ]*${property.property_number}$`, 'i'),
      );
    });
    return (
      regexs.some(regex => regex.test(latinise(filter))) ||
      filters.every(fToken => {
        return tokens.some(token => {
          if (token === undefined || token === null) {
            return false;
          }
          return latinise(token.toLocaleLowerCase()).includes(latinise(fToken.toLocaleLowerCase()));
        });
      })
    );
  }

  isValid(): boolean {
    return this.inNameOfId !== undefined && this.inNameOfId !== null;
  }

  getInvalidClass(): string {
    return !this.isValid() && this.required ? 'sc-invalid' : '';
  }

  onUserSelected(option: any) {
    this.searchedUser = `${option.item.first_name} ${option.item.last_name}`;
    this.searchedUserEntity = option.item;
    console.log(this.searchedUserEntity);
    this.inNameOfId = option.item.id;
    this.userSelectedEvent.emit(option.item);
  }

  clear(): void {
    this.searchedUserEntity = undefined;
    this.searchedUser = '';
    this.deselectUser();
  }

  deselectUser() {
    this.searchedUserEntity = undefined;
    this.inNameOfId = undefined;
    this.userSelectedEvent.emit(undefined);
  }

  get userId(): string {
    return this.inNameOfId;
  }
}
