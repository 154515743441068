import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { icons } from '@tsq-web/assets';
import { slideDown } from '../../utils/banner.animations';

@Component({
  selector: 'comm-native-app-banner',
  templateUrl: './native-app-banner.component.html',
  styleUrls: ['./native-app-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideDown],
})
export class NativeAppBannerComponent {
  @Input() hasClose: boolean;
  @Input() linkText: 'open' | 'show' = 'open';
  @Output() closeBanner = new EventEmitter();
  @Output() linkClicked = new EventEmitter();

  readonly icons = icons;

  get linkTextTranslationKey(): string {
    return `NATIVE_APP_BANNER.${ this.linkText.toUpperCase() }`;
  }
}
