import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { TSqValidators } from '@tsq-web/forms';

import { AisAccountRetrieval } from '../../models/ais-account-retrieval.json';
import { AisAccountsList } from '../../models/ais-accounts-list.json';
import { PublicAisService } from '../../services/public-ais.service';

@Component({
  selector: 'comm-ais-account-retrieval',
  templateUrl: './ais-account-retrieval.component.html',
  styleUrls: ['./ais-account-retrieval.component.scss'],
})
export class AisAccountRetrievalComponent implements OnInit, AfterViewInit, OnDestroy {
  form: UntypedFormGroup;
  userJwt: string;

  readonly noContent = 204;

  private _sending: boolean;
  private routeSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private toastr: ToastrService,
    private publicAisService: PublicAisService,
    private cookieService: CookieService,
  ) {}

  ngOnInit(): void {
    this.userJwt = this.cookieService.get('jwt');

    this.form = new UntypedFormGroup({
      account: new UntypedFormControl('', this.formValidators),
      zip: new UntypedFormControl('', this.formValidators),
      firstName: new UntypedFormControl(''),
      lastName: new UntypedFormControl('', this.formValidators),
      company: new UntypedFormControl(''),
    });

    if (!this.userJwt) {
      this.form.disable();
    }
  }

  ngAfterViewInit(): void {
    this.routeSubscription = this.route.queryParams.subscribe(params => {
      if (!!params.account) {
        this.form.controls.account.setValue(params.account);
      }
      if (!!params.zip) {
        this.form.controls.zip.setValue(params.zip);
      }
      if (!!params.firstName) {
        this.form.controls.firstName.setValue(params.firstName);
      }
      if (!!params.lastName) {
        this.form.controls.lastName.setValue(params.lastName);
      }
      if (!!params.company) {
        this.form.controls.company.setValue(params.company);
      }

      if (this.isFormValid) {
        this.getAccount();
      }
    });
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }

  get sending(): boolean {
    return this._sending;
  }

  set sending(value: boolean) {
    this._sending = value;

    for (const field in this.form.controls) {
      if (value) {
        this.form.get(field).disable();
      } else {
        this.form.get(field).enable();
      }
    }
  }

  get isFormValid(): boolean {
    return this.form.valid;
  }

  onContinue(): void {
    this.getAccount();
  }

  private get formValidators(): ValidatorFn[] {
    return [Validators.required, TSqValidators.hasCharDifferentThanWhitespace];
  }

  private getAccount(): void {
    const account = this.form.controls.account.value;
    const zip = this.form.controls.zip.value;
    const firstName = this.form.controls.firstName.value;
    const lastName = this.form.controls.lastName.value;
    const company = this.form.controls.company.value;

    const aisAccRetrieval = AisAccountRetrieval.from(account, zip, firstName, lastName, company);

    this.sending = true;
    this.publicAisService
      .retrieveAccounts(account, zip, firstName, lastName, company)
      .pipe(finalize(() => (this.sending = false)))
      .subscribe(
        (statusData: { status: number; data: AisAccountsList }) => {
          if (statusData.status === this.noContent || statusData.data.empty) {
            this.toastr.error(this.translateService.instant('AIS_SIGN_UP.EMPTY_ACCOUNT_RETRIEVE'));
          } else {
            this.onGetAccountSuccess(statusData.data, aisAccRetrieval);
          }
        },
        () =>
          this.toastr.error(this.translateService.instant('AIS_SIGN_UP.EMPTY_ACCOUNT_RETRIEVE')),
      );
  }

  private onGetAccountSuccess(
    accountsList: AisAccountsList,
    aisAccRetrieval: AisAccountRetrieval,
  ): void {
    this.publicAisService.aisAccRetrieval = aisAccRetrieval;

    const accountsMatch = accountsList.accounts;
    let path: string;

    if (!accountsList.empty) {
      if (accountsMatch.length > 1) {
        this.publicAisService.accountsMatch = accountsMatch;
        path = 'accounts';
      } else {
        this.publicAisService.selectedAccount = accountsMatch[0];

        if (accountsMatch[0].account.owners.length > 1) {
          path = 'owner-selection';
        } else {
          this.publicAisService.owner = accountsMatch[0].account.owners[0];
          path = 'link-user';
        }
      }

      this.router.navigate(['ais', 'sign-up', path], { queryParamsHandling: 'merge' });
    } else {
      this.toastr.error(this.translateService.instant('AIS_SIGN_UP.EMPTY_ACCOUNT_RETRIEVE'));
    }
  }
}
