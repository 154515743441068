import {
  AfterViewInit,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { combineLatest } from 'rxjs';
import { startWith } from 'rxjs/operators';

import { icons } from '@tsq-web/assets';

@UntilDestroy()
@Component({
  selector: 'tsq-dropdown',
  templateUrl: './tsq-dropdown.component.html',
})
export class TSqDropdownComponent implements OnInit, AfterViewInit, ControlValueAccessor, DoCheck {
  @ViewChild('dropdownToggle') dropdownToggle: ElementRef<HTMLElement>;
  @ViewChild(BsDropdownDirective) dropdownDirective: BsDropdownDirective;

  @Input() placeholder: string;
  @Input() tabIndex = 0;
  @Input() errorMessage = 'MANDATORY_FIELD_ERROR_LABEL';
  @Input() limitItemListHeight = false;
  @Input() isNewDesign = false;
  @Input() focusOnInit = false;
  @Input() dropUp = false;
  @Input() itemTemplate: TemplateRef<{ item: unknown }>;
  @Input() selectedTemplate: TemplateRef<{ item: unknown }>;
  @Input() container: string;
  @Input() isCleanable = false;
  @Input() menuWidthPx: number;

  @Output() tabKeyEvent = new EventEmitter();

  control: AbstractControl;
  itemsText: string[];
  selectedItemText: string;
  caretIcon = icons.caret;
  xMarkIcon = icons.xmarkCircleFill;
  iconClasses: string;
  markInnerAsTouched = false;

  onChanged: (item: unknown) => void;
  onTouched: () => void;

  private selectedItemRaw: unknown;
  private _optionField: string | ((item: unknown) => string);
  private _items: unknown[];
  private _disabled: boolean;

  private readonly defaultIconClasses = 'transform transition all duration-300';

  constructor(@Optional() @Self() public ngControl: NgControl) {
    ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    this.control = this.ngControl.control;
    this.updateIconClasses();
  }

  ngDoCheck(): void {
    this.markInnerAsTouched = this.control.touched;
  }

  ngAfterViewInit(): void {
    combineLatest([
      this.dropdownDirective.isOpenChange.pipe(startWith(false)),
      this.control.statusChanges.pipe(startWith(null as string)),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(() => this.updateIconClasses());

    if (this.focusOnInit) {
      this.focus();
    }

    if (this.container) {
      const observer = new IntersectionObserver(
        ([toggleElement]) => {
          if (!toggleElement.isIntersecting) {
            this.dropdownDirective.hide();
          }
        },
        { threshold: [0] },
      );

      observer.observe(this.dropdownToggle.nativeElement);
    }
  }

  @Input() set optionField(value: string | ((item: unknown) => string)) {
    this._optionField = value;

    this.setItemsText();

    if (!!this.selectedItemRaw) {
      this.writeValue(this.selectedItemRaw);
    }
  }

  @Input() set items(value: unknown[]) {
    this._items = value;
    this.setItemsText();
  }

  get items(): unknown[] {
    return this._items;
  }

  get disabled(): boolean {
    return this._disabled;
  }

  set selectItem(index: number) {
    this.onChanged(this._items[index]);
    this.writeValue(this._items[index]);
  }

  registerOnChange(fn: (item: unknown) => void): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  writeValue(item: unknown): void {
    this.selectedItemRaw = item;
    this.selectedItemText = this.getItemText(item);
  }

  setItemsText(): void {
    this.itemsText = [];
    if (!!this._items) {
      this._items.forEach(v => this.itemsText.push(this.getItemText(v)));
    }
  }

  getItemText(item: unknown): string {
    if (typeof item === 'string' || !item) {
      return item as string;
    }

    if (typeof this._optionField === 'string') {
      return item[this._optionField];
    }

    return !!this._optionField ? this._optionField(item) : '';
  }

  onKeyDown(dropdownRef: BsDropdownDirective, dropdownEl: Element, event: KeyboardEvent): void {
    if (event.key.length === 1 && /^[a-z0-9]/i.test(event.key) && dropdownRef.isOpen) {
      const key = event.key.toLocaleLowerCase();
      const list = this.getAnchorList(dropdownEl);
      const targetIndex = list.findIndex(item => item === event.target);

      let found: HTMLAnchorElement;
      if (targetIndex !== list.length - 1) {
        const listAhead = list.slice(targetIndex + 1);
        found = listAhead.find(item => item.textContent[0].toLocaleLowerCase() === key);
      }

      if (!found) {
        found = list.find(item => item.textContent[0].toLocaleLowerCase() === key);
      }

      if (!!found) {
        found.focus();
      }
    }
  }

  onKeyArrow(dropdownRef: BsDropdownDirective, dropdownEl: Element, event: KeyboardEvent): void {
    event.preventDefault();
    if (dropdownEl.children[0] === event.target && !dropdownRef.isOpen) {
      dropdownRef.show();

      return;
    }

    const list = this.getAnchorList(dropdownEl);
    this.focusNext(list, event.target as HTMLAnchorElement, event.code === 'ArrowDown');
  }

  onSelectItem(dropdownRef: BsDropdownDirective, index: number): void {
    this.selectItem = index;
    dropdownRef.toggle(true);
    this.focus();
  }

  focus(): void {
    this.dropdownToggle?.nativeElement?.focus();
  }

  clearSelection(): void {
    this.onChanged(undefined);
    this.writeValue(undefined);
  }

  private get colorForIcon(): string {
    if (!!this.control) {
      const { invalid, disabled, touched } = this.control;
      if (disabled) {
        return 'text-coal-tertiary';
      } else if (invalid && touched) {
        return 'text-red';
      }
    }

    return 'text-primary';
  }

  private get rotationForIcon(): string {
    return this.dropdownDirective?.isOpen ? '-rotate-180' : '';
  }

  private updateIconClasses(): void {
    this.iconClasses = `${this.defaultIconClasses} ${this.colorForIcon} ${this.rotationForIcon}`;
  }

  private getAnchorList(el: Element): HTMLAnchorElement[] {
    const ul = Array.from(el.children).find(child => child instanceof HTMLUListElement);

    return (Array.from(ul?.children) as HTMLLIElement[]).map(
      li => li.children[0] as HTMLAnchorElement,
    );
  }

  private focusNext(
    list: HTMLAnchorElement[],
    target: HTMLAnchorElement,
    isNextDown: boolean,
  ): void {
    let el: HTMLAnchorElement;
    let index = list.findIndex(item => item === target);

    if (index === -1) {
      el = list[0];
    } else {
      index += isNextDown ? 1 : -1;
      index = (index + list.length) % list.length;
      el = list[Math.max(0, Math.min(index, list.length - 1))];
    }

    el.focus();
  }
}
