import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as crypto from 'crypto-js';
import { Subject, combineLatest, filter } from 'rxjs';

import { UserRecovery } from '@tsq-web/users';

import { getCurrentLogoPath } from '../../../shared/util/environment.utils';
import { ChangePasswordSteps } from '../../models/change-password-steps.enum';
import { ChangePasswordStore } from '../../state/change-password/change-password.store';

@UntilDestroy()
@Component({
  templateUrl: './change-password.component.html',
  providers: [ChangePasswordStore],
})
export class ChangePasswordComponent implements OnInit {
  private readonly store = inject(ChangePasswordStore);
  private readonly router = inject(Router);
  private readonly activatedRoute = inject(ActivatedRoute);

  readonly currentStep$ = this.store.currentStep$;
  readonly steps = ChangePasswordSteps;
  protected readonly logoPath = getCurrentLogoPath();

  readonly changeRequestedSubject = new Subject<string>();
  readonly passwordUpdateRequest$ = combineLatest([
    this.changeRequestedSubject,
    this.activatedRoute.params,
  ]).pipe(filter(([password, params]) => !!password.trim() && !!params.userId && !!params.token));
  readonly isChangingPassword$ = this.store.isChangingPassword$;

  ngOnInit(): void {
    this.store.validateToken();

    this.passwordUpdateRequest$.pipe(untilDestroyed(this)).subscribe(([password, params]) => {
      const hash = crypto.SHA1(password).toString();
      const recovery: UserRecovery = {
        id: params.userId,
        token: params.token,
        hashPassword: hash,
      };

      this.store.changePassword(recovery);
    });
  }

  changePassword(password: string): void {
    this.changeRequestedSubject.next(password);
  }

  backToLogin(): void {
    this.router.navigate(['login']);
  }
}
