/* eslint-disable @angular-eslint/component-selector */
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store, select } from '@ngrx/store';
import moment from 'moment-timezone';

import { fromLocalizationSelectors } from '@tsq-web/localization';
import { AppState } from '@tsq-web/state';
import { fromUserContextSelectors } from '@tsq-web/user-context';
import { User } from '@tsq-web/users';

import { FacilityCalendarDay } from '../../../facility/facility-calendar-day.model';
import { TimeSlot } from '../../../facility/timeslot.json';
import { SCCalendarCaptionController } from '../sc-calendar-caption.controller';

@UntilDestroy()
@Component({
  selector: 'sc-calendar-day',
  templateUrl: './sc-calendar-day.component.html',
  styleUrls: ['./sc-calendar-day.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SCCalendarDayComponent implements OnInit {
  @Input() date: moment.Moment;
  @Input() currentDate: moment.Moment;
  @Input() available = true;
  @Input() isInNavigationMonth = true;
  @Input() timeSlots: TimeSlot[];

  private day: FacilityCalendarDay;

  private user: User;
  private timeZone: string;

  constructor(
    private captionController: SCCalendarCaptionController,
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.store
      .pipe(select(fromUserContextSelectors.selectUser), untilDestroyed(this))
      .subscribe(user => (this.user = user));

    this.store
      .pipe(select(fromLocalizationSelectors.selectTimeZone), untilDestroyed(this))
      .subscribe(timeZone => (this.timeZone = timeZone));
  }

  @Input() set calendarDay(value: FacilityCalendarDay) {
    this.day = value;
    this.captionController.hasCurrentUserEvent =
      this.captionController.hasCurrentUserEvent || this.hasCurrentUserEvent;
    this.captionController.hasFullBusyDay =
      this.captionController.hasFullBusyDay || !this.hasTimeSlotAvailable;
    this.captionController.hasBusyDay = this.captionController.hasBusyDay || this.isBusyDay;
    this.captionController.hasWaitingApproval =
      this.captionController.hasWaitingApproval || this.hasWaitingApproval;
    this.captionController.hasPendingConfirmation =
      this.captionController.hasPendingConfirmation || this.isPendingConfirmation;
    this.captionController.hasWaitingQueue =
      this.captionController.hasWaitingQueue || this.hasWaitingQueue;
  }

  getDay(): number {
    return this.date.date();
  }

  isPast(): boolean {
    return this.date.isBefore(this.currentDate, 'day');
  }

  isFuture(): boolean {
    return this.date.isAfter(this.currentDate, 'day');
  }

  isToday(): boolean {
    return this.date.isSame(this.currentDate, 'day');
  }

  get hasTimeSlotAvailable(): boolean {
    return !!this.day && this.day.events < this.timeSlots.length;
  }

  get isBusyDay(): boolean {
    return this.hasTimeSlotAvailable && this.day.events > 0;
  }

  get isFullBusyDay(): boolean {
    return !this.hasTimeSlotAvailable && !!this.day && this.day.events > 0;
  }

  get hasCurrentUserEvent(): boolean {
    return !!this.day && this.day.userEvents > 0;
  }

  get hasWaitingQueue(): boolean {
    return !!this.day && this.day.hasWaitingQueue;
  }

  get isPendingConfirmation(): boolean {
    return !!this.day && this.day.hasPendingConfirmation;
  }

  get hasWaitingApproval(): boolean {
    return !!this.day && this.day.hasWaitingApproval;
  }
}
