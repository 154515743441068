import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { Subject, combineLatest } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[tsqDropdown]',
})
export class TSqDropdownDirective implements AfterViewInit, OnDestroy {
  @Input() errorMessage = 'MANDATORY_FIELD_ERROR_LABEL';
  @Input() isNewDesign: boolean;

  private control: AbstractControl;

  private errorEl: Element;

  private innerTouched$ = new Subject<void>();

  private directiveDestroyed$ = new Subject<void>();

  constructor(
    private elRef: ElementRef,
    private renderer: Renderer2,
    private translateService: TranslateService,
  ) {}

  ngAfterViewInit(): void {
    if (!!this.control) {
      combineLatest([
        this.innerTouched$.pipe(startWith()),
        this.control.statusChanges.pipe(startWith(this.control.status)),
      ])
        .pipe(takeUntil(this.directiveDestroyed$))
        .subscribe(([, status]) => {
          if (status === 'VALID' || !this.control.touched) {
            this.removeErrorLabel();
          } else if (this.control.touched && status === 'INVALID') {
            this.addErrorLabel();
          }
        });
    } else {
      console.warn(
        'tsqDropdown directive needs an Abstract Control so it can listen to changes and behave as expected.' +
          '\n' +
          "You didn't provide one, so tsqDropdown won't initialise for this element.",
      );
    }
  }

  ngOnDestroy(): void {
    this.directiveDestroyed$.next();
    this.directiveDestroyed$.unsubscribe();
  }

  @Input() set tsqDropdown(value: AbstractControl) {
    this.control = value;
  }

  @HostListener('onHidden') onHidden(): void {
    this.control.markAsTouched();
    this.innerTouched$.next();
  }

  @Input() set markInnerAsTouched(value: boolean) {
    if (value) {
      this.innerTouched$.next();
    }
  }

  private addErrorLabel(): void {
    if (!this.errorEl) {
      const el = this.elRef.nativeElement as Element;
      const parent = this.renderer.parentNode(el);
      const firstChild = el.children[0];

      this.renderer.addClass(firstChild, 'ng-touched');
      this.renderer.addClass(firstChild, 'ng-invalid');

      this.errorEl = this.renderer.createElement('div');
      this.renderer.addClass(
        this.errorEl,
        this.isNewDesign ? 'text-input-error-label' : 'input-error-label',
      );

      if (!!this.errorMessage) {
        this.renderer.appendChild(
          this.errorEl,
          this.renderer.createText(this.translateService.instant(this.errorMessage)),
        );
      }

      this.renderer.insertBefore(parent, this.errorEl, el.nextSibling);
    }
  }

  private removeErrorLabel(): void {
    if (!!this.errorEl) {
      const el = this.elRef.nativeElement;
      const firstChild = (el as Element).children[0];
      this.renderer.removeClass(firstChild, 'ng-touched');
      this.renderer.removeClass(firstChild, 'ng-invalid');
      this.renderer.removeChild(el, this.errorEl);
      this.errorEl = null;
    }
  }
}
