<div class="base-wrap">
  <div class="layout-flex">
    <div class="flex-content-wrapper">
      <neighbor-mass-invite-layout>
        <ng-container class="content">
          <ng-container *ngIf="(isSuccessful$ | async) === false; else success">
            <neighbor-mass-invite-form
              (submit)="sendEmails($event)"
              [isLoading]="isLoading$ | async"
            ></neighbor-mass-invite-form>
          </ng-container>

          <ng-template #success>
            <div class="success-label">
              {{ 'MASS_INVITE_NEIGHBORS.SUCCESS_TITLE' | translate }}
            </div>

            <button
              id="mass-invite-neighbors--button--success-redirect"
              class="btn btn-primary"
              (click)="redirectToCondo()"
            >
              {{ 'MASS_INVITE_NEIGHBORS.SUCCESS_BACK' | translate }}
            </button>
          </ng-template>
        </ng-container>
      </neighbor-mass-invite-layout>
    </div>
  </div>
</div>

<comm-in-app-feedback-modal *ngIf="inAppFeedback" />
