import { Component, Input, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store, select } from '@ngrx/store';
import * as crypto from 'crypto-js';
import { Observable, combineLatest } from 'rxjs';

import { icons } from '@tsq-web/assets';
import { AppType } from '@tsq-web/core';
import { getTSqEnvironment } from '@tsq-web/environment';
import { TSqValidators } from '@tsq-web/forms';
import { selectUntilDestroyed } from '@tsq-web/redux/operators';
import { fromRouterSelectors } from '@tsq-web/router';
import { AppState } from '@tsq-web/state';
import * as fromUserContextActions from '@tsq-web/user-context/actions';
import * as fromUserContextSelectors from '@tsq-web/user-context/selectors';
import { User, fromUsersActions, fromUsersSelectors } from '@tsq-web/users';

import { AuthProvider } from '../../models/auth-provider.enum';
import { LoginApplication } from '../../models/login-application.model';
import { LoginBackground } from '../../models/login-background.model';
import { LoginBody, LoginCredentials } from '../../models/login-body.model';
import { Platform } from '../../models/platform.enum';
import { SsoResetPasswordStore } from '../../state/sso-reset-password.store';

@UntilDestroy()
@Component({
  selector: 'tsq-login',
  templateUrl: './tsq-login.component.html',
  styleUrls: ['./tsq-login.component.scss'],
})
export class TSqLoginComponent implements OnInit {
  @Input() ssoLink: string;
  @Input() ssoPasswordReset: boolean;

  loggingIn$: Observable<boolean>;
  sendingUserRecovery$: Observable<boolean>;
  completedUserRecovery$: Observable<boolean>;
  resetPasswordLoading$: Observable<boolean>;
  showSentEmailMessage$: Observable<boolean>;

  forgotPassword: boolean;
  title: string;
  description: string;
  buttonText: string;
  ssoText: string;
  actionText: string;
  isNewAccount = false;

  loginForm = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl(''),
  });

  background: LoginBackground = new LoginBackground();
  environment = getTSqEnvironment();

  readonly checkCircleFill = icons.checkCircleFill;

  private typedEmail: string;
  private isSsoEmail = false;
  private _ssoEnabled = false;

  private readonly emailRegex =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  private readonly store = inject(Store<AppState>);
  private readonly router = inject(Router);
  private readonly ssoResetPasswordStore = inject(SsoResetPasswordStore);

  ngOnInit(): void {
    this.setBackgroundShapes();

    this.listenToStore();

    this.refreshTextKeys();
  }

  @Input() set ssoEnabled(value: boolean) {
    this._ssoEnabled = value;
    this.refreshTextKeys();
  }

  get ssoEnabled(): boolean {
    return this._ssoEnabled;
  }

  get isFormValid(): boolean {
    return (
      this.loginForm.valid && (this.forgotPassword || !!this.loginForm.controls.password.value)
    );
  }

  get ssoDisableForm(): boolean {
    return (
      this.forgotPassword &&
      this.isSsoEmail &&
      this.typedEmail === this.loginForm.controls.username.value
    );
  }

  get username(): string {
    return this.loginForm.controls.username.value;
  }

  get password(): string {
    return this.loginForm.controls.password.value;
  }

  get showResetYourPasswordCard(): boolean {
    return (
      this.forgotPassword &&
      this.isSsoEmail &&
      this.typedEmail === this.loginForm.controls.username.value
    );
  }

  setBackgroundShapes(): void {
    if (this.environment.appType === AppType.BUSINESS) {
      this.background.logo = '/assets/img/brand/logo-light.svg';
      this.background.topShape = '/assets/img/shapes/login-top-shape.svg';
      this.background.bottomShape = '/assets/img/shapes/login-bottom-shape.svg';
    }
  }

  toggleForgotPassword(): void {
    this.forgotPassword = !this.forgotPassword;

    if (this.forgotPassword) {
      this.loginForm.controls.username.setValidators([
        Validators.required,
        TSqValidators.validEmail,
      ]);
    } else {
      this.loginForm.controls.username.setValidators([Validators.required]);
    }
    this.loginForm.controls.username.updateValueAndValidity();
    this.refreshTextKeys();
  }

  refreshTextKeys(): void {
    if (this.isNewAccount) {
      this.title = 'LOGIN.ACCOUNT_CREATED_TITLE';
      this.description = 'LOGIN.LOGIN_DESCRIPTION';
      this.buttonText = 'LOGIN.SIGN_IN';
      this.actionText = 'LOGIN.FORGOT_MY_PASSWORD';
    } else if (this.forgotPassword) {
      this.title = 'LOGIN.FORGOT_MY_PASSWORD';
      this.description = 'LOGIN.FORGOT_PASSWORD_DESCRIPTION';
      this.buttonText = 'LOGIN.SEND_CONFIRMATION';
      this.actionText = 'LOGIN.BACK_TO_LOGIN';
    } else {
      this.title = 'LOGIN.WELCOME_BACK';
      this.description = 'LOGIN.LOGIN_DESCRIPTION';
      this.buttonText = `LOGIN.${this.ssoEnabled ? 'LOG_IN' : 'SIGN_IN'}`;
      this.actionText = 'LOGIN.FORGOT_MY_PASSWORD';
    }
    this.ssoText = 'LOGIN.SIGN_IN_WITH_SSO';
  }

  onSubmit(): void {
    if (!this.forgotPassword) {
      this.onLogin();
    } else {
      if (this.ssoPasswordReset) {
        this.ssoResetPasswordStore.resetPassword({ email: this.username });
      } else {
        this.store.dispatch(fromUsersActions.requestUserRecovery({ email: this.username }));
      }
    }
  }

  onLogin(): void {
    if (this.environment.appType === AppType.COMMUNITIES) {
      const user = new User();
      user.platform = 'webapp';
      user.login_type = 'CMN';
      user.email = this.username;
      user.password = this.generateSHA1(this.password);
      this.store.dispatch(fromUserContextActions.login({ user }));
    } else {
      const loginBody = new LoginBody();
      loginBody.credentials = new LoginCredentials();
      loginBody.credentials.username = this.username;
      loginBody.application = new LoginApplication();
      loginBody.application.name = AppType.BUSINESS;
      loginBody.application.platform = Platform.WebApp;
      loginBody.application.version = '1.0.0';

      if (this.isEmail) {
        loginBody.credentials.password = this.generateSHA1(this.password);
        loginBody.credentials.authProvider = AuthProvider.TOWNSQ;
      } else {
        loginBody.credentials.password = this.password;
        loginBody.credentials.authProvider = AuthProvider.ACTIVE_DIRECTORY;
      }

      this.store.dispatch(fromUserContextActions.contextLogin({ loginBody }));
    }
  }

  onLoginWithSso(): void {
    this.router.navigate(['/sso']);
  }

  onBackToLogin(): void {
    this.toggleForgotPassword();
    this.ssoResetPasswordStore.resetShowSentEmailMessage();
    this.store.dispatch(fromUsersActions.restoreUserRecoveryInitialState());
  }

  private get isEmail(): boolean {
    return this.emailRegex.test(this.username);
  }

  private generateSHA1(toDigest: string): string {
    return crypto.SHA1(toDigest).toString();
  }

  private listenToStore(): void {
    this.loggingIn$ = this.store.pipe(select(fromUserContextSelectors.selectUserLoggingIn));
    this.sendingUserRecovery$ = this.store.pipe(
      select(fromUsersSelectors.selectUserRecoverySending),
    );
    this.completedUserRecovery$ = this.store.pipe(
      select(fromUsersSelectors.selectUserRecoveryCompleted),
    );

    this.store
      .pipe(selectUntilDestroyed(fromRouterSelectors.selectRouterQueryParams, this))
      .subscribe(params => (this.isNewAccount = !!params.newAccount));

    this.resetPasswordLoading$ = this.ssoResetPasswordStore.resetPasswordLoading$;
    this.showSentEmailMessage$ = this.ssoResetPasswordStore.showSentEmailMessage$;

    combineLatest([this.ssoResetPasswordStore.emailIsSso$, this.ssoResetPasswordStore.typedEmail$])
      .pipe(untilDestroyed(this))
      .subscribe(([emailIsSso, typedEmail]) => {
        this.isSsoEmail = emailIsSso;
        this.typedEmail = typedEmail;
      });
  }
}
