import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { TSqChipsListComponent } from '@tsq-web/chips';

@Component({
  selector: 'comm-chips-group',
  templateUrl: './chips-groups.component.html',
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipsGroupComponent extends TSqChipsListComponent {}
