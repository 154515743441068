<div
  bsModal
  #modal="bs-modal"
  class="modal fadeInRight"
  tabindex="-1"
  role="dialog"
  [config]="{ ignoreBackdropClick: ignoreBackdropClick, keyboard: !ignoreEscClick }"
  (onShow)="modalShowFired($event)"
  (onHide)="modalHideFired($event)"
  (onHidden)="modalHiddenFired($event)"
>
  <div class="modal-dialog" #modalDialog>
    <div class="modal-content">
      <div class="modal-header">
        <ng-content select="[modal-header]"></ng-content>
        <div *ngIf="!!title">{{ title }}</div>
        <img
          src="./assets/img/icons/light-close-avocado.svg"
          class="pointer-cursor"
          (click)="innerClose()"
          alt=""
        />
      </div>

      <div class="divider divider-dark"></div>

      <div *ngIf="hasTabs">
        <tsq-tabs
          [tabs]="tabs"
          [selected]="selected"
          [disabled]="sending"
          [shouldUppercase]="true"
          (selectedChange)="selectedChange.emit($event)"
        ></tsq-tabs>
      </div>

      <div class="modal-body tsq-scroll-y">
        <ng-content select="[modal-content]"></ng-content>
      </div>

      <ng-container *ngIf="hasFooter">
        <div class="divider divider-dark"></div>
        <div class="modal-footer">
          <ng-content select="[modal-footer]"></ng-content>
        </div>
      </ng-container>
    </div>
  </div>
</div>
