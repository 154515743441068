import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpService } from '@tsq-web/core';
import { TypeaheadDataSource, TypeaheadItem } from '@tsq-web/forms';

import { EntityDeletionDetails } from '../../../apps/communities/src/app/+dashboard/shared/deactivate-modal/models/entity-deletion-details.models';
import { Administrator } from '../models/administrator.model';

@Injectable()
export class AdministratorService
  extends HttpService
  implements TypeaheadDataSource<Administrator>
{
  cachedAdministrators: Administrator[] = [];

  constructor(
    protected cookieService: CookieService,
    protected httpClient: HttpClient,
    protected router: Router,
  ) {
    super();
  }

  getEntities(search: string): Observable<TypeaheadItem<Administrator>[]> {
    return this.getAdministratorList(0, 7, search).pipe(
      map(administrators =>
        administrators.map(administrator => ({
          text: administrator.name,
          value: administrator,
        })),
      ),
    );
  }

  getAdministratorList(
    page = 0,
    size = 1000,
    search?: string,
    active?: string,
    inactive?: string,
    countCondos?: string,
  ): Observable<Administrator[]> {
    let params: HttpParams = new HttpParams();
    params = params.append('size', String(size));
    params = params.append('page', String(page));

    if (active === 'false' && inactive === 'false') {
      active = 'true';
      inactive = 'true';
    }

    if (!!search) {
      params = params.append('search', search);
    }
    if (!!active) {
      params = params.append('active', String(active));
    }
    if (!!inactive) {
      params = params.append('inactive', String(inactive));
    }
    if (!!countCondos) {
      params = params.append('countCondos', String(countCondos));
    }

    const options = this.getJsonOptions({ params });

    return this.httpClient
      .get<{ content: Administrator[] }>(this.getFullUrl(`administrators`, `v1`), options)
      .pipe(map(page => page.content));
  }

  getAdministrator(administratorId: string): Observable<Administrator> {
    let params: HttpParams = new HttpParams();
    params = params.append('includeDeleted', true);

    const options = this.getJsonOptions({ params });

    return this.httpClient.get<Administrator>(
      this.getFullUrl(`administrator/${administratorId}`),
      options,
    );
  }

  saveAdministrator(administrator: Administrator): Observable<HttpResponse<Administrator>> {
    const options = { ...this.getJsonOptions(), observe: 'response' as 'response' };

    return this.httpClient.post<Administrator>(
      this.getFullUrl(`administrator`),
      administrator,
      options,
    );
  }

  deleteAdministrator(administrator: Administrator): Observable<Administrator> {
    return this.httpClient.delete<Administrator>(
      this.getFullUrl(`administrator/${administrator.id}`),
    );
  }

  inactivateAdministrator(
    administrator: Administrator,
    reason: EntityDeletionDetails,
  ): Observable<Administrator> {
    return this.httpClient.patch<Administrator>(
      this.getFullUrl(`administrators/${administrator.id}/inactivate-start`, 'v1'),
      reason,
    );
  }

  reactivateAdministrator(
    administrator: Administrator,
    reason: EntityDeletionDetails,
  ): Observable<Administrator> {
    return this.httpClient.patch<Administrator>(
      this.getFullUrl(`administrators/${administrator.id}/reactivate-start`, 'v1'),
      reason,
    );
  }

  updateAdministrator(administrator: Administrator): Observable<HttpResponse<Administrator>> {
    let params: HttpParams = new HttpParams();
    params = params.append('updateFeatures', true);
    params = params.append('updateExternalPaymentLink', true);

    const options = { ...this.getJsonOptions({ params }), observe: 'response' as 'response' };

    return this.httpClient.put<Administrator>(
      this.getFullUrl(`administrator/${administrator.id}`),
      administrator,
      options,
    );
  }
}
