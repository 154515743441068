import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, combineLatest, iif, map, mergeMap, of } from 'rxjs';

import { TooltipTruncatedDirective } from '../../directives/tooltip-truncated/tooltip-truncated.directive';
import { TableColumnCurrency } from '../../models/table.model';
import { TableCellBaseComponent } from '../table-cell-base/table-cell-base.component';

@Component({
  standalone: true,
  imports: [AsyncPipe, TableCellBaseComponent, TooltipTruncatedDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'tsq-table-cell-currency',
  template: `
    <tsq-table-cell-base [align]="'right'" [tsqTooltipTruncated]="el">
      <div class="text-coal-secondary truncate" #el>{{ text$ | async }}</div>
    </tsq-table-cell-base>
  `,
  styles: [
    `
      :host {
        @apply min-w-0;
      }
    `,
  ],
})
export class TableCellCurrencyComponent<TRowData> {
  private readonly contentSubject = new BehaviorSubject<
    TableColumnCurrency<TRowData>['content'] | undefined
  >(undefined);
  private readonly rowDataSubject = new BehaviorSubject<TRowData | undefined>(undefined);

  private readonly translate = inject(TranslateService);

  readonly text$: Observable<string> = combineLatest([
    this.contentSubject.asObservable(),
    this.rowDataSubject.asObservable(),
  ]).pipe(
    mergeMap(([content, rowData]) => {
      const emptyValue = '';

      if (!content || !rowData) {
        return of(emptyValue);
      }

      let num: number | undefined = undefined;

      if (typeof content.value === 'function') {
        num = content.value(rowData);
      } else if ('key' in content.value) {
        const cellData = rowData[content.value.key];

        if (typeof content.value.transform === 'function') {
          num = content.value.transform(cellData);
        } else {
          if (typeof cellData === 'number') {
            num = cellData;
          } else {
            return of(emptyValue);
          }
        }
      } else {
        return of(emptyValue);
      }

      return iif(
        () => typeof num === 'number',
        of(num).pipe(
          map(value => {
            const fractionDigits = content.fractionDigits ?? 2;

            return value.toFixed(fractionDigits).split('.');
          }),
          map(([whole, fraction]) => {
            const defaultThousandsSeparator = '';

            let separator: string;
            if (typeof content.thousandsSeparator === 'function') {
              separator =
                content.thousandsSeparator({ translate: this.translate }) ??
                defaultThousandsSeparator;
            } else {
              separator =
                typeof content.thousandsSeparator === 'string'
                  ? content.thousandsSeparator
                  : defaultThousandsSeparator;
            }

            return [whole.replace(/\B(?=(\d{3})+(?!\d))/g, separator), fraction];
          }),
          map(([whole, fraction]) => {
            if (!fraction) {
              return whole;
            }

            const defaultFractionSeparator = '.';

            let separator: string;
            if (typeof content.fractionSeparator === 'function') {
              separator =
                content.fractionSeparator({ translate: this.translate }) ??
                defaultFractionSeparator;
            } else {
              separator =
                typeof content.fractionSeparator === 'string'
                  ? content.fractionSeparator
                  : defaultFractionSeparator;
            }

            return `${whole}${separator}${fraction}`;
          }),
          map(numAsString => {
            const defaultPrefix = '$';

            let prefix: string;
            if (typeof content.prefix === 'function') {
              prefix = content.prefix({ translate: this.translate }) ?? defaultPrefix;
            } else {
              prefix = typeof content.prefix === 'string' ? prefix : defaultPrefix;
            }

            return !!prefix ? `${prefix} ${numAsString}` : numAsString;
          }),
        ),
        of(emptyValue),
      );
    }),
  );

  @Input()
  set content(value: TableColumnCurrency<TRowData>['content']) {
    this.contentSubject.next(value);
  }

  @Input()
  set rowData(value: TRowData) {
    this.rowDataSubject.next(value);
  }
}
