<div class="tsq-card" [class.multi-line]="multiLine">
  <div class="info-container">
    <h1 [class.no-text]="!text">{{ title | translate }}</h1>
    <span id="tsq-feature-header--span--text" class="mt-4" *ngIf="!!text">{{
      text | translate
    }}</span>
  </div>

  <div class="break" *ngIf="multiLine"></div>

  <div class="content-container">
    <div class="search-container" [ngStyle]="{ width: customSearchWidth }" *ngIf="showSearchInput">
      <input
        id="search-input"
        class="form-control"
        autocomplete="off"
        tsqSearchInput
        [formControl]="searchControl"
        [placeholder]="searchPlaceholder | translate"
      />
    </div>

    <ng-content></ng-content>
  </div>
</div>
