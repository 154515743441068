import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { concatLatestFrom } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, switchMap, tap } from 'rxjs';

import { ToastErrorComponent } from '@tsq-web/toast';
import { UserRecovery, UserRecoveryService } from '@tsq-web/users';

import { ChangePasswordSteps } from '../../models/change-password-steps.enum';
import { ResetPasswordService } from '../../services/reset-password.service';

interface ChangePasswordState {
  isChangingPassword: boolean;
  currentStep: ChangePasswordSteps;
}

const initialState: ChangePasswordState = {
  isChangingPassword: false,
  currentStep: undefined,
};

@Injectable()
export class ChangePasswordStore extends ComponentStore<ChangePasswordState> {
  readonly isChangingPassword$ = this.select(({ isChangingPassword }) => isChangingPassword);
  readonly currentStep$ = this.select(({ currentStep }) => currentStep);

  readonly changePassword = this.effect((userRecovery$: Observable<UserRecovery>) =>
    userRecovery$.pipe(
      tap(() => this.setIsChangingPassword(true)),
      switchMap(userRecovery =>
        this.userRecoveryService.submitRecovery(userRecovery).pipe(
          tapResponse(
            () => this.changePasswordSuccess(),
            (error: HttpErrorResponse) => {
              this.setIsChangingPassword(false);

              const isLinkExpired = error.status === HttpStatusCode.Forbidden;
              const toastText = isLinkExpired
                ? 'RECOVERY.TOASTS.LINK_EXPIRED_MESSAGE'
                : 'COMMON_ERROR_TEXT';
              const toastTitle = isLinkExpired
                ? 'RECOVERY.TOASTS.LINK_EXPIRED_TITLE'
                : 'COMMON_ERROR_TITLE';
              this.toastr.show(
                this.translate.instant(toastText),
                this.translate.instant(toastTitle),
                { toastComponent: ToastErrorComponent },
              );
            },
          ),
        ),
      ),
    ),
  );

  readonly validateToken = this.effect(data$ =>
    data$.pipe(
      concatLatestFrom(() => this.activatedRoute.params),
      switchMap(([, params]) => {
        const userId = params.userId;

        return this.resetPasswordService.validateToken(userId).pipe(
          tapResponse(
            () => this.patchState({ currentStep: ChangePasswordSteps.Form }),
            () => this.patchState({ currentStep: ChangePasswordSteps.InvalidToken }),
          ),
        );
      }),
    ),
  );

  private readonly setIsChangingPassword = this.updater((state, isChangingPassword: boolean) => ({
    ...state,
    isChangingPassword,
  }));
  private readonly changePasswordSuccess = this.updater(state => ({
    ...state,
    isChangingPassword: false,
    currentStep: ChangePasswordSteps.Success,
  }));

  private readonly userRecoveryService = inject(UserRecoveryService);
  private readonly resetPasswordService = inject(ResetPasswordService);
  private readonly toastr = inject(ToastrService);
  private readonly translate = inject(TranslateService);
  private readonly activatedRoute = inject(ActivatedRoute);

  constructor() {
    super(initialState);
  }
}
