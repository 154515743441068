<div (click)="toggleTab()"
     [class.open]="open" class="accordion-title pointer-cursor">
  <div>
    <span>{{ title | translate | uppercase }}</span>
    <ng-content *ngIf="!open" select="[context]"></ng-content>
  </div>
  <i class="fa" [class.fa-angle-down]="!open" [class.fa-angle-up]="open" aria-hidden="true"></i>
</div>
<div [hidden]="!open" class="accordion-body" [class.has-padding]="hasBodyPadding">
  <ng-content></ng-content>
</div>
