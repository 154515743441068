import { Component } from '@angular/core';

import { SSOLoginUrls } from '@tsq-web/environment';

import { getEnvironment } from '../../shared/environment';
import { CommunitiesEnvironments } from '../../shared/environment/models/communities-environments.enum';

@Component({
  selector: 'comm-reset-your-password-card',
  template: `
    <div
      class="bg-yellow-n1 border-1 border-yellow-p1 mb-24 flex w-full flex-col justify-between rounded p-16 shadow"
    >
      <span class="font-bold text-base">
        {{ 'RECOVERY.RESET_YOUR_PASSWORD' | translate }}
      </span>

      <p class="small-text mb-0">
        {{ 'RECOVERY.YOUR_EMAIL_IS_LINKED' | translate }}
        <a
          class="hover:text-kale-p1 focus:text-kale-p1 text-kale text-sm"
          target="_blank"
          [href]="ssoLink"
          >{{ 'RECOVERY.LOGIN_WITH_YOUR' | translate }}</a
        >
      </p>
    </div>
  `,
})
export class ResetYourPasswordCardComponent {
  get ssoLink(): string {
    switch (getEnvironment().env) {
      case CommunitiesEnvironments.DEV:
      case CommunitiesEnvironments.LOCAL:
        return SSOLoginUrls.QA;
      case CommunitiesEnvironments.UAT:
        return SSOLoginUrls.UAT;
      case CommunitiesEnvironments.US_PROD:
        return SSOLoginUrls.PROD_US;
      case CommunitiesEnvironments.BR_PROD:
        return SSOLoginUrls.PROD_BR;
      default:
        return SSOLoginUrls.QA;
    }
  }
}
