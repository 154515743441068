import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ToastrModule } from 'ngx-toastr';

import { TSqFormsModule } from '@tsq-web/forms';
import { TSqLoadersModule } from '@tsq-web/loaders';

import { CondoService } from '../condo/condo.service';
import { SCWidgetModule } from '../shared/component/sc.widget.module';
import { LoginService } from '../shared/user/login.service';
import { AisAccountRetrievalComponent } from './components/ais-account-retrieval/ais-account-retrieval.component';
import { AisLinkSignUpLoginComponent } from './components/ais-link-sign-up-login/ais-link-sign-up-login.component';
import { AisOwnerSelectionComponent } from './components/ais-owner-selection/ais-owner-selection.component';
import { LoginInfoBannerComponent } from './components/login-info-banner/login-info-banner.component';
import { AisLinkLoggedComponent } from './containers/ais-link-logged/ais-link-logged.component';
import { AisLinkRegisterComponent } from './containers/ais-link-register/ais-link-register.component';
import { AisLinkUserComponent } from './containers/ais-link-user/ais-link-user.component';
import { AisMultipleAccountsComponent } from './containers/ais-multiple-accounts/ais-multiple-accounts.component';
import { PublicAisComponent } from './containers/public-ais/public-ais.component';
import { PublicAisRoutingModule } from './public-ais.routes';
import { PublicAisService } from './services/public-ais.service';

@NgModule({
  imports: [
    PublicAisRoutingModule,
    SCWidgetModule,
    TooltipModule,
    TypeaheadModule,
    ToastrModule,
    TSqLoadersModule,
    TSqFormsModule,
    TranslateModule.forRoot(),
  ],
  declarations: [
    PublicAisComponent,
    AisAccountRetrievalComponent,
    AisMultipleAccountsComponent,
    AisOwnerSelectionComponent,
    AisLinkUserComponent,
    AisLinkSignUpLoginComponent,
    AisLinkRegisterComponent,
    AisLinkLoggedComponent,
    LoginInfoBannerComponent,
  ],
  providers: [PublicAisService, LoginService, CondoService],
})
export class PublicAisModule {}
