import { Component, Input } from '@angular/core';

@Component({
  selector: 'tsq-small-loader',
  templateUrl: './tsq-small-loader.component.html',
  styleUrls: ['./tsq-small-loader.component.scss'],
})
export class TSqSmallLoaderComponent {
  @Input() backgroundColor = '';
}
