import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CondoSubscription } from '../../../shared/subscription/condo-subscription.json';
import { TsqConfirmationDialogComponent } from '../../../shared/component/tsq-confimation-dialog/tsq-confirmation-dialog.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'subscription-summary-modal',
  templateUrl: './subscription-summary-modal.component.html',
  styleUrls: ['./subscription-summary-modal.component.scss'],
})
export class SubscriptionSummaryModalComponent {
  @ViewChild('summaryModal', { static: true }) summaryModal: TsqConfirmationDialogComponent;

  @Input() subscription: CondoSubscription;
  @Input() multiplierNumber: number;
  @Input() percentageNumber: number;
  @Input() rawValueNumber: number;
  @Input() totalAmount: number;
  @Output() confirmChangeSubscription = new EventEmitter();

  openSummary(): void {
    this.summaryModal.askConfirmation();
  }

  onConfirmClicked(): void {
    this.confirmChangeSubscription.emit();
  }
}
