import { AsyncPipe, NgIf, NgStyle, NgTemplateOutlet } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  distinctUntilChanged,
  fromEvent,
  map,
  merge,
  startWith,
} from 'rxjs';

import { AssetsModule, icons } from '@tsq-web/assets';

import { TableColumnActions } from '../../models/table.model';
import { TableCellBaseComponent } from '../table-cell-base/table-cell-base.component';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [AsyncPipe, NgIf, NgStyle, NgTemplateOutlet, AssetsModule, TableCellBaseComponent],
  selector: 'tsq-table-cell-actions',
  template: `
    <tsq-table-cell-base class="text-coal-secondary" size="small">
      <button
        *ngIf="show$ | async"
        class="bg-petro-p1 hover:bg-petro-n2 hover:text-primary rounded transition-colors"
        (click)="toggleActionsCard(); $event.stopPropagation()"
      >
        <tsq-icon [icon]="dotsIcon" classes="rotate-90" />
      </button>

      <div class="fixed" [ngStyle]="actionsCardStyle$ | async" #actionsCard>
        <div
          class="actions-card bg-petro-p1 border-1 border-petro-n2 content relative rounded p-8 shadow"
          [attr.actions-card-arrow]="actionsCardArrow$ | async"
        >
          <ng-container
            *ngTemplateOutlet="actionsTemplate; context: { $implicit: rowData$ | async }"
          />
        </div>
      </div>
    </tsq-table-cell-base>
  `,
  styles: [
    `
      button {
        &:focus-visible {
          outline: theme('colors.purple.DEFAULT') dotted 2px;
          outline-offset: 0;
        }

        &:focus:not(:focus-visible) {
          outline: none;
        }
      }

      div[actions-card-arrow]:before {
        @apply bg-petro-p1 absolute right-8 size-16 rotate-45;

        content: '';
      }

      div[actions-card-arrow='top']:before {
        @apply -top-8;

        border-top: inherit;
        border-left: inherit;
      }

      div[actions-card-arrow='bottom']:before {
        @apply -bottom-8;

        border-bottom: inherit;
        border-right: inherit;
      }
    `,
  ],
})
export class TableCellActionsComponent<TRowData> implements AfterViewInit {
  @ViewChild('actionsCard', { static: true }) actionsCard: ElementRef<HTMLDivElement>;

  @Input() actionsTemplate: TemplateRef<{ $implicit: TRowData }>;

  readonly dotsIcon = icons.ellipsis;

  private readonly contentSubject = new BehaviorSubject<
    TableColumnActions<TRowData>['content'] | undefined
  >(undefined);
  private readonly rowDataSubject = new BehaviorSubject<TRowData | undefined>(undefined);
  readonly rowData$ = this.rowDataSubject.asObservable();

  readonly show$: Observable<boolean> = combineLatest([
    this.contentSubject.asObservable(),
    this.rowData$,
  ]).pipe(
    map(([content, rowData]) => {
      if (!content || !rowData) {
        return true;
      }

      if (typeof content.show === 'boolean') {
        return content.show;
      } else if (typeof content.show === 'function') {
        const result = content.show(rowData);

        return typeof result === 'boolean' ? result : true;
      } else if ('key' in content.show) {
        const cellData = rowData[content.show.key];

        if (typeof content.show.transform === 'function') {
          const result = content.show.transform(cellData);

          return typeof result === 'boolean' ? result : true;
        }

        return typeof cellData === 'boolean' ? cellData : true;
      }

      return true;
    }),
  );

  private readonly el = inject<ElementRef<HTMLElement>>(ElementRef).nativeElement;
  private readonly renderer = inject(Renderer2);

  private readonly actionsCardArrowSubject = new BehaviorSubject<'top' | 'bottom'>('bottom');
  readonly actionsCardArrow$ = this.actionsCardArrowSubject.asObservable();

  private readonly toggleActionsCardSubject = new Subject<void>();
  private readonly hideActionsCardSubject = new Subject<void>();
  readonly actionsCardStyle$ = merge(
    this.toggleActionsCardSubject.asObservable().pipe(map(() => 'toggle' as const)),
    this.hideActionsCardSubject.asObservable().pipe(map(() => 'hide' as const)),
  ).pipe(
    map(action => {
      return action === 'toggle' && this.actionsCardElDisplay === 'none'
        ? ('show' as const)
        : ('hide' as const);
    }),
    distinctUntilChanged(),
    map(action => {
      if (action === 'show') {
        const elRect = this.el.getBoundingClientRect();

        this.renderer.setStyle(this.actionsCard.nativeElement, 'display', 'block');
        const actionsCardRect = this.actionsCard.nativeElement.getBoundingClientRect();

        const screenHeight = document.documentElement.clientHeight;
        const remainingVerticalSpace = screenHeight - elRect.bottom;

        const verticalOffset = 4;
        let top: string;
        if (remainingVerticalSpace > actionsCardRect.height) {
          top = `${elRect.top + elRect.height - verticalOffset}px`;
          this.actionsCardArrowSubject.next('top');
        } else {
          top = `${elRect.top - actionsCardRect.height + verticalOffset}px`;
          this.actionsCardArrowSubject.next('bottom');
        }

        const horizontalOffset = 6;
        const right = `calc(round(down, calc(100% - ${elRect.right}px), 1px) + ${horizontalOffset}px)`;

        return {
          display: 'block',
          right,
          top,
        };
      }

      return {
        display: 'none',
      };
    }),
    startWith({
      display: 'none',
    }),
  );

  ngAfterViewInit(): void {
    if (!!this.actionsCard) {
      const scrollableAncestors: HTMLElement[] = [];

      let curEl = this.actionsCard.nativeElement.parentElement;

      while (!!curEl) {
        const styles = getComputedStyle(curEl);

        if (
          curEl.scrollHeight > curEl.clientHeight &&
          (styles.overflowY === 'auto' || styles.overflowY === 'scroll')
        ) {
          scrollableAncestors.push(curEl);
        }

        curEl = curEl.parentElement;
      }

      scrollableAncestors.forEach(scrollableEl => {
        fromEvent(scrollableEl, 'scroll', () => {
          this.hideActionsCardSubject.next();
        })
          .pipe(untilDestroyed(this))
          .subscribe();
      });
    }
  }

  @Input()
  set content(value: TableColumnActions<TRowData>['content']) {
    this.contentSubject.next(value);
  }

  @Input()
  set rowData(value: TRowData) {
    this.rowDataSubject.next(value);
  }

  toggleActionsCard(): void {
    this.toggleActionsCardSubject.next();
  }

  @HostListener('window:resize')
  private onWindowResize(): void {
    this.hideActionsCardSubject.next();
  }

  @HostListener('window:scroll')
  private onWindowScroll(): void {
    this.hideActionsCardSubject.next();
  }

  @HostListener('window:click', ['$event'])
  private onWindowClick(event: PointerEvent): void {
    if (
      this.actionsCardElDisplay === 'block' &&
      !this.actionsCard?.nativeElement.contains(event.target as HTMLElement)
    ) {
      this.hideActionsCardSubject.next();
    }
  }

  private get actionsCardElDisplay(): string {
    if (!this.actionsCard) {
      return 'none';
    }

    return getComputedStyle(this.actionsCard.nativeElement).display;
  }
}
