import { Component, EventEmitter, Input, Output } from '@angular/core';

import { icons } from '@tsq-web/assets';

@Component({
  selector: 'tsq-create-comment-placeholder',
  templateUrl: './create-comment-placeholder.component.html',
})
export class CreateCommentPlaceholderComponent {
  @Input() customPlaceHolder: string;

  @Output() focused = new EventEmitter();

  readonly icons = icons;
}
