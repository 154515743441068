import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { Tab } from '../../models/tab.model';

@Component({
  selector: 'tsq-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent {
  @Input() tabs: Tab[];
  @Input() selected: number;
  @Input() disabled: boolean;
  @Input() shouldUppercase: boolean;
  @Input() useIconPool = false;

  @Output() selectedChange = new EventEmitter<number>();

  selectTab(index: number): void {
    if (!this.disabled) {
      this.selectedChange.emit(index);
    }
  }

  badgeClasses(tab: Tab, active: boolean): string {
    return active
      ? tab.activeBadgeColorClass ?? 'bg-primary'
      : tab.inactiveBadgeColorClass ?? 'dark-background';
  }
}
