/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export class Year {
  name: number;
  size: number;
}
export class Month {
  name: number;
  size: number;
}
export class Legend {
  date: string;
  label: string;
  size: number;
  startIndex: number;
  endIndex: number;
}

@Component({
  selector: 'tsq-two-handles-slider',
  templateUrl: 'tsq-two-handles-slider.component.html',
  styleUrls: ['tsq-two-handles-slider.component.scss'],
})
export class TSqTwoHandlesSliderComponent implements OnInit {
  @Input() startDate = '';
  @Input() endDate = '';
  @Input() legends: Legend[] = [];
  @Input() startValue = '';
  @Input() endValue = '';

  @Output() rangeChanged = new EventEmitter<string>();

  years: Year[] = [];
  months: Month[] = [];
  startValueIndex = -1;
  endValueIndex = -1;
  dates: string[] = [];
  legendsLocal: Legend[] = [];

  ngOnInit() {
    //Generate Dates
    let values = this.startDate.split('/');
    const startYear: number = parseInt(values[0]);
    const startMonth: number = parseInt(values[1]);

    values = this.endDate.split('/');
    const endYear: number = parseInt(values[0]);
    const endMonth: number = parseInt(values[1]);

    values = this.legends[0].date.split('/');
    const firstYear: number = parseInt(values[0]);
    const firstMonth: number = parseInt(values[1]);

    //Create legends
    if (firstYear > startYear || (firstYear === startYear && firstMonth > startMonth)) {
      const firstLegend: Legend = new Legend();
      firstLegend.date = '0/0';
      firstLegend.label = '';
      firstLegend.startIndex = 0;
      firstLegend.endIndex = 0;
      firstLegend.size = 0;
      this.legendsLocal.unshift(firstLegend);
    }
    this.legendsLocal = this.legendsLocal.concat(this.legends);

    let currentYear: number = startYear;
    let currentMonth: number = startMonth;
    let countMonth = 0;
    let countMonthLegend = -1;
    let indexMonth = 0;
    while (currentYear < endYear || (currentYear === endYear && currentMonth <= endMonth)) {
      this.months.push(this.createMonth(currentMonth));
      const date = currentYear + '/' + currentMonth;
      this.dates.push(date);

      //Select range
      if (this.startValue === date) {
        this.startValueIndex = indexMonth;
      }
      if (this.endValue === date) {
        this.endValueIndex = indexMonth;
      }
      indexMonth++;

      //Legends
      countMonthLegend++;
      for (let i = 1; i < this.legendsLocal.length; i++) {
        const legend: Legend = this.legendsLocal[i];
        const legendYear = parseInt(legend.date.split('/')[0]);
        const legendMonth = parseInt(legend.date.split('/')[1]);

        if (currentYear === legendYear && currentMonth === legendMonth) {
          this.legendsLocal[i - 1].size = countMonthLegend;
          countMonthLegend = 0;

          //Set range index
          legend.startIndex = indexMonth - 1;
          this.legendsLocal[i - 1].endIndex = indexMonth - 2;
        }
      }

      //Years and Month
      currentMonth++;
      countMonth++;
      if (currentMonth > 12) {
        currentMonth = 1;

        this.years.push(this.createYear(currentYear, countMonth));
        countMonth = 0;

        currentYear++;
      }
    }

    if (currentYear <= endYear) {
      this.years.push(this.createYear(currentYear, countMonth));
    }
    this.legendsLocal[this.legendsLocal.length - 1].endIndex = indexMonth - 1;

    //Adjust size
    this.years.forEach(year => {
      year.size *= 100 / this.months.length;
    });
    this.months.forEach(month => {
      month.size = 100 / this.months.length;
    });

    //Generate Legends
    this.legendsLocal[this.legendsLocal.length - 1].size =
      this.months.length - this.legendsLocal[this.legendsLocal.length - 1].startIndex;
    this.legendsLocal.forEach(legend => {
      legend.size *= 100 / this.months.length;
    });

    //Generate Sliders
    const descriptor = Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, 'value');
    Array.from(document.querySelectorAll('input[type=range][multiple]:not(.multirange)')).forEach(
      inputElement => {
        const input: any = inputElement;
        input.max = this.months.length - 1;

        //Control input range
        const min = +(input.min || 0);
        const max = +(input.max || 100);
        const ghost = input.cloneNode();

        ghost.addEventListener('change', () => {
          this.onChangeSelection();
        });

        input.classList.add('multirange', 'original');
        ghost.classList.add('multirange', 'ghost');

        input.value = this.startValueIndex !== -1 ? this.startValueIndex : min + (max - min) / 2;
        ghost.value = this.endValueIndex !== -1 ? this.endValueIndex : min + (max - min) / 2;

        input.parentNode.insertBefore(ghost, input.nextSibling);

        Object.defineProperty(
          input,
          'originalValue',
          descriptor.get
            ? descriptor
            : {
                get: function () {
                  return this.value;
                },
                set: function (v) {
                  this.value = v;
                },
              },
        );

        Object.defineProperties(input, {
          valueLow: {
            get: function () {
              return Math.min(this.originalValue, ghost.value);
            },
            set: function (v) {
              this.originalValue = v;
            },
            enumerable: true,
          },
          valueHigh: {
            get: function () {
              return Math.max(this.originalValue, ghost.value);
            },
            set: function (v) {
              ghost.value = v;
            },
            enumerable: true,
          },
        });

        if (descriptor.get) {
          Object.defineProperty(input, 'value', {
            get: function () {
              return this.valueLow + ',' + this.valueHigh;
            },
            set: function (v) {
              const vl = v.split(',');
              this.valueLow = vl[0];
              this.valueHigh = vl[1];
              update();
            },
            enumerable: true,
          });
        }

        if (typeof input.oninput === 'function') {
          ghost.oninput = input.oninput.bind(input);
        }

        const update = () => {
          let percentageBar = (100 / (max - min)) * (input.valueLow - min);
          if (percentageBar < 0.5) {
            percentageBar = 0.5;
          }
          ghost.style.setProperty('--low', percentageBar + '%');
          percentageBar = (100 / (max - min)) * (input.valueHigh - min);
          if (percentageBar > 99.5) {
            percentageBar = 99.5;
          }
          ghost.style.setProperty('--high', percentageBar + '%');

          //Send Update Range
          if (this.startValueIndex !== input.valueLow || this.endValueIndex !== input.valueHigh) {
            this.startValueIndex = input.valueLow;
            this.endValueIndex = input.valueHigh;
          }
        };

        input.addEventListener('input', update);
        ghost.addEventListener('input', update);

        update();
      },
    );
  }

  createYear(currentYear: number, countMonth: number): Year {
    const year = new Year();
    year.name = currentYear;
    year.size = countMonth;

    return year;
  }

  createMonth(currentMonth: number): Month {
    const month = new Month();
    month.name = currentMonth;
    month.size = 0;

    return month;
  }

  onSelectRange(legend: Legend) {
    this.startValueIndex = legend.startIndex;
    this.endValueIndex = legend.endIndex;

    this.rangeChanged.emit(this.dates[legend.startIndex] + ',' + this.dates[legend.endIndex]);
  }

  onChangeSelection() {
    this.rangeChanged.emit(this.dates[this.startValueIndex] + ',' + this.dates[this.endValueIndex]);
  }
}
