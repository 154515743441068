/* eslint-disable @typescript-eslint/naming-convention */
import { mockedProperty } from '@tsq-web/property';

import { DefaultLandingPages } from '../models/default-landing-pages.enum';
import { Favorite } from '../models/favorite.model';
import { MobileNotificationSettings } from '../models/mobile-notification-settings.model';
import { Phone } from '../models/phone.model';
import { SMSNotificationSettings } from '../models/sms-notification-settings.model';
import { User } from '../models/user.model';
import { Vehicle } from '../models/vehicle.model';

export const mobileNotificationSettings: MobileNotificationSettings = {
  alertPushNotification: false,
  discussionPushNotification: false,
  pollPushNotification: false,
  assignmentPushNotification: false,
  residentDiscussionPushNotification: false,
  trusteeDiscussionPushNotification: false,
  lobbyPushNotification: false,
  packagePushNotification: false,
  digitalVotingPushNotification: false,
  offersUpdatesPushNotification: false,
  valetParkingPushNotification: false,
};

export const smsNotificationSettings: SMSNotificationSettings = {
  emergencyBroadcastNotification: false,
};

export const mockedUser: User = {
  id: 'user-id',
  email: 'mail@example.com',
  first_name: 'Mocked',
  firstName: 'Mocked',
  last_name: 'User',
  lastName: 'User',
  preferredName: 'PreferredName',
  documentNumber: 'DocumentNumber',
  billingDocument: 'BillingDocument',
  phones: [new Phone('(00)00000-0000')],
  vehicles: [new Vehicle('676h778')],
  name: 'Mocked User',
  smallPictureUrl: 'https://randomuser.me/api/portraits/lego/1.jpg',
  pictureUrl: 'https://randomuser.me/api/portraits/lego/1.jpg',
  properties: [],
  roles: [],
  mobileNotificationSettings,
  smsNotificationSettings,
  defaultLandingPage: DefaultLandingPages.Home,
} as User;

export const mockedUserWithProperty: User = {
  ...mockedUser,
  properties: [mockedProperty],
} as User;

export const mockedFavorite: Favorite = {
  id: '1',
  creationDate: 123,
  updateDate: 123,
  features: ['PetRegistry', 'ValetParking'],
  userId: '2',
};
