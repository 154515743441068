<div
  bsModal
  #modal="bs-modal"
  class="modal"
  tabindex="-1"
  [config]="{ ignoreBackdropClick: true, keyboard: false }"
  [ngClass]="'tsq-modal-' + position"
>
  <img
    src="./assets/libs/icons/close.svg"
    class="filter-primary floating-close pointer-cursor"
    alt=""
    *ngIf="position === modalPositionEnum.CENTER && !disableClose && !hideCloseImg"
    (click)="close()"
  />

  <div class="modal-dialog" [ngClass]="'tsq-modal-' + size.toLocaleLowerCase()">
    <div class="modal-content">
      <div class="modal-body">
        <ng-container *cdkPortalOutlet />
      </div>
    </div>
  </div>
</div>
