import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

@Injectable()
export class FullScreenModalService {
  private _onHidden = new Subject<boolean>();

  get onHidden$(): Observable<boolean> {
    return this._onHidden.asObservable();
  }

  hidden(): void {
    this._onHidden.next(true);
  }
}
