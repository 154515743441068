/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { IconList } from './icons';

@Component({
  selector: 'sc-icon-picker',
  templateUrl: 'sc-icon-picker.component.html',
  styleUrls: ['sc-icon-picker.component.scss'],
})
export class SCIconPickerComponent implements OnInit {
  @Output() onIconChanged = new EventEmitter<any>();

  iconList: IconList = new IconList();
  selectedIcon: any;

  ngOnInit() {}

  updateColor(colorHex: string) {
    if (!!colorHex) {
      for (const icon of this.iconList.icons) {
        icon.backgroundColor = colorHex;
        icon.color = '#FFF';
        icon.borderColor = colorHex;
      }
    } else {
      this.selectedIcon = null;
      this.resetColors();
    }
  }

  resetColors() {
    this.iconList = new IconList();
  }

  onIconSelected(icon: any) {
    this.selectedIcon = icon;
    if (!!this.selectedIcon) {
      this.updateColors(this.selectedIcon);
    } else {
      this.unselect();
    }
    this.onIconChanged.emit(this.selectedIcon);
  }

  unselect() {
    for (const icon of this.iconList.icons) {
      icon.highlighted = true;
      icon.selected = false;
    }
  }

  updateColors(color: any) {
    for (const icon of this.iconList.icons) {
      if (icon.code === this.selectedIcon.code) {
        icon.highlighted = true;
        icon.selected = true;
      } else {
        icon.highlighted = false;
        icon.selected = false;
      }
    }
  }

  resetPicker() {
    this.selectedIcon = null;
    this.resetColors();
  }

  selectIcon(icon: any) {
    const selectedIcon: any = this.iconList.icons.filter(icn => icn.code === icon)[0];
    this.onIconSelected(selectedIcon);
  }
}
