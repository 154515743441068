<sc-public-container greetingBgColor="{{ greetingBackground }}">
  <div class="full-workspace-height" left>
    <img [src]="loginImage" class="greeting-image hidden-sm" alt="" />
  </div>
  <div right>
    <div *ngIf="error" class="public-col-message">
      <h1>{{ 'REGISTER.ERROR_TITLE' | translate }}</h1>
      <p>{{ 'REGISTER.ERROR_MESSAGE' | translate }}</p>
    </div>

    <div *ngIf="!error">
      <div class="text-center">
        <div class="picture-uploader" ng2FileDrop [uploader]="uploader">
          <input
            type="file"
            class="picture-uploader-input"
            accept="image/png,image/gif,image/jpeg"
            ng2FileSelect
            [uploader]="uploader"
            (change)="onFileSelected($event)"
            title="{{ 'SHARED.UPLOAD_IMAGE' | translate }}"
            #profilePicInput
          />
          <img
            #profilePic
            width="100"
            height="100"
            src="{{ picture }}"
            alt=""
            class="media-object img-circle img-center"
          />
        </div>
      </div>

      <div class="text-center text-blue">
        <strong>{{ user.email }}</strong>
      </div>

      <p class="lines-sided-text"></p>

      <form accept-charset="UTF-8" role="form" (ngSubmit)="onSubmit()">
        <fieldset>
          <div class="form-group">
            <label for="first_name">{{ 'REGISTER.NAME' | translate }}</label>
            <input
              type="text"
              class="form-control"
              id="first_name"
              placeholder="{{ 'REGISTER.NAME' | translate }}"
              [(ngModel)]="user.first_name"
              [disabled]="user.hasAccount"
              name="first_name"
            />
          </div>
          <div class="form-group">
            <label for="last_name">{{ 'REGISTER.LAST_NAME*' | translate }}</label>
            <input
              type="text"
              class="form-control"
              id="last_name"
              placeholder="{{ 'REGISTER.LAST_NAME' | translate }}"
              required
              [(ngModel)]="user.last_name"
              [disabled]="user.hasAccount"
              name="last_name"
            />
          </div>
          <div class="form-group" *ngIf="user.requireDocument">
            <label for="billing_document">{{ 'REGISTER.DOCUMENT' | translate }}</label>
            <input
              type="text"
              class="form-control"
              id="billing_document"
              placeholder="{{ 'REGISTER.DOCUMENT_PLACEHOLDER' | translate }}"
              required
              [(ngModel)]="user.billingDocument"
              name="billing_document"
            />
          </div>
          <div class="form-group">
            <label for="password">{{ 'REGISTER.PASSWORD' | translate }}</label>
            <input
              type="password"
              class="form-control"
              id="password"
              placeholder="{{ 'REGISTER.PASSWORD' | translate }}"
              required
              [(ngModel)]="password"
              name="password"
            />
          </div>
          <div class="form-group">
            <label for="password2">{{ 'REGISTER.CONFIRM_PASSWORD' | translate }}</label>
            <input
              type="password"
              class="form-control"
              id="password2"
              placeholder="{{ 'REGISTER.CONFIRM_PASSWORD' | translate }}"
              required
              [(ngModel)]="password2"
              name="password2"
            />
          </div>
          <button class="btn btn-default" [disabled]="isDisabled()">
            {{ 'REGISTER.REGISTER' | translate }}
          </button>
        </fieldset>
      </form>
    </div>
    <div class="text-center text-blue">
      <a routerLink="/login">{{ 'REGISTER.BACK_TO_LOGIN' | translate }}</a>
    </div>
  </div>
</sc-public-container>
