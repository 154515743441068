/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'sc-config-item',
  templateUrl: 'sc-config-item.component.html',
  styleUrls: ['sc-config-item.component.scss'],
})
export class SCConfigItemComponent {
  @Input() title: string;
  @Input() active = false;
  @Output() toggleClick = new EventEmitter();

  onClick(): void {
    this.toggleClick.emit();
  }

  open(): void {
    this.active = true;
  }

  close(): void {
    this.active = false;
  }

  isOpen(): boolean {
    return this.active;
  }
}
