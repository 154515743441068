import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

import { BaseService } from '@tsq-web/core';

@Injectable()
export class FilesService extends BaseService {
  constructor(
    protected cookieService: CookieService,
    protected httpClient: HttpClient,
    protected router: Router,
  ) {
    super(cookieService, httpClient, router);
  }

  getUploadUrl(
    uploadUrl: string,
    service: string,
  ): Observable<{ key: string; uploadUrl: string; bucket: string }> {
    const options = { headers: this.headers };

    return this.httpClient.get<{ key: string; uploadUrl: string; bucket: string }>(
      BaseService.getFullUrl(uploadUrl, 'v1', service),
      options,
    );
  }
}
