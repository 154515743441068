import { Component, OnInit, inject } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { Router } from '@angular/router';

import { Store, select } from '@ngrx/store';
import { take } from 'rxjs/operators';

import { fromRouterSelectors } from '@tsq-web/router';

import { initializePendoForAnonymousVisitor } from '../../../shared/pendo/pendo.utils';
import { SourceEventsEnum } from '../../models/source-events.enum';
import { PublicAisService } from '../../services/public-ais.service';

@Component({
  selector: 'comm-public-ais',
  template: `
    <sc-public-container greetingBgColor="green">
      <div right>
        <router-outlet></router-outlet>
      </div>
    </sc-public-container>
  `,
})
export class PublicAisComponent implements OnInit {
  private readonly analytics = inject(AngularFireAnalytics);
  private readonly store = inject(Store);
  private readonly route = inject(Router);
  private readonly publicAisService = inject(PublicAisService);

  constructor() {
    if (!!this.route.getCurrentNavigation()?.extras?.state?.accountsMatch) {
      this.publicAisService.accountsMatch =
        this.route.getCurrentNavigation().extras.state.accountsMatch;

      this.publicAisService.accountNumberRequest(
        this.route.getCurrentNavigation().extras.state.accountNumberRequest,
      );
    }
  }

  ngOnInit(): void {
    this.store
      .pipe(select(fromRouterSelectors.selectRouterQueryParams), take(1))
      .subscribe(params => {
        const sourceParam = params.source;
        const source = Object.values(SourceEventsEnum).find(src => src === sourceParam);

        this.analytics.logEvent('invites_signup_form', { source });
      });

    initializePendoForAnonymousVisitor();
  }
}
