/* eslint-disable @typescript-eslint/adjacent-overload-signatures */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable @angular-eslint/no-empty-lifecycle-method */

/* eslint-disable @typescript-eslint/no-empty-function */

/* eslint-disable @angular-eslint/component-selector */

/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/explicit-function-return-type */

/* eslint-disable @typescript-eslint/naming-convention */

/* eslint-disable @angular-eslint/no-output-on-prefix */

/* eslint-disable @angular-eslint/relative-url-prefix */
import { Platform } from '@angular/cdk/platform';
import { AfterViewInit, Component, HostListener } from '@angular/core';

import { BeforeInstallPromptEvent } from '../../models/android-native-prompt.model';

@Component({
  selector: 'comm-native-app-banner-wrapper',
  templateUrl: './native-app-banner-wrapper.component.html',
})
export class NativeAppBannerWrapperComponent implements AfterViewInit {
  prompt: BeforeInstallPromptEvent;
  shouldShow = false;
  readonly starsArray = Array(5)
    .fill(0)
    .map((_, i) => i);

  constructor(private platform: Platform) {}

  ngAfterViewInit() {
    setTimeout(() => (this.shouldShow = true), 5000);
  }

  @HostListener('window:beforeinstallprompt', ['$event'])
  private onBeforeInstallPrompt(e: BeforeInstallPromptEvent) {
    e.preventDefault();
    this.prompt = e;
  }

  get isAndroid(): boolean {
    return this.platform.ANDROID;
  }

  onOpenClick() {
    window.open('https://app.townsq.io/w/id/home', '_blank');
  }

  onInstallClick() {
    this.prompt?.prompt();
    this.prompt?.userChoice.then(() => {
      this.shouldShow = false;
      this.prompt = null;
    });
  }
}
