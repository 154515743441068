<div *ngIf="navigationMoment" class="sc-calendar col-md-12 col-sm-12 col-xs-12">
  <div class="row pagination-container text-center">
    <div class="custom-pagination">
      <div class="prev-date">
        <a (click)="navigateToPreviousMonth()">
          <img
            src="./assets/img/icons/left-arrow.svg"
            title="{{ 'SHARED.PREVIOUS_MONTH' | translate }}"
            alt=""
          />
        </a>
      </div>
      <div class="date-field">
        <div class="month-and-year">{{ monthWithYear }}</div>
      </div>
      <div class="next-date">
        <a (click)="navigateToNextMonth()">
          <img
            src="./assets/img/icons/right-arrow.svg"
            title="{{ 'SHARED.NEXT_MONTH' | translate }}"
            alt=""
          />
        </a>
      </div>
    </div>
  </div>
  <div class="row week-days">
    <div class="week-day" *ngFor="let weekDay of weekDays">
      {{ weekDay }}
    </div>
  </div>
  <div class="row week" *ngFor="let week of weeks">
    <sc-calendar-day
      *ngFor="let day of week"
      class="col-md-1 col-sm-1"
      [date]="day"
      [isInNavigationMonth]="isNavigationMonth(day)"
      [available]="hasSlotsAndIsAvailable(day)"
      [timeSlots]="getTimeSlotsForWeekday(day)"
      [calendarDay]="getCalendarDay(day)"
      [currentDate]="currentDate"
      (click)="onDayClicked(day)"
    />
  </div>
  <div class="row caption">
    <div [hidden]="!captionController.hasBusyDay" class="col-md-3">
      <div class="caption-symbol">
        <i class="fa fa-circle-o busy-day-caption" aria-hidden="true"></i>
      </div>
      <span class="caption-text">{{ 'SHARED.SOME_FREE' | translate }}</span>
    </div>
    <div [hidden]="!captionController.hasFullBusyDay" class="col-md-3">
      <div class="caption-symbol">
        <i class="fa fa-circle full-busy-day-caption" aria-hidden="true"></i>
      </div>
      <span class="caption-text">{{ 'SHARED.ALL_OCCUPIED' | translate }}</span>
    </div>
    <div [hidden]="!captionController.hasWaitingQueue" class="col-md-3">
      <div class="caption-symbol">
        <i class="fa fa-circle waiting-queue" aria-hidden="true"></i>
      </div>
      <span class="caption-text">{{ 'SHARED.ON_QUEUE' | translate }}</span>
    </div>
    <div [hidden]="!captionController.hasWaitingApproval" class="col-md-3">
      <div class="caption-symbol">
        <i class="fa fa-circle waiting-approval" aria-hidden="true"></i>
      </div>
      <span class="caption-text">{{ 'SHARED.AWAITING_APPROVAL' | translate }}</span>
    </div>
    <div [hidden]="!captionController.hasCurrentUserEvent" class="col-md-3">
      <div class="caption-symbol">
        <i class="fa fa-circle current-user-event" aria-hidden="true"></i>
      </div>
      <span class="caption-text">{{ 'SHARED.MY_RESERVATION' | translate }}</span>
    </div>
    <div [hidden]="!captionController.hasPendingConfirmation" class="col-md-3">
      <div class="caption-symbol">
        <i class="fa fa-circle pending-confirmation" aria-hidden="true"></i>
      </div>
      <span class="caption-text">{{ 'SHARED.PENDING_CONFIRMATION' | translate }}</span>
    </div>
  </div>
</div>
