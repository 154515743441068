<h1>{{ 'RECOVERY.NEW_PASSWORD' | translate }}</h1>
<p class="text-md mb-0 mt-8">{{ 'RECOVERY.NEW_PASSWORD_DESC' | translate }}</p>

<form class="mt-16" [formGroup]="changePasswordForm">
  <div class="group">
    <label>{{ 'RECOVERY.PASSWORD_V2' | translate }}</label>
    <input
      tsqPeekablePassword
      schema="new-primary"
      type="password"
      class="control"
      formControlName="password"
      [errorMessage]="errorMessage$ | async | translate"
    />
  </div>
  <div class="text-coal-secondary mt-4 text-sm">
    <span class="block">{{ 'RECOVERY.PASSWORD_REQUIREMENTS.MUST_HAVE' | translate }}</span>
    <div *ngFor="let requirement of passwordRequirements$ | async" class="flex gap-4">
      <tsq-icon
        *ngIf="changePasswordForm.controls.password.touched"
        data-testid="new-password-form--tsq-icon--valid"
        [classes]="'size-16 ' + (requirement.hasError ? 'text-red' : 'text-new-primary')"
        [icon]="requirement.hasError ? icons.xmark : icons.check"
      />
      <span>{{ requirement.message | translate }}</span>
    </div>
  </div>

  <div class="group mt-16">
    <label>{{ 'RECOVERY.CONFIRM_PASSWORD_V2' | translate }}</label>
    <input
      tsqPeekablePassword
      schema="new-primary"
      type="password"
      class="control"
      formControlName="passwordConfirm"
      [errorMessage]="'RECOVERY.PASSWORD_REQUIREMENTS.DONT_MATCH' | translate"
    />
  </div>

  <div class="mt-24">
    <tsq-button
      *ngIf="!isLoading; else loader"
      type="submit"
      schema="new-primary"
      size="lg"
      [text]="'RECOVERY.SAVE_NEW_PASSWORD' | translate"
      [disabled]="changePasswordForm.invalid"
      (clicked)="changePassword.emit(changePasswordForm.controls.password.value)"
    />
    <ng-template #loader>
      <tsq-small-loader />
    </ng-template>
  </div>
  <tsq-button
    schema="new-primary"
    kind="secondary"
    size="lg"
    class="mt-16"
    [text]="'RECOVERY.BACK_TO_LOGIN' | translate"
    (clicked)="backToLogin.emit()"
  />
</form>
