/* eslint-disable @typescript-eslint/naming-convention */
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { Temporal } from '@js-temporal/polyfill';
import { TranslateService } from '@ngx-translate/core';
import IMask from 'imask';
import { BsDatepickerInputDirective, BsLocaleService } from 'ngx-bootstrap/datepicker';

import { TemporalComparison } from '@tsq-web/date-time';

import { MaskRangedBlocks } from '../../models/mask-blocks.model';

@Component({
  selector: 'tsq-datepicker',
  templateUrl: './tsq-datepicker.component.html',
  styleUrls: ['./tsq-datepicker.component.scss'],
})
export class TSqDatepickerComponent implements AfterViewInit {
  @ViewChild(BsDatepickerInputDirective, { static: true })
  datepickerDirective: BsDatepickerInputDirective;
  @ViewChild('inputElement', { static: true }) input: ElementRef<HTMLInputElement>;

  @Input() placement: 'top' | 'bottom' | 'left' | 'right' = 'bottom';

  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() invalid = false;
  @Input() touched: boolean;
  @Input() enableTyping = false;
  @Input() placeholder = 'DATE';
  @Input() tabIndex = 0;
  @Input() isNewDesign = false;
  @Input() focusOnInit = false;
  @Input() adaptivePosition = false;
  @Input() hideClearButton = false;

  @Output() dateChange = new EventEmitter<Date>(true);
  @Output() touchedChange = new EventEmitter();
  @Output() shownChange = new EventEmitter();

  _value: Date;

  private _disabled = false;
  private _hasFocus = false;
  private _isCalendarOpen = false;
  private _iMaskInstance: IMask.InputMask<IMask.AnyMaskedOptions>;
  private mask: IMask.AnyMaskedOptions;

  constructor(
    private bsLocaleService: BsLocaleService,
    private translateService: TranslateService,
    private renderer: Renderer2,
  ) {
    if (this.translateService.currentLang === 'pt') {
      this.bsLocaleService.use('pt-br');
    } else if (this.translateService.currentLang === 'es') {
      this.bsLocaleService.use('es-us');
    }
    this.mask = this.buildMask();
  }

  ngAfterViewInit(): void {
    if (this.enableTyping) {
      this._iMaskInstance = IMask(this.input.nativeElement, this.mask);
      this.updateMask();
    }

    if (this.focusOnInit) {
      this.input?.nativeElement?.focus();
    }
  }

  @Input() set date(value: Date) {
    if (!value || !this._value || value.getTime() !== this._value.getTime()) {
      this._value = value;
      if (!!this._iMaskInstance) {
        this._iMaskInstance.value = this.input.nativeElement.value;
        this.updateMask();
      }
    }
  }

  @Input() set disabled(value: boolean) {
    this._disabled = value;

    setTimeout(() => {
      if (value) {
        this.renderer.setAttribute(this.input.nativeElement, 'readonly', 'readonly');
      } else {
        this.renderer.removeAttribute(this.input.nativeElement, 'readonly');
      }
    }, 1);
  }

  get disabled(): boolean {
    return this._disabled;
  }

  set value(v: Date) {
    this.dateChange.emit(v);
  }

  get isOpen(): boolean {
    return this._isCalendarOpen;
  }

  get isInUse(): boolean {
    return this._hasFocus || this._isCalendarOpen;
  }

  open(): void {
    this.input?.nativeElement?.focus();
    this.setIsCalendarOpen(true);
  }

  close(): void {
    this.setIsCalendarOpen(false);
  }

  onClearInput(): void {
    this.dateChange.emit(null);
    if (!!this._iMaskInstance) {
      this._iMaskInstance.value = '';
      this._iMaskInstance.updateValue();
      this.updateMask();
    }
  }

  onFocus(): void {
    this.setHasFocus(true);

    if (!!this._value) {
      const value = this.input.nativeElement.value;
      this.input.nativeElement.setSelectionRange(value.length, value.length);
    } else {
      this.input.nativeElement.setSelectionRange(0, 0);
    }
  }

  onBlur(): void {
    this.setHasFocus(false);

    if (!this.touched) {
      this.touchedChange.emit();
    }
  }

  onKeyDown(): boolean {
    return this.enableTyping;
  }

  onValueChanged(value: Date): void {
    const currValue = value;
    const prevValue = this._value;
    const tz = Temporal.Now.timeZone();

    if (
      !this._value ||
      Temporal.PlainDate.compare(
        Temporal.Instant.fromEpochMilliseconds(prevValue.getTime())
          .toZonedDateTimeISO(tz)
          .startOfDay(),
        Temporal.Instant.fromEpochMilliseconds(currValue.getTime())
          .toZonedDateTimeISO(tz)
          .startOfDay(),
      ) !== TemporalComparison.Date1EqualDate2
    ) {
      setTimeout(() => {
        const errors = this.datepickerDirective.validate(new FormControl(currValue));

        if (this.enableTyping && !!errors) {
          if (!!errors.bsDate.minDate) {
            this.value = this.minDate;
          } else if (!!errors.bsDate.maxDate) {
            this.value = this.maxDate;
          } else if (!!errors.bsDate.invalid) {
            this.onClearInput();
          }
        } else {
          this.value = currValue;
        }
      }, 1);
    }
  }

  onShown(): void {
    this.open();
    this.shownChange.emit();
  }

  onHidden(): void {
    this._iMaskInstance?.updateValue();
    this.close();
  }

  private setHasFocus(value: boolean): void {
    this._hasFocus = value;
    this.updateMask();
  }

  private setIsCalendarOpen(value: boolean): void {
    this._isCalendarOpen = value;
    this.updateMask();
  }

  private buildMask(): IMask.AnyMaskedOptions {
    return {
      mask: this.maskPattern,
      lazy: false,
      blocks: this.maskBlocks,
    };
  }

  private updateMask(): void {
    if (!this._iMaskInstance) {
      return;
    }

    const hasValue = this.maskHasValue();
    const usePlaceholder = !this.isInUse && !hasValue;
    this._iMaskInstance.updateOptions({
      lazy: usePlaceholder,
    });

    this._iMaskInstance.updateValue();
    this._iMaskInstance.updateControl();
  }

  private maskHasValue(): boolean {
    return (
      !!this._iMaskInstance.unmaskedValue ||
      (!!this._iMaskInstance?.value && this._iMaskInstance?.value !== '__/__/____')
    );
  }

  private get maskPattern(): string {
    if (this.translateService.currentLang === 'pt') {
      return 'd/m/Y';
    }

    return 'm/d/Y';
  }

  private get maskBlocks(): MaskRangedBlocks {
    return {
      d: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 31,
        maxLength: 2,
      },
      m: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 12,
        maxLength: 2,
      },
      Y: {
        mask: IMask.MaskedRange,
        from: 1900,
        to: 9999,
      },
    };
  }
}
