import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ModalContext } from '../../models/modal-context';
import { ModalPositions } from '../../models/modal-positions.enum';
import { ModalSize } from '../../models/modal-size.enum';
import { ModalTypes } from '../../models/modal-types.enum';
import { TSqTemplatePortal } from '../../models/template-portal';
import { ModalService } from '../../services/modal.service';

@UntilDestroy()
@Component({
  selector: 'tsq-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent implements OnInit {
  @ViewChild('modalContent') modalRef: TemplateRef<ModalContext>;

  @Input() loading: boolean;
  @Input() iconPath: string;
  @Input() iconFilter: 'filter-primary' | 'filter-text-primary' | 'filter-delete-error';
  @Input() title: string;
  @Input() cancelText = 'LIBS.CANCEL';
  @Input() confirmEnable = true;
  @Input() confirmText = 'LIBS.CONFIRM';
  @Input() confirmStyle: 'btn-primary' | 'btn-secondary' | 'btn-danger' = 'btn-primary';
  @Input() size = ModalSize.Responsive;
  @Input() hideCancel = false;
  @Input() showCloseButton = false;

  @Output() openChange = new EventEmitter<boolean>(true);
  @Output() cancel = new EventEmitter();
  @Output() confirm = new EventEmitter();

  private modalContext: ModalContext = {
    position: ModalPositions.CENTER,
    disableClose: true,
  };

  constructor(private viewContainerRef: ViewContainerRef, private modalService: ModalService) {}

  ngOnInit(): void {
    this.modalService.open$.pipe(untilDestroyed(this)).subscribe(value => {
      if (!value) {
        this.openChange.emit(false);
      }
    });
  }

  @Input() set open(value: boolean) {
    if (value) {
      this.modalContext.size = this.size;
      this.modalService.open(ModalTypes.ConfirmationDialog);
      this.modalService.setPortal(
        new TSqTemplatePortal(this.modalRef, this.viewContainerRef, this.modalContext),
      );
    }
  }

  cancelAction(): void {
    this.modalService.close();
    this.cancel.emit();
  }

  confirmAction(): void {
    this.confirm.emit();
  }
}
