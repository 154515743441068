import { Feature } from '../models/feature.enum';

export function featureEnumToTranslationKey(feature: Feature): string {
  switch (feature) {
    case Feature.Alerts:
      return 'ALERTS.ALERTS';
    case Feature.Assignments:
      return 'ASSIGNMENTS.ASSIGNMENTS';
    case Feature.Discussion:
      return 'DISCUSSION.DISCUSSION';
    case Feature.Neighbors:
      return 'RESIDENT.RESIDENTS';
    case Feature.Poll:
      return 'POLL.POLLS';
    case Feature.TrusteeDiscussion:
      return 'REQUESTS.REQUESTS';
    case Feature.ResidentDiscussion:
      return 'RESIDENT_DISCUSSION.RESIDENT_DISCUSSIONS';
    case Feature.UserGroups:
      return 'GROUPS.GROUPS';
    case Feature.Calendars:
      return 'FACILITY.RESERVATIONS';
    case Feature.PackageDelivery:
      return 'PACKAGE_CONTROL.PACKAGE_CONTROL';
    case Feature.Lobby:
      return 'AUTHS.LOBBY';
    case Feature.ShiftLogs:
      return 'SHIFT_LOGS.SHIFT_LOGS';
    case Feature.Documents:
      return 'DOCUMENTS.DOCUMENTS';
    case Feature.AuditLogs:
      return 'AUDIT_LOGS.AUDIT_LOGS';
    case Feature.Properties:
      return 'PROPERTY.PROPERTIES';
    case Feature.DigitalVoting:
      return 'LIBS.DIGITAL_VOTING';
    case Feature.TrusteeComment:
      return 'LIBS.COMMENTS.TRUSTEE_COMMENTS';
    case Feature.AssignmentComment:
      return 'LIBS.COMMENTS.ASSIGNMENT_COMMENTS';
    case Feature.ArchitecturalReviewComment:
      return 'LIBS.COMMENTS.ARCHITECTURAL_REVIEW_COMMENTS';
    case Feature.ArchitecturalReview:
      return 'LIBS.ARCHITECTURAL_REVIEW.ARCH_REVIEW';
    case Feature.ValetParking:
      return 'VALET_PARKING.FEATURE_NAME_STAFF';
    case Feature.PetRegistry:
      return 'PET_REGISTRY.MENU_TEXT';
    case Feature.ServiceLogs:
      return 'SERVICE_LOGS.FEATURE_TITLE';
    default:
      return feature;
  }
}
