import { icons } from '@tsq-web/assets';

export function getExtensionImage(extensionName: string): string {
  switch (extensionName.toLocaleLowerCase()) {
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'gif':
      return icons.landscape;
    case 'xlsx':
    case 'xls':
    case 'doc':
    case 'docx':
    case 'txt':
    case 'pdf':
      return icons.document;
    case 'ppt':
    case 'pptx':
      return icons.rectangleWithTriangle;
    default:
      return icons.geometricShapes;
  }
}
