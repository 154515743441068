import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { Params, Router } from '@angular/router';

import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { Observable, combineLatest, concat, of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import { Condo, PaymentSettings, Settings, UpsellSettings } from '@tsq-web/condo';
import { Feature } from '@tsq-web/feature';
import { LoginData } from '@tsq-web/login';
import { Permission, hasFeatureAccessAnyCondo } from '@tsq-web/permission';
import { fromRouterSelectors } from '@tsq-web/router';
import { AppState } from '@tsq-web/state';
import { UserState } from '@tsq-web/user-context';
import * as fromUserContextActions from '@tsq-web/user-context/actions';
import * as fromUserContextSelectors from '@tsq-web/user-context/selectors';
import { User } from '@tsq-web/users';

import { CondoSettingsService } from '../condo/condo.settings.service';
import { CondoService } from '../shared/condo/condo.service';
import { getEnvironment } from '../shared/environment';
import { CommunitiesEnvironments } from '../shared/environment/models/communities-environments.enum';
import { getFeatureToggle } from '../shared/feature-toggle';
import { MessagingService } from '../shared/push/services/messaging.service';
import { UpsellSettingsService } from '../shared/upsell-settings/upsell.settings.service';
import * as fromAppActions from './app.actions';

@Injectable()
export class AppEffects {
  loggedIn$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromUserContextActions.loggedIn),
        concatLatestFrom(() => [this.store.select(fromRouterSelectors.selectRouterQueryParams)]),
        map(([{ loginData }, params]) => {
          if (
            getFeatureToggle().termsOfUse.login &&
            getEnvironment().env !== CommunitiesEnvironments.BR_PROD
          ) {
            this.store.dispatch(fromUserContextActions.getUserTermsAcceptance());
          } else {
            this.handleLoginRedirection(loginData, params);
          }
        }),
      ),
    { dispatch: false },
  );

  acceptedTerms$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          fromUserContextActions.getUserTermsAcceptanceSuccess,
          fromUserContextActions.acceptTermsSuccess,
          fromUserContextActions.getUserTermsAcceptanceFailed,
        ),
        concatLatestFrom(() => [
          this.store.select(fromUserContextSelectors.selectUserState),
          this.store.select(fromRouterSelectors.selectRouterQueryParams),
        ]),
        map(([, userState, params]) => this.handleLoginRedirection(userState, params)),
      ),
    { dispatch: false },
  );

  clearUserContext$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromUserContextActions.clearUserContext),
        map(() => {
          this.messagingService.unregister();

          const langCookie = this.cookieService.get('sc-lang');
          this.townSqCookies.forEach(cookieName => {
            this.cookieService.delete(cookieName, '/');
          });
          this.cookieService.set('sc-lang', langCookie, null, '/');

          localStorage.setItem('refresh', 'true');

          this.router.navigate(['login']);
        }),
      ),
    { dispatch: false },
  );

  goToCondo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAppActions.goToCondo),
      withLatestFrom(this.store.pipe(select(fromUserContextSelectors.selectUserCondos))),
      mergeMap(([{ condo }, condos]: [{ condo: string | Condo }, Condo[]]) =>
        combineLatest([
          this.condoService.goToCondo(typeof condo === 'string' ? condo : condo.id),
          of(condo),
          of(condos),
        ]),
      ),
      catchError((err, caught$) => {
        this.router.navigateByUrl('');

        return caught$;
      }),
      mergeMap(([user, condo, condos]: [User, Condo, Condo[]]) => {
        if (typeof condo === 'string') {
          const condoFound = condos.find(c => c.id === condo);
          if (!!condoFound) {
            return of([user, condoFound]);
          }

          return this.condoService.getCondo(condo).pipe(map(c => [user, c]));
        }

        return of([user, condo]);
      }),
      catchError((err, caught$) => {
        this.router.navigateByUrl('');

        return caught$;
      }),
      switchMap(([user, condo]: [User, Condo]) => [
        fromUserContextActions.setCondo({ condo }),
        fromUserContextActions.updateUser({ user }),
      ]),
    ),
  );

  setCondoById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserContextActions.setCondoById),
      withLatestFrom(this.store.select(fromUserContextSelectors.selectUserCondos)),
      mergeMap(([{ condo, suppressEvent }, condos]) => {
        const foundCondo = condos.find(c => c.id === condo);
        const condoResult = !!foundCondo ? of(foundCondo) : this.condoService.getCondo(condo);

        return combineLatest([condoResult, of(suppressEvent)]);
      }),
      catchError((err, caught$) => {
        this.router.navigate(['']);

        return caught$;
      }),
      concatMap(([condo, suppressEvent]) =>
        concat(
          of(fromUserContextActions.setCondo({ condo, suppressEvent })),
          this.condoService.goToCondo(condo.id).pipe(
            map(user => fromUserContextActions.updateUser({ user })),
            catchError(() => of(fromUserContextActions.contextLoginFailed())),
          ),
        ),
      ),
    ),
  );

  setCondo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromUserContextActions.setCondo),
        map(({ condo, suppressEvent }) => {
          const token = JSON.parse(this.cookieService.get('logonToken'));
          token.currentCondoId = condo.id;
          this.cookieService.set('logonToken', JSON.stringify(token), null, '/');

          if (!suppressEvent) {
            this.router.navigate(['w', condo.id, 'home']);
          }
        }),
      ),
    { dispatch: false },
  );

  updateCondoSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAppActions.updateCondoSettings),
      withLatestFrom(this.store.pipe(select(fromUserContextSelectors.selectCondo))),
      map(([{ settings }, condo]: [{ settings: Settings }, Condo]) => {
        const newCondo = { ...condo, settings };

        return fromUserContextActions.setCondo({ condo: newCondo, suppressEvent: true });
      }),
    ),
  );

  setCondoPaymentSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAppActions.setCondoPaymentSettings),
      withLatestFrom(this.store.pipe(select(fromUserContextSelectors.selectCondo))),
      map(([{ paymentSettings }, condo]: [{ paymentSettings: PaymentSettings }, Condo]) => {
        const newCondo = { ...condo, paymentSettings };

        return fromUserContextActions.setCondo({ condo: newCondo, suppressEvent: true });
      }),
    ),
  );

  updateCondoPaymentSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserContextActions.updateCondoPaymentSettings),
      mergeMap(({ paymentSettings }) =>
        this.condoSettingService.updateCondoPaymentSettings(paymentSettings).pipe(
          switchMap(response => {
            this.toast.success(
              this.translateService.instant('COMMUNITY_SETTINGS.PAYMENT_SETTINGS_SUCCESS'),
            );

            const warning = response.headers.get('Warning');
            if (!!warning) {
              this.toast.warning(warning);
            }

            return [
              fromUserContextActions.updateCondoPaymentSettingsSuccess(),
              fromAppActions.setCondoPaymentSettings({
                paymentSettings: response.body as PaymentSettings,
              }),
            ];
          }),
          catchError(err =>
            this.catchUpdateSettingsError(
              err,
              'COMMUNITY_SETTINGS.PAYMENT_SETTINGS_ERROR',
              fromUserContextActions.updateCondoPaymentSettingsFailed(),
            ),
          ),
        ),
      ),
    ),
  );

  updateCondoIntegrationSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserContextActions.updateCondoIntegrationSettings),
      mergeMap(({ integrationSettings }) =>
        this.condoSettingService.updateCondoIntegrationSettings(integrationSettings).pipe(
          switchMap(settings => {
            this.toast.success(
              this.translateService.instant('COMMUNITY_SETTINGS.INTEGRATION.UPDATE_SUCCESS'),
            );

            return [
              fromUserContextActions.updateCondoIntegrationSettingsSuccess(),
              fromAppActions.setCondoIntegrationSettings({ settings }),
            ];
          }),
          catchError(err =>
            this.catchUpdateSettingsError(
              err,
              'COMMUNITY_SETTINGS.INTEGRATION.UPDATE_ERROR',
              fromUserContextActions.updateCondoIntegrationSettingsFailed(),
            ),
          ),
        ),
      ),
    ),
  );

  patchUpsellSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserContextActions.patchUpsellSettings),
      withLatestFrom(this.store.pipe(select(fromUserContextSelectors.selectCondo))),
      mergeMap(([{ upsellSettings }, condo]: [{ upsellSettings: UpsellSettings }, Condo]) => {
        return this.upsellSettingsService.patchUpsellSettings(upsellSettings).pipe(
          map(() => {
            const newCondo = { ...condo, upsellSettings };
            fromUserContextActions.patchUpsellSettingsSuccess();

            return fromUserContextActions.setCondo({ condo: newCondo, suppressEvent: true });
          }),
          catchError(() => of(fromUserContextActions.patchUpsellSettingsFailed())),
        );
      }),
    ),
  );

  private readonly conflict = 409;
  private readonly badRequest = 400;
  private readonly townSqCookies = ['sc-lang', 'jwt', 'logonToken'];

  constructor(
    private actions$: Actions,
    private router: Router,
    private cookieService: CookieService,
    private store: Store<AppState>,
    private messagingService: MessagingService,
    private condoService: CondoService,
    private upsellSettingsService: UpsellSettingsService,
    private condoSettingService: CondoSettingsService,
    private analyticsService: AngularFireAnalytics,
    private translateService: TranslateService,
    private toast: ToastrService,
  ) {}

  private catchUpdateSettingsError(
    err: { status: number; error: { message: string } },
    messageKey: string,
    action: TypedAction<string>,
  ): Observable<TypedAction<string>> {
    err.status === this.conflict || this.badRequest
      ? this.toast.error(err?.error?.message)
      : this.toast.error(this.translateService.instant(messageKey));

    return of(action);
  }

  private handleLoginRedirection(userState: UserState | LoginData, params: Params): void {
    const redirectLink = localStorage.getItem('redirectLink');
    this.analyticsService.setUserId(userState.user.id);
    this.analyticsService.logEvent('logged_in');
    if (!!params.redirect) {
      this.router.navigateByUrl(params.redirect);
    } else if (!!redirectLink) {
      window.location.href = redirectLink;
    } else if (!!params.return) {
      window.open(params.return, '_self');
    } else if (hasFeatureAccessAnyCondo(userState.user, Feature.Dashboard, Permission.ADMIN)) {
      this.router.navigate(['d', 'brief']);
    } else if (!!userState.condos && userState.condos.length === 1) {
      this.store.dispatch(fromAppActions.goToCondo({ condo: userState.condos[0] }));
    } else {
      this.router.navigate(['select-community']);
    }
  }
}
