import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';

import { Observable, of } from 'rxjs';

import { PublicAisService } from './public-ais.service';

export const aisMultipleAccountsGuard: CanActivateFn = (): Observable<boolean | UrlTree> => {
  const router = inject(Router);
  const publicAisService = inject(PublicAisService);

  if (
    (!!publicAisService.aisAccRetrieval &&
      !!publicAisService.accountsMatch &&
      publicAisService.accountsMatch.length > 0) ||
    router.getCurrentNavigation().extras?.state?.accountsMatch?.length > 0
  ) {
    return of(true);
  }

  return of(router.createUrlTree(['ais', 'sign-up']));
};
