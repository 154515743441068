import { Routes } from '@angular/router';

import { FeatureGuard } from '@tsq-web/feature';

import { getFeatureToggle } from '../shared/feature-toggle';
import { LoginV2Component } from './containers/login-v2/login-v2.component';
import { LoginComponent } from './containers/login/login.component';
import { SingleSignOnCallbackComponent } from './containers/sso/callback.component';
import { SingleSignOnComponent } from './containers/sso/sso.component';
import { byPassLoginGuard } from './guards/bypass-login.guard';
import { bypassOnRootGuard } from './guards/bypass-on-root.guard';

export const loginRoutes: Routes = [
  {
    path: '',
    component: getFeatureToggle().newLogin ? LoginV2Component : LoginComponent,
    canActivate: [bypassOnRootGuard],
  },
  {
    path: 'login',
    component: getFeatureToggle().newLogin ? LoginV2Component : LoginComponent,
    canActivate: [byPassLoginGuard],
  },
  {
    path: 'sso',
    component: SingleSignOnComponent,
    canActivate: [byPassLoginGuard, FeatureGuard],
    data: {
      featureToggle: getFeatureToggle().login.sso.enabled,
    },
  },
  {
    path: 'auth/callback',
    component: SingleSignOnCallbackComponent,
    canActivate: [byPassLoginGuard, FeatureGuard],
    data: {
      featureToggle: getFeatureToggle().login.sso.enabled,
    },
  },
];
