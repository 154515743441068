import { createAction, props } from '@ngrx/store';

import { CondoAndSubscription } from '../../condo-and-subscription.json';

export const loadCondoAndSubscription = createAction(
  '[Subscription] Load Condo and Subscription',
  props<{ condoId: string }>()
);

export const loadCondoAndSubscriptionSuccess = createAction(
  '[TownSq API] Load Condo and Subscription Success',
  props<{ condoAndSubscription: CondoAndSubscription }>()
);

export const loadCondoAndSubscriptionFailure = createAction(
  '[TownSq API] Load Condo and Subscription Failure'
);
