import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';

import { Condo } from '@tsq-web/condo';
import { HttpService } from '@tsq-web/core';
import { AppState } from '@tsq-web/state';
import { fromUserContextSelectors } from '@tsq-web/user-context';
import { User } from '@tsq-web/users';

import { PropertyAndPhone, ResidentInvite } from '../models/resident-invite.model';

@UntilDestroy()
@Injectable()
export class ResidentsInviteService extends HttpService {
  private condoId: string;
  private userId: string;

  private readonly http = inject(HttpClient);
  private readonly store = inject(Store<AppState>);

  constructor() {
    super();

    combineLatest([
      this.store.select(fromUserContextSelectors.selectCondo),
      this.store.select(fromUserContextSelectors.selectUser),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([condo, user]: [Condo, User]) => {
        this.condoId = condo?.id;
        this.userId = user?.id;
      });
  }

  getNeighborInvites(): Observable<ResidentInvite[]> {
    return this.http.get<ResidentInvite[]>(this.getFullUrl('invite-neighbors'));
  }

  postNeighborInvite(invite: ResidentInvite): Observable<ResidentInvite> {
    return this.http.post<ResidentInvite>(this.getFullUrl('invite-neighbors'), invite);
  }

  adminApprove(invite: ResidentInvite): Observable<ResidentInvite> {
    const body = { inviteId: invite.id, property: invite.invitee.properties[0] };
    return this.http.patch<ResidentInvite>(this.getFullUrl('neighbor-invite/approve', 'v1'), body);
  }

  adminDecline(invite: ResidentInvite): Observable<ResidentInvite> {
    return this.http.put<ResidentInvite>(
      this.getFullUrl(`invite-neighbors/admin-decline/${invite.id}`),
      invite,
    );
  }

  sendAgain(invite: ResidentInvite): Observable<ResidentInvite> {
    return this.http.put<ResidentInvite>(
      this.getFullUrl(`invite-neighbors/send-again/${invite.id}`),
      {},
    );
  }

  acceptNeighborInvite(invite: ResidentInvite): Observable<ResidentInvite> {
    return this.http.put<ResidentInvite>(
      this.getFullUrl(`public/invite-neighbors/invite-accept/${invite.id}`),
      invite,
    );
  }

  getInviteById(id: string): Observable<ResidentInvite> {
    return this.http.get<ResidentInvite>(this.getFullUrl(`public/invite-neighbors/${id}`));
  }

  deleteInvite(id: string): Observable<ResidentInvite> {
    return this.http.delete<ResidentInvite>(this.getFullUrl(`neighbor-invite/delete/${id}`, 'v1'));
  }

  updateInvite(invite: ResidentInvite): Observable<ResidentInvite> {
    const propertyAndPhone: PropertyAndPhone = {
      property: {
        id: invite.invitee.properties[0].id,
        propertyNumber: invite.invitee.properties[0].property_number,
        groupNumber: invite.invitee.properties[0].group_number,
      },
      phones: invite.invitee.phones,
    };

    return this.http.patch<ResidentInvite>(
      this.getFullUrl(`neighbor-invite/${invite.id}/invitee/property-and-phones`, 'v1'),
      propertyAndPhone,
    );
  }

  getMyInvites(): Observable<ResidentInvite[]> {
    const params = new HttpParams({ fromObject: { condoId: this.condoId } });
    return this.http.get<ResidentInvite[]>(
      this.getFullUrl(`invite-neighbors/${this.userId}/my-invites`),
      this.getJsonOptions({ params }),
    );
  }
}
