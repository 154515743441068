/* eslint-disable @typescript-eslint/naming-convention */
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import {
  BsDatepickerConfig,
  BsDatepickerViewMode,
  BsLocaleService,
} from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'tsq-monthpicker',
  templateUrl: './tsq-monthpicker.component.html',
  styleUrls: ['./tsq-monthpicker.component.scss'],
})
export class TSqMonthpickerComponent {
  @Input() placement: 'top' | 'bottom' | 'left' | 'right' = 'bottom';

  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() disabled = false;

  @Output() dateChange = new EventEmitter<Date>();

  _value: Date;

  minMode: BsDatepickerViewMode = 'month'; // change for month:year

  bsConfig: Partial<BsDatepickerConfig>;

  private _isOpen = false;

  constructor(
    private bsLocaleService: BsLocaleService,
    private translateService: TranslateService,
  ) {
    if (this.translateService.currentLang === 'pt') {
      this.bsLocaleService.use('pt-br');
    }
    this.bsConfig = {
      minMode: this.minMode,
      dateInputFormat: 'MMMM YYYY',
    };
  }

  @Input() set date(value: Date) {
    if (!value || !this._value || value.getTime() !== this._value.getTime()) {
      this._value = value;
    }
  }

  set value(v: Date) {
    this.dateChange.emit(v);
  }

  get isOpen(): boolean {
    return this._isOpen;
  }

  open(): void {
    this._isOpen = true;
  }

  close(): void {
    this._isOpen = false;
  }

  onClearInput(): void {
    this.dateChange.emit(null);
  }

  onOpenCalendar(container): void {
    container.setViewMode('month');
  }
}
