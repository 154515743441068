import { Injectable } from '@angular/core';

import * as crypto from 'crypto-js';

@Injectable()
export class PasswordUtils {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  SHA1(toDigest: string): string {
    return crypto.SHA1(toDigest).toString();
  }
}
