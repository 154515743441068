import { Component, OnInit, inject } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { ActivatedRoute, Params, RouteConfigLoadStart, Router, RouterEvent } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, take } from 'rxjs';

import { clearQueryParams, fromRouterSelectors } from '@tsq-web/router';
import { AppState } from '@tsq-web/state';
import { fromUserContextActions, fromUserContextSelectors } from '@tsq-web/user-context';
import { User } from '@tsq-web/users';

import { getEnvironment } from '../../../shared/environment';
import { CommunitiesEnvironments } from '../../../shared/environment/models/communities-environments.enum';
import { getFeatureToggle } from '../../../shared/feature-toggle';
import { getCurrentLogoPath } from '../../../shared/util/environment.utils';
import { LoginInformation } from '../../models/login-information.model';
import { Steps } from '../../models/steps.enum';
import { sha1 } from '../../utils/login.utils';

@UntilDestroy()
@Component({
  selector: 'comm-login-v2',
  templateUrl: './login-v2.component.html',
  styleUrls: ['./login-v2.component.scss'],
})
export class LoginV2Component implements OnInit {
  showAlreadyRegisteredBanner: boolean;
  showLinkExpiredBanner: boolean;
  loading: boolean;
  routing: boolean;
  email: string;

  isLoginIn$: Observable<boolean>;
  currentStep$: BehaviorSubject<Steps> = new BehaviorSubject<Steps>(undefined);

  readonly steps = Steps;
  readonly loginSimplificationEnabled = getFeatureToggle().loginSimplification;
  readonly ssoEnabled = getFeatureToggle().login.sso.enabled;
  protected readonly logoPath = getCurrentLogoPath();

  private readonly router = inject(Router);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly store = inject(Store<AppState>);
  private readonly analytics = inject(AngularFireAnalytics);
  private readonly cookieService = inject(CookieService);

  ngOnInit(): void {
    this.isLoginIn$ = this.store.select(fromUserContextSelectors.selectUserLoggingIn);

    const currentStep =
      getEnvironment().env === CommunitiesEnvironments.BR_PROD
        ? Steps.LoginCard
        : Steps.CommunityCard;
    this.currentStep$.next(currentStep);

    this.router.events.pipe(untilDestroyed(this)).subscribe((event: RouterEvent) => {
      if (event instanceof RouteConfigLoadStart) {
        this.routing = true;
      }
    });

    this.store
      .select(fromRouterSelectors.selectRouterQueryParams)
      .pipe(take(1), untilDestroyed(this))
      .subscribe(params => this.breakDownParams(params));

    this.currentStep$.pipe(untilDestroyed(this)).subscribe(step => {
      if (step === Steps.CommunityCard) {
        this.analytics.logEvent('self_sign_up_page_view', {
          pageName: 'step_one_choose_sign_up_option',
        });
      }
    });
  }

  handleStep(): void {
    if (getEnvironment().env === CommunitiesEnvironments.BR_PROD) {
      window.location.href = 'https://townsq.com.br/';
    } else {
      clearQueryParams(this.router, this.activatedRoute);
      this.email = undefined;
      this.showAlreadyRegisteredBanner = false;
      const nextStep =
        this.currentStep$.value === Steps.CommunityCard ? Steps.LoginCard : Steps.CommunityCard;
      this.currentStep$.next(nextStep);

      if (nextStep === Steps.LoginCard) {
        this.analytics.logEvent('self_sign_up_click', {
          linkName: 'log_in',
        });
      }
    }
  }

  goToLoginWithSso(): void {
    this.router.navigate(['/sso']);
  }

  goToSignUp(): void {
    getFeatureToggle().registration.signUp
      ? this.router.navigate(['registration', 'account-number'])
      : this.router.navigate(['/ais/sign-up']);
    this.analytics.logEvent('self_sign_up_click', {
      linkName: 'sign_up_account',
    });
  }

  goToSignUpWithEmail(): void {
    this.router.navigate(['registration', 'email']);
    this.analytics.logEvent('self_sign_up_click', {
      linkName: 'sign_up_email',
    });
  }

  doLogin(loginInformation: LoginInformation): void {
    const user: User = {
      ...new User(),
      platform: 'webapp',
      email: loginInformation.username.trim(),
      password: this.isActiveDirectory(loginInformation.username)
        ? loginInformation.password
        : sha1(loginInformation.password),
      ['login_type']: this.isActiveDirectory(loginInformation.username) ? 'AD' : 'CMN',
    };

    this.store.dispatch(fromUserContextActions.loginV2({ user }));
  }

  doLoginWithSso(): void {
    this.router.navigate(['/sso']);
  }

  closeBanner(): void {
    this.showAlreadyRegisteredBanner = false;
  }

  closeLinkExpiredBanner(): void {
    this.showLinkExpiredBanner = false;
  }

  sendNeedHelpInfoClick(): void {
    this.analytics.logEvent('self_sign_up_click', {
      linkName: 'need_help',
    });
  }

  private isActiveDirectory(username: string): boolean {
    return new RegExp(/(na|dev|associa)\\.{3,}$/i).test(username);
  }

  private breakDownParams(params: Params): void {
    if (!!params?.redirect) {
      localStorage.setItem('redirectLink', window.location.href);
      if (!!this.cookieService.get('jwt')) {
        this.loading = true;
      }
    } else {
      localStorage.removeItem('redirectLink');
    }
    if (params.alreadyRegistered === 'true') {
      this.currentStep$.next(Steps.LoginCard);
      this.showAlreadyRegisteredBanner = true;
      if (!!params.email) {
        this.email = params.email;
      }
    }
    if (params.goDirectlyToLogin === 'true') {
      this.currentStep$.next(Steps.LoginCard);
    }
    this.showLinkExpiredBanner = params.linkExpired === 'true';
  }
}
