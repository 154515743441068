<div
  tabindex="0"
  class="border-1 flex w-fit cursor-pointer items-center justify-center gap-8 rounded-full p-8"
  [class.active]="kind === 'active'"
  [class.error]="kind === 'error'"
  (click)="clicked.emit()"
  (keyup.enter)="clicked.emit()"
  (keyup.space)="clicked.emit()"
>
  <span class="base-text-bold">{{ text }}</span>

  <tsq-icon [icon]="icons.xmarkCircleFill"></tsq-icon>
</div>
