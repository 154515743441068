<div class="tsq-comment" *ngIf="!!comment">
  <img [src]="userPicture" class="img-circle" alt="" />
  <div class="comment-details">
    <div class="comment-details-header">
      <div>
        <h3>{{ userFullName }}</h3>
        <label
          data-cy="comments--label--edited-label"
          class="label-text mb-4 font-base text-xs uppercase text-coal-tertiary"
          *ngIf="comment.edited && !comment.isDeleted"
          >{{ 'LIBS.COMMENTS.EDITED' | translate }}</label
        >
      </div>
      <label class="text-label mb-4">{{ time }}</label>
    </div>

    <div class="comment-details-text" *ngIf="!!comment.text" [class.generated]="comment.generated">
      <img
        *ngIf="!!comment.statusIcon && comment.generated"
        class="filter-text-primary"
        [src]="comment.statusIcon"
        alt=""
      />

      <div class="break-line flex justify-between">
        <p *ngIf="!clickableUrl" data-cy="tsq-comment--p--comment-text">
          {{ comment.text }}
        </p>
        <p
          *ngIf="clickableUrl"
          [innerHTML]="comment.text | linky"
          data-cy="tsq-comment--p--comment-text--linky"
        ></p>

        <tsq-edit-comment-dropdown
          *ngIf="shouldShowCommentDropdown || showCommentDropdownForAdmin"
          class="pl-8"
          [deletable]="deletable"
          [editable]="editable"
          [shouldShowDeleteOnly]="shouldShowDeleteOnly"
          (editMessage)="editComment.emit(comment)"
          (deleteMessage)="deleteComment.emit(comment)"
        ></tsq-edit-comment-dropdown>
      </div>
    </div>

    <tsq-attachment-gallery
      *ngIf="!!comment.attachments.length && !comment.isDeleted"
      [attachments]="comment.attachments"
    ></tsq-attachment-gallery>

    <div class="comment-tag" *ngFor="let tag of comment.tags" [ngClass]="tag.class">
      <img *ngIf="!!tag.icon" [src]="tag.icon" alt="" />
      <label class="text-label">{{ tag.text | translate }}</label>
    </div>
  </div>
</div>
