import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'trial-selection',
  templateUrl: './trial-selection.component.html',
  styleUrls: ['./trial-selection.component.scss'],
})
export class TrialSelectionComponent implements OnInit {
  @Output() trialSelectionChanged = new EventEmitter<unknown>();

  form: UntypedFormGroup;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      trial: new UntypedFormControl({ value: false, disabled: false }, Validators.nullValidator),
      trialDuration: new UntypedFormControl(
        { value: '', disabled: true },
        Validators.nullValidator,
      ),
    });

    this.form.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((formJson: Record<string, unknown>) => {
        if (formJson.trial && this.form.controls.trialDuration.disabled) {
          this.form.controls.trialDuration.enable();
        } else if (!formJson.trial && this.form.controls.trialDuration.enabled) {
          this.form.controls.trialDuration.disable();
        }
        this.trialSelectionChanged.emit(formJson);
      });
  }

  initTrialSelection(trial: boolean, trialDuration: number): void {
    this.form.controls.trial.setValue(trial);
    this.cdRef.detectChanges();
    if (trial && !!trialDuration) {
      this.form.controls.trialDuration.setValue(trialDuration);
    }
  }
}
