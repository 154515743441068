/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, Input, ViewChild, Output, EventEmitter, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'sc-confirmation-dialog',
  templateUrl: 'sc-confirmation-dialog.component.html',
  styleUrls: ['sc-confirmation-dialog.component.scss'],
})
export class SCConfirmationDialogComponent implements OnInit {
  @ViewChild('modalControl', { static: true }) modalControl: ModalDirective;

  @Input() title = '';
  @Input() message = '';
  @Input() buttonLabel = this._translateService.instant('YES');
  @Input() leftButtonLabel = this._translateService.instant('BACK');
  @Output() confirmedActionOnEntity = new EventEmitter();
  @Output() confirmedAction = new EventEmitter();
  @Output() leftButtonActionOnEntity = new EventEmitter();
  @Output() leftButtonAction = new EventEmitter();
  // eslint-disable-next-line @typescript-eslint/ban-types
  selectedAction: Function;
  entity: any;

  constructor(private _translateService: TranslateService) {}

  ngOnInit(): void {
    this.modalControl.onHidden.subscribe(() => {
      this.onHidden();
    });
  }

  confirmActionOnEntity(entity: any) {
    this.entity = entity;
    this.modalControl.show();
  }

  confirmAction() {
    this.modalControl.show();
  }

  isShown() {
    return this.modalControl === undefined ? false : this.modalControl.isShown;
  }

  onButtonClick(event: any) {
    if (this.entity !== undefined && this.entity !== null) {
      this.selectedAction = this.emitConfirmationOnEntity;
    } else {
      this.selectedAction = () => this.confirmedAction.emit(event);
    }
    this.modalControl.hide();
  }

  onLeftButtonClick(event: any) {
    if (this.entity !== undefined && this.entity !== null) {
      this.selectedAction = this.emitLeftButtonOnEntity;
    } else {
      this.selectedAction = () => this.leftButtonAction.emit(event);
    }
    this.modalControl.hide();
  }

  onHidden(): void {
    if (!this.selectedAction) {
      this.selectedAction = () => this.leftButtonAction.emit();
    }
    this.selectedAction();
  }

  emitConfirmationOnEntity() {
    this.confirmedActionOnEntity.emit(this.entity);
    this.entity = undefined;
  }

  emitLeftButtonOnEntity() {
    this.leftButtonActionOnEntity.emit(this.entity);
    this.entity = undefined;
  }
}
