import { Component, Input, EventEmitter, Output } from '@angular/core';

import { Color } from '../tsq-color-picker/tsq-color-picker.component';

@Component({
  selector: 'tsq-color-picker-item',
  templateUrl: './tsq-color-picker-item.component.html',
  styleUrls: ['./tsq-color-picker-item.component.scss'],
})
export class TSqColorPickerItemComponent {
  @Input() color: Color;
  @Output() colorChange = new EventEmitter<Color>();

  selectColor(): void {
    this.colorChange.emit(this.color.selected ? undefined : this.color);
  }
}
