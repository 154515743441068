import { Component } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { AssetsModule, icons } from '@tsq-web/assets';

@Component({
  selector: 'comm-townsq-article',
  templateUrl: './townsq-article.component.html',
  styleUrls: ['./townsq-article.component.scss'],
  standalone: true,
  imports: [TranslateModule, AssetsModule],
})
export class TownSqArticleComponent {
  readonly icons = icons;
}
