export interface StoreEntity<T> {
  entity: T;
  loading: boolean;
  error: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const storeEntityInitialState: StoreEntity<any> = {
  entity: undefined,
  loading: false,
  error: false,
};
