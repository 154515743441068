<div class="tsq-card">
  <div class="feature-details-info" *ngIf="!!featureDetails">
    <label class="text-label">{{ featureDetails.creationDate }}</label>
    <h1>{{ featureDetails.title }}</h1>

    <ng-container *ngIf="!!detailsTemplate">
      <ng-container *ngTemplateOutlet="detailsTemplate"></ng-container>
    </ng-container>

    <ng-container *ngIf="!!featureDetails.description && !detailsTemplate">
      <p data-cy="tsq-feature-details--p--feature-details-description" *ngIf="!clickableUrl">
        {{ featureDetails.description }}
      </p>
      <p
        data-cy="tsq-feature-details--p--feature-details-description-linky"
        *ngIf="clickableUrl"
        [innerHtml]="featureDetails.description | linky"
      ></p>
    </ng-container>

    <div class="mt-8" *ngIf="featureDetails.attachments?.length && !detailsTemplate">
      <h4>{{ 'LIBS.ATTACHMENTS' | translate }}</h4>
      <tsq-attachment-gallery [attachments]="featureDetails.attachments" />
    </div>
  </div>

  <div *ngIf="!featureDetails">
    <ng-container *ngIf="!!detailsTemplate">
      <ng-container *ngTemplateOutlet="detailsTemplate"></ng-container>
    </ng-container>
  </div>

  <div class="feature-details-comments" *ngIf="showCommentList">
    <ng-content></ng-content>
  </div>
</div>
