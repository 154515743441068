import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from 'rxjs';
import { catchError, pluck } from 'rxjs/operators';

import { Administrator } from '@tsq-web/administrator';
import { Condo } from '@tsq-web/condo';
import { BaseService } from '@tsq-web/core';

import { EntityDeletionDetails } from '../../../shared/deactivate-modal/models/entity-deletion-details.models';
import { CreateOrganizationProps } from '../models/create-organization-props.model';
import { EditOrganizationProps } from '../models/edit-organization-props.model';
import { OrganizationReactivate } from '../models/organization-reactivate.model';
import { OrganizationSorting } from '../models/organization-sorting.enum';
import { OrganizationUser } from '../models/organization-user.model';
import { Organization } from '../models/organization.model';

@Injectable()
export class OrganizationsService extends BaseService {
  constructor(
    protected cookieService: CookieService,
    protected httpClient: HttpClient,
    protected router: Router,
  ) {
    super(cookieService, httpClient, router);
  }

  getOrganization(organizationId: string): Observable<Organization> {
    let params = new HttpParams();
    params = params.append('includeDeleted', true);
    const options = { headers: this.headers, params: params };

    return this.httpClient.get<Organization>(
      BaseService.getFullUrl(`organizations/${organizationId}`, 'v1', 'business'),
      options,
    );
  }

  saveOrganization(createOrganizationProps: CreateOrganizationProps): Observable<Organization> {
    const options = { headers: this.headers };

    return this.httpClient.post<Organization>(
      BaseService.getFullUrl('organizations', 'v1', 'business'),
      createOrganizationProps,
      options,
    );
  }

  updateOrganization(
    administrator: Administrator,
    editOrganizationProps: EditOrganizationProps,
  ): Observable<Organization> {
    const options = { headers: this.headers };

    return this.httpClient.put<Organization>(
      BaseService.getFullUrl('organizations', 'v1', 'business'),
      {
        administratorId: administrator?.id,
        ...editOrganizationProps,
      },
      options,
    );
  }

  deleteOrganization(
    organizationId: string,
    deactivationReason: EntityDeletionDetails,
  ): Observable<boolean> {
    const options = { headers: this.headers, body: deactivationReason };

    return this.httpClient.delete<boolean>(
      BaseService.getFullUrl(`organizations/${organizationId}`, 'v1', 'business'),
      options,
    );
  }

  getCondos(search: string): Observable<Condo[]> {
    let params = new HttpParams();

    if (!!search) {
      params = params.append('search', search);
    }

    params = params.append('page', '0');
    params = params.append('size', '8');

    const options = { headers: this.headers, params: params };

    return this.httpClient
      .get<{ condos: Condo[] }>(BaseService.getFullUrl('condos', 'v1'), options)
      .pipe(pluck('condos'));
  }

  getOrganizations(
    search: string,
    sort: OrganizationSorting,
    order: string,
    page: number,
  ): Observable<Organization[]> {
    let params = new HttpParams();
    params = params.append('page', String(page));

    if (!!search) {
      params = params.append('search', search);
    }

    if (!!sort) {
      params = params.append('sort', sort);
    }

    if (!!order) {
      params = params.append('order', order);
    }

    params = params.append('includeDeleted', true);

    const options = { headers: this.headers, params: params };

    return this.httpClient
      .get<{ content: Organization[] }>(
        BaseService.getFullUrl(`organizations`, 'v2', 'business-view'),
        options,
      )
      .pipe(pluck('content'));
  }

  getOrganizationCommunities(organizationId: string, page: number): Observable<Condo[]> {
    let params = new HttpParams();

    params = params.append('page', String(page));
    params = params.append('size', '15');
    params = params.append('sort', 'name');

    const options = { headers: this.headers, params: params };

    return this.httpClient
      .get<{ content: Condo[] }>(
        BaseService.getFullUrl(
          `organizations/${organizationId}/communities`,
          'v2',
          'business-view',
        ),
        options,
      )
      .pipe(pluck('content'));
  }

  addOrganizationCommunity(organizationId: string, communityId: string): Observable<Condo> {
    const options = { headers: this.headers };

    return this.httpClient.put<Condo>(
      BaseService.getFullUrl(`organizations/${organizationId}/communities`, 'v1', 'business'),
      {
        communityId,
      },
      options,
    );
  }

  removeOrganizationCommunity(organizationId: string, communityId: string): Observable<boolean> {
    const options = { headers: this.headers };

    return this.httpClient.delete<boolean>(
      BaseService.getFullUrl(
        `organizations/${organizationId}/communities/${communityId}`,
        'v1',
        'business',
      ),
      options,
    );
  }

  getOrganizationMembers(organizationId: string, page: number): Observable<OrganizationUser[]> {
    let params = new HttpParams();

    params = params.append('page', String(page));
    params = params.append('size', '15');
    params = params.append('sort', 'firstName');

    const options = { headers: this.headers, params: params };

    return this.httpClient
      .get<{ content: OrganizationUser[] }>(
        BaseService.getFullUrl(`organizations/${organizationId}/members`, 'v2', 'business-view'),
        options,
      )
      .pipe(pluck('content'));
  }

  addOrganizationMember(organizationId: string, memberId: string): Observable<OrganizationUser> {
    const options = { headers: this.headers };

    return this.httpClient.put<OrganizationUser>(
      BaseService.getFullUrl(`organizations/${organizationId}/members`, 'v1', 'business'),
      {
        memberId,
      },
      options,
    );
  }

  removeOrganizationMember(organizationId: string, memberId: string): Observable<boolean> {
    const options = { headers: this.headers };

    return this.httpClient.delete<boolean>(
      BaseService.getFullUrl(
        `organizations/${organizationId}/members/${memberId}`,
        'v1',
        'business',
      ),
      options,
    );
  }

  reactivateOrganization(
    organizationId: string,
    reactivate: OrganizationReactivate,
  ): Observable<Organization> {
    const options = { headers: this.headers };

    return this.httpClient.patch<Organization>(
      BaseService.getFullUrl(`organizations/${organizationId}/reactivate`, 'v1', 'business'),
      reactivate,
      options,
    );
  }

  getBrandingLogo(condoId: string): Observable<string> {
    let params: HttpParams = new HttpParams();
    params = params.append('communityId', condoId);
    const options = { headers: this.headers, params: params };

    return this.httpClient
      .get<{ logoUrl: string }>(
        BaseService.getFullUrl('community-branding', 'v1', 'business'),
        options,
      )
      .pipe(
        pluck('logoUrl'),
        catchError(() => of('')),
      );
  }
}
