<div class="address-container">
  <h1>{{ 'AIS_SIGN_UP.WHERE_DO_YOU_LIVE' | translate }}</h1>
  <p>{{ 'AIS_SIGN_UP.ADDRESS_CAPTION' | translate }}</p>

  <form class="form-group">
    <label for="address">{{ 'AIS_SIGN_UP.ADDRESS' | translate }}</label>
    <input id="address"
           class="form-control"
           autocomplete="off"
           [placeholder]="'AIS_SIGN_UP.ADDRESS_PLACEHOLDER' | translate"
           [formControl]="addressControl"
           [typeahead]="typeaheadAccounts"
           [typeaheadOptionField]="'address'"
           [typeaheadSingleWords]="true"
           [typeaheadScrollable]="true"
           [typeaheadOptionsInScrollableView]="8"
           [typeaheadLatinize]="true"
           (typeaheadOnSelect)="onSelectAddress($event)">
  </form>

  <button class="btn btn-primary"
          [disabled]="!isAddressValid"
          (click)="onContinue()">{{ 'AIS_SIGN_UP.CONTINUE' | translate }}</button>

  <div class="help-actions">
    <a [routerLink]="['/login']">{{ 'AIS_SIGN_UP.ALREADY_REGISTERED' | translate }}</a>
  </div>
</div>
