<h1>{{ 'LOGIN.WELCOME_BACK_V2' | translate }}</h1>
<p class="text-md mb-0 mt-8">{{ 'LOGIN.ENTER_EMAIL_TO_ACCESS_COMMUNITY' | translate }}</p>

<form class="group mt-16" (ngSubmit)="onNext()">
  <label for="email-form--input--email">{{ 'LOGIN.EMAIL' | translate }}</label>
  <input
    tsqTextInput
    id="email-form--input--email"
    schema="new-primary"
    type="text"
    name="email"
    autocomplete="username"
    class="control font-base"
    [placeholder]="'LOGIN.EMAIL_PLACEHOLDER_V2' | translate"
    [formControl]="emailControl"
    [errorMessage]="'LOGIN.ENTER_A_VALID_EMAIL' | translate"
  />

  <div class="mt-24">
    <tsq-button
      *ngIf="!isLoading; else loader"
      schema="new-primary"
      size="lg"
      type="submit"
      [text]="'LOGIN.NEXT_BUTTON_LABEL' | translate"
      [disabled]="emailControl.invalid"
    />

    <ng-template #loader>
      <tsq-small-loader />
    </ng-template>
  </div>
</form>
