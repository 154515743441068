import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { icons } from '@tsq-web/assets';

import { Breadcrumb } from '../../models/breadcrumb.model';

@Component({
  selector: 'tsq-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent {
  @Input() breadcrumbs: Breadcrumb[];
  @Input() hasBoxShadow = true;
  @Input() hasBackIcon = false;
  @Input() isNewDesign = false;
  @Input() loading: boolean;

  readonly arrowLeftIcon = icons.arrowLeft;
}
