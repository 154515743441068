import {Injectable} from '@angular/core';

@Injectable()
export class SCCalendarCaptionController {
  private _hasCurrentUserEvent = false;
  private _hasWaitingQueue = false;
  private _hasWaitingApproval = false;
  private _hasPendingConfirmation = false;
  private _hasBusyDay = false;
  private _hasFullBusyDay = false;

  reset():void {
    this._hasBusyDay = false;
    this._hasCurrentUserEvent = false;
    this._hasWaitingQueue = false;
    this._hasWaitingApproval = false;
    this._hasFullBusyDay = false;
    this._hasPendingConfirmation = false;
  }

  get hasCurrentUserEvent(): boolean {
    return this._hasCurrentUserEvent;
  }

  set hasCurrentUserEvent(value: boolean) {
    this._hasCurrentUserEvent = value;
  }

  get hasWaitingQueue(): boolean {
    return this._hasWaitingQueue;
  }

  set hasWaitingQueue(value: boolean) {
    this._hasWaitingQueue = value;
  }

  get hasWaitingApproval(): boolean {
    return this._hasWaitingApproval;
  }

  set hasWaitingApproval(value: boolean) {
    this._hasWaitingApproval = value;
  }

  get hasPendingConfirmation(): boolean {
    return this._hasPendingConfirmation;
  }

  set hasPendingConfirmation(value: boolean) {
    this._hasPendingConfirmation = value;
  }

  get hasBusyDay(): boolean {
    return this._hasBusyDay;
  }

  set hasBusyDay(value: boolean) {
    this._hasBusyDay = value;
  }

  get hasFullBusyDay(): boolean {
    return this._hasFullBusyDay;
  }

  set hasFullBusyDay(value: boolean) {
    this._hasFullBusyDay = value;
  }
}
