import { Action, createReducer, on } from '@ngrx/store';

import { StoreList, storeListInitialState } from '@tsq-web/redux/utils';
import * as fromPlansActions from './plans.actions';
import { Plan } from '../plan.json';

const reducer = createReducer(
  storeListInitialState,
  on(fromPlansActions.load, state => ({
    ...state,
    entities: [],
    loading: true,
    error: false,
  })),
  on(fromPlansActions.loadSuccess, (state, { entities }) => ({
    ...state,
    entities,
    loading: false,
  })),
  on(fromPlansActions.loadFailure, state => ({
    ...state,
    entities: [],
    loading: false,
    error: true,
  })),
);

export function plansReducer(state: StoreList<Plan> | undefined, action: Action): StoreList<Plan> {
  return reducer(state, action);
}
