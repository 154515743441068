import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'tsq-sso-reset-your-password-card',
  template: `
    <div
      class="bg-yellow-n1 border-1 border-yellow-p1 mb-24 flex w-full flex-col justify-between rounded p-16 shadow"
    >
      <span class="text-yellow-p1 base-text-bold">
        {{ 'LIBS.LOGIN.RESET_YOUR_PASSWORD' | translate }}
      </span>
      <p class="text-yellow-p1 small-text mb-0">
        {{ 'LIBS.LOGIN.YOUR_EMAIL_IS_LINKED' | translate }}
        <a
          class="hover:text-kale-p1 focus:text-kale-p1 text-kale text-sm"
          target="_blank"
          [href]="ssoLink"
          >{{ 'LIBS.LOGIN.LOGIN_WITH_YOUR' | translate }}</a
        >
      </p>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsoResetYourPasswordCardComponent {
  @Input() ssoLink: string;
}
