import { Action, createReducer, on } from '@ngrx/store';

import { Condo } from '@tsq-web/condo';
import { IntroBanner, LoginContext, LoginData, LoginProvider } from '@tsq-web/login';
import {
  StoreEntity,
  StoreListWithPage,
  storeListWithPageInitialState,
} from '@tsq-web/redux/utils';
import { Favorite, User } from '@tsq-web/users';

import * as fromUserContextActions from './user-context.actions';

export interface UserContextState {
  userState: UserState;
  contexts: StoreListWithPage<LoginContext>;
  condo: Condo;
  introBanners: IntroBanner[];
  login: {
    loggingIn: boolean;
    loggingOut: boolean;
    error: LoginData | any;
  };
  patchUpsellSettings: {
    error: boolean;
    success: boolean;
  };
  userFavorites: Favorite;
  ssoLogin: {
    loading: boolean;
    error: boolean;
  };
}

export interface UserState {
  user: User;
  condos: Condo[];
  isFirstLogin: boolean;
  loginProvider: LoginProvider;
  acceptedTerms: StoreEntity<boolean>;
}

export const userContextInitialState: UserContextState = {
  userState: {
    user: undefined,
    condos: [],
    isFirstLogin: false,
    loginProvider: LoginProvider.Standard,
    acceptedTerms: {
      entity: true,
      loading: false,
      error: false,
    },
  },
  contexts: storeListWithPageInitialState,
  condo: undefined,
  introBanners: [],
  login: {
    loggingIn: false,
    loggingOut: false,
    error: undefined,
  },
  patchUpsellSettings: {
    error: false,
    success: false,
  },
  userFavorites: undefined,
  ssoLogin: {
    loading: false,
    error: false,
  },
};

const reducer = createReducer(
  userContextInitialState,
  on(fromUserContextActions.clearLogin, state => ({
    ...state,
    login: {
      ...state.login,
      loggingIn: false,
      error: undefined,
    },
  })),
  on(
    fromUserContextActions.login,
    fromUserContextActions.relogin,
    fromUserContextActions.loginV2,
    fromUserContextActions.loginV3,
    fromUserContextActions.contextLogin,
    fromUserContextActions.authenticate,
    fromUserContextActions.authTokenExchange,
    fromUserContextActions.authTokenExchangeV0,
    state => ({
      ...state,
      login: {
        ...state.login,
        loggingIn: true,
        error: false,
      },
    }),
  ),
  on(fromUserContextActions.loginFailed, (state, { obj }) => ({
    ...state,
    login: {
      ...state.login,
      loggingIn: false,
      error: obj,
    },
  })),
  on(fromUserContextActions.loggedIn, fromUserContextActions.contextLoginSuccess, state => ({
    ...state,
    login: {
      ...state.login,
      loggingIn: false,
    },
  })),
  on(fromUserContextActions.loadLoginContexts, state => ({
    ...state,
    contexts: {
      ...state.contexts,
      loading: true,
      error: false,
      page: 0,
      allLoaded: false,
    },
  })),
  on(fromUserContextActions.loadLoginContextsSuccess, (state, { contexts }) => ({
    ...state,
    contexts: {
      ...state.contexts,
      entities: contexts,
      loading: false,
      allLoaded: !contexts.length,
    },
  })),
  on(fromUserContextActions.loadLoginContextsFailed, state => ({
    ...state,
    contexts: {
      ...state.contexts,
      loading: false,
      loadingMore: false,
      error: true,
    },
  })),
  on(fromUserContextActions.loadLoginContextsNextPage, state => ({
    ...state,
    contexts: {
      ...state.contexts,
      loadingMore: true,
      page: state.contexts.page + 1,
    },
  })),
  on(fromUserContextActions.loadLoginContextsNextPageSuccess, (state, { contexts }) => ({
    ...state,
    contexts: {
      ...state.contexts,
      entities: [...state.contexts.entities, ...contexts],
      loadingMore: false,
      allLoaded: !contexts.length,
    },
  })),
  on(fromUserContextActions.contextLoginFailed, fromUserContextActions.authFailed, state => ({
    ...state,
    login: {
      ...state.login,
      loggingIn: false,
      error: true,
    },
  })),
  on(fromUserContextActions.logout, state => ({
    ...state,
    login: {
      ...state.login,
      loggingOut: true,
      error: false,
    },
  })),
  on(fromUserContextActions.logoutFailed, state => ({
    ...state,
    login: {
      ...state.login,
      loggingOut: false,
      error: true,
    },
  })),
  on(fromUserContextActions.logoutSuccess, state => ({
    ...state,
    login: {
      ...state.login,
      loggingOut: false,
      error: false,
    },
  })),
  on(fromUserContextActions.contextLogoutSuccess, () => ({
    ...userContextInitialState,
  })),
  on(fromUserContextActions.setContext, (state, { loginContext }) => ({
    ...state,
    userState: {
      ...state.userState,
      user: loginContext.user,
    },
  })),
  on(fromUserContextActions.setUserState, (state, { loginData }) => ({
    ...state,
    userState: {
      ...state.userState,
      user: loginData.user,
      condos: loginData.condos,
      isFirstLogin: loginData.isFirstLogin,
      loginProvider: !!loginData.loginProvider ? loginData.loginProvider : LoginProvider.Standard,
      introBanners: loginData.introBanners,
    },
  })),
  on(fromUserContextActions.updateUser, (state, { user }) => ({
    ...state,
    condo: { ...state.condo, ...user.condo },
    userState: {
      ...state.userState,
      user,
    },
  })),
  on(fromUserContextActions.clearUserContext, () => userContextInitialState),
  on(fromUserContextActions.setCondo, (state, { condo }) => ({
    ...state,
    condo,
    userState: {
      ...state.userState,
      condos: state.userState.condos.map(comm => (comm.id === condo.id ? condo : comm)),
    },
  })),
  on(fromUserContextActions.clearCondo, state => ({
    ...state,
    condo: undefined,
  })),
  on(fromUserContextActions.patchUpsellSettingsSuccess, state => ({
    ...state,
    patchUpsellSettings: {
      ...state.patchUpsellSettings,
      success: true,
      error: false,
    },
  })),
  on(fromUserContextActions.patchUpsellSettingsFailed, state => ({
    ...state,
    patchUpsellSettings: {
      ...state.patchUpsellSettings,
      success: false,
      error: true,
    },
  })),
  on(fromUserContextActions.getUserTermsAcceptance, state => ({
    ...state,
    login: {
      ...state.login,
      loggingIn: true,
    },
  })),
  on(fromUserContextActions.getUserTermsAcceptanceSuccess, state => ({
    ...state,
    userState: {
      ...state.userState,
      acceptedTerms: {
        ...state.userState.acceptedTerms,
        entity: true,
      },
    },
    login: {
      ...state.login,
      loggingIn: false,
    },
  })),
  on(fromUserContextActions.getUserTermsAcceptanceFailed, state => ({
    ...state,
    userState: {
      ...state.userState,
      acceptedTerms: {
        ...state.userState.acceptedTerms,
        entity: false,
        error: true,
      },
    },
    login: {
      ...state.login,
      loggingIn: false,
    },
  })),
  on(fromUserContextActions.acceptUserTerms, state => ({
    ...state,
    userState: {
      ...state.userState,
      acceptedTerms: {
        ...state.userState.acceptedTerms,
        loading: true,
      },
    },
  })),
  on(fromUserContextActions.acceptTermsSuccess, state => ({
    ...state,
    userState: {
      ...state.userState,
      acceptedTerms: {
        ...state.userState.acceptedTerms,
        loading: false,
        entity: true,
      },
    },
  })),
  on(fromUserContextActions.acceptTermsFailed, state => ({
    ...state,
    userState: {
      ...state.userState,
      acceptedTerms: {
        ...state.userState.acceptedTerms,
        loading: false,
        error: true,
      },
    },
  })),
  on(fromUserContextActions.setUser, (state, { user }) => ({
    ...state,
    userState: {
      ...state.userState,
      user: {
        ...state.userState.user,
        ...user,
      },
    },
  })),
  on(
    fromUserContextActions.updateUserDefaultLandingPageSuccess,
    (state, { defaultLandingPage }) => ({
      ...state,
      userState: {
        ...state.userState,
        user: { ...state.userState.user, defaultLandingPage },
      },
    }),
  ),

  on(fromUserContextActions.loadFavorites, state => ({
    ...state,
    userFavorites: undefined,
  })),
  on(fromUserContextActions.loadFavoritesSuccess, (state, { favorites }) => ({
    ...state,
    userFavorites: favorites,
  })),
  on(fromUserContextActions.loadFavoritesFailure, state => ({
    ...state,
    userFavorites: undefined,
  })),
  on(fromUserContextActions.saveFavorites, (state, { features }) => ({
    ...state,
    userFavorites: {
      ...state.userFavorites,
      features,
    },
  })),
  on(fromUserContextActions.saveFavoritesFailure, (state, { targetFeature, operation }) => ({
    ...state,
    userFavorites: {
      ...state.userFavorites,
      features: getFeaturesByOperation(targetFeature, operation, state.userFavorites.features),
    },
  })),
  on(fromUserContextActions.ssoAuthenticate, state => ({
    ...state,
    ssoLogin: {
      ...state.ssoLogin,
      loading: true,
    },
  })),
  on(fromUserContextActions.authSuccess, state => ({
    ...state,
    userState: {
      ...state.userState,
    },
    ssoLogin: {
      ...state.ssoLogin,
      loading: false,
      error: false,
    },
  })),
  on(fromUserContextActions.authFailed, state => ({
    ...state,
    ssoLogin: {
      ...state.ssoLogin,
      loading: false,
      error: true,
    },
  })),
);

function getFeaturesByOperation(
  targetFeature: string,
  operation: 'add' | 'remove',
  features: string[],
): string[] {
  switch (operation) {
    case 'add':
      return features.filter(feature => feature !== targetFeature);
    case 'remove':
      return [...features, targetFeature];
  }
}

export function userContextReducer(state: UserContextState | undefined, action: Action) {
  return reducer(state, action);
}
