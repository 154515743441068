import {FeaturePermission} from '@tsq-web/feature';

export class Role {
  condo_id: string;
  prop_id: string;
  role_id: string;
  display_name: string;
  groupId: string;
  permissionList: FeaturePermission[];

  constructor(groupId: string, condo_id: string, prop_id?: string) {
    this.condo_id = condo_id;
    this.groupId = groupId;
    if (prop_id) {
      this.prop_id = prop_id;
    }
  }
}
