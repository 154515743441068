<div class="px-16 pt-16">
  <div class="tsq-card">
    <div class="panel-heading">
      <h1 class="text-base font-regular">{{ 'SUBSCRIPTION.CUSTOMER_TAXES' | translate }}</h1>
    </div>

    <div class="panel-body">
      <form [formGroup]="form">
        <div class="tsq-card flex p-16 justify-around">
          <div>
            <div class="flex items-center">
              <label class="m-0">{{ 'SUBSCRIPTION.TAXPAYER' | translate }}</label>
              <tsq-switch class="ml-8" formControlName="taxpayer"></tsq-switch>
            </div>
          </div>

          <div>
            <div class="flex items-center">
              <label class="m-0">{{ 'SUBSCRIPTION.SIMPLES_NACIONAL' | translate }}</label>
              <tsq-switch class="ml-8" formControlName="simplesNacional"></tsq-switch>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
