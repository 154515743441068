/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

import { LoginData } from '@tsq-web/login';
import { User } from '@tsq-web/users';
import { BaseService } from '@tsq-web/core';

@Injectable()
export class LoginService extends BaseService {
  constructor(
    protected _cookieService: CookieService,
    protected _http: HttpClient,
    protected _router: Router,
  ) {
    super(_cookieService, _http, _router);
  }

  loginUser(user: User): Observable<LoginData> {
    return this.doPost(`login`, this.prepareBody(user));
  }

  reloginUser(user: User): Observable<LoginData> {
    return this.doPost(`login/relogin`, this.prepareBody(user));
  }

  logoutUser(): Observable<LoginData> {
    return this.doGet('logout');
  }
}
