<sc-modal title="{{ modalTitle | translate }}" #modal>
  <div modal-content>
    <ul class="group-list" *ngIf="displayedCategory">
      <li class="group-line pull-left" *ngFor="let groupPermission of displayedCategory.userGroupPermissions">
        <div class="group-icon pull-left" [style.backgroundColor]="groupPermission.userGroup.color">
          <i class="{{'fa ' + groupPermission.userGroup.icon}}" aria-hidden="true"></i>
        </div>
        <div class="info-data">
          <div class="info-title">
            {{groupPermission.userGroup.name}}
          </div>
        </div>
      </li>
    </ul>
  </div>

  <div actions>
  </div>
</sc-modal>
