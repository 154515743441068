import { Component, Input, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

import { FormSelectionOption } from '../../models/form-selection-option.model';

@Component({
  selector: 'tsq-choice-selection',
  templateUrl: './choice-selection.component.html',
  styleUrls: ['./choice-selection.component.scss'],
})
export class ChoiceSelectionComponent implements ControlValueAccessor {
  @Input() options: FormSelectionOption[];

  private onChange: (value: unknown) => void;
  private onTouched: () => void;

  private _selected: unknown;
  private _disabled: boolean;

  constructor(@Optional() @Self() ngControl: NgControl) {
    ngControl.valueAccessor = this;
  }

  get pillStyle(): Record<string, string> {
    return {
      top: '2px',
      left: this.calculateLeft,
      width: 100 / this.options.length + '%',
      height: '36px',
    };
  }

  get calculateLeft(): string {
    if (this.selectedIndex === 0) {
      return '2px';
    } else if (this.selectedIndex === this.options.length - 1) {
      return `calc(${this.selectedIndex * (100 / this.options.length)}% - 2px)`;
    } else {
      return `${this.selectedIndex * (100 / this.options.length)}%`;
    }
  }

  get selectedIndex(): number {
    return this.options.findIndex(opt => opt.value === this.selected);
  }

  get selected(): unknown {
    return this._selected;
  }

  get disabled(): boolean {
    return this._disabled;
  }

  registerOnChange(fn: (value: unknown) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(opt: FormSelectionOption): void {
    this._selected = opt;
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  onRadioSelect(value: unknown): void {
    this.onTouched();
    this.onChange(value);
    this._selected = value;
  }
}
