<div class="flex" [ngClass]="{ 'flex-row': inline, 'flex-col': !inline, 'flex-wrap': inlineWrap }">
  <div
    tabindex="0"
    class="flex items-center rounded p-8 last:m-0"
    [ngClass]="{
      'pointer-cursor focus:outline': !disabled && !option.isDisabled,
      'hover:bg-primary-n1': schema !== 'new-primary' && !disabled && !option.disabled,
      'focus:outline-new-primary hover:bg-petro-n1': schema === 'new-primary' && !disabled && !option.isDisabled,
      'cursor-not-allowed': disabled || option.isDisabled,
      'my-0 mr-8': inline,
      'grow': inline && !inlineWrap,
      'mb-8': !inline,
    }"
    *ngFor="let option of options"
    (click)="onRadioSelected(option)"
    (keyup.Space)="onRadioSelected(option)"
    (keyup.enter)="onRadioSelected(option)"
  >
    <div
      class="radio"
      [class.self-start]="showLongText"
      [attr.selected]="!!selected && option.value === selected.value"
      [attr.disabled]="option.isDisabled || disabled"
      [attr.schema]="schema"
    ></div>
    <ng-container *ngIf="!!itemTemplate">
      <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: option }"></ng-container>
    </ng-container>
    <span
      class="ml-8"
      [class.truncate]="!showLongText"
      *ngIf="!itemTemplate"
      [innerHTML]="option.text | translate"
    ></span>
  </div>
</div>
