import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';

import { icons } from '@tsq-web/assets';
import { FormSelectionOption, TSqValidators } from '@tsq-web/forms';
import {
  ModalContext,
  ModalPositions,
  ModalService,
  ModalSize,
  ModalTypes,
  TSqTemplatePortal,
  ofModalType,
} from '@tsq-web/modals';

import { ReasonOptionsDeleteComment } from '../../models/reason-options-delete-comment.enum';

@UntilDestroy()
@Component({
  selector: 'tsq-delete-comment-modal',
  templateUrl: './delete-comment-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteCommentModalComponent implements OnInit {
  @ViewChild('modalRef') modalRef: TemplateRef<ModalContext>;

  @Input() adminDeleteCommentIsSending: boolean;

  @Output() reason = new EventEmitter<string>();

  form: FormGroup<{
    reason: FormControl<FormSelectionOption>;
    description: FormControl<string>;
  }>;

  shouldShowOtherReasonField: boolean;
  deleteCommentsOptions: FormSelectionOption[] = [];

  readonly icons = icons;

  private readonly modalContext: ModalContext = {
    position: ModalPositions.CENTER,
    disableClose: false,
    size: ModalSize.Medium,
  };

  constructor(
    private viewContainerRef: ViewContainerRef,
    private modalService: ModalService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.modalService.type$
      .pipe(ofModalType(ModalTypes.DeleteCommentManagerModal), untilDestroyed(this))
      .subscribe(() => {
        this.modalService.setPortal(
          new TSqTemplatePortal(this.modalRef, this.viewContainerRef, this.modalContext),
        );
        this.form.reset();
      });

    this.initForm();
    this.listenChangesForm();

    this.deleteCommentsOptions = this.buildReasonList();
  }

  onDeleteComment(): void {
    const reasonCommentDeletion = this.shouldShowOtherReasonField
      ? `${this.form.value.reason?.text} ${this.form.value.description}`
      : this.form.value.reason?.text;

    this.reason.emit(reasonCommentDeletion);
  }

  private initForm(): void {
    this.form = new FormGroup({
      reason: new FormControl<FormSelectionOption>(undefined, Validators.required),
      description: new FormControl(''),
    });
  }

  private listenChangesForm(): void {
    this.form.controls.reason.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      if (value?.value === ReasonOptionsDeleteComment.OtherSpecify) {
        this.form.controls.description.addValidators([
          Validators.required,
          TSqValidators.hasCharDifferentThanWhitespace,
        ]);
        this.form.controls.description.updateValueAndValidity();
        this.shouldShowOtherReasonField = true;
        this.form.controls.description.reset();
      } else {
        this.shouldShowOtherReasonField = false;
        this.form.controls.description.clearValidators();
        this.form.controls.description.updateValueAndValidity();
      }
    });
  }

  private buildReasonList(): FormSelectionOption[] {
    const enumValues = Object.values(ReasonOptionsDeleteComment);

    return enumValues.map((value: string) => {
      const text = this.translateService.instant(`LIBS.DELETE_COMMENTS.${value}`);
      return { text, value };
    });
  }
}
