import { Component, Input, OnInit } from '@angular/core';

import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

import { ModalService } from '../../services/modal.service';

@UntilDestroy()
@Component({
  selector: 'tsq-generic-modal-cancel-button',
  template: `
    <button id="modal-cancel" class="btn btn-secondary" [disabled]="disabled" (click)="onClick()">
      {{ text || 'LIBS.CANCEL' | translate }}
    </button>
  `,
})
export class TSqGenericModalCancelButtonComponent implements OnInit {
  @Input() text: string;

  disabled: boolean;

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {
    this.modalService.context$
      .pipe(untilDestroyed(this))
      .subscribe(context => (this.disabled = !!context && context.disableClose));
  }

  onClick(): void {
    this.modalService.close();
  }
}
