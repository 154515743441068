export function isDescendant(parent: HTMLElement, child: HTMLElement): boolean {
  let node = child.parentNode;
  while (node != null) {
    if (node == parent) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
}
