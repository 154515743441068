import { Component, Input, Optional, Self, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

import * as _ from 'lodash';

import { FormMultipleSelectionOption } from '../../models/form-selection-option.model';

@Component({
  selector: 'tsq-checkbox-group',
  templateUrl: './tsq-checkbox-group.component.html',
})
export class TSqCheckboxGroupComponent implements ControlValueAccessor {
  @Input() options: FormMultipleSelectionOption[];
  @Input() itemTemplate: TemplateRef<{ item: FormMultipleSelectionOption }>;

  selected: FormMultipleSelectionOption[] = [];

  disabled: boolean;
  private onChange: (value: FormMultipleSelectionOption[]) => void;
  private onTouched: () => void;

  constructor(@Optional() @Self() private ngControl: NgControl) {
    ngControl.valueAccessor = this;
  }

  registerOnChange(fn: (value: FormMultipleSelectionOption[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(option: FormMultipleSelectionOption | FormMultipleSelectionOption[]): void {
    if (!!option && Array.isArray(option) && option.length === 0) {
      this.selected = [];
    } else if (!!option) {
      const options = Array.isArray(option) ? option : [option];
      this.selected = _.xorBy(this.selected, options, 'id');
    }
  }

  isSelected(option: FormMultipleSelectionOption): boolean {
    return this.selected.some(s => s.id === option.id);
  }

  onSelected(option: FormMultipleSelectionOption): void {
    if (!this.disabled) {
      this.writeValue(option);
      this.onTouched();
      this.onChange(this.selected);
    }
  }
}
