import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'comm-tsq-radio-inline',
  templateUrl: './tsq-radio-inline.component.html',
  styleUrls: ['./tsq-radio-inline.component.scss'],
})
export class TSqRadioInlineComponent {
  @Input() options: { value: unknown; label: string }[];
  @Input() disabled: boolean;
  @Input() tooltipMessage: string;
  @Output() selectedChange = new EventEmitter<{ value: unknown; label: string }>();

  selectedIndex: number;

  @Input() set selected(value: { value: unknown; label: string }) {
    this.options.forEach((option, index) => {
      if (option.value === value.value) {
        this.selectedIndex = index;
        return;
      }
    });
  }

  onOptionSelected(index: number): void {
    this.selectedChange.emit(this.options[index]);
  }
}
