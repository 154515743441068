<div id="pager-bullet-container"
     [ngStyle]="{ 'padding': paddingForCard,
                  'background': backgroundColor,
                  'border-radius': borderRadius }"
     [ngClass]="{ 'flex-column-reverse': bulletsPositionY === 'top',
                  'flex-column': bulletsPositionY === 'bottom' }">
  <div>
    <ng-content></ng-content>
  </div>
  <div id="bullets-container"
       *ngIf="bullets > 1 || (bullets === 1 && showIfSingle)"
       [ngClass]="{ 'flex-start': bulletsPositionX === 'left',
                    'flex-center': bulletsPositionX === 'center',
                    'flex-end': bulletsPositionX === 'right' }">
    <div *ngFor="let bullet of arrayRef(bullets).fill(1); let i = index">
      <i class="fa fa-circle-o pointer-cursor"
         [ngClass]="{ 'fa-circle': selected === i }"
         (click)="selectedChange.emit(i)"></i>
    </div>
  </div>
</div>
