import { ArchitecturalReview } from '@tsq-web/architectural-review';
import { Condo } from '@tsq-web/condo';
import { Feature, FeaturePermission } from '@tsq-web/feature';
import { UserGroup } from '@tsq-web/groups';
import { User } from '@tsq-web/users';

import { Permission } from '../models/permission.enum';
import { Role } from '../models/role.model';

export function hasFeatureAccess(
  user: User,
  condo: Condo,
  feature: Feature,
  permission: Permission,
): boolean {
  return hasFeaturePermission(user, condo, feature, permission) && isFeatureActive(feature, condo);
}

export function hasFeaturePermission(
  user: User,
  condo: Condo,
  feature: Feature,
  permission: Permission,
): boolean {
  if (!user) {
    return false;
  }
  return user.roles.some((role: Role) => {
    return (
      !!role.permissionList &&
      role.permissionList.some((featurePermission: FeaturePermission) => {
        if (featurePermission.feature === Feature[feature]) {
          return (
            featurePermission.permission === Permission[Permission.SUPER] ||
            (isRoleForCondo(condo, role) &&
              containsPermission(permission, featurePermission.permission))
          );
        }
        return false;
      })
    );
  });
}

export function hasFeatureAccessAnyCondo(
  user: User,
  feature: Feature,
  permission: Permission,
): boolean {
  if (!user) {
    return false;
  }
  return user.roles.some(
    (role: Role) =>
      !!role.permissionList &&
      role.permissionList.some((ftPerm: FeaturePermission) =>
        feature === ftPerm.feature ? containsPermission(permission, ftPerm.permission) : false,
      ),
  );
}

export function isSuperUser(user: User): boolean {
  return !!user && user.roles.some(role => role.role_id === Permission.SUPER);
}

export function condoEnablesOwnerRolesManagement(condo: Condo): boolean {
  return !condo.adminFeatures || !!condo.adminFeatures.manageOwnerRoles !== false;
}

export function condoEnablesManagerRolesManagement(condo: Condo): boolean {
  return !condo.adminFeatures || !!condo.adminFeatures.manageManagerRoles !== false;
}

export function containsPermission(access: Permission, permission: string): boolean {
  if (permission === Permission[Permission.SUPER]) {
    return true;
  }
  switch (access) {
    case Permission.VIEW:
      return (
        permission === Permission[Permission.VIEW] ||
        permission === Permission[Permission.CREATE_FOR_OTHERS] ||
        permission === Permission[Permission.ADMIN]
      );

    case Permission.CREATE_FOR_OTHERS:
      return (
        permission === Permission[Permission.CREATE_FOR_OTHERS] ||
        permission === Permission[Permission.ADMIN]
      );

    case Permission.ADMIN:
      return permission === Permission[Permission.ADMIN];

    default:
      return false;
  }
}

export function userIsInDefaultOwnerGroup(user: User, condo: Condo): boolean {
  if (!user || !condo) {
    return false;
  }
  return user.roles.some(role => role.groupId === condo.defaultOwnerGroup.id);
}

export function userIsInDefaultManagerGroup(user: User, condo: Condo): boolean {
  if (!user || !condo) {
    return false;
  }
  return user.roles.some(role => role.groupId === condo.defaultManagerGroup.id);
}

export function userIsInDefaultAdminGroup(user: User, condo: Condo): boolean {
  if (!user || !condo) {
    return false;
  }
  return user.roles.some(role => role.groupId === condo.defaultAdminGroup.id);
}

export function userIsInDefaultGroup(user: User, condo: Condo): boolean {
  const defaultGroups: UserGroup[] = [
    condo.defaultOwnerGroup,
    condo.defaultAdminGroup,
    condo.defaultFamilyMemberGroup,
    condo.defaultManagerGroup,
    condo.defaultTenantGroup,
  ];
  return defaultGroups.some(group => user.roles.some(role => role.groupId === group.id));
}

export function isMoreThanJustResident(user: User, condo: Condo): boolean {
  return (
    hasFeatureAccess(user, condo, Feature.Neighbors, Permission.ADMIN) ||
    hasFeatureAccess(user, condo, Feature.Lobby, Permission.ADMIN)
  );
}

export function isFeatureActive(feature: Feature, condo: Condo): boolean {
  if (!condo) {
    return false;
  }
  switch (feature) {
    case Feature.Alerts:
      return condo.featuresStatuses.alertsActive;
    case Feature.Assignments:
      return condo.featuresStatuses.assignmentsActive;
    case Feature.Calendars:
      return condo.featuresStatuses.calendarActive;
    case Feature.Discussion:
      return condo.featuresStatuses.discussionActive;
    case Feature.Documents:
      return condo.featuresStatuses.documentsActive;
    case Feature.Lobby:
      return condo.featuresStatuses.lobbyActive;
    case Feature.Neighbors:
      return condo.featuresStatuses.neighborsActive;
    case Feature.Poll:
      return condo.featuresStatuses.pollsActive;
    case Feature.DigitalVoting:
      return condo.featuresStatuses.digitalVotingActive;
    case Feature.ResidentDiscussion:
      return condo.featuresStatuses.residentDiscussionActive;
    case Feature.TrusteeDiscussion:
      return condo.featuresStatuses.trusteeDiscActive;
    case Feature.Website:
      return condo.featuresStatuses.websiteActive;
    case Feature.PackageDelivery:
      return condo.featuresStatuses.packageDeliveryActive;
    case Feature.ShiftLogs:
      return condo.featuresStatuses.shiftLogsActive;
    case Feature.ArchitecturalReview:
      return condo.featuresStatuses.architecturalReviewActive;
    case Feature.ViewUsersFullNames:
      return condo.featuresStatuses.viewUsersFullNamesActive;
    case Feature.UserCustomFields:
      return condo.featuresStatuses.userCustomFieldsActive;
    case Feature.BonvoyNumber:
      return condo.featuresStatuses.bonvoyNumberActive;
    case Feature.PetRegistry:
      return condo.featuresStatuses.petRegistryActive;
    case Feature.ValetParking:
      return condo.featuresStatuses.valetParkingActive;
    case Feature.EmergencyBroadcast:
      return condo.featuresStatuses.emergencyBroadcastActive;
    case Feature.ConciergeHub:
      return condo.featuresStatuses.conciergeHubActive;
    case Feature.KeyManagement:
      return condo.featuresStatuses.keyManagementActive;
    case Feature.ServiceLogs:
      return condo.featuresStatuses.serviceLogsActive;
    case Feature.ReplySuggestion:
      return condo.featuresStatuses.replySuggestionActive;
    case Feature.RequestInterception:
      return condo.featuresStatuses.requestInterceptionActive;
    default:
      return true;
  }
}

export function userHasRoleInArchReview(user: User, archReview: ArchitecturalReview): boolean {
  if (!user || !archReview) {
    return false;
  }

  return user.roles.some(role => role.prop_id === archReview.property.id);
}

function isRoleForCondo(condo: Condo, role: Role): boolean {
  return !role.condo_id || (!!condo && condo.id === role.condo_id);
}
