<div id="container">
  <div class="panel-transparent panel">
    <div class="panel-heading">
      <h1>{{ 'SUBSCRIPTION.RESPONSIBLE_PAYMENT' | translate }}</h1>
    </div>

    <div class="panel-body">
      <sc-user-typeahead id="auth-in-name-of"
                         fieldLabel="{{ 'SUBSCRIPTION.NAME' | translate }}"
                         (userSelectedEvent)="typeaheadUserSelected($event)" #userTypeahead></sc-user-typeahead>
      <form [formGroup]="form" class="mt-10">
        <div class="col-xs-6 pr-5 no-padding-left form-group">
          <label for="email">{{ 'SUBSCRIPTION.EMAIL' | translate }}</label>
          <input id="email" class="form-control" formControlName="email">
        </div>
        <div class="col-xs-6 pl-5 no-padding-right form-group">
          <label for="phone">{{ 'SUBSCRIPTION.PHONE' | translate }}</label>
          <input id="phone" class="form-control" formControlName="phone" [imask]="phoneMask">
        </div>
      </form>
    </div>
  </div>
</div>
