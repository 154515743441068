import { Component, EventEmitter, Input, Output } from '@angular/core';

import { icons } from '@tsq-web/assets';

@Component({
  selector: 'comm-shared-modal-form-confirm-alert',
  templateUrl: './modal-form-confirm-alert.component.html',
  styleUrls: ['./modal-form-confirm-alert.component.scss'],
})
export class ModalFormConfirmAlertComponent {
  @Input() open = false;
  @Input() confirmBtnText: string;
  @Input() cancelBtnText: string;
  @Input() description: string;

  @Output() cancel = new EventEmitter<void>();
  @Output() confirm = new EventEmitter<void>();

  readonly libIcons = icons;

  cancelClicked(): void {
    this.cancel.emit();
  }

  confirmClicked(): void {
    this.confirm.emit();
  }
}
