export enum BusinessFeatures {
  ArchitecturalReview = 'ArchitecturalReview',
  ArchitecturalReviewMailing = 'ArchitecturalReviewMailing',
  Automations = 'Automations',
  Insights = 'Insights',
  Portfolio = 'Portfolio',
  Reports = 'Reports',
  Violations = 'Violations',
  ViolationMailing = 'ViolationMailing',
  Workflow = 'Workflow',
  MailOrders = 'MailOrders',
  AnalyticsInsights = 'AnalyticsInsights',
  EmergencyBroadcast = 'EmergencyBroadcast',
}
