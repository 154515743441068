import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import moment from 'moment-timezone';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'charge-date',
  templateUrl: './charge-date.component.html',
  styleUrls: ['./charge-date.component.scss'],
})
export class ChargeDateComponent implements OnInit {
  @Output() nextChargeDateChanged = new EventEmitter<string>();
  @Input() date: string;
  form: UntypedFormGroup;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      nextChargeDate: new UntypedFormControl('', Validators.required),
    });
  }

  get minDate(): Date {
    return moment().toDate();
  }

  get nextChargeDate(): Date {
    const chargeDate = moment(this.date, 'x').toDate();
    if (!!this.form.value.nextChargeDate) {
      return moment(this.form.value.nextChargeDate, 'x').toDate();
    } else if (!!chargeDate) {
      return chargeDate;
    }
    return undefined;
  }

  handleChargeDate(date: Date): void {
    if (!!date) {
      const currentDate = moment(date).valueOf().toString();
      this.form.controls.nextChargeDate.setValue(currentDate);
      this.cdRef.detectChanges();
      this.nextChargeDateChanged.emit(currentDate);
    }
  }
}
