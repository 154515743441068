import { Injectable, inject } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import {
  IConfigCatClient as ConfigCatClient,
  User as ConfigCatUser,
  LogLevel,
  PollingMode,
  createConsoleLogger,
  getClient,
} from 'configcat-js';
import {
  Observable,
  ReplaySubject,
  Subject,
  combineLatest,
  from,
  map,
  mergeMap,
  repeat,
  switchMap,
  tap,
} from 'rxjs';

import { FeatureFlagName, FeatureFlagService } from '@tsq-web/feature-flag';
import { fromUserContextSelectors } from '@tsq-web/user-context';

import { FeatureFlags } from '../../../feature-flag/feature-flags.model';
import { getEnvironment } from '../environment';
import { CommunitiesEnvironments } from '../environment/models/communities-environments.enum';
import { getCustomConfigCatUserPayload } from './config-cat.utils';

@UntilDestroy()
@Injectable()
export class ConfigCatService extends FeatureFlagService<FeatureFlags> {
  private client: ConfigCatClient;

  private readonly clientReadySubject = new ReplaySubject<void>(1);
  private readonly nonAutoPollConfigRefreshedSubject = new Subject<void>();

  private readonly store = inject(Store);

  constructor() {
    super();

    combineLatest([
      this.store.select(fromUserContextSelectors.selectUser),
      this.store.select(fromUserContextSelectors.selectCondo),
    ])
      .pipe(
        map(([user, condo]) => {
          if (!user || !condo) {
            return null;
          }

          return new ConfigCatUser(
            `${user.id}_${condo.id}`,
            user.email,
            condo.address?.country,
            getCustomConfigCatUserPayload(user, condo),
          );
        }),
        mergeMap(user => {
          if (!this.client) {
            return this.createClient(user).pipe(tap(() => this.clientReadySubject.next()));
          }

          this.client.setDefaultUser(user);

          return from(this.client.forceRefreshAsync()).pipe(
            tap(() => this.nonAutoPollConfigRefreshedSubject.next()),
            map(() => this.client),
          );
        }),
        untilDestroyed(this),
      )
      .subscribe(client => {
        this.client = client;
      });
  }

  override getValue(flag: FeatureFlagName<FeatureFlags, boolean>, fallback?: boolean): boolean {
    return this.baseGetValue(flag, fallback ?? false);
  }

  override getValueAsync(
    flag: FeatureFlagName<FeatureFlags, boolean | string>,
    fallback?: boolean,
  ): Observable<boolean> {
    return this.baseGetValueAsync(flag, fallback ?? false);
  }

  override getValueString(flag: FeatureFlagName<FeatureFlags, string>, fallback?: string): string {
    return this.baseGetValue(flag, fallback ?? '');
  }

  override getValueStringAsync(
    flag: FeatureFlagName<FeatureFlags, string>,
    fallback?: string,
  ): Observable<string> {
    return this.baseGetValueAsync(flag, fallback ?? '');
  }

  override getValueNumber(flag: FeatureFlagName<FeatureFlags, number>, fallback?: number): number {
    return this.baseGetValue(flag, fallback ?? 0);
  }

  override getValueNumberAsync(
    flag: FeatureFlagName<FeatureFlags, number>,
    fallback?: number,
  ): Observable<number> {
    return this.baseGetValueAsync(flag, fallback ?? 0);
  }

  private createClient(user: ConfigCatUser): Observable<ConfigCatClient> {
    const { env, configCatSdkKey } = getEnvironment();
    const isLowerEnvironment = [
      CommunitiesEnvironments.LOCAL,
      CommunitiesEnvironments.DEV,
      CommunitiesEnvironments.UAT,
    ].includes(env as CommunitiesEnvironments);

    const client = getClient(configCatSdkKey, PollingMode.AutoPoll, {
      defaultUser: user,
      logger: createConsoleLogger(isLowerEnvironment ? LogLevel.Warn : LogLevel.Info),
      pollIntervalSeconds: isLowerEnvironment ? 60 : 3600,
    });

    return from(client.waitForReady()).pipe(map(() => client));
  }

  private baseGetValue<T extends string | number | boolean>(flag: string, fallback: T): T {
    if (!this.client) {
      return fallback;
    }

    return this.client.snapshot().getValue<T>(flag, fallback) as T;
  }

  private baseGetValueAsync<T extends string | number | boolean>(
    flag: string,
    fallback: T,
  ): Observable<T> {
    return this.clientReadySubject.asObservable().pipe(
      switchMap(() => {
        return from(this.client.getValueAsync<T>(flag, fallback) as Promise<T>);
      }),
      repeat({
        delay: () => this.nonAutoPollConfigRefreshedSubject.asObservable(),
      }),
    );
  }
}
