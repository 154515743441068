import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import { Condo } from '@tsq-web/condo';
import {
  LoginBody,
  LoginContext,
  LoginData,
  LoginErrorType,
  LoginProvider,
  LoginResponse,
  LoginService,
  OptInService,
  SingleSignOnService,
} from '@tsq-web/login';
import { fromRouterSelectors } from '@tsq-web/router';
import { AppState } from '@tsq-web/state';
import { ToastErrorComponent, ToastSuccessComponent } from '@tsq-web/toast';
import { User, UserFavoritesService, UsersService } from '@tsq-web/users';

import * as fromUserContextActions from './user-context.actions';
import * as fromUserContextSelectors from './user-context.selectors';

@Injectable()
export class UserContextEffects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserContextActions.login),
      mergeMap(({ user }) => this.loginService.login(user)),
      catchError((err, caught$) => {
        this.toastrService.error(
          this.translateService.instant('LIBS.LOGIN.ERRORS.UNKNOWN'),
          this.translateService.instant('COMMON_ERROR_TITLE'),
          { toastComponent: ToastErrorComponent },
        );
        this.store.dispatch(fromUserContextActions.loginFailed({ obj: err }));
        return caught$;
      }),
      map((loginData: LoginData) => {
        if (loginData.success) {
          return fromUserContextActions.loggedIn({ loginData });
        } else if (loginData.errorType === LoginErrorType.INTEGRATION) {
          const details = { message: loginData.errorMessage };
          const message = this.translateService.instant('LIBS.LOGIN.ERRORS.INTEGRATION', details);
          this.toastrService.error(message, this.translateService.instant('COMMON_ERROR_TITLE'), {
            toastComponent: ToastErrorComponent,
          });
        } else {
          const errorMessageKey = `LIBS.LOGIN.ERRORS.${loginData.errorType}`;
          const isAccessDeniedError = [
            LoginErrorType.NO_CONDOS_FOR_RESIDENT,
            LoginErrorType.NO_CONDOS_FOR_MANAGER,
          ].includes(loginData.errorType);

          this.toastrService.error(
            this.translateService.instant(errorMessageKey),
            isAccessDeniedError
              ? this.translateService.instant('LIBS.LOGIN.ERRORS.ACCESS_DENIED')
              : this.translateService.instant('COMMON_ERROR_TITLE'),
            { toastComponent: ToastErrorComponent },
          );
        }
        return fromUserContextActions.loginFailed({ obj: loginData });
      }),
    ),
  );

  loginV2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserContextActions.loginV2),
      switchMap(({ user }) =>
        this.loginService.login(user).pipe(
          map((loginData: LoginData) => {
            if (loginData.success) {
              return fromUserContextActions.loggedIn({ loginData });
            }

            this.toastrService.error(
              this.translateService.instant(
                `LIBS.LOGIN.ERRORS_V2.DESCRIPTION.${this.handleLoginError(loginData)}`,
              ),
              this.translateService.instant(
                `LIBS.LOGIN.ERRORS_V2.TITLE.${this.handleLoginError(loginData)}`,
              ),
              { toastComponent: ToastErrorComponent },
            );

            return fromUserContextActions.loginFailed({ obj: loginData });
          }),
          catchError(err => {
            this.toastrService.error(
              this.translateService.instant('LIBS.LOGIN.ERRORS.UNKNOWN'),
              this.translateService.instant('COMMON_ERROR_TITLE'),
              { toastComponent: ToastErrorComponent },
            );

            return of(fromUserContextActions.loginFailed({ obj: err }));
          }),
        ),
      ),
    ),
  );

  loginV3$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserContextActions.loginV3),
      switchMap(({ user }) =>
        this.loginService.login(user).pipe(
          map((loginData: LoginData) => {
            if (loginData.success) {
              return fromUserContextActions.loggedIn({ loginData });
            }

            const errorMessageKey = this.handleLoginError(loginData);
            const errorMessage = `LOGIN.ERRORS.BANNER.${errorMessageKey}`;
            return fromUserContextActions.loginFailed({
              obj: errorMessage,
            });
          }),
          catchError(err => {
            return of(
              fromUserContextActions.loginFailed({ obj: this.handleLoginError(null, true) }),
            );
          }),
        ),
      ),
    ),
  );

  relogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserContextActions.relogin),
      withLatestFrom(this.store.pipe(select(fromUserContextSelectors.selectCondo))),
      mergeMap(([{ logonToken }, condo]: [{ logonToken: string }, Condo]) => {
        const token = JSON.parse(logonToken || this.cookieService.get('logonToken'));
        if (!!token) {
          const user = new User();
          user.login_type = 'CMN';
          user.platform = 'webapp';
          user.email = token.email;
          user.logonToken = token.logonToken;
          user.currentCondoId = condo && condo.id;

          return this.loginService.relogin(user);
        }
      }),
      catchError((err, caught$) => {
        this.store.dispatch(fromUserContextActions.loginFailed({ obj: err }));
        this.router.navigate(['login']);
        return caught$;
      }),
      map((loginData: LoginData) => {
        if (loginData.success) {
          return fromUserContextActions.loggedIn({ loginData });
        }
        return fromUserContextActions.loginFailed({ obj: loginData });
      }),
    ),
  );

  loggedIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserContextActions.loggedIn),
      map(({ loginData }) => {
        localStorage.removeItem('refresh');
        localStorage.setItem('currentUser', loginData.user.id);

        this.cookieService.set('jwt', loginData.jwt, null, '/');
        const token = { email: loginData.user.email, logonToken: loginData.logon_token };
        this.cookieService.set('logonToken', JSON.stringify(token), null, '/');

        return fromUserContextActions.setUserState({ loginData });
      }),
    ),
  );

  getUserTermsAcceptance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserContextActions.getUserTermsAcceptance),
      switchMap(() =>
        this.optInService.getTerms().pipe(
          map(() => fromUserContextActions.getUserTermsAcceptanceSuccess()),
          catchError(() => of(fromUserContextActions.getUserTermsAcceptanceFailed())),
        ),
      ),
    ),
  );

  acceptUserTerms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserContextActions.acceptUserTerms),
      mergeMap(() =>
        this.optInService.acceptAuthorizedTerms().pipe(
          map(() => fromUserContextActions.acceptTermsSuccess()),
          catchError((err: HttpErrorResponse) => {
            if (err.status === HttpStatusCode.Conflict) {
              return of(fromUserContextActions.acceptTermsSuccess());
            }

            return of(fromUserContextActions.acceptTermsFailed());
          }),
        ),
      ),
    ),
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserContextActions.logout),
      mergeMap(() => this.loginService.logout()),
      catchError((err, caught$) => {
        this.store.dispatch(fromUserContextActions.logoutFailed());
        return caught$;
      }),
      map(() => fromUserContextActions.logoutSuccess()),
    ),
  );

  logoutSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserContextActions.logoutSuccess),
      map(() => fromUserContextActions.clearUserContext()),
    ),
  );

  contextLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserContextActions.contextLogin),
      mergeMap(({ loginBody }: { loginBody: LoginBody }) =>
        this.loginService.contextLogin(loginBody),
      ),
      catchError((err, caught$) => {
        if (err.status === this.unauthorizedErrorCode) {
          this.toastrService.error(
            err.error.message,
            this.translateService.instant('COMMON_ERROR_TITLE'),
            { toastComponent: ToastErrorComponent },
          );
        } else {
          this.toastrService.error(
            this.translateService.instant('COMMON_ERROR_TEXT'),
            this.translateService.instant('COMMON_ERROR_TITLE'),
            { toastComponent: ToastErrorComponent },
          );
        }

        this.store.dispatch(fromUserContextActions.contextLoginFailed());
        return caught$;
      }),
      map((loginResponse: LoginResponse) =>
        fromUserContextActions.contextLoginSuccess({ loginResponse }),
      ),
    ),
  );

  contextLoginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromUserContextActions.contextLoginSuccess),
        map(({ loginResponse }: { loginResponse: LoginResponse }) =>
          this.cookieService.set('jwt', loginResponse.authorization.jwt, null, '/'),
        ),
      ),
    { dispatch: false },
  );

  contextLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserContextActions.contextLogout),
      map(() => {
        this.cookieService.delete('jwt', '/');
        this.cookieService.delete('CmAccessToken', '/');
        return fromUserContextActions.contextLogoutSuccess();
      }),
    ),
  );

  loadLoginContexts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromUserContextActions.loadLoginContexts,
        fromUserContextActions.loadLoginContextsNextPage,
      ),
      withLatestFrom(
        this.store.pipe(select(fromUserContextSelectors.selectLoginContextsPage)),
        this.store.pipe(select(fromRouterSelectors.selectRouterQueryParamSearch)),
      ),
      mergeMap(([, page, search]: [any, number, string]) =>
        combineLatest([of(page), this.loginService.getContextList(search, page)]),
      ),
      catchError((err, caught$) => {
        this.store.dispatch(fromUserContextActions.loadLoginContextsFailed());
        return caught$;
      }),
      map(([page, contexts]: [number, LoginContext[]]) => {
        if (!!page) {
          return fromUserContextActions.loadLoginContextsNextPageSuccess({ contexts });
        }
        return fromUserContextActions.loadLoginContextsSuccess({ contexts });
      }),
    ),
  );

  updateUserDefaultLandingPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserContextActions.updateUserDefaultLandingPage),
      switchMap(action =>
        this.usersService.updateUserDefaultLandingPage(action.defaultLandingPage).pipe(
          map(defaultLandingPage =>
            fromUserContextActions.updateUserDefaultLandingPageSuccess({ defaultLandingPage }),
          ),
          catchError(() => {
            return of(fromUserContextActions.updateUserDefaultLandingPageFailed());
          }),
        ),
      ),
    ),
  );

  authenticate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserContextActions.authenticate),
      map(params => window.location.replace(this.tokenService.redirect(params))),
      catchError((err, caught$) => {
        this.handle(err);
        this.store.dispatch(fromUserContextActions.authFailed());
        return caught$;
      }),
      map(_ => fromUserContextActions.authSuccess()),
    ),
  );

  ssoAuthenticate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserContextActions.ssoAuthenticate),
      map(params => window.location.replace(this.tokenService.redirect(params))),
      catchError(caught$ => {
        this.store.dispatch(fromUserContextActions.authFailed());
        return caught$;
      }),
      map(() => fromUserContextActions.authSuccess()),
    ),
  );

  authTokenExchangeV0$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserContextActions.authTokenExchangeV0),
      switchMap(({ code }) => this.tokenService.ssoV0(code)),
      catchError((err, caught$) => {
        this.handle(err);
        this.store.dispatch(fromUserContextActions.authFailed());
        return caught$;
      }),
      map((loginData: LoginData) => {
        if (loginData.success) {
          return fromUserContextActions.loggedIn({
            loginData: { ...loginData, loginProvider: LoginProvider.Sso },
          });
        }
        return fromUserContextActions.loginFailed({ obj: loginData });
      }),
    ),
  );

  authTokenExchange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserContextActions.authTokenExchange),
      switchMap(({ code }) => this.tokenService.sso(code)),
      catchError((err, caught$) => {
        this.handle(err);
        this.store.dispatch(fromUserContextActions.authFailed());
        return caught$;
      }),
      map((loginResponse: LoginResponse) =>
        fromUserContextActions.contextLoginSuccess({ loginResponse }),
      ),
    ),
  );

  loadFavorites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserContextActions.loadFavorites),
      switchMap(() => {
        return this.userFavoritesService.loadFavorites().pipe(
          map(favorites => {
            return fromUserContextActions.loadFavoritesSuccess({ favorites });
          }),
          catchError(() => {
            return of(fromUserContextActions.loadFavoritesFailure());
          }),
        );
      }),
    ),
  );

  saveFavorites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserContextActions.saveFavorites),
      switchMap(({ features, targetFeature, isPost, operation }) => {
        return this.userFavoritesService.saveFavorites(features, isPost).pipe(
          map(() => {
            this.toastrService.show(
              undefined,
              operation === 'add'
                ? this.translateService.instant('LIBS.FAVORITES.ADD_FAVORITE_SUCCESS')
                : this.translateService.instant('LIBS.FAVORITES.REMOVE_FAVORITE_SUCCESS'),
              {
                toastComponent: ToastSuccessComponent,
              },
            );

            return fromUserContextActions.saveFavoritesSuccess();
          }),
          catchError(() => {
            this.toastrService.error(
              this.translateService.instant('LIBS.FAVORITES.ADD_FAVORITE_ERROR'),
              this.translateService.instant('LIBS.FAVORITES.ADD_FAVORITE_ERROR_TITLE'),
              {
                toastComponent: ToastErrorComponent,
              },
            );

            return of(fromUserContextActions.saveFavoritesFailure({ targetFeature, operation }));
          }),
        );
      }),
    ),
  );

  private handle = (err: any) => {
    if (err.status === this.unauthorizedErrorCode) {
      this.toastrService.error(
        err.error.message,
        this.translateService.instant('COMMON_ERROR_TITLE'),
        { toastComponent: ToastErrorComponent },
      );
    } else {
      this.toastrService.error(
        this.translateService.instant('COMMON_ERROR_TEXT'),
        this.translateService.instant('COMMON_ERROR_TITLE'),
        { toastComponent: ToastErrorComponent },
      );
    }
  };

  private readonly unauthorizedErrorCode = 401;

  constructor(
    private actions$: Actions,
    private loginService: LoginService,
    private cookieService: CookieService,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    private store: Store<AppState>,
    private router: Router,
    private optInService: OptInService,
    private usersService: UsersService,
    private userFavoritesService: UserFavoritesService,
    private tokenService: SingleSignOnService,
  ) {}

  private handleLoginError(loginData: LoginData, isSimplifiedLogin = false): string {
    if (isSimplifiedLogin && !loginData) {
      return 'LOGIN.ERRORS.BANNER.CREDENTIALS_ERROR';
    }

    switch (loginData.errorType) {
      case LoginErrorType.USER_NOT_FOUND:
      case LoginErrorType.WRONG_PASSWORD:
      case LoginErrorType.INTEGRATION:
        return 'CREDENTIALS_ERROR';
      case LoginErrorType.NO_CONDOS:
      case LoginErrorType.NO_CONDOS_FOR_RESIDENT:
      case LoginErrorType.NO_CONDOS_FOR_MANAGER:
        return 'CONTACT_COMMUNITY_ADMINISTRATOR';
      case LoginErrorType.FIRST_LOGIN:
        return 'COMPLETE_SIGN_UP';
      default:
        return 'RELOAD_AND_TRY_AGAIN';
    }
  }
}
