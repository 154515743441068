import { Observable, of } from 'rxjs';

import { mockedUser } from '@tsq-web/users';

import { TSqBaseComment } from '../models/tsq-base-comment.model';
import { TSqCommentPresentation } from '../models/tsq-comment-presentation.model';
import { TSqFeatureDetails } from '../models/tsq-feature-details.model';

export const mockedBaseComment: TSqBaseComment = {
  text: 'mocked-base-comment',
  attachments: [],
};

export const mockedCommentPresentation: TSqCommentPresentation = {
  text: 'mocked-text',
  statusIcon: 'reviews.svg',
  createdBy: mockedUser,
  creationDate: '1676408060000',
  generated: false,
  tags: [],
  attachments: [],
};

export const mockedFeatureDetails: TSqFeatureDetails = {
  id: '123',
  title: 'mocked title feature details',
  description: 'mocked description feature details',
  creationDate: '1676408060000',
  attachments: [],
};

export const mockedCommentsService = {
  editComment: (): Observable<TSqCommentPresentation> => of(mockedCommentPresentation),
  deleteComment: (): Observable<TSqCommentPresentation> => of(mockedCommentPresentation),
  adminDeleteComment: (): Observable<void> => of(void 0),
  adminDeleteCommentAssignments: (): Observable<void> => of(void 0),
};
