import {createAction, props} from '@ngrx/store';

export const setLanguage = createAction(
  '[Localization] Set Language',
  props<{language: string}>(),
);

export const clearLanguage = createAction('[Localization] Clear Language');

export const setTimeZone = createAction(
  '[Localization] Set TimeZone',
  props<{timeZone: string}>(),
);

export const clearTimeZone = createAction('[Localization] Clear Time Zone');
