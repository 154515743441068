import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';

import { AppState } from '@tsq-web/state';
import * as fromUserContextActions from '@tsq-web/user-context/actions';

@Injectable()
export class ContextLoginBypassGuard implements CanActivate {
  constructor(private cookieService: CookieService, private store: Store<AppState>) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const params = route.queryParams;

    if (!!params.l && !!params.contextId) {
      this.cookieService.set('jwt', params.l, null, '/');

      this.store.dispatch(fromUserContextActions.bypassLogin({ contextId: params.contextId }));

      return true;
    } else if (!!params.l || this.cookieService.check('jwt')) {
      const jwt = params.l || this.cookieService.get('jwt');
      this.cookieService.set('jwt', jwt, null, '/');

      this.store.dispatch(fromUserContextActions.bypassLogin());

      return false;
    }

    return true;
  }
}
