/* eslint-disable @typescript-eslint/prefer-enum-initializers */
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { SubscriptionTabs } from '../../../shared/subscription/subscription-tabs.enum';
import { Plan } from '../../../shared/plans/plan.json';
import { FeaturePackage } from '../../../shared/plans/feature-package.json';
import { PaymentInfo } from '../../../shared/subscription/payment-info.json';
import { SubscriptionResponsible } from '../../../shared/subscription/subscription-responsible.json';
import { PaymentInfoType } from '../../../shared/subscription/payment-info-type.enum';
import { FixedCharge } from '../../../shared/subscription/fixed.charge';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'subscription-cart',
  templateUrl: './subscription-cart.component.html',
  styleUrls: ['./subscription-cart.component.scss'],
})
export class SubscriptionCartComponent {
  @Input() customerCart = false;

  @Input() openTab: SubscriptionTabs;
  @Output() openTabChange = new EventEmitter<SubscriptionTabs>();

  @Input() selectedPlan: Plan;
  @Input() selectedPackages: FeaturePackage[];
  @Input() percentage: string;
  @Input() rawValue: string;
  @Input() paymentInfo: PaymentInfo;
  @Input() responsibleForPayment: SubscriptionResponsible;
  @Input() amount: string;
  @Input() cartValid = false;
  @Input() fixedCharges: FixedCharge[] = [];
  @Output() changeSubscriptionClicked = new EventEmitter();
  @Output() cancelSubscriptionClicked = new EventEmitter();

  tabsEnum = SubscriptionTabs;
  paymentInfoTypeEnum = PaymentInfoType;

  isSending = false;

  constructor(private translateService: TranslateService) {}

  changeSubscription(): void {
    this.changeSubscriptionClicked.emit();
  }

  get cardBrandImgName(): string {
    const stripePaymentInfo = this.paymentInfo.stripe;
    if (!!stripePaymentInfo && !!stripePaymentInfo.brand) {
      switch (stripePaymentInfo.brand.toLocaleUpperCase()) {
        case CardBrand[CardBrand.MASTER_CARD]:
          return 'icon-card-MasterCard';
        case CardBrand[CardBrand.VISA]:
          return 'icon-card-Visa';
        case CardBrand[CardBrand.AMERICAN_EXPRESS]:
          return 'icon-card-AmericanExpress';
        default:
          return 'icon-card-generic';
      }
    } else {
      return 'icon-card-generic';
    }
  }

  get planValueString(): string {
    if (this.selectedPlan.multiplier !== 'NONE') {
      return this.translateService.instant('SUBSCRIPTION.PLAN_VALUE_PER', {
        multiplier:
          this.selectedPlan.multiplier === 'UNIT'
            ? this.translateService.instant('SUBSCRIPTION.UNIT')
            : this.translateService.instant('SUBSCRIPTION.USER'),
      });
    }
    return this.translateService.instant('SUBSCRIPTION.PLAN_VALUE');
  }
}

enum CardBrand {
  MASTER_CARD,
  VISA,
  AMERICAN_EXPRESS,
  OTHER,
}
