/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, Input, Renderer2, TemplateRef } from '@angular/core';

import { TSqGalleryFileModel } from '../../models/tsq-gallery-file.model';
import {
  TSqGalleryBottomPreviewTemplateRefContext,
  TSqGalleryTopPreviewTemplateRefContext,
} from '../../models/tsq-gallery-template-ref-context.model';

@Component({
  selector: 'tsq-gallery-preview',
  templateUrl: 'tsq-gallery-preview.component.html',
  styleUrls: ['tsq-gallery-preview.component.scss'],
})
export class TSqGalleryPreviewComponent {
  @Input() files: TSqGalleryFileModel[];
  @Input() topPreviewTemplate: TemplateRef<TSqGalleryTopPreviewTemplateRefContext>;
  @Input() bottomPreviewTemplate: TemplateRef<any>;

  private isOpen: boolean;
  private selectedFileIndex: number;

  constructor(private renderer: Renderer2) {}

  contextClose = () => {
    this.close();
  };

  increment = () => {
    if (this.selectedFileIndex !== this.files.length - 1) {
      this.selectedFileIndex++;
    }
  };

  decrement = () => {
    if (this.selectedFileIndex !== 0) {
      this.selectedFileIndex--;
    }
  };

  get isPreviewOpen(): boolean {
    return this.isOpen;
  }

  get selectedFileToDisplay(): TSqGalleryFileModel {
    return (
      this.files &&
      this.files[
        !!this.selectedFileIndex || this.selectedFileIndex === 0 ? this.selectedFileIndex : 0
      ]
    );
  }

  get topPreviewContext(): TSqGalleryTopPreviewTemplateRefContext {
    return {
      file: this.selectedFileToDisplay,
      fns: {
        close: this.contextClose,
      },
    };
  }

  get bottomPreviewContext(): TSqGalleryBottomPreviewTemplateRefContext {
    return {
      $implicit: this.selectedFileToDisplay,
      fns: {
        increment: this.increment,
        decrement: this.decrement,
      },
    };
  }

  open(index?: number) {
    this.isOpen = true;
    this.selectedFileIndex = index;
    this.renderer.setStyle(document.body, 'overflow', 'hidden');
  }

  close() {
    this.isOpen = false;
    this.selectedFileIndex = undefined;
    this.renderer.removeStyle(document.body, 'overflow');
  }

  onKeyUp(keyboardEvent: KeyboardEvent) {
    if (!!this.files) {
      if (keyboardEvent.key === 'ArrowRight' && this.selectedFileIndex !== this.files.length - 1) {
        this.selectedFileIndex++;
      } else if (keyboardEvent.key === 'ArrowLeft' && this.selectedFileIndex !== 0) {
        this.selectedFileIndex--;
      }
    }
  }
}
