import { NgSwitch, NgSwitchCase } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';

import { LetModule } from '@ngrx/component';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, combineLatest, map } from 'rxjs';

import { TableColumnLink, TableColumnLinkReturnType } from '../../models/table.model';
import { TableCellDummyComponent } from '../table-cell-dummy/table-cell-dummy.component';
import { TableCellLinkExternalComponent } from '../table-cell-link-external/table-cell-link-external.component';
import { TableCellLinkInternalComponent } from '../table-cell-link-internal/table-cell-link-internal.component';

@Component({
  standalone: true,
  imports: [
    NgSwitch,
    NgSwitchCase,
    LetModule,
    TableCellLinkExternalComponent,
    TableCellLinkInternalComponent,
    TableCellDummyComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'tsq-table-cell-link',
  template: `
    <ng-container
      *ngrxLet="{ content: content$, linkConfig: linkConfig$ } as vm"
      [ngSwitch]="vm.linkConfig?.link.type"
    >
      <tsq-table-cell-link-external
        *ngSwitchCase="'external'"
        [align]="content?.align"
        [text]="vm.linkConfig.text"
        [href]="vm.linkConfig.link.href"
        [target]="vm.linkConfig.link.target"
      />

      <tsq-table-cell-link-internal
        *ngSwitchCase="'internal'"
        [align]="content?.align"
        [text]="vm.linkConfig.text"
        [route]="vm.linkConfig.link.route"
        [relativeTo]="vm.linkConfig.link.relative"
        [queryParams]="vm.linkConfig.link.queryParams"
        [queryParamsHandling]="vm.linkConfig.link.queryParamsHandling"
        [fragment]="vm.linkConfig.link.fragment"
        [preserveFragment]="vm.linkConfig.link.preserveFragment"
      />

      <tsq-table-cell-dummy *ngSwitchDefault />
    </ng-container>
  `,
  styles: [
    `
      :host {
        @apply min-w-0;
      }
    `,
  ],
})
export class TableCellLinkComponent<TRowData> {
  private readonly contentSubject = new BehaviorSubject<
    TableColumnLink<TRowData>['content'] | undefined
  >(undefined);
  private readonly rowDataSubject = new BehaviorSubject<TRowData | undefined>(undefined);
  readonly content$ = this.contentSubject.asObservable();

  private readonly translate = inject(TranslateService);

  readonly linkConfig$: Observable<TableColumnLinkReturnType> = combineLatest([
    this.content$,
    this.rowDataSubject.asObservable(),
  ]).pipe(
    map(([content, rowData]) => {
      if (!content || !rowData) {
        return undefined;
      }

      if (typeof content.value === 'function') {
        return content.value(rowData, { translate: this.translate });
      } else if ('key' in content.value && 'transform' in content.value) {
        return content.value.transform(rowData[content.value.key], { translate: this.translate });
      }

      return undefined;
    }),
  );

  @Input()
  set content(value: TableColumnLink<TRowData>['content']) {
    this.contentSubject.next(value);
  }

  @Input()
  set rowData(value: TRowData) {
    this.rowDataSubject.next(value);
  }
}
