<div
  bsModal
  #modal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  [config]="{ backdrop: false, keyboard: false }"
  (onHidden)="modalHiddenFired()"
>
  <div class="modal-dialog unresponsive">
    <div class="modal-content">
      <div class="modal-body">
        <div class="content-header">
          <div class="content-header-back" *ngIf="showBack" (click)="onBack()">
            <tsq-icon
              [icon]="libIcons.chevron"
              [classes]="
                'transform rotate-180 ' + (!enableBack ? 'text-coal-tertiary' : 'text-primary')
              "
            ></tsq-icon>
          </div>

          <div class="content-header-info">
            <div class="content-header-description">
              <div class="feature-icon" *ngIf="!!icon">
                <img [src]="icon" [ngClass]="iconClass" alt="" />
              </div>
              <div class="feature-text">
                <span class="feature-title">{{ title | translate | uppercase }}</span>
                <span class="feature-description" *ngIf="!!description">{{ description }}</span>
              </div>
            </div>

            <div class="content-header-actions">
              <button
                class="btn btn-secondary"
                [disabled]="!enableSecondaryBtn || sending"
                (click)="onClose()"
              >
                <span
                  class="font-bold"
                  [ngClass]="secondaryBtnText === 'CANCEL' ? 'text-red' : 'text-current'"
                  >{{ secondaryBtnText | translate }}</span
                >
              </button>

              <div #actions>
                <ng-content select="[actions]"></ng-content>
              </div>
              <button
                class="btn btn-primary"
                data-cy="fullscreen-modal--button--primary-btn"
                *ngIf="!actions.childNodes.length && !sending"
                [disabled]="!enablePrimaryBtn || sending"
                (click)="onSave()"
              >
                <span class="font-bold">{{ primaryBtnText | translate }}</span>
              </button>

              <tsq-small-loader *ngIf="sending"></tsq-small-loader>
            </div>
          </div>
        </div>

        <div
          #scrollContainer
          [ngClass]="{ 'py-16': hasBodyPadding, 'p-0': !hasBodyPadding }"
          class="content-body overflow-y-auto"
        >
          <ng-content select="[pre-content]"></ng-content>
          <tsq-loader *ngIf="loading; else notLoading"></tsq-loader>
          <ng-template #notLoading>
            <div class="top-info-wrapper">
              <ng-content select="[top-info]"></ng-content>
            </div>
            <div [class]="'common-info-wrapper ' + infoWrapperClass">
              <ng-content></ng-content>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
