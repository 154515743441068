import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { PaymentInfoType } from '../../../shared/subscription/payment-info-type.enum';
import { CommunitiesEnvironments } from '../../../shared/environment/models/communities-environments.enum';

@Component({
  selector: 'comm-payment-info-type-selection',
  templateUrl: './payment-info-type-selection.component.html',
  styleUrls: ['./payment-info-type-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentInfoTypeSelectionComponent {
  @Input() environment: CommunitiesEnvironments;

  @Input() type: PaymentInfoType;
  @Output() typeChange = new EventEmitter<PaymentInfoType>();

  paymentTypes = PaymentInfoType;

  paymentTypeSelected(paymentType: PaymentInfoType): void {
    this.typeChange.emit(paymentType);
  }
}
