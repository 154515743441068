import {NgModule, ModuleWithProviders} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';

import {TrialCondoAuthorizer} from './trial.condo.authorizer';
import {AssociaSignUpGuard} from './associa-sign-up.guard';
import {LoginService} from '@tsq-web/login';

@NgModule({
  providers: [
    TrialCondoAuthorizer,
    CookieService,
    AssociaSignUpGuard,
    LoginService,
  ]
})
export class AuthorizationModule {
  static forRoot(): ModuleWithProviders<AuthorizationModule> {
    return {
      ngModule: AuthorizationModule
    };
  }
}
