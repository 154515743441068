/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { PaymentInfo } from '../../../shared/subscription/payment-info.json';
import { PaymentInfoType } from '../../../shared/subscription/payment-info-type.enum';
import { BoletoBillingChanged } from '../../../shared/subscription/boletoBillingChanged.model';
import { StripePaymentInfo } from '../../../shared/subscription/stripe-payment-info.json';
import { CreditCardBillingSelectionEditComponent } from '../credit-card-billing-selection/credit-card-billing-selection-edit.component';
import { BoletoBillingSelectionEditComponent } from '../boleto-billing-selection/boleto-billing-selection-edit.component';

@Component({
  selector: 'comm-billing-info',
  templateUrl: './billing-info.component.html',
})
export class BillingInfoComponent {
  @ViewChild('brBillingSelection') set brBillingSelection(
    comp: BoletoBillingSelectionEditComponent,
  ) {
    this._brBillingSelection = comp;
  }

  @ViewChild('usBillingSelection') set usBillingSelection(
    comp: CreditCardBillingSelectionEditComponent,
  ) {
    this._usBillingSelection = comp;
    if (this.usBillingSelection) {
      this.usBillingSelection.initUsBillingSelection(this.paymentInfo.stripe);
    }
  }

  @Input() paymentInfo: PaymentInfo;

  @Output() brBillingChanged = new EventEmitter<BoletoBillingChanged>();
  @Output() usBillingChanged = new EventEmitter<StripePaymentInfo>();

  paymentTypes = PaymentInfoType;

  private _brBillingSelection: BoletoBillingSelectionEditComponent;
  private _usBillingSelection: CreditCardBillingSelectionEditComponent;

  get brBillingSelection(): BoletoBillingSelectionEditComponent {
    return this._brBillingSelection;
  }

  get usBillingSelection(): CreditCardBillingSelectionEditComponent {
    return this._usBillingSelection;
  }

  boletoChanged(boletoChange: BoletoBillingChanged): void {
    this.brBillingChanged.emit(boletoChange);
  }

  creditCardChanged(stripePaymentInfo: StripePaymentInfo): void {
    this.usBillingChanged.emit(stripePaymentInfo);
  }
}
