import { Injectable } from '@angular/core';

import { Observable, Subscriber } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable()
export class SCListNotifier {
  private loadingObserver: Subscriber<boolean>;
  private _loadingObservable: Observable<boolean>;

  private loadingMoreObserver: Subscriber<boolean>;
  private _loadingMoreObservable: Observable<boolean>;

  private errorObserver: Subscriber<boolean>;
  private _errorObservable: Observable<boolean>;

  private listSizeObserver: Subscriber<number>;
  private _listSizeObservable: Observable<number>;

  constructor() {
    this._loadingObservable = new Observable<boolean>(
      (observer: Subscriber<boolean>) => (this.loadingObserver = observer),
    ).pipe(debounceTime(200));

    this._listSizeObservable = new Observable<number>(
      (observer: Subscriber<number>) => (this.listSizeObserver = observer),
    );
    this._loadingMoreObservable = new Observable<boolean>(
      (observer: Subscriber<boolean>) => (this.loadingMoreObserver = observer),
    );

    this._errorObservable = new Observable<boolean>(
      (observer: Subscriber<boolean>) => (this.errorObserver = observer),
    );
  }

  get loadingObservable(): Observable<boolean> {
    return this._loadingObservable;
  }

  get loadingMoreObservable(): Observable<boolean> {
    return this._loadingMoreObservable;
  }

  get errorObservable(): Observable<boolean> {
    return this._errorObservable;
  }

  notifyLoadingMore(): void {
    this.loadingMoreObserver.next(true);
  }

  notifyLoadMoreFinished(): void {
    this.loadingMoreObserver.next(false);
  }

  notifyLoading(): void {
    this.loadingObserver?.next(true);
  }

  notifyLoadFinished(): void {
    this.loadingObserver.next(false);
  }

  notifyLoadingPage(page: number): void {
    if (page > 1) {
      this.notifyLoadingMore();
    } else {
      this.notifyLoading();
    }
  }

  notifyLoadPageFinished(listSize: number): void {
    this.loadingObserver.next(false);
    this.loadingMoreObserver.next(false);
    this.notifyListSize(listSize);
    if (this.errorObserver) {
      this.errorObserver.next(false);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  notifyError(error: unknown): void {
    this.loadingObserver.next(false);
    if (this.errorObserver) {
      this.errorObserver.next(true);
    }
  }

  get listSizeObservable(): Observable<number> {
    return this._listSizeObservable;
  }

  notifyListSize(size: number): void {
    this.listSizeObserver.next(size);
  }
}
