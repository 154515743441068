/* eslint-disable @typescript-eslint/naming-convention */
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import * as crypto from 'crypto-js';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';

import { GlobalVariables } from '../shared/globals';
import { initializePendoForAnonymousVisitor } from '../shared/pendo/pendo.utils';
import { PipzService } from '../shared/pipz/pipz.service';
import { RegisterService } from '../shared/user/register.service';
import { RegistrationRequest } from '../shared/user/registration-request.model';
import { RegistrationSearchRequest } from '../shared/user/registration-search-request.model';
import { UserRegister } from '../shared/user/user-register.model';
import { provideRegisterPicUploader } from './register.uploader.provider';

@UntilDestroy()
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sc-user-register',
  templateUrl: './user-register.component.html',
  styleUrls: ['./user-register.component.scss'],
  providers: [
    RegisterService,
    { provide: FileUploader, useFactory: provideRegisterPicUploader, deps: [CookieService] },
  ],
})
export class UserRegisterComponent implements OnInit {
  @ViewChild('profilePicInput') profilePicInput: ElementRef;

  user: UserRegister = new UserRegister();
  picture: string;
  password = '';
  password2 = '';
  error = false;
  globalVariables = GlobalVariables;

  private userId = '';
  private token = '';

  constructor(
    private route: ActivatedRoute,
    private _router: Router,
    private _registerService: RegisterService,
    private _uploader: FileUploader,
    private _cookieService: CookieService,
    private _translateService: TranslateService,
    private toastr: ToastrService,
    private pipzService: PipzService,
  ) {
    this.route.params
      .pipe(untilDestroyed(this))
      .subscribe(params => this.registrationSearch(params));
  }

  ngOnInit(): void {
    this.uploader.onSuccessItem = (item: FileItem, response: string): void => {
      this.onPictureChange(response);
      this.uploader.clearQueue();
    };

    initializePendoForAnonymousVisitor();
  }

  onSubmit(): void {
    const registerRequest = new RegistrationRequest();
    registerRequest.id = this.userId;
    registerRequest.email = this.user.email;
    registerRequest.firstName = this.user.first_name;
    registerRequest.lastName = this.user.last_name;
    registerRequest.billingDocument = this.user.billingDocument;
    registerRequest.hashPassword = crypto.SHA1(this.password).toString();
    registerRequest.registrationToken = this.token;
    registerRequest.pictureName = this.user.pictureName;

    this._registerService.registration(registerRequest).subscribe(
      user => this.onReceiveRegisterInfo(user),
      error => this.onReceiveRegisterError(error),
    );
  }

  isDisabled(): boolean {
    return (
      this.error ||
      !this.user.first_name ||
      this.password.length === 0 ||
      this.password !== this.password2 ||
      (this.user.requireDocument && !this.user.billingDocument)
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFileSelected(event: any): void {
    if (event.target.files?.[0]) {
      // eslint-disable-next-line no-var
      var reader = new FileReader();
      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
      reader.onload = function (e: any) {
        this.picture = e.target.result;
      }.bind(this);
    }
    reader.readAsDataURL(event.target.files[0]);
  }

  get uploader(): FileUploader {
    return this._uploader;
  }

  get greetingBackground(): string {
    return this.isPortuguese ? 'green' : 'green-alternate';
  }

  get loginImage(): string {
    return this.isPortuguese
      ? '../../assets/img/login-image-pt.png'
      : '../../assets/img/login-image-en.png';
  }

  get isPortuguese(): boolean {
    return this._translateService.currentLang === 'pt';
  }

  private onReceiveUserInfo(user: UserRegister): void {
    this.user = user;
    this.picture = this.user.pictureUrl;
    if (user.hasLoggedIn) {
      this._router.navigate([
        'login',
        {
          userId: this.userId,
          token: this.token,
        },
      ]);
    }
  }

  private onReceiveUserError(): void {
    this.error = true;
  }

  private onPictureChange(name: string): void {
    this.user.pictureName = name;
  }

  private onReceiveRegisterInfo(user: UserRegister): void {
    const token = {
      email: user.email,
      logonToken: user.logonToken,
    };
    this._cookieService.set('logonToken', JSON.stringify(token), null, '/');
    this._router.navigate(['']);
    this.pipzService.track('User signed on', user.email);
  }

  private onReceiveRegisterError(error: { error: string }): void {
    this.toastr.error(error.error);
  }

  private registrationSearch(params: Params): void {
    this.userId = params.userId || this.userId;
    this.token = params.token || this.token;

    const request = new RegistrationSearchRequest();
    request.userId = this.userId;
    request.registrationToken = this.token;

    this._registerService.registrationSearch(request).subscribe(
      user => this.onReceiveUserInfo(user),
      () => this.onReceiveUserError(),
    );
  }
}
