import { FeaturePermission } from '@tsq-web/feature';

export class UserGroup {
  id: string;
  name = '';
  description = '';
  color: string;
  icon: string;
  requiresProperty: boolean;
  showGroupNameInUserProfile: boolean;
  userListedInNeighbours: boolean;
  permissions: FeaturePermission[];
  condo: any;
  createdBy: any;
  count: number;

  constructor(id?: string) {
    this.id = id;
    this.permissions = [];
  }
}
