<div class="info-mini-card" [ngClass]="{'extra-small-card': extraSmall, 'col-md-12': extraSmall}">
  <div class="simplified-img auto-cursor-important">
    <img width="30" height="30" src="{{ getAttachmentIconUrl(fileExtension) }}" alt="" class="img-circle">
  </div>
  <div class="info-data info-middle" [class.info-auto]="autoWidth">
    <div class="feed-item-date auto-cursor-important" *ngIf="date !== undefined">
      {{ getDateLabel() | translate:{relative: getRelativeDay(), date: getDateString()} }}
    </div>
    <div class="info-title overflow" [class.info-auto]="autoWidth">
      <a href="{{ fileViewUrl }}" (click)="viewFileClick()" target="_blank">{{ fileName }}</a>
    </div>
    <div class="info-small auto-cursor-important">
      {{ 'SHARED.SIZE_' | translate }} {{ getReadableSize(fileSize) }}
    </div>
  </div>
  <div class="info-picture info-second-picture" [class.info-picture-auto]="autoWidth">
    <a href="{{ fileDownloadUrl }}" (click)="downloadFileClick()" target="_blank">
      <img width="25" height="25" src="./assets/img/icons/download.svg" alt="" class="img-circle">
    </a>
  </div>
</div>
