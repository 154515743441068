import { animate, style, transition, trigger } from '@angular/animations';

export const slideInOut = trigger('slideInOut', [
  transition(':enter', [
    style({
      transformOrigin: 'top',
      transform: 'scaleY(0.5)',
      height: '0px',
      paddingTop: '0px',
      paddingBottom: '0px',
      opacity: 0,
      overflow: 'hidden',
    }),
    animate(
      '200ms ease-in',
      style({
        transform: 'scaleY(1)',
        height: '*',
        paddingTop: '*',
        paddingBottom: '*',
        opacity: 1,
      }),
    ),
  ]),
  transition(':leave', [
    style({
      transformOrigin: 'top',
      height: '*',
      paddingTop: '*',
      paddingBottom: '*',
      opacity: 1,
    }),
    animate(
      '200ms ease-out',
      style({
        transform: 'scaleY(0.5)',
        height: '0px',
        paddingTop: '0px',
        paddingBottom: '0px',
        opacity: 0,
      }),
    ),
  ]),
]);
