/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, Input } from '@angular/core';

@Component({
  selector: 'sc-comment-status',
  templateUrl: 'sc-comment-status.component.html',
  styleUrls: ['sc-comment-status.component.scss'],
})
export class SCCommentStatusComponent {
  @Input() text: string;
  @Input() avatarUrl: string;
  @Input() fineDescription: number;
  @Input() name: string;
  @Input() warning = true;
  @Input() simplified = false;
  @Input() date: string;

  parseDiscussionText(text: string) {
    if (text === undefined) return '';

    return text.replace(/(?:\r\n|\r|\n)/g, '<br />');
  }
}
