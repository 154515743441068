import { TranslateService } from '@ngx-translate/core';

export function enumTransformerToTextValue(
  enumValue: Record<number, string>,
  i18nStringPath: string,
  translateService: TranslateService,
): { text: string; value: string }[] {
  return Object.values(enumValue).map(value => ({
    text: translateService.instant(`${i18nStringPath}${value.toUpperCase()}`),
    value,
  }));
}
