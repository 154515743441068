import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TsqMainInfoCardComponent } from './tsq-main-info-card.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    TsqMainInfoCardComponent,
  ],
  exports: [
    TsqMainInfoCardComponent,
  ],
})

export class TsqMainInfoCardModule {
}
