<div class="sub-header-tabs">
  <div>
    <ul>
      <li *ngFor="let tab of tabs; let index = index" #tabRef>
        <a
          [routerLink]="tab.path"
          [queryParams]="tab.queryParams ?? {}"
          [queryParamsHandling]="tab.queryParamsHandling ?? ''"
          [class.is-active]="selectedIndex === index"
        >
          <img [src]="tab.icon" [class]="tab.iconFilter || ''" alt="Item icon" />
          <span>{{ tab.text | translate }}</span>
        </a>
      </li>
    </ul>

    <div class="selected-tab-marker" [ngStyle]="selectedMarkerStyle"></div>
  </div>
</div>
