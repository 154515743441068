/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { ElementRef, HostListener, Directive } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[autosize]',
})
export class SCTextareaAutosizeDirective {
  @HostListener('input', ['$event.target'])
  onInput(textArea: HTMLTextAreaElement): void {
    this.adjust();
  }

  constructor(public element: ElementRef) {}

  // ngAfterContentChecked(): void {
  //   this.checkEmpty();
  // }

  // checkEmpty(): void {
  //   let hasContent = (this.element.nativeElement.value.trim().length > 0);
  //   // if(!hasContent) {
  //   //   this.element.nativeElement.style.height = '50px';
  //   // }
  // }

  adjust(): void {
    const scrollTop = window.pageYOffset;
    const scrollLeft = window.pageXOffset;
    const height = Math.max(50, this.element.nativeElement.scrollHeight);

    this.element.nativeElement.style.overflowX = 'hidden';
    this.element.nativeElement.style.overflowY = 'scroll';
    this.element.nativeElement.style.height = 'auto';
    this.element.nativeElement.style.height = height + 'px';

    window.scrollTo(scrollLeft, scrollTop);
  }
}
