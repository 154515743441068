import { Injectable, inject } from '@angular/core';

import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, map, mergeMap, switchMap, tap } from 'rxjs';

import { StoreList, storeListInitialState } from '@tsq-web/redux/utils';
import { ToastErrorComponent } from '@tsq-web/toast';

import { Feedback } from '../models/feedback.model';
import { InAppFeedbackSteps } from '../models/in-app-feedback-steps.enum';
import { FeedbackTriggerService } from '../services/feedback-trigger.service';
import { InAppFeedbackService } from '../services/in-app-feedback.service';

export interface InAppFeedbackState {
  activeStep: InAppFeedbackSteps;
  chips: {
    positiveChips: StoreList<string>;
    negativeChips: StoreList<string>;
  };
  feedback: {
    isPosting: boolean;
    error: boolean;
  };
}

const initialState: InAppFeedbackState = {
  activeStep: InAppFeedbackSteps.Rating,
  chips: {
    positiveChips: storeListInitialState,
    negativeChips: storeListInitialState,
  },
  feedback: {
    isPosting: false,
    error: false,
  },
};

@Injectable()
export class InAppFeedbackStore extends ComponentStore<InAppFeedbackState> {
  readonly activeStep$ = this.select(({ activeStep }) => activeStep);

  readonly isFetchingChips$ = this.select(
    ({ chips }) => chips.positiveChips.loading || chips.negativeChips.loading,
  );
  readonly positiveChips$ = this.select(({ chips }) => chips.positiveChips.entities);
  readonly negativeChips$ = this.select(({ chips }) => chips.negativeChips.entities);

  readonly isPosting$ = this.select(({ feedback }) => feedback.isPosting);
  readonly postFeedbackError$ = this.select(({ feedback }) => feedback.error);

  readonly getChips = this.effect((isPositive$: Observable<boolean>) =>
    isPositive$.pipe(
      tap(isPositive => (isPositive ? this.fetchingPositiveChips() : this.fetchingNegativeChips())),
      mergeMap(isPositive =>
        this.inAppFeedbackService.getSuggestionChips(isPositive).pipe(
          tapResponse(
            (chips: string[]) =>
              isPositive
                ? this.fetchPositiveChipsSuccess(chips)
                : this.fetchNegativeChipsSuccess(chips),
            () => {
              this.toastr.error(
                this.translateService.instant('COMMON_ERROR_TEXT'),
                this.translateService.instant('COMMON_ERROR_TITLE'),
                {
                  toastComponent: ToastErrorComponent,
                },
              );

              if (isPositive) {
                this.fetchPositiveChipsFailure();
                return;
              }
              this.fetchNegativeChipsFailure();
            },
          ),
        ),
      ),
    ),
  );

  readonly postFeedback = this.effect((data$: Observable<Omit<Feedback, 'context'>>) =>
    data$.pipe(
      tap(() => this.postingFeedback()),
      map(
        (data): Feedback => ({
          ...data,
          context: this.feedbackTriggerService.currentContext,
        }),
      ),
      switchMap(feedback =>
        this.inAppFeedbackService.postFeedback(feedback).pipe(
          tapResponse(
            () => this.postFeedbackSuccess(),
            () => this.postFeedbackFailure(),
          ),
        ),
      ),
    ),
  );

  private readonly fetchingPositiveChips = this.updater(state => ({
    ...state,
    chips: {
      ...state.chips,
      positiveChips: {
        ...state.chips.positiveChips,
        loading: true,
        error: false,
      },
    },
  }));
  private readonly fetchPositiveChipsSuccess = this.updater((state, entities: string[]) => ({
    ...state,
    chips: {
      ...state.chips,
      positiveChips: {
        ...state.chips.positiveChips,
        entities,
        loading: false,
        error: false,
      },
    },
  }));
  private readonly fetchPositiveChipsFailure = this.updater(state => ({
    ...state,
    chips: {
      ...state.chips,
      positiveChips: {
        ...state.chips.positiveChips,
        loading: false,
        error: true,
      },
    },
  }));

  private readonly fetchingNegativeChips = this.updater(state => ({
    ...state,
    chips: {
      ...state.chips,
      negativeChips: {
        ...state.chips.negativeChips,
        loading: true,
        error: false,
      },
    },
  }));
  private readonly fetchNegativeChipsSuccess = this.updater((state, entities: string[]) => ({
    ...state,
    chips: {
      ...state.chips,
      negativeChips: {
        ...state.chips.negativeChips,
        entities,
        loading: false,
        error: false,
      },
    },
  }));
  private readonly fetchNegativeChipsFailure = this.updater(state => ({
    ...state,
    chips: {
      ...state.chips,
      negativeChips: {
        ...state.chips.negativeChips,
        loading: false,
        error: true,
      },
    },
  }));

  private readonly postingFeedback = this.updater(state => ({
    ...state,
    feedback: {
      ...state.feedback,
      isPosting: true,
      error: false,
    },
  }));
  private readonly postFeedbackSuccess = this.updater(state => ({
    ...state,
    feedback: {
      ...state.feedback,
      isPosting: false,
      error: false,
    },
  }));
  private readonly postFeedbackFailure = this.updater(state => ({
    ...state,
    feedback: {
      ...state.feedback,
      isPosting: false,
      error: true,
    },
  }));

  private readonly inAppFeedbackService = inject(InAppFeedbackService);
  private readonly feedbackTriggerService = inject(FeedbackTriggerService);
  private readonly toastr = inject(ToastrService);
  private readonly translateService = inject(TranslateService);

  constructor() {
    super(initialState);
  }

  goToNextStep(): void {
    let nextStep: InAppFeedbackSteps;

    switch (this.get().activeStep) {
      case InAppFeedbackSteps.Rating:
        nextStep = InAppFeedbackSteps.Sent;
        break;
      case InAppFeedbackSteps.Sent:
        nextStep = InAppFeedbackSteps.Article;
        break;
    }

    this.patchState({ activeStep: nextStep });
  }

  restoreSteps(): void {
    this.patchState({ activeStep: InAppFeedbackSteps.Rating });
  }
}
