<div class="page-container">
  <h1>{{ 'AIS_SIGN_UP.CREATE_LOGIN' | translate }}</h1>
  <p>{{ 'AIS_SIGN_UP.CREATE_LOGIN_CAPTION' | translate }}</p>

  <form [formGroup]="form">
    <div class="form-group">
      <label for="email"
        >{{ 'EMAIL' | translate }}
        <span class="secondary-label"> {{ 'MANDATORY_FIELD' | translate }}</span></label
      >
      <input id="email" class="form-control" formControlName="email" />
    </div>

    <div class="form-group">
      <label for="password"
        >{{ 'AIS_SIGN_UP.PASSWORD' | translate }}
        <span class="secondary-label"> {{ 'MANDATORY_FIELD' | translate }}</span></label
      >
      <i
        class="fa fa-question-circle"
        aria-hidden="true"
        placement="top"
        tooltip="{{ 'AIS_SIGN_UP.PASSWORD_TOOLTIP' | translate }}"
      ></i>
      <input id="password" class="form-control" formControlName="password" type="password" />
    </div>

    <div class="form-group">
      <label for="confirm-password"
        >{{ 'AIS_SIGN_UP.CONFIRM_PASSWORD' | translate }}
        <span class="secondary-label"> {{ 'MANDATORY_FIELD' | translate }}</span></label
      >
      <input
        id="confirm-password"
        class="form-control"
        formControlName="confirmPassword"
        type="password"
      />
    </div>

    <button
      data-cy="ais-link-register--button--link-register"
      class="btn btn-primary"
      *ngIf="!isSending"
      [disabled]="!formValid"
      (click)="linkRegister()"
    >
      {{ 'AIS_SIGN_UP.CONTINUE' | translate }}
    </button>

    <tsq-small-loader *ngIf="isSending"></tsq-small-loader>
  </form>
</div>
