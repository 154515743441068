import { Injectable } from '@angular/core';

import { createEffect, ofType, Actions } from '@ngrx/effects';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import { SubscriptionService } from '../../subscription.service';
import { CondoAndSubscription } from '../../condo-and-subscription.json';
import * as fromSubscriptionAction from './subscription.actions';

@Injectable()
export class SubscriptionEffects {
  loadCondoAndSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSubscriptionAction.loadCondoAndSubscription),
      mergeMap(({ condoId }) =>
        this.subscriptionService.getCondoAndSubscription(condoId).pipe(
          map((condoAndSubscription: CondoAndSubscription) => {
            return fromSubscriptionAction.loadCondoAndSubscriptionSuccess({ condoAndSubscription });
          }),
          catchError(() => {
            return of(fromSubscriptionAction.loadCondoAndSubscriptionFailure());
          })
        )
      )
    )
  );

  constructor(private actions$: Actions, private subscriptionService: SubscriptionService) {}
}
