<div class="label-switch-card">
  <div class="text-label" [ngClass]="{ 'pb-4 pt-8': !!description }">
    {{ label }}
    <ng-content *ngIf="!label" select="label"></ng-content>
    <span
      *ngIf="description"
      class="text-coal-secondary font-regular mb-4 flex text-sm normal-case"
      >{{ description }}</span
    >
  </div>
  <div class="tsq-switch">
    <ng-content select="sc-switch"></ng-content>
    <ng-content select="tsq-switch"></ng-content>
  </div>
</div>
