import { Component, EventEmitter, Input, Output } from '@angular/core';

import { icons } from '@tsq-web/assets';
import { FileMeta } from '@tsq-web/files';
import { getExtensionImage } from '../../utils/get-file-extension-icon/get-file-extension-icon';
import { memo } from '../../utils/memo.utils';

@Component({
  selector: 'tsq-file-list',
  templateUrl: './file-list.component.html',
})
export class FileListComponent {
  @Input() files: FileMeta[];
  @Input() columnView = true;

  @Output() delete = new EventEmitter<number>();

  readonly icons = icons;

  fileExtension = memo(getExtensionImage);

  getFileSizeInHumanFormat(bytes: number): string {
    if (bytes === 0) {
      return '0.00 B';
    }
    const exponent = Math.floor(Math.log(bytes) / Math.log(1024));

    return `${ (bytes / Math.pow(1024, exponent)).toFixed(2) }${ ' KMGTP'.charAt(exponent) }B`;
  }
}
