/* eslint-disable @typescript-eslint/adjacent-overload-signatures */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable @angular-eslint/no-empty-lifecycle-method */

/* eslint-disable @typescript-eslint/no-empty-function */

/* eslint-disable @angular-eslint/component-selector */

/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/explicit-function-return-type */

/* eslint-disable @typescript-eslint/naming-convention */

/* eslint-disable @angular-eslint/no-output-on-prefix */

/* eslint-disable @angular-eslint/relative-url-prefix */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { BaseService } from '@tsq-web/core';

import { CondoAndSubscription } from './condo-and-subscription.json';
import { CondoSubscription } from './condo-subscription.json';
import { PaymentInfo } from './payment-info.json';
import { SubscriptionChargesList } from './subscription-charges-list.json';
import { SubscriptionDetails } from './subscription-details.json';
import { SubscriptionPriceCount } from './subscription-price-count.json';

@Injectable()
export class SubscriptionService extends BaseService {
  constructor(
    protected _cookieService: CookieService,
    protected _http: HttpClient,
    protected _router: Router,
  ) {
    super(_cookieService, _http, _router);
  }

  postSubscriptionDetails(
    condoId: string,
    subscriptionDetails: SubscriptionDetails,
  ): Observable<SubscriptionDetails> {
    let params = new HttpParams();
    params = params.append('condoId', condoId);
    return this.doPost(`subscriptions`, subscriptionDetails, params, 'v1');
  }

  postSubscription(
    condoId: string,
    subscription: CondoSubscription,
  ): Observable<CondoSubscription> {
    let params = new HttpParams();
    params = params.append('condoId', condoId);
    return this.doPost(`subscriptions`, subscription, params, 'v2');
  }

  updateSubscription(condoId: string, subscription: CondoSubscription): Observable<any> {
    let params = new HttpParams();
    params = params.append('condoId', condoId);
    const options = { headers: this.headers, withCredentials: true, params: params };
    return this._http
      .put<CondoSubscription>(BaseService.getFullUrl(`subscriptions`, 'v1'), subscription, options)
      .pipe(catchError(this.handleErrorWithStatus));
  }

  postPaymentInfo(condoId: string, paymentInfo: PaymentInfo): Observable<PaymentInfo> {
    let params = new HttpParams();
    params = params.append('condoId', condoId);
    return this.doPost(`payment-sources`, paymentInfo, params, 'v1');
  }

  updatePaymentInfo(condoId: string, paymentInfo: PaymentInfo): Observable<PaymentInfo> {
    let params = new HttpParams();
    params = params.append('condoId', condoId);
    return this.doPut(`payment-sources`, paymentInfo, params, 'v1');
  }

  getCondoAndSubscription(condoId: string): Observable<CondoAndSubscription> {
    let params = new HttpParams();
    params = params.append('condoId', condoId);
    return this.doGet(`subscriptions`, params, 'v1');
  }

  getSubscriptionCharges(condoId: string): Observable<SubscriptionChargesList> {
    let params = new HttpParams();
    params = params.append('condoId', condoId);
    return this.doGet(`subscriptions/charges`, params, 'v1');
  }

  getSubscriptionPriceCounts(condoId: string): Observable<SubscriptionPriceCount> {
    return this.doGet(`subscriptions/condos/${condoId}`, 'v1');
  }
}
