import { Component, HostBinding } from '@angular/core';

import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

import { icons } from '@tsq-web/assets';

@Component({
  selector: 'tsq-toast-success',
  templateUrl: './toast-success.component.html',
  styleUrls: ['./toast-success.component.scss'],
})
export class ToastSuccessComponent extends Toast {
  @HostBinding('class.ngx-toastr') readonly hasDefaultClass = true;

  readonly libIcons = icons;

  constructor(protected toastrService: ToastrService, public toastPackage: ToastPackage) {
    super(toastrService, toastPackage);
  }

  onRemove(): void {
    this.toastPackage.triggerAction({
      action: 'close',
      title: this.title,
    });
    this.remove();
  }
}
