import { Component, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as crypto from 'crypto-js';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';

import { TSqValidators } from '@tsq-web/forms';
import { LoginData } from '@tsq-web/login';
import { AppState } from '@tsq-web/state';
import { fromUserContextActions, fromUserContextSelectors } from '@tsq-web/user-context';
import { User } from '@tsq-web/users';

import { LoginService } from '../../../shared/user/login.service';
import { AisOwner } from '../../models/ais-owner.json';
import { PublicAisService } from '../../services/public-ais.service';

@UntilDestroy()
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ais-link-register',
  templateUrl: './ais-link-register.component.html',
  styleUrls: ['./ais-link-register.component.scss'],
})
export class AisLinkRegisterComponent implements OnInit {
  readonly successCode = 200;

  form: UntypedFormGroup;
  user: User;

  private _isSending: boolean;
  private linkErrorStrings: string[];

  constructor(
    private publicAisService: PublicAisService,
    private loginService: LoginService,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private store: Store<AppState>,
    private analytics: AngularFireAnalytics,
  ) {
    translateService
      .get([
        'AIS_SIGN_UP.LINK_ERROR_409_1',
        'AIS_SIGN_UP.LINK_ERROR_409_2',
        'AIS_SIGN_UP.LINK_ERROR_409_3',
        'AIS_SIGN_UP.LINK_ERROR_409_4',
        'AIS_SIGN_UP.LINK_ERROR_409_5',
      ])
      .subscribe(res => (this.linkErrorStrings = Object.keys(res).map(key => res[key])));
  }

  ngOnInit(): void {
    this.store
      .pipe(select(fromUserContextSelectors.selectUser), untilDestroyed(this))
      .subscribe(user => (this.user = user));

    const email = !!this.publicAisService.owner.email
      ? this.publicAisService.owner.email.trim()
      : '';
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl(email, [Validators.required, TSqValidators.validEmail]),
      password: new UntypedFormControl('', [
        Validators.required,
        TSqValidators.hasCharDifferentThanWhitespace,
        Validators.minLength(8),
      ]),
      confirmPassword: new UntypedFormControl('', [
        Validators.required,
        TSqValidators.hasCharDifferentThanWhitespace,
        Validators.minLength(8),
      ]),
    });
  }

  get formValid(): boolean {
    return (
      this.form.valid &&
      this.form.controls.password.value === this.form.controls.confirmPassword.value
    );
  }

  get isSending(): boolean {
    return this._isSending;
  }

  set isSending(value: boolean) {
    this._isSending = value;

    if (value) {
      this.form.controls.email.disable();
      this.form.controls.password.disable();
      this.form.controls.confirmPassword.disable();
    } else {
      this.form.controls.email.enable();
      this.form.controls.password.enable();
      this.form.controls.confirmPassword.enable();
    }
  }

  linkRegister(): void {
    this.isSending = true;

    const aisOwner = new AisOwner();
    if (!!this.user) {
      aisOwner.tsUserId = this.user.id;
    }
    aisOwner.firstName = this.publicAisService.owner.firstName;
    aisOwner.lastName = this.publicAisService.owner.lastName;
    aisOwner.email = this.form.controls.email.value;
    aisOwner.password = crypto.SHA1(this.form.controls.password.value).toString();
    aisOwner.phones = this.publicAisService.owner.phones;
    aisOwner.aisAccountUuid = this.publicAisService.selectedAccount.account.entityUuid;
    aisOwner.aisPersonUuid = this.publicAisService.owner.entityUuid;
    aisOwner.aisUnitUuid = this.publicAisService.selectedAccount.account.unitUuid;
    aisOwner.aisCommunityUuid = this.publicAisService.selectedAccount.communityUuid;

    this.publicAisService
      .linkAccount(aisOwner, this.publicAisService.selectedAccount.aisName)
      .pipe(finalize(() => (this.isSending = false)))
      .subscribe(
        (res: { status: number; data: unknown }) => {
          this.analytics.logEvent('invites_signup_linked');
          if (res.status === this.successCode) {
            this.onLinkAccountSuccess(aisOwner);
          } else {
            this.showError(res);
          }
        },
        error => this.showError(error),
      );
  }

  private onLinkAccountSuccess(aisOwner: AisOwner): void {
    const user = new User();
    user.email = aisOwner.email;
    user.password = aisOwner.password;
    user.login_type = 'CMN';
    user.platform = 'webapp';

    this.isSending = true;
    this.loginService
      .loginUser(user)
      .pipe(finalize(() => (this.isSending = false)))
      .subscribe(
        (loginData: LoginData) =>
          this.store.dispatch(fromUserContextActions.loggedIn({ loginData })),
        error => this.showError(error),
      );
  }

  private showError(error: { status: number }): void {
    const toastErrorConfig = {
      enableHtml: true,
      tapToDismiss: false,
      timeOut: 6000,
      extendedTimeOut: 5000,
    };
    switch (error.status) {
      case 204:
        this.toastr.error(
          this.translateService.instant('AIS_SIGN_UP.LINK_ERROR_204'),
          undefined,
          toastErrorConfig,
        );
        break;
      case 404:
        this.toastr.error(
          this.translateService.instant('AIS_SIGN_UP.LINK_ERROR_404'),
          undefined,
          toastErrorConfig,
        );
        break;
      case 409:
        this.toastr.error(
          `${this.linkErrorStrings[0]}<ul>` +
            `<li>${this.linkErrorStrings[1]}</li>` +
            `<li>${this.linkErrorStrings[2]}</li>` +
            `<li>${this.linkErrorStrings[3]}</li>` +
            `</ul><small>${this.linkErrorStrings[4]}</small>`,
          undefined,
          toastErrorConfig,
        );
        break;
      case 500:
        this.toastr.error(
          this.translateService.instant('AIS_SIGN_UP.LINK_ERROR_500'),
          undefined,
          toastErrorConfig,
        );
        break;
      default:
        this.toastr.error(
          this.translateService.instant('AIS_SIGN_UP.LINK_ERROR_DEFAULT'),
          undefined,
          toastErrorConfig,
        );
        break;
    }
  }
}
