/* eslint-disable @angular-eslint/component-selector */
import { Component, EventEmitter, Input, Output, inject } from '@angular/core';

import moment from 'moment-timezone';

import { FacilityCalendarDay } from '../../facility/facility-calendar-day.model';
import { TimeSlot } from '../../facility/timeslot.json';
import { SCCalendarCaptionController } from './sc-calendar-caption.controller';

@Component({
  selector: 'sc-calendar',
  styleUrls: ['./sc-calendar.component.scss'],
  templateUrl: './sc-calendar.component.html',
  providers: [SCCalendarCaptionController],
})
export class SCCalendarComponent {
  @Input() monthWithYear: string;
  @Input() currentDate: moment.Moment;
  @Input() availableFrom: moment.Moment;
  @Input() availableUntil: moment.Moment;
  @Input() enabledWeekdays: number[];
  @Input() slotsForWeekday: Record<number, TimeSlot[]> = {};
  @Input() selectedDate: moment.Moment;
  @Input() blockPeriod: { start: moment.Moment; end: moment.Moment };

  @Output() nextMonthClicked = new EventEmitter<Date>();
  @Output() previousMontClicked = new EventEmitter<Date>();
  @Output() dateClicked = new EventEmitter<moment.Moment>();

  navigationMoment: moment.Moment;
  weekDays: string[] = [];
  weeks: moment.Moment[][];

  readonly initialWeekDay = 0;

  private _calendarDays: FacilityCalendarDay[] = [];

  readonly captionController = inject(SCCalendarCaptionController);

  @Input() set initialDate(date: Date) {
    this.navigationMoment = moment(date);
    this.initializeMonth();
    for (let i = this.initialWeekDay; this.weekDays.length < 7; i++) {
      this.weekDays.push(this.navigationMoment.weekday(i % 7).format('ddd'));
    }
  }

  set calendarDays(days: FacilityCalendarDay[]) {
    this.captionController.reset();
    this._calendarDays = days;
  }

  clearEvents(): void {
    this._calendarDays = [];
  }

  getCalendarDay(date: moment.Moment): FacilityCalendarDay {
    const day = date.format('YYYY-MM-DD');
    return !!this._calendarDays.length
      ? this._calendarDays.find(event => event.day === day)
      : undefined;
  }

  navigateToPreviousMonth(): void {
    this.captionController.reset();
    this.navigationMoment.subtract(1, 'month');
    this.initializeMonth();
    this.previousMontClicked.emit(this.navigationMoment.toDate());
  }

  navigateToNextMonth(): void {
    this.captionController.reset();
    this.navigationMoment.add(1, 'month');
    this.initializeMonth();
    this.nextMonthClicked.emit(this.navigationMoment.toDate());
  }

  onDayClicked(date: moment.Moment): void {
    this.dateClicked.emit(date);
  }

  hasSlotsAndIsAvailable(d: moment.Moment): boolean {
    return !!this.getTimeSlotsForWeekday(d)?.length && this.isAvailable(d);
  }

  isAvailable(d: moment.Moment): boolean {
    return this.isInAvailableRange(d) && this.isEnabledWeekday(d) && !this.isInBlockedRange(d);
  }

  isNavigationMonth(d: moment.Moment): boolean {
    return this.navigationMoment.isSame(d, 'month');
  }

  getTimeSlotsForWeekday(d: moment.Moment): TimeSlot[] {
    const slots = this.slotsForWeekday[d.weekday() + 1];

    return !!slots?.length ? slots : [];
  }

  private isEnabledWeekday(d: moment.Moment): boolean {
    return this.enabledWeekdays.some(weekday => weekday === d.weekday());
  }

  private isInAvailableRange(d: moment.Moment): boolean {
    const dateAfterAvailableFrom =
      this.availableFrom !== undefined
        ? d.isAfter(this.availableFrom, 'day') || d.isSame(this.availableFrom, 'day')
        : true;
    const dateBeforeAvailableUntil =
      this.availableUntil !== undefined
        ? this.availableUntil.isAfter(d, 'day') || d.isSame(this.availableUntil, 'day')
        : true;
    return dateAfterAvailableFrom && dateBeforeAvailableUntil;
  }

  private initializeMonth(): void {
    this.captionController.reset();
    const navMoment = this.navigationMoment.clone();
    const dayIterator = navMoment.clone().startOf('month');
    const monthEnd = navMoment.clone().endOf('month');
    const firstWeekday = dayIterator.weekday();
    let weekDayDelta = firstWeekday;
    if (weekDayDelta < 0) {
      weekDayDelta = weekDayDelta + 7;
    }
    dayIterator.add(-weekDayDelta, 'd');

    this.weeks = [];
    while (!dayIterator.isAfter(monthEnd)) {
      const week: moment.Moment[] = [];
      for (let i = 0; i < 7; i++) {
        week.push(dayIterator.clone());
        dayIterator.add(1, 'd');
      }
      this.weeks.push(week);
    }
  }

  private isInBlockedRange(date: moment.Moment): boolean {
    if (!this.blockPeriod) {
      return false;
    }
    const d = date.valueOf();
    const start = this.blockPeriod.start.valueOf();
    const end = this.blockPeriod.end.valueOf();
    return d >= start && d <= end;
  }
}
