import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '@tsq-web/core';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { User } from '@tsq-web/users';

import { UserTypeaheadRequest } from '../models/user-typeahead-request.model';

@Injectable()
export class TSqUserTypeaheadService extends BaseService {
  constructor(
    protected cookieService: CookieService,
    protected httpClient: HttpClient,
    protected router: Router,
  ) {
    super(cookieService, httpClient, router);
  }

  getUsers(request: UserTypeaheadRequest, search: string): Observable<User[]> {
    let params = request.params || new HttpParams();
    const apiVersion = request.version || undefined;
    const apiService = request.service || undefined;

    if (!!request.searchable) {
      params = params.append('search', search || '');
    }

    if (request.includeWrappers) {
      params = params.append('includeWrappers', request.includeWrappers.toString());
    }

    const options = { headers: this.headers, params };

    const source$ = this.httpClient.get(
      BaseService.getFullUrl(request.url, apiVersion, apiService),
      options,
    );

    if (!!request.listInsideObject) {
      return source$.pipe(map((res: { users: User[] }) => res.users));
    }

    return source$ as Observable<User[]>;
  }
}
