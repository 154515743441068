import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';

import { LetModule } from '@ngrx/component';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, combineLatest, map } from 'rxjs';

import { TooltipTruncatedDirective } from '../../directives/tooltip-truncated/tooltip-truncated.directive';
import { TableColumnAvatar } from '../../models/table.model';
import { TableCellBaseComponent } from '../table-cell-base/table-cell-base.component';

@Component({
  standalone: true,
  imports: [NgIf, LetModule, TableCellBaseComponent, TooltipTruncatedDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'tsq-table-cell-avatar',
  template: `
    <tsq-table-cell-base *ngrxLet="avatar$ as avatar" [tsqTooltipTruncated]="el">
      <ng-container *ngIf="!!avatar?.image">
        <img
          *ngIf="avatar.image.type === 'url'; else initials"
          [src]="avatar.image.url"
          alt="{{ avatar.text }}'s avatar image"
          class="size-24 rounded-full object-cover"
        />

        <ng-template #initials>
          <div
            class="bg-primary-n1 text-primary small-text flex size-24 shrink-0 items-center justify-center rounded-full"
          >
            {{ avatar.image.text }}
          </div>
        </ng-template>
      </ng-container>

      <div class="truncate">
        <div class="text-coal-secondary truncate" #el>{{ avatar?.text }}</div>
        <div *ngIf="!!avatar?.additional" class="small-text text-coal-tertiary -mt-2 truncate">
          {{ avatar?.additional }}
        </div>
      </div>
    </tsq-table-cell-base>
  `,
  styles: [
    `
      :host {
        @apply min-w-0;
      }

      .-mt-2 {
        margin-top: -2px;
      }
    `,
  ],
})
export class TableCellAvatarComponent<TRowData> {
  private readonly contentSubject = new BehaviorSubject<
    TableColumnAvatar<TRowData>['content'] | undefined
  >(undefined);
  private readonly rowDataSubject = new BehaviorSubject<TRowData | undefined>(undefined);

  private readonly translate = inject(TranslateService);

  readonly avatar$: Observable<
    | {
        image:
          | {
              type: 'url';
              url: string;
            }
          | {
              type: 'text';
              text: string;
            }
          | undefined;
        text: string;
        additional?: string;
      }
    | undefined
  > = combineLatest([this.contentSubject.asObservable(), this.rowDataSubject.asObservable()]).pipe(
    map(([content, rowData]) => {
      if (!content || !rowData) {
        return undefined;
      }

      if (typeof content.value === 'function') {
        return content.value(rowData, { translate: this.translate });
      } else if ('key' in content.value && 'transform' in content.value) {
        return content.value.transform(rowData[content.value.key], { translate: this.translate });
      }

      return undefined;
    }),
    map(avatar => {
      if (!avatar) {
        return undefined;
      }

      let image:
        | {
            type: 'url';
            url: string;
          }
        | {
            type: 'text';
            text: string;
          }
        | undefined = undefined;
      switch (avatar.image.type) {
        case 'url':
          image = {
            type: 'url',
            url: avatar.image.url,
          };
          break;
        case 'initials':
          image = {
            type: 'text',
            text: avatar.text
              .split(' ')
              .map(t => t[0].toLocaleUpperCase())
              .join('')
              .slice(0, 2),
          };
          break;
      }

      return {
        image,
        text: avatar.text,
        additional: avatar.additional,
      };
    }),
  );

  @Input()
  set content(value: TableColumnAvatar<TRowData>['content']) {
    this.contentSubject.next(value);
  }

  @Input()
  set rowData(value: TRowData) {
    this.rowDataSubject.next(value);
  }
}
