import { Injectable } from '@angular/core';

import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import { AuthService } from '@tsq-web/auth';
import { Feature } from '@tsq-web/feature';
import { hasFeaturePermission, isFeatureActive, Permission } from '@tsq-web/permission';
import { AppState } from '@tsq-web/state';
import { fromUserContextSelectors } from '@tsq-web/user-context';

@Injectable()
export class CommunitiesAuthService extends AuthService {
  constructor(private store: Store<AppState>) {
    super();
  }

  hasFeatureEnabled(feature: Feature): Observable<boolean> {
    return this.store.pipe(
      select(fromUserContextSelectors.selectCondo),
      map(condo => isFeatureActive(feature, condo)),
    );
  }

  hasPermissionOn(
    feature: Feature,
    permission: Permission,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fn?: (...params: any) => boolean,
  ): Observable<boolean> {
    return combineLatest([
      this.store.pipe(select(fromUserContextSelectors.selectUser)),
      this.store.pipe(select(fromUserContextSelectors.selectCondo)),
    ]).pipe(
      map(([user, condo]) => {
        if (!!fn) {
          return fn(user, feature, permission);
        }

        return hasFeaturePermission(user, condo, feature, permission);
      }),
    );
  }

  hasManagerPermissionInCondo(): Observable<boolean> {
    return combineLatest([
      this.store.pipe(select(fromUserContextSelectors.selectUser)),
      this.store.pipe(select(fromUserContextSelectors.selectCondo)),
    ]).pipe(
      map(([user, condo]) =>
        user.roles.some(role => role.groupId === condo.defaultManagerGroup.id),
      ),
    );
  }

  shouldRefresh(): Observable<boolean> {
    return combineLatest([
      this.store.pipe(select(fromUserContextSelectors.selectUser)),
      this.store.pipe(select(fromUserContextSelectors.selectCondo)),
    ]).pipe(map(() => true));
  }
}
