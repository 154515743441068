/* eslint-disable @angular-eslint/component-selector */

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

import { SCSearchAlertComponent } from '../sc-search-alert/sc-search-alert.component';
import { SCListNotifier } from './sc-list.notifier';

@Component({
  selector: 'sc-list',
  templateUrl: './sc-list.component.html',
  styleUrls: ['./sc-list.component.scss'],
})
export class SCListComponent implements OnInit {
  @ViewChild('searchAlert') searchAlert: SCSearchAlertComponent;
  @ViewChild('listContainer') listContainer: ElementRef;

  @Input() title: string;
  @Input() subtitle: string;
  @Input() caption: string;
  @Input() parentRoute: string;
  @Input() configIcon: string;
  @Input() reportIcon: string;
  @Input() listWidth: number;
  @Input() showConfig = false;
  @Input() showReport = false;
  @Input() showToolbar = true;
  @Input() showSearch = true;
  @Input() searchPlaceholder: string;
  @Input() loading = true;
  @Input() loadingMore = false;
  @Input() error = false;
  @Input() showDivider = false;
  @Input() showHeaderSpace = false;
  @Input() showNavigation = false;
  @Input() panel = true;
  @Input() debounceTime = 400;
  @Input() filterMinLength = 1;
  @Input() entityFilter = '';
  @Input() scrollUp = false;
  @Input() fixedHeight = false;
  @Input() showZeroData = true;

  @Output() scrollevent = new EventEmitter();
  @Output() scrollUpEvent = new EventEmitter();
  @Output() searchevent = new EventEmitter();
  @Output() retryevent = new EventEmitter();
  @Output() configClicked = new EventEmitter();
  @Output() reportClicked = new EventEmitter();
  @Output() removeUserIdFilter = new EventEmitter();

  filterControl = new UntypedFormControl();
  listSize: number;
  userIdFilter = '';

  private parameterSubscription: Subscription;

  private readonly route = inject(ActivatedRoute);
  private readonly notifier = inject(SCListNotifier);

  ngOnInit(): void {
    this.notifier.loadingObservable.subscribe((nextValue: boolean) => (this.loading = nextValue));
    this.notifier.listSizeObservable.subscribe((nextValue: number) => {
      this.listSize = nextValue;
    });
    this.notifier.loadingMoreObservable.subscribe(
      (nextValue: boolean) => (this.loadingMore = nextValue),
    );
    this.notifier.errorObservable.subscribe((nextValue: boolean) => (this.error = nextValue));
    this.filterControl.valueChanges
      .pipe(
        debounceTime(this.debounceTime),
        distinctUntilChanged(),
        filter((value: string) => !value || value.length >= this.filterMinLength),
      )
      .subscribe((f: string) => {
        this.onSearch(f);
      });

    this.parameterSubscription = this.route.params.subscribe(params => {
      if (this.userIdFilter !== params.userId) {
        this.userIdFilter = params.userId;
      }
    });
  }

  @Input() set search(value: string) {
    this.filterControl.setValue(value);
  }

  get searchFor(): string {
    return this.filterControl.value;
  }

  showListContent(): void {
    this.loading = false;
  }

  onRetry(event: any): void {
    this.retryevent.emit(event);
  }

  removeUserFilter(): void {
    this.removeUserIdFilter.emit();
  }

  onSearch(f: string): void {
    this.searchevent.emit(f);
  }

  onScroll(event: any): void {
    if (!this.loading) {
      this.scrollevent.emit(event);
    }
  }

  onScrollUp(event: any): void {
    if (!this.loading) {
      this.scrollUpEvent.emit(event);
    }
  }

  configIconClicked(): void {
    this.configClicked.emit({});
  }

  reportIconClicked(): void {
    this.reportClicked.emit({});
  }

  goToListBottom(): void {
    this.listContainer.nativeElement.scrollTop =
      this.listContainer.nativeElement.scrollHeight - this.listContainer.nativeElement.clientHeight;
  }
}
