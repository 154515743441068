import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

import {
  Condo,
  IntegrationSettingsRequest,
  IntegrationSettingsResponse,
  PaymentSettings,
  Settings,
} from '@tsq-web/condo';
import { HttpService } from '@tsq-web/core';
import { selectUntilDestroyed } from '@tsq-web/redux/operators';
import { AppState } from '@tsq-web/state';
import { fromUserContextSelectors } from '@tsq-web/user-context';

@UntilDestroy()
@Injectable()
export class CondoSettingsService extends HttpService {
  condoId: string;

  constructor(
    protected cookieService: CookieService,
    protected http: HttpClient,
    protected router: Router,
    private store: Store<AppState>,
  ) {
    super();

    store
      .pipe(selectUntilDestroyed(fromUserContextSelectors.selectCondo, this))
      .subscribe(condo => (this.condoId = condo?.id));
  }

  getCondoSettings(condo: Condo): Observable<Settings> {
    return this.http.get<Settings>(this.getFullUrl(`condo-settings/${condo.id}`));
  }

  getCondoIntegrationSettings(): Observable<IntegrationSettingsResponse> {
    return this.http.get<IntegrationSettingsResponse>(
      this.getFullUrl(`condos/${this.condoId}/integration-settings`, 'v1'),
    );
  }

  updateCondoSettings(condo: Condo, settings: Settings): Observable<boolean> {
    return this.http.put<boolean>(this.getFullUrl(`condo-settings/${condo.id}`), settings);
  }

  updateCondoPaymentSettings(
    paymentSettings: PaymentSettings,
  ): Observable<HttpResponse<PaymentSettings>> {
    const options = { ...this.getJsonOptions(), observe: 'response' as const };

    return this.http.put<PaymentSettings>(
      this.getFullUrl(`condos/${this.condoId}/payment-settings`, 'v1'),
      paymentSettings,
      options,
    );
  }

  updateCondoIntegrationSettings(
    integrationSettings: IntegrationSettingsRequest,
  ): Observable<IntegrationSettingsResponse> {
    return this.http.put<IntegrationSettingsResponse>(
      this.getFullUrl(`condos/${this.condoId}/integration-settings`, 'v1'),
      integrationSettings,
    );
  }
}
