import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { BaseService } from '@tsq-web/core';

import { getEnvironment } from '../../shared/environment';
import { AccountNumberRequest } from '../models/account-number-request.model';
import { AisAccountMatch } from '../models/ais-account-match.json';
import { AisAccountRetrieval } from '../models/ais-account-retrieval.json';
import { AisAccountsList } from '../models/ais-accounts-list.json';
import { AisOwner } from '../models/ais-owner.json';
import { AisPerson } from '../models/ais-person.json';

@Injectable()
export class PublicAisService extends BaseService {
  private _aisAccRetrieval: AisAccountRetrieval;
  private _accountsMatch: AisAccountMatch[];
  private _selectedAccount: AisAccountMatch;
  private _owner: AisPerson;

  private _accountNumberRequest = new BehaviorSubject(undefined);

  constructor(
    protected cookieService: CookieService,
    protected http: HttpClient,
    protected router: Router,
  ) {
    super(cookieService, http, router);
  }

  get aisAccRetrieval(): AisAccountRetrieval {
    return this._aisAccRetrieval;
  }

  set aisAccRetrieval(value: AisAccountRetrieval) {
    this._aisAccRetrieval = value;
  }

  get accountsMatch(): AisAccountMatch[] {
    return this._accountsMatch;
  }

  set accountsMatch(value: AisAccountMatch[]) {
    this._accountsMatch = value;
  }

  get selectedAccount(): AisAccountMatch {
    return this._selectedAccount;
  }

  set selectedAccount(value: AisAccountMatch) {
    this._selectedAccount = value;
  }

  get owner(): AisPerson {
    return this._owner;
  }

  set owner(value: AisPerson) {
    this._owner = value;
  }

  get accountNumberRequest$(): Observable<AccountNumberRequest> {
    return this._accountNumberRequest.asObservable();
  }

  accountNumberRequest(value: AccountNumberRequest): void {
    this._accountNumberRequest.next(value);
  }

  retrieveAccounts(
    account: string,
    zip: string,
    firstName: string,
    lastName: string,
    company?: string,
  ): Observable<{ status: number; data: AisAccountsList }> {
    let params = new HttpParams();
    params = params.append('aisAccountId', account);
    params = params.append('zipCode', zip);
    if (!!firstName) {
      params = params.append('firstName', firstName);
    }
    params = params.append('lastName', lastName);
    if (!!company) {
      params = params.append('managementCompanyId', company);
    }
    const options: {
      headers: Record<string, string | string[]> | HttpHeaders;
      params: HttpParams;
      observe: 'response';
    } = { headers: this.headers, params: params, observe: 'response' };
    return this.http
      .get<AisAccountsList>(`${getEnvironment().baseApi}/api/ais/v1/accounts`, options)
      .pipe(map(this.extractDataAndStatus), catchError(this.handleErrorWithStatus));
  }

  linkAccount(aisOwner: AisOwner, aisName: string): Observable<{ status: number; data: unknown }> {
    const params = new HttpParams().append('aisName', aisName);
    const options: {
      headers: Record<string, string | string[]> | HttpHeaders;
      params: HttpParams;
      observe: 'response';
    } = { headers: this.headers, params: params, observe: 'response' };
    return this.http
      .post<undefined>(`${getEnvironment().baseApi}/api/ais/v1/owners`, aisOwner, options)
      .pipe(
        map(this.extractDataAndStatus),
        catchError(err => of(err)),
      );
  }
}
