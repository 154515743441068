import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpService } from '@tsq-web/core';

@Injectable()
export class OptInService extends HttpService {
  private readonly httpClient = inject(HttpClient);

  getTerms(): Observable<void> {
    return this.httpClient.get<undefined>(this.getFullUrl('terms', 'v1'));
  }

  acceptAuthorizedTerms(): Observable<void> {
    return this.httpClient.post<undefined>(this.getFullUrl('terms/accept', 'v1'), undefined);
  }

  acceptUnauthorizedTerms(userId: string): Observable<void> {
    const params = new HttpParams({ fromObject: { userId } });

    return this.httpClient.post<undefined>(
      this.getFullUrl('terms/unauthorized/accept', 'v1'),
      undefined,
      this.getJsonOptions({ params }),
    );
  }
}
