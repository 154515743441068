/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { shareReplay } from 'rxjs/operators';

import { BaseService } from '@tsq-web/core';
import { Plan } from './plan.json';
import { FeaturePackage } from './feature-package.json';
import { PlansList } from './plans-list.json';
import { FeaturePackagesList } from './feature-packages-list.json';
import { DefaultConfiguration } from './default-configuration.json';

@Injectable()
export class PlanService extends BaseService {
  private cache$: Observable<PlansList>;
  private lastComAvailable: boolean;

  constructor(
    protected cookieService: CookieService,
    protected http: HttpClient,
    protected router: Router,
  ) {
    super(cookieService, http, router);
  }

  getPlans(condoId?: string, commerciallyAvailable?: boolean) {
    if (!this.cache$) {
      this.lastComAvailable = commerciallyAvailable;
      this.cache$ = this.requestPlansList(condoId, commerciallyAvailable).pipe(shareReplay(1));
    }
    if (this.lastComAvailable !== commerciallyAvailable) {
      this.lastComAvailable = commerciallyAvailable;
      this.cache$ = this.requestPlansList(condoId, commerciallyAvailable).pipe(shareReplay(1));
    }
    return this.cache$;
  }

  createPlan(plan: Plan): Observable<Plan> {
    return this.doPost('plans', plan, 'v1');
  }

  updatePlan(plan: Plan): Observable<Plan> {
    return this.doPut(`plans/${plan.id}`, plan, 'v1');
  }

  deletePlan(planId: string): Observable<any> {
    return this.doDelete(`plans/${planId}`, 'v1');
  }

  getFeaturePackages(): Observable<FeaturePackagesList> {
    return this.doGet('feature-packages', 'v1');
  }

  createFeaturePackage(featurePackage: FeaturePackage): Observable<FeaturePackage> {
    return this.doPost('feature-packages', featurePackage, 'v1');
  }

  updateFeaturePackage(featurePackage: FeaturePackage): Observable<FeaturePackage> {
    return this.doPut(`feature-packages/${featurePackage.id}`, featurePackage, 'v1');
  }

  deleteFeaturePackage(packageId: string): Observable<any> {
    return this.doDelete(`feature-packages/${packageId}`, 'v1');
  }

  getSelfServiceConfiguration(): Observable<DefaultConfiguration> {
    return this.doGet('subscriptions/configuration', 'v1');
  }

  getDefaultConfigurations(): Observable<DefaultConfiguration[]> {
    return this.doGet('subscriptions/configuration', 'v2');
  }

  updateSelfServiceConfiguration(
    selfServiceConfig: DefaultConfiguration,
  ): Observable<DefaultConfiguration> {
    return this.doPut('subscriptions/configuration/self-service', selfServiceConfig, 'v2');
  }

  updateDashboardConfiguration(
    selfServiceConfig: DefaultConfiguration,
  ): Observable<DefaultConfiguration> {
    return this.doPut('subscriptions/configuration/dashboard', selfServiceConfig, 'v2');
  }

  private requestPlansList(condoId?: string, commerciallyAvailable?: boolean) {
    let params = new HttpParams();
    if (commerciallyAvailable) {
      params = params.append('condoId', condoId);
      params = params.append('commerciallyAvailable', 'true');
    }
    const options = { headers: this.headers, withCredentials: true, params: params };
    return this.http.get<PlansList>(BaseService.getFullUrl(`plans`, 'v1'), options);
  }
}
