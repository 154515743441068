import { Component, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';

import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import IMask from 'imask';

@UntilDestroy()
@Component({
  selector: 'comm-community-document-number',
  templateUrl: './community-document-number.component.html',
  styleUrls: ['./community-document-number.component.scss'],
})
export class CommunityDocumentNumberComponent implements OnInit, ControlValueAccessor {
  documentNumber: UntypedFormControl = new UntypedFormControl();

  cpfCnpjMask: IMask.AnyMaskedOptions = {
    mask: [
      {
        mask: '000.000.000-00',
        lazy: false,
      },
      {
        mask: '00.000.000/0000-00',
        lazy: false,
      },
    ],
  };

  private onChanged: (value: string) => void;
  private onTouched: () => void;

  constructor(@Optional() @Self() public ngControl: NgControl) {
    ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    this.documentNumber.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged(), untilDestroyed(this))
      .subscribe(value => this.onChangeInput(value));
  }

  writeValue(value: string): void {
    this.documentNumber.setValue(value);
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  onChangeInput(value: string): void {
    this.onChanged(this.sanitizeString(value));
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.documentNumber.disable();
    } else {
      this.documentNumber.enable();
    }
  }

  private sanitizeString(raw: string): string {
    return !!raw ? raw.replace(/[.\s\-_()/]+/g, '') : '';
  }
}
