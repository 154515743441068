import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SCWidgetModule } from '../shared/component/sc.widget.module';
import { TranslateModule } from '@ngx-translate/core';
import { userEmailChangeRoutes } from './user-email-change.routes';
import { UserEmailChangeComponent } from './user-email-change.component';
import { UserService } from '../shared/user/user.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SCWidgetModule,
    RouterModule.forChild(userEmailChangeRoutes),
    TranslateModule,
  ],
  declarations: [UserEmailChangeComponent],
  exports: [UserEmailChangeComponent],
  providers: [UserService],
})
export class UserEmailChangeModule {}
