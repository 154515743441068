<div class="font-base flex flex-col gap-32 p-16 pt-56">
  <h1 class="article-heading">{{ 'IN_APP_FEEDBACK.WHAT_IS_TOWNSQ_ROLE' | translate }}</h1>

  <div class="bg-petro-n2 mt-8 h-4 w-72"></div>

  <div class="flex flex-col gap-16">
    <tsq-icon [icon]="icons.house" [classes]="'h-32 w-32'" />
    <h2 class="text-xl">{{ 'IN_APP_FEEDBACK.IS_TOWNSQ_A_MGMT_COMPANY' | translate }}</h2>
    <p class="mb-0">{{ 'IN_APP_FEEDBACK.IS_TOWNSQ_A_MGMT_COMPANY_BODY' | translate }}</p>
  </div>

  <div class="bg-petro-n2 mt-8 h-4 w-72"></div>

  <div class="flex flex-col gap-16">
    <tsq-icon [icon]="icons.questionCircleFill" [classes]="'h-32 w-32'" />
    <h2 class="text-xl">{{ 'IN_APP_FEEDBACK.HOW_DOES_TOWNSQ_WORK' | translate }}</h2>
    <p class="mb-0">{{ 'IN_APP_FEEDBACK.HOW_DOES_TOWNSQ_WORK_BODY' | translate }}</p>
  </div>

  <div class="bg-petro-n2 mt-8 h-4 w-72"></div>

  <div class="flex flex-col gap-16">
    <tsq-icon [icon]="icons.messageExclamation" [classes]="'h-32 w-32'" />
    <h2 class="text-xl">{{ 'IN_APP_FEEDBACK.WHY_WASNT_MY_REQUEST_ADDRESSED' | translate }}</h2>
    <p class="mb-0">{{ 'IN_APP_FEEDBACK.WHY_WASNT_MY_REQUEST_ADDRESSED_BODY' | translate }}</p>
  </div>
</div>
