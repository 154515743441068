import { Administrator } from '@tsq-web/administrator';
import { AdministratorFeatures } from '@tsq-web/administrator';
import { UpsellSettings } from '@tsq-web/condo';
import { UserGroup } from '@tsq-web/groups';

import { Address } from './address.model';
import { FeaturesStatuses } from './features-statuses.model';
import { PaymentSettings } from './payment-settings.model';
import { Settings } from './settings.model';
import { Step } from './step.model';

export class Condo {
  id: string;
  name: string;
  address: Address = new Address();
  timeZone: string;
  propertyType: string;
  groupType: string;
  deleted = false;
  demo = false;
  inDebitMessage: string;
  userCount: number;
  administrator: Administrator;
  admId = '';
  admPictureURL = '';
  admUrl = '';
  admName: string;
  featuresStatuses: FeaturesStatuses;
  settings: Settings;
  paymentSettings: PaymentSettings;
  organizationId: string;
  demoUserFirstName: string;
  demoUserLastName: string;
  demoUserEmail: string;
  demoExpireDate: string;
  defaultOwnerGroup: UserGroup;
  defaultManagerGroup: UserGroup;
  defaultAdminGroup: UserGroup;
  defaultFamilyMemberGroup: UserGroup;
  defaultTenantGroup: UserGroup;
  adminFeatures: AdministratorFeatures = new AdministratorFeatures();
  upsellSettings: UpsellSettings;
  steps: Step;
  isEasyOnboarding: boolean;
}
