import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'comm-login-info-banner',
  templateUrl: './login-info-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginInfoBannerComponent {
  reloadPage(): void {
    location.reload();
  }
}
