import { UpsellSettings } from 'libs/condo/models/upsell-settings.model';
import { Condo } from '../models/condo.model';
import { FeaturesStatuses } from '../models/features-statuses.model';
import { Settings } from '../models/settings.model';
import { Address } from '../models/address.model';

export const mockedCondo: Condo = {
  id: 'condo-id',
  name: 'Condo Name',
  timeZone: 'America/Sao_Paulo',
  featuresStatuses: new FeaturesStatuses(),
  settings: new Settings(),
  address: new Address(),
} as Condo;

export const mockedAddress: Address = {
  street: 'Mocked Street',
  number: '554',
  neighborhood: 'Mocked Neighborhood',
  city: 'Mocked City',
  state: 'Mocked State',
  country: 'BRASIL',
  zipCode: '55555',
  location: [],
};

export const mockedSettings: Settings = {
  maxLobbyCarsPerUnit: 0,
  authToDateUnlimitedEnable: true,
  authCarEnable: true,
  signatureRequiredForPackagePickup: true,
  carRequestEnabled: false,
  discoverEnabled: false,
};

