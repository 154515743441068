import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpService } from '@tsq-web/core';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { TypeaheadDataSource, TypeaheadItem } from '@tsq-web/forms/models';
import { selectUntilDestroyed } from '@tsq-web/redux/operators';
import * as fromUserContextSelectors from '@tsq-web/user-context/selectors';

import { DefaultLandingPages } from '../models/default-landing-pages.enum';
import { User } from '../models/user.model';
import { getUserFullName } from '../utils/user.utils';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class UsersService extends HttpService implements TypeaheadDataSource<User> {
  private condoId: string;
  private userId: string;

  private readonly httpClient = inject(HttpClient);
  private readonly store = inject(Store);

  constructor() {
    super();

    this.store
      .pipe(selectUntilDestroyed(fromUserContextSelectors.selectCondo, this))
      .subscribe(condo => (this.condoId = condo?.id || ''));
    this.store
      .pipe(selectUntilDestroyed(fromUserContextSelectors.selectUser, this))
      .subscribe(user => (this.userId = user?.id));
  }

  getEntities(search: string): Observable<TypeaheadItem<User>[]> {
    let params = new HttpParams();

    params = params.append('condoId', this.condoId);
    params = params.append('search', search);

    return this.httpClient
      .get<User[]>(this.getFullUrl(`user/condo/${this.condoId}`), this.getJsonOptions({ params }))
      .pipe(
        map((users: User[]) =>
          users.map(user => ({
            text: getUserFullName(user),
            value: user,
          })),
        ),
      );
  }

  getUser(userId: string): Observable<User> {
    return this.httpClient.get<User>(this.getFullUrl(`users/${userId}`, 'v1'));
  }

  getUserFromCurrentCondo(userId: string): Observable<User> {
    let params = new HttpParams();
    if (this.condoId) {
      params = params.append('condoId', this.condoId);
    }
    return this.httpClient.get<User>(
      this.getFullUrl(`users/${userId}`, 'v1'),
      this.getJsonOptions({ params }),
    );
  }

  updateUserDefaultLandingPage(
    defaultLandingPage: DefaultLandingPages,
  ): Observable<DefaultLandingPages> {
    return this.httpClient
      .patch<User>(this.getFullUrl(`users/${this.userId}/default-landing-page`, 'v1'), {
        defaultLandingPage,
      })
      .pipe(map(user => user.defaultLandingPage));
  }
}
