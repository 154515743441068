<tsq-center-modal
  [hasHeader]="false"
  [isOpen]="isOpen"
  [disableBackdrop]="loading"
  [backdropAction]="backdropAction"
>
  <div modal-content class="confirmation-content">
    <img src="{{ imgUrl }}" alt="" />
    <div id="content">
      <p [innerHtml]="message | translate" *ngIf="!!message"></p>
      <div class="tsq-scroll-y">
        <ng-content select=".confirmation-content"></ng-content>
      </div>
    </div>
  </div>
  <div modal-footer class="actions">
    <button class="btn white-btn" [disabled]="loading" (click)="cancelAction()">
      {{ cancelLabel | translate }}
    </button>
    <button class="btn btn-primary" *ngIf="!loading" (click)="confirmAction()">
      {{ confirmationLabel | translate }}
    </button>
    <div *ngIf="loading">
      <tsq-small-loader></tsq-small-loader>
    </div>
  </div>
</tsq-center-modal>
