/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'tsq-pager-bullet',
  templateUrl: 'tsq-pager-bullet.component.html',
  styleUrls: ['tsq-pager-bullet.component.scss'],
})
export class TsqPagerBulletComponent {
  @Input() selected: number;
  @Output() selectedChange = new EventEmitter<number>();

  @Input() bulletsPositionY: 'top' | 'bottom' = 'bottom';
  @Input() bulletsPositionX: 'left' | 'center' | 'right' = 'center';

  @Input() backgroundColor = '#f4f4f4';
  @Input() padding = '15px';
  @Input() borderRadius = '0';

  @Input() showIfSingle = false;

  arrayRef = Array;
  private bulletsListSize: number;

  @Input() set bullets(size: number) {
    this.bulletsListSize = size;
  }

  get bullets(): number {
    return this.bulletsListSize;
  }

  get paddingForCard() {
    return this.bullets === 1 && !this.showIfSingle ? '15px' : this.padding;
  }
}
