/* eslint-disable @typescript-eslint/naming-convention */
export const GlobalVariables = Object.freeze({
  FILE_TYPES_IMAGES: ['image/png', 'image/gif', 'image/jpeg'],
  FILE_TYPES_IMPORT: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
  FILE_TYPES_DOCS: [
    'image/png',
    'image/gif',
    'image/jpeg',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.presentationml.slide',
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
    'application/vnd.openxmlformats-officedocument.presentationml.template',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    'application/msword',
    'application/vnd.ms-word.document.macroenabled.12',
    'application/vnd.ms-word.template.macroenabled.12',
    'application/vnd.ms-excel',
    'application/vnd.ms-powerpoint',
    'text/plain',
  ],
});
