import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Administrator } from '@tsq-web/administrator';
import { Condo } from '@tsq-web/condo';
import { UserContextState, UserState } from '@tsq-web/user-context';

export const selectUserContextState = createFeatureSelector<UserContextState>('userContext');

export const selectUserState = createSelector(
  selectUserContextState,
  (userContextState: UserContextState) => userContextState?.userState,
);

export const selectUser = createSelector(
  selectUserState,
  (userState: UserState) => userState?.user,
);

export const selectUserCondos = createSelector(
  selectUserState,
  (userState: UserState) => userState.condos,
);

export const selectLoginContexts = createSelector(
  selectUserContextState,
  (userContextState: UserContextState) => userContextState.contexts.entities,
);

export const selectLoginContextsLoading = createSelector(
  selectUserContextState,
  (userContextState: UserContextState) => userContextState.contexts.loading,
);

export const selectLoginContextsLoadingMore = createSelector(
  selectUserContextState,
  (userContextState: UserContextState) => userContextState.contexts.loadingMore,
);

export const selectLoginContextsError = createSelector(
  selectUserContextState,
  (userContextState: UserContextState) => userContextState.contexts.error,
);

export const selectLoginContextsPage = createSelector(
  selectUserContextState,
  (userContextState: UserContextState) => userContextState.contexts.page,
);

export const selectLoginContextsAllLoaded = createSelector(
  selectUserContextState,
  (userContextState: UserContextState) => userContextState.contexts.allLoaded,
);

export const selectCondo = createSelector(
  selectUserContextState,
  (userContextState: UserContextState) => userContextState?.condo,
);

export const selectCondoTimeZone = createSelector(
  selectCondo,
  (condo: Condo) => condo && condo.timeZone,
);

export const selectCondoAdministrator = createSelector(
  selectCondo,
  (condo: Condo) => condo && condo.administrator,
);

export const selectCondoHasFinancial = createSelector(
  selectCondoAdministrator,
  (administrator: Administrator) =>
    administrator && administrator.features && administrator.features.financial,
);

export const selectCondoPaymentEnabled = createSelector(
  selectCondo,
  (condo: Condo) => condo.paymentSettings?.archReviewPaymentEnabled,
);

export const selectCondoAlternativePaymentEnabled = createSelector(
  selectCondo,
  (condo: Condo) => condo.paymentSettings?.archReviewAlternativePaymentEnabled,
);

export const selectCondoPaymentInstructions = createSelector(
  selectCondo,
  (condo: Condo) => condo.paymentSettings?.paymentInstructions,
);

export const selectUserLoggingIn = createSelector(
  selectUserContextState,
  (userContextState: UserContextState) => userContextState.login.loggingIn,
);

export const selectUserLoggingOut = createSelector(
  selectUserContextState,
  (userContextState: UserContextState) => userContextState.login.loggingOut,
);

export const selectIntroBanners = createSelector(
  selectUserContextState,
  (userContextState: UserContextState) => userContextState.introBanners,
);

export const selectIsFirstLogin = createSelector(
  selectUserState,
  (userState: UserState) => userState.isFirstLogin,
);

export const selectAcceptedTerms = createSelector(
  selectUserState,
  (userState: UserState) => userState.acceptedTerms.entity,
);

export const selectUserTermsLoading = createSelector(
  selectUserState,
  (userState: UserState) => userState.acceptedTerms.loading,
);

export const selectFavorites = createSelector(
  selectUserContextState,
  (userContextState: UserContextState) => userContextState?.userFavorites,
);

export const selectLoginError = createSelector(
  selectUserContextState,
  (userContextState: UserContextState) => userContextState.login.error,
);

export const selectLoginProvider = createSelector(
  selectUserContextState,
  (userContextState: UserContextState) => userContextState.userState.loginProvider,
);

export const selectSsoLoginError = createSelector(
  selectUserContextState,
  (userContextState: UserContextState) => userContextState.ssoLogin.error,
);

export const selectSsoAuthenticateLoading = createSelector(
  selectUserContextState,
  (userContextState: UserContextState) => userContextState.ssoLogin.loading,
);
