/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sc-public-container',
  templateUrl: './sc-public-container.component.html',
  styleUrls: ['./sc-public-container.component.scss'],
  providers: [],
})
export class SCPublicContainerComponent {
  @Input() shouldShowBannerOverlay = false;
  @Input() greetingBgColor = 'green';

  constructor(private _translateService: TranslateService) {}

  getReferralLink(): string {
    return 'http://condominio.townsq.com.br/indiqueeganhetownsq';
  }

  get isPortuguese(): boolean {
    return this._translateService.currentLang === 'pt';
  }
}
