import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

import { BaseService } from '@tsq-web/core';

@Injectable()
export class NeighborMassInviteRequestsService extends BaseService {
  constructor(
    protected router: Router,
    protected cookieService: CookieService,
    protected httpClient: HttpClient,
  ) {
    super(cookieService, httpClient, router);
  }

  sendInvites(emails: string[], condoId: string): Observable<void> {
    const params = new HttpParams({ fromObject: { condoId: condoId } });

    return this.doPost('neighbor-invite/onboard', { emails }, params, 'v1');
  }
}
