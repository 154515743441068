import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { AngularFireRemoteConfig } from '@angular/fire/compat/remote-config';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'comm-join-community-card',
  templateUrl: './join-community-card.component.html',
  styleUrls: ['./join-community-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JoinCommunityCardComponent implements OnInit {
  @Input() loginSimplificationEnabled = false;
  @Input() showLinkExpiredBanner;
  @Input() ssoEnabled = false;

  @Output() goToLogin = new EventEmitter<void>();
  @Output() goToLoginWithSso = new EventEmitter<void>();
  @Output() goToSignUp = new EventEmitter<void>();
  @Output() goToSignUpWithEmail = new EventEmitter<void>();
  @Output() closeLinkExpiredBanner = new EventEmitter<void>();
  @Output() sendInfoClick = new EventEmitter<void>();

  buttonSchema = 'primary';
  appName = 'TownSq';

  private readonly remoteConfig = inject(AngularFireRemoteConfig);
  private readonly translateService = inject(TranslateService);

  readonly showSignUpByEmail$ = this.remoteConfig.booleans.signUpByEmail;

  ngOnInit(): void {
    this.setSchema();
    this.setAppName();
  }

  private setSchema(): void {
    this.buttonSchema = this.loginSimplificationEnabled ? 'new-primary' : 'primary';
  }

  private setAppName(): void {
    this.appName =
      this.loginSimplificationEnabled && this.translateService.currentLang !== 'pt'
        ? 'Community'
        : 'TownSq';
  }
}
