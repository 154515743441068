<div class="tsq-card">
  <div class="att-image">
    <img [src]="'./assets/img/icons/' + extensionImage + '.svg'" alt="" />
    <span>{{ extension | uppercase }}</span>
  </div>

  <div class="vertical-divider"></div>

  <div class="att-info">
    <div (click)="onTitleClick()" [class.pointer-cursor]="!!titleLink">
      {{ attachment.name }}
    </div>
    <div>{{ attachment.size | formatBytes: 2 }}</div>
  </div>

  <div class="att-action">
    <img
      [src]="'./assets/img/icons/' + iconImage + '.svg'"
      alt=""
      class="pointer-cursor"
      (click)="actionClicked(); $event.stopPropagation()"
    />
  </div>
</div>
