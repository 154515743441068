<div class="tsq-profile-dropdown pointer-cursor"
     *ngIf="!!user" (click)="openProfileDropdown()">
  <img src="{{user.pictureUrl}}" class="img-circle" alt="user-image">
  <div>
    <div class="profile-dropdown-name" id="profile--text--name">{{user.first_name + ' ' + user.last_name}}</div>
    <div *ngIf="!!subtitle" class="profile-dropdown-subtitle">{{subtitle}}</div>
  </div>
  <i class="fa fa-angle-down" aria-hidden="true"></i>
</div>

<div class="tsq-profile-dropdown-open"
     *ngIf="!!user && open" #dialogContainer [@dumbParent]>
  <div class="profile-info pointer-cursor" (click)="closeProfileDropdown()" #dialog [@dropdownFadeOut]>
    <div class="tsq-profile-dropdown">
      <img src="{{user.pictureUrl}}" class="img-circle" alt="user-image">
      <div>
        <div class="profile-dropdown-name">{{user.first_name + ' ' + user.last_name}}</div>
        <div *ngIf="!!condo" class="profile-dropdown-subtitle">{{condo.name}}</div>
      </div>
      <i class="fa fa-angle-down" aria-hidden="true"></i>
    </div>
    <div class="divider"></div>
    <div class="tsq-dropdown-content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
