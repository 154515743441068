<ng-template #userTemplate let-model="item" let-index="index">
  <div class="info-mini-card" data-cy="typeahead-result">
    <div class="simplified-img">
      <img class="img-circle" src="{{ model.pictureUrl }}" alt="" />
    </div>
    <div class="info-data">
      <div
        class="info-title"
        [innerHTML]="model.first_name + ' ' + model.last_name | tsqSearchHighlight : searchedUser"
      ></div>
      <div class="user-properties">
        <div *ngIf="model.properties.length === 0">
          {{ 'PROPERTY.NO_PROPERTIES' | translate }}
        </div>
        <div
          *ngFor="let property of model.properties"
          class="info-small"
          [innerHTML]="property.fine_description | tsqSearchHighlight : searchedUser"
        ></div>
      </div>
    </div>
  </div>
</ng-template>
<div class="form-group">
  <label for="user-in-name-of">{{ fieldLabel }}</label>
  <input
    class="form-control {{ getInvalidClass() }}"
    id="user-in-name-of"
    [ngStyle]="inputStatusParam"
    autocomplete="off"
    [(ngModel)]="searchedUser"
    [typeahead]="dataSource"
    [typeaheadItemTemplate]="userTemplate"
    (typeaheadOnSelect)="onUserSelected($event)"
    [typeaheadOptionsLimit]="8"
    typeaheadOptionField="name"
    (keyup)="deselectUser()"
    [ngModelOptions]="{ standalone: true }"
  />
</div>
<div *ngIf="searchedUserEntity">
  <sc-user-bio id="user-bio" [user]="searchedUserEntity" #userBio></sc-user-bio>
</div>
