<div id="container">
  <div class="panel-transparent panel">
    <div class="panel-heading">
      <h1>{{ 'SUBSCRIPTION.CREDIT_CREDENTIALS' | translate }}</h1>
    </div>

    <div class="panel-body" [hidden]="!!stripePaymentInfo && !!stripePaymentInfo.lastFour">
      <div id="card"></div>
      <span *ngIf="!!error">{{ error }}</span>
      <span *ngIf="serviceError">{{ 'SUBSCRIPTION.GET_CARD_TOKEN_ERROR' | translate }}</span>
      <div id="loader" *ngIf="waitingToken">
        <tsq-small-loader></tsq-small-loader>
      </div>
    </div>

    <div class="panel-body" [hidden]="!(!!stripePaymentInfo && !!stripePaymentInfo.lastFour)">
      <div id="card-info">
        <div class="flex">
          <img src="./assets/img/icons/{{ cardBrandImgName }}.png" alt="" />
          <span>•••• •••• •••• {{ stripePaymentInfo ? stripePaymentInfo.lastFour : '' }}</span>
        </div>
        <button class="btn white-btn" (click)="emitCardUndefined(); clearStripeInput()">
          <img src="./assets/img/icons/icon-remove-orange.svg" alt="" />
        </button>
      </div>
    </div>
  </div>
</div>
