import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, pluck } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { AppState } from '@tsq-web/state';
import { TSqTemplatePortal } from '../models/template-portal';
import { ModalContext } from '../models/modal-context';
import { modalInitialState, ModalState } from '../models/modal.state';
import { ModalTypes } from '../models/modal-types.enum';

@Injectable({ providedIn: 'root' })
export class ModalService {
  private stateSubject$ = new BehaviorSubject<ModalState>(modalInitialState);
  private closedSubject$ = new Subject<ModalTypes>();

  constructor(private store: Store<AppState>) {}

  get state$(): Observable<ModalState> {
    return this.stateSubject$.asObservable();
  }

  get open$(): Observable<boolean> {
    return this.selectFromState('open');
  }

  get closed$(): Observable<ModalTypes> {
    return this.closedSubject$.asObservable();
  }

  get type$(): Observable<ModalTypes> {
    return this.selectFromState('type');
  }

  get context$(): Observable<ModalContext> {
    return this.selectFromState('context');
  }

  get portal$(): Observable<TSqTemplatePortal> {
    return this.selectFromState('portal');
  }

  open(type: ModalTypes): void {
    this.stateSubject$.next({ ...this.stateSubject$.value, open: true, type });
  }

  close(): void {
    const state = this.stateSubject$.value;
    this.stateSubject$.next({ ...state, open: false, type: undefined });
    this.closedSubject$.next(state.type);
    if (!!state.context && !!state.context.backdropAction) {
      this.store.dispatch(state.context.backdropAction);
    }
  }

  updateContext(value: Partial<ModalContext>): void {
    const state = this.stateSubject$.value;
    this.stateSubject$.next({ ...state, context: { ...state.context, ...value } });
  }

  setPortal(portal: TSqTemplatePortal): void {
    this.stateSubject$.next({ ...this.stateSubject$.value, context: portal?.context, portal });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private selectFromState(property: string): Observable<any> {
    return this.state$.pipe(pluck(property), distinctUntilChanged());
  }
}
