<div class="tsq-card">
  <div class="comment-date">{{creationDate | uppercase}}</div>
  <div class="comment-text">
    <div [ngClass]="{'generated-comment': generated}">{{text}}</div>
  </div>
  <div class="comment-created-by">
    <img [src]="createdBy.pictureUrl" class="img-circle" alt="">
    <div>
      <span>{{createdBy.first_name + ' ' + createdBy.last_name}}</span>
      <span>{{propertyDescription}}</span>
    </div>
  </div>
  <ng-container *ngIf="!!attachments && attachments.length !== 0">
    <div class="divider"></div>
    <div class="comment-att-container">
      <tsq-attachment-card *ngFor="let att of attachments"
                           [attachment]="att"
                           [iconImage]="'download'"
                           (actionClick)="onAttachmentDownload(att.fileUrl)"></tsq-attachment-card>
    </div>
  </ng-container>
</div>
