import { ParkingSpot } from './parking-spot.model';

export class Property {
  id: string;
  property_type: string;
  property_number: string;
  group_type: string;
  group_number: string;
  fine_description: string;
  inDebit: boolean;
  parkingSpots: ParkingSpot[] = [];
  voteFraction: number;
  description: string;
}
