<sc-public-container greetingBgColor="{{ greetingBackground }}">
  <div class="full-workspace-height" left>
    <img [src]="loginImage" class="greeting-image hidden-sm" alt="" />
  </div>
  <div right>
    <div *ngIf="success" class="public-col-message">
      <h1>{{ 'CHANGE_EMAIL.SUCCESS_TITLE' | translate }}</h1>
      <p>{{ 'CHANGE_EMAIL.SUCCESS_MESSAGE' | translate }}</p>
    </div>

    <div *ngIf="errorMsg" class="public-col-message error">
      <h1>{{ 'CHANGE_EMAIL.ERROR_TITLE' | translate }}</h1>
      <p>{{ errorMsg }}</p>
    </div>

    <div class="text-center text-blue">
      <a routerLink="/login">{{ 'CHANGE_EMAIL.BACK_TO_LOGIN' | translate }}</a>
    </div>
  </div>
</sc-public-container>
