<div class="row full-height">
  <div class="full-height full-width content-float" *ngIf="shouldShowBannerOverlay">
    <ng-content select="[banner-overlay]"></ng-content>
  </div>
  <div class="col-lg-8 col-md-7 col-sm-4 hidden-xs full-height">
    <div
      class="public-col-container public-greeting full-height full-width"
      [ngClass]="greetingBgColor"
    >
      <img class="logo-white" src="../assets/img/logo-inline-white.png" alt="" />
      <ng-content select="[left]"></ng-content>
    </div>
  </div>
  <div class="col-lg-4 col-md-5 col-sm-8 col-xs-12 full-height no-padding">
    <div class="row sc-centered">
      <div class="col-sm-offset-1 col-sm-10 col-sm-offset-1 col-xs-10 col-xs-offset-1">
        <div class="public-col-container">
          <ng-content select="[right]"></ng-content>
        </div>
      </div>
    </div>

    <div class="row sc-footer" *ngIf="isPortuguese">
      <comm-social-media-footer-pt-br />
    </div>
  </div>
</div>
