/* eslint-disable @typescript-eslint/no-explicit-any */
import { FeaturePackage } from '../plans/feature-package.json';
import { Plan } from '../plans/plan.json';
import { FixedCharge } from './fixed.charge';
import { SubscriptionDiscount } from './subscription-discount';
import { SubscriptionResponsible } from './subscription-responsible.json';

export class CondoSubscription {
  id: string;
  activationDate: string;
  condo: string;
  document: string;
  trial: boolean;
  trialDuration: number;
  expirationDate: string;
  nextChargeDate: string;
  chargeValue: number;
  discounts: SubscriptionDiscount[] = [];
  plan: Plan;
  featurePackages: FeaturePackage[] = [];
  responsible: SubscriptionResponsible;
  fixedCharges: FixedCharge[] = [];
}

export function coveredFeatures(subscription: CondoSubscription): string[] {
  if (!subscription) {
    return [];
  }
  return [...featuresFromPlan(subscription), ...featuresFromPackages(subscription)];
}

export function featuresFromPlan(subscription: CondoSubscription): string[] {
  return subscription.plan
    ? subscription.plan.packages.reduce(
        (next: any[], prev: FeaturePackage) => [...next, ...prev.features],
        [],
      )
    : [];
}

export function featuresFromPackages(subscription: CondoSubscription): string[] {
  return subscription
    ? subscription.featurePackages.reduce(
        (next: any[], prev: FeaturePackage) => [...next, ...prev.features],
        [],
      )
    : [];
}
