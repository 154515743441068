import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable()
export class ElementManipulationService {
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  wrapElement(currentEl: Element, wrapperEl: Element): void {
    const parent = this.renderer.parentNode(currentEl);
    this.renderer.insertBefore(parent, wrapperEl, currentEl);
    this.renderer.removeChild(parent, currentEl);
    this.renderer.appendChild(wrapperEl, currentEl);
  }
}
