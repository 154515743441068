/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

import { FileUploader } from 'ng2-file-upload';
import { CookieService } from 'ngx-cookie-service';

import { GlobalVariables } from '../../globals';

@Component({
  selector: 'sc-picture-uploader',
  templateUrl: 'sc-picture-uploader.component.html',
  styleUrls: ['sc-picture-uploader.component.scss'],
})
export class SCPictureUploaderComponent implements OnInit {
  readyToUpload = false;
  loading = false;
  uploader: FileUploader;
  @Input() pictureUrl: string;
  @Output() pictureUploadComplete = new EventEmitter<string>();
  globalVariables = GlobalVariables;

  constructor(private cookieService: CookieService) {}

  setUrl(url: string) {
    this.uploader.options.url = url;
  }

  ngOnInit() {
    const uploaderHeaders = [
      { name: 'Authorization', value: `Bearer ${this.cookieService.get('jwt')}` },
    ];

    const logonToken = this.cookieService.get('logonToken');
    if (!!logonToken) {
      uploaderHeaders.push({ name: 'xx-sc-r', value: JSON.parse(logonToken).logonToken });
    }

    this.uploader = new FileUploader({
      allowedMimeType: GlobalVariables.FILE_TYPES_IMAGES,
      autoUpload: true,
      queueLimit: 1,
      headers: uploaderHeaders,
      url: undefined,
    });

    this.uploader.onSuccessItem = (item: any, response: any, headers: any) => {
      this.pictureUrl = response;
      this.uploader.clearQueue();
      this.pictureUploadComplete.emit(this.pictureUrl);
    };

    this.uploader.onErrorItem = (item: any, response: any, status: any, headers: any) => {
      this.uploader.clearQueue();
      console.log(response);
    };
  }

  fileDropped(files: FileList) {
    const file = files[0];
    this.loadPictureFromFile(file);
  }

  fileSelected(event: any) {
    const target = event.target || event.srcElement;
    const file = target.files[0];
    this.loadPictureFromFile(file);
  }

  getUploadButtonText() {
    if (this.uploader.isUploading) {
      return 'SHARED.UPLOADING';
    } else {
      return 'SHARED.SELECT_IMAGE';
    }
  }

  private loadPictureFromFile(file: File) {
    const fr = new FileReader();
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    fr.onload = function () {
      self.pictureUrl = fr.result.toString();
    };
    fr.readAsDataURL(file);
  }
}
