<div id="list">
  <tsq-small-loader *ngIf="!availablePlans"></tsq-small-loader>
  <div *ngIf="!!availablePlans">
    <div class="panel-card mb-panel-card" *ngFor="let plan of availablePlans; let i = index">
      <div class="panel-card-header">
        <div class="selected-area" [ngClass]="{ 'selected-header': isCurrentPlan(plan) }">
          <img src="{{ plan.iconUrl }}" alt="" />
          <span
            ><strong>{{ plan.name }}</strong></span
          >
          <button
            class="btn btn-action btn-primary pull-right"
            *ngIf="!isCurrentPlan(plan)"
            (click)="onPlanSelected(plan)"
          >
            {{ 'SUBSCRIPTION.HIRE_PLAN' | translate }}
          </button>
          <div
            class="selected-plan-btn pointer-cursor inline-block pointer-cursor"
            *ngIf="isCurrentPlan(plan)"
          >
            {{ 'SUBSCRIPTION.CURRENT_PLAN' | translate }}
          </div>
        </div>
      </div>
      <div class="divider"></div>
      <div class="panel-card-content">
        <div class="col-xs-12">
          <div class="row">
            <div class="col-xs-5">
              <div class="row">
                <h1>{{ getPlanPricing(plan) }}</h1>
                <p id="plan-description" class="tsq-scroll-y">{{ plan.description }}</p>
              </div>
            </div>
            <div class="col-xs-7">
              <div class="row">
                <div class="panel-card">
                  <h2>{{ plan.packages[selectedFeaturePackages[i]].name }}</h2>
                  <div class="card-description tsq-scroll-y">
                    <p>{{ plan.packages[selectedFeaturePackages[i]].description }}</p>
                  </div>
                  <div class="bullet">
                    <i
                      *ngFor="let bullet of plan.packages; let j = index"
                      class="fa fa-circle-o pointer-cursor"
                      [class.fa-circle]="isPackageSelectedForPlan(i, j)"
                      (click)="onPackageSelectedForPlan(i, j)"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
