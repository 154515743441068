import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ErrorsService } from './services/errors.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    ErrorsService,
  ],
})
export class ErrorsModule {
}
