import { Injectable } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ComponentStore } from '@ngrx/component-store';
import {
  Observable,
  Subscription,
  filter,
  isObservable,
  map,
  mergeMap,
  tap,
  withLatestFrom,
} from 'rxjs';

import { FormMultipleSelectionOption } from '../../../models/form-selection-option.model';

type ListState = {
  options: {
    entities: FormMultipleSelectionOption[];
    initialized: boolean;
    loading: boolean;
  };
  outsideSelectedOptions: FormMultipleSelectionOption[];
  internalList: {
    entities: {
      selected: boolean;
      option: FormMultipleSelectionOption;
    }[];
    initialized: boolean;
  };
};

@UntilDestroy()
@Injectable()
export class ListStore extends ComponentStore<ListState> {
  readonly list$ = this.select(state => state.internalList.entities);
  readonly selectedList$ = this.select(state =>
    state.internalList.entities.filter(item => item.selected),
  );
  readonly optionsLoading$ = this.select(state => state.options.loading);

  readonly setOutsideSelectedOptions = this.updater<FormMultipleSelectionOption[]>(
    (state, selectedOptions) => {
      return {
        ...state,
        outsideSelectedOptions: selectedOptions || [],
      };
    },
  );

  readonly toggleListItem = this.updater<{ value: boolean; option: FormMultipleSelectionOption }>(
    (state, { value, option }) => {
      return {
        ...state,
        outsideSelectedOptions: value
          ? [...state.outsideSelectedOptions, option]
          : state.outsideSelectedOptions.filter(o => o.id !== option.id),
        internalList: {
          ...state.internalList,
          entities: state.internalList.entities.map(item => ({
            ...item,
            selected: item.option.id === option.id ? value : item.selected,
          })),
        },
      };
    },
  );

  private readonly _setOptions = this.updater<FormMultipleSelectionOption[]>((state, options) => {
    if (!options || options.length === 0) {
      return {
        ...state,
        options: {
          ...state.options,
          entities: [],
          loading: false,
        },
      };
    }

    return {
      ...state,
      options: {
        ...state.options,
        entities: options,
        initialized: true,
        loading: false,
      },
    };
  });

  private readonly buildInternalList = this.effect(() => {
    return this.select(state => state.options.initialized).pipe(
      filter(Boolean),
      mergeMap(() => {
        return this.select(state => state.options.entities);
      }),
      withLatestFrom(this.select(state => state.outsideSelectedOptions)),
      map(([options, outsideSelectedOptions]) => {
        return options.map(option => ({
          selected: outsideSelectedOptions.some(selected => selected.id === option.id),
          option,
        }));
      }),
      tap<ListState['internalList']['entities']>(list => {
        this.setState(state => ({
          ...state,
          internalList: {
            ...state.internalList,
            entities: list,
            initialized: true,
          },
        }));
      }),
    );
  });

  private readonly applyOutsideSelectedOptions = this.effect(() => {
    return this.select(state => state.internalList.initialized).pipe(
      filter(Boolean),
      mergeMap(() => {
        return this.select(state => state.outsideSelectedOptions);
      }),
      withLatestFrom(this.select(state => state.internalList.entities)),
      map(([outsideSelectedOptions, list]) => {
        return list.map(item => ({
          ...item,
          selected: outsideSelectedOptions.some(selected => selected.id === item.option.id),
        }));
      }),
      tap<ListState['internalList']['entities']>(list => {
        this.setState(state => ({
          ...state,
          internalList: {
            ...state.internalList,
            entities: list,
          },
        }));
      }),
    );
  });

  private subscription: Subscription;

  constructor() {
    super({
      options: {
        entities: [],
        initialized: false,
        loading: false,
      },
      outsideSelectedOptions: [],
      internalList: {
        entities: [],
        initialized: false,
      },
    });
  }

  get selectedList(): FormMultipleSelectionOption[] {
    return this.get(state => state.internalList.entities)
      .filter(item => item.selected)
      .map(item => item.option);
  }

  setOptions(
    value: FormMultipleSelectionOption[] | Observable<FormMultipleSelectionOption[]>,
  ): void {
    if (!!this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }

    if (isObservable(value)) {
      this.setState(state => ({
        ...state,
        options: {
          ...state.options,
          loading: true,
        },
      }));
      this.subscription = value.pipe(untilDestroyed(this)).subscribe({
        next: options => {
          this._setOptions(options);
        },
        error: () => {
          this._setOptions(null);
        },
      });
    } else {
      this._setOptions(value);
    }
  }
}
