<div class="flex items-center absolute bottom-16 left-1/2 -translate-x-1/2" @slideUp>
  <button class="px-16 py-8 rounded-l-full bg-primary border-r-1 border-white hover:bg-primary-p1"
          (click)="download.emit()">
    {{ 'NATIVE_APP_BANNER.DOWNLOAD' | translate }}
  </button>
  <button class="rounded-r-full bg-primary hover:bg-primary-p1"
          (click)="closeButton.emit()">
    <tsq-icon [icon]="icons.xmark"></tsq-icon>
  </button>
</div>
