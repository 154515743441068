import { getEnvironment } from '..';
import { CommunitiesEnvironments } from '../models/communities-environments.enum';

export function isLowerEnvironment(): boolean {
  const { env } = getEnvironment();

  return [
    CommunitiesEnvironments.LOCAL,
    CommunitiesEnvironments.DEV,
    CommunitiesEnvironments.UAT,
  ].includes(env as CommunitiesEnvironments);
}
