<div class="conversation text-white"
     [ngClass]="{'conversation-warning': warning, 'conversation-default': !warning}">
  <div class="conversation-picture pull-left info-picture" *ngIf="avatarUrl">
    <img src="{{avatarUrl}}" alt="" class="img-circle">
  </div>
  <div>
    <p>
      <small>
        <div *ngIf="!simplified">
          {{date}}<br/>
        </div>
        <p>{{name}}</p>
        <div *ngIf="!simplified">
          <br/>
          <br/>
        </div>
        <p *ngIf="text" [innerHTML]="parseDiscussionText(text) || sanitizeHtml"></p>
      </small>
    </p>
  </div>
</div>
