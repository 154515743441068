import { Property } from '../models/property.model';

export const mockedProperty: Property = {
  id: 'property-id',
  property_type: 'Apartment',
  property_number: '320',
  group_type: 'Tower',
  group_number: 'C',
  fine_description: 'Tower C - Apartment 320',
  inDebit: false,
  parkingSpots: [],
} as Property;
