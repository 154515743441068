<div class="relative grid h-full grid-cols-1 md:grid-cols-3">
  <section class="bg-green md:col-span-1">
    <div class="m-40">
      <img
        src="./assets/brand/white-logo.svg"
        class="h-56"
        alt="{{ 'LOGIN.TOWNSQ_LOGO' | translate }}"
      />
    </div>
  </section>
  <section class="col-span-2 flex flex-col">
    <div
      class="mx-auto flex h-full items-center justify-center"
      *ngrxLet="showSentEmailMessage$ as showSentEmailMessage"
    >
      <div class="w-[380px]">
        <div class="instructions">
          <h3 class="header">{{ 'RECOVERY.HEADER' | translate }}</h3>
          <p class="description">{{ 'RECOVERY.DESCRIPTION' | translate }}</p>
        </div>

        <comm-reset-your-password-card *ngIf="showResetYourPasswordCard" />

        <div *ngIf="isSent || showSentEmailMessage" class="public-col-message">
          <img src="./assets/svg/email.svg" alt="" />
          <h1>{{ 'RECOVERY.EMAIL_SENT_TITLE' | translate }}</h1>
          <p>{{ 'RECOVERY.EMAIL_SENT_MESSAGE' | translate }}</p>
          <p>{{ 'RECOVERY.CHECK_YOUR_SPAM' | translate }}</p>
        </div>

        <form
          class="w-full"
          accept-charset="UTF-8"
          role="form"
          *ngIf="ssoPasswordReset ? !showSentEmailMessage : !isSent"
          (ngSubmit)="onSubmit()"
        >
          <fieldset>
            <div class="form-group">
              <label for="email1">{{ 'RECOVERY.EMAIL' | translate }}</label>
              <input
                type="email"
                class="form-control"
                id="email1"
                placeholder="{{ 'EMAIL_PLACEHOLDER' | translate }}"
                required
                [(ngModel)]="email1"
                name="email1"
              />
            </div>

            <div class="form-group">
              <label for="email2">{{ 'RECOVERY.CONFIRM_EMAIL' | translate }}</label>
              <input
                type="email"
                class="form-control"
                id="email2"
                placeholder="{{ 'EMAIL_PLACEHOLDER' | translate }}"
                required
                [(ngModel)]="email2"
                name="email2"
              />
            </div>

            <tsq-small-loader *ngIf="resetPasswordLoading$ | async; else sendLinkButton" />

            <ng-template #sendLinkButton>
              <button class="btn btn-default" [disabled]="isDisabled">
                {{ 'RECOVERY.SEND_LINK' | translate }}
              </button>
            </ng-template>
          </fieldset>
        </form>

        <div class="text-blue mt-32 w-full text-center">
          <a (click)="backToLogin()">{{ 'RECOVERY.BACK_TO_LOGIN' | translate }}</a>
        </div>
      </div>
    </div>
    <div *ngIf="isPortuguese" class="mb-[20px] w-full justify-end">
      <comm-social-media-footer-pt-br />
    </div>
  </section>
</div>
