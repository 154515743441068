<div class="panel panel-transparent">
  <div class="panel-heading flex">
    <img src="./assets/img/icons/icon-cart-plans.svg" alt="" />
    <span>{{ 'SUBSCRIPTION.MY_PLAN' | translate }}</span>
  </div>
  <div class="panel-body">
    <div class="divider"></div>

    <div class="cart-section" (click)="openTabChange.emit(tabsEnum.PLANS)">
      <i
        class="fa"
        [ngClass]="{
          'fa-angle-up': openTab === tabsEnum.PLANS,
          'fa-angle-down': openTab !== tabsEnum.PLANS
        }"
      ></i>
      <span>{{ 'SUBSCRIPTION.PLAN' | translate }}</span>
      <div
        [ngClass]="openTab !== tabsEnum.PLANS ? 'hidden' : 'block'"
        *ngIf="selectedPlan"
        (click)="$event.stopPropagation()"
        class="collapse-section"
      >
        <div class="plan-card">
          <img [src]="selectedPlan.iconUrl" alt="" />
          <span>{{ selectedPlan.name }}</span
          ><br />
          <div>
            <span>{{ planValueString }}</span>
            <span class="pull-right">{{
              '$ ' + (selectedPlan.price.amount / 100).toFixed(2)
            }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="divider" *ngIf="!customerCart"></div>

    <div
      class="cart-section"
      *ngIf="!customerCart"
      (click)="openTabChange.emit(tabsEnum.FEATURE_PACKAGES)"
    >
      <i
        class="fa"
        [ngClass]="{
          'fa-angle-up': openTab === tabsEnum.FEATURE_PACKAGES,
          'fa-angle-down': openTab !== tabsEnum.FEATURE_PACKAGES
        }"
      ></i>
      <span>{{ 'SUBSCRIPTION.FEATURES' | translate }}</span>
      <div
        [ngClass]="openTab !== tabsEnum.FEATURE_PACKAGES ? 'hidden' : 'block'"
        *ngIf="selectedPackages && selectedPackages.length > 0"
        (click)="$event.stopPropagation()"
        class="collapse-section"
      >
        <div class="feature-card" *ngFor="let featurePackage of selectedPackages">
          <div>{{ featurePackage.name }}</div>
          <span class="pull-right">{{
            '$ ' + (featurePackage.price.amount / 100).toFixed(2)
          }}</span>
        </div>
      </div>
    </div>

    <div class="divider" *ngIf="!customerCart"></div>

    <div
      class="cart-section"
      *ngIf="!customerCart"
      (click)="openTabChange.emit(tabsEnum.DISCOUNTS)"
    >
      <i
        class="fa"
        [ngClass]="{
          'fa-angle-up': openTab === tabsEnum.DISCOUNTS,
          'fa-angle-down': openTab !== tabsEnum.DISCOUNTS
        }"
      ></i>
      <span>{{ 'SUBSCRIPTION.OFFER_DETAILS' | translate }}</span>
      <div
        [ngClass]="openTab !== tabsEnum.DISCOUNTS ? 'hidden' : 'block'"
        (click)="$event.stopPropagation()"
        class="collapse-section"
        *ngIf="selectedPlan"
      >
        <div class="feature-card" *ngIf="percentage">
          <div>{{ 'SUBSCRIPTION.PERCENTAGE' | translate }}</div>
          <span>- {{ percentage }}%</span>
        </div>
        <div class="feature-card" *ngIf="rawValue">
          <div>{{ 'SUBSCRIPTION.GROSS' | translate }}</div>
          <span>- $ {{ rawValue }}</span>
        </div>
      </div>
    </div>

    <div class="divider"></div>

    <div class="cart-section" (click)="openTabChange.emit(tabsEnum.BILLING_DETAILS)">
      <i
        class="fa"
        [ngClass]="{
          'fa-angle-up': openTab === tabsEnum.BILLING_DETAILS,
          'fa-angle-down': openTab !== tabsEnum.BILLING_DETAILS
        }"
      ></i>
      <span>{{ 'SUBSCRIPTION.BILLING_DETAILS' | translate }}</span>
      <div
        [ngClass]="openTab !== tabsEnum.BILLING_DETAILS ? 'hidden' : 'block'"
        (click)="$event.stopPropagation()"
        class="collapse-section"
      >
        <div
          class="billing-card"
          *ngIf="
            !!paymentInfo && !!paymentInfo.stripe && paymentInfo.type === paymentInfoTypeEnum.Stripe
          "
        >
          <div class="flex">
            <img src="./assets/img/icons/{{ cardBrandImgName }}.png" id="brand" alt="" />
            <span>•••• •••• •••• {{ paymentInfo.stripe.lastFour }}</span>
          </div>
          <img src="./assets/img/icons/ic-check-avocado.svg" class="pull-right check" alt="" />
        </div>
        <div class="billing-card" *ngIf="!customerCart && responsibleForPayment">
          <span>{{ 'SUBSCRIPTION.RESPONSIBLE_PAYMENT' | translate }}</span>
          <img src="./assets/img/icons/ic-check-avocado.svg" class="pull-right check" alt="" />
        </div>
      </div>
    </div>

    <div class="divider"></div>

    <div
      *ngIf="customerCart && selectedPackages && selectedPackages.length > 0"
      class="info-container"
    >
      <div class="info-card">
        <div class="info-card-item" *ngFor="let featurePackage of selectedPackages">
          <div>{{ featurePackage.name }}</div>
          <div>{{ '$ ' + (featurePackage.price.amount / 100).toFixed(2) }}</div>
        </div>
      </div>
    </div>

    <div *ngIf="fixedCharges && fixedCharges.length > 0" class="info-container">
      <div class="info-card">
        <div *ngFor="let charge of fixedCharges" class="info-card-item">
          <div>{{ charge.description }}</div>
          <div>$ {{ (charge.price.amount / 100).toFixed(2) }}</div>
        </div>
      </div>
    </div>

    <div *ngIf="customerCart && (percentage || rawValue)" class="info-container">
      <div class="info-card">
        <div class="info-card-item" *ngIf="percentage">
          <div>{{ 'SUBSCRIPTION.DISCOUNT' | translate }}</div>
          <div>- {{ percentage }}%</div>
        </div>
        <div class="info-card-item" *ngIf="rawValue">
          <div>{{ 'SUBSCRIPTION.DISCOUNT' | translate }}</div>
          <div>- $ {{ rawValue }}</div>
        </div>
      </div>
    </div>

    <div class="cart-sum">
      <span>{{ 'SUBSCRIPTION.TOTAL' | translate }}</span>
      <div class="cart-card sum">$ {{ amount ? amount : '0.00' }}</div>
    </div>

    <div class="divider"></div>
  </div>

  <div class="panel-actions flex gap-8">
    <tsq-button
      class="grow"
      [size]="'lg'"
      [text]="'SUBSCRIPTION.CANCEL' | translate"
      [kind]="'tertiary'"
      [disabled]="isSending"
      (clicked)="cancelSubscriptionClicked.emit()"
    />
    <tsq-button
      class="grow"
      [size]="'lg'"
      *ngIf="!isSending"
      [text]="'SUBSCRIPTION.CHANGE_PLAN' | translate"
      [disabled]="!cartValid"
      (clicked)="changeSubscription()"
    />
    <tsq-small-loader id="loader" *ngIf="isSending"></tsq-small-loader>
  </div>
</div>
