<div class="sidebar-container" #menuContainer>
  <nav role="navigation" class="sidebar">
    <ul class="acc-menu">
      <ng-content></ng-content>
    </ul>
  </nav>
</div>
<div *ngIf="canScrollDown" (click)="gotoMenuBottom()" id="more-options">
  {{ "MORE_OPTIONS" | translate }} <i class="fa fa-angle-down " aria-hidden="true"></i>
</div>
