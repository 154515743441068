<div class="bg-petro-n1 font-base flex h-full justify-center">
  <div class="flex h-full flex-col items-center gap-48">
    <img
      [src]="'LOGO_SRC' | translate"
      [alt]="'TOWNSQ_LOGO' | translate"
      class="mt-120 block w-[194px]"
    />

    <tsq-loader *ngIf="loggingIn$ | async" />
  </div>
</div>
