import {
  AbstractControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

export class TSqValidators {
  static hasCharDifferentThanWhitespace(control: AbstractControl): Record<string, boolean> {
    return new RegExp(/\S/).test(control.value) ? null : { whitespace: true };
  }

  static validEmail(control: AbstractControl): Record<string, boolean> {
    return TSqValidators.stringEmailValidator(control.value) ? null : { invalidEmail: true };
  }

  static validEmptyEmail(control: AbstractControl): Record<string, boolean> {
    if (!control.value) {
      return null;
    }

    return TSqValidators.stringEmailValidator(control.value) ? null : { invalidEmail: true };
  }

  static validLoginUsername(control: AbstractControl): Record<string, boolean> {
    return TSqValidators.stringEmailValidator(control.value) ||
      new RegExp(/(na|dev|associa)\\.{3,}$/i).test(control.value)
      ? null
      : { invalidEmail: true };
  }

  static validMultipleEmails(control: AbstractControl): Record<string, boolean> {
    if (!!control.value) {
      const hasInvalidEmail = control.value
        .split(',')
        .some(email => !TSqValidators.stringEmailValidator(email.trim()));
      if (hasInvalidEmail) {
        return { invalidEmail: true };
      } else {
        return null;
      }
    } else {
      return { invalidEmail: true };
    }
  }

  static emptyOrHasCharDifferentThanWhitespace(control: AbstractControl): Record<string, boolean> {
    if (!!control.value) {
      return TSqValidators.hasCharDifferentThanWhitespace(control);
    } else {
      return null;
    }
  }

  static stringEmailValidator(email: string): boolean {
    return new RegExp(
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
    ).test(email);
  }

  // validation for dd/mm/yyyy
  static validDate(control: AbstractControl): Record<string, boolean> {
    return new RegExp(
      /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
    ).test(control.value)
      ? null
      : { invalidDate: true };
  }

  // validation for dd/mm/yyyy
  static validDateUs(control: AbstractControl): Record<string, boolean> {
    return new RegExp(
      /(((19|20)([2468][048]|[13579][26]|0[48])|2000)[/-]02[/-]29|((19|20)[0-9]{2}[/-](0[4678]|1[02])[/-](0[1-9]|[12][0-9]|30)|(19|20)[0-9]{2}[/-](0[1359]|11)[/-](0[1-9]|[12][0-9]|3[01])|(19|20)[0-9]{2}[/-]02[/-](0[1-9]|1[0-9]|2[0-8])))/,
    ).test(control.value)
      ? null
      : { invalidDate: true };
  }

  static validDateValue(control: AbstractControl): Record<string, boolean> {
    const minDate = new Date(1000, 0, 1);

    if (!control.value) {
      return null;
    }

    if (control.value < minDate) {
      return { invalid: true };
    } else {
      return null;
    }
  }

  // validation for cpf & cnpj
  static validCpfCnpj(control: AbstractControl): Record<string, boolean> {
    if (!!control && !!control.value) {
      const controlValue: string = TSqValidators.sanitizeString(control.value);

      return controlValue.length > 13
        ? TSqValidators.checkCnpjIsValid(control)
        : TSqValidators.checkCpfIsValid(control);
    } else {
      return { invalid: true };
    }
  }

  static sanitizeString(raw: string): string {
    return !!raw ? raw.replace(/[.\s\-_()/]+/g, '') : '';
  }

  static checkCpfIsValid(control: AbstractControl): Record<string, boolean> {
    const parsedCpf = TSqValidators.parseToArrayNumber(control.value);

    const modNinth = TSqValidators.cpfDigitMultiplication(parsedCpf, 10) % 11;
    const modTenth = TSqValidators.cpfDigitMultiplication(parsedCpf, 11) % 11;

    const nextToLastDigit = TSqValidators.defineDigit(modNinth);
    const lastDigit = TSqValidators.defineDigit(modTenth);

    return parsedCpf[9] === nextToLastDigit && parsedCpf[10] === lastDigit
      ? null
      : { invalidCPF: true };
  }

  static checkCnpjIsValid(control: AbstractControl): Record<string, boolean> {
    const parsedCnpj = TSqValidators.parseToArrayNumber(control.value);

    const modTwelfth = TSqValidators.cnpjDigitMultiplication(parsedCnpj, 5, 3) % 11;
    const modThirteenth = TSqValidators.cnpjDigitMultiplication(parsedCnpj, 6, 4) % 11;

    const nextToLastDigit = TSqValidators.defineDigit(modTwelfth);
    const lastDigit = TSqValidators.defineDigit(modThirteenth);

    return parsedCnpj[12] === nextToLastDigit && parsedCnpj[13] === lastDigit
      ? null
      : { invalidCNPJ: true };
  }

  static defineDigit(digit: number): number {
    return digit === 1 || digit === 0 ? 0 : 11 - digit;
  }

  static parseToArrayNumber(cpf: string): number[] {
    return cpf
      .split('')
      .filter(c => c !== '-' && c !== '.' && c !== '/')
      .map(s => +s);
  }

  static cpfDigitMultiplication(cpf: number[], multiply: number): number {
    let accDigit = 0;

    for (const n of cpf) {
      accDigit += multiply * n;
      multiply--;
      if (multiply <= 1) {
        break;
      }
    }

    return accDigit;
  }

  static cnpjDigitMultiplication(cnpj: number[], multiply: number, indexFlip: number): number {
    let accDigit = 0;

    for (let index = 0; multiply > 1; multiply--, index++) {
      accDigit += multiply * cnpj[index];

      if (index === indexFlip) {
        multiply = 10;
      }
    }

    return accDigit;
  }

  static maxLengthNChars(maxLength: number): ValidatorFn {
    return (control: AbstractControl) =>
      !!Validators.maxLength(maxLength)(control) ? { maxOfNChar: true, maxLength } : null;
  }

  static minLengthNChars(minLength: number): ValidatorFn {
    return (control: AbstractControl) =>
      !!Validators.minLength(minLength)(control) ? { minOfNChar: true, minLength } : null;
  }

  static confirmationValidator(
    key1: string,
    key2: string,
  ): (control: AbstractControl) => ValidationErrors | null {
    return (group: UntypedFormGroup): ValidationErrors | null => {
      const control1 = group.get(key1);
      const control2 = group.get(key2);

      if (!!control1.value && !!control2.value && control1.value !== control2.value) {
        return { confirmationInvalid: true };
      }

      return null;
    };
  }

  static matchPasswordValidator(matchTo: string, reverse?: boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.parent && reverse) {
        const c = (control.parent?.controls as AbstractControl[])[matchTo] as AbstractControl;
        if (c) {
          c.updateValueAndValidity();
        }
        return null;
      }
      return !!control.parent &&
        !!control.parent.value &&
        control.value === (control.parent?.controls as AbstractControl[])[matchTo].value
        ? null
        : { passwordNotMatched: true };
    };
  }
}
