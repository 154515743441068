import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { UntilDestroy } from '@ngneat/until-destroy';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import { icons } from '@tsq-web/assets';
import { getTSqEnvironment } from '@tsq-web/environment';
import { LoginBackground } from '@tsq-web/login';
import { AppState } from '@tsq-web/state';
import { fromUserContextActions, fromUserContextSelectors } from '@tsq-web/user-context';

@UntilDestroy()
@Component({
  selector: 'comm-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.scss'],
})
export class SingleSignOnComponent implements OnInit {
  readonly arrowLeftIcon = icons.arrowLeft;

  loggingIn$: Observable<boolean>;

  loginForm = new FormGroup({
    username: new FormControl('', [Validators.required]),
  });

  background: LoginBackground = new LoginBackground();
  environment = getTSqEnvironment();

  constructor(private store: Store<AppState>, private router: Router) {}

  ngOnInit(): void {
    this.loggingIn$ = this.store.pipe(select(fromUserContextSelectors.selectUserLoggingIn));
  }

  get isFormValid(): boolean {
    return this.loginForm.valid;
  }

  get username(): string {
    return this.loginForm.controls.username.value;
  }

  onSubmit(): void {
    this.store.dispatch(
      fromUserContextActions.authenticate({
        email: this.username,
        uri: `${getTSqEnvironment().communitiesUrl}/auth/callback`,
      }),
    );
  }

  getBackToLogin(): void {
    this.router.navigate(['/login']);
  }
}