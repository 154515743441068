<div id="switch-collapse-container" [ngClass]="{ 'card': isCard }">
  <div class="collapse-header">
    <span [ngClass]="{ 'text-app-primary': textColorsEnum.APP_PRIMARY === textColor,
                       'text-primary': textColorsEnum.TEXT_PRIMARY === textColor }">
      {{ label | translate }}
    </span>
    <div>
      <ng-content select="[toggle]"></ng-content>
    </div>
  </div>
  <div [ngClass]="{ 'collapse-body-panel': bodyIsPanel }" [collapse]="!show">
    <div class="divider" *ngIf="bodyIsPanel"></div>
    <div>
      <ng-content select="[body]"></ng-content>
    </div>
  </div>
</div>
