import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

import { BaseService } from '@tsq-web/core';
import { getTSqEnvironment } from '@tsq-web/environment';
import { User } from '@tsq-web/users';
import { LoginData } from '../models/login-data.model';
import { LoginResponse } from '../models/login-response.model';
import { LoginBody } from '../models/login-body.model';
import { LoginContext } from '../models/login-context.model';

@Injectable()
export class LoginService extends BaseService {
  constructor(
    protected cookieService: CookieService,
    protected httpClient: HttpClient,
    protected router: Router,
  ) {
    super(cookieService, httpClient, router);
  }

  login(user: User): Observable<LoginData> {
    return this.doPost('login', user);
  }

  relogin(user: User): Observable<LoginData> {
    return this.doPost(`login/relogin`, user);
  }

  logout(): Observable<LoginData> {
    return this.doGet('logout');
  }

  contextLogin(loginBody: LoginBody): Observable<LoginResponse> {
    return this.doPost('login', loginBody, 'v1');
  }

  getContext(contextId: string): Observable<LoginContext> {
    return this.doGet(`login/${getTSqEnvironment().appType}/context/${contextId}`, 'v1');
  }

  getContextList(search?: string, page?: number): Observable<LoginContext[]> {
    let params: HttpParams = new HttpParams();

    params = params.append('page', String(!!page ? String(page) : '0'));
    params = params.append('size', '10');

    if (!!search) {
      params = params.append('search', search);
    }

    return this.doGet<{ models: LoginContext[] }>(
      `login/${getTSqEnvironment().appType}/context`,
      params,
      'v1',
    ).pipe(pluck('models'));
  }
}
