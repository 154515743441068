export class Address {
  street: string;
  number: string;
  neighborhood: string;
  city: string;
  state: string;
  country = 'BRASIL';
  zipCode: string;
  location: number[] = [];
}
