<div
  class="breadcrumbs"
  [ngClass]="{ 'has-box-shadow': hasBoxShadow, 'is-new-design': isNewDesign }"
>
  <tsq-icon
    *ngIf="hasBackIcon"
    [classes]="'w-16 h-16 text-coal-secondary cursor-pointer mr-8'"
    [icon]="arrowLeftIcon"
    [routerLink]="breadcrumbs[breadcrumbs.length - 2].path"
  ></tsq-icon>
  <ng-container *ngFor="let breadcrumb of breadcrumbs; let index = index">
    <a
      class="label breadcrumb truncate"
      [ngClass]="{ 'is-active': index === breadcrumbs.length - 1 }"
      [routerLink]="breadcrumb.path"
      [queryParams]="breadcrumb.queryParams"
    >
      {{
        breadcrumb.text.shouldTranslate
          ? (breadcrumb.text.value | translate)
          : breadcrumb.text.value
      }}
    </a>

    <div class="separator min-w-max" *ngIf="index < breadcrumbs.length - 1">
      <img src="/assets/libs/icons/caret.svg" class="filter-text-secondary" alt="Separator" />
    </div>
  </ng-container>
  <a class="breadcrumb">
    <tsq-small-loader *ngIf="loading"></tsq-small-loader>
  </a>
</div>
