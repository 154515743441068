<div *ngIf="!hasPaginator"
     class="list-container h-full"
     [ngClass]="{ 'scroll-up-container': scrollUp }"
     infiniteScroll
     tsqListInsufficientItems
     [infiniteScrollDistance]="0.5"
     [infiniteScrollUpDistance]="3.0"
     [infiniteScrollThrottle]="20"
     [infiniteScrollContainer]="scrollableContainer"
     [scrollWindow]="false"
     [fromRoot]="true"
     [alwaysCallback]="true"
     [scrollableContainer]="scrollableContainer"
     [existingItems]="!loading ? listSize : 0"
     (scrolled)="onScroll($event)"
     (scrolledUp)="onScrollUp($event)"
     (loadMore)="onScroll($event)"
     #listContainer>

  <ng-container *ngTemplateOutlet="listStates"></ng-container>
</div>

<div *ngIf="hasPaginator"
     class="list-container">

  <ng-container *ngTemplateOutlet="listStates"></ng-container>

  <div *ngIf="!loading && !error && !showZeroData"
       class="paginator">
    <tsq-paginator [theme]="paginatorTheme"
                   [entity]="paginatorEntity"
                   [total]="totalRecords"
                   [inPage]="listSize"
                   [perPage]="perPage"
                   [page]="page"
                   (pageChange)="pageChanged.emit($event)"></tsq-paginator>
  </div>

</div>

<ng-template #listStates>
  <div id="loader-container" *ngIf="loading && selfHandleLoader">
    <tsq-loader></tsq-loader>
  </div>

  <div *ngIf="loading && !selfHandleLoader">
    <ng-content select="[loader]"></ng-content>
  </div>

  <div class="h-full" *ngIf="!loading && !loadingMore && showZeroData && !error">
    <ng-content select="[zero-data]"></ng-content>
  </div>

  <div id="error-container" *ngIf="!loading && error && selfHandleError">
    <tsq-error [title]="errorTitle"
               [text]="errorText"
               (retry)="retryOnError()"></tsq-error>
  </div>

  <div *ngIf="!loading && error && !selfHandleError">
    <ng-content select="[error]"></ng-content>
  </div>

  <div *ngIf="!loading && !error && !showZeroData">
    <ng-content select="[list]"></ng-content>
  </div>

  <div id="load-more-container" *ngIf="loadingMore">
    <tsq-small-loader></tsq-small-loader>
  </div>
</ng-template>
