/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { selectUntilDestroyed } from '@tsq-web/redux/operators';
import { AppState } from '@tsq-web/state';
import { fromUserContextActions, fromUserContextSelectors } from '@tsq-web/user-context';
import { User } from '@tsq-web/users';

import { RegisterService } from '../../../shared/user/register.service';
import { RegistrationSearchRequest } from '../../../shared/user/registration-search-request.model';
import { UserRegister } from '../../../shared/user/user-register.model';
import { PasswordUtils } from '../../utils/password.utils';

@UntilDestroy()
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sc-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  user: User;
  fromRegister = false;
  password = '';
  userEmail = '';
  isLogging = false;

  emailRegex =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

  supportEmail = 'support@townsq.io';
  private userId = '';
  private token = '';
  private returnTo: string;

  private paramSubscription: Subscription;
  private queryParamSubscription: Subscription;
  private activeDirectoryRegex = /(na|dev|associa)\\.{3,}$/i;

  constructor(
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private _registerService: RegisterService,
    private _translateService: TranslateService,
    private _passwordUtils: PasswordUtils,
    private store: Store<AppState>,
  ) {
    this.user = new User();
    this.user.platform = 'webapp';

    this.paramSubscription = this.route.params.subscribe(params => {
      this.userId = params.userId || this.userId;
      this.token = params.token || this.token;

      const request = new RegistrationSearchRequest();
      request.userId = this.userId;
      request.registrationToken = this.token;

      if (this.userId.length > 0) {
        this._registerService.registrationSearch(request).subscribe(
          user => this.onReceiveUserInfo(user),
          error => this.onReceiveUserError(error),
        );
      }
    });

    this.queryParamSubscription = this.route.queryParams.subscribe(
      params => (this.returnTo = params.return),
    );
  }

  ngOnInit(): void {
    this.store
      .pipe(selectUntilDestroyed(fromUserContextSelectors.selectUserLoggingIn, this))
      .subscribe(loggingIn => (this.isLogging = loggingIn));
  }

  ngOnDestroy(): void {
    this.paramSubscription.unsubscribe();
    this.queryParamSubscription.unsubscribe();
  }

  onSubmit(): void {
    this.user = {
      ...this.user,
      email: this.userEmail,
      login_type: 'CMN',
    };
    if (this.isEmail()) {
      this.user.password = this._passwordUtils.SHA1(this.password);
    } else {
      this.user.password = this.password;
    }
    this.doLogin();
  }

  get loginImage(): string {
    return this.isPortuguese
      ? '../../assets/images/login/login-image-pt.png'
      : '../../assets/img/login-image-en.png';
  }

  get greetingBackground(): string {
    return this.isPortuguese ? 'avocado-with-countries' : 'green-alternate';
  }

  get isPortuguese(): boolean {
    return this._translateService.currentLang === 'pt';
  }

  onFacebookResponse(response: { user: { id: string; authToken: string; email: string } }): void {
    this.user.login_type = 'FB';
    this.user.fb_user = response.user.id;
    this.user.auth_token = response.user.authToken;
    this.user.email = response.user.email;
    this.doLogin();
  }

  onFacebookError(error: string): void {
    this.toastr.error(this._translateService.instant('LOGIN.ERRORS.FACEBOOK_ERROR'));
    throw new Error(error);
  }

  private doLogin(): void {
    this.user.email = !!this.user.email ? this.user.email.trim() : undefined;
    if (!this.isFacebookLogin() && this.validUsername()) {
      this.toastr.error(this._translateService.instant('LOGIN.ERRORS.INVALID_USERNAME_FORMAT'));
    } else {
      this.user.login_type = this.loginType();
      this.store.dispatch(fromUserContextActions.login({ user: this.user }));
    }
  }

  private validUsername(): boolean {
    return !this.isEmail() && !this.isActiveDirectoryAccount();
  }

  private isEmail(): boolean {
    return this.emailRegex.test(this.user.email);
  }

  private isActiveDirectoryAccount(): boolean {
    return this.activeDirectoryRegex.test(this.user.email.toLowerCase());
  }

  private isFacebookLogin(): boolean {
    return this.user.login_type === 'FB';
  }

  private loginType(): string {
    if (this.isFacebookLogin()) {
      return 'FB';
    } else if (this.isActiveDirectoryAccount()) {
      return 'AD';
    }

    return 'CMN';
  }

  private onReceiveUserInfo(user: UserRegister): void {
    this.user.first_name = user.first_name;
    this.user.last_name = user.last_name;
    this.user.pictureUrl = user.pictureUrl;
    this.fromRegister = true;
  }

  private onReceiveUserError(error: string): void {
    throw new Error(error);
  }
}
