import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { SubscriptionDiscount } from '../../../shared/subscription/subscription-discount';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'discounts-selection',
  templateUrl: './discounts-selection.component.html',
  styleUrls: ['./discounts-selection.component.scss'],
})
export class DiscountsSelectionComponent implements OnInit {
  @Output() discountsSelectionChanged = new EventEmitter<SubscriptionDiscount[]>();

  form: UntypedFormGroup;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      discounts: new UntypedFormArray([]),
    });
    this.addDiscount();

    (this.form.controls.discounts as UntypedFormArray).valueChanges.subscribe(values => {
      values.forEach((discount: { gross: string; percentage: string }, index: number) => {
        const gross = (
          (this.form.controls.discounts as UntypedFormArray).at(index) as UntypedFormGroup
        ).controls.gross;
        const percentage = (
          (this.form.controls.discounts as UntypedFormArray).at(index) as UntypedFormGroup
        ).controls.percentage;

        if (discount.percentage !== undefined && discount.percentage !== '') {
          if (!gross.disabled) {
            gross.disable();
          }
        } else if (discount.gross !== undefined && discount.gross !== '') {
          if (!percentage.disabled) {
            percentage.disable();
          }
        } else {
          if (!percentage.enabled) {
            percentage.enable();
          }
          if (!gross.enabled) {
            gross.enable();
          }
        }
      });
      this.emitChanges(values);
    });
  }

  addDiscount(): void {
    const control = this.form.controls.discounts as UntypedFormArray;
    control.push(
      new UntypedFormGroup({
        percentage: new UntypedFormControl(
          { value: '', disabled: false },
          Validators.nullValidator,
        ),
        gross: new UntypedFormControl({ value: '', disabled: false }, Validators.nullValidator),
        oneTime: new UntypedFormControl(false, Validators.nullValidator),
      }),
    );
  }

  initDiscountsSelection(discounts: SubscriptionDiscount[]): void {
    const formArray = this.form.controls.discounts as UntypedFormArray;
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }

    discounts.forEach((discount, index) => {
      this.addDiscount();

      const formAtIndex = formArray.at(index) as UntypedFormGroup;
      if (discount.percentage) {
        formAtIndex.controls.percentage.setValue(discount.percentage);
      }
      if (discount.rawValue) {
        formAtIndex.controls.gross.setValue(discount.rawValue);
      }
      formAtIndex.controls.oneTime.setValue(discount.oneTime);
      this.cdRef.detectChanges();
    });

    if (discounts.length === 0) {
      this.addDiscount();
    }
  }

  removeDiscount(index: number): void {
    const control = this.form.controls.discounts as UntypedFormArray;
    control.removeAt(index);
  }

  get discountsArrayControl(): AbstractControl[] {
    const control = this.form.controls.discounts as UntypedFormArray;
    return control.controls;
  }

  private emitChanges(formDiscountsArray: Record<string, unknown>[]): void {
    const discounts = [];
    for (const discount of formDiscountsArray) {
      if (
        (discount.percentage !== '' && discount.percentage !== undefined) ||
        (discount.gross !== '' && discount.gross !== undefined)
      ) {
        const discountObj = SubscriptionDiscount.from(
          +String(discount.gross).replace(',', '.'),
          discount.percentage as number,
          discount.oneTime as boolean,
        );
        discounts.push(discountObj);
      }
    }
    this.discountsSelectionChanged.emit(discounts);
  }
}
