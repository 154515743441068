import { Component, inject } from '@angular/core';

import { icons } from '@tsq-web/assets';

import { getCurrentLogoPath } from '../../../shared/util/environment.utils';
import { RecoveryRequestSteps } from '../../models/recovery-request-steps.enum';
import { RequestRecoveryLinkStore } from '../../state/request-recovery-link/request-recovery-link.store';

@Component({
  templateUrl: './request-password-recovery.component.html',
  providers: [RequestRecoveryLinkStore],
})
export class RequestPasswordRecoveryComponent {
  private readonly store = inject(RequestRecoveryLinkStore);

  readonly currentStep$ = this.store.currentRequestLinkStep$;
  readonly isLoading$ = this.store.isSendingRecoveryLink$;
  readonly isSso$ = this.store.isSsoEmail$;
  readonly email$ = this.store.email$;

  readonly steps = RecoveryRequestSteps;

  readonly leftArrow = icons.arrowLeft;
  protected readonly logoPath = getCurrentLogoPath();

  goBack(): void {
    this.store.goBack();
  }

  requestRecoveryLink(email: string): void {
    this.store.requestRecoveryLink(email);
  }
}
