<div
  class="bg-yellow-n1 border-1 border-yellow-p1 my-16 flex w-full justify-between rounded p-16 shadow"
>
  <div class="flex flex-col">
    <span class="text-yellow-p1 base-text-bold">
      {{ 'AIS_SIGN_UP.LOGIN_INFO_BANNER.TITLE' | translate }}
    </span>

    <span>
      <a class="text-kale text-sm" href="#" [routerLink]="['/login']" target="_blank"
        >{{ 'AIS_SIGN_UP.LOGIN_INFO_BANNER.LOGIN_LINK_INFO' | translate }}
      </a>
      <span class="text-yellow-p1 small-text mx-1 mb-0">{{
        'AIS_SIGN_UP.LOGIN_INFO_BANNER.DESCRIPTION' | translate
      }}</span>
      <a class="text-kale mx-1 text-sm lowercase" (click)="reloadPage()">{{
        'AIS_SIGN_UP.LOGIN_INFO_BANNER.REFRESH_LINK_INFO' | translate
      }}</a>
      <span class="text-yellow-p1 small-text mb-0 ml-1 lowercase">{{
        'AIS_SIGN_UP.LOGIN_INFO_BANNER.DESCRIPTION_INFO' | translate
      }}</span>
    </span>
  </div>
</div>
