/* eslint-disable @typescript-eslint/naming-convention */
import { CommunitiesFeatureToggle } from '../app/shared/feature-toggle/models/communities-feature-toggle.model';

export const featureToggle: CommunitiesFeatureToggle = {
  PIPZ_EVENTS: false,
  RCX: true,
  MY_FINANCES: true,
  MULTI_JOURNEY_ENGINE: true,
  SUPER_DELETE_USER: true,
  DOCUMENTS: false,
  SUBSCRIPTION_PAYMENT_TYPE_SELECTION: true,
  MARKETPLACE: true,
  BILLING_MENU: true,
  CondoReintegration: true,
  DigitalVotingCrossSell: true,
  FormatCurrencyString: true,
  BoletoDuplicate: true,
  DownloadChargeDocuments: true,
  SuperUsersManagement: true,
  CommunitiesUsersManagement: true,
  CondoReintegrationExportData: true,
  mailOrders: {
    settings: true,
    dashboardFeature: true,
  },
  loginAis: true,
  adminDeleteComments: true,
  clickableUrls: {
    requests: true,
    assignments: true,
    archReview: true,
  },
  taskCreatorAssignments: true,
  analyticsInsights: true,
  privateCategoryForum: true,
  dashboard: {
    reports: true,
    onHoldReports: false,
    pendo: true,
    requestInformation: false,
  },
  newOffersMenuPosition: true,
  goodwinAdminEditEmail: true,
  workspaceGuardOnReservations: true,
  requestsXlsxReport: true,
  newLogin: true,
  violationsLoginImprovements: true,
  fixOnRequestsFilter: true,
  reservationsUserWithNoProps: true,
  registration: {
    signUp: true,
    loginForRegisteredUsers: true,
    addressInReviewYourProfile: true,
    automaticLogin: true,
  },
  myInvitesEndpointChange: true,
  groupsEndpointUpdate: true,
  luxuryCustomFields: true,
  termsOfUse: {
    signUp: true,
    login: true,
    selfService: true,
  },
  downloadUsersButtonForAdmins: true,
  assignGroupToRequest: true,
  luxuryNotesBonvoyNumber: true,
  emptyStates: {
    discussion: true,
    invites: true,
    residents: true,
    announcements: true,
    events: true,
    documents: true,
    homeFeed: true,
    homeInbox: true,
    messages: true,
    surveys: true,
    requests: true,
  },
  searchHeader: true,
  petRegistry: true,
  petVaccinationRecord: true,
  showDiscoverySection: true,
  inAppFeedback: true,
  valetParking: true,
  saveAccountErrorMessage: true,
  accountRedirect: true,
  addAccountFix: true,
  facilitiesEndpointUpdate: true,
  serviceLogs: true,
  eventsRefactor: {
    eventsList: true,
    details: true,
    rsvp: true,
    uiChanges: true,
    detailsAdmin: true,
    creationModal: true,
    specifyExternalGuests: true,
    allowExternalGuests: true,
    messageToEditEvent: true,
    adminToTrackExternalGuests: true,
    allowComments: true,
    newSelectionOfInvitedGroups: true,
    downloadGuestsReport: true,
    externalGuestLimit: true,
    allowGuestRsvp: true,
    guestListWithNoResponse: true,
  },
  emergencyBroadcast: true,
  conciergeHub: true,
  keyManagement: true,
  preventDefaultGroupsDeletion: true,
  login: {
    sso: {
      enabled: true,
    },
  },
  bahamasCountryOption: true,
  customFieldsDragAndDrop: true,
  updateUserAfterGroupsActions: true,
  notifyResidentsInfoBanner: true,
  customFieldsReport: {
    communitySettings: true,
  },
  accessControl: {
    entryLogs: true,
  },
  unitsRefactor: true,
  pinnedOnly: true,
  residentsNewHeader: true,
  aiFeatures: {
    branchConfig: true,
  },
  emptyStatePayments: true,
  hasPropertyQueryParam: true,
  entryLogsReauthorization: true,
  performanceReview: {
    trusteeDiscussion: true,
  },
  reservationsRangeFix: true,
  simplifiedMenu: true,
  includeOneUnitIfExistsParam: true,
  enableServiceLogsAllGroups: true,
  enableResidentEndpointUpdate: true,
  showUnitStats: true,
  localSearch: {
    requests: true,
    residents: true,
    announcements: true,
    events: true,
    surveys: true,
    groups: true,
    digitalVoting: true,
  },
  enableNewPinnedFilter: true,
  passwordRecovery: true,
  pendoSegmentationIncludeFeaturesDetails: true,
  signUpSimplification: true,
  loginSimplification: true,
  ssoForNewLogin: false,
};
