import { Routes } from '@angular/router';

import { ChangePasswordComponent } from './containers/change-password/change-password.component';
import { RequestPasswordRecoveryComponent } from './containers/request-password-recovery/request-password-recovery.component';
import { newPasswordRecoveryFlowGuard } from './guards/new-password-recovery-flow.guard';
import { UserRecoveryFormComponent } from './user-recovery-form/user-recovery-form.component';
import { UserRecoveryComponent } from './user-recovery.component';

export const userRecoveryRoutes: Routes = [
  {
    path: 'user-recovery',
    component: RequestPasswordRecoveryComponent,
    canMatch: [newPasswordRecoveryFlowGuard()],
  },
  {
    path: 'user-recovery',
    component: UserRecoveryFormComponent,
  },
  {
    path: 'user-recovery/:userId/:token',
    component: ChangePasswordComponent,
    canMatch: [newPasswordRecoveryFlowGuard()],
  },
  {
    path: 'user-recovery/:userId/:token',
    component: UserRecoveryComponent,
  },
];
