<ng-template *ngIf="!confirming; then linkLogged; else linkConfirmation"></ng-template>

<ng-template #linkLogged>
  <div data-cy="ais-link-logged--div--link-logged" class="page-container">
    <h1>{{ 'AIS_SIGN_UP.LINK_LOGGED' | translate }}</h1>
    <p>{{ 'AIS_SIGN_UP.LINK_LOGGED_CAPTION' | translate }}</p>

    <div class="form-group">
      <label for="email">{{ 'AIS_SIGN_UP.TSQ_USER_EMAIL' | translate }}</label>
      <input id="email" class="form-control" [formControl]="emailControl" />
    </div>

    <div class="flex grow-0 flex-row justify-end">
      <button class="btn btn-secondary mr-8" routerLink="/login">
        {{ 'AIS_SIGN_UP.LOGIN' | translate }}
      </button>

      <button
        data-cy="ais-link-logged--button--continue"
        class="btn btn-primary"
        (click)="onContinue()"
      >
        {{ 'AIS_SIGN_UP.CONTINUE' | translate }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #linkConfirmation>
  <div data-cy="ais-link-logged--div--link-confirmation" class="page-container">
    <p [innerHtml]="linkWarning"></p>

    <div class="tsq-card" (click)="understandWarning = !understandWarning">
      <div>
        <img src="./assets/img/icons/ic-check-avocado.svg" *ngIf="understandWarning" alt="" />
      </div>
      <div>{{ 'AIS_SIGN_UP.WARNING_UNDERSTAND' | translate }}</div>
    </div>

    <div class="warning-actions">
      <button data-cy="ais-link-logged--button--cancel" class="btn white-btn" (click)="onCancel()">
        {{ 'CANCEL' | translate }}
      </button>
      <button
        data-cy="ais-link-logged--button--continue"
        class="btn btn-danger"
        *ngIf="!isSending"
        [disabled]="!understandWarning"
        (click)="onLinkLogged()"
      >
        Link
      </button>
      <tsq-small-loader *ngIf="isSending"></tsq-small-loader>
    </div>
  </div>
</ng-template>
