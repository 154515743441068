import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { CreationStep } from '../../models/step.model';

@Component({
  selector: 'tsq-steps-status',
  templateUrl: './steps-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepsStatusComponent {
  @Input() creationSteps: CreationStep[];
  @Input() activeStep: number;

  gridClass(): string {
    return `grid grid-cols-${this.creationSteps.length}`;
  }
}
