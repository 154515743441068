<div class="comment-list-header">
  <h3>{{ (title || 'COMMENTS') | translate }}</h3>
  <button [title]="'LIBS.SCROLL_TO_BOTTOM' | translate"
          [disabled]="!hasScroll"
          class="btn btn-secondary"
          (click)="onScrollBottom(); btn.blur()" #btn>
    <img src="./assets/libs/icons/arrow.svg"
         [ngClass]="hasScroll ? 'filter-primary' : 'filter-coal-disabled'" alt="">
  </button>
</div>
<div>

  <div *ngIf="loading">
    <tsq-loader></tsq-loader>
  </div>

  <ng-container *ngIf="!loading && !error">
    <ng-content *ngIf="!!length"></ng-content>

    <div class="zero-data" *ngIf="!length">
      <img src="./assets/libs/icons/no-comments.svg" class="filter-text-primary" alt="">
      <p>{{ (zeroDataDescription || 'NO_COMMENTS') | translate }}</p>
    </div>
  </ng-container>

  <tsq-small-loader *ngIf="!loading && loadingMore && !error"></tsq-small-loader>

  <div *ngIf="!loading && error">
    <tsq-error (retry)="retry.emit()"></tsq-error>
  </div>

</div>
