import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { distinctUntilChanged } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'comm-charge-value',
  templateUrl: './charge-value.component.html',
  styleUrls: ['./charge-value.component.scss'],
})
export class ChargeValueComponent implements OnInit {
  @Output() chargeValueChanged = new EventEmitter<number>();

  form: UntypedFormGroup = new UntypedFormGroup({
    chargeValue: new UntypedFormControl(''),
  });

  ngOnInit(): void {
    this.form.controls.chargeValue.valueChanges
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe(value => this.emitChange(value));
  }

  @Input() set chargeValue(value: number) {
    this.initChargeValue(value);
  }

  emitChange(value: number): void {
    this.chargeValueChanged.emit(value);
  }

  initChargeValue(value: number): void {
    this.form.controls.chargeValue.setValue(value);
  }
}
