import { createReducer, on, Action } from '@ngrx/store';

import { CondoAndSubscription } from '../../condo-and-subscription.json';
import * as fromSubscriptionAction from './subscription.actions';

export interface SubscriptionState {
  condoAndSubscription: CondoAndSubscription;
  loading: boolean;
  error: boolean;
}

export const subscriptionFeatureKey = 'subscription';

export const subscriptionInitialState: SubscriptionState = {
  condoAndSubscription: undefined,
  loading: false,
  error: false,
};

const reducer = createReducer(
  subscriptionInitialState,
  on(fromSubscriptionAction.loadCondoAndSubscription, state => ({
    ...state,
    loading: true,
    error: false,
  })),
  on(fromSubscriptionAction.loadCondoAndSubscriptionSuccess, (state, { condoAndSubscription }) => ({
    ...state,
    loading: false,
    condoAndSubscription,
  })),
  on(fromSubscriptionAction.loadCondoAndSubscriptionFailure, state => ({
    ...state,
    loading: false,
    error: true,
  })),
);

export function subscriptionReducer(
  state: SubscriptionState | undefined,
  action: Action,
): SubscriptionState {
  return reducer(state, action);
}
