import { ErrorHandler, Injectable } from '@angular/core';

import { getEnvironment } from '../../environment';

@Injectable()
export class TSqErrorHandler implements ErrorHandler {
  handleError(error: unknown): void {
    if (!getEnvironment().production) {
      console.error(error);
    }
  }
}
