<div
  class="col-sm-{{ listWidth }} col-md-{{ listWidth }}"
  infiniteScroll
  [infiniteScrollDistance]="0.5"
  [infiniteScrollThrottle]="20"
  [infiniteScrollContainer]="'.root-scrollable-content'"
  [scrollWindow]="false"
  [fromRoot]="true"
  [alwaysCallback]="true"
  (scrolled)="onScroll($event)"
>
  <div
    [class.row]="panelIsRow"
    [class.panel]="!showNewHeader && (panel || loading || listSize === 0)"
    [class.panel-transparent]="!showNewHeader"
    [ngClass]="{ 'flex flex-col gap-16': showNewHeader }"
    data-widget='{"draggable": "false"}'
  >
    <ng-content select="[pre-header]"></ng-content>
    <ng-container *ngIf="showNewHeader; else oldHeader">
      <tsq-feature-header
        [showSearchInput]="!featureToggle.localSearch.residents"
        [title]="title"
        [(search)]="search"
        [text]="caption"
        [searchPlaceholder]="newHeaderSearchPlaceholder"
        [customSearchWidth]="newHeaderCustomSearchWidth"
      >
        <ng-content select="[newHeaderActions]"></ng-content>
      </tsq-feature-header>
    </ng-container>
    <ng-template #oldHeader>
      <div class="panel-heading">
        <div class="title flex justify-between">
          <div>
            <span
              *ngIf="parentRoute !== undefined"
              class="parent-link"
              [routerLink]="['..', parentRoute]"
              >{{ title }}</span
            >
            <span *ngIf="parentRoute === undefined">{{ title }}</span>
            <span *ngIf="subtitle !== '' && subtitle !== undefined">/</span>
            <span *ngIf="subtitle !== '' && subtitle !== undefined" class="subtitle">
              {{ subtitle }}
            </span>
          </div>

          <div>
            <tsq-button
              *ngIf="showConfig"
              [text]="'MANAGE' | translate"
              [schema]="'kale'"
              [icon]="icons.gear"
              (clicked)="configIconClicked()"
            ></tsq-button>

            <a
              id="report-button"
              *ngIf="showReport"
              class="pull-right"
              (click)="reportIconClicked()"
            >
              <img
                *ngIf="reportIcon === undefined"
                class="btn-cfg"
                src="./assets/img/icons/report.svg"
                alt="report"
              />
              <img
                *ngIf="reportIcon !== undefined"
                class="btn-cfg"
                src="{{ reportIcon }}"
                alt="report"
              />
            </a>
          </div>
        </div>

        <div *ngIf="showDivider" class="divider"></div>

        <div *ngIf="showToolbar" class="toolbar">
          <div class="toolbar-text">
            <p>{{ caption }}</p>
          </div>
          <div *ngIf="showSearch && !loading && !error" class="search col-md-4 pull-left">
            <div class="inner-addon-sm left-addon">
              <i class="fa fa-search"></i>
              <input
                id="list-search"
                [formControl]="filterControl"
                value="{{ search }}"
                type="text"
                placeholder="{{ searchPlaceholder }}"
                class="form-control input-sm"
              />
            </div>
          </div>
          <div [hidden]="loading || error" class="buttons pull-right">
            <ng-content select="[actions]"></ng-content>
          </div>
        </div>
      </div>
      <br />
    </ng-template>

    <div *ngIf="showHeaderSpace">
      <div class="header-space">
        <div class="top-draw mt-16"></div>
      </div>
    </div>
    <div *ngIf="showNavigation">
      <ng-content select="[navigation]"></ng-content>
    </div>
    <div *ngIf="featureToggle.localSearch.residents">
      <ng-content select="[filter]" />
    </div>
    <div
      [hidden]="loading || listSize === 0 || error"
      [ngClass]="{ 'rounded bg-white': showNewHeader }"
    >
      <ng-content select="[list]"></ng-content>
    </div>
    <div *ngIf="loading && !error" class="row">
      <div class="col-md-2 col-sm-6 col-xs-8 col-md-offset-5 col-sm-offset-3 col-xs-offset-2">
        <div *ngIf="!simplified" class="loader-container animated fadeIn">
          <tsq-loader></tsq-loader>
        </div>
        <div *ngIf="simplified" class="margin-bottom-10">
          <tsq-small-loader></tsq-small-loader>
        </div>
      </div>
    </div>
    <div *ngIf="loadingMore && !error">
      <div class="loader-container animated fadeIn">
        <tsq-small-loader></tsq-small-loader>
      </div>
    </div>
    <div *ngIf="!loading && listSize === 0 && searchFor.length === 0 && !error" class="row">
      <div
        class="col-xs-12"
        [ngClass]="{
          'col-lg-8 col-md-8 col-sm-10 col-lg-offset-2 col-md-offset-2 col-sm-offset-1':
            hasZeroDataOffset
        }"
      >
        <ng-content select="[zero-data]"></ng-content>
      </div>
    </div>
    <div *ngIf="!loading && listSize === 0 && searchFor.length > 0 && !error" class="row">
      <div
        class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12"
      >
        <sc-zero-data
          topText="{{ 'SEARCH_EMPTY_TOP' | translate : { value: searchFor } }}"
          bottomText="{{ 'SEARCH_EMPTY_BOTTOM' | translate }}"
          illustrationSrc="./assets/svg/empty-search.svg"
        >
        </sc-zero-data>
      </div>
    </div>
    <div *ngIf="!loading && error" class="row">
      <div
        class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12"
      >
        <sc-error (retry)="onRetry($event)"></sc-error>
      </div>
    </div>
  </div>
</div>
