import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { LetModule } from '@ngrx/component';
import { BehaviorSubject, Observable, combineLatest, map } from 'rxjs';

import { AssetsModule } from '@tsq-web/assets';

import { TableColumnIcon, TableColumnIconReturnType } from '../../models/table.model';
import { TableCellBaseComponent } from '../table-cell-base/table-cell-base.component';

@Component({
  standalone: true,
  imports: [NgIf, LetModule, AssetsModule, TableCellBaseComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'tsq-table-cell-icon',
  template: `
    <tsq-table-cell-base
      class="text-coal-secondary"
      *ngrxLet="iconConfig$ as iconConfig"
      size="small"
    >
      <tsq-icon
        *ngIf="!!iconConfig"
        [icon]="iconConfig.icon"
        [classes]="iconConfig.classes"
        [childClasses]="iconConfig.childClasses"
      />
    </tsq-table-cell-base>
  `,
})
export class TableCellIconComponent<TRowData> {
  private readonly contentSubject = new BehaviorSubject<
    TableColumnIcon<TRowData>['content'] | undefined
  >(undefined);
  private readonly rowDataSubject = new BehaviorSubject<TRowData | undefined>(undefined);

  readonly iconConfig$: Observable<TableColumnIconReturnType> = combineLatest([
    this.contentSubject.asObservable(),
    this.rowDataSubject.asObservable(),
  ]).pipe(
    map(([content, rowData]) => {
      if (!content || !rowData) {
        return undefined;
      }

      if (typeof content.value === 'function') {
        return content.value(rowData);
      } else if ('key' in content.value && 'transform' in content.value) {
        return content.value.transform(rowData[content.value.key]);
      }

      return undefined;
    }),
    map(config => {
      if (!config) {
        return undefined;
      }

      return {
        icon: config.icon,
        classes: ((config.classes || '') + ' !size-24').trim(),
        childClasses: config.childClasses,
      };
    }),
  );

  @Input()
  set content(value: TableColumnIcon<TRowData>['content']) {
    this.contentSubject.next(value);
  }

  @Input()
  set rowData(value: TRowData) {
    this.rowDataSubject.next(value);
  }
}
