import IMask from 'imask';

export function getCurrencyMask(lang: string): IMask.AnyMaskedOptions {
  const currencySymbol = lang === 'pt' ? 'R$' : '$';

  return {
    mask: `${currencySymbol}num`,
    blocks: {
      num: {
        mask: Number,
        scale: 2,
        min: 0,
        padFractionalZeros: true,
        radix: lang === 'en' ? '.' : ',',
        mapToRadix: lang === 'en' ? [','] : ['.'],
      },
    },
  };
}

export function getCurrencyMaskMin1(lang: string): IMask.AnyMaskedOptions {
  const currencySymbol = lang === 'pt' ? 'R$' : '$';

  return {
    mask: `${currencySymbol}num`,
    blocks: {
      num: {
        mask: Number,
        scale: 2,
        min: 0.01,
        padFractionalZeros: true,
        radix: lang === 'en' ? '.' : ',',
        mapToRadix: lang === 'en' ? [','] : ['.'],
      },
    },
  };
}

export function getNegativeCurrencyMask(lang: string): IMask.AnyMaskedOptions {
  const currencySymbol = lang === 'pt' ? 'R$' : '$';

  return {
    mask: `${currencySymbol}num`,
    blocks: {
      num: {
        mask: Number,
        scale: 2,
        min: -999999999999999,
        signed: true,
        padFractionalZeros: true,
        radix: lang === 'en' ? '.' : ',',
        mapToRadix: lang === 'en' ? [','] : ['.'],
        autofix: true,
      },
    },
  };
}

export function currencyToNumber(value: string): number {
  return !!value ? Number(value.replace(/[^\d|,.]+/g, '').replace(',', '.')) * 100 : 0;
}

export function numberToCurrency(value: number): string {
  return (value / 100).toFixed(2);
}
