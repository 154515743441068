export enum ModalTypes {
  ConfirmationDialog = 'ConfirmationDialog',
  AUDIT_LOGS_FILTER = 'AUDIT_LOGS_FILTER',
  DOCUMENTS_CREATE = 'DOCUMENTS_CREATE',
  DOCUMENTS_MANAGE = 'DOCUMENTS_MANAGE',
  DOCUMENTS_FILTER = 'DOCUMENTS_FILTER',
  ORGANIZATIONS_CREATE = 'ORGANIZATIONS_CREATE',
  ORGANIZATIONS_EDIT = 'ORGANIZATIONS_EDIT',
  RESIDENT_INVITE_ADD_TO_GROUP = 'RESIDENT_INVITE_ADD_TO_GROUP',
  REQUEST_CHANGE_CATEGORY = 'REQUEST_CHANGE_CATEGORY',
  REQUESTS_ASSIGNEE_SELECTION = 'REQUESTS_ASSIGNEE_SELECTION',
  SHORTCUTS_DELETE = 'SHORTCUTS_DELETE',
  TSQ_DELETE_CONFIRMATION = 'TSQ_DELETE_CONFIRMATION',
  WORKSPACE_CREATION_CONFIRMATION = 'WORKSPACE_CREATION_CONFIRMATION',
  ARCHITECTURAL_REVIEW_MANAGE = 'ARCHITECTURAL_REVIEW_MANAGE',
  ARCHITECTURAL_REVIEW_FILTER = 'ARCHITECTURAL_REVIEW_FILTER',
  BOLETO_CREATE = 'BOLETO_CREATE',
  TSQ_FEATURE_WRAPPER_APPROVE_COMMENT = 'TSQ_FEATURE_WRAPPER_APPROVE_COMMENT',
  ARCHITECTURAL_REVIEW_VOTING_MEMBERS = 'ARCHITECTURAL_REVIEW_VOTING_MEMBERS',
  ARCHITECTURAL_REVIEW_PROJECT_VOTE = 'ARCHITECTURAL_REVIEW_PROJECT_VOTE',
  ARCHITECTURAL_REVIEW_PUBLISH = 'ARCHITECTURAL_REVIEW_PUBLISH',
  ARCHITECTURAL_REVIEW_VERDICT_LETTER = 'ARCHITECTURAL_REVIEW_VERDICT_LETTER',
  ArchitecturalReviewPayAnotherWay = 'ArchitecturalReviewPayAnotherWay',
  ArchitecturalReviewManagerConfirmPayment = 'ArchitecturalReviewManagerConfirmPayment',
  ArchitecturalReviewConfirmPayment = 'ArchitecturalReviewConfirmPayment',
  ArchitecturalReviewHomeowners = 'ArchitecturalReviewHomeowners',
  ArchitecturalReviewOverrideResult = 'ArchitecturalReviewOverrideResult',
  ArchitecturalReviewArchiveProject = 'ArchitecturalReviewArchiveProject',
  ArchitecturalReviewFinalize = 'ArchitecturalReviewFinalize',
  ArchitecturalReviewUploadVerdictLetter = 'ArchitecturalReviewUploadVerdictLetter',
  SEEN_BY = 'SEEN_BY',
  BOLETO_EDIT = 'BOLETO_EDIT',
  PackageControlPickUp = 'PackageControlPickUp',
  PackageControlNotifyResident = 'PackageControlNotifyResident',
  PackageControlCreate = 'PackageControlCreate',
  PackageControlManage = 'PackageControlManage',
  RequestFeedbackModal = 'RequestFeedbackModal',
  SurveysFilter = 'SurveysFilter',
  SurveysCreate = 'SurveysCreate',
  SurveysViewVotes = 'SurveysViewVotes',
  CommunityContextSelectorModal = 'ContextSelectorModalComponent',
  CommunityContextSelectorSaveChangesModal = 'CommunityContextSelectorSaveChangesModal',
  DigitalVotingFilter = 'DigitalVotingFilter',
  DigitalVotingCreate = 'DigitalVotingCreate',
  DigitalVotingConfirmBallot = 'DigitalVotingConfirmBallot',
  DigitalVotingViewVoters = 'DigitalVotingViewVoters',
  WorkspaceRequestChangeSourceModal = 'WorkspaceRequestChangeSourceModal',
  WorkspaceRequestChangePeriodModal = 'WorkspaceRequestChangePeriodModal',
  WorkspaceRequestActionItemFlowchartModal = 'WorkspaceRequestActionItemFlowchartModal',
  BoardMemberConfigurationDetails = 'BoardMemberConfigurationDetails',
  BoardMemberConfigurationPositions = 'BoardMemberConfigurationPositions',
  NotificationModal = 'NotificationModal',
  CmTaskTrackingDetailsResolve = 'CmTaskTrackingDetailsResolve',
  OfferDetails = 'OfferDetails',
  CmWorkOrdersInvalidDefaultTypeModal = 'CmWorkOrdersInvalidDefaultTypeModal',
  DownloadReport = 'DowloadReport',
  ReportsModal = 'ReportsModal',
  BoardMemberAdded = 'BoardMemberAdded',
  RequestDetailFromFeedback = 'RequestDetailFromFeedback',
  EditPlanConfirmationModal = 'EditPlanConfirmationModal',
  ArchitecturalReviewFilterModal = 'ArchitecturalReviewFilterModal',
  NoAccountModal = 'NoAccountModal',
  CmFactTypesSetup = 'CmFactTypesSetup',
  DashboardReportsModal = 'DashboardReportsModal',
  ViolationsSendNotification = 'ViolationsSendNotification',
  CmAssociationGroupsByGroup = 'CmAssociationGroupsByGroup',
  CmUserAccessProfile = 'CmUserAccessProfile',
  ViolationsViewHomeowners = 'ViolationsViewHomeowners',
  ViolationsListFilterModal = 'ViolationsListFilterModal',
  ViolationsReportModal = 'ViolationReportModal',
  DocumentsDetailsModal = 'DocumentsDetailsModal',
  CmNewPeriodModal = 'CmNewPeriodModal',
  CmNoInfoModal = 'CmNoInfoModal',
  CmDeleteConfirmationModal = 'CmDeleteConfirmationModal',
  DocumentsSeenByModal = 'DocumentsSeenByModal',
  CmSidePanelModal = 'CmSidePanelModal',
  CondoReintegrationReportErrorModal = 'CondoReintegrationReportErrorModal',
  CondoReintegrationConfirmModal = 'CondoReintegrationConfirmModal',
  CondoReintegrationMatchingIntelligenceModal = 'CondoReintegrationMatchingIntelligenceModal',
  CondoReintegrationMatchingIntelligenceConfirmModal = 'CondoReintegrationMatchingIntelligenceConfirmModal',
  CmcwoNotificationModal = 'CmcwoNotificationModal',
  CmReimbursablesReimbursementItemModal = 'CmReimbursablesReimbursementItemModal',
  AssignmentsFilterModal = 'AssignmentsFilterModal',
  CmcaSubaccountModal = 'CmcaSubaccountModal',
  CmcaInformativeModal = 'CmcaInformativeModal',
  CmcaGeneralModal = 'CmcaGeneralModal',
  CmSaveProofTemplateModal = 'CmSaveProofTemplateModal',
  RecoverLastDeletedAnnouncementModal = 'RecoverLastDeletedAnnouncementModal',
  AssignmentsManageModal = 'AssignmentsManageModal',
  CmTaskTrackingDetailsChangeAssignmentModal = 'CmTaskTrackingDetailsChangeAssignmentModal',
  CmTaskTrackingDetailsCancelTaskModal = 'CmTaskTrackingDetailsCancelTaskMFodal',
  ArchitecturalReviewLetterFilterModal = 'ArchitecturalReviewLetterFilterModal',
  CmTaskTrackingDetailsChangeAccountModal = 'CmTaskTrackingDetailsChangeAccountModal',
  OfspCategoryFilterModal = 'OfspCategoryFilterModal',
  OfspCategorySituationModal = 'OfspCategorySituationModal',
  OfspCategoryCreate = 'OfspCategoryCreate',
  OfspOfferCreate = 'OfspOfferCreate',
  OfspOfferSituationModal = 'OfspOfferSituationModal',
  OfspOfferFilterModal = 'OfspOfferFilterModal',
  OfspOfferDetaillModal = 'OfspOfferDetailModal',
  OfspOfferSortingListlModal = 'OfspOfferSortingListlModal',
  OfspPartnerSituationModal = 'OfspPartnerSituationModal',
  OfspEnableDisableCondoModal = 'OfspEnableDisableCondoModal',
  OfspCatalogFilterModal = 'OfspCatalogFilterModal',
  OfspCategoryDeleteModal = 'OfspCategoryDeleteModal',
  EditCommentModal = 'EditCommentModal',
  DeleteCommentModal = 'DeleteCommmentModal',
  ViolationsSettingsManage = 'ViolationsSettingsManage',
  DeleteModal = 'DeleteModal',
  LoginAndPasswordModal = 'LoginAndPasswordModal',
  CategoryNotificationsSettingModal = 'CategoryNotificationsSettingModal',
  DownloadCenterModal = 'DownloadCenterModal',
  DeleteChargeModal = 'DeleteChargeModal',
  RequestsManagerModal = 'RequestsManagerModal',
  InkOrdersListFilterModal = 'InkOrdersListFilterModal',
  CreateChargeModal = 'CreateChargeModal',
  SelfServiceSubscribeModal = 'SelfServiceSubscribeModal',
  AddMailOrderReimbursableModal = 'AddMailOrderReimbursableModal',
  UserDeleteConfirmModal = 'UserDeleteConfirmModal',
  SuperUserPermissionModal = 'SuperUserPermissionModal',
  ReviewMailSettingsModal = 'ReviewMailSettingsModal',
  CommunitieUserModal = 'CommunitieUserModal',
  ActionItemsListFilterModal = 'ActionItemsListFilterModal',
  ActionItemsListColumnsModal = 'ActionItemsListColumnsModal',
  ActionItemsStartFlowModal = 'ActionItemsListColumnsModal',
  ActionItemsChangeStatusModal = 'ActionItemsChangeStatusModal',
  DeleteCommentManagerModal = 'DeleteCommentManagerModal',
  WorkflowsFilterModal = 'WorkflowsFilterModal',
  WorkflowsCategoryModal = 'WorkflowsCategoryModal',
  WorkflowsAssigneeModal = 'WorkflowsAssigneeModal',
  CmDelinquencyStatusModal = 'CmDelinquencyStatusModal',
  ArchictecturalReviewCommunitiesEditionModal = 'ArchictecturalReviewCommunitiesEditionModal',
  ArchitecturalReviewCategoriesFilterModal = 'ArchitecturalReviewCategoriesFilterModal',
  EditInkOrdersAddressModal = 'EditInkOrdersAddressModal',
  RequestFeedbackModalV2 = 'RequestFeedbackModalV2',
  AnalyticsInsightsRequestsZeroDataModal = 'AnalyticsInsightsRequestsZeroDataModal',
  CondoReintegrationExportModal = 'CondoReintegrationExportModal',
  ChooseMailOrderModal = 'ChooseMailOrderModal',
  CondoReintegrationCancelModal = 'CondoReintegrationCancelModal',
  CondoReintegrationFinishSimulationModal = 'CondoReintegrationFinishSimulationModal',
  CondoReintegrationRemoveAccountingModal = 'CondoReintegrationRemoveAccountingModal',
  ResidentsEditEmailModal = 'ResidentsEditEmailModal',
  DocumentPreviewModal = 'DocumentPreviewModal',
  DocumentsCommunitySelectionModal = 'DocumentsCommunitySelectionModal',
  CmAccountLookupModal = 'CmAccountLookupModal',
  CmWorkOrderLookupModal = 'CmWorkOrderLookupModal',
  InvitesModal = 'InvitesModal',
  DeclineInvitationModal = 'DeclineInvitationModal',
  AssignmentsFilterModalV2 = 'AssignmentsFilterModalV2',
  ResidentsExtraInfoModal = 'ResidentsExtraInfoModal',
  CommunitySettingsCustomFormModal = 'CommunitySettingsCustomFieldsModal',
  InkOrdersSummaryModal = 'InkOrderSummaryModal',
  MailManagementUploadNewsletterModal = 'MailManagementUploadNewsletterModal',
  CommunitiesUsersRolesConfirmModal = 'CommunitiesUsersRolesConfirmModal',
  CommunitiesUsersWarningModal = 'CommunitiesUsersWarningModal',
  UnitsExtraInfoModal = 'UnitsExtraInfoModal',
  PetRegistryAddEditModal = 'PetRegistryAddEditModal',
  VehicleRegistryAddEditModal = 'VehicleRegistryAddEditModal',
  InAppFeedbackModal = 'InAppFeedbackModal',
  RequestVehicleModal = 'RequestVehicleModal',
  PdfPagePreviewModal = 'PdfPagePreviewModal',
  GenerateC3TokenModal = 'GenerateC3TokenModal',
  ViewJsonModal = 'ViewJsonModal',
  EmergencyBroadcastModal = 'EmergencyBroadcastModal',
  DeleteEventModal = 'DeleteEventModal',
  DailyUpdatesModal = 'DailyUpdatesModal',
  OnboardUsersModal = 'OnboardUsersModal',
  OnboardUsersAlertModal = 'OnboardUsersAlertModal',
  OnboardValidateModal = 'OnboardValidateModal',
  OnboardRecommendationModal = 'OnboardRecommendationModal',
  OnboardMultiAccountModal = 'OnboardMultiAccountModal',
  BatchListFilterModalComponent = 'BatchListFilterModalComponent',
  ResidentsManageModal = 'ResidentsManageModalComponent',
  EditVotingFractionModal = 'EditVotingFractionModal',
  UnitsManageModal = 'UnitsManageModalComponent',
  InkOrderXmlErrorsModal = 'InkOrderXmlErrorsModal',
  CreateUnitModal = 'CreateUnitModal',
  GoBackFromCreatePasswordModal = 'GoBackFromCreatePasswordModal',
  AddDocumentsModal = 'AddDocumentsModal',
  UpdateDocumentModal = 'UpdateDocumentModal',
  CreateUpdateDocumentsFoldersModal = 'CreateUpdateDocumentsFoldersModal',
  ArchReviewMoreFilterModal = 'ArchReviewMoreFilterModal',
  OffersThumbtackModal = 'OffersThumbtackModal',
  AssignmentsMoreFilterModal = 'AssignmentsMoreFilterModal',
  MoveDocumentsFoldersModal = 'MoveDocumentsFoldersModal',
  CondoDeactivateModal = 'CondoDeactivateModal',
  ImportInkCommunityPreferences = 'ImportInkCommunityPreferences',
  EditNotificationRule = 'EditNotificationRule',
  DigitalVotingMoreFilterModal = 'DigitalVotingMoreFilterModal',
  CommunitiesThumbtackModal = 'CommunitiesThumbtackModal',
  CmReimbursementItemsModal = 'CmReimbursementItemsModal',
  CmPrefillModal = 'CmPrefillModal',
  ServiceLogsMoreFiltersModal = 'ServiceLogsMoreFiltersModal',
  CmVendorLookupModal = 'CmVendorLookupModal',
  WorkspaceOverviewFilter = 'WorkspaceOverviewFilter',
  WorkspaceFilter = 'WorkspaceFilter',
  AdministratorsFilterModal = 'AdministratorsFilterModal',
  AdministratorsInactivateReasonModal = 'AdministratorsInactivateReasonModal',
  AdministratorsDeactivateConfirmModal = 'AdministratorsDeactivateConfirmModal',
  OrganizationDeactivationReasonModal = 'OrganizationDeactivationReasonModal',
  OrganizationReactivationModal = 'OrganizationReactivationModal',
  GenerateInkBackOfficeReportModal = 'GenerateInkBackOfficeReportModal',
  InkOrdersXmlWarningsModal = 'InkOrdersXmlWarningsModal',
  InkOrdersPreferencesCouponTemplatePreview = 'InkOrdersPreferencesCouponTemplatePreview',
  AccountFeatureModal = 'AccountFeatureModal',
  AiFeedbackModal = 'AiFeedbackModal',
  CancelAccountLinkingModal = 'CancelAccountLinkingModal',
  DeactivateModal = 'DeactivateModal',
  SubmitRequestModal = 'SubmitRequestModal',
  ReactivateModal = 'ReactivateModal',
  CondoReactivateDemoModal = 'CondoReactivateDemoModal',
}
