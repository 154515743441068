import {Action, createReducer, on} from '@ngrx/store';

import * as fromLocalizationActions from './localization.actions';

export interface LocalizationState {
  language: string;
  timeZone: string;
}

export const localizationInitialState: LocalizationState = {
  language: undefined,
  timeZone: undefined,
};

const reducer = createReducer(
  localizationInitialState,
  on(fromLocalizationActions.setLanguage, (state, {language}) => ({
    ...state,
    language,
  })),
  on(fromLocalizationActions.clearLanguage, state => ({
    ...state,
    language: undefined,
  })),
  on(fromLocalizationActions.setTimeZone, (state, {timeZone}) => ({
    ...state,
    timeZone,
  })),
  on(fromLocalizationActions.clearTimeZone, state => ({
    ...state,
    timeZone: undefined,
  })),
);

export function localizationReducer(state: LocalizationState, action: Action): LocalizationState {
  return reducer(state, action);
}
