import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'tsq-accordion',
  templateUrl: './tsq-accordion.component.html',
  styleUrls: ['./tsq-accordion.component.scss'],
})
export class TSqAccordionComponent {
  @Input() title: string;
  @Input() hasBodyPadding = true;

  @Output() openChange = new EventEmitter();

  private _open;

  @Input() set open(value: boolean) {
    this._open = value;
  }

  get open(): boolean {
    return this._open;
  }

  toggleTab(): void {
    this.open = !this.open;
    this.openChange.emit(this.open);
  }
}
