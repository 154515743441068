/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs/internal/Observable';
import { UntilDestroy } from '@ngneat/until-destroy';

import { AppState } from '@tsq-web/state';
import { BaseService } from '@tsq-web/core';
import { UpsellSettings } from '@tsq-web/condo';
import { selectUntilDestroyed } from '@tsq-web/redux/operators';
import { fromUserContextSelectors } from '@tsq-web/user-context';

@Injectable()
@UntilDestroy()
export class UpsellSettingsService extends BaseService {
  private condoId: string;

  constructor(
    protected _cookieService: CookieService,
    protected _http: HttpClient,
    protected _router: Router,
    private store: Store<AppState>,
  ) {
    super(_cookieService, _http, _router);

    store
      .pipe(selectUntilDestroyed(fromUserContextSelectors.selectCondo, this))
      .subscribe(condo => (this.condoId = condo?.id));
  }

  patchUpsellSettings(upsellSettings: UpsellSettings): Observable<UpsellSettings> {
    return this.doPatch(`condos/${this.condoId}/upsell-settings`, { ...upsellSettings }, 'v1');
  }
}
