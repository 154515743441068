<div class="navbar">
  <div class="left-action" (click)="onBack()" *ngIf="!!prevUrl">
    <img src="/libs/assets/icons/angle.svg"
         class="filter-primary"
         [alt]="'LIBS.BACK' | translate"
         [title]="'LIBS.BACK' | translate">
  </div>
  <div class="navbar-content">
    <div class="navbar-info">
      <ng-container *ngIf="loading; else notLoading">
        <tsq-small-loader></tsq-small-loader>
      </ng-container>
      <ng-template #notLoading>
        <div class="circle-icon" *ngIf="!!icon">
          <img [src]="icon" alt="{{ title }} icon">
        </div>
        <div class="image-container" *ngIf="!!img">
          <img class="img-circle" src="{{ img }}" alt="{{ title }}">
        </div>
        <h3>{{ title | translate }}</h3>
      </ng-template>
    </div>
    <div>
      <ng-content></ng-content>
      <div *ngIf="hasSearch">
        <input class="form-control" tsqSearchInput
               [formControl]="searchControl"
               [placeholder]="'SEARCH' | translate"/>
      </div>
    </div>
  </div>
</div>
