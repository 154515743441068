import { Observable, OperatorFunction } from 'rxjs';
import { MemoizedSelector, select } from '@ngrx/store';
import { untilDestroyed } from '@ngneat/until-destroy';

export function selectUntilDestroyed<State, Result, Instance>(
  selector: MemoizedSelector<State, Result>,
  instance: Instance,
  destroyMethodName?: keyof Instance): OperatorFunction<State, Result> {

  return (store: Observable<State>) => store
    .pipe(
      select(selector),
      untilDestroyed(instance, destroyMethodName),
    );
}
