import { Directive, ElementRef, Input, Renderer2, inject } from '@angular/core';

@Directive({
  selector: 'div[tsqFilterGroupItem]',
})
export class FilterGroupItemDirective {
  readonly el = inject<ElementRef<HTMLDivElement>>(ElementRef).nativeElement;

  private _width: number | 'auto' = 'auto';

  private readonly renderer = inject(Renderer2);

  constructor() {
    this.renderer.setAttribute(this.el, 'tsq-filter-group-item', '');
  }

  @Input('tsqFilterGroupItem')
  set width(value: number | 'auto') {
    this._width = value;
  }

  get width(): number | 'auto' {
    if (typeof this._width === 'number') {
      if (this._width > 400) {
        return 400;
      } else if (this._width < 200) {
        return 200;
      }

      return this._width;
    }

    return 'auto';
  }
}
