/* eslint-disable @typescript-eslint/no-explicit-any */
export function memo<
  F extends (...args: unknown[]) => any,
  P extends Parameters<F>,
  R extends ReturnType<F>,
>(fn: F): (...args: P) => R {
  let prevArgs: P;
  let result: R;

  return (...args: P): R => {
    if (hasDifferentArgs(prevArgs, args)) {
      result = fn(...args);
      prevArgs = args;
    }

    return result;
  };
}

function hasDifferentArgs<P extends unknown[]>(prev: P, next: P): boolean {
  if (!prev || prev.length !== next.length) {
    return true;
  }
  for (let i = 0; i < prev.length; i++) {
    if (!Object.is(prev[i], next[i])) {
      return true;
    }
  }

  return false;
}
