import {
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, filter } from 'rxjs';

import { icons } from '@tsq-web/assets';
import { Feature } from '@tsq-web/feature';
import {
  ModalContext,
  ModalPositions,
  ModalService,
  ModalTypes,
  TSqTemplatePortal,
} from '@tsq-web/modals';
import { User } from '@tsq-web/users';

import { CommentsConfig } from '../../models/comments-config.model';
import { FeatureWrapperColumnsModes } from '../../models/feature-wrapper-columns-modes.enum';
import { TSqBaseComment } from '../../models/tsq-base-comment.model';
import { TSqCommentPresentation } from '../../models/tsq-comment-presentation.model';
import { TSqFeatureDetails } from '../../models/tsq-feature-details.model';
import { CommentsStore } from '../../state/comments.store';

/**
 * @deprecated Tsq feature wrapper is deprecated.
 */
@UntilDestroy()
@Component({
  selector: 'tsq-feature-wrapper',
  templateUrl: './tsq-feature-wrapper.component.html',
  styleUrls: ['./tsq-feature-wrapper.component.scss'],
  providers: [CommentsStore],
})
export class TSqFeatureWrapperComponent implements OnInit, DoCheck {
  @ViewChild('modalRef') modalRef: TemplateRef<ModalContext>;

  @ViewChild('detailsWrapper') set detailsWrapperEl(value: ElementRef) {
    this.detailsWrapper = value?.nativeElement;
  }

  @ViewChild('stickyFooterWrapper') set stickyFooterWrapperEl(value: ElementRef) {
    this.stickyFooterWrapper = value?.nativeElement;
  }

  @Input() details: TSqFeatureDetails;
  @Input() detailsTemplate: TemplateRef<unknown>;
  @Input() loading: boolean;
  @Input() error: boolean;
  @Input() editCommentIsLoading: boolean;
  @Input() deleteCommentIsLoading: boolean;
  @Input() selfHandleComments: boolean;
  @Input() adminDeleteComments: boolean;
  @Input() clickableUrl: boolean;
  @Input() feature: Feature;
  @Input() isAdmin: boolean;

  @Input() commentsConfig: CommentsConfig;
  @Input() comments: TSqCommentPresentation[];
  @Input() commentsLoading: boolean;
  @Input() commentsLoadingMore: boolean;
  @Input() commentsError: boolean;
  @Input() commentsPaginated: boolean;
  @Input() commentRequired = true;
  @Input() createCommentPlaceholder: TemplateRef<{ onFocused: () => void }> | undefined;

  @Input() showCommentList = true;
  @Input() showFooter = true;
  @Input() sendingComment: boolean;
  @Input() errorSendingComment: boolean;
  @Input() singleLineComment: boolean;
  @Input() commentHasAttachments = true;
  @Input() commentAttachmentUploadUrl = '';
  @Input() maxAttachments = 1;
  @Input() commentRequiresApproval: boolean;
  @Input() approvalTemplate: TemplateRef<TSqBaseComment>;
  @Input() extraActionsTemplate: TemplateRef<unknown>;
  @Input() commentFileExtensions: string[];

  @Input() templateColumns: FeatureWrapperColumnsModes = FeatureWrapperColumnsModes.Default;
  @Input() scrollableContainer: string;

  @Input() deletable = false;
  @Input() editable = false;
  @Input() user: User;
  @Input() editAndDeleteCommentsFeatureToggle = false;

  @Output() saveComment = new EventEmitter<TSqBaseComment>();
  @Output() commentSent = new EventEmitter();
  @Output() loadMoreComments = new EventEmitter();
  @Output() retry = new EventEmitter();
  @Output() retryComments = new EventEmitter();
  @Output() editComment = new EventEmitter<TSqCommentPresentation>();
  @Output() deleteComment = new EventEmitter<TSqCommentPresentation>();

  detailsWrapperWidth: number;
  stickyFooterWrapperHeight: number;
  selectedComment: TSqCommentPresentation;

  approvalContext: TSqBaseComment;

  shouldShowCreateCommentTextArea = false;

  readonly icons = icons;
  readonly editCommentIsSending$ = this.commentsStore.editCommentIsSending$;
  readonly editCommentSuccess$ = this.commentsStore.editCommentSuccess$;
  readonly deleteCommentIsSending$ = this.commentsStore.deleteCommentIsSending$;
  readonly deleteCommentSuccess$ = this.commentsStore.deleteCommentSuccess$;
  readonly adminDeleteCommentIsSending$ = this.commentsStore.adminDeleteCommentIsSending$;
  readonly adminDeleteCommentSuccess$ = this.commentsStore.adminDeleteCommentSuccess$;

  readonly createCommentPlaceholderContext = {
    onFocused: (): void => {
      this.shouldShowCreateCommentTextArea = true;
    },
  };

  private detailsWrapper: Element;
  private stickyFooterWrapper: Element;

  private modalContext: ModalContext = {
    position: ModalPositions.CENTER,
    disableClose: false,
  };

  private _commentContent: string;

  constructor(
    private modalService: ModalService,
    private viewContainerRef: ViewContainerRef,
    private commentsStore: CommentsStore,
  ) {}

  ngOnInit(): void {
    this.modalService.type$.pipe(untilDestroyed(this)).subscribe((type: ModalTypes) => {
      if (type === ModalTypes.TSQ_FEATURE_WRAPPER_APPROVE_COMMENT) {
        this.modalService.setPortal(
          new TSqTemplatePortal(this.modalRef, this.viewContainerRef, this.modalContext),
        );
      }
    });

    combineLatest([
      this.editCommentSuccess$,
      this.deleteCommentSuccess$,
      this.adminDeleteCommentSuccess$,
    ])
      .pipe(
        untilDestroyed(this),
        filter(
          ([editSuccess, deleteSuccess, adminDeleteSuccess]) =>
            editSuccess || deleteSuccess || adminDeleteSuccess,
        ),
      )
      .subscribe(() => {
        this.retry.emit();
      });
  }

  ngDoCheck(): void {
    this.detailsWrapperWidth = this.detailsWrapper?.clientWidth;
    this.stickyFooterWrapperHeight = this.stickyFooterWrapper?.clientHeight || 0;
  }

  @Input() set commentContent(value: string) {
    this._commentContent = value;

    if (!!this._commentContent) {
      this.shouldShowCreateCommentTextArea = true;
    }
  }

  get commentContent(): string {
    return this._commentContent;
  }

  onScroll(): void {
    if (this.commentsPaginated && !this.commentsLoading && !this.commentsLoadingMore) {
      this.loadMoreComments.emit();
    }
  }

  onSaveComment(comment: TSqBaseComment, isApproval: boolean): void {
    if (this.commentRequiresApproval && !isApproval) {
      this.approvalContext = comment;
      this.modalService.open(ModalTypes.TSQ_FEATURE_WRAPPER_APPROVE_COMMENT);
    } else {
      this.approvalContext = undefined;
      this.modalService.close();
      this.saveComment.emit(comment);
    }
  }

  onOpenEditCommentModal(comment: TSqCommentPresentation): void {
    this.selectedComment = comment;
    this.modalService.open(ModalTypes.EditCommentModal);
  }

  onOpenDeleteCommentModal(comment: TSqCommentPresentation): void {
    this.selectedComment = comment;
    const commentWasNotCreatedByUser = !!this.user && comment.createdBy.id !== this.user.id;

    if (commentWasNotCreatedByUser && this.isAdmin) {
      this.modalService.open(ModalTypes.DeleteCommentManagerModal);
    } else {
      this.modalService.open(ModalTypes.DeleteModal);
    }
  }

  commentTrackBy(index: number, comment: TSqCommentPresentation): string {
    return comment.creationDate;
  }

  onEditComment(comment: TSqCommentPresentation): void {
    if (this.selfHandleComments) {
      this.commentsStore.editComment({ comment: comment, feature: this.feature });
    } else {
      this.editComment.emit(comment);
    }
  }

  onDeleteComment(comment: TSqCommentPresentation): void {
    if (this.selfHandleComments) {
      this.commentsStore.deleteComment({ comment: comment, feature: this.feature });
    } else {
      this.deleteComment.emit(comment);
    }
  }

  onAdminDeleteComment(comment: TSqCommentPresentation, reason: string): void {
    this.commentsStore.adminDeleteComment({
      comment,
      reason,
      assignmentId: this.details.id ?? null,
      feature: this.feature,
    });
  }
}
