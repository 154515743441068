import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';

import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from 'rxjs';

import { fromUserContextActions } from '@tsq-web/user-context';

export const bypassOnRootGuard: CanActivateFn = (): Observable<boolean | UrlTree> => {
  const cookieService = inject(CookieService);
  const store = inject(Store);
  const router = inject(Router);

  if (cookieService.check('logonToken')) {
    store.dispatch(fromUserContextActions.relogin());

    return of(true);
  }

  return of(router.createUrlTree(['login']));
};
