/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';

import { SubscriptionService } from '../shared/subscription/subscription.service';
import { CondoSubscription } from '../shared/subscription/condo-subscription.json';
import { Multiplier } from '../shared/plans/multiplier.enum';
import { StripePaymentInfo } from '../shared/subscription/stripe-payment-info.json';
import { PaymentInfo } from '../shared/subscription/payment-info.json';
import { PaymentInfoType } from '../shared/subscription/payment-info-type.enum';
import { initializePendoForAnonymousVisitor } from '../shared/pendo/pendo.utils';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'expired-subscription-trial',
  templateUrl: './expired-subscription-trial.component.html',
  styleUrls: ['./expired-subscription-trial.component.scss'],
})
export class ExpiredSubscriptionTrialComponent implements OnInit {
  private condoId: string;

  subscription: CondoSubscription;
  paymentInfo: PaymentInfo;
  multiplierNumber: number;

  paymentInfoDraft: PaymentInfo;

  loading = true;
  error = false;
  sending = false;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _subscriptionService: SubscriptionService,
    private _toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    this._route.params.subscribe(params => {
      this.condoId = params.condoId;
    });

    initializePendoForAnonymousVisitor();
  }

  onUsBillingChanged(stripeInfo: StripePaymentInfo): void {
    const paymentInfo = new PaymentInfo();
    paymentInfo.type = PaymentInfoType.Stripe;
    paymentInfo.stripe = stripeInfo;
    this.paymentInfoDraft = paymentInfo;
  }

  onReactivateClicked(): void {
    this.sending = true;
    this._subscriptionService
      .postPaymentInfo(this.condoId, this.paymentInfoDraft)
      .pipe(finalize(() => (this.sending = false)))
      .subscribe(
        () => {
          this._toastr.success('Sucesso');
          this._router.navigate(['w', this.condoId, 'feed']);
        },
        () => this._toastr.error('Error'),
      );
  }

  get percentageForCart(): number {
    return this.subscription.discounts.length > 0 && this.totalPercentageDiscount > 0
      ? this.totalPercentageDiscount
      : undefined;
  }

  get rawValueForCart(): string {
    return this.subscription.discounts.length > 0 && this.totalRawValueDiscount > 0
      ? this.totalRawValueDiscount.toFixed(2)
      : undefined;
  }

  get totalSubscriptionValue(): number {
    if (this.subscription.plan) {
      return this.calculateTotalPrice(
        this.subscription.plan.multiplier !== Multiplier[Multiplier.NONE]
          ? this.multiplierNumber
          : 1,
      );
    }

    return 0;
  }

  private get totalPercentageDiscount(): number {
    return this.subscription.discounts
      .map(discount => (discount.percentage ? +discount.percentage : 0))
      .reduce((value, p) => p + value, 0);
  }

  private get totalRawValueDiscount(): number {
    return this.subscription.discounts
      .map(discount => (discount.rawValue ? +discount.rawValue : 0))
      .reduce((value, p) => p + value, 0);
  }

  private get totalPackagesPrice(): number {
    return this.subscription.featurePackages
      .map(featurePackage => (featurePackage.price ? +featurePackage.price.amount : 0))
      .reduce((value, p) => p + value, 0);
  }

  private calculateTotalPrice(multiplierNumber: number): number {
    const planPrice =
      this.subscription.plan.price.amount * (multiplierNumber ? multiplierNumber : 1);
    const basePrice =
      planPrice + (this.subscription.featurePackages.length > 0 ? this.totalPackagesPrice : 0);

    const percentageDiscount =
      this.subscription.discounts.length > 0 && this.totalPercentageDiscount > 0
        ? this.totalPercentageDiscount / 100.0
        : undefined;
    const rawValueDiscount =
      this.subscription.discounts.length > 0 && this.totalRawValueDiscount > 0
        ? this.totalRawValueDiscount
        : undefined;

    return (
      basePrice * (percentageDiscount ? 1 - percentageDiscount : 1) -
      (rawValueDiscount ? rawValueDiscount : 0)
    );
  }
}
