import { AuthProvider } from './auth-provider.enum';
import { LoginApplication } from './login-application.model';

export class LoginCredentials {
  username: string;
  password: string;
  token: string;
  authProvider: AuthProvider;
}

export class LoginBody {
  credentials: LoginCredentials;
  application: LoginApplication;
}
