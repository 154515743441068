import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EnvBasedAssetPathPipe } from './pipes/env-based-asset-path.pipe';
import { AssetsService } from './services/assets.service';
import { IconComponent } from './components/icon/icon.component';

@NgModule({
  imports: [CommonModule],
  declarations: [EnvBasedAssetPathPipe, IconComponent],
  exports: [EnvBasedAssetPathPipe, IconComponent],
})
export class AssetsModule {
  static forRoot(): ModuleWithProviders<AssetsModule> {
    return {
      ngModule: AssetsModule,
      providers: [AssetsService],
    };
  }
}
