<div
  class="font-base bg-yellow-n1 border-1 border-yellow-p1 flex w-full justify-between rounded p-16 shadow"
>
  <div class="flex flex-col">
    <span class="font-bold text-base">
      {{ 'LOGIN.YOU_ALREADY_SIGNED_UP' | translate }}
    </span>

    <p class="small-text mb-0 mr-48">
      {{
        (showAlreadyRegisteredBannerAdditionalInfo
          ? 'LOGIN.ENTER_YOUR_EMAIL_AND_PASSWORD'
          : 'LOGIN.ENTER_YOUR_EMAIL'
        ) | translate
      }}
      <a
        data-pendo="already-registered-banner--a--see-the-guidelines"
        *ngIf="showAlreadyRegisteredBannerAdditionalInfo"
        class="hover:text-kale focus:text-kale text-kale-p1 text-sm"
        href="https://learn.townsq.io/faq-link-additional-accounts"
        target="_blank"
        rel="noopener"
        >{{ 'LOGIN.SEE_GUIDELINES_FAQ' | translate }}</a
      >
    </p>
  </div>

  <div>
    <button class="focus:outline" (click)="closeBanner.emit()">
      <tsq-icon [icon]="xmarkIcon" [classes]="'text-yellow-p1 w-16 h-16'" />
    </button>
  </div>
</div>
