import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'comm-sso-redirect-error',
  templateUrl: './sso-redirect-error.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsoRedirectErrorComponent {
  @Input() loading: boolean;

  @Output() redirectToSso = new EventEmitter<void>();
  @Output() backToLogin = new EventEmitter<void>();
}
