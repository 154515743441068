import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Endereco, NgxViacepService } from '@brunoc/ngx-viacep';

@Injectable()
export class ViacepService {

  constructor(private viacep: NgxViacepService) {
  }

  searchAddress(cep: string): Observable<Endereco> {
    return this.viacep.buscarPorCep(cep);
  }
}
