import { AppType } from '@tsq-web/core';
import { LoginApplication } from './login-application.model';
import { LoginContext } from './login-context.model';

export class LoginResponse {
  authorization: {
    token: string;
    jwt: string;
    apps: AppType[];
  };
  application: LoginApplication;
  context: LoginContext;
}
