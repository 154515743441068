/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

import { documentFileTypes, FileMeta, TSqFileUploaderComponent } from '@tsq-web/files';
import { TSqValidators } from '@tsq-web/forms';
import { TSqBaseComment } from '../../models/tsq-base-comment.model';

@UntilDestroy()
@Component({
  selector: 'tsq-create-comment',
  templateUrl: './tsq-create-comment.component.html',
  styleUrls: ['./tsq-create-comment.component.scss'],
})
export class TSqCreateCommentComponent implements OnInit {
  @ViewChild('fileUploader', { static: true }) fileUploader: TSqFileUploaderComponent;

  @Input() hasAttachments = true;
  @Input() maxAttachments = 1;
  @Input() error: boolean;
  @Input() isSingleLine: boolean;
  @Input() extraActionsTemplate: TemplateRef<any>;
  @Input() fileExtensions: string[];

  @Output() saveComment = new EventEmitter<TSqBaseComment>();
  @Output() filesChanged = new EventEmitter<FileMeta[]>();
  @Output() commentSent = new EventEmitter();

  commentTextControl: UntypedFormControl;
  sendingComment: boolean;

  ngOnInit(): void {
    this.commentTextControl = new UntypedFormControl('', [
      Validators.required,
      TSqValidators.hasCharDifferentThanWhitespace,
    ]);

    this.fileUploader.files$
      .pipe(untilDestroyed(this))
      .subscribe(files => this.filesChanged.emit(files));
  }

  @Input() set uploadUrl(value: string) {
    if (!!value) {
      this.fileUploader.setupUploader(
        value,
        documentFileTypes,
        false,
        undefined,
        this.fileExtensions,
      );
    }
  }

  @Input() set sending(value: boolean) {
    if (this.sendingComment && !value && !this.error) {
      this.commentTextControl.reset();
      this.fileUploader.clearFiles();
      this.commentSent.emit();
    }

    this.sendingComment = value;
  }

  get formValid(): boolean {
    return (
      this.commentTextControl.valid &&
      (!this.hasAttachments || (!!this.fileUploader.uploader && !this.fileUploader.uploading))
    );
  }

  onSaveComment(): void {
    this.saveComment.emit({
      text: this.commentTextControl.value,
      attachments: this.fileUploader.files,
    });
  }
}
