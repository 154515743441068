<header class="fixed left-24 top-24">
  <img [src]="background.logo" [alt]="'LOGIN.LOGO' | translate" />
</header>

<main class="flex min-h-screen flex-col items-center justify-center">
  <div class="flex max-w-[320px] flex-col items-center" [class.login-form]="!ssoEnabled">
    <ng-container
      *ngIf="
        (completedUserRecovery$ | async) === false && (showSentEmailMessage$ | async) === false;
        else completed
      "
    >
      <div class="welcome-grid-cols grid justify-center text-center">
        <h1>{{ title | translate }}</h1>
        <tsq-icon
          *ngIf="!forgotPassword && isNewAccount"
          class="text-primary ml-8"
          [icon]="checkCircleFill"
        />
      </div>
      <p class="mb-24 text-center">{{ description | translate }}</p>

      <tsq-sso-reset-your-password-card [ssoLink]="ssoLink" *ngIf="showResetYourPasswordCard" />

      <form class="flex w-full flex-col gap-8" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="group">
          <label for="login--input--email">
            {{ 'LIBS.LOGIN.E-MAIL' | translate }}
          </label>
          <input
            type="text"
            class="control"
            formControlName="username"
            data-cy="login--input--email"
            id="login--input--email"
            tsqInput
            [errorMessage]="'LIBS.LOGIN.ERRORS.INVALID_E-MAIL' | translate"
            [placeholder]="'LIBS.LOGIN.E-MAIL' | translate"
          />
        </div>

        <div *ngIf="!forgotPassword" class="group">
          <label for="login--input--password">
            {{ 'LIBS.LOGIN.PASSWORD' | translate }}
          </label>
          <input
            type="password"
            class="control"
            formControlName="password"
            tsqPeekablePassword
            data-cy="login--input--password"
            id="login--input--password"
            autocomplete="off"
            [placeholder]="'LIBS.LOGIN.PASSWORD' | translate"
          />
        </div>

        <div *ngIf="ssoEnabled && !forgotPassword" class="text-right">
          <a (click)="toggleForgotPassword()">{{ actionText | translate }}</a>
        </div>

        <tsq-button
          *ngIf="
            (sendingUserRecovery$ | async) === false &&
              (loggingIn$ | async) === false &&
              (resetPasswordLoading$ | async) === false;
            else sending
          "
          [ngClass]="ssoEnabled && !forgotPassword ? 'mt-32' : 'my-24'"
          [size]="'lg'"
          [text]="buttonText | translate"
          [attr.data-cy]="!this.forgotPassword ? 'login--button--sign-in' : null"
          [disabled]="!isFormValid || ssoDisableForm"
          [type]="'submit'"
        />
      </form>

      <ng-template #sending>
        <div class="my-24">
          <tsq-small-loader />
        </div>
      </ng-template>

      <ng-container *ngIf="ssoEnabled && !forgotPassword; else legacyPasswordRecovery">
        <div
          data-testid="tsq-login--div--sso-options"
          class="text-coal-tertiary my-16 mb-16 flex items-center gap-24 text-sm uppercase"
        >
          <div class="bg-petro-n2 h-1 w-32"></div>
          <span>{{ 'LOGIN.OR' | translate }}</span>
          <div class="bg-petro-n2 h-1 w-32"></div>
        </div>

        <tsq-button
          class="tooltip-bottom w-full"
          kind="tertiary"
          size="lg"
          [text]="ssoText | translate"
          [attr.data-tooltip]="'LOGIN.SIGN_IN_WITH_SSO_TOOLTIP' | translate"
          (clicked)="onLoginWithSso()"
        />
      </ng-container>

      <ng-template #legacyPasswordRecovery>
        <a class="mb-16" (click)="toggleForgotPassword()">{{ actionText | translate }}</a>
        <p *ngIf="forgotPassword" data-cy="tsq-login--p--forgot-password-info">
          {{ 'LIBS.LOGIN.FORGOT_PASSWORD_INFO' | translate }}
          <a [href]="'LIBS.LOGIN.SUPPORT_E-MAIL_LINK' | translate">{{
            'LIBS.LOGIN.SUPPORT_E-MAIL' | translate
          }}</a>
        </p>
      </ng-template>
    </ng-container>

    <ng-template #completed>
      <img src="./assets/img/icons/email.svg" alt="" />
      <h1>{{ 'LIBS.LOGIN.USER_RECOVERY_SENT' | translate }}</h1>
      <p>{{ 'LIBS.LOGIN.USER_RECOVERY_DESCRIPTION' | translate }}</p>
      <a class="mb-16" (click)="onBackToLogin()">{{ 'LIBS.LOGIN.BACK_TO_LOGIN' | translate }}</a>
    </ng-template>
  </div>
</main>
<footer class="fixed bottom-0 h-auto w-full bg-white p-8 shadow">
  <a
    class="font-regular float-left"
    [href]="'LIBS.LOGIN.LINKS.ACCESS_HELP_CENTER' | translate"
    target="_blank"
  >
    {{ 'LIBS.LOGIN.ACCESS_HELP_CENTER' | translate }}
  </a>
  <div class="mx-auto w-fit">
    <a
      class="font-regular mr-40"
      [href]="'LIBS.LOGIN.LINKS.PRIVACY_POLICY' | translate"
      target="_blank"
    >
      {{ 'LIBS.LOGIN.PRIVACY_POLICY' | translate }}
    </a>
    <a class="font-regular" [href]="'LIBS.LOGIN.LINKS.TERMS_OF_USE' | translate" target="_blank">
      {{ 'LIBS.LOGIN.TERMS_OF_USE' | translate }}
    </a>
  </div>
</footer>
