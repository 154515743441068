<div class="font-base flex flex-col items-center justify-center px-16 py-56 text-center">
  <div class="bg-petro-n1 mb-24 flex h-64 w-64 items-center justify-center rounded-full">
    <tsq-icon [icon]="checkDoubleIcon" [classes]="'h-32 w-32 text-coal-tertiary'" />
  </div>

  <h2 class="text-xl">{{ 'IN_APP_FEEDBACK.THANK_YOU' | translate }}</h2>
  <p class="text-coal-secondary mb-0 mt-4">
    {{ 'IN_APP_FEEDBACK.FEEDBACK_IMPACT' | translate }}
  </p>

  <div
    *ngIf="!isFeedbackPositive"
    data-testid="thank-you-step--div--see-article-container"
    class="mt-16"
  >
    <p class="text-coal-secondary mb-0">
      {{ 'IN_APP_FEEDBACK.IS_TOWNSQ_A_MGMT_COMPANY' | translate }}
    </p>
    <button
      class="text-primary hover:text-primary-p1 focus:text-primary-p1 font-bold hover:underline focus:underline"
      (click)="openArticle.emit()"
    >
      {{ 'IN_APP_FEEDBACK.LEARN_MORE' | translate }}
    </button>
  </div>

  <tsq-button
    class="mt-24"
    kind="tertiary"
    [text]="'CLOSE' | translate"
    (clicked)="closeModal.emit()"
  />
</div>
