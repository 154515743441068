import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'tsq-icon',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent implements OnChanges {
  @Input() icon: string;
  @Input() classes: string;
  @Input() childClasses: Record<string, string>;

  private el: Element;

  constructor(private elRef: ElementRef, private renderer: Renderer2) {
    this.el = elRef.nativeElement as Element;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const iconName = changes.icon;

    if (iconName?.currentValue !== iconName?.previousValue && !!this.icon) {
      this.el.innerHTML = this.icon;
    }

    const svg = this.el.firstChild;
    if (!!svg && svg instanceof Element) {
      this.renderer.removeAttribute(svg, 'class');

      let toAddClasses = 'fill-current';
      if (!!this.classes) {
        toAddClasses += ` ${this.classes}`;
      }
      this.renderer.setAttribute(svg, 'class', toAddClasses);

      const keys = Object.keys(this.childClasses || {});
      const paths = Array.from(svg.childNodes) as Element[];

      keys?.forEach(key => {
        const path = paths.find(p => p.id === key);

        if (!!path) {
          this.renderer.removeAttribute(path, 'class');
          this.renderer.setAttribute(path, 'class', `fill-current ${this.childClasses[key]}`);
        }
      });
    }
  }
}
