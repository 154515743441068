<h1>{{ 'RECOVERY.CHECK_YOUR_EMAIL' | translate }}</h1>
<p class="text-md mb-0 mt-8" [innerHtml]="'RECOVERY.LINK_SENT' | translate : { email }"></p>

<p class="text-coal-secondary mb-40 mt-16">{{ 'RECOVERY.CHECK_YOUR_SPAM' | translate }}</p>

<tsq-button
  schema="new-primary"
  size="lg"
  [text]="'RECOVERY.BACK_TO_LOGIN' | translate"
  [route]="['login']"
/>
