import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { ActivatedRoute, Params, QueryParamsHandling, RouterLink } from '@angular/router';

import { TooltipTruncatedDirective } from '../../directives/tooltip-truncated/tooltip-truncated.directive';
import { AlignmentOptions } from '../../models/_table.model';
import { TableCellBaseComponent } from '../table-cell-base/table-cell-base.component';

@Component({
  standalone: true,
  imports: [RouterLink, TableCellBaseComponent, TooltipTruncatedDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'tsq-table-cell-link-internal',
  template: `
    <tsq-table-cell-base [align]="align" [tsqTooltipTruncated]="el">
      <a
        class="truncate rounded focus-visible:z-[1]"
        [routerLink]="route"
        [relativeTo]="relative ? activatedRoute : null"
        [queryParams]="queryParams"
        [queryParamsHandling]="queryParamsHandling"
        [fragment]="fragment"
        [preserveFragment]="preserveFragment"
        (click)="onAnchorClick($event)"
        #el
      >
        {{ text }}
      </a>
    </tsq-table-cell-base>
  `,
  styles: [
    `
      a {
        &:focus-visible {
          outline: theme('colors.purple.DEFAULT') dotted 2px;
          outline-offset: 0;
        }

        &:focus:not(:focus-visible) {
          outline: none;
        }
      }
    `,
  ],
})
export class TableCellLinkInternalComponent {
  @Input() align: AlignmentOptions;
  @Input() text: string;
  @Input() route: string;
  @Input() relative: boolean;
  @Input() queryParams: Params;
  @Input() queryParamsHandling: QueryParamsHandling;
  @Input() fragment: string;
  @Input() preserveFragment: boolean;

  readonly activatedRoute = inject(ActivatedRoute);

  onAnchorClick(event: PointerEvent): void {
    event.stopPropagation();
  }
}
