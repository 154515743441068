<div id="list">
  <ng-template *ngIf="selectedPlan; then packageList; else noPlanSelected"></ng-template>

  <ng-template #noPlanSelected>
    <div>{{ 'SUBSCRIPTION.SELECT_PLAN_FIRST' | translate }}</div>
  </ng-template>

  <ng-template #packageList>
    <div *ngIf="!!filteredPackages && filteredPackages.length === 0">
      {{ 'SUBSCRIPTION.NO_AVAILABLE_PACKAGES' | translate }}
    </div>

    <tsq-small-loader *ngIf="!filteredPackages"></tsq-small-loader>

    <div *ngIf="!!filteredPackages && filteredPackages.length > 0">
      <div *ngFor="let featurePackage of filteredPackages" class="feature-card">
        <div class="inline-block">
          <h1>{{ featurePackage.name }}</h1>
          <p>{{ featurePackage.description }}</p>
          <div class="flex">
            <img src="./assets/img/icons/icon-price.svg" alt="" />
            <span class="mr-20">{{ '$ ' + (featurePackage.price.amount / 100).toFixed(2) }}</span>
          </div>
        </div>
        <div id="add-feature" class="inline-block">
          <button
            class="btn btn-action btn-primary"
            *ngIf="!isPackageSelected(featurePackage)"
            (click)="featurePackageClicked(featurePackage)"
          >
            {{ 'SUBSCRIPTION.ADD_THIS_FEATURE' | translate }}
          </button>
          <div id="feature-added" *ngIf="isPackageSelected(featurePackage)">
            <button class="pull-right px-4" (click)="featurePackageClicked(featurePackage)">
              {{ 'SUBSCRIPTION.REMOVE' | translate }}
            </button>
            <span class="pull-right flex"
              ><img src="./assets/img/icons/icon-added.svg" alt="" />{{
                'SUBSCRIPTION.FEATURE_ADDED' | translate
              }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
