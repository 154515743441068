import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'tsqSearchHighlight' })
export class TSqSearchHighlightPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string, search: string): SafeHtml {
    if (!!search && !!search.trim()) {
      const tokens = search.trim().replace(new RegExp(' +', 'g'), ' ').split(' ');
      let safeHtml = this.sanitizer.sanitize(SecurityContext.HTML, value);

      tokens.forEach(token => (safeHtml = this.markText(token, safeHtml)));

      if (!!safeHtml && !!safeHtml.trim().length) {
        safeHtml = safeHtml.replace(new RegExp('</mark> <mark>', 'g'), ' ');
      }

      return this.sanitizer.bypassSecurityTrustHtml(safeHtml);
    } else {
      const safeHtml = this.sanitizer.sanitize(SecurityContext.HTML, value);
      return this.sanitizer.bypassSecurityTrustHtml(safeHtml);
    }
  }

  private markText(token: string, html: string): string {
    if (!html?.trim().length) {
      return '';
    }

    return html.replace(new RegExp(`(${token})(?![^<]*>|[^<>]*</)`, 'gi'), '<mark>$&</mark>');
  }
}
