<div class="tsq-card">

  <div class="form-group">
    <textarea class="form-control" [placeholder]="'COMMENT' | translate" [formControl]="commentTextControl"></textarea>
  </div>

  <div class="form-group">
    <label class="optional">{{attachmentsLabel | translate}}
      <span>{{'OPTIONAL' | translate | uppercase}}</span>
    </label>
    <tsq-file-uploader [maxFiles]="maxAttachments" #fileUploader></tsq-file-uploader>
  </div>

  <button class="btn btn-primary"
          *ngIf="!sending"
          [disabled]="!formValid"
          (click)="onSaveComment()">{{'ADD_COMMENT' | translate}}</button>
  <div class="loading-container" *ngIf="sending">
    <tsq-small-loader></tsq-small-loader>
  </div>
</div>
