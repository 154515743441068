import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import IMask from 'imask';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { Observable, Observer, Subject, of } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';

import { TSqValidators } from '@tsq-web/forms';
import { Property } from '@tsq-web/property';
import { latinise } from '@tsq-web/text';
import { User } from '@tsq-web/users';
import { Phone } from '@tsq-web/users';

import { ResidentInviteStatusEnum } from '../+sc-workspace/features/residents/models/resident-invite-status.enum';
import { ResidentInvite } from '../+sc-workspace/features/residents/models/resident-invite.model';
import { ResidentsInviteService } from '../+sc-workspace/features/residents/services/residents-invite.service';
import { provideRegisterPicUploader } from '../+user-register/register.uploader.provider';
import { GlobalVariables } from '../shared/globals';
import { initializePendoForAnonymousVisitor } from '../shared/pendo/pendo.utils';
import { PropertyService } from '../shared/property/property.service';

@Component({
  selector: 'comm-neighbor-invite-register',
  templateUrl: './neighbor-invite-register.component.html',
  styleUrls: ['./neighbor-invite-register.component.scss'],
  providers: [
    ResidentsInviteService,
    { provide: FileUploader, useFactory: provideRegisterPicUploader, deps: [CookieService] },
  ],
})
export class NeighborInviteRegisterComponent implements OnInit, OnDestroy {
  @ViewChild('profilePicInput') profilePicInput: ElementRef;

  readonly inviteAlreadyUsedMessage = 'REGISTER.INVITE_ALREADY_ACCEPTED';
  readonly defaultErrorMessage = 'REGISTER.ERROR_MESSAGE';

  neighborInvite: ResidentInvite;
  form: UntypedFormGroup;
  user: User = new User();
  picture = '//s3-sa-east-1.amazonaws.com/br.socialcondo.user.images/public/user_nophoto';
  error = false;
  globalVariables = GlobalVariables;
  phoneMask: IMask.AnyMaskedOptions = this.getPhoneMask();
  inviteFound = false;
  isSending = false;
  success = false;
  dynamicErrorMessage: string;

  property: Property;
  propertySearch = '';

  private inviteId = '';
  private componentDestroyed$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private fileUploader: FileUploader,
    private builder: UntypedFormBuilder,
    private residentsInviteService: ResidentsInviteService,
    private propertyService: PropertyService,
    private toastr: ToastrService,
    private analytics: AngularFireAnalytics,
  ) {
    this.route.params.pipe(takeUntil(this.componentDestroyed$)).subscribe(params => {
      this.inviteId = params.inviteId || this.inviteId;
    });

    this.residentsInviteService.getInviteById(this.inviteId).subscribe(
      invite => this.onInviteFound(invite),
      () => this.onErrorGetInvite(),
    );
  }

  ngOnInit(): void {
    this.analytics.logEvent('invites_neighbor_enters_form');
    initializePendoForAnonymousVisitor();

    this.setUpForm();
    this.dynamicErrorMessage = this.defaultErrorMessage;
    this.fileUploader.onSuccessItem = (item: FileItem, response: string): void => {
      this.onPictureChange(response);
      this.fileUploader.clearQueue();
    };

    this.form.controls.property.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(value => {
        const prop = new Property();
        prop.property_number = value;
        this.property = prop;
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.unsubscribe();
  }

  get greetingBackground(): string {
    return this.isPortuguese ? 'green' : 'green-alternate';
  }

  get loginImage(): string {
    return this.isPortuguese
      ? '../../assets/img/login-image-pt.png'
      : '../../assets/img/login-image-en.png';
  }

  get isPortuguese(): boolean {
    return this.translateService.currentLang === 'pt';
  }

  get uploader(): FileUploader {
    return this.fileUploader;
  }

  getDataSource(search: string): Observable<Property[]> {
    return new Observable((observer: Observer<string>) => observer.next(search)).pipe(
      mergeMap((token: string) => this.getPropertyAsObservable(token)),
    );
  }

  getPropertyAsObservable(token: string): Observable<Property[]> {
    return of(
      this.propertyService.cachedProperties.filter((property: Property) =>
        this.passesFilter(property, token),
      ),
    );
  }

  passesFilter(property: Property, filter: string): boolean {
    if (filter === '') {
      return true;
    }

    const filters = filter.replace(/\s+/, ' ').trim().split(' ');

    return filters.every(token => this.tokenMatchesProperty(property, token));
  }

  tokenMatchesProperty(property: Property, token: string): boolean {
    let tokenMatch = false;
    if (property.group_type && property.group_number) {
      tokenMatch =
        this.match(property.group_type, token) || this.match(property.group_number, token);
      tokenMatch =
        tokenMatch || this.match(property.group_number + '-' + property.property_number, token);
    }

    return (
      tokenMatch ||
      this.match(property.property_type, token) ||
      this.match(property.property_number, token)
    );
  }

  match(search: string, token: string): boolean {
    return search.toUpperCase().search(latinise(token.toUpperCase())) !== -1;
  }

  onPropertySelected(option: { item: Property }): void {
    this.property = option.item;
  }

  onSubmit(): void {
    this.getUserFromForm();
    this.neighborInvite.invitee = this.user;
    this.isSending = true;
    this.residentsInviteService.acceptNeighborInvite(this.neighborInvite).subscribe(
      () => this.onAcceptInviteSuccess(),
      () => this.onError(),
    );
  }

  isDisabled(): boolean {
    return (
      !this.form.valid ||
      this.error ||
      this.property === null ||
      this.property === undefined ||
      this.isSending
    );
  }

  onFileSelected(event: { target: { files: File[] } }): void {
    const reader = new FileReader();
    if ((event.target as unknown as { files: File[] }).files && event.target.files[0]) {
      reader.onload = (e: { target: { result: string | ArrayBuffer } }): void => {
        this.picture = e.target.result as string;
      };
    }
    reader.readAsDataURL(event.target.files[0]);
  }

  private onInviteFound(invite: ResidentInvite): void {
    switch (invite.status) {
      case ResidentInviteStatusEnum[ResidentInviteStatusEnum.PENDING]:
      case ResidentInviteStatusEnum[ResidentInviteStatusEnum.APPROVED]:
      case ResidentInviteStatusEnum[ResidentInviteStatusEnum.DECLINED]:
        this.error = true;
        this.dynamicErrorMessage = this.inviteAlreadyUsedMessage;
        break;
      case ResidentInviteStatusEnum[ResidentInviteStatusEnum.SENT]:
      default: {
        this.neighborInvite = invite;
        this.form.controls.email.setValue(invite.email);
        this.getPropertiesForCondo();
      }
    }
  }

  private getPropertiesForCondo(): void {
    this.propertyService.getPropertiesPublic(this.neighborInvite.condoId).subscribe(
      props => this.onPropertiesLoaded(props),
      () => this.onErrorGettingProperties(),
    );
  }

  private onErrorGetInvite(): void {
    this.error = true;
  }

  private onPropertiesLoaded(properties: Property[]): void {
    this.inviteFound = true;
    this.propertyService.cachedProperties = properties;
  }

  private onErrorGettingProperties(): void {
    this.error = true;
  }

  private onAcceptInviteSuccess(): void {
    this.analytics.logEvent('invites_neighbor_submits_form');
    this.isSending = false;
    this.success = true;
  }

  private onError(): void {
    this.isSending = false;
    this.toastr.error(this.translateService.instant('INVITE_NEIGHBORS.ERROR_NEIGHBOR_REGISTER'));
  }

  private setUpForm(): void {
    if (!this.error) {
      this.form = this.builder.group({
        firstName: this.builder.control('', Validators.required),
        lastName: this.builder.control('', Validators.required),
        email: this.builder.control('', [Validators.required, TSqValidators.validEmail]),
        phone: this.builder.control(new Phone(''), Validators.nullValidator),
        property: this.builder.control(''),
      });
    }
  }

  private getUserFromForm(): void {
    this.user.first_name = this.form.controls.firstName.value;
    this.user.last_name = this.form.controls.lastName.value;
    this.user.properties = [this.property];
    this.user.email = this.form.controls.email.value;
    if (this.form.controls.phone.value) {
      this.user.phones.push(
        new Phone(this.form.controls.phone.value.number, this.form.controls.phone.value.countryISO),
      );
    }
  }

  private onPictureChange(url: string): void {
    this.user.pictureUrl = url;
  }

  private getPhoneMask(): IMask.AnyMaskedOptions {
    if (this.isPortuguese) {
      return {
        mask: '(00) 00000-0000',
        lazy: false,
      };
    }

    return {
      mask: '(000) 000-0000',
      lazy: false,
    };
  }
}
