<h1>{{ 'RECOVERY.PASSWORD_RECOVERY' | translate }}</h1>
<p class="text-md mb-0 mt-8">{{ 'RECOVERY.PASSWORD_RECOVERY_DESC' | translate }}</p>

<comm-reset-your-password-card *ngIf="isSso" class="mt-16 block" />

<form class="group mt-16">
  <label for="recover-password--input--email">{{ 'EMAIL' | translate }}</label>
  <input
    tsqTextInput
    schema="new-primary"
    id="recover-password--input--email"
    type="text"
    name="email"
    class="control font-base"
    [placeholder]="'RECOVERY.EMAIL_PLACEHOLDER' | translate"
    [formControl]="email"
    [errorMessage]="'RECOVERY.ENTER_A_VALID_EMAIL' | translate"
  />

  <div class="mt-24">
    <tsq-button
      *ngIf="!isLoading; else loader"
      schema="new-primary"
      size="lg"
      type="submit"
      [text]="'RECOVERY.SEND_RESET_LINK' | translate"
      [disabled]="email.invalid"
      (clicked)="requestLink()"
    />

    <ng-template #loader>
      <tsq-small-loader />
    </ng-template>
  </div>
</form>
