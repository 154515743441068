<div class="link-user-container">

  <a [routerLink]="['/login']">{{ 'AIS_SIGN_UP.ISNT_YOU' | translate }}</a>

  <h1>{{ 'AIS_SIGN_UP.ACCOUNT_INFORMATION' | translate }}</h1>

  <div class="tsq-card">
    <div>
      <label>{{ 'AIS_SIGN_UP.NAME' | translate | uppercase }}</label>
      <span>{{ ownerName }}</span>
    </div>
    <div class="inline">
      <div>
        <label>{{ 'AIS_SIGN_UP.ACCOUNT_NUMBER' | translate | uppercase }}</label>
        <span>{{ accountNumber }}</span>
      </div>
      <div>
        <label>{{ 'AIS_SIGN_UP.ZIP' | translate | uppercase }}</label>
        <span>{{ zip }}</span>
      </div>
    </div>
    <div>
      <label>{{ 'AIS_SIGN_UP.ADDRESS' | translate | uppercase }}</label>
      <span>{{ account.address.address1 }}</span>
    </div>
  </div>

  <router-outlet></router-outlet>

  <a (click)="goToLogin()">{{ 'AIS_SIGN_UP.ALREADY_REGISTERED' | translate }}</a>

</div>
