import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UntilDestroy } from '@ngneat/until-destroy';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, take } from 'rxjs';

import { LoginBackground, singleSignOnErrorKey } from '@tsq-web/login';
import { fromRouterSelectors } from '@tsq-web/router';
import { AppState } from '@tsq-web/state';
import { fromUserContextActions, fromUserContextSelectors } from '@tsq-web/user-context';

@UntilDestroy()
@Component({
  selector: 'comm-sso-callback',
  templateUrl: './callback.component.html',
})
export class SingleSignOnCallbackComponent implements OnInit {
  loggingIn$: Observable<boolean>;

  background: LoginBackground = new LoginBackground();

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private toastrService: ToastrService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.loggingIn$ = this.store.pipe(select(fromUserContextSelectors.selectUserLoggingIn));

    this.store
      .select(fromRouterSelectors.selectRouterQueryParams)
      .pipe(take(1))
      .subscribe(params => {
        if (!!params.code) {
          this.store.dispatch(fromUserContextActions.authTokenExchangeV0({ code: params.code }));
        }
        if (!!params.error) {
          this.translateService.get('LOGIN').subscribe(() => {
            const fallback = this.translateService.instant('LOGIN.SSO_ERROR.FALLBACK');
            const key = singleSignOnErrorKey(params.error);
            const message = this.translateService.instant(key, { fallback });
            this.toastrService.error(message);
            this.store.dispatch(fromUserContextActions.authFailed());
            this.router.navigate(['/login']);
          });
        }
      });
  }
}