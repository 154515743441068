import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TextColorsEnum } from '@tsq-web/text';

@Component({
  selector: 'tsq-switch-collapse',
  templateUrl: './tsq-switch-collapse.component.html',
  styleUrls: ['./tsq-switch-collapse.component.scss'],
})
export class TSqSwitchCollapseComponent {
  @Input() label = '';
  @Input() show = true;
  @Input() isCard = true;
  @Input() bodyIsPanel = true;
  @Input() textColor = 'primary';

  @Output() showChange = new EventEmitter<boolean>();

  textColorsEnum = TextColorsEnum;
}
