<ng-template #modalContent>
  <div class="modal-header">
    <div class="flex gap-8">
      <tsq-icon [icon]="trashIcon"></tsq-icon>
      <span>{{ title | translate }}</span>
    </div>
    <tsq-generic-modal-close></tsq-generic-modal-close>
  </div>

  <div class="modal-body-content px-16 pt-16">
    <p>{{ description | translate }}</p>
  </div>

  <div class="modal-footer gap-8">
    <tsq-button
      data-cy="delete-modal--button--cancel"
      (clicked)="closeModal()"
      [text]="'LIBS.COMMENTS.CANCEL' | translate"
      [kind]="'tertiary'"
    ></tsq-button>
    <tsq-button
      *ngIf="!deleteIsLoading"
      data-cy="delete-modal--button--delete"
      [icon]="confirmActionIcon"
      [text]="confirmActionText | translate"
      [schema]="'red'"
      (clicked)="confirmDelete.emit()"
    ></tsq-button>
    <tsq-small-loader *ngIf="deleteIsLoading"></tsq-small-loader>
  </div>
</ng-template>
