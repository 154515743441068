import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';

import Lottie from 'lottie-web/build/player/lottie_light';

@Component({
  selector: 'tsq-loader',
  template: ` <div #animationContainer [style]="styles"></div> `,
})
export class TSqLoaderComponent implements AfterViewInit {
  @ViewChild('animationContainer') private container: ElementRef;

  readonly styles = 'width: 200px; height: 200px; overflow: hidden; margin: 0 auto;';

  ngAfterViewInit(): void {
    const options = {
      container: this.container.nativeElement as Element,
      loop: true,
      autoplay: true,
      path: './assets/loader-animation.json',
    };

    Lottie.loadAnimation(options);
  }
}
