import { Routes } from '@angular/router';

import { NeighborInviteRegisterComponent } from './neighbor-invite-register.component';

export const neighborInviteRegisterRoute: Routes = [
  {
    path: 'neighbor-invite-register/:inviteId',
    component: NeighborInviteRegisterComponent,
  },
];
