import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { map, startWith } from 'rxjs';

import { icons } from '@tsq-web/assets';
import { TSqValidators } from '@tsq-web/forms';

@Component({
  selector: 'comm-user-recovery-new-password-form',
  templateUrl: './new-password-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewPasswordFormComponent {
  @Input() isLoading = false;

  @Output() backToLogin = new EventEmitter<void>();
  @Output() changePassword = new EventEmitter<string>();

  readonly icons = icons;

  readonly changePasswordForm = new FormGroup({
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      Validators.pattern(/^\S*$/),
      TSqValidators.matchPasswordValidator('passwordConfirm', true),
    ]),
    passwordConfirm: new FormControl('', [TSqValidators.matchPasswordValidator('password')]),
  });
  readonly passwordRequirements$ = this.changePasswordForm.valueChanges.pipe(
    startWith({ password: '', confirmPassword: '' }),
    map(() => {
      const password = this.changePasswordForm.get('password');
      const isEmpty = !password.value;
      return [
        {
          message: 'RECOVERY.PASSWORD_REQUIREMENTS.AT_LEAST_8_CHARACTERS',
          hasError: password.hasError('minlength') || isEmpty,
        },
        {
          message: 'RECOVERY.PASSWORD_REQUIREMENTS.NO_SPACES',
          hasError: password.hasError('pattern'),
        },
      ];
    }),
  );
  readonly errorMessage$ = this.passwordRequirements$.pipe(
    map(
      requirements =>
        'RECOVERY.PASSWORD_REQUIREMENTS.' +
        (requirements[0].hasError ? 'LENGTH_ERROR' : 'SPACE_ERROR'),
    ),
  );
}
