/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { PrivacySettings } from '../../user/privacySettings.json';
import { PrivacySettingsService } from '../../user/privacySettings.service';
import { User } from '@tsq-web/users';

@Component({
  selector: 'sc-privacy-settings',
  templateUrl: './sc-privacy-settings.component.html',
  styleUrls: ['./sc-privacy-settings.component.scss'],
})
export class SCPrivacySettingsComponent implements OnInit {
  @Input() currentUser: User;
  @Output() onError = new EventEmitter<any>();
  @Output() onUpdateSuccess = new EventEmitter<any>();
  privacySettings: PrivacySettings = new PrivacySettings();
  previousPrivacySettings: PrivacySettings;
  isLoading = false;

  constructor(private _privacySettingsService: PrivacySettingsService) {}

  ngOnInit(): void {
    this.isLoading = true;
    this._privacySettingsService.getPrivacySettings(this.currentUser.id).subscribe(
      (privacySettings: PrivacySettings) => {
        this.privacySettings = privacySettings;
        this.previousPrivacySettings = Object.assign({}, privacySettings);
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      },
    );
  }

  getPrivacySettings() {
    return this.privacySettings;
  }

  updatePrivacySettingsSuccess(privacySettings: PrivacySettings) {
    this.privacySettings = privacySettings;
    this.previousPrivacySettings = Object.assign({}, privacySettings);
  }

  updatePrivacySettingsError() {
    setTimeout(() => {
      this.privacySettings = Object.assign({}, this.previousPrivacySettings);
    }, 500);
  }
}
