<ng-container *ngIf="!ssoError; else ssoErrorTpl">
  <h1>{{ 'LOGIN.SSO_REDIRECT_TITLE' | translate }}</h1>
  <p class="text-md mb-0 mt-8">
    {{ 'LOGIN.SSO_REDIRECT_INFO' | translate }}
  </p>

  <div class="mt-16 flex flex-col gap-y-40">
    <span class="text-coal-tertiary">
      {{ 'LOGIN.REDIRECTING_IN' | translate }} {{ countdown }}.</span
    >

    <tsq-small-loader *ngIf="isRedirecting; else buttonTpl" />

    <ng-template #buttonTpl>
      <tsq-button
        schema="new-primary"
        size="lg"
        type="submit"
        [text]="'LOGIN.SSO_REDIRECT_BUTTON' | translate"
        (clicked)="onRedirectSso()"
      />
    </ng-template>
  </div>
</ng-container>

<ng-template #ssoErrorTpl>
  <comm-sso-redirect-error
    class="mt-8 block"
    [loading]="ssoAuthenticateLoading"
    (redirectToSso)="redirectSso.emit()"
    (backToLogin)="backToLogin.emit()"
  />
</ng-template>
