import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ToastrModule } from 'ngx-toastr';

import { AssetsModule } from '@tsq-web/assets';

import { ToastErrorComponent } from './components/toast-error/toast-error.component';
import { ToastSuccessComponent } from './components/toast-success/toast-success.component';
import { ToastWarningComponent } from './components/toast-warning/toast-warning.component';
import { TSqToastComponent } from './components/tsq-toast/tsq-toast.component';

@NgModule({
  imports: [
    CommonModule,
    AssetsModule,
    ToastrModule.forRoot({
      toastComponent: TSqToastComponent,
    }),
  ],
  declarations: [
    TSqToastComponent,
    ToastSuccessComponent,
    ToastErrorComponent,
    ToastWarningComponent,
  ],
  exports: [TSqToastComponent, ToastSuccessComponent, ToastErrorComponent, ToastWarningComponent],
})
export class TSqToastModule {}
