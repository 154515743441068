/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { IntroBanner } from '@tsq-web/login';

@Component({
  selector: 'sc-intro-banner-container',
  templateUrl: 'sc-intro-banner-container.component.html',
  styleUrls: ['sc-intro-banner-container.component.scss'],
})
export class SCIntroBannerContainerComponent {
  @Input() bannerCollection: IntroBanner[];
  @Output() hideBannerEvent = new EventEmitter();

  get currentBanner(): IntroBanner {
    if (
      this.bannerCollection === undefined ||
      this.bannerCollection === null ||
      this.bannerCollection.length === 0
    ) {
      return undefined;
    }
    return this.bannerCollection[0];
  }
}
