<div id="date-picker">
  <input
    #inputElement
    bsDatepicker
    #dp="bsDatepicker"
    type="text"
    class="has-icon font-base"
    [tabindex]="tabIndex"
    [placement]="placement"
    [placeholder]="placeholder | translate"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [bsConfig]="{ showWeekNumbers: false, adaptivePosition: adaptivePosition }"
    [isDisabled]="disabled"
    [disabled]="disabled"
    [isOpen]="isOpen"
    [ngClass]="{
      'ng-invalid ng-touched': invalid && touched,
      'form-control': !isNewDesign,
      control: isNewDesign
    }"
    [bsValue]="_value"
    (bsValueChange)="onValueChanged($event)"
    (focusin)="onFocus()"
    (blur)="onBlur()"
    (keydown)="onKeyDown()"
    (keyup.Space)="dp.toggle()"
    (onShown)="onShown()"
    (onHidden)="onHidden()"
  />

  <img
    *ngIf="!_value || disabled"
    class="absolute right-16 top-1/2 -translate-y-1/2 cursor-pointer"
    [class.filter-coal-disabled]="disabled"
    [class.filter-primary]="!disabled"
    (click)="isOpen ? close() : open()"
    src="./assets/libs/icons/date.svg"
    alt=""
  />

  <img
    *ngIf="!!_value && !disabled && !hideClearButton"
    src="./assets/libs/icons/cancel.svg"
    class="pointer-cursor filter-primary absolute right-16 top-1/2 -translate-y-1/2"
    (click)="onClearInput(); $event.stopPropagation()"
    alt=""
  />
</div>
