import {
  APP_INITIALIZER,
  EnvironmentProviders,
  InjectionToken,
  Type,
  ValueProvider,
  inject,
  makeEnvironmentProviders,
} from '@angular/core';
import { TestBed } from '@angular/core/testing';

import { Observable, of } from 'rxjs';

import { FeatureFlagService } from '../services/feature-flag.service';

const featureFlagInjectionToken = new InjectionToken<FeatureFlagService>('Feature Flag Service');

export function provideFeatureFlagService(
  service: Type<unknown> & typeof FeatureFlagService,
): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: featureFlagInjectionToken,
      useClass: service,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => (): void => void 0,
      deps: [featureFlagInjectionToken],
      multi: true,
    },
  ]);
}

export function injectFeatureFlagService<
  TFlagsType extends Record<string, boolean | string | number> = Record<
    string,
    boolean | string | number
  >,
>(): FeatureFlagService<TFlagsType> {
  return inject<FeatureFlagService<TFlagsType>>(featureFlagInjectionToken);
}

export function provideMockFeatureFlagService(): ValueProvider {
  return {
    provide: featureFlagInjectionToken,
    useValue: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      getValue: (flag: string, fallback?: boolean): boolean => {
        return false;
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      getValueAsync: (flag: string, fallback?: boolean): Observable<boolean> => {
        return of(false);
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      getValueString: (flag: string, fallback?: string): string => {
        return '';
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      getValueStringAsync: (flag: string, fallback?: string): Observable<string> => {
        return of('');
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      getValueNumber: (flag: string, fallback?: number): number => {
        return 0;
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      getValueNumberAsync: (flag: string, fallback?: number): Observable<number> => {
        return of(0);
      },
    },
  };
}

export function injectMockFeatureFlagService<
  TFlagsType extends Record<string, boolean | string | number> = Record<
    string,
    boolean | string | number
  >,
>(): FeatureFlagService<TFlagsType> {
  return TestBed.inject<FeatureFlagService<TFlagsType>>(featureFlagInjectionToken);
}
