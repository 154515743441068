/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

import { BaseService } from '@tsq-web/core';
import { UserRegister } from './user-register.model';
import { RegistrationSearchRequest } from './registration-search-request.model';
import { RegistrationRequest } from './registration-request.model';

@Injectable()
export class RegisterService extends BaseService {
  constructor(
    protected _cookieService: CookieService,
    protected _http: HttpClient,
    protected _router: Router,
  ) {
    super(_cookieService, _http, _router);
  }

  registrationSearch(request: RegistrationSearchRequest): Observable<UserRegister> {
    return this.doPost('registration/search', request, 'v1');
  }

  registration(request: RegistrationRequest): Observable<UserRegister> {
    return this.doPost<UserRegister>('registration', request, 'v1');
  }
}
