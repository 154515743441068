/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tsq-box',
  templateUrl: 'tsq-box.component.html',
  styleUrls: ['tsq-box.component.scss'],
})
export class TSqBoxComponent implements OnInit {
  @Input() backgroundColor = 'white';
  @Input() heightPx: number = null;
  @Input() heightPercent: number = null;
  @Input() maxHeight: number = null;
  @Input() showExpander = false;
  @Input() overflowX = 'hidden';
  @Input() overflowY = 'auto';

  componentHeightPx: number;
  componentHeightPercent: number;
  componentMaxHeight: number;
  componentButtonLStyle: any;
  componentButtonRStyle: any;
  componentOverflowX: string;
  componentOverflowY: string;
  containerExpanded = false;

  ngOnInit() {
    this.changeExpanderButtonIcon();
  }

  onExpanderClick() {
    this.containerExpanded = !this.containerExpanded;
    this.changeExpanderButtonIcon();
  }

  changeExpanderButtonIcon() {
    if (this.containerExpanded) {
      this.componentButtonLStyle = {
        transform: 'rotate(315deg)',
      };
      this.componentButtonRStyle = {
        transform: 'rotate(45deg)',
      };
      this.componentHeightPx = null;
      this.componentHeightPercent = null;
      this.componentMaxHeight = null;
    } else {
      this.componentButtonLStyle = {
        transform: 'rotate(45deg)',
      };
      this.componentButtonRStyle = {
        transform: 'rotate(315deg)',
      };
      this.componentHeightPx = this.heightPx;
      this.componentHeightPercent = this.heightPercent;
      this.componentMaxHeight = this.maxHeight;
    }
    this.componentOverflowX = this.overflowX;
    this.componentOverflowY = this.overflowY;
  }
}
