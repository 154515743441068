import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { TSqChipsModule } from '@tsq-web/chips';
import { TSqFormsModule } from '@tsq-web/forms';
import { TSqGenericComponentsModule } from '@tsq-web/generic-components';

import { ChipsGroupComponent } from '../chips-group/chips-group.component';

@Component({
  selector: 'comm-rating-details-step',
  templateUrl: './rating-details-step.component.html',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    TSqChipsModule,
    TSqFormsModule,
    TSqGenericComponentsModule,
    ReactiveFormsModule,
    ChipsGroupComponent,
  ],
})
export class RatingDetailsStepComponent {
  @Input() isPositiveFeedback: boolean;
  @Input() suggestionChips: string[];
  @Input() selectedChipsIndexes: number[];
  @Input() reasonControl: FormControl<string>;

  @Output() suggestionSelected = new EventEmitter<number>();
  @Output() feedbackSubmitted = new EventEmitter<void>();

  get isReasonMandatory(): boolean {
    return this.reasonControl.hasValidator(Validators.required);
  }
}
