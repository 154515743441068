<tsq-chip
  *ngFor="let option of options; let index = index"
  [schema]="schema"
  [text]="option"
  [selected]="isSelected(index)"
  [hasCheck]="hasCheck"
  [hasRemove]="hasRemove"
  [disabled]="disabled"
  [isNewDesign]="isNewDesign"
  [invalid]="isInvalid(index)"
  [lock]="hasLock(index)"
  (removeTag)="optionRemoved.emit(index)"
  (click)="onChipSelected(index); $event.stopPropagation()"
/>
