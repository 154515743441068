import { UserGroup } from '../models/user-group.model';

export const mockedUserGroup: UserGroup = {
  id: 'user-group-id',
  name: 'Test Group',
  description: 'Group description',
  color: '#FF0000',
  icon: 'fa-eye',
  requiresProperty: false,
  showGroupNameInUserProfile: true,
  userListedInNeighbours: true,
  permissions: [],
  count: 3,
} as UserGroup;
