import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

import { Condo } from '@tsq-web/condo';
import { FeaturesStatuses } from '@tsq-web/condo';
import { Settings } from '@tsq-web/condo';
import { HttpService } from '@tsq-web/core';
import { User } from '@tsq-web/users';

import { CondoReactivateRequest } from '../+dashboard/main-condos/condo-details/models/condo-reactivate-request.model';
import { EntityDeletionDetails } from '../+dashboard/shared/deactivate-modal/models/entity-deletion-details.models';
import { CondoHomeInfo } from './homeInfo.json';

@Injectable()
export class CondoService extends HttpService {
  constructor(
    protected cookieService: CookieService,
    protected http: HttpClient,
    protected router: Router,
  ) {
    super();
  }

  gotoCondo(condoId: string): Observable<User> {
    return this.http.put<User>(
      this.getFullUrl(`login/gotoCondo/${condoId}`),
      {},
      this.getJsonOptions(),
    );
  }

  getCondo(condoId: string): Observable<Condo> {
    return this.http.get<Condo>(this.getFullUrl(`condo/${condoId}`), this.getJsonOptions());
  }

  getCondoList(
    search?: string,
    includeDeleted?: boolean,
    limit?: number,
    skip?: number,
  ): Observable<Condo[]> {
    let params: HttpParams = new HttpParams();
    if (search) {
      params = params.append('search', search);
    }
    if (limit) {
      params = params.append('limit', limit.toString());
    }
    if (skip) {
      params = params.append('skip', skip.toString());
    }
    if (includeDeleted) {
      params = params.append('includeDeleted', String(includeDeleted));
    }

    return this.http.get<Condo[]>(this.getFullUrl('condo'), this.getJsonOptions({ params }));
  }

  resendAccess(condo: Condo): Observable<boolean> {
    return this.http.get<boolean>(
      this.getFullUrl(`user/condo/resend/access/${condo.id}`),
      this.getJsonOptions(),
    );
  }

  saveCondo(condo: Condo): Observable<Condo> {
    return this.http.post<Condo>(this.getFullUrl('condo'), condo, this.getJsonOptions());
  }

  updateCondo(condo: Condo): Observable<Condo> {
    return this.http.put<Condo>(this.getFullUrl(`condo/${condo.id}`), condo, this.getJsonOptions());
  }

  updateExpireDate(condo: Condo): Observable<boolean> {
    return this.http.patch<boolean>(
      this.getFullUrl(`condo/${condo.id}`),
      condo,
      this.getJsonOptions(),
    );
  }

  reactivateCondo(condoReactivateRequest: CondoReactivateRequest): Observable<boolean> {
    return this.http.patch<boolean>(
      this.getFullUrl(`condos/${condoReactivateRequest.condoJson.id}/activate`, 'v1'),
      condoReactivateRequest,
      this.getJsonOptions(),
    );
  }

  lobbySettings(settings: Settings): Observable<Settings> {
    return this.http.put<Settings>(
      this.getFullUrl('condo/lobbySettings'),
      settings,
      this.getJsonOptions(),
    );
  }

  deleteCondo(condo: Condo, churnReason: EntityDeletionDetails): Observable<boolean> {
    return this.http.delete<boolean>(this.getFullUrl(`condo/${condo.id}`), { body: churnReason });
  }

  getHomeInfo(condoId?: string): Observable<CondoHomeInfo> {
    let params: HttpParams = new HttpParams();
    if (condoId) {
      params = params.append('condoId', condoId);
    }
    return this.http.get<CondoHomeInfo>(
      this.getFullUrl('condo/homeInfo'),
      this.getJsonOptions({ params }),
    );
  }

  toggleFeature(
    condoId: string,
    feature: string,
    featuresStatuses: FeaturesStatuses,
  ): Observable<FeaturesStatuses> {
    return this.http.put<FeaturesStatuses>(
      this.getFullUrl(`condo/${condoId}/features/${feature}`),
      featuresStatuses,
      this.getJsonOptions(),
    );
  }
}
