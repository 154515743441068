import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';

import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

import { AppState } from '@tsq-web/state';
import { User } from '@tsq-web/users';
import { Feature } from '@tsq-web/feature';
import { Permission } from '@tsq-web/permission';
import { LoginService, ReloginGuardUtil } from '@tsq-web/login';
import { fromUserContextSelectors } from '@tsq-web/user-context';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard extends ReloginGuardUtil implements CanActivate {
  constructor(
    protected store: Store<AppState>,
    protected router: Router,
    protected cookieService: CookieService,
    protected loginService: LoginService,
    private service: AuthService,
  ) {
    super(loginService, cookieService, router, store);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    const feature: Feature = route.data.feature;
    const permission: Permission = route.data.permission;

    return this.store.pipe(
      select(fromUserContextSelectors.selectUser),
      mergeMap((user: User) => {
        if (!user) {
          return this.reloginOverRoute(route, state);
        }

        return this.service.hasFeatureEnabled(feature).pipe(
          mergeMap(isEnabled =>
            isEnabled ? this.service.hasPermissionOn(feature, permission) : of(false),
          ),
          map(hasPermission => hasPermission || this.router.parseUrl('')),
        );
      }),
    );
  }
}
