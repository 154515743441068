/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import moment from 'moment-timezone';

@Component({
  selector: 'sc-attachment-view',
  templateUrl: 'sc-attachment-view.component.html',
  styleUrls: ['sc-attachment-view.component.scss'],
})
export class SCAttachmentViewComponent {
  @Input('fileName') fileName = '';
  @Input('fileSize') fileSize = 0;
  @Input('fileExtension') fileExtension = '';
  @Input('fileViewUrl') fileViewUrl = '';
  @Input('fileDownloadUrl') fileDownloadUrl = '';
  @Input('extraSmall') extraSmall = false;
  @Input('autoWidth') autoWidth = false;
  @Input() date: Date;

  @Output() onViewFile = new EventEmitter();
  @Output() onDownloadFile = new EventEmitter();

  getAttachmentIconUrl(extension: string): string {
    if (extension === undefined || extension === null || extension.trim().length === 0) {
      return './assets/img/fileExts/others.png';
    }
    switch (extension.toLocaleLowerCase()) {
      case 'doc':
      case 'docx':
        return './assets/img/fileExts/doc.png';
      case 'jpg':
      case 'jpeg':
        return './assets/img/fileExts/jpg.png';
      case 'pdf':
        return './assets/img/fileExts/pdf.png';
      case 'png':
        return './assets/img/fileExts/png.png';
      case 'ppt':
      case 'pptx':
        return './assets/img/fileExts/ppt.png';
      case 'txt':
        return './assets/img/fileExts/txt.png';
      case 'xls':
      case 'xlsx':
        return './assets/img/fileExts/xls.png';
      default:
        return './assets/img/fileExts/others.png';
    }
  }

  getDateLabel(): string {
    if (this.date === undefined) {
      return '';
    }
    const creationDate = moment(this.date).format('YYYYMMDD');
    const today = moment().format('YYYYMMDD');
    const yesterday = moment(today)
      .date(moment(today).date() - 1)
      .format('YYYYMMDD');
    if (creationDate === today) {
      return 'FEED.TODAY';
    }
    if (creationDate === yesterday) {
      return 'FEED.YESTERDAY';
    }
    return 'FEED.RELATIVE';
  }

  getDateString(): string {
    return moment(this.date).format('ll');
  }

  getRelativeDay(): string {
    return moment(this.date).fromNow();
  }

  viewFileClick() {
    this.onViewFile.emit(this.fileViewUrl);
  }

  downloadFileClick() {
    this.onDownloadFile.emit(this.fileDownloadUrl);
  }

  getReadableSize(fileSizeInBytes: number) {
    let index = 0;
    const bytes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    if (fileSizeInBytes === undefined || fileSizeInBytes === null || fileSizeInBytes <= 0) {
      return 'N/A';
    }

    do {
      fileSizeInBytes = fileSizeInBytes / 1024;
      index++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0.1).toFixed(2) + ' ' + bytes[index];
  }
}
