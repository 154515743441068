import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { HttpService } from '@tsq-web/core';
import { selectUntilDestroyed } from '@tsq-web/redux/operators';
import { AppState } from '@tsq-web/state';
import { fromUserContextSelectors } from '@tsq-web/user-context';

import { Feedback } from '../models/feedback.model';

@UntilDestroy()
@Injectable()
export class InAppFeedbackService extends HttpService {
  private httpClient = inject(HttpClient);
  private store = inject(Store<AppState>);

  private condoId: string;

  constructor() {
    super();
    this.store
      .pipe(selectUntilDestroyed(fromUserContextSelectors.selectCondo, this))
      .subscribe(condo => (this.condoId = condo?.id));
  }

  getSuggestionChips(getPositive: boolean): Observable<string[]> {
    const params = new HttpParams({ fromObject: { positive: getPositive } });

    return this.httpClient.get<string[]>(
      this.getFullUrl('feedback/chips', 'v1'),
      this.getJsonOptions({ params }),
    );
  }

  postFeedback(feedback: Feedback): Observable<Feedback> {
    const params = new HttpParams({ fromObject: { condoId: this.condoId } });

    return this.httpClient.post<Feedback>(
      this.getFullUrl('feedback', 'v1'),
      feedback,
      this.getJsonOptions({ params }),
    );
  }
}
