/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeStyle } from '@angular/platform-browser';

import { IntroBanner } from '@tsq-web/login';

@Component({
  selector: 'sc-intro-banner',
  templateUrl: 'sc-intro-banner.component.html',
  styleUrls: ['sc-intro-banner.component.scss'],
})
export class SCIntroBannerComponent {
  @Input() currentBanner: IntroBanner;

  constructor(private _sanitizer: DomSanitizer) {}

  get backgroundUrl(): SafeResourceUrl {
    if (this.currentBanner !== undefined && this.currentBanner !== null) {
      return this.currentBanner.backgroundImageUrl;
    }
    return undefined;
  }

  get backgroundStyle(): SafeStyle {
    return this._sanitizer.bypassSecurityTrustStyle(`background-image: url(${this.backgroundUrl})`);
  }

  get foregroundUrl(): SafeResourceUrl {
    if (this.currentBanner !== undefined && this.currentBanner !== null) {
      return this._sanitizer.bypassSecurityTrustResourceUrl(this.currentBanner.foregroundImageUrl);
    }
    return undefined;
  }
}
