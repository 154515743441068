<div class="mobile-float-banner">
  <div class="top-banner">
    <div id="top-info">
      <img src="./assets/img/icons/ic-mobile-device.svg" class="inline-block" alt="" />
      <div class="inline-block">
        <i>
          <div>{{ 'SHARED.ACCESSING_FROM' | translate }}</div>
          <strong>{{ 'SHARED.YOUR_PHONE_' | translate }}</strong></i
        >
      </div>
    </div>
    <div id="bottom-info">{{ infoText | translate }}</div>
  </div>
  <div id="web-link" (click)="keepOnWebClick()">
    <u>{{ 'SHARED.KEEP_ON_WEB' | translate }}</u>
  </div>
  <div class="bottom-banner">
    <img [src]="handImg" class="inline-block" alt="" />
    <div class="inline-block">
      <i
        ><strong>{{ 'SHARED.DONT_HAVE' | translate }}</strong>
        <div>{{ 'SHARED.THE_APP_YET_' | translate }}</div></i
      >
      <img [src]="storeImg" (click)="openStore()" alt="" />
    </div>
  </div>
</div>
