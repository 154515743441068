import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import { Observable, Observer } from 'rxjs';
import { Store } from '@ngrx/store';

import { User } from '@tsq-web/users';
import { AppState } from '@tsq-web/state';
import * as fromUserContextActions from '@tsq-web/user-context/actions';
import { LoginData } from '../models/login-data.model';
import { LoginService } from '../services/login.service';

export class ReloginGuardUtil {

  constructor(protected loginService: LoginService,
              protected cookieService: CookieService,
              protected router: Router,
              protected store: Store<AppState>) {
  }

  reloginOverRoute(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return new Observable((observer: Observer<boolean | UrlTree>) => {
      if (this.cookieService.check('logonToken')) {
        const token = JSON.parse(this.cookieService.get('logonToken'));
        if (!!token) {
          const user = new User();
          user.login_type = 'CMN';
          user.platform = 'webapp';
          user.email = token.email;
          user.logonToken = token.logonToken;

          this.loginService.relogin(user)
            .subscribe(
              (loginData: LoginData) => {
                localStorage.removeItem('refresh');
                localStorage.setItem('currentUser', loginData.user.id);

                this.cookieService.set('jwt', loginData.jwt, null, '/');
                const newToken = { email: loginData.user.email, logonToken: loginData.logon_token };
                this.cookieService.set('logonToken', JSON.stringify(newToken), null, '/');

                this.store.dispatch(fromUserContextActions.setUserState({ loginData }));
                if (!!route.params.condoId) {
                  this.store.dispatch(
                    fromUserContextActions.setCondoById({ condo: route.params.condoId, suppressEvent: true }),
                  );
                }
                observer.next(true);
              },
              () => observer.next(this.router.parseUrl('login')),
            );
        }
      } else {
        observer.next(
          this.router.createUrlTree(['login'], { queryParams: { redirect: state.url } }),
        );
      }
    });
  }
}
