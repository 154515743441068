/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';

import { FormatDatePipe } from '@tsq-web/date-time';

import { TSqFeatureDetails } from '../../models/tsq-feature-details.model';

@Component({
  selector: 'tsq-feature-details',
  templateUrl: './tsq-feature-details.component.html',
  styleUrls: ['./tsq-feature-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TSqFeatureDetailsComponent {
  @Input() detailsTemplate: TemplateRef<any>;
  @Input() showCommentList: boolean;
  @Input() clickableUrl: boolean;

  featureDetails: TSqFeatureDetails;

  constructor(private dateFormatter: FormatDatePipe) {}

  @Input() set details(value: TSqFeatureDetails) {
    if (!!value) {
      value.creationDate = this.dateFormatter.transform(value.creationDate);
    }

    this.featureDetails = value;
  }
}
