<sc-public-container [greetingBgColor]="greetingBackground">
  <div class="full-workspace-height" [class.flex-image]="isPortuguese" left>
    <img [src]="loginImage" class="greeting-image hidden-sm" alt="" />
  </div>
  <div right>
    <div *ngIf="fromRegister">
      <div class="text-center">
        <div class="picture-uploader">
          <img
            #profilePic
            width="150"
            height="150"
            src="{{ user.pictureUrl }}"
            alt=""
            class="media-object img-circle img-center"
          />
        </div>
      </div>

      <div class="text-center text-blue">
        <strong>{{ user.first_name }} {{ user.last_name }}</strong>
      </div>
      <br />
    </div>

    <div *ngIf="!fromRegister && !isPortuguese">
      <div class="coming-soon">{{ 'LOGIN.EXPERIENCE_COMMUNITY_YOUR_WAY' | translate }}</div>
      <div class="welcome-info">{{ 'LOGIN.LOGIN_NOW_HINT' | translate }}</div>
      <div
        class="welcome-info"
        [innerHTML]="'LOGIN.LOGIN_NOW_HINT_2' | translate : { supportEmail: supportEmail }"
      ></div>
    </div>

    <form accept-charset="UTF-8" role="form">
      <fieldset>
        <div class="form-group">
          <label for="login--input--email">{{ 'LOGIN.USERNAME' | translate }}</label>
          <input
            tsqTextInput
            class="control"
            id="login--input--email"
            data-cy="email"
            name="email"
            required
            placeholder="{{ 'LOGIN.USERNAME' | translate }}"
            [(ngModel)]="userEmail"
          />
        </div>

        <div class="form-group">
          <label for="login--input--password">
            {{ 'LOGIN.PASSWORD' | translate }}
          </label>
          <div class="pull-right">
            <a class="forgotpassword" routerLink="/user-recovery">
              <span class="fa fa-lock"></span> {{ 'LOGIN.FORGOT_MY_PASSWORD' | translate }}</a
            >
          </div>
          <input
            tsqPeekablePassword
            type="password"
            class="control"
            id="login--input--password"
            data-cy="password"
            name="password"
            required
            placeholder="{{ 'LOGIN.PASSWORD' | translate }}"
            [(ngModel)]="password"
          />
        </div>

        <button
          data-cy="submit"
          class="btn btn-primary full-width"
          id="login--button--submit"
          [disabled]="isLogging"
          (click)="onSubmit()"
        >
          <span *ngIf="!isLogging">{{ 'LOGIN.LOGIN' | translate }}</span>
          <i *ngIf="isLogging" class="fa fa-spinner fa-spin"></i>
        </button>
      </fieldset>
    </form>

    <div class="under col-xs-12">
      <a
        [href]="
          isPortuguese
            ? 'https://townsq.rcxit.com.br/webchat/?channelHash=976892c6e1be8f7302c94d39eabcd03d#/'
            : '/ais/sign-up'
        "
        [target]="isPortuguese ? '_blank' : ''"
      >
        {{ (isPortuguese ? 'LOGIN.NEED_HELP' : 'LOGIN.NEED_TO_REGISTER') | translate }}
      </a>
    </div>

    <div class="under col-xs-12">
      <a *ngIf="!isPortuguese" href="http://privacy.townsq.io" target="_blank" class="bottom-legal">
        {{ 'LOGIN.PRIVACY_POLICY' | translate }}
      </a>
      <span *ngIf="!isPortuguese"> | </span>
      <a
        [href]="
          isPortuguese
            ? 'https://www.townsq.com.br/termos-de-uso-e-servicos/'
            : 'http://terms.townsq.io'
        "
        target="_blank"
        class="bottom-legal"
      >
        {{ 'LOGIN.TERMS_OF_USE' | translate }}
      </a>
    </div>
  </div>
</sc-public-container>
