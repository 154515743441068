/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, of } from 'rxjs';
import { catchError, distinctUntilChanged, finalize } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Condo } from '@tsq-web/condo';
import { TSqValidators } from '@tsq-web/forms';
import { TsqConfirmationDialogComponent } from '../../shared/component/tsq-confimation-dialog/tsq-confirmation-dialog.component';
import { CondoSubscription } from '../../shared/subscription/condo-subscription.json';
import { PaymentInfo } from '../../shared/subscription/payment-info.json';
import { SubscriptionTabs } from '../../shared/subscription/subscription-tabs.enum';
import { Multiplier } from '../../shared/plans/multiplier.enum';
import { SubscriptionService } from '../../shared/subscription/subscription.service';
import { Plan } from '../../shared/plans/plan.json';
import { FeaturePackage } from '../../shared/plans/feature-package.json';
import { SubscriptionDiscount } from '../../shared/subscription/subscription-discount';
import { CondoAndSubscription } from '../../shared/subscription/condo-and-subscription.json';
import { SubscriptionResponsibleSelectionComponent } from './subscription-responsible-selection/subscription-responsible-selection.component';
import { SubscriptionResponsible } from '../../shared/subscription/subscription-responsible.json';
import { DiscountsSelectionComponent } from './discounts-selection/discounts-selection.component';
import { TrialSelectionComponent } from './trial-selection/trial-selection.component';
import { StripePaymentInfo } from '../../shared/subscription/stripe-payment-info.json';
import { SubscriptionSummaryModalComponent } from './subscription-summary-modal/subscription-summary-modal.component';
import { CommunityDocumentNumberComponent } from './community-cnpj-selection/community-document-number.component';
import { SubscriptionCartComponent } from './subscription-cart/subscription-cart.component';
import { SubscriptionDetails } from '../../shared/subscription/subscription-details.json';
import { PlanService } from '../../shared/plans/plan.service';
import { SubscriptionPriceCount } from '../../shared/subscription/subscription-price-count.json';
import { SubscriptionChargeUtilService } from '../../shared/subscription/subscription-charge-util.service';
import { PipzService } from '../../shared/pipz/pipz.service';
import { getEnvironment } from '../../shared/environment';
import { getFeatureToggle } from '../../shared/feature-toggle';
import { ChargeDateComponent } from './charge-date/charge-date.component';
import { PaymentInfoType } from '../../shared/subscription/payment-info-type.enum';
import { BoletoBillingChanged } from '../../shared/subscription/boletoBillingChanged.model';
import { CommunitiesEnvironments } from '../../shared/environment/models/communities-environments.enum';
import { TaxToPayIssService } from './services/tax-to-pay-iss.service';

@UntilDestroy()
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'subscription-component',
  templateUrl: './main-subscription.component.html',
  styleUrls: ['./main-subscription.component.scss'],
  providers: [PlanService],
})
export class MainSubscriptionComponent implements OnInit {
  readonly PRECONDITION_FAILED = 412;

  @ViewChild('subscriptionSummaryModal', { static: true })
  subscriptionSummaryModal: SubscriptionSummaryModalComponent;
  @ViewChild('askCondoInputModal', { static: true })
  askCondoInputModal: TsqConfirmationDialogComponent;

  @ViewChild('discountsSelection') set discountsSelection(comp: DiscountsSelectionComponent) {
    this._discountsSelection = comp;
    if (this.discountsSelection) {
      this.discountsSelection.initDiscountsSelection(this.subscriptionDraft.discounts);
    }
  }

  @ViewChild('trialSelection') set trialSelection(comp: TrialSelectionComponent) {
    this._trialSelection = comp;
    if (this.trialSelection) {
      this.trialSelection.initTrialSelection(
        this.subscriptionDraft.trial,
        this.subscriptionDraft.trialDuration,
      );
    }
  }

  @ViewChild('chargeDate') chargeDate: ChargeDateComponent;

  @ViewChild('subscriptionResponsibleSelection')
  subscriptionResponsibleSelection: SubscriptionResponsibleSelectionComponent;

  @ViewChild('condoCnpjSelection') condoCnpjSelection: CommunityDocumentNumberComponent;

  @ViewChild('subsCart') subsCart: SubscriptionCartComponent;

  private _discountsSelection: DiscountsSelectionComponent;
  private _trialSelection: TrialSelectionComponent;

  paymentTypes = PaymentInfoType;
  bankSlipValid = false;
  responsibleValid = false;
  condoId: string;

  error = false;
  loading = true;
  openTab: SubscriptionTabs = SubscriptionTabs.PLANS;
  _tabs = SubscriptionTabs;

  condo: Condo;
  currentSubscription: CondoSubscription;
  subscriptionDraft = new CondoSubscription();
  hadSubscriptionPreviously: boolean;

  currentPaymentInfo: PaymentInfo;
  paymentInfoDraft: PaymentInfo;
  hadPaymentInfoPreviously: boolean;

  form: UntypedFormGroup;
  taxesToPayForm: UntypedFormGroup;
  customerTaxes: UntypedFormGroup;
  billingDetails = new UntypedFormGroup({
    document: new UntypedFormControl({ value: '', disabled: false }, [
      Validators.required,
      TSqValidators.validCpfCnpj,
    ]),
  });
  multiplierInput: number;
  _multiplier = Multiplier;

  featureToggle = getFeatureToggle();
  communitiesEnvironments = CommunitiesEnvironments;
  availablePlans: Plan[] = [];

  constructor(
    private _route: ActivatedRoute,
    private _subscriptionService: SubscriptionService,
    private _router: Router,
    private _translateService: TranslateService,
    private _toastr: ToastrService,
    private _chargeUtilService: SubscriptionChargeUtilService,
    private pipzService: PipzService,
    private planService: PlanService,
    private taxToPayIssService: TaxToPayIssService,
  ) {}

  ngOnInit(): void {
    this._route.params.subscribe(params => {
      this.condoId = params.condoId;
      this.getCondoAndSubs(this.condoId);
    });

    this.taxesToPayForm = new UntypedFormGroup({
      ir: new UntypedFormControl(undefined),
      iss: new UntypedFormControl(undefined),
      pisCofinsCsll: new UntypedFormControl(undefined),
    });

    this.customerTaxes = new UntypedFormGroup({
      taxpayer: new UntypedFormControl(false),
      simplesNacional: new UntypedFormControl(false),
    });

    this.form = new UntypedFormGroup({
      multiplierInput: new UntypedFormControl(),
    });

    this.billingDetails.controls.document.valueChanges.subscribe(
      value => (this.subscriptionDraft.document = value),
    );

    this.taxesToPayForm.valueChanges
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe(values => {
        this.paymentInfoDraft.bankSlip.applyIR = values.ir;
        this.paymentInfoDraft.bankSlip.applyISS = values.iss;
        this.paymentInfoDraft.bankSlip.applyPisCofinsCsll = values.pisCofinsCsll;

        this.taxToPayIssService.taxToPayIss$.next(!!values.iss);
      });

    this.customerTaxes.valueChanges
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe(values => {
        this.paymentInfoDraft.bankSlip.taxpayer = values.taxpayer;
        this.paymentInfoDraft.bankSlip.simplesNacional = values.simplesNacional;
      });

    this.planService.getPlans().subscribe(
      plansList => {
        this.availablePlans = plansList.plans;
      },
      () => (this.error = true),
    );
  }

  now(): Date {
    return new Date();
  }

  get discountsSelection(): DiscountsSelectionComponent {
    return this._discountsSelection;
  }

  get trialSelection(): TrialSelectionComponent {
    return this._trialSelection;
  }

  get environmentConfig(): string {
    return getEnvironment().env;
  }

  setInitialPaymentTypeSelected(): string {
    if (this.environmentConfig === 'US_PROD') {
      return PaymentInfoType.Stripe;
    } else if (this.environmentConfig === 'PROD') {
      return PaymentInfoType.BankSlip;
    } else {
      return this._translateService.currentLang === 'pt'
        ? PaymentInfoType.BankSlip
        : PaymentInfoType.Stripe;
    }
  }

  isBankSlip(): boolean {
    return this.paymentInfoDraft.type === PaymentInfoType.BankSlip;
  }

  isCreditCard(): boolean {
    return this.paymentInfoDraft.type === PaymentInfoType.Stripe;
  }

  isInvoice(): boolean {
    return this.paymentInfoDraft.type === PaymentInfoType.Invoice;
  }

  isDelegate(): boolean {
    return !!this.currentPaymentInfo && this.currentPaymentInfo.type === PaymentInfoType.Delegate;
  }

  onConfirmChangeSubscription(): void {
    this.subscriptionDraft.discounts.forEach(
      discount => (discount.rawValue = Math.floor(discount.rawValue * 1000) / 10),
    );

    const chargeValue = this.subscriptionDraft.chargeValue;
    this.subscriptionDraft.chargeValue = chargeValue * 100;

    this.saveSubscription();
  }

  onCancelChangeSubscription(): void {
    this._router.navigate(['d', 'condos', this.condo.id, 'details']);
  }

  onPlanSelected(plan: Plan): void {
    this.multiplierInput = undefined;
    this.subscriptionDraft.plan = plan;
    this.subscriptionDraft.featurePackages = [];
    if (plan.multiplier !== Multiplier[Multiplier.NONE]) {
      this.askCondoInputModal.askConfirmation();
    } else {
      this.openTab = SubscriptionTabs.FEATURE_PACKAGES;
    }
  }

  get multiplierInputValid(): boolean {
    return this.form.valid && +this.form.controls.multiplierInput.value > 0;
  }

  onMultiplierInputConfirm(): void {
    this.multiplierInput = this.form.controls.multiplierInput.value;
    this.form.controls.multiplierInput.setValue('');
    this.openTab = SubscriptionTabs.FEATURE_PACKAGES;
  }

  onMultiplierInputCancel(): void {
    this.subscriptionDraft.plan = this.currentSubscription
      ? this.currentSubscription.plan
      : undefined;
    this.subscriptionDraft.featurePackages = this.currentSubscription
      ? this.currentSubscription.featurePackages
      : [];
    this.form.controls.multiplierInput.setValue('');
  }

  onFeaturePackageToggle(featurePackage: FeaturePackage): void {
    const index = this.subscriptionDraft.featurePackages.findIndex(f => f.id === featurePackage.id);
    if (index >= 0) {
      this.subscriptionDraft.featurePackages.splice(index, 1);
    } else {
      this.subscriptionDraft.featurePackages.push(featurePackage);
    }
  }

  onDiscountsSelectionChanged(discounts: SubscriptionDiscount[]): void {
    this.subscriptionDraft.discounts = discounts;
  }

  onTrialSelectionChanged(trialJson: Record<string, boolean | number>): void {
    if (!!trialJson) {
      this.subscriptionDraft.trial = (trialJson.trial ? trialJson.trial : false) as boolean;
      this.subscriptionDraft.trialDuration = (
        this.subscriptionDraft.trial ? trialJson.trialDuration : undefined
      ) as number;
    }
  }

  onNextChargeDateChanged(date: string): void {
    this.subscriptionDraft.nextChargeDate = date.toString();
  }

  onChargeValueChanged(value: number): void {
    this.subscriptionDraft.chargeValue = value;
  }

  onSubscriptionResponsibleChanged(responsible: SubscriptionResponsible): void {
    this.subscriptionDraft.responsible = responsible;
  }

  responsibleValidChanged(valid: boolean): void {
    this.responsibleValid = valid;
  }

  brBillingChanged(validAndInfo: BoletoBillingChanged): void {
    this.bankSlipValid = validAndInfo.valid;
    this.paymentInfoDraft.bankSlip = validAndInfo.bankSlip;
    this.paymentInfoDraft.bankSlip.applyIR = this.taxesToPayForm.controls.ir.value;
    this.paymentInfoDraft.bankSlip.applyISS = this.taxesToPayForm.controls.iss.value;
    this.paymentInfoDraft.bankSlip.applyPisCofinsCsll =
      this.taxesToPayForm.controls.pisCofinsCsll.value;
    this.paymentInfoDraft.bankSlip.taxpayer = this.customerTaxes.controls.taxpayer.value;
    this.paymentInfoDraft.bankSlip.simplesNacional =
      this.customerTaxes.controls.simplesNacional.value;
  }

  usBillingChanged(stripePaymentInfo: StripePaymentInfo): void {
    this.paymentInfoDraft.stripe = stripePaymentInfo;
  }

  cartValid(): boolean {
    return (
      !!this.subscriptionDraft.plan &&
      (this.billingDetails.controls.document.valid || this.environmentConfig !== 'PROD') &&
      (!this.requiresBillingInfo() || (this.responsibleValid && this.isBillingSelectionValid()))
    );
  }

  requiresBillingInfo(): boolean {
    return (
      !(this.subscriptionDraft.trial && !!this.subscriptionDraft.trialDuration) &&
      (this.totalSubscriptionValue > 0 ||
        (this.subscriptionDraft.plan.multiplier !== Multiplier.NONE.toString() &&
          this.subscriptionDraft.plan.price.amount > 0))
    );
  }

  isBillingSelectionValid(): boolean {
    if (this.subscriptionDraft.trial) {
      return true;
    }

    return this.billingSelectionValid;
  }

  get billingSelectionValid(): boolean {
    if (this.isDelegate()) {
      return this.billingDetails.controls.document.valid;
    } else if (this.isCreditCard()) {
      if (this.hadSubscriptionPreviously) {
        return this.isStripeAndHasCustomer() || this.isStripeAndHasToken();
      } else {
        return this.isStripeAndHasToken();
      }
    } else if (this.isBankSlip()) {
      return this.bankSlipValid;
    } else {
      return this.isInvoice();
    }
  }

  get totalSubscriptionValue(): number {
    return this._chargeUtilService.getTotalSubscriptionValue(
      this.subscriptionDraft,
      this.multiplierInput,
    );
  }

  get percentageForCart(): number {
    if (this.subscriptionDraft?.discounts?.length) {
      const totalPercentageDiscount = this._chargeUtilService.totalPercentageDiscount(
        this.subscriptionDraft,
      );

      return totalPercentageDiscount > 0 ? totalPercentageDiscount : undefined;
    }

    return undefined;
  }

  get rawValueForCart(): string {
    if (this.subscriptionDraft?.discounts?.length) {
      const totalRawDiscount = this._chargeUtilService.totalRawValueDiscount(
        this.subscriptionDraft,
      );

      return totalRawDiscount > 0 ? totalRawDiscount.toFixed(2) : undefined;
    }

    return undefined;
  }

  getCondoAndSubs(condoId: string): void {
    this.loading = true;
    this.error = false;
    this._subscriptionService
      .getCondoAndSubscription(condoId)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(
        condoAndSubs => this.setup(condoAndSubs),
        () => (this.error = true),
      );
  }

  private isStripeAndHasCustomer(): boolean {
    return !!this.paymentInfoDraft.stripe && !!this.paymentInfoDraft.stripe.customer;
  }

  private isStripeAndHasToken(): boolean {
    return !!this.paymentInfoDraft.stripe && !!this.paymentInfoDraft.stripe.token;
  }

  private setup(condoAndSubs: CondoAndSubscription): void {
    this.condo = condoAndSubs.condo;

    if (condoAndSubs.subscriptionDetails?.paymentInfo) {
      this.currentPaymentInfo = condoAndSubs.subscriptionDetails.paymentInfo;
      this.buildPaymentInfoDraft(this.currentPaymentInfo);
      this.hadPaymentInfoPreviously = true;
    } else {
      this.hadPaymentInfoPreviously = false;
      this.paymentInfoDraft = new PaymentInfo();
      this.paymentInfoDraft.type = this.setInitialPaymentTypeSelected();
    }

    if (condoAndSubs.subscriptionDetails?.subscription) {
      this.currentSubscription = condoAndSubs.subscriptionDetails.subscription;
      this.buildSubsDraft(this.currentSubscription);
      this.hadSubscriptionPreviously = true;
    } else {
      this.hadSubscriptionPreviously = false;
      this.subscriptionDraft = new CondoSubscription();
    }
  }

  private buildSubsDraft(currentSubscription: CondoSubscription): void {
    this.subscriptionDraft = new CondoSubscription();
    this.subscriptionDraft.chargeValue = currentSubscription.chargeValue / 100;
    this.subscriptionDraft.plan = currentSubscription.plan;
    this.subscriptionDraft.featurePackages = currentSubscription.featurePackages;
    this.subscriptionDraft.discounts = currentSubscription.discounts;
    this.subscriptionDraft.discounts.forEach(discount => {
      if (discount.rawValue) {
        discount.rawValue = discount.rawValue / 100;
      }
    });
    this.subscriptionDraft.trial = currentSubscription.trial;
    this.subscriptionDraft.trialDuration = currentSubscription.trialDuration;
    this.subscriptionDraft.responsible = currentSubscription.responsible;
    this.subscriptionDraft.nextChargeDate = currentSubscription.nextChargeDate;
    this.subscriptionDraft.fixedCharges = this.currentSubscription.fixedCharges;
    if (
      this.subscriptionDraft.plan &&
      this.subscriptionDraft.plan.multiplier !== Multiplier[Multiplier.NONE]
    ) {
      this.getSubscriptionPriceCount();
    }
    this.billingDetails.controls.document.setValue(currentSubscription.document);
  }

  private buildPaymentInfoDraft(currentPaymentInfo: PaymentInfo): void {
    this.paymentInfoDraft = new PaymentInfo();
    this.paymentInfoDraft.type = currentPaymentInfo.type;

    if (currentPaymentInfo.type === PaymentInfoType.BankSlip) {
      this.paymentInfoDraft.bankSlip = currentPaymentInfo.bankSlip;

      this.taxesToPayForm.setValue({
        ir: currentPaymentInfo.bankSlip.applyIR,
        iss: currentPaymentInfo.bankSlip.applyISS,
        pisCofinsCsll: currentPaymentInfo.bankSlip.applyPisCofinsCsll,
      });

      this.customerTaxes.setValue({
        taxpayer: currentPaymentInfo.bankSlip?.taxpayer
          ? currentPaymentInfo.bankSlip?.taxpayer
          : false,
        simplesNacional: currentPaymentInfo.bankSlip?.simplesNacional
          ? currentPaymentInfo.bankSlip?.simplesNacional
          : false,
      });
    } else if (currentPaymentInfo.type === PaymentInfoType.Stripe) {
      this.paymentInfoDraft.stripe = currentPaymentInfo.stripe;
    }
  }

  private getSubscriptionPriceCount(): void {
    this.loading = true;
    this._subscriptionService
      .getSubscriptionPriceCounts(this.condo.id)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(
        (priceCounts: SubscriptionPriceCount) => {
          this.multiplierInput =
            this.subscriptionDraft.plan.multiplier === Multiplier[Multiplier.USER]
              ? priceCounts.users
              : priceCounts.units;
        },
        () => (this.error = true),
      );
  }

  private saveSubscription(): void {
    if (this.hadSubscriptionPreviously && this.hadPaymentInfoPreviously) {
      this.updateSubsAndPaymentInfo();
    } else if (this.hadSubscriptionPreviously && !this.hadPaymentInfoPreviously) {
      this.putSubsPostPaymentInfo();
    } else if (!this.hadSubscriptionPreviously && !this.hadPaymentInfoPreviously) {
      this.postSubsDetails();
    } else if (!this.hadSubscriptionPreviously && this.hadPaymentInfoPreviously) {
      this.postSubsPutPaymentInfo();
    }
  }

  private postSubsPutPaymentInfo(): void {
    const postSubscription = this._subscriptionService
      .postSubscription(this.condo.id, this.subscriptionDraft)
      .pipe(catchError(() => of(undefined)));

    const putPaymentInfo = this._subscriptionService
      .updatePaymentInfo(this.condo.id, this.paymentInfoDraft)
      .pipe(catchError(() => of(undefined)));

    this.subsCart.isSending = true;
    this.billingDetails.controls.document.disable();
    forkJoin([postSubscription, putPaymentInfo])
      .pipe(
        finalize(() => {
          this.subsCart.isSending = false;
          this.billingDetails.controls.document.enable();
        }),
      )
      .subscribe(([condoSubscription, paymentInfo]) => {
        if (!!condoSubscription && !!paymentInfo) {
          this._toastr.success(this._translateService.instant('SUBSCRIPTION.PAYMENT_UPDATED'));
          this.onSubscriptionSaveSuccess(
            this._translateService.instant('SUBSCRIPTION.SUBSCRIPTION_CREATED', {
              condoName: this.condo.name,
            }),
          );
        } else {
          if (!!condoSubscription) {
            this._toastr.success(
              this._translateService.instant('SUBSCRIPTION.SUBSCRIPTION_CREATED', {
                condoName: this.condo.name,
              }),
            );
            this._toastr.error(this._translateService.instant('SUBSCRIPTION.PAYMENT_UPDATE_ERROR'));
          } else if (!!paymentInfo) {
            this._toastr.success(this._translateService.instant('SUBSCRIPTION.PAYMENT_UPDATED'));
            this._toastr.error(
              this._translateService.instant('SUBSCRIPTION.SUBSCRIPTION_ERROR_CREATING'),
            );
          } else {
            this._toastr.error(this._translateService.instant('SUBSCRIPTION.PAYMENT_UPDATE_ERROR'));
            this._toastr.error(
              this._translateService.instant('SUBSCRIPTION.SUBSCRIPTION_ERROR_CREATING'),
            );
          }
        }
      });
  }

  private postSubsDetails(): void {
    this.subsCart.isSending = true;
    this.billingDetails.controls.document.disable();
    const paymentInfoFinal =
      (this.totalSubscriptionValue === 0 || this.subscriptionDraft.trial) &&
      !this.billingSelectionValid
        ? undefined
        : this.paymentInfoDraft;
    const subsDetails = SubscriptionDetails.from(this.subscriptionDraft, paymentInfoFinal);
    this._subscriptionService
      .postSubscriptionDetails(this.condo.id, subsDetails)
      .pipe(
        finalize(() => {
          this.subsCart.isSending = false;
          this.billingDetails.controls.document.enable();
        }),
      )
      .subscribe(
        () => {
          this.onSubscriptionSaveSuccess(
            this._translateService.instant('SUBSCRIPTION.SUBSCRIPTION_CREATED', {
              condoName: this.condo.name,
            }),
          );
          this.pipzService.track('Subscription registered');
        },
        () =>
          this._toastr.error(
            this._translateService.instant('SUBSCRIPTION.SUBSCRIPTION_ERROR_CREATING'),
          ),
      );
  }

  private putSubsPostPaymentInfo(): void {
    let subsUpdated = false;
    let paymentPosted = false;

    this.subsCart.isSending = true;
    this.billingDetails.controls.document.disable();
    this._subscriptionService
      .updateSubscription(this.condo.id, this.subscriptionDraft)
      .pipe(
        finalize(() => {
          if (
            (this.totalSubscriptionValue === 0 || this.subscriptionDraft.trial) &&
            !this.billingSelectionValid
          ) {
            this.subsCart.isSending = false;
            this.billingDetails.controls.document.enable();
            if (subsUpdated) {
              this._router.navigate(['d', 'condos', this.condo.id, 'details']);
            }
          } else {
            this._subscriptionService
              .postPaymentInfo(this.condo.id, this.paymentInfoDraft)
              .pipe(
                finalize(() => {
                  this.subsCart.isSending = false;
                  if (subsUpdated && paymentPosted) {
                    this._router.navigate(['d', 'condos', this.condo.id, 'details']);
                  }
                }),
              )
              .subscribe(
                () => {
                  paymentPosted = true;
                  this._toastr.success(
                    this._translateService.instant('SUBSCRIPTION.PAYMENT_UPDATED'),
                  );
                },
                () => {
                  paymentPosted = false;
                  this._toastr.error(
                    this._translateService.instant('SUBSCRIPTION.PAYMENT_UPDATE_ERROR'),
                  );
                },
              );
          }
        }),
      )
      .subscribe(
        () => {
          subsUpdated = true;
          this._toastr.success(
            this._translateService.instant('SUBSCRIPTION.SUBSCRIPTION_UPDATED', {
              condoName: this.condo.name,
            }),
          );
        },
        error => {
          if (error.status === this.PRECONDITION_FAILED) {
            subsUpdated = true;
            this._toastr.success(
              this._translateService.instant('SUBSCRIPTION.NO_CHANGES_SUBSCRIPTION', {
                condoName: this.condo.name,
              }),
            );
          } else {
            subsUpdated = false;
            this._toastr.error(
              this._translateService.instant('SUBSCRIPTION.SUBSCRIPTION_ERROR_UPDATING'),
            );
          }
        },
      );
  }

  private updateSubsAndPaymentInfo(): void {
    let subsUpdated = false;
    let paymentUpdated = false;

    this.subsCart.isSending = true;
    this.billingDetails.controls.document.disable();
    this._subscriptionService
      .updateSubscription(this.condo.id, this.subscriptionDraft)
      .pipe(
        finalize(() => {
          this._subscriptionService
            .updatePaymentInfo(this.condo.id, this.paymentInfoDraft)
            .pipe(
              finalize(() => {
                this.subsCart.isSending = false;
                this.billingDetails.controls.document.enable();
                if (subsUpdated && paymentUpdated) {
                  this._router.navigate(['d', 'condos', this.condo.id, 'details']);
                }
              }),
            )
            .subscribe(
              () => {
                paymentUpdated = true;
                this._toastr.success(
                  this._translateService.instant('SUBSCRIPTION.PAYMENT_UPDATED'),
                );
              },
              () => {
                paymentUpdated = false;
                this._toastr.error(
                  this._translateService.instant('SUBSCRIPTION.PAYMENT_UPDATE_ERROR'),
                );
              },
            );
        }),
      )
      .subscribe(
        () => {
          subsUpdated = true;
          this._toastr.success(
            this._translateService.instant('SUBSCRIPTION.SUBSCRIPTION_UPDATED', {
              condoName: this.condo.name,
            }),
          );
        },
        error => {
          if (error.status === this.PRECONDITION_FAILED) {
            subsUpdated = true;
            this._toastr.success(
              this._translateService.instant('SUBSCRIPTION.NO_CHANGES_SUBSCRIPTION', {
                condoName: this.condo.name,
              }),
            );
          } else {
            subsUpdated = false;
            this._toastr.error(
              this._translateService.instant('SUBSCRIPTION.SUBSCRIPTION_ERROR_UPDATING'),
            );
          }
        },
      );
  }

  private onSubscriptionSaveSuccess(message: string): void {
    this._toastr.success(this._translateService.instant(message));
    this._router.navigate(['d', 'condos', this.condo.id, 'details']);
  }
}
