<li [class.open]="isCollapsed" class="hasChild">
  <a (click)="toggleCollapse()">
    <i><img src="{{icon}}" alt=""></i>
    <span>{{label}}</span>
  </a>
  <div id="divider"></div>
  <ul class="acc-menu" [style.display]="displayChildren()">
    <ng-content></ng-content>
  </ul>
  <div></div>
</li>
