<div>
  <input
    type="radio"
    [attr.id]="id"
    [value]=value
    [name]="inputName">
  <label (click)="onClick.emit()" [for]="id">
    <div>
      <div></div>
    </div>
  </label>
</div>
