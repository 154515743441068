import {
  NeighborMassInviteActionTypes,
  NeighborMassInviteActions,
} from './neighbor-mass-invite.actions';

export interface NeighborMassInviteState {
  loading: boolean;
  success: boolean;
}

export const neighborMassInviteInitialState: NeighborMassInviteState = {
  loading: false,
  success: false,
};

export function neighborMassInviteReducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: NeighborMassInviteState = neighborMassInviteInitialState,
  action: NeighborMassInviteActions,
): NeighborMassInviteState {
  switch (action.type) {
    case NeighborMassInviteActionTypes.RestoreNeighborMassInviteState:
      return neighborMassInviteInitialState;
    case NeighborMassInviteActionTypes.SendInvitesRequest:
      return {
        ...state,
        loading: true,
      };
    case NeighborMassInviteActionTypes.SendInvitesSuccess:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case NeighborMassInviteActionTypes.SendInvitesError:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
