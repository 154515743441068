<div
  class="inline-container tooltip-multiline font-regular"
  [ngClass]="{ '!cursor-not-allowed': disabled }"
  [attr.data-tooltip]="disabled ? tooltipMessage : null"
>
  <div
    class="selected-mark"
    [ngClass]="{ '!bg-petro-n2': disabled }"
    [ngStyle]="{
      width: 100 / options.length + '%',
      left: selectedIndex * (100 / options.length) + '%'
    }"
  ></div>
  <div
    class="inline-label font-bold"
    [ngClass]="{ '!text-coal-tertiary pointer-events-none ': disabled }"
    *ngFor="let option of options; let i = index"
    [attr.selected]="i === selectedIndex ? true : null"
    (click)="onOptionSelected(i); $event.stopPropagation()"
  >
    {{ option.label | translate }}
  </div>
</div>
