/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UserService } from '../../user/user.service';
import { User } from '@tsq-web/users';
@Component({
  selector: 'sc-search-alert',
  templateUrl: 'sc-search-alert.component.html',
  styleUrls: ['sc-search-alert.component.scss'],
})
export class SCSearchAlertComponent {
  @Input() filterName = '';
  @Input() closable = true;
  @Output() onClose = new EventEmitter();
  _userId: string;
  user: User;
  name = '';
  pictureUrl = '';
  showAlert = false;

  constructor(public _userService: UserService) {}

  @Input()
  set userId(userId: string) {
    this._userId = userId;
    this.showAlert = this._userId && this._userId !== '';

    if (this.showAlert) {
      this._userService.getResident(this._userId).subscribe(user => {
        if (user?.pictureUrl && user.name) {
          this.name = user.name;
          this.pictureUrl = user.pictureUrl;
          this.setShowAlert(true);
        } else {
          this.setShowAlert(false);
        }
      });
    }
  }

  setShowAlert(show: boolean) {
    this.showAlert = show;
  }

  close() {
    this.onClose.emit();
    this.showAlert = false;
  }
}
