<h1>{{ 'LOGIN.REVIEW_ACCESS.TITLE' | translate }}</h1>
<p class="text-md mb-0 mt-8">{{ 'LOGIN.REVIEW_ACCESS.DESCRIPTION' | translate }}</p>

<form class="group mt-16" [formGroup]="form" (ngSubmit)="onLogin()">
  <comm-error-banner>
    <div class="flex flex-col">
      <label class="base-text-bold mb-0">
        {{ titleKey | translate }}
      </label>

      <p class="small-text mb-0">
        {{ descriptionKey | translate }}
      </p>
    </div>
  </comm-error-banner>

  <label for="review-access--input--email" class="mt-16">{{ 'LOGIN.EMAIL' | translate }}</label>
  <div class="relative w-full">
    <input
      tsqTextInput
      id="review-access--input--email"
      schema="new-primary"
      type="text"
      name="email"
      class="control font-base mb-16 w-full"
      formControlName="username"
      [placeholder]="'LOGIN.EMAIL_PLACEHOLDER_V2' | translate"
    />
    <button class="absolute right-16 top-8 cursor-pointer">
      <tsq-icon class="text-new-primary-p2" [icon]="pencilIcon" (click)="onRestartFlow()" />
    </button>
  </div>

  <label for="review-access--input--password">{{ 'LOGIN.PASSWORD' | translate }}</label>
  <input
    id="review-access--input--password"
    schema="new-primary"
    tsqPeekablePassword
    type="password"
    formControlName="password"
    class="control font-base"
  />

  <div class="mt-16 flex justify-center">
    <a
      data-testid="review-access--a--forgot-password"
      routerLink="/user-recovery"
      class="new-primary-link"
      >{{ 'LOGIN.FORGOT_YOUR_PASSWORD_V2' | translate }}</a
    >
  </div>

  <div class="mt-24">
    <tsq-button
      *ngIf="!isLoginIn; else loader"
      [disabled]="isLoginDisabled"
      schema="new-primary"
      size="lg"
      type="submit"
      [text]="'LOGIN.LOG_IN_V2' | translate"
    />

    <ng-template #loader>
      <tsq-small-loader />
    </ng-template>
  </div>
</form>
