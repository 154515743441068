<div class="panel">
  <div class="panel-heading">
    <img src="./assets/img/profile/privacy-icon.svg" alt="" />
    <span
      ><strong>{{ 'ACCOUNT.PRIVACY' | translate | uppercase }}</strong></span
    >
  </div>
  <div class="panel-body">
    <div *ngIf="!isLoading">
      <div>
        <tsq-card-for-switch
          id="show-address-switch"
          label="{{ 'ACCOUNT.PRIVACY_SETTINGS.SHOW_ADDRESS' | translate }}"
        >
          <sc-switch [size]="'small'" [(checked)]="privacySettings.showAddress"></sc-switch>
        </tsq-card-for-switch>
      </div>
      <div class="margin-top-20">
        <tsq-card-for-switch
          id="show-phone-switch"
          label="{{ 'ACCOUNT.PRIVACY_SETTINGS.SHOW_TELEPHONE' | translate }}"
        >
          <sc-switch [size]="'small'" [(checked)]="privacySettings.showTelephone"></sc-switch>
        </tsq-card-for-switch>
      </div>
      <div class="margin-top-20">
        <tsq-card-for-switch
          id="show-email-switch"
          label="{{ 'ACCOUNT.PRIVACY_SETTINGS.SHOW_EMAIL' | translate }}"
        >
          <sc-switch [size]="'small'" [(checked)]="privacySettings.showEmail"></sc-switch>
        </tsq-card-for-switch>
      </div>
    </div>

    <tsq-small-loader *ngIf="isLoading"></tsq-small-loader>
  </div>
</div>
