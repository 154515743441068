export class StripePaymentInfo {
  lastFour: string;
  brand: string;
  token: string;
  customer: string;

  static from(lastFour: string, brand: string, token: string): StripePaymentInfo {
    const spi = new StripePaymentInfo();
    spi.lastFour = lastFour;
    spi.brand = brand;
    spi.token = token;
    return spi;
  }
}
