import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'tsq-feature-header',
  templateUrl: './feature-header.component.html',
  styleUrls: ['./feature-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureHeaderComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() text: string;
  @Input() showSearchInput = true;
  @Input() multiLine: boolean;
  @Input() searchPlaceholder = 'SEARCH';
  @Output() searchChange = new EventEmitter<string>();

  searchControl = new UntypedFormControl('');

  private componentDestroyed$ = new Subject<void>();
  private _customSearchWidth = '205px';
  private readonly defaultSearchWidth = '205px';

  ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged(), takeUntil(this.componentDestroyed$))
      .subscribe((search: string) => this.searchChange.emit(search));
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.unsubscribe();
  }

  @Input() set search(value: string) {
    this.searchControl.setValue(value);
  }

  @Input() set customSearchWidth(value: string) {
    const isValidWidth = /^(\d+)(px|em|rem|%)$/.test(value);
    this._customSearchWidth = isValidWidth ? value : this.defaultSearchWidth;
  }

  get customSearchWidth(): string {
    return this._customSearchWidth;
  }
}
