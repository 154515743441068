export class AdministratorFeatures {
  vehicles = true;
  userEditionAndCreation = true;
  propertyEditionAndCreation = true;
  disablesReports = true;
  residentDocuments = true;
  editDefaultGroups = true;
  manageOwnerRoles = true;
  manageManagerRoles = true;
  subscriptionEditionAndCreation = true;
  financial = false;
  externalPaymentLinkDisabled = false;
  payments = false;
}
