<div class="tsq-card">
  <div class="create-comment-container" [class.is-uploading]="!!fileUploader && fileUploader.uploading">
    <div>
      <input class="form-control" tsqInput
             *ngIf="isSingleLine"
             [placeholder]="'ADD_COMMENT' | translate"
             [formControl]="commentTextControl">

      <textarea class="form-control" tsqInput
                *ngIf="!isSingleLine"
                [placeholder]="'ADD_COMMENT' | translate"
                [formControl]="commentTextControl"></textarea>
    </div>

    <div class="actions-container" [class.has-attachments]="hasAttachments">

      <div [hidden]="!hasAttachments || (!!fileUploader && fileUploader.uploading)">
        <tsq-file-uploader [maxFiles]="maxAttachments"
                           [selfHandleVisualization]="true"
                           [selfHandleSending]="true" #fileUploader></tsq-file-uploader>
      </div>

      <div class="loading-container" *ngIf="hasAttachments && !!fileUploader && fileUploader.uploading">
        <tsq-small-loader></tsq-small-loader>
      </div>

      <div class="send-btn-container" *ngIf="!sendingComment">
        <button class="btn btn-primary"
                [disabled]="!formValid"
                (click)="onSaveComment()">{{ 'SEND' | translate }}</button>
      </div>

      <div class="loading-container" *ngIf="sendingComment">
        <tsq-small-loader></tsq-small-loader>
      </div>

    </div>
  </div>

  <ng-container *ngTemplateOutlet="extraActionsTemplate"></ng-container>

  <tsq-uploaded-list [hidden]="!hasAttachments"
                     [uploader]="fileUploader.uploader"
                     [uploading]="fileUploader.uploading"
                     [disabled]="fileUploader.disabled"
                     [files]="fileUploader.files"
                     (delete)="fileUploader.removeAt($event)"></tsq-uploaded-list>
</div>
