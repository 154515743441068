import { latinise } from '@tsq-web/text';

export function containsAllWords(targetPhrase: string, searchPhrase: string): boolean {
  if (!searchPhrase) {
    return true;
  }
  const filters = searchPhrase.replace(/\s+/, ' ').trim().split(' ');
  const tokens: string[] = [];
  tokens.push(...targetPhrase.split(' '));
  return tokenizedSearch(filters, tokens);
}

export function tokenizedSearch(filters: string[], tokens: string[]): boolean {
  return filters.every(fToken => {
    return tokens.some(token => {
      if (!token) {
        return false;
      }
      return latinise(token.toLocaleLowerCase()).includes(latinise(fToken.toLocaleLowerCase()));
    });
  });
}
