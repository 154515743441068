import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';

import { partition } from 'lodash';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { FileMeta } from '@tsq-web/files';

@Component({
  selector: 'tsq-attachment-gallery',
  templateUrl: './attachment-gallery.component.html',
  styleUrls: ['./attachment-gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentGalleryComponent {
  @ViewChild('modal') modal: ModalDirective;

  images: FileMeta[];
  files: FileMeta[];

  loadedImages: number[] = [];

  currentIndex: number;
  currentLoading = true;

  @Input() set attachments(value: FileMeta[]) {
    const [images, nonImages] = partition(value || [], att => this.isImage(att));
    this.images = images;
    this.files = nonImages;
  }

  get currentImage(): FileMeta {
    return this.images[this.currentIndex];
  }

  hasLoaded(i: number): boolean {
    return this.loadedImages.find(l => l === i) !== undefined;
  }

  loadedImage(i: number): void {
    this.loadedImages.push(i);
  }

  selectImage(index: number): void {
    this.currentIndex = index;
    this.modal.show();
  }

  download(): void {
    window.open(this.currentImage?.fileUrl, '_blank');
  }

  close(): void {
    this.modal.hide();
    setTimeout(() => {
      this.currentIndex = undefined;
      this.currentLoading = true;
    }, 400);
  }

  previous(): void {
    if (this.currentIndex !== 0) {
      this.currentLoading = true;
      this.currentIndex--;
    }
  }

  next(): void {
    if (this.currentIndex < this.images?.length - 1) {
      this.currentLoading = true;
      this.currentIndex++;
    }
  }

  private isImage(att: FileMeta & { extension?: string }): boolean {
    return ['png', 'jpg', 'jpeg', 'gif'].some(ext => {
      if (!!att.filenameExt) {
        return att.filenameExt.toLocaleLowerCase() === ext;
      } else if (!!att.extension) {
        return att.extension.toLocaleLowerCase() === ext;
      }

      return false;
    });
  }
}
