<sc-public-container greetingBgColor="{{ greetingBackground }}">
  <div class="full-workspace-height" left>
    <img [src]="loginImage" class="greeting-image hidden-sm" alt="" />
  </div>
  <div right>
    <div *ngIf="error" class="public-col-message">
      <h1>{{ 'REGISTER.ERROR_TITLE' | translate }}</h1>
      <p>{{ dynamicErrorMessage | translate }}</p>
    </div>

    <div *ngIf="success" class="public-col-message">
      <img src="../../assets/img/icons/ic-smile-avocado.svg" alt="" />
      <h1 class="success-text">{{ 'INVITE_NEIGHBORS.CONGRATULATIONS_' | translate }}</h1>
      <p>{{ 'INVITE_NEIGHBORS.SUCCESS_NEIGHBOR_REGISTER' | translate }}</p>
    </div>

    <tsq-small-loader
      *ngIf="!inviteFound && !error && !success"
      class="margin-bottom-10"
    ></tsq-small-loader>

    <div *ngIf="!error && inviteFound && !success">
      <div class="uploader-card text-center" ng2FileDrop [uploader]="uploader">
        <img [src]="picture" class="img-circle pointer-cursor" alt="" />
        <div class="upload-text">{{ 'REGISTER.UPLOAD_PROFILE_PICTURE' | translate }}</div>
        <input
          type="file"
          (change)="onFileSelected($event)"
          accept="image/png,image/gif,image/jpeg"
          class="picture-uploader-input"
          ng2FileSelect
          [uploader]="uploader"
          #profilePicInput
        />
      </div>

      <p class="lines-sided-text"></p>

      <form accept-charset="UTF-8" role="form" [formGroup]="form">
        <fieldset>
          <div class="form-group">
            <label for="firstName"
              >{{ 'REGISTER.NAME' | translate }}
              <span class="mandatory-text">{{
                'INVITE_NEIGHBORS.MANDATORY_FIELD' | translate
              }}</span></label
            >
            <input
              type="text"
              class="form-control"
              id="firstName"
              formControlName="firstName"
              placeholder="{{ 'REGISTER.NAME' | translate }}"
              required
            />
          </div>
          <div class="form-group">
            <label for="lastName"
              >{{ 'REGISTER.LAST_NAME' | translate }}
              <span class="mandatory-text">{{
                'INVITE_NEIGHBORS.MANDATORY_FIELD' | translate
              }}</span></label
            >
            <input
              type="text"
              class="form-control"
              id="lastName"
              formControlName="lastName"
              placeholder="{{ 'REGISTER.LAST_NAME' | translate }}"
              required
            />
          </div>
          <div class="form-group">
            <label for="property">{{ 'REGISTER.PROPERTY' | translate }}</label>
            <input
              class="form-control"
              id="property"
              placeholder="{{ 'REGISTER.PROPERTY' | translate }}"
              formControlName="property"
              [(ngModel)]="propertySearch"
              [typeahead]="getDataSource(propertySearch)"
              (typeaheadOnSelect)="onPropertySelected($event)"
              [typeaheadOptionsLimit]="8"
              typeaheadOptionField="fine_description"
              autocomplete="off"
            />
          </div>
          <div class="form-group">
            <label for="email"
              >{{ 'REGISTER.EMAIL' | translate }}
              <span class="mandatory-text">{{
                'INVITE_NEIGHBORS.MANDATORY_FIELD' | translate
              }}</span></label
            >
            <input
              type="text"
              class="form-control"
              id="email"
              formControlName="email"
              placeholder="{{ 'REGISTER.EMAIL' | translate }}"
              required
            />
          </div>
          <div class="form-group">
            <label for="phone">{{ 'REGISTER.PHONE' | translate }}</label>
            <comm-intl-phone-input
              formControlName="phone"
              [preferredCountryCodes]="['BR', 'US', 'CA', 'MX']"
              [defaultSelectedCountryCode]="isPortuguese ? 'BR' : 'US'"
            />
          </div>
          <button class="btn btn-primary" [disabled]="isDisabled()" (click)="onSubmit()">
            {{ 'REGISTER.REGISTER' | translate }}
          </button>
        </fieldset>
      </form>
    </div>
    <div class="text-blue text-center">
      <a routerLink="/login">{{ 'REGISTER.BACK_TO_LOGIN' | translate }}</a>
    </div>
  </div>
</sc-public-container>
