/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChangeDetectionStrategy, Component, Input, OnInit, Optional, Self } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl } from '@angular/forms';

import { cloneDeep } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

import { UserGroup } from '@tsq-web/groups';
import { UserGroupPermission } from '@tsq-web/groups';
import { Permission } from '@tsq-web/permission/models';

@UntilDestroy()
@Component({
  selector: 'tsq-group-permission-selector',
  templateUrl: './tsq-group-permission-selector.component.html',
  styleUrls: ['./tsq-group-permission-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TSqGroupPermissionSelectorComponent implements OnInit, ControlValueAccessor {
  control: AbstractControl;

  options = [
    `SHARED.${Permission.NONE}`,
    `SHARED.${Permission.VIEW}`,
    `SHARED.${Permission.ADMIN}`,
  ];

  private groups$ = new BehaviorSubject<UserGroup[]>([]);

  constructor(@Optional() @Self() public ngControl: NgControl) {
    ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    this.control = this.ngControl.control;

    this.groups$.pipe(untilDestroyed(this)).subscribe(value => {
      const defaultGroupPermissions = value.map(
        group => new UserGroupPermission(group, Permission.NONE),
      );
      const controlGroupPermissions = this.control.value as UserGroupPermission[];
      const all = defaultGroupPermissions.map(groupPermission => {
        const equivalent = (controlGroupPermissions || []).find(
          controlGroupPermission =>
            controlGroupPermission.userGroup.id === groupPermission.userGroup.id,
        );

        return equivalent || groupPermission;
      });
      this.onChange(all);
    });
  }

  @Input() set groups(value: UserGroup[]) {
    if (!!value) {
      this.groups$.next(value);
    }
  }

  get groupPermissions(): UserGroupPermission[] {
    return this.control.value as UserGroupPermission[];
  }

  onChange = (groupPermissions: UserGroupPermission[]) => {};
  onTouched = () => {};

  registerOnChange(fn: (groupPermissions: UserGroupPermission[]) => {}): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {}

  writeValue(obj: any): void {}

  getSelected(index: number): number {
    const permission = this.groupPermissions[index].permission;
    switch (permission) {
      case Permission.NONE:
        return 0;
      case Permission.VIEW:
        return 1;
      case Permission.ADMIN:
        return 2;
    }
  }

  onSelected(selected: number, index: number): void {
    const copy = cloneDeep(this.groupPermissions);
    const newGroupPermissions = copy.map((groupPermission, i) => {
      if (i === index) {
        groupPermission.permission = this.getPermissionByIndex(selected);
      }

      return groupPermission;
    });
    this.onTouched();
    this.onChange(newGroupPermissions);
  }

  private getPermissionByIndex(index: number): Permission {
    switch (index) {
      case 0:
        return Permission.NONE;
      case 1:
        return Permission.VIEW;
      case 2:
        return Permission.ADMIN;
    }
  }
}
