import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { FileUploadModule } from 'ng2-file-upload';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';

import { AssetsModule } from '@tsq-web/assets';
import { TSqPipesModule } from '@tsq-web/pipes';

import { TSqFileUploaderComponent } from './components/tsq-file-uploader/tsq-file-uploader.component';
import { TSqUploadedListComponent } from './components/tsq-uploaded-list/tsq-uploaded-list.component';
import { FilesService } from './services/files.service';

@NgModule({
  imports: [
    CommonModule,
    ProgressbarModule.forRoot(),
    FileUploadModule,
    AssetsModule,
    TSqPipesModule,
    TranslateModule,
  ],
  declarations: [TSqFileUploaderComponent, TSqUploadedListComponent],
  exports: [TSqFileUploaderComponent, TSqUploadedListComponent],
  providers: [FilesService],
})
export class TSqFilesModule {}
