/* eslint-disable @angular-eslint/component-selector */
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'tsq-info-card',
  templateUrl: './tsq-info-card.component.html',
  styleUrls: ['./tsq-info-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TSqInfoCardComponent {
  @Input() title = '';
  @Input() titleColor: 'battleship-grey' | 'avocado' = 'battleship-grey';
  @Input() subtitle = '';
  @Input() icon: string;
  @Input() hasInfo = true;
  @Input() highlight: string;
  @Input() hasNewHighlight = false;

  @Output() infoClick = new EventEmitter();
}
