import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';

import { combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

import { User } from '@tsq-web/users';
import { Condo } from '@tsq-web/condo';
import { AppState } from '@tsq-web/state';
import { isSuperUser } from '@tsq-web/permission';
import { fromUserContextSelectors } from '@tsq-web/user-context';
import { tokenizedSearch } from '@tsq-web/search';
import { CondoSelectionItem } from './models/condo-selection-item.model';
import { CondoService } from '../condo.service';
import * as fromAppActions from '../../state/app.actions';

@UntilDestroy()
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'condo-selection',
  templateUrl: './condo-selection.component.html',
  styleUrls: ['./condo-selection.component.scss'],
})
export class CondoSelectionComponent implements OnInit {
  user: User;
  userCondos: Condo[];
  isSuper: boolean;

  searchControl = new UntypedFormControl('');

  showingCondos: CondoSelectionItem[] = [];
  orderedCondos: CondoSelectionItem[] = [];
  loading: boolean;
  page = 0;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>,
    private condoService: CondoService,
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.store.pipe(select(fromUserContextSelectors.selectUser)),
      this.store.pipe(select(fromUserContextSelectors.selectUserCondos)),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([user, condos]: [User, Condo[]]) => {
        this.user = user;
        this.isSuper = isSuperUser(user);

        if (!this.isSuper) {
          this.userCondos = condos;
          this.orderedCondos = this.sortCondos(
            condos.map(condo => this.condoToCondoSelectionItem(condo)),
          );
          this.showingCondos = this.orderedCondos;
        }
      });

    this.searchControl.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged(), untilDestroyed(this))
      .subscribe(value => this.onSearch(value));
  }

  condoSelected(condo: Condo): void {
    this.store.dispatch(fromAppActions.goToCondo({ condo }));
  }

  shouldShowHeader(index: number): boolean {
    if (!this.showingCondos[index].condo.administrator) {
      return false;
    }
    if (index === 0) {
      return !!this.showingCondos[0].condo.administrator && !!this.showingCondos[0].condo.admId;
    }
    const currentAdmId =
      this.showingCondos[index].condo.administrator && this.showingCondos[index].condo.admId;
    const previousAdmId =
      this.showingCondos[index - 1].condo.administrator &&
      this.showingCondos[index - 1].condo.admId;
    if (currentAdmId !== previousAdmId) {
      return !!currentAdmId;
    }
    return false;
  }

  loadCondoList(search?: string): void {
    this.page++;
    this.loading = true;
    this.condoService
      .getCondoList(search, false, 10, this.page)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((condos: Condo[]) => {
        this.orderedCondos = this.sortCondos([
          ...this.orderedCondos,
          ...condos.map(condo => this.condoToCondoSelectionItem(condo)),
        ]);
        this.showingCondos = this.orderedCondos;
      });
  }

  private onSearch(value: string): void {
    if (this.isSuper) {
      this.orderedCondos = [];
      this.page = 0;
      this.loadCondoList(value);
    } else {
      this.showingCondos = this.orderedCondos.filter(condo => this.passesFilter(value, condo));
    }
  }

  private passesFilter(filter: string, item: CondoSelectionItem): boolean {
    if (!filter) {
      return true;
    }

    const condo = item.condo;

    const filters = filter.replace(/\s+/, ' ').trim().split(' ');

    const tokens: string[] = [];
    tokens.push(...condo.name.toLocaleLowerCase().split(' '));
    tokens.push(...item.address.toLocaleLowerCase().split(' '));
    if (!!condo.administrator) {
      tokens.push(...condo.administrator.name.toLocaleLowerCase().split(' '));
    }

    return tokenizedSearch(filters, tokens);
  }

  private condoToCondoSelectionItem(condo: Condo): CondoSelectionItem {
    return {
      condo,
      address: this.getCondoAddress(condo),
    };
  }

  private getCondoAddress(condo: Condo): string {
    if (!condo.address) {
      return '';
    }
    let address = '';
    if (!!condo.address.street) {
      address += condo.address.street;
      address += ' | ';
    }
    if (!!condo.address.city) {
      address += condo.address.city;
      address += ' ';
    }
    if (!!condo.address.state) {
      address += condo.address.state;
    }
    return address;
  }

  private sortCondos(items: CondoSelectionItem[]): CondoSelectionItem[] {
    if (!!items) {
      return items.sort((c1: CondoSelectionItem, c2: CondoSelectionItem) => {
        if (!c1.condo.administrator && !!c2.condo.administrator) {
          return -1;
        }
        if (!!c1.condo.administrator && !c2.condo.administrator) {
          return 1;
        }
        if (
          (!c1.condo.administrator && !c2.condo.administrator) ||
          c1.condo.administrator.name === c2.condo.administrator.name
        ) {
          return c1.condo.name.localeCompare(c2.condo.name);
        }
        return c1.condo.administrator.name.localeCompare(c2.condo.administrator.name);
      });
    }
    return [];
  }
}
