import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NgxViacepModule } from '@brunoc/ngx-viacep';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { IMaskModule } from 'angular-imask';

import { TSqFormsModule } from '@tsq-web/forms';
import { TSqGenericComponentsModule } from '@tsq-web/generic-components';
import { TSqLoadersModule } from '@tsq-web/loaders';

import { CondoService } from '../../condo/condo.service';
import { SCWidgetModule } from '../../shared/component/sc.widget.module';
import { LibsModule } from '../../shared/libs.module';
import { CommunityPlansModule } from '../../shared/plans/plans.module';
import { ChargeService } from '../../shared/subscription/charge.service';
import { SubscriptionEffects } from '../../shared/subscription/state/subscription/subscription.effects';
import {
  subscriptionFeatureKey,
  subscriptionReducer,
} from '../../shared/subscription/state/subscription/subscription.reducer';
import { SubscriptionChargeUtilService } from '../../shared/subscription/subscription-charge-util.service';
import { SubscriptionService } from '../../shared/subscription/subscription.service';
import { ViacepService } from '../../shared/viacep/viacep.service';
import { BillingInfoComponent } from './billing-info/billing-info.component';
import { BoletoBillingSelectionEditComponent } from './boleto-billing-selection/boleto-billing-selection-edit.component';
import { ChargeDateComponent } from './charge-date/charge-date.component';
import { ChargeValueComponent } from './charge-value/charge-value.component';
import { CommunityDocumentNumberComponent } from './community-cnpj-selection/community-document-number.component';
import { CreditCardBillingSelectionEditComponent } from './credit-card-billing-selection/credit-card-billing-selection-edit.component';
import { CustomerTaxesComponent } from './customer-taxes/customer-taxes.component';
import { DiscountsSelectionComponent } from './discounts-selection/discounts-selection.component';
import { FeaturePackagesSelectionComponent } from './feature-packages-selection/feature-packages-selection.component';
import { MainSubscriptionComponent } from './main-subscription.component';
import { PaymentInfoTypeSelectionComponent } from './payment-info-type-selection/payment-info-type-selection.component';
import { TaxToPayIssService } from './services/tax-to-pay-iss.service';
import { SubscriptionCartComponent } from './subscription-cart/subscription-cart.component';
import { SubscriptionResponsibleSelectionComponent } from './subscription-responsible-selection/subscription-responsible-selection.component';
import { SubscriptionSummaryContentComponent } from './subscription-summary-content/subscription-summary-content.component';
import { SubscriptionSummaryModalComponent } from './subscription-summary-modal/subscription-summary-modal.component';
import { TaxesSelectionComponent } from './taxes-selection/taxes-selection.component';
import { TrialSelectionComponent } from './trial-selection/trial-selection.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    LibsModule,
    IMaskModule,
    NgxViacepModule,
    SCWidgetModule,
    TSqLoadersModule,
    TSqFormsModule,
    CommunityPlansModule,
    StoreModule.forFeature(subscriptionFeatureKey, subscriptionReducer),
    EffectsModule.forFeature([SubscriptionEffects]),
    TSqGenericComponentsModule,
  ],
  exports: [
    CreditCardBillingSelectionEditComponent,
    SubscriptionSummaryContentComponent,
    SubscriptionSummaryModalComponent,
    SubscriptionCartComponent,
    SubscriptionResponsibleSelectionComponent,
    BoletoBillingSelectionEditComponent,
    CommunityDocumentNumberComponent,
  ],
  declarations: [
    MainSubscriptionComponent,
    SubscriptionCartComponent,
    FeaturePackagesSelectionComponent,
    CreditCardBillingSelectionEditComponent,
    SubscriptionResponsibleSelectionComponent,
    DiscountsSelectionComponent,
    TrialSelectionComponent,
    SubscriptionSummaryModalComponent,
    SubscriptionSummaryContentComponent,
    BoletoBillingSelectionEditComponent,
    ChargeDateComponent,
    CommunityDocumentNumberComponent,
    BillingInfoComponent,
    PaymentInfoTypeSelectionComponent,
    TaxesSelectionComponent,
    ChargeValueComponent,
    CustomerTaxesComponent,
  ],
  providers: [
    ChargeService,
    CondoService,
    SubscriptionService,
    SubscriptionChargeUtilService,
    TaxToPayIssService,
    ViacepService,
  ],
})
export class SubscriptionModule {}
