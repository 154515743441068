export function getReadableSize(size: number): string {
  if (!size || size <= 0) {
    return 'N/A';
  }

  let index = 0;
  const bytes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  do {
    size = size / 1024;
    index++;
  } while (size > 1024);

  return Math.max(size, 0.1).toFixed(2) + ' ' + bytes[index];
}
