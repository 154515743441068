import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import { Observable, throwError } from 'rxjs';

import { getTSqEnvironment } from '@tsq-web/environment';

import { BlobOptions } from '../utils/base-service.utils';

/**
 * @deprecated BaseService has been deprecated. Use HttpService instead.
 */
@Injectable()
export class BaseService {
  constructor(
    protected cookieService: CookieService,
    protected httpClient: HttpClient,
    protected router: Router,
  ) {}

  static getFullUrl(url: string, version?: string, service?: string): string {
    const host = getTSqEnvironment().baseApi;
    const api = !version && !service ? 'rest' : 'api';

    return [host, api, service, version, url].filter(part => !!part).join('/');
  }

  protected get options(): {
    headers: Record<string, string | string[]>;
    withCredentials: true;
    observe: 'response';
  } {
    return { headers: this.headers, withCredentials: true, observe: 'response' };
  }

  protected blobOptions(accept?: string): BlobOptions {
    return {
      headers: {
        ...this.headers,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Accept: !!accept ? accept : 'application/pdf',
      },
      withCredentials: true,
      observe: 'body',
      responseType: 'blob',
    };
  }

  protected get headers(): Record<string, string | string[]> {
    const lang = this.cookieService.get('tsq-lang') || this.cookieService.get('sc-lang');
    const headers = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Accept: 'application/json',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Accept-Language': lang,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'xx-sc-l': lang,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'X-Townsq-Application': `${getTSqEnvironment().appType?.toLocaleLowerCase()}/1.0.0 webapp/1 ${this.getBrowserNameAndVersion()}`,
    };

    if (this.cookieService.check('logonToken')) {
      const token = this.cookieService.get('logonToken');
      headers['xx-sc-r'] = (JSON.parse(token) as { logonToken }).logonToken as string;
    }
    const jwt = this.cookieService.get('jwt');
    if (!!jwt) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      (headers as unknown as { Authorization: string }).Authorization = `Bearer ${jwt}`;
    }

    return headers;
  }

  saveEntity<T extends { id: string }>(url: string, entity: T): Observable<T> {
    if (entity.id) {
      return this.doPut(`${url}/${entity.id}`, entity);
    }

    return this.doPost(url, entity);
  }

  doPost<T>(url: string, body: unknown, version?: string): Observable<T>;
  doPost<T>(url: string, body: unknown, params?: HttpParams, version?: string): Observable<T>;
  doPost<T>(url: string, body: unknown, a?: HttpParams | string, b?: string): Observable<T> {
    const params = typeof a === 'string' ? undefined : a;
    const version = typeof a === 'string' ? a : b;
    const options = { headers: this.headers, params, withCredentials: true };

    return this.httpClient.post<T>(BaseService.getFullUrl(url, version), body, options);
  }

  doPut<T>(url: string, body: unknown, version?: string): Observable<T>;
  doPut<T>(url: string, body: unknown, params?: HttpParams, version?: string): Observable<T>;
  doPut<T>(url: string, body: unknown, a?: HttpParams | string, b?: string): Observable<T> {
    const params = typeof a === 'string' ? undefined : a;
    const version = typeof a === 'string' ? a : b;
    const options = { headers: this.headers, params, withCredentials: true };

    return this.httpClient.put<T>(BaseService.getFullUrl(url, version), body, options);
  }

  doPatch<T>(url: string, body: unknown, version?: string): Observable<T>;
  doPatch<T>(url: string, body: unknown, params?: HttpParams, version?: string): Observable<T>;
  doPatch<T>(url: string, body: unknown, a?: HttpParams | string, b?: string): Observable<T> {
    const params = typeof a === 'string' ? undefined : a;
    const version = typeof a === 'string' ? a : b;
    const options = { headers: this.headers, params, withCredentials: true };

    return this.httpClient.patch<T>(BaseService.getFullUrl(url, version), body, options);
  }

  doGet<T>(url: string, version?: string): Observable<T>;
  doGet<T>(url: string, params?: HttpParams, version?: string): Observable<T>;
  doGet<T>(url: string, a?: HttpParams | string, b?: string): Observable<T> {
    const params = typeof a === 'string' ? undefined : a;
    const version = typeof a === 'string' ? a : b;
    const options = { headers: this.headers, withCredentials: true, params };

    return this.httpClient.get<T>(BaseService.getFullUrl(url, version), options);
  }

  doDelete<T>(url: string, version?: string): Observable<T>;
  doDelete<T>(url: string, params?: HttpParams, version?: string): Observable<T>;
  doDelete<T>(url: string, a?: HttpParams | string, b?: string): Observable<T> {
    const params = typeof a === 'string' ? undefined : a;
    const version = typeof a === 'string' ? a : b;
    const options = { headers: this.headers, withCredentials: true, params };

    return this.httpClient.delete<T>(BaseService.getFullUrl(url, version), options);
  }

  protected handleError(error: HttpErrorResponse): Observable<never> {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    let errorMsg = 'Server error';
    if (error.status === 401) {
      this.router.navigate(['login']);
    }
    if (error.message) {
      errorMsg = error.message;
    } else if (error.status) {
      errorMsg = `${error.status} - ${error.statusText}`;
    }

    return throwError(() => errorMsg);
  }

  protected extractData<T>(res: HttpResponse<T>): T {
    const { body } = res;

    return body;
  }

  protected handleErrorWithStatus(error: HttpErrorResponse): Observable<never> {
    return throwError(() => error);
  }

  protected extractDataAndStatus<T>(res: HttpResponse<T>): { status: number; data: T } {
    return { status: res.status, data: res.body };
  }

  protected extractPlainData<T>(res: HttpResponse<T>): T {
    const { body } = res;

    return body;
  }

  protected prepareBody(bodyObj: unknown): string {
    return JSON.stringify(bodyObj);
  }

  private getBrowserNameAndVersion(): string {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(window as unknown as { opr }).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(window as unknown as { chrome }).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }
}
