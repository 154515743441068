import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { inject } from '@angular/core';
import { Params } from '@angular/router';

import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpService } from '@tsq-web/core';
import { Role } from '@tsq-web/permission';
import { selectUntilDestroyed } from '@tsq-web/redux/operators';
import { fromUserContextSelectors } from '@tsq-web/user-context';
import { User } from '@tsq-web/users';

import { ChangePasswordRequest } from '../../+sc-workspace/features/account/models/change-password-request.model';
import { getFeatureToggle } from '../feature-toggle';
import { ChangeEmail } from './changeEmail.json';
import { NotificationSettings } from './notification-settings.model';
import { PrivacySettings } from './privacySettings.json';
import { ProfileConfigurationResponse } from './profile-configuration-response.model';
import { UserLookup } from './user.lookup.json';

@UntilDestroy()
export class UserService extends HttpService {
  private _cachedUsers: User[] = [];
  private _condoId: string;

  private readonly httpClient = inject(HttpClient);
  private readonly store = inject(Store);

  constructor() {
    super();

    this.store
      .pipe(selectUntilDestroyed(fromUserContextSelectors.selectCondo, this))
      .subscribe(condo => (this._condoId = condo?.id));
  }

  getUserFiltered(userId: string): Observable<User> {
    return this.httpClient.get<User>(this.getFullUrl(`users/${userId}/filtered`, 'v1'));
  }

  createUser(user: User): Observable<User> {
    return this.httpClient.post<User>(this.getFullUrl('user'), user);
  }

  updateUser(user: User, isDeletingPhone: boolean): Observable<User> {
    let params: HttpParams = new HttpParams();
    params = params.append('isDeletingPhone', isDeletingPhone);

    return this.httpClient.put<User>(
      this.getFullUrl(`user/${user.id}`),
      user,
      this.getJsonOptions({ params }),
    );
  }

  removeUser(user: User): Observable<User> {
    return this.httpClient.delete<User>(this.getFullUrl(`user/${user.id}`));
  }

  removeUsersAndAllRoles(user: UserLookup): Observable<UserLookup> {
    return this.httpClient.delete<UserLookup>(this.getFullUrl(`users/${user.id}`, 'v1'));
  }

  updateUserRoles(userId: string, roles: Role[]): Observable<UserLookup> {
    return this.httpClient.post<UserLookup>(this.getFullUrl(`user/${userId}/roles`), roles);
  }

  changePassword(request: ChangePasswordRequest): Observable<User> {
    return this.httpClient.put<User>(this.getFullUrl('user/changePassword'), request);
  }

  resendAccess(user: User): Observable<boolean> {
    return this.httpClient.get<boolean>(this.getFullUrl(`user/resend/access/${user.id}`));
  }

  changeEmail(newEmail: string): Observable<string> {
    const options = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      headers: { 'Content-Type': 'text/plain' },
      withCredentials: true,
      responseType: 'text' as const,
    };

    return this.httpClient.post(
      this.getFullUrl('user/requestChangeEmail'),
      newEmail,
      options,
    ) as Observable<string>;
  }

  confirmEmailChange(changeEmail: ChangeEmail): Observable<ChangeEmail> {
    return this.httpClient.put<ChangeEmail>(
      this.getFullUrl('user/changeEmail'),
      changeEmail,
      this.getJsonOptions(),
    );
  }

  getResidentsForAdminPageable(
    condoId: string,
    page: number,
    perPage: number,
    search: string,
    typeahead?: boolean,
  ): Observable<User[]> {
    let params: HttpParams = new HttpParams();
    params = params.append('page', String(page));
    params = params.append('perPage', String(perPage));
    params = params.append('search', search === undefined ? '' : search);

    if (typeahead) {
      return this.httpClient.get<User[]>(
        this.getFullUrl(`user/condo/${condoId}/typeahead`),
        this.getJsonOptions({ params }),
      );
    } else {
      return this.httpClient.get<User[]>(
        this.getFullUrl(`user/condo/${condoId}/settings`),
        this.getJsonOptions({ params }),
      );
    }
  }

  getResidentsPageable(
    condoId: string,
    page?: number,
    perPage?: number,
    search?: string,
    hasProperty = false,
    includeOneUnitIfExists = false,
  ): Observable<User[]> {
    let params: HttpParams = new HttpParams();
    params = params.append('page', page ? String(page) : '0');
    params = params.append('perPage', perPage ? String(perPage) : '0');
    params = params.append('search', search === undefined ? '' : search);

    if (getFeatureToggle().hasPropertyQueryParam) {
      params = params.append('hasProperty', hasProperty);
    }

    if (getFeatureToggle().includeOneUnitIfExistsParam) {
      params = params.append('includeOneUnitIfExists', includeOneUnitIfExists);
    }

    return this.httpClient.get<User[]>(
      this.getFullUrl(`user/condo/${condoId}`),
      this.getJsonOptions({ params }),
    );
  }

  getResidentsForAdmin(
    condoId: string,
    refresh?: boolean,
    typeahead?: boolean,
  ): Observable<User[]> {
    if (!refresh && this.cachedUsers.length > 0) {
      return of(this.cachedUsers);
    }
    if (typeahead) {
      return this.httpClient.get<User[]>(this.getFullUrl(`user/condo/${condoId}/typeahead`));
    } else {
      return this.httpClient.get<User[]>(this.getFullUrl(`user/condo/${condoId}/settings`));
    }
  }

  getUsersByCondoIds(condoIds: string[], search: string): Observable<User[]> {
    let params = new HttpParams();
    params = params.append('condosIds', condoIds.join(','));
    params = params.append('search', search);

    return this.httpClient
      .get<{ users: User[] }>(
        this.getFullUrl('users/users-by-condos', 'v1'),
        this.getJsonOptions({ params }),
      )
      .pipe(map(res => res.users));
  }

  getOccupants(userId: string, propertyId: string): Observable<User[]> {
    return this.httpClient
      .get<User[]>(this.getFullUrl(`user/${userId}/${propertyId}/occupants`), this.getJsonOptions())
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  postOccupant(
    user: User,
    currentUserId: string,
    propertyId: string,
    groupId: string,
  ): Observable<User> {
    return this.httpClient.post<User>(
      this.getFullUrl(`user/${currentUserId}/${propertyId}/occupants/${groupId}`),
      user,
    );
  }

  deleteOccupant(user: User, currentUserId: string, propertyId: string): Observable<User> {
    return this.httpClient.delete<User>(
      this.getFullUrl(`user/${currentUserId}/${propertyId}/occupants/${user.id}`),
    );
  }

  getResidents(condoId: string, refresh?: boolean): Observable<User[]> {
    if (!refresh && this.cachedUsers.length > 0) {
      return of(this.cachedUsers);
    }

    return this.httpClient.get<User[]>(this.getFullUrl(`user/condo/${condoId}`));
  }

  getResidentsFromProperty(condoId: string, propertyId: string): Observable<User[]> {
    return this.httpClient.get<User[]>(this.getFullUrl(`user/condo/${condoId}/${propertyId}`));
  }

  getNotificationSettings(): Observable<NotificationSettings> {
    return this.httpClient.get<NotificationSettings>(this.getFullUrl('user/settings/notification'));
  }

  getResident(id: string): Observable<User> {
    return this.httpClient.get<User>(this.getFullUrl(`users/${id}`, 'v1'));
  }

  patchUserProfile(
    basicUserInfoForm: unknown,
    notificationSettings: NotificationSettings,
    privacySettings: PrivacySettings,
    userId: string,
    condoId: string,
    isDeletingPhone: boolean,
  ): Observable<ProfileConfigurationResponse> {
    let params = new HttpParams();
    params = params.append('condoId', condoId);
    params = params.append('isDeletingPhone', isDeletingPhone);

    const updates = {
      notificationSettings,
      privacySettings,
      basicInfo: basicUserInfoForm,
    };

    return this.httpClient.patch<ProfileConfigurationResponse>(
      this.getFullUrl(`users/${userId}/settings`, 'v1'),
      updates,
      this.getJsonOptions({ params }),
    );
  }

  loadResidentsPage(
    params: Params,
    page: number,
    size: number = 20,
  ): Observable<{ entities: User[]; totalElements: number }> {
    const httpParams = new HttpParams({
      fromObject: {
        page: String(page),
        size: String(size),
        ...params,
      },
    });

    return this.httpClient
      .get<{ content: User[]; totalElements: number }>(
        this.getFullUrl(`users/condo/${this._condoId}/residents`, 'v1'),
        this.getJsonOptions({ params: httpParams }),
      )
      .pipe(map(({ content, totalElements }) => ({ entities: content, totalElements })));
  }

  get cachedUsers(): User[] {
    return this._cachedUsers;
  }

  set cachedUsers(value: User[]) {
    this._cachedUsers = value;
  }
}
