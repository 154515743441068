import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TSqLoaderComponent } from './tsq-loader/tsq-loader.component';
import { TSqSmallLoaderComponent } from './tsq-small-loader/tsq-small-loader.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    TSqLoaderComponent,
    TSqSmallLoaderComponent,
  ],
  exports: [
    TSqLoaderComponent,
    TSqSmallLoaderComponent,
  ],
})
export class TSqLoadersModule {
}
