import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';

import {combineLatest, Observable, of} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {filter, mergeMap} from 'rxjs/operators';
import {CookieService} from 'ngx-cookie-service';

import {AppState} from '@tsq-web/state';
import {User} from '@tsq-web/users';
import {Condo} from '@tsq-web/condo';
import {Feature} from '@tsq-web/feature';
import {LoginService, ReloginGuardUtil} from '@tsq-web/login';
import * as fromUserContextSelectors from '@tsq-web/user-context/selectors';
import {Permission} from '../models/permission.enum';
import {hasFeatureAccess, hasFeatureAccessAnyCondo} from '../utils/user-permission.utils';

@Injectable({providedIn: 'root'})
export class FeaturePermissionGuard extends ReloginGuardUtil implements CanActivate {

  constructor(protected store: Store<AppState>,
              protected router: Router,
              protected cookieService: CookieService,
              protected loginService: LoginService) {
    super(loginService, cookieService, router, store);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const feature: Feature = route.data.feature;
    const permission: Permission = route.data.permission;
    const anyCondo = !!route.data.anyCondo;

    return combineLatest([
      this.store.pipe(select(fromUserContextSelectors.selectUser)),
      this.store.pipe(select(fromUserContextSelectors.selectCondo)),
    ])
      .pipe(
        filter(([user, condo]: [User, Condo]) => anyCondo || !!condo),
        mergeMap(([user, condo]: [User, Condo]) => {
          if (!user) {
            return this.reloginOverRoute(route, state);
          }
          if ((anyCondo && hasFeatureAccessAnyCondo(user, feature, permission)) || hasFeatureAccess(user, condo, feature, permission)) {
            return of(true);
          }
          return of(this.router.parseUrl(''));
        })
      );
  }
}
