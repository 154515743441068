<div class="btn-group dropdown-button"
     dropdown
     [tsqDropdown]="control"
     [isDisabled]="disabled">
  <button class="btn dropdown-toggle"
          dropdownToggle
          type="button"
          aria-controls="dropdown-animated">
    <img src="./assets/libs/icons/{{ icon }}.svg" class="filter-primary" alt="">
    {{ placeholder | translate }}
  </button>
  <ul *dropdownMenu class="dropdown-menu">
    <li *ngFor="let item of itemsText; let index = index">
      <a class="dropdown-item" (click)="selectItem = index">{{ item }}</a>
    </li>
  </ul>
</div>
