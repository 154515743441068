import { Directive, ElementRef, HostListener, Input, Renderer2, inject } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[tsqTooltipTruncated]',
})
export class TooltipTruncatedDirective {
  @Input('tsqTooltipTruncated') refEl: HTMLElement;

  private readonly el = inject<ElementRef<HTMLElement>>(ElementRef).nativeElement;
  private readonly renderer = inject(Renderer2);

  @HostListener('mouseover')
  private onMouseOver(): void {
    if (!!this.refEl && this.refEl.offsetWidth < this.refEl.scrollWidth) {
      this.renderer.setAttribute(this.el, 'data-tooltip', this.refEl.textContent);
      this.renderer.addClass(this.el, 'tooltip-multiline');
    }
  }

  @HostListener('mouseout')
  private onMouseOut(): void {
    this.renderer.removeAttribute(this.el, 'data-tooltip');
    this.renderer.removeClass(this.el, 'tooltip-multiline');
  }
}
