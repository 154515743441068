/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import {
  Component,
  Input,
  Output,
  EventEmitter,
  HostListener,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'sc-switch',
  templateUrl: 'sc-switch.component.html',
  styleUrls: ['sc-switch.component.scss'],
})
export class SCSwitchComponent implements OnChanges {
  @Input() checked: any = null;
  @Input() valueTrue: any = true;
  @Input() valueFalse: any = false;

  @Input() disabled = false;
  @Input() size = 'small';
  @Input() color = '#83c035';
  @Input() backgroundColor = '#fff';
  @Input() borderColor = '#dfdfdf';

  internalChecked: boolean;

  @Output() checkedChange = new EventEmitter<boolean>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change = new EventEmitter<boolean>();

  ngOnChanges(changes: SimpleChanges) {
    if (this.checked === undefined || this.checked === null) {
      this.internalChecked = false;
    } else {
      this.internalChecked = this.isTrueValue();
    }
  }

  @HostListener('click')
  onSwitchClick() {
    if (this.disabled) {
      return;
    }

    this.internalChecked = !this.internalChecked;
    this.emitMessages();
  }

  private emitMessages() {
    this.checkedChange.emit(this.internalChecked ? this.valueTrue : this.valueFalse);
    this.change.emit(this.internalChecked);
  }

  private isTrueValue() {
    return this.checked === this.valueTrue;
  }
}
