<div class="condo-selection-container full-height">
  <div class="col-md-6 col-md-offset-3">

    <img class="logo-image" src="./assets/img/logo-inline.png" alt="">

    <div class="panel outer-condo-selection-panel">

      <div class="condo-selection-header">
        <div class="col-md-7 col-sm-7">{{ 'CONDO.SELECT_YOUR_CONDO' | translate }}</div>
        <div class="col-md-5 col-sm-5 search">
          <div class="inner-addon-sm left-addon">
            <i class="fa fa-search"></i>
            <input id="list-search" [formControl]="searchControl" type="text" class="form-control input-sm condo-search"/>
          </div>
        </div>
      </div>

      <ul class="condo-list">
        <ng-container *ngFor="let item of showingCondos; let i = index">

          <li class="adm-header"
              *ngIf="shouldShowHeader(i)"
              [innerHTML]="item.condo.administrator && item.condo.administrator.name | tsqSearchHighlight: searchControl.value">
          </li>

          <li (click)="condoSelected(item.condo)">
            <div class="col-sm-6 col-md-6 no-padding-left title"
                 [innerHTML]="item.condo.name | tsqSearchHighlight: searchControl.value">
            </div>
            <div class="col-sm-6 col-md-6 text-right no-padding-right condo-address"
                 [innerHTML]="item.address | tsqSearchHighlight: searchControl.value">
            </div>
          </li>

        </ng-container>

        <li *ngIf="isSuper">
          <button class="btn btn-primary-o" *ngIf="!loading" (click)="loadCondoList()">
            {{'CONDO.LOAD_MORE' | translate}}
          </button>
          <tsq-small-loader *ngIf="loading"></tsq-small-loader>
        </li>
      </ul>

    </div>

  </div>
</div>

