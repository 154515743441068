import {Feature} from './feature.enum';
import {Permission} from '@tsq-web/permission';

export class FeaturePermission {
  feature: Feature;
  permission: Permission;

  static from(feature: Feature, permission: Permission) {
    const featurePermission = new FeaturePermission();
    featurePermission.feature = feature;
    featurePermission.permission = permission;
    return featurePermission;
  }
}
