<div
  class="flex w-full rounded"
  [class.outline]="isFocused"
  [ngClass]="{ 'border-red border-1': phoneControl.invalid && phoneControl.touched }"
>
  <button
    data-cy="intl-phone-input--button--selected-country-btn"
    class="flex items-center justify-between shrink-0 p-8 gap-8 w-80 bg-petro border-1 border-petro-n2 border-r-0 rounded-l cursor-pointer hover:bg-green-n1"
    [class.bg-petro-n1]="isDropdownOpen"
    [ngClass]="{ 'border-none': phoneControl.invalid && phoneControl.touched }"
    (click)="toggleDropdown()"
    [disabled]="isDisabled"
  >
    <span
      data-cy="intl-phone-input--span--flag"
      class="text-xl fi fis"
      [ngClass]="'fi-' + (selectedCountry?.code.toLowerCase() ?? 'us')"
    ></span>
    <div
      class="grow text-center"
      data-cy="intl-phone-input--div--dial-code"
      [class.text-coal-tertiary]="isDropdownOpen"
    >
      {{ selectedCountry?.dialCode ?? '+1' }}
    </div>
  </button>

  <input
    class="w-full px-16 py-8 bg-petro border-1 border-petro-n2 rounded-r placeholder:text-coal-tertiary"
    [ngClass]="{ 'border-t-0 border-r-0 border-b-0': phoneControl.invalid && phoneControl.touched }"
    type="text"
    [formControl]="phoneControl"
    [imask]="phoneMask"
    [placeholder]="phoneMask.mask[0].mask"
    (focus)="onFocus()"
    (blur)="onBlur()"
  />
</div>
<span
  *ngIf="phoneControl.invalid && phoneControl.touched"
  class="label-text mt-4 text-red uppercase"
  >{{ 'INTL_PHONE_INPUT.ERRORS.PHONE_LENGTH_VALIDATION' | translate }}</span
>

<div class="relative w-full">
  <div
    data-cy="intl-phone-input--div--countries-dropdown"
    class="h-200 absolute w-full overflow-auto tsq-card p-4 mt-4 z-50"
    role="listbox"
    [aria-expanded]="isDropdownOpen"
    *ngIf="isDropdownOpen"
  >
    <div
      data-cy="intl-phone-input--div--preferred-countries-section"
      *ngIf="!!preferredCountryCodes"
      [ngClass]="!displayOnlyPreferredCountries ? 'pb-4 mb-4 border-b-1 border-petro-n2' : ''"
    >
      <ng-container *ngFor="let country of preferredCountriesList">
        <button
          (click)="selectCountry(country)"
          class="flex w-full items-center rounded p-8 gap-8 hover:bg-green-n1"
          [class.bg-petro-n1]="selectedCountry === country"
          role="listitem"
        >
          <span
            class="text-xl shrink-0 fi fis"
            [ngClass]="'fi-' + country.code.toLowerCase()"
          ></span>
          <div class="grow text-left">
            {{ country.name | translate }} {{ country.dialCode }}
          </div>
        </button>
      </ng-container>
    </div>

    <div
      data-cy="intl-phone-input--div--generic-countries-section"
      *ngIf="!displayOnlyPreferredCountries"
    >
      <ng-container *ngFor="let country of countryCodes">
        <button
          data-cy="intl-phone-input--button--select-country-btn"
          *ngIf="!preferredCountryCodes?.includes(country.code)"
          (click)="selectCountry(country)"
          class="flex w-full items-center rounded p-8 gap-8 hover:bg-green-n1"
          [class.bg-petro-n1]="selectedCountry === country"
          role="listitem"
        >
          <span
            class="text-xl shrink-0 fi fis"
            [ngClass]="'fi-' + country.code.toLowerCase()"
          ></span>
          <div class="grow text-left">{{ country.name + ' ' + country.dialCode }}</div>
        </button>
      </ng-container>
    </div>
  </div>
</div>
