import { FileMeta } from '../models/file-meta.model';

export const mockedFileMeta: FileMeta = {
  id: '3a5dc2178fd582b6248ff1377df9c735',
  name: 'MockedFileMeta',
  key: '4213',
  fileUrl: 'https://randomuser.me/api/portraits/women/4.jpg',
  filenameExt: 'jpg',
  size: 500000,
};
