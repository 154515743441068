import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { ComponentType, Toast, ToastrService } from 'ngx-toastr';

import { ToastErrorComponent } from '@tsq-web/toast';

import { ErrorMessageFindFn, defaultFindFn } from '../models/errors.model';
import { getHttpErrorMessage } from '../utils/errors.utils';

@Injectable()
export class ErrorsService {
  constructor(private translateService: TranslateService, private toastr: ToastrService) {}

  show(error: HttpErrorResponse, findFn: ErrorMessageFindFn = defaultFindFn): void {
    this.toastr.error(
      null,
      getHttpErrorMessage(error, findFn) || this.translateService.instant('LIBS.COMMON_ERROR_TEXT'),
      {
        toastComponent: ToastErrorComponent,
      },
    );
  }

  showCustomToastComponent(
    error: HttpErrorResponse,
    toastComponent: ComponentType<Toast>,
    findFn: ErrorMessageFindFn = defaultFindFn,
  ): void {
    this.toastr.show(
      '',
      getHttpErrorMessage(error, findFn) || this.translateService.instant('LIBS.COMMON_ERROR_TEXT'),
      { toastComponent },
    );
  }
}
