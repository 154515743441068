/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'menu-item-child',
  templateUrl: 'menu-item-child.component.html',
  styleUrls: ['menu-item-child.component.scss'],
  // directives: [ROUTER_DIRECTIVES]
})
export class MenuItemChildComponent {
  @Input() label: string;
  @Input() linksTo: string;
  @Input() queryParams: Record<string, string> = {};
  @Output() clickEvent = new EventEmitter();
}
