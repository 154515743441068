import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable, Subject, combineLatest, filter, take } from 'rxjs';

import { icons } from '@tsq-web/assets';
import { getTSqEnvironment } from '@tsq-web/environment';
import { AppState } from '@tsq-web/state';
import { fromUserContextActions, fromUserContextSelectors } from '@tsq-web/user-context';
import { User } from '@tsq-web/users';

import { getCurrentLogoPath } from '../../../shared/util/environment.utils';
import { AuthStep } from '../../models/auth-step.enum';
import { LoginInformation } from '../../models/login-information.model';
import { AuthStepStore } from '../../state/auth-step/auth-step.store';
import { sha1 } from '../../utils/login.utils';

@UntilDestroy()
@Component({
  selector: 'comm-auth-card',
  templateUrl: './auth-card.component.html',
  providers: [],
})
export class AuthCardComponent implements OnInit {
  @Output() goBackClicked = new EventEmitter<void>();

  errorMessage$: Observable<string>;
  isLoginIn$: Observable<boolean>;

  authStep = AuthStep;
  loginInformation: LoginInformation;
  redirectedToSso = false;

  readonly leftArrow = icons.arrowLeft;
  protected readonly logoPath = getCurrentLogoPath();

  readonly passwordSent$ = new Subject<string>();

  private readonly stepStore = inject(AuthStepStore);
  private readonly store = inject<Store<AppState>>(Store);

  readonly email$ = this.stepStore.email$;
  readonly isLoading$ = this.stepStore.isLoading$;
  readonly step$ = this.stepStore.step$;
  readonly ssoAuthenticateLoading$ = this.store.select(
    fromUserContextSelectors.selectSsoAuthenticateLoading,
  );
  readonly ssoLoginError$ = this.store.select(fromUserContextSelectors.selectSsoLoginError);

  ngOnInit(): void {
    this.stepStore.resetState();

    this.isLoginIn$ = this.store.select(fromUserContextSelectors.selectUserLoggingIn);
    this.errorMessage$ = this.store.select(fromUserContextSelectors.selectLoginError);

    combineLatest([this.email$, this.passwordSent$])
      .pipe(
        untilDestroyed(this),
        filter(([, password]) => !!password),
      )
      .subscribe(([email, password]) => {
        this.handleLogin(email, password);
        this.loginInformation = {
          username: email,
          password,
        };
      });
  }

  onPreviousStep(): void {
    this.step$.pipe(take(1)).subscribe(step => this.handleStepBack(step));
  }

  onNext(email: string): void {
    this.passwordSent$.next(undefined);
    this.stepStore.checkSsoConnection(email);
  }

  onLoginRequested(loginInformation: LoginInformation): void {
    this.handleLogin(loginInformation.username, loginInformation.password);
  }

  onRestartFlowRequested(): void {
    this.stepStore.goToStep(AuthStep.Email);
    this.loginInformation = { ...this.loginInformation, password: '' };
  }

  redirectSso(): void {
    if (!this.redirectedToSso) {
      this.stepStore.email$.pipe(take(1)).subscribe(email => {
        this.store.dispatch(
          fromUserContextActions.ssoAuthenticate({
            email,
            uri: `${getTSqEnvironment().communitiesUrl}/auth/callback`,
          }),
        );
      });

      this.redirectedToSso = true;
    }
  }

  backToLogin(): void {
    this.stepStore.goToStep(AuthStep.Email);
  }

  private handleLogin(email: string, password: string): void {
    const user: User = {
      ...new User(),
      platform: 'webapp',
      email: email.trim(),
      password: this.isActiveDirectory(email) ? password : sha1(password),
      ['login_type']: this.isActiveDirectory(email) ? 'AD' : 'CMN',
    };

    this.store.dispatch(fromUserContextActions.loginV3({ user }));
  }

  private isActiveDirectory(username: string): boolean {
    return new RegExp(/(na|dev|associa)\\.{3,}$/i).test(username);
  }

  private handleStepBack(step: AuthStep): void {
    this.shouldEmitGoBack(step) ? this.goBackClicked.emit() : this.stepStore.goBack();
  }

  private shouldEmitGoBack(step: AuthStep): boolean {
    return step === AuthStep.Email;
  }
}
