import { PageableV2 } from './pageable-v2.model';
import { Pageable } from './pageable.model';

export function mockedPageable<T>(entities: T[] = []): Pageable<T> {
  return {
    content: entities,
    page: 0,
    number: 0,
    size: entities.length,
    total: entities.length,
    totalElements: entities.length,
  };
}

export function mockedPageableV2<T>(content: T[] = []): PageableV2<T> {
  return {
    content,
    pageable: {
      sort: {
        sorted: false,
        unsorted: true,
        empty: content.length === 0,
      },
      offset: 0,
      pageNumber: 1,
      pageSize: content.length,
      paged: true,
      unpaged: false,
    },
    last: true,
    totalElements: content.length,
    totalPages: 1,
    size: content.length,
    number: 0,
    sort: {
      sorted: false,
      unsorted: true,
      empty: content.length === 0,
    },
    numberOfElements: content.length,
    first: true,
    empty: content.length === 0,
  };
}
