import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { Observable, Observer, of } from 'rxjs';

import { AppState } from '@tsq-web/state';
import { ToastErrorComponent } from '@tsq-web/toast';
import * as fromUserContextActions from '@tsq-web/user-context/actions';

import { LoginContext } from '../models/login-context.model';
import { LoginService } from '../services/login.service';

@Injectable()
export class ChangeContextGuard implements CanActivate {
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private cookieService: CookieService,
    protected toastrService: ToastrService,
    protected translateService: TranslateService,
    private loginService: LoginService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    if (this.cookieService.check('jwt')) {
      return new Observable((observer: Observer<boolean | UrlTree>) => {
        this.loginService.getContextList().subscribe(
          (contexts: LoginContext[]) => {
            if (!!contexts.length) {
              if (contexts.length > 1) {
                observer.next(true);
              } else {
                const loginContext = contexts[0];
                loginContext.user.roles = loginContext.permissions;

                this.store.dispatch(fromUserContextActions.setContext({ loginContext }));
                this.store.dispatch(
                  fromUserContextActions.bypassLogin({ contextId: loginContext.id }),
                );
                observer.next(false);
              }
            } else {
              this.store.dispatch(fromUserContextActions.contextLogout());
              observer.next(this.router.parseUrl(`login?redirect=${state.url}`));
            }
          },
          () => {
            this.toastrService.error(null, this.translateService.instant('COMMON_ERROR_TEXT'), {
              toastComponent: ToastErrorComponent,
            });
            this.store.dispatch(fromUserContextActions.contextLogout());
            observer.next(this.router.parseUrl(`login?redirect=${state.url}`));
          },
        );
      });
    } else {
      return of(this.router.parseUrl('login'));
    }
  }
}
