import { ActionReducerMap } from '@ngrx/store';
import { routerReducer } from '@ngrx/router-store';

import { userContextReducer } from '@tsq-web/user-context';
import { localizationReducer } from '@tsq-web/localization';
import { AppState } from '../models/app-state';

export const reducers: ActionReducerMap<AppState> = {
  userContext: userContextReducer,
  router: routerReducer,
  localization: localizationReducer,
};
