import { BusinessFeatures } from '../models/business-features.enum';

export function getBusinessFeatureTranslationKey(feature: BusinessFeatures): string {
  return `ORGANIZATION_SUBSCRIPTION.${pascalToSnakeCase(feature)}`;
}

export function getBusinessFeatureCommercialName(feature: BusinessFeatures) {
  switch (feature) {
    case BusinessFeatures.ArchitecturalReview:
      return 'Architectural Review';
    case BusinessFeatures.ArchitecturalReviewMailing:
      return 'Architectural Review Mailing';
    case BusinessFeatures.Automations:
      return 'Automations';
    case BusinessFeatures.EmergencyBroadcast:
      return 'EmergencyBroadcast';
    case BusinessFeatures.Insights:
      return 'Performance Review';
    case BusinessFeatures.Portfolio:
      return 'Portfolio';
    case BusinessFeatures.Reports:
      return 'Reports';
    case BusinessFeatures.Violations:
      return 'Violations';
    case BusinessFeatures.ViolationMailing:
      return 'Violations Mailing';
    case BusinessFeatures.Workflow:
      return 'Workflow';
    case BusinessFeatures.MailOrders:
      return 'Ink Orders';
    case BusinessFeatures.AnalyticsInsights:
      return 'Insights';
  }
}

function pascalToSnakeCase(feature: BusinessFeatures): string {
  return feature
    .split(/\.?(?=[A-Z])/)
    .join('_')
    .toUpperCase();
}
