import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

import { HttpService } from '@tsq-web/core';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { selectUntilDestroyed } from '@tsq-web/redux/operators';
import * as fromUserContextSelectors from '@tsq-web/user-context/selectors';

import { Favorite } from '../models/favorite.model';
import { getFullUrlFavorite } from '../utils/user.utils';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class UserFavoritesService extends HttpService {
  private condoId: string;
  private userId: string;

  private readonly httpClient = inject(HttpClient);
  private readonly store = inject(Store);
  private readonly cookieService = inject(CookieService);

  constructor() {
    super();

    this.store
      .pipe(selectUntilDestroyed(fromUserContextSelectors.selectCondo, this))
      .subscribe(condo => (this.condoId = condo?.id || ''));
    this.store
      .pipe(selectUntilDestroyed(fromUserContextSelectors.selectUser, this))
      .subscribe(user => (this.userId = user?.id));
  }

  loadFavorites(): Observable<Favorite> {
    let headers = new HttpHeaders();

    if (this.cookieService.check('jwt')) {
      const jwt = this.cookieService.get('jwt');
      headers = headers.set('Authorization', `Bearer ${jwt}`);
    }

    return this.httpClient.get<Favorite>(`${getFullUrlFavorite()}/${this.userId}`, { headers });
  }

  saveFavorites(favorites: string[], isPost: boolean): Observable<Favorite> {
    let headers = new HttpHeaders();

    if (this.cookieService.check('jwt')) {
      const jwt = this.cookieService.get('jwt');
      headers = headers.set('Authorization', `Bearer ${jwt}`);
    }

    const request = { features: favorites, userId: this.userId };

    return isPost
      ? this.httpClient.post<Favorite>(`${getFullUrlFavorite()}/${this.userId}`, request, {
          headers,
        })
      : this.httpClient.put<Favorite>(`${getFullUrlFavorite()}/${this.userId}`, request, {
          headers,
        });
  }
}
