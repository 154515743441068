/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/relative-url-prefix */
import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sc-color-picker',
  templateUrl: 'sc-color-picker.component.html',
  styleUrls: ['sc-color-picker.component.scss'],
})
export class SCColorPickerComponent implements OnInit {
  @Output() onColorChanged = new EventEmitter<any>();

  colors: { colorId: string; colorHex: string; highlighted: boolean; selected: boolean }[] = [
    { colorId: 'red', colorHex: '#FD6860', highlighted: true, selected: false },
    { colorId: 'orange', colorHex: '#FCAD41', highlighted: true, selected: false },
    { colorId: 'green', colorHex: '#7AAA12', highlighted: true, selected: false },
    { colorId: 'oceanGreen', colorHex: '#42AF71', highlighted: true, selected: false },
    { colorId: 'turquoise', colorHex: '#47D9DE', highlighted: true, selected: false },
    { colorId: 'blue', colorHex: '#5CA0E2', highlighted: true, selected: false },
    { colorId: 'purple', colorHex: '#A162AE', highlighted: true, selected: false },
    { colorId: 'brown', colorHex: '#92551F', highlighted: true, selected: false },
    { colorId: 'black', colorHex: '#333333', highlighted: true, selected: false },
    { colorId: 'grey', colorHex: '#C0C3D6', highlighted: true, selected: false },
  ];

  selectedColor: any;

  ngOnInit() {}

  onColorSelected(color: any) {
    this.selectedColor = color;
    if (color !== null) {
      this.updateColors(this.selectedColor);
    } else {
      this.resetColors();
    }
    this.onColorChanged.emit(this.selectedColor);
  }

  updateColors(selectedColor: any) {
    for (const color of this.colors) {
      if (color.colorId === selectedColor.colorId) {
        color.highlighted = true;
        color.selected = true;
      } else {
        color.highlighted = false;
        color.selected = false;
      }
    }
  }

  resetColors() {
    for (const color of this.colors) {
      color.highlighted = true;
      color.selected = false;
    }
  }

  resetPicker() {
    this.selectedColor = null;
    this.resetColors();
  }

  selectColor(color: string) {
    const selectedColor: any = this.colors.filter(clr => clr.colorHex === color)[0];
    this.onColorSelected(selectedColor);
  }
}
